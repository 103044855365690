import React, { useState } from 'react'
import { Button, Stack, Typography, Divider } from '@mui/material'
import {CiSearch} from 'react-icons/ci'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FillButton, OutlinedButton} from '../../../../Widgets/FormUtils/FormUtils'
import {IoClose} from 'react-icons/io5'

import './SkillSet.css'

const SkillSet = ({data, setOpenSkillSet, chosenSkills, setChosenSkills}) => {
    const [skills, setSkills] = useState(data)
    const [view, setView] = useState(1)
    
    const handleChange = (e) => {
        const text = e.target.value
        if(text.length > 0){
            const result = data.filter((val) => val.name.toLowerCase().includes(text.toLowerCase()) && val.name.toLowerCase().startsWith(text.toLowerCase()))
            setSkills(result)
        }else if (text === ''){
            setSkills(data)
        }
    }

    const handleSelected = (e) => {
        const checked = e.target.checked
        const id = e.target.value

        const result = data.filter(val => val.id === id)
        if(checked){
            setChosenSkills([...chosenSkills,result[0]])

        }else {
            setChosenSkills(chosenSkills.filter(val => val.id !== id))
        }

    }

    const removeSelected = (id) => {
        setChosenSkills(chosenSkills.filter(val => val.id !== id))
        if (chosenSkills.length === 1) setView(1)
    }

    const handleClick = () => {
        setView(2)
    }
    return (
        <div>
        {view === 1 && <Stack className='max-h-[300px] overflow-y-auto bg-white dark:bg-A06' sx={{
            minWidth: '356px',
            minHeight: '292px',
            position: 'relative',
            boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)',
            padding: '1rem'
        }} direction="column" gap={3}>
  
            {/* search row */}
            <Stack className='sticky bg-white border-1 border-[#E6E6E6] dark:border-333 dark:bg-A06 dark:text-white top-0 z-10' sx={{fontSize: '18px', color: '#A5A5A5', padding: '3px 5px', borderRadius: '5px'}}
            direction='row'
            alignContent='flex-end'
            gap={0.5}
            >
                <CiSearch/>
                <input
                placeholder='Search'
                className='search-input dark:bg-A06 dark:border-333' style={{
                    height: '1px', 
                    border: 'none', 
                    width: '70%', 
                    margin: 0,
                    padding: '10px 5px',
                
                    }} type="text" onChange={handleChange}/>
            </Stack>
  
            {/* checkboxes row */}
            <Stack ml={1}>
                <FormGroup onChange={handleSelected}>
                    {skills.map((val, index) => {
                        // if (index < 6) {
                            return <FormControlLabel control={<Checkbox size='small' sx={{padding: '2px'}}/>} label={<Typography sx={{fontSize: '14px',fontWeight: '400'}}>{val.name}</Typography>} value={val.id}/>
                        // }
                    })}
                    
                </FormGroup>
            </Stack>
  
            {/* Divider button row */}
            <Stack mt={2} gap={1} direction='column' className='sticky bottom-0 bg-white dark:bg-A06'>
                <Divider /> 
                <div className='flex flex-row justify-between'>
                    <OutlinedButton text={'Cancel'} callBack={()=>setOpenSkillSet(false)}/>
                    <FillButton text={'Done'} disable={chosenSkills.length > 0 ? false : true} callBack={handleClick}/>
                </div>
            </Stack>
        </Stack>}
        
        {view === 2 && <div className='min-w-[356px] h-[115px] p-1 border-1 dark:border-333 flex flex-row flex-wrap gap-2 overflow-y-auto border-[#d3d4d8] rounded-[4px]'>
           
           {chosenSkills.map(val => {
            return <SkillElement text={val.name} handleClick={()=>removeSelected(val.id)}/>
           })}
          
          

        </div> }
    </div>
  )
}


const SkillElement = ({text, handleClick}) => {
  return (
    <div className='bg-[#E6E6E6] dark:bg-969 dark:text-white px-3 py-1 h-8 gap-1 flex flex-row justify-center items-center  rounded-[5px]'>
        <p className='text-[13px]'>{text}</p>
        <div className='cursor' onClick={handleClick}><IoClose size={18}/></div>
    </div>
  )
}


export default SkillSet