import React,{useState, useEffect} from 'react'
import { Pie } from '@ant-design/plots';
import { useSelector} from 'react-redux';
import { TextAndField, FieldSelectWithOutFormik, Text } from '../../../../Widgets/FormUtils/FormUtils';
import {font} from '../../../../Widgets/Styles/font'
import { getSkillSetStat } from '../../../../../Network/ServiceClass/dashboardService';
import CustomDropdown from '../../../../Widgets/CustomDropdown';


import { CircularProgress } from '@mui/material';

const SkillSetStat = ({branches = []}) => {

 
  const [pieData, setPieData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState({
    label: 'All Locations',
    value: '1',
    hexcode: ''
  })
  const company = useSelector(state => state.company.activeCompany)

  const abbrevateName = (text) => {
    let abb
    const findSpace = text.includes(' ')

    if (findSpace){
      const a = text.split(' ')[0][0]
      const b = text.split(' ')[1][0]
      abb = a.concat(b)

    }else{
      abb = text.slice(0,2)
    }
    return abb
  }


  useEffect(()=>{
    setIsLoading(true)
   
    getSkillSetStat()
    .then(val => {
      setIsLoading(false)
      setPieData(val.map(el => ({
        type: el?.name,
        value: Number(el?.count),
        // color: el.hexcode === 'NULL' ? '#00000' : el.hexcode

      })))
    })
    .catch(()=> setIsLoading(false))
  },[company])

  const handleChangeBranch = (e) => {
    const value = e.value
    setSelectedBranch(e)
    if (value === '1') {
      getSkillSetStat()
      .then(val => {
        setIsLoading(false)
        setPieData(val.map(el => ({
          type: (el.skillSetCode === "") ? abbrevateName(el?.name) : el?.skillSetCode,
          value: Number(el?.count),
  
        })))
      })
      .catch(()=> setIsLoading(false))
    } else {
      getSkillSetStat(value)
      .then(val => {
        setIsLoading(false)
        setPieData(val.map(el => ({
          type: (el.skillSetCode === "") ? abbrevateName(el?.name) : el?.skillSetCode,
          value: Number(el?.count),
        })))
      })
      .catch(()=> setIsLoading(false))
    }

  }


  const config = {
    appendPadding: 10,
    padding:0,
    data: pieData,
    angleField: 'value',
    radius: 0.6,
    innerRadius: 0.8,
    legend: {
      position: 'bottom', // Display legend below the chart
    },
    
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    // legend: false,
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '21px',
          ...font
        },
        content: 'Skill Set',
      },
    },

    
    label: false,
      colorField: 'type', // or seriesField in some cases
      color: ["#3498db", "#2980b9", "#1abc9c", "#16a085", "#2ecc71"]
      // (type) => {
      //   const item = pieData.find((d) => d.type === type.type);
      //   return item ? item.color : undefined;
      // },
  };

  


  return (
    <div className='flex flex-col p-3 shadow-shift-shadow rounded-lg '>
      <div className='flex justify-between w-[100%]'>
          <p className='text-sm font-medium dark:text-white'>Skills Distribution</p>
          <div className='w-[35%]'>

            <TextAndField space={0.3}>
                <Text className='dark:text-white' text={'Select Location'}/>
                
                <CustomDropdown
                trimtext
                trimLength={13}
                data={[
                  {
                    label: 'All Locations',
                    value: '1',
                    hexcode: ''
                  },
                  ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
                ]}
                className="h-10 rounded "
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={selectedBranch}
                onChangeOption={handleChangeBranch}
              />

            </TextAndField>
          </div>

      </div>
      {!isLoading && <Pie {...config} />}
      {isLoading && <div className='w-[100%] flex justify-center mt-3'><CircularProgress/></div>} 


    </div>
  )
}

export default SkillSetStat