import React, { useState } from 'react'
import Box from '@mui/material/Box';
import './Style.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Branch } from '../../../../../../Network/ServiceClass/Branch';
import { CircularProgress } from '@mui/material';
import {Text, TextAndField, Field, FieldSelect, OutlinedButton, FillButton} from '../../../../../Widgets/FormUtils/FormUtils'
import { useDispatch } from 'react-redux';
import PopError from '../../../../../Widgets/PopError';
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';


const AddLicenseModal = ({ open, handleClose, callback }) => {

    const branch = new Branch();
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            name: "",
            type: "",
            licenceNumber: "",
            expiration: "",
            priorExpirationAlert: "10"
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, "Name is to short")
                .required("Please enter the name of the license"),
            type: Yup.string().required("Please select license type"),
            licenceNumber: Yup.string().required("Please enter license number"),
            expiration: Yup.string().required("Please enter expiration date"),
            priorExpirationAlert: Yup.string().required("Please enter prior expiration alert")
        }),
        onSubmit: (value) => {

            if (file !== null) {
                const formdata = new FormData();
                formdata.append("name", value.name);
                formdata.append("type", value.type);
                formdata.append("licenceNumber", value.licenceNumber);
                formdata.append("expiration", value.expiration);
                formdata.append("priorExpirationAlert", value.priorExpirationAlert);
                formdata.append("file", file);

                setIsLoading(true);

                branch.AddLicense(formdata).then((value) => {
                    callback();
                    setIsLoading(false); 
                    formik.resetForm()
                    toast('You added a license successfully')
                    handleClose();
                }).catch((err) => {
                    console.log(err); 
                    setIsLoading(false); 
                    setIsError(true)
                    setErrorMessage(err.response.data.message);
                });

            }else{
                setIsError(true)
                setErrorMessage('You have to choose a file to upload')
            }

        }
    });

    const [file, setFile] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState();
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const chooseFile = () => {
        const choosenfile = document.querySelector('.img-file');
        choosenfile.click();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '100vh',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflowY:'auto',
        overflowX:'hidden'
      };

        
    return (
        <FormModal open={open} handleClose={handleClose}>
            <Box sx={{ ...style, }} className="create-account dark:bg-A06 dark:text-white">


                <div className='flex flex-col gap-3 items-center'>
                    <p className='font-semibold'>Add User License</p>

                    <TextAndField space={0.5}>
                        <Text text={'Name of the license'}/>
                        <Field text={'E.g Licensed Acupuncturist'} 
                            value={formik.values.name} 
                            formik={formik} 
                            name='name'/>
                    </TextAndField>

                    <TextAndField space={0.5}>
                        <Text text={'License number'}/>
                        <Field text={'E.g 112233445566'} 
                            value={formik.values.licenceNumber} 
                            formik={formik} 
                            name='licenceNumber'/>
                    </TextAndField>

                    <TextAndField space={0.5}>
                        <Text text={'Type of License'}/>
                        <FieldSelect  list={[{id: 'some-type', name: 'Some Type'}]}
                            value={formik.values.type} 
                            formik={formik} name='type'
                            />
                    </TextAndField>

                    <TextAndField space={0.5}>
                        <Text text={'Prior Expiration Alert'}/>
                        <FieldSelect  list={[
                            {id: 30, name: '30 Days'}, 
                            {id: 60, name: '60 Days'}, 
                            {id: 90, name: '90 Days'}]}
                            value={formik.values.priorExpirationAlert} 
                            formik={formik} name='priorExpirationAlert'
                            />
                    </TextAndField>
                    
                    <div className='w-[100%]'>
                        <TextAndField space={0.5}>
                            <Text text={'Expiry date'}/>
                            <input name="expiration" className='border-1 p-1 rounded-[5px] dark:bg-A06 dark:border-333' value={formik.values.expiration} onChange={formik.handleChange} type="date" />

                        </TextAndField>

                    </div>

                    <div className='w-[80%] m-auto flex flex-row justify-center'>

                        <TextAndField>

                            <div className='border-1 border-dashed px-10 py-5'>

                                <input type='file' className='img-file' onChange={(e) => setFile(e.target.files[0])} style={{ display: "none" }} />

                                <div onClick={chooseFile} style={{ fontSize: "12px", cursor: "pointer",}}>
                                    <span style={{borderBottom:'1px solid black',fontSize:"15px"}}>Click to upload files</span>
                                    <p style={{color:"#A5A5A5",fontSize:"10px"}}>SVG,PNG,JPG (MAX 3mb)</p>
                                </div>

                            </div>
                            <div className='w-[100%] text-left text-[11px] mt-[10px] mb-[60px]' >{file === null ? "File should not be larger than 3mb" : "1 file picked"}</div>
                        </TextAndField>
                    </div>

                    {isError &&<PopError message={errorMessage} handleClick={()=> setIsError(false)}/>}

                    <div className='flex self-start w-[100%] justify-between'>
                        <OutlinedButton text={'Cancel'} width={'100px'} height={'45px'} callBack={()=>{handleClose(); formik.resetForm(); setFile(null)}}/>
                        {!isLoading && <FillButton text={'Submit'} width={'100px'} height={'45px'} callBack={formik.handleSubmit}/>}
                        {isLoading && <CircularProgress/>}
                    </div>

                </div>

            </Box>
        </FormModal>
    )
}

export default AddLicenseModal