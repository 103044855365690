import { useRef, useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import setIntents from "../hooks/setIntents";
import { useStreaming } from "../hooks/useSteamingState";

const VoiceInput = (setText, handleStopListening) => {
	const { hasEnded, hasStarted } = useStreaming((state) => state);
	const [listening, setListening] = useState(false);
	const recognitionRef = useRef(null);
	const timerRef = useRef();
	const lastRecognitionTimeRef = useRef(null); // Timestamp of last recognition event
	const { currentIntent } = setIntents((state) => state);

	const startTimer = () => {
		timerRef.current = setTimeout(() => {}, 1500);
	};

	const resetTimer = useCallback(() => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		startTimer(); // Start timer or restart if already running
	}, []);

	const stopListening = useCallback(() => {
		if (recognitionRef.current) {
			recognitionRef.current.stop();
		}
	}, []);

	if (hasStarted) {
		stopListening();
	}

	const startRecognition = useCallback(() => {
		if ("webkitSpeechRecognition" in window) {
			// Chrome
			recognitionRef.current = new (window).webkitSpeechRecognition();
		} else if ("SpeechRecognition" in window) {
			// Other browsers
			recognitionRef.current = new (window).SpeechRecognition();
		} else {
			toast.error("your browser is not supported");
			return;
		}
		const recognition = recognitionRef.current;

		recognition.interimResults = true;
		recognition.continuous = true;
		recognition.onstart = () => {
			setListening(true);
		};

		recognition.onend = () => {
			setListening(false);
			lastRecognitionTimeRef.current = null; // Reset last recognition time
		};

		recognition.onresult = (event) => {
			const transcript = Array.from(event.results)
				.map((result) => result[0])
				.map((result) => result.transcript)
				.join("");
			setText(transcript);
			lastRecognitionTimeRef.current = Date.now();
			resetTimer(); // Reset timer on new recognition event
		};

		recognition.start();
	}, [setText, resetTimer]);

	// Activate the mic when done streaming and other conditions
	const toggleMic = useCallback(() => {
		const action = currentIntent?.action;

		if (
			hasEnded &&
			action !== "conversation.end" &&
			action !== "gratitude" &&
			(currentIntent?.prediction ?? 0) < 85
		) {
			// Start recognition if hasEnded is true and action is not conversation.end or gratitude
			startRecognition();
		} else if (
			hasEnded &&
			(action === "conversation.end" || action === "gratitude") &&
			(currentIntent?.prediction ?? 0) < 85
		) {
			startRecognition();
		} else {
			stopListening();
		}
	}, [hasEnded, currentIntent, startRecognition, stopListening]);

	useEffect(() => {
		toggleMic();
	}, [toggleMic]);

	// Check for inactivity every 500ms
	useEffect(() => {
		const interval = setInterval(() => {
			const lastRecognitionTime = lastRecognitionTimeRef.current;
			if (listening && lastRecognitionTime) {
				const timeSinceLastRecognition = Date.now() - lastRecognitionTime;
				if (timeSinceLastRecognition > 1500) {
					handleStopListening(); // Send the prompt
					setListening(false);
					setText("");
				}
			}
		}, 300); // Check every 500ms

		return () => clearInterval(interval);
	}, [listening, setText, handleStopListening]);

	// Cleanup timer on component unmount
	useEffect(() => {
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, []);

	return { startRecognition, stopListening, listening };
};

export default VoiceInput;