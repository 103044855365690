import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Storage } from "../Network/StorageClass/StorageClass";
import { getReport } from '../Network/ServiceClass/reportService';
import { setCompanyLogo, getUnavailableDays, blockDays, unBlockDays, adminUnBlockDays, getCompanyRotationRecord } from '../Network/ServiceClass/Company';
import { Home } from '../Network/ServiceClass/Home';
import moment from 'moment'

const storage = new Storage();
const activeCompany = storage.getActiveCompany();
const companies = storage.getCompanies();
const user = storage.getUserDetails()
const dp = (storage.getUserDetails()===null)?null:storage.getUserDetails().picture;
const home = new Home();
export const getReportAsync = createAsyncThunk('company/getReports', getReport);
export const setCompanyLogoAsync = createAsyncThunk("company/setCompanyLogo", setCompanyLogo)
export const getCityAsync = createAsyncThunk("company/getCityAsync", home.getCity)
export const getUnavailableAsync = createAsyncThunk("company/getUnavailableDays", getUnavailableDays)
export const blockDaysAsync = createAsyncThunk("company/blockDays", blockDays)
export const adminUnBlockDaysAsync = createAsyncThunk("company/adminUnBlockDays", adminUnBlockDays)
export const unBlockDaysAsync = createAsyncThunk("company/unBlockDays", unBlockDays)

export const finishTourThunk = createAsyncThunk("company/finishTour", home.finishTour)

export const companyRotationDataThunk = createAsyncThunk("company/companyRotationThunk", getCompanyRotationRecord)
const member = activeCompany?.memberships.find((x) => x.userId === user?.id);

const initialState = {
    activeCompany: activeCompany,
    companies: companies,
    reports: {},
    loading: false,
    cities: [],
    privilege: member?.privilege || 1,
    rotationNumber: Math.ceil(moment().endOf('year').diff(moment(activeCompany?.rotationStart),'days')/(activeCompany?.rotationDays || 28)),
    currentRotationNumber: Math.floor(moment().diff(moment(activeCompany?.rotationStart),'days')/(activeCompany?.rotationDays || 28)),

    dp: dp,
    loggedIn: false,

    successAlert: {
        status: false,
        title: '',
        content: '',
        image: ''
    },
    unavailableDays: [],
    rotationData: [],
    memberships: []

}


const CompanySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        setActiveCompany: (state, action)=>{
            state.activeCompany = action.payload;
        }, 
        setCompanies: (state, action)=>{
            state.companies = action.payload;
        },
        setDp: (state, action)=>{
            state.dp = action.payload;
        },
        setLoggedInUser: (state, action) => {
            state.loggedIn = action.payload
        },
        setSuccessAlert: (state, action)=>{
            state.successAlert = action.payload;
        }  
    },
    extraReducers: {
        [companyRotationDataThunk.pending]: state => {
            state.loading = true;
            return state;
        },
        [companyRotationDataThunk.fulfilled]: (state, action) => {
            state.loading = false;
            state.rotationData = action.payload
            return state;
        },
        [companyRotationDataThunk.rejected]: state => {
            state.loading = false;
            return state;
        },
        [adminUnBlockDaysAsync.pending]: state => {
            state.loading = true;
            return state;
        },
        [adminUnBlockDaysAsync.fulfilled]: (state) => {
            state.loading = false;
            return state;
        },
        [adminUnBlockDaysAsync.rejected]: state => {
            state.loading = false;
            return state;
        },
        [unBlockDaysAsync.pending]: state => {
            state.loading = true;
            return state;
        },
        [unBlockDaysAsync.fulfilled]: (state) => {
            state.loading = false;
            return state;
        },
        [unBlockDaysAsync.rejected]: state => {
            state.loading = false;
            return state;
        },
        [blockDaysAsync.pending]: state => {
            state.loading = true;
            return state;
        },
        [blockDaysAsync.fulfilled]: (state) => {
            state.loading = false;
            return state;
        },
        [blockDaysAsync.rejected]: state => {
            state.loading = false;
            return state;
        },
        [finishTourThunk.pending]: state => {
            state.loading = true;
            return state;
        },
        [finishTourThunk.fulfilled]: (state) => {
            state.loading = false;
            return state;
        },
        [finishTourThunk.rejected]: state => {
            state.loading = false;
            return state;
        },
        [getUnavailableAsync.pending]: state => {
            state.loading = true;
            return state;
        },
        [getUnavailableAsync.fulfilled]: (state, action) => {
            state.loading = false;
            if (Array.isArray(action.payload)){
                state.unavailableDays = action.payload
                } else {
                state.unavailableDays = action.payload.unavailableDays;
                state.memberships = action.payload.memberships;
                
            }
            return state;
        },
        [getUnavailableAsync.rejected]: state => {
            state.loading = false;
            return state;
        },
        [getCityAsync.pending]: state => {
            state.loading = true;
            return state;
        },
        [getCityAsync.fulfilled]: (state, action) => {
            state.loading = false;
            state.cities = action.payload.data
            return state;
        },
        [getCityAsync.rejected]: state => {
            state.loading = false;
            return state;
        },
        [setCompanyLogoAsync.pending]: state => {
            state.loading = true;
            return state;
        },
        [setCompanyLogoAsync.fulfilled]: state => {
            state.loading = false;
            return state;
        },
        [setCompanyLogoAsync.rejected]: state => {
            state.loading = false;
            return state;
        },
        [getReportAsync.pending]: (state) => {
            state.loading = true;
            return state
          },
          [getReportAsync.fulfilled]: (state, action) => {
            state.reports = action.payload
            state.loading = false;
            return state
          },
          [getReportAsync.rejected]: (state) => {
            state.loading = false;
            return state
          },
    }
});

export const {setActiveCompany, setCompanies, setDp, setSuccessAlert, setLoggedInUser} = CompanySlice.actions;

export default CompanySlice.reducer;