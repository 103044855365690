import {format} from 'date-fns'

const categorizeWriteups = (writeup) => {
  const category = [];
  writeup?.map((item) => {
    const dateConvert = format(new Date(item.createdOn), 'dd MMM yyyy');
    const todayDate = format(new Date(), 'dd MMM yyyy');
    const checkIfToday = dateConvert === todayDate ? "Today": dateConvert;
    if(category[checkIfToday]){
      category[checkIfToday] = [...category[checkIfToday], item];
    } else {
      category[checkIfToday] = [item];
    }

    return item
  })
  return category;
}

export default categorizeWriteups;