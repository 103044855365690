import React, { useState, useEffect } from 'react';
import { HomeNavbar } from '../../Widgets/HomeNavbar';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import { TaskTabs } from '../TaskScreen/Widget/TaskTabs/TaskTabs';
import { SideBar } from '../../Widgets/SideBar';
import { BsSearch } from 'react-icons/bs';
import Task from '../TaskScreen/Tabs/Task/Task';
import { useLocation } from 'react-router';
import { TaskClass } from '../../../Network/ServiceClass/Task';
import { Loader } from '../../Widgets/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskStatus } from '../../../Store/Task';
import { Overdue } from '../TaskScreen/Tabs/Overdue/Overdue';
import AppLayout from '../../Widgets/AppLayout';
import SearchInput from '../../Widgets/SearchInput';
import {ReactComponent as BackArrow} from '../../../Assets/back_arrow.svg'
import { Link } from 'react-router-dom';

const BoardScreen = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.task);
  const [index, setIndex] = useState(0);
  const location = useLocation();
  const taskClass = new TaskClass();

  const [boardData, setBoardData] = useState([]);
  const [isfetching, setFetching] = useState(true);
  const [searchedTask, setSearchedTask] = useState('');

  const callback = () => {
    taskClass
      .getBoard(location.state.id)
      .then((value) => {
        dispatch(getTaskStatus(value.data));
        setBoardData(value.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
      });
  };

  useEffect(() => {
    callback();
  }, []);

  const layout = {
    0: <Task searchedTask={searchedTask} tasks={boardData} />,
    1: (
      <Overdue
        theme={{
          overdue: {
            p: '#D7D7D7',
            s: '#C1C1C1',
          },
        }}
        searchedTask={searchedTask}
        tasks={boardData}
      />
    ),
  };

  const handleOnChangeTask = (e) => {
    setSearchedTask(e.target.value);
  }

  return (
    <section className="homepage">
       <AppLayout
        custom="company"
        subtitle=""
        leftComponent={false}
        mainClass="bg-5F5"
      >
        <Link to="/tasks">
        <div className="flex cursor-pointer mb-5 items-center gap-3">
          <BackArrow />
          <p className="text-base text-888 dark:text-ABA">Board</p>
        </div>
        </Link>
        <p className="mb-10 font-bold text-18 text-color-333 dark:text-white">Tasks</p>
        <TaskTabs index={index} setIndex={setIndex} />

        <div
          className="flex items-center"
          style={{ marginLeft: '-2px', marginTop: '4vh' }}
        >
        <SearchInput
        className="bg-white border-1 border-5A5"
          placeholder="Search task"
          onChange={handleOnChangeTask}
        />
         
        </div>

        {!store.loading ? (
          <div style={{ width: '100%' }}>{layout[index]}</div>
        ) : (
          <Loader />
        )}
      </AppLayout>
      {/* <HomeNavbar />
      <SideBar>
        <section className="overview">
          <BranchesHeader
            title="Task board"
            subtitle="Manage and regulate tasks"
          />
        </section>
      </SideBar> */}
    </section>
  );
};

export default BoardScreen;
