import React from 'react';
import './TaskLayout.css';

const TaskLayout = ({children}) => {
  return (
    <section className='task-layout'>
      {children}
    </section>
  )
}

export default TaskLayout