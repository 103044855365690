import { useState, useEffect, useRef } from "react"
import { CliveSendIcon } from "../../../Assets/Svg/Index"
import { useDispatch, useSelector } from "react-redux"
import { askChatGptThunk } from "../../../Store/ChatGpt"
import { CircularProgress } from "@mui/material"
import { CustomIconButton } from "../../Widgets/FormUtils/FormUtils"
import chatAvatar from '../../../Assets/chat-avatar.png'
import { toast } from 'react-toastify'
import {ReactComponent as LightChatMode } from '../../../Assets/icons/chat_light.svg'
import {ReactComponent as DarkChatMode } from '../../../Assets/chat_dark.svg'
import {ReactComponent as DarkMicMode } from '../../../Assets/mic_dark.svg'
import {ReactComponent as LightMicMode } from '../../../Assets/mic_light.svg'
import {ReactComponent as LightSend } from '../../../Assets/send_light.svg'
import {ReactComponent as DarkSend } from '../../../Assets/send_dark.svg'
import VoiceInput from '../../Widgets/voice'

const Chat = ({...props}) => {
  const mode = localStorage.getItem("color-theme") ?? 'light'
  const [text, setText] = useState('')

  const handleStopListening = () => {
		stopListening();
		handleAskChatGpt();
	};

  const { startRecognition, listening, stopListening } = VoiceInput(
		setText,
		handleStopListening
		// doneStreaming
	);

   const dispatch = useDispatch()
    const store = useSelector(state => state.chatGpt)
    const [reserveData, setReserveData] = useState(store.chat)
   const mainContainerRef = useRef(null);
    const dp = useSelector((state) => state.company.dp);

    useEffect(()=>{
      setReserveData(store.chat)
    },[store.chat])

    
    const [showSendIcon, setShowSendIcon] = useState(false)

    const handleChange = (event) => {
        setText(event.target.value);
        if (event.target.value.length >= 1) setShowSendIcon(true) 
        else setShowSendIcon(false)
    };

    const handleTextareaResize = (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + 'px';
    };

    useEffect(()=>{
      if (mainContainerRef.current) {
        mainContainerRef.current.scrollTo({
          top: mainContainerRef.current.scrollHeight,
          behavior: 'smooth', // You can use 'auto' for instant scrolling
        });
      }
    },[text])



    const handleAskChatGpt = () => {
        setText('')
        setReserveData([...reserveData, {ans: false, msg:text}])

        dispatch(askChatGptThunk({sentence: text}))
        .then(val => {

          if(val.meta.requestStatus === "rejected"){
            const errorMsg = JSON.parse(val.error.message)
            toast.error(errorMsg.error)
            return
          }
          if (mainContainerRef.current) {
            mainContainerRef.current.scrollTo({
              top: mainContainerRef.current.scrollHeight,
              behavior: 'smooth', // You can use 'auto' for instant scrolling
            });
          }
        })
    }


  return (
        <div id="main"  className=" py-10 h-full overflow-y-scroll dark:bg-828" {...props}>

          <div ref={mainContainerRef} className='flex flex-col gap-2  text-[14px] overflow-y-scroll overflow-x-hidden w-[600px] flex-2 '>


          {!store.loading && store?.chat?.length > 0 &&
          <div className=' max-h-[100%] text-[14px] font-semibold'>
              {store?.chat.map((val, index) => 
                  <div
                  style={{paddingBottom: index == (store.chat.length - 1) ? '50px': ''}}
                    className={`flex flex-col ${index !== 0 ? 'mt-10' : ''} px-8`}>
                  
                  <div className="flex gap-4 justify-end items-center">
                    <p className='min-h-[41px] text-right p-2 flex items-center leading-4'>
                      {val?.msg}
                    </p>

                    <img src={dp ? dp?.url : chatAvatar} alt="user_pix" className="w-[34px] h-[34px] border-1 dark:border-A1A rounded-full object-fill"/>
                    

                  </div>

                    <p className='mt-[20px] whitespace-pre-wrap pr-3 text-left dark:bg-A06 leading-4'>
                        {val?.ans}
                    </p>

              </div>
              )}
          </div>}
          {!store.loading && store?.chat?.length === 0 &&
          <div className=' flex justify-center mt-[33%] !overflow-hidden'>
             {mode === "light" ? <LightChatMode /> : <DarkChatMode />}
          </div>}
          {store.loading && <div className='w-full max-h-[100%] text-[14px] font-semibold'>
            {reserveData.map((val, index) => 
                <div 
                style={{paddingBottom: index == (reserveData.length - 1) ? '50px': ''}}
                  className={`flex flex-col ${index !== 0 ? 'mt-10' : ''} px-8`}>

                  <div className="flex gap-4 justify-end items-center">
                    <p className='min-h-[41px] text-right p-2 flex items-center leading-4'>
                      {val.msg}
                    </p>

                    <img src={dp ? dp?.url : chatAvatar} alt="user_pix" className="w-[34px] h-[34px] border-1 dark:border-A1A rounded-full object-fill"/>
                    

                  </div>

                    {val.ans === false ?
                      <div className='w-[50px] mt-5'><CircularProgress className='text-[#9ee6e1]' size={28}/></div>
                    :
                    <p className='mt-[20px] whitespace-pre-wrap text-black flex items-center min-h-[41px] px-4 py-2 text-ellipsis leading-4 pr-3'>
                      {val.ans}
                    </p>
                    }
                
            </div>
            )}
        </div>}


          

          </div>

            <div className='sticky h-full bottom-[2px]  ml-8 min-h-[100px] w-[90%] flex flex-col  flex-1 justify-end dark:bg-transparent'>
              <div className="flex items-center gap-3">
                <div className='flex items-center grow bg-5F5 mb-2 dark:bg-black px-3 min-h-[65px] dark:text-white rounded-lg '>
                    <textarea 
                      className="max-h-[300px] w-[95%] bg-5F5 dark:bg-black focus:border-transparent focus:outline-none   resize-none"
                      placeholder={listening ? "Listening....." : "Ask Me Something"}

                      value={text}
                      onChange={handleChange}
                      onInput={handleTextareaResize}
                      onKeyDown={(event)=>{
                        if (event.key === 'Enter' && !event.shiftKey) {
                          event.preventDefault();
                          if (text.length >= 1) handleAskChatGpt()
                          
                        }
                      }}
                    rows={1}/>
                    <div className=" flex items-center gap-2">
                      {mode === "light" ? <LightSend  
                      disable={!showSendIcon || store.loading}
                      onClick={handleAskChatGpt}

                      /> : <DarkSend 
                      disable={!showSendIcon || store.loading}
                      onClick={handleAskChatGpt}
                      /> 
                    }
                    </div>
                
                </div>
                      <div onClick={listening
															? handleStopListening
															: startRecognition}>
                        {mode === "light" ? <LightMicMode />: <DarkMicMode /> }
                      </div>
            </div>
              </div>

        </div>

  )
}

export default Chat