import { Stack } from "@mui/material";
import FormModal from "../Animation/FormModal";
import {ReactComponent as TourOnboarding} from '../../../Assets/Svg/tour_onboarding.svg'
import './style.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '789px',
    height: '670px',
    maxHeight: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };
  
export function TourModal({open = false, handleClose, handleTour}){
    return (
        <FormModal open={open} handleClose={handleClose}>
            <Stack sx={style} direction="column">
                <div>
                    <div className="p-[50px] pb-0 bg-tour">
                        <TourOnboarding />
                    </div>
                    <div className="p-[50px] pb-0 mt-[-23%] bg-white z-50 !font-jakarta relative">
                        <p className="font-semibold text-[32px] !font-jakarta">Welcome to SuperSync</p>
                        <p className="text-060 text-xl !font-jakarta">Efficiently manage employees and promote productivity. Also take control of your workforce management and scheduling tasks with our employee-centric app</p>
                        <div className="mt-10 flex items-center gap-4">
                            <div onClick={handleTour} className="bg-D57 cursor-pointer text-18 p-2 rounded-[40px]"><p className="text-white !font-jakarta">Take a tour</p></div>
                            <div onClick={handleClose}><p className="text-18 p-2 rounded-[40px] cursor-pointer !font-jakarta">Skip</p></div>
                        </div>
                    </div>
                </div>

            </Stack>
        </FormModal>
    )
}