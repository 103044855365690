export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Mulish',
    width: '450px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '4px',
    
    pt: 2,
    px: 4,
    pb: 3,
    padding:'30px 0px',
    // scrollY

};