
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { 
  getAllItems, 
  getInventoryGroups, 
  createInventoryGroup, 
  confirmOrder, 
  getInventoryGroupItem, 
  getAnItem, 
  transferItems,
  getInventorySuppliers,
  addInventorySuppliers,
  editInventorySuppliers, 
  createInventoryItem,
  deletInventorySuppliers,
  getInventoryOrderRequest, 
  getInventoryBackOrderRequest,
  getInventoryWithdrawalLogs,
  getInventoryManufacturers,
  addInventoryManuAndSuppliers,
  getInventoryMeasurementUnit,
  addInventoryMeasurementUnit,
  stockupInventoryItem,
  stockupInventoryTransferItem,
  getInventoryPendingOrders,
  getInventoryPendingTransferApproval,
  getInventoryLogs,
  getInventoryItemsBelowCriticalLimit,

  confirmQuickWithdrawal,
  addInventoryManufacturers,
  editInventoryManufacturer,
  deleteInventoryManufacturer,
  fetchCurrentItems,
  createMetaInventoryItem,
  getTransferItemsRequest,

  inventoryUpdateTrackingDetails,
  getInventorySingleOrderRequest,
  getInventoryDataWithdrawals,
  getInventoryDataUsage,
  getInventoryExpiredItems,
  trackItemUsage,
  getInventoryItemsForecast, 
  getInventoryItemsUtilityRate,
  getInventoryItemsFuturePurchase

} from '../Network/ServiceClass/InventoryService';

// thunk
 export const getAllItemsThunk = createAsyncThunk('inventory/getAllItems', getAllItems);
 export const getAnItemThunk = createAsyncThunk('inventory/getAnItem', getAnItem);
 export const getInventoryGroupsThunk = createAsyncThunk('inventory/getInventoryGroups', getInventoryGroups);
 export const createInventoryGroupsThunk = createAsyncThunk('inventory/createInventoryGroups', createInventoryGroup);
 export const getInventoryGroupItemThunk = createAsyncThunk('inventory/getInventoryGroupItem', getInventoryGroupItem);
 export const confirmOrderThunk = createAsyncThunk('inventory/confirmOrder', confirmOrder);

 export const getInventorySuppliersThunk = createAsyncThunk('inventory/getInventorySuppliers', getInventorySuppliers);
 export const addInventorySuppliersThunk = createAsyncThunk('inventory/addInventorySuppliers', addInventorySuppliers);
 export const editInventorySuppliersThunk = createAsyncThunk('inventory/editInventorySuppliers', editInventorySuppliers);
 export const deletInventorySuppliersThunk = createAsyncThunk('inventory/deletInventorySuppliers', deletInventorySuppliers);

 export const addInventoryManufacturersThunk = createAsyncThunk('inventory/addInventoryManufacturers', addInventoryManufacturers);
 export const editInventoryManufacturerThunk = createAsyncThunk('inventory/editInventoryManufacturer', editInventoryManufacturer);
 export const deleteInventoryManufacturerThunk = createAsyncThunk('inventory/deleteInventoryManufacturer', deleteInventoryManufacturer);

 export const getInventoryOrderRequestThunk = createAsyncThunk('inventory/getInventoryOrderRequest', getInventoryOrderRequest);
 export const getInventorySingleOrderRequestThunk = createAsyncThunk('inventory/getInventorySingleOrderRequest', getInventorySingleOrderRequest);

 export const getInventoryBackOrderRequestThunk = createAsyncThunk('inventory/getInventoryBackOrderRequest', getInventoryBackOrderRequest);


 export const transferItemThunk = createAsyncThunk('inventory/transferItems', transferItems);
 export const getTransferItemsRequestThunk = createAsyncThunk('inventory/getTransferItemsRequest', getTransferItemsRequest);

 export const createInventoryItemThunk = createAsyncThunk('inventory/createInventoryItemThunk', createInventoryItem);
 export const getInventoryWithdrawalLogsThunk = createAsyncThunk('inventory/getInventoryWithdrawalLogs', getInventoryWithdrawalLogs)
 export const createInventoryItemMetaThunk = createAsyncThunk('inventory/createInventoryItemMetaThunk', createMetaInventoryItem);
 
 export const getInventoryManufacturersThunk = createAsyncThunk('inventory/getInventoryManufacturers', getInventoryManufacturers)
 export const addInventoryManAndSuppThunk = createAsyncThunk('inventory/addInventoryManAndSupp', addInventoryManuAndSuppliers)
 export const getInventoryMeasurementUnitThunk = createAsyncThunk('inventory/getInventoryMeasurementUnit', getInventoryMeasurementUnit)
 export const addInventoryMeasurementUnitThunk = createAsyncThunk('inventory/addInventoryMeasurementUnit', addInventoryMeasurementUnit)

 export const stockupInventoryItemThunk = createAsyncThunk('inventory/stockInventoryItem', stockupInventoryItem)
 export const stockupInventoryTransferItemThunk = createAsyncThunk('inventory/stockupInventoryTransferItem', stockupInventoryTransferItem)
 export const getInventoryItemsForecastThunk = createAsyncThunk('inventory/getInventoryItemsForecast', getInventoryItemsForecast)
 export const getInventoryItemsUtilityRateThunk = createAsyncThunk('inventory/getInventoryItemsUtilityRate', getInventoryItemsUtilityRate)
 export const getInventoryItemsFuturePurchaseThunk = createAsyncThunk('inventory/getInventoryItemsFuturePurchase', getInventoryItemsFuturePurchase)

 

 export const getInventoryPendingOrdersThunk = createAsyncThunk('inventory/getInventoryPendingOrders', getInventoryPendingOrders);
 export const getInventoryPendingTransferApprovalThunk = createAsyncThunk('inventory/getInventoryPendingTransferApproval', getInventoryPendingTransferApproval);
 export const getInventoryLogsThunk = createAsyncThunk('inventory/getInventoryLogs', getInventoryLogs);
 export const getInventoryItemsBelowCriticalLimitThunk = createAsyncThunk('inventory/getInventoryItemsBelowCriticalLimit', getInventoryItemsBelowCriticalLimit);
 export const getInventoryDataWithdrawalsThunk = createAsyncThunk('inventory/getInventoryDataWithdrawals', getInventoryDataWithdrawals);
 export const getInventoryDataUsageThunk = createAsyncThunk('inventory/getInventoryDataUsage', getInventoryDataUsage);
 export const getInventoryExpiredItemsThunk = createAsyncThunk('inventory/getInventoryExpiredItems', getInventoryExpiredItems);



 export const confirmQuickWithdrawalThunk = createAsyncThunk('inventory/confirmQuickWithdrawal', confirmQuickWithdrawal)
 export const fetchCurrentItemsThunk = createAsyncThunk('inventory/fetchCurrentItems', fetchCurrentItems)
 
 export const inventoryUpdateTrackingDetailsThunk = createAsyncThunk('inventory/inventoryUpdateTrackingDetails', inventoryUpdateTrackingDetails)
 
 export const inventoryTrackItemUsageThunk = createAsyncThunk('inventory/trackItemUsage', trackItemUsage)

const InventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    items: [],
    groups: [],
    suppliers: [],
    orderRequest: [],
    backOrderRequest: [],
    loading: false,
    loadingItems: true,
    loadingForcast: true,
    loadingFuturePurchase: true,
    loadingAddUnit: false,
    item: {},
    withdrawalLogs: [],
    transferRequestItems: [],
    itemsForecast: [],
    itemsFuturePurchase: [],
    utilityRate: [],
    manufacturers: [],
    measurementUnits: [],
    pendingOrders: [],
    loadingPendingOrders: true,
    pendingTransferApproval: [],
    loadingPendingTransferApproval: true,
    loadingCriticalLimit: true,
    criticalLimit: [],
    loadingDataWithdrawals: true,
    dataWithdrawals: [],
    loadingDataUsage: true,
    dataUsage: [],
    loadingExpiredItems: true,
    expiredItems: [],
    logs: [],
    loadingLog: true,
    currentInventoryItem: [],
    loadingUpdateTracking : false,
    singleOrderRequest: []

  },
  extraReducers: {
    [inventoryTrackItemUsageThunk.pending]: (state) => {
      state.loading = true
    },
    [inventoryTrackItemUsageThunk.fulfilled]: (state, action) => {
      state.loading = false
    },
    [inventoryTrackItemUsageThunk.rejected]: (state) => {
      state.loading = false
    },
    [createInventoryItemMetaThunk.pending]: (state) => {
      state.loading = true
    },
    [createInventoryItemMetaThunk.fulfilled]: (state, action) => {
      state.loading = false
    },
    [createInventoryItemMetaThunk.rejected]: (state) => {
      state.loading = false
    },
    [fetchCurrentItemsThunk.pending]: (state) => {
      state.loading = true
    },
    [fetchCurrentItemsThunk.fulfilled]: (state, action) => {
      state.currentInventoryItem = action.payload
      state.loading = false
    },
    [fetchCurrentItemsThunk.rejected]: (state) => {
      state.loading = false
    },
   [getInventoryMeasurementUnitThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventoryMeasurementUnitThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.measurementUnits = action.payload || []
    return state
   },
   [getInventoryMeasurementUnitThunk.rejected]: (state) => {
    state.loading = false;
   },
   [getInventoryWithdrawalLogsThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventoryWithdrawalLogsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.withdrawalLogs = action.payload.data || []
    return state
   },
   [getInventoryWithdrawalLogsThunk.rejected]: (state) => {
    state.loading = false;
   },
   [stockupInventoryItemThunk.pending]: (state) => {
    state.loading = true
   },
   [stockupInventoryItemThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [stockupInventoryItemThunk.rejected]: (state, action) => {
    state.loading = false;
   },

   [stockupInventoryTransferItemThunk.pending]: (state) => {
    state.loading = true
   },
   [stockupInventoryTransferItemThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [stockupInventoryTransferItemThunk.rejected]: (state, action) => {
    state.loading = false;
   },

   [addInventoryManAndSuppThunk.pending]: (state) => {
    state.loading = true
   },
   [addInventoryManAndSuppThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [addInventoryManAndSuppThunk.rejected]: (state) => {
    state.loading = false;
   },
   [confirmQuickWithdrawalThunk.pending]: (state) => {
    state.loading = true
   },
   [confirmQuickWithdrawalThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [confirmQuickWithdrawalThunk.rejected]: (state) => {
    state.loading = false;
   },
   [createInventoryItemThunk.pending]: (state) => {
    state.loading = true
   },
   [createInventoryItemThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [createInventoryItemThunk.rejected]: (state) => {
    state.loading = false;
   },
   [createInventoryGroupsThunk.pending]: (state) => {
    state.loading = true
   },
   [createInventoryGroupsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [createInventoryGroupsThunk.rejected]: (state) => {
    state.loading = false;
   },

   [transferItemThunk.pending]: (state) => {
    state.loading = true
    return state
   },
   [transferItemThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [transferItemThunk.rejected]: (state) => {
    state.loading = false;
    return state
   },

   [confirmOrderThunk.pending]: (state) => {
    state.loading = true
    return state
   },
   [confirmOrderThunk.fulfilled]: (state, action) => {
    state.loading = false;
    return state
   },
   [confirmOrderThunk.rejected]: (state) => {
    state.loading = false;
    return state
   },

   [getAnItemThunk.pending]: (state) => {
    state.loading = true
   },
   [getAnItemThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.item = action.payload.data
    return state
   },
   [getAnItemThunk.rejected]: (state) => {
    state.loading = false;
   },

   [getAllItemsThunk.pending]: (state) => {
    state.loading = true
   },
   [getAllItemsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.items = action.payload.data
    return state
   },
   [getAllItemsThunk.rejected]: (state) => {
    state.loading = false;
   },

   [getInventoryGroupsThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventoryGroupsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.groups = action.payload.data
    return state
   },
   [getInventoryGroupsThunk.rejected]: (state) => {
    state.loading = false;
   },


   [getInventoryGroupItemThunk.pending]: (state) => {
    state.loadingItems = true
    return state
   },
   [getInventoryGroupItemThunk.fulfilled]: (state, action) => {
    state.loadingItems = false
    return state
   },
   [getInventoryGroupItemThunk.rejected]: (state) => {
    state.loadingItems = false
    return state
   },
   
   [getInventoryManufacturersThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventoryManufacturersThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.manufacturers = action.payload
    return state
   },
   [getInventoryManufacturersThunk.rejected]: (state) => {
    state.loading = false;
   },
   
   [getInventorySuppliersThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventorySuppliersThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.suppliers = action.payload.data
    return state
   },
   [getInventorySuppliersThunk.rejected]: (state) => {
    state.loading = false;
   },

   [addInventorySuppliersThunk.pending]: (state) => {
    state.loading = true
    return state
   },
   [addInventorySuppliersThunk.fulfilled]: (state, action) => {
    if (action?.payload?.status === 201) {
      state.suppliers = [action.payload?.data, ...state.suppliers]
    }
    state.loading = false;
    return state
   },
   [addInventorySuppliersThunk.rejected]: (state) => {
    state.loading = false;
    return state
   },

   [addInventoryManufacturersThunk.pending]: (state) => {
    state.loading = true
    return state
   },
   [addInventoryManufacturersThunk.fulfilled]: (state, action) => {
    if (action?.payload?.status === 201) {
      state.manufacturers = [action.payload?.data, ...state.manufacturers]
    }
    state.loading = false;
    return state
   },
   [addInventoryManufacturersThunk.rejected]: (state) => {
    state.loading = false;
    return state
   },

   [editInventorySuppliersThunk.pending]: (state) => {
    return state
   },
   [editInventorySuppliersThunk.fulfilled]: (state, action) => {
    return state
   },
   [editInventorySuppliersThunk.rejected]: (state) => {
    return state
   },

   [editInventoryManufacturerThunk.pending]: (state) => {
    return state
   },
   [editInventoryManufacturerThunk.fulfilled]: (state, action) => {
    return state
   },
   [editInventoryManufacturerThunk.rejected]: (state) => {
    return state
   },
   

   [deletInventorySuppliersThunk.pending]: (state) => {
    return state
   },
   [deletInventorySuppliersThunk.fulfilled]: (state, action) => {
    return state
   },
   [deletInventorySuppliersThunk.rejected]: (state) => {
    return state
   },

   [deleteInventoryManufacturerThunk.pending]: (state) => {
    return state
   },
   [deleteInventoryManufacturerThunk.fulfilled]: (state, action) => {
    return state
   },
   [deleteInventoryManufacturerThunk.rejected]: (state) => {
    return state
   },


   [getInventoryOrderRequestThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventoryOrderRequestThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.orderRequest = action.payload.data
    return state
   },
   [getInventoryOrderRequestThunk.rejected]: (state) => {
    state.loading = false;
   },

   [getInventoryBackOrderRequestThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventoryBackOrderRequestThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.backOrderRequest = action.payload.data
    return state
   },
   [getInventoryBackOrderRequestThunk.rejected]: (state) => {
    state.loading = false;
   },

   [getInventoryPendingOrdersThunk.pending]: (state) => {
    state.loadingPendingOrders = true
   },
   [getInventoryPendingOrdersThunk.fulfilled]: (state, action) => {
    state.loadingPendingOrders = false;
    state.pendingOrders = action.payload?.data?.message || []
    return state
   },
   [getInventoryPendingOrdersThunk.rejected]: (state) => {
    state.loadingPendingOrders = false;
   },

   [getInventoryPendingTransferApprovalThunk.pending]: (state) => {
    state.loadingPendingTransferApproval = true
   },
   [getInventoryPendingTransferApprovalThunk.fulfilled]: (state, action) => {
    state.loadingPendingTransferApproval = false;
    state.pendingTransferApproval = action.payload?.data?.message || []
    return state
   },
   [getInventoryPendingTransferApprovalThunk.rejected]: (state) => {
    state.loadingPendingTransferApproval = false;
   },

   [getInventoryItemsBelowCriticalLimitThunk.pending]: (state) => {
    state.loadingCriticalLimit = true
   },
   [getInventoryItemsBelowCriticalLimitThunk.fulfilled]: (state, action) => {
    state.loadingCriticalLimit = false;
    state.criticalLimit = action.payload?.data?.message || []
    return state
   },
   [getInventoryItemsBelowCriticalLimitThunk.rejected]: (state) => {
    state.loadingCriticalLimit = false;
   },

   [getInventoryDataWithdrawalsThunk.pending]: (state) => {
    state.loadingDataWithdrawals = true
   },
   [getInventoryDataWithdrawalsThunk.fulfilled]: (state, action) => {
    state.loadingDataWithdrawals = false;
    state.dataWithdrawals = action.payload?.data || []
    return state
   },
   [getInventoryDataWithdrawalsThunk.rejected]: (state) => {
    state.loadingDataWithdrawals = false;
   },

   [getInventoryDataUsageThunk.pending]: (state) => {
    state.loadingDataUsage = true
   },
   [getInventoryDataUsageThunk.fulfilled]: (state, action) => {
    state.loadingDataUsage = false;
    state.dataUsage = action.payload?.data || []
    return state
   },
   [getInventoryDataUsageThunk.rejected]: (state) => {
    state.loadingDataUsage = false;
   },

   [getInventoryExpiredItemsThunk.pending]: (state) => {
    state.loadingExpiredItems = true
   },
   [getInventoryExpiredItemsThunk.fulfilled]: (state, action) => {
    state.loadingExpiredItems = false;
    state.expiredItems = action.payload?.data || []
    return state
   },
   [getInventoryExpiredItemsThunk.rejected]: (state) => {
    state.loadingExpiredItems = false;
   },

   [getInventoryLogsThunk.pending]: (state) => {
    state.loadingLog = true
   },
   [getInventoryLogsThunk.fulfilled]: (state, action) => {
    state.loadingLog = false;
    state.logs = action.payload?.data?.message || []
    return state
   },
   [getInventoryLogsThunk.rejected]: (state) => {
    state.loadingLog = false;
   },

   [getTransferItemsRequestThunk.pending]: (state) => {
    state.loading = true
   },
   [getTransferItemsRequestThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.transferRequestItems = action.payload.data || []
    return state
   },
   [getTransferItemsRequestThunk.rejected]: (state) => {
    state.loading = false;
   },

   [getInventoryItemsForecastThunk.pending]: (state) => {
    state.loadingForcast = true
   },
   [getInventoryItemsForecastThunk.fulfilled]: (state, action) => {
    state.loadingForcast = false;
    state.itemsForecast = action.payload.data.reverse() || []
    return state
   },
   [getInventoryItemsForecastThunk.rejected]: (state) => {
    state.loadingForcast = false;
   },

   [getInventoryItemsFuturePurchaseThunk.pending]: (state) => {
    state.loadingFuturePurchase = true
   },
   [getInventoryItemsFuturePurchaseThunk.fulfilled]: (state, action) => {
    state.loadingFuturePurchase = false;
    state.itemsFuturePurchase = action.payload.data?.data || []
    return state
   },
   [getInventoryItemsFuturePurchaseThunk.rejected]: (state) => {
    state.loadingFuturePurchase = false;
   },

   [getInventoryItemsUtilityRateThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventoryItemsUtilityRateThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.utilityRate = action.payload.data.reverse() || []
    return state
   },
   [getInventoryItemsUtilityRateThunk.rejected]: (state) => {
    state.loading = false;
   },

   [inventoryUpdateTrackingDetailsThunk.pending]: (state) => {
    state.loadingUpdateTracking = true
   },
   [inventoryUpdateTrackingDetailsThunk.fulfilled]: (state, action) => {
    state.loadingUpdateTracking = false;
    return state
   },
   [inventoryUpdateTrackingDetailsThunk.rejected]: (state) => {
    state.loadingUpdateTracking = false;
   },

   [getInventorySingleOrderRequestThunk.pending]: (state) => {
    state.loading = true
   },
   [getInventorySingleOrderRequestThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.singleOrderRequest = action.payload.data || []
    return state
   },
   [getInventorySingleOrderRequestThunk.rejected]: (state) => {
    state.loading = false;
   },
   
   [addInventoryMeasurementUnitThunk.pending]: (state) => {
    state.loadingAddUnit = true
   },
   [addInventoryMeasurementUnitThunk.fulfilled]: (state, action) => {
    state.loadingAddUnit = false;
    return state
   },
   [addInventoryMeasurementUnitThunk.rejected]: (state) => {
    state.loadingAddUnit = false;
   },
   
  }
})


// export const { selectedTask, getTaskStatus, setTaskLoading } = InventorySlice.actions;

export default InventorySlice.reducer;