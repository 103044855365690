import React from 'react';
import Modal from '@mui/material/Modal';
import moment from 'moment';

function EditAvailability({
  handleClose,
  open,
  date,
  data,
  handleSubmit,
  memberships,
  loading = false,
}) {

  const getUnavailableDetaiks = (eachData) => {
    const [eachMember] = memberships.filter((x) => x.id === eachData?.teamMembershipId)
    return eachMember;
  }
  
  return (
    <Modal 
    onClose={handleClose}
    open={open}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
    >
      <div className="h-screen flex items-center justify-center">
        <div className="bg-white w-[533px] px-[73px] py-[59px] text">
          <p className="font-semibold text-lg text-center mb-11">Edit</p>
          <p className="font-semibold text-sm text-left mb-[51px]">
            Below is the list of team members not available on{' '}
            {moment(date).format('MMMM Do')}
          </p>
          <div className="">
            {React.Children.toArray(
              data?.map((unavailData) => (
                <div className="flex items-start justify-between mb-[18px]">
                  <div>
                    <p className="font-semibold text-sm">
                      {unavailData?.user?.firstName}{' '}
                      {unavailData?.user?.lasttName}
                    </p>
                    <div className="flex items-center gap-3">
                      <p className="font-semibold text-sm text-080">{getUnavailableDetaiks(unavailData)?.jobrole || "N/A"}</p>
                      <div style={{ backgroundColor: getUnavailableDetaiks(unavailData)?.hexcode !== "NULL" ? getUnavailableDetaiks(unavailData)?.hexcode : "white" }} className={`w-2 h-2 rounded-full`} />
                    </div>
                  </div>
                  <p
                    onClick={() =>
                      handleSubmit(moment(date).format('yyyy-MM-DD'), unavailData?.companyId, unavailData?.userId)
                    }
                    className="text-xs text-080 font-semibold cursor-pointer"
                  >
                    Make Available
                  </p>
                </div>
              ))
            )}

            {data?.length === 0 && (
              <div className="h-[200px] flex items-center justify-center">
                <p>No Block days available</p>
              </div>
            )}
          </div>
          <button
            onClick={loading ? null : handleClose}
            className="w-full bg-black text-white rounded mt-[95px] h-[40px] text-base font-semibold"
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditAvailability;
