import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as DownIcon } from '../../Assets/down-arrow.svg';
import Proptypes from 'prop-types';
import InitialsGen from './InitialsGen';

const UserDropdown = ({
  value = '',
  className = '',
  defaultLabel = { value: 'Select', label: 'select' },
  onChangeOption,
  data,
  showAvatar = true,
  headerClass = '',
  zIndex = 'z-10',
}) => {
  const [openDropdown, setDropdown] = React.useState(false);
  const defaultValue =
    value?.length > 0
      ? data.filter((x) => x.value === value)[0]
      : { label: defaultLabel.label, value: defaultLabel.value };
  const [assigneSelected, setAssignee] = React.useState(defaultValue);

  const handleOpenDropDown = () => {
    setDropdown((prev) => !prev);
  };

  const handleSelectedOption = (val) => {
    onChangeOption(val);
    setAssignee(val);
    setDropdown(false);
  };

  const handleCloseDropdown = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <div className="relative">
        <div
          onClick={handleOpenDropDown}
          className={`flex justify-between items-center border-1 border-6E6 rounded-[5px] py-2 pt-[4px] px-[10px] ${className}`}
        >
          <div
            className={`px-[10px] py-[5px] flex items-center cursor-pointer`}
          >
            {defaultValue?.value !== defaultLabel.value && showAvatar ? (
              <InitialsGen name={assigneSelected?.label} bgColor="blue" />
            ) : null}
            <p
              className={`text-[10px] font-medium p-2 rounded text-center ${headerClass} ${
                defaultValue?.label === defaultLabel?.label
                  ? 'text-080'
                  : 'text-black'
              }`}
            >
              {assigneSelected?.label}
            </p>
          </div>
          <DownIcon />
        </div>

        {openDropdown && (
          <div
            className={`shadow-select bg-white absolute w-full mt-4 max-h-[200px] overflow-scroll ${zIndex}`}
          >
            {data.map((item, index) => (
              <div
                key={index}
                onClick={() => handleSelectedOption(item)}
                className={`px-[10px] py-[5px] flex items-center hover:bg-5A5 hover:text-white cursor-pointer`}
              >
                {item.label && showAvatar && (
                  <InitialsGen name={item.label} bgColor="blue" />
                )}
                <p
                  className="text-sm font-medium p-2 rounded text-center"
                  style={{ backgroundColor: item.bgColor, color: item.color }}
                >
                  {item.label}
                </p>
              </div>
            ))}
            {
              data?.length === 0 && (<p className="text-sm font-medium p-2 rounded text-center">No items available</p>)
            }
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default UserDropdown;

UserDropdown.proptype = {
  priority: Proptypes.arrayOf({
    value: Proptypes.string.isRequired,
    label: Proptypes.string.isRequired,
    color: Proptypes.string.isRequired,
    bgColor: Proptypes.string.isRequired,
  }).isRequired,
  value: Proptypes.string.isRequired,
  onChangeOption: Proptypes.func.isRequired,
};
