import React,{useState, useEffect} from 'react';
import { SideBar } from '../../Widgets/SideBar';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import { Shift } from '../../../Network/ServiceClass/Shift'
import { useDispatch,useSelector } from 'react-redux';
import {setRequetsOffers} from '../../../Store/swapOffers'
import {CircularProgress,Stack} from '@mui/material';
import checkPmOrAm from '../../../utils/checkPmOrAm'
import BasicTabs from './ShiftForOfferView/Tabs';
import TemporaryDrawer from './ShiftForOfferView/Drawer';
import AlertDialog from '../../Widgets/SuccessDialog'
import './SwapOffers.css'
import AppLayout from '../../Widgets/AppLayout';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { swapOffersTutorial } from '../mock';

const shift = new Shift()

export const SwapOffers = () => {

    const dispatch = useDispatch()
    const swapRequesterState = useSelector((state)=> state.swapOffers.requestOffers)

    const [isLoading, setIsLoading] = useState(false)

   

    useEffect(()=>{
        setIsLoading(true)
        shift.fetchSwapRequest('pending').then((value)=>{
            setIsLoading(false)
            // console.log(value.data)
            if(value.status !== 200) throw new Error('Something went wrong')
            dispatch(setRequetsOffers(value.data))
        }).catch((error) => {
            setIsLoading(false)
            console.log(error)
        })
    },[])  


    // drawer functions
        const openDrawerState = useSelector((state)=> state.swapOffers.openDrawer)
    //end of toggle drawer
      
    return (
        <section className='homepage'>
            <AppLayout
                subCustom='swap-offers'
                custom='shift'
                customTitle=" "
                title='Shift offers' 
                subtitle='See shift swap and accept the one convenient for you'
                RightComponent={<TutorialPanel items={swapOffersTutorial} />}
            >
                    <AlertDialog/>
                    {openDrawerState && <TemporaryDrawer /> }

                    <div className='' >
                        {!isLoading && swapRequesterState.length > 0 && <div className='flex flex-col'>
                            <p className='text-[12px] text-[A5A5A5] dark:text-gray-100'>Your shifts up for swap</p>
                            <BasicTabs />
                        </div> }

                        {isLoading && <Stack direction='row' justifyContent='center' mt={10}>
                            <CircularProgress/>
                        </Stack> }

                        {(!isLoading && swapRequesterState.length === 0) && <p className='empty-data'>You don't have any requests yet! </p> }
                

                    </div>
            </AppLayout>
           
        </section>
    )
}
