import { SideBar } from "../../Widgets/SideBar"
import { BranchesHeader } from "../BranchScreen/BranchesHeader/BranchesHeader"
import React, { useState, useEffect } from "react";
import CustomDropdown from "../../Widgets/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getBranchesAsync } from "../../../Store/Branches";
import { getClockinClockoutShiftAsync } from "../../../Store/Shift";
import { Loader } from "../../Widgets/Loader";
import InitialsGen from "../../Widgets/InitialsGen";
import moment from "moment";
import NavigateCalender from '../ShiftScreen/Widget/NavigateCalender/NavigateCalender';
import GoogleMapView from '../BranchScreen/BranchTab/Modal/GoogleMapModal';
import { BsMap } from "react-icons/bs";
import AppLayout from "../../Widgets/AppLayout";
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { clockinClockoutTutorial } from '../mock';



export const ClockInClockOut = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const [selectedBranch, setBranch] = useState({
    label: 'All locations',
    value: '',
  });

  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );

  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];
  
    const clockShifts =
    useSelector((state) => state?.shift)?.clockinShifts || [];
  
    const loading =
    useSelector((state) => state?.shift)?.loading;

    useEffect(()=>{
      dispatch(getBranchesAsync());
      if(shiftStateWeeklyDateLabel.from){
        dispatch(getClockinClockoutShiftAsync({
          branchId: selectedBranch.value,
          from: moment(shiftStateWeeklyDateLabel.from)
          .add(0, 'minutes')
          .add(0, 'hours')
          .format('yyyy-MM-DDTHH:mm'),
        to: moment(shiftStateWeeklyDateLabel.to)
          .add(59, 'minutes')
          .add(23, 'hours')
          .format('yyyy-MM-DDTHH:mm'),
        }))
      }
    },[dispatch, selectedBranch.value, shiftStateWeeklyDateLabel.from, shiftStateWeeklyDateLabel.to])

  const handleSelectBranch = (e) => {
    setBranch(e);
  };

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  return (
    <section>
      {open && (<GoogleMapView open={open} data={data} handleClose={handleCloseModal} />)}
      
       <AppLayout
        custom="shift"
        subCustom="clock-in-clock-out"
        title="Clock in"
        customTitle=""
        subtitle="View team members that have clocked in"
        RightComponent={<TutorialPanel items={clockinClockoutTutorial} />}
      >
        <div className='flex items-center gap-3'>
          <CustomDropdown
            data={[{ name: 'All locations', value: '' }, ...branches]?.map(
              (branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode })
            )}
            className="w-[200px] h-[43px]"
            value={selectedBranch}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] shadow-md min-h-[50px] w-[200px]"
            onChangeOption={handleSelectBranch}
          />
          <NavigateCalender diff={7} />
        </div>

          <section className="mt-10">
            <table className="text-center">
              <thead>
                <tr>
                  <td className="bg-0F5 py-3 w-[100px] dark:bg-1F1 dark:border-333"></td>
                  <td className="bg-0F5 py-3 dark:bg-1F1 dark:border-333"><p className="text-base font-semibold text-969 dark:text-white">Employee</p></td>
                  <td className="bg-0F5 py-3 dark:bg-1F1 dark:border-333"><p className="text-base font-semibold text-969 dark:text-white">Location</p></td>
                  <td className="bg-0F5 py-3 dark:bg-1F1 dark:border-333"><p className="text-base font-semibold text-969 dark:text-white">Clock in</p></td>
                  <td className="bg-0F5 py-3 dark:bg-1F1 dark:border-333"><p className="text-base font-semibold text-969 dark:text-white">Clock out</p></td>
                  <td className="bg-0F5 py-3 dark:bg-1F1 dark:border-333"></td>
                  {/* <td className="bg-0F5 py-3"><p className="text-base font-semibold text-969">Manual Clock</p></td> */}
                </tr>
              </thead>
              <tbody>
                {!loading && clockShifts.length > 1 && (<>
                {
                  clockShifts?.map((clock) => !Object.keys(clock).includes('totalPages') && (
                  <tr className="border border-5F5 dark:border-333">
                  <td className="p-2 dark:border-333">
                    {
                      clock?.user?.picture?.url ? (
                        <img
                          className="rounded-full h-10 w-10 mr-2"
                          src={clock?.user?.picture?.url}
                          alt="logo"
                        />
                      ) : (
                        <InitialsGen
                          width={40}
                          name={`${clock?.user?.firstName} ${clock?.user?.lastName}`}
                          bgColor="blue"
                        />
                      )
                    }
                  </td>
                    <td className="py-2 dark:border-333 dark:text-white">{`${clock?.user?.firstName} ${clock?.user?.lastName}`}</td>
                    <td className="py-2 dark:border-333 dark:text-white">{clock?.slot?.branch?.name}</td>
                    <td className="py-2 dark:border-333 dark:text-white">{(clock?.clockedIn && moment(clock?.clockedIn).format("DD ddd MMM, yyyy, hh:mm A")) || ""} <span className="text-171 font-bold">{moment(clock?.start) < moment(clock?.clockedIn) ? "Late": ""}</span></td>
                    <td className="py-2 dark:border-333 dark:text-white">{(clock?.clockedOut && moment(clock?.clockedOut).format("ddd MMM, yyyy, hh:mm A")) || ""}</td>
                    {clock?.clockedInLatitude && (<td className="py-2 flex items-center gap-5"><BsMap className='cursor-pointer dark:text-white' onClick={()=> {
                      setData({
                        latitude: Number(clock.clockedInLatitude),
                        longitude: Number(clock.clockedInLongitude),
                      })
                      handleOpenModal()
                    }} />
                    {((clock?.clockedIn !== null) && (clock?.clockedOut == null)) && (<button className="p-3 border-D57 dark:border-333 dark:text-gray-300 rounded border">Manual Clock Out</button>)}
                    </td>)}
                  </tr>

                  ))
                }
                </>)}

                {!loading && clockShifts.length === 1 && <tr className=" h-[300px]"><td colSpan={6}><p className=" flex items-center justify-center dark:text-white">No team member clocked in</p></td></tr>}

                {loading && <tr className="h-[200px]"><td colSpan={6}><div className="flex justify-center items-center"><Loader className="dark:text-white" /></div></td></tr> }

              </tbody>

            </table>
          </section>

      </AppLayout>


    </section>
  )
}