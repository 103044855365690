/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './CreateSlot.css';
import { Job } from '../../../../../../../Network/ServiceClass/Job';
import { font } from '../../../../../../Widgets/Styles/font';
import {
  Text,
  Field,
  TextAndField,
  FillButton,
  FieldSelect,
  OutlinedButton,
  FieldSelectWithOutFormik
} from '../../../../../../Widgets/FormUtils/FormUtils';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormModal from '../../../../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import PopError from '../../../../../../Widgets/PopError';
import { useDispatch, useSelector } from 'react-redux';
import { getTeammemberAsync, getSkillSetAsync } from '../../../../../../../Store/Branches';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { createAssigneeShiftslotAsync } from '../../../../../../../Store/Shift';
import getDay from 'date-fns/getDay';
import DatePicker from 'react-datepicker';
import { transformDate } from '../../../../../../../utils/formatTime';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const job = new Job();

export const CreateShiftSlot = ({
  open,
  handleClose,
  branches,
  refresh,
  edit = false,
  setEdit,
  shiftSlotData = {},
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.branches)?.teamMembers?.map(
    (team) => {
      return {
      id: team?.user?.id,
      jobRoleId: team.jobRole.id,
      name: `${team?.user?.firstName} ${team?.user?.lastName}`,
      gender: team?.user?.gender,
      skillSets: team?.userSkillSets
    }
  }
  );


  const store1 = useSelector((state) => state.branches)

  useEffect(()=> {
    dispatch(getSkillSetAsync())
  },[])


  const isLoading = useSelector((state) => state.shift)?.loading;
  const [jobroles, setJobRoles] = useState([]);

  const initialState = Array.isArray(shiftSlotData?.assignee) ? [...shiftSlotData?.assignee] : shiftSlotData?.assignee?.length > 0 ? [shiftSlotData?.assignee] :[]

  const [personName, setPersonName] = useState(initialState);

  const [isError, setIsError] = useState(false);
  const [editAssignee, setEditAssign] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const [ruleCategory, setRuleCategory] = useState('none')
  const [selectedRule, setSelectedRule] = useState([])


  const [rules, setRules] = useState([])
  const [ruleTypes, setRuleType] = useState('none')

  useEffect(() => {
    if(ruleCategory === 'gender') {
      setSelectedRule([
        {name: 'Male', id: 'male'},
        {name: 'Female', id: 'female'},
      ])
      setRuleType('gender')
    }
    else if (ruleCategory === 'skill') {
      setSelectedRule(store1?.skillSets?.data)
      setRuleType('skillSet')
    }
    else {
      setSelectedRule([])
      setRuleType('none')
    }
  }, [ruleCategory])

  const handleRuleCategory = (val) => {
    setRuleCategory(val)
    setRules([])
  }

  const handleChangeRules = (event) => {
    const {
      target: { value },
    } = event;
    setRules(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    formik.setFieldValue(
      'rule',
     
      {
        ruleTypes: ruleTypes,
        rules: value
      }
    );

    formik.setFieldValue(
      'assignees',
     []
    );

    setPersonName([])
  };

  const dayOfWeek = [
    { name: 'Monday', id: 1 },
    { name: 'Tuesday', id: 2 },
    { name: 'Wednesday', id: 3 },
    { name: 'Thursday', id: 4 },
    { name: 'Friday', id: 5 },
    { name: 'Saturday', id: 6 },
    { name: 'Sunday', id: 0 },
  ];

  const formik = useFormik({
    initialValues: {
      branchId: shiftSlotData?.branchId || '',
      jobRoleId: shiftSlotData?.jobId || '',
      dayOfWeek:
        (shiftSlotData?.date && getDay(moment(shiftSlotData?.date).toDate())) || 0,
      hours: 0,
      startTime: shiftSlotData?.selectedTimeData?.split('-')[0] || '',
      assignees: shiftSlotData?.assignee || [],
      endTime: shiftSlotData?.selectedTimeData?.split('-')[1] || '',
      bookDate: (shiftSlotData?.date && moment(shiftSlotData?.date).toDate()) || moment().toDate(),
      staffRequired: shiftSlotData?.maxStaffRequired || 1,
      rule: {},
      mustBe: 'none',
      gender: 'both'
    },
    validationSchema: Yup.object({
      branchId: Yup.string().required('A branch is required'),
      jobRoleId: Yup.string().required('A job role is required'),
      dayOfWeek: Yup.string().required('Day of week is required'),
      startTime: Yup.string().required('Start time is required'),
      bookDate: Yup.string().required('Book date must be selected'),
      endTime: Yup.string().required('End time is required'),
      staffRequired: Yup.string().required('Staff is required'),
      // mustBe: Yup.string().required('Must be is required'),
      // gender: Yup.string().required('gender is required'),

    }),
    enableReinitialize: Object.keys(shiftSlotData)?.length > 0,
    onSubmit: (value, actions) => {
      if (true) {
        if (edit) {
          setEdit(false);
        }
        value['dayOfWeek'] = Number(value['dayOfWeek']);
        value['staffRequired'] = Number(value['staffRequired']);
        value['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var start = moment.utc(value.startTime, 'HH:mm');
        var end = moment.utc(value.endTime, 'HH:mm');

        const data = {
          ...value,
          bookDate: transformDate(formik.values.bookDate),
          assignees: store
            ?.filter((team) => value.assignees.includes(team.id))
            .map((selectedMember) => ({
              userId: selectedMember.id,
              userName: selectedMember.name,
            })),
          hours: end.diff(start, 'hours'),
          endTime: undefined,
        };

        dispatch(createAssigneeShiftslotAsync(data))
          .then((res) => {
            if (res?.payload?.status === 201) {
              actions.resetForm();
              setPersonName([]);
              toast.success('Your shift has been created successfully');
              handleClose();
              // update fetch created slot
              refresh();
            }

            if (res?.payload?.response?.status === 400) {
              toast.error(res.payload.response.data?.message);
            }
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
          });
      }
    },
  });

  useEffect(() => {
    dispatch(getTeammemberAsync({}));
  }, [dispatch]);

  useEffect(() => {
    job
      .fetchJobRoles()
      .then((value) => {
        setJobRoles(
          value.data.map((val) => {
            return { id: val.id, name: val.title };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '700px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    formik.setFieldValue(
      'assignees',
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const RenderReadOnly = ({ text }) => {
    return (
      <div className="flex w-full justify-between items-center border-2 py-[10px] px-[10px] rounded-lg bg-5F5">
        <p>{text}</p>
      </div>
    );
  };

  const handleEditAssignee = () => {
    setEditAssign(true);
    setPersonName(initialState);
  }

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack direction="column" alignItems="center" sx={style} gap={3}>
        <Typography sx={{ ...font, fontSize: '18px', fontWeight: '500' }}>
          {edit ? 'Edit' : 'Create'} shift slot
        </Typography>

        <TextAndField>
          <Text text={'Location'} />
          {!edit ? (
            <FieldSelect
              list={branches}
              value={formik.values.branchId}
              formik={formik}
              width="100%"
              name="branchId"
            />
          ) : (
            <RenderReadOnly
              text={
                branches?.filter(
                  (branch) => branch.id === formik.values.branchId
                )[0]?.name
              }
            />
          )}
        </TextAndField>

        <TextAndField>
          <Text text={'Job role'} />
          {!edit ? (
            <FieldSelect
              list={jobroles}
              value={formik.values.jobRoleId}
              formik={formik}
              width="100%"
              name="jobRoleId"
            />
          ) : (
            <RenderReadOnly
              text={
                jobroles?.filter(
                  (jobRole) => jobRole.id === formik.values.jobRoleId
                )[0]?.name
              }
            />
          )}
        </TextAndField>

        <TextAndField>
          <Text text={'Date'} />
          {!edit ? (
            <div className="border-1 border-grey-600 py-2 pl-2 rounded text-xs text-grey-800">
              <DatePicker
                selected={formik.values.bookDate}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => 
                  {formik.setFieldValue('bookDate', date);}
                }
                placeholderText="mm/dd/yyyy"
                className='outline-none'
              />
            </div>
          ) : (
            <RenderReadOnly text={moment(new Date(formik.values.bookDate)).format('MM/DD/yyyy')} />
          )}
        </TextAndField>

        <TextAndField>
          <Text text={'Day'} />
          {!edit ? (
            <FieldSelect
              list={dayOfWeek}
              width="100%"
              value={formik.values.dayOfWeek}
              formik={formik}
              name="dayOfWeek"
            />
          ) : (
            <RenderReadOnly
              text={
                dayOfWeek.filter((x) => x.id === formik.values.dayOfWeek)[0]
                  ?.name
              }
            />
          )}
        </TextAndField>

        <TextAndField>
          <Text text={'Staff required'} />
          {!edit ? (
            <Field
              text={'Enter amount of staff required'}
              type="number"
              value={formik.values.staffRequired}
              formik={formik}
              name="staffRequired"
            />
          ) : (
            <RenderReadOnly text={formik.values.staffRequired} />
          )}
        </TextAndField>

        <Stack width="100%" direction="row" gap={1}>
          <TextAndField>
            <Text text={'Start Shift'} />
            {!edit ? (
              <Field
                text={'Choose time'}
                type="time"
                width={'130px'}
                value={formik.values.startTime}
                formik={formik}
                name="startTime"
              />
            ) : (
              <RenderReadOnly text={formik.values.startTime} />
            )}
          </TextAndField>

          <TextAndField>
            <Text text={'End Shift'} />
            {!edit ? (
              <Field
                text={'Choose time'}
                type="time"
                width={'130px'}
                value={formik.values.endTime}
                formik={formik}
                name="endTime"
              />
            ) : (
              <RenderReadOnly text={formik.values.endTime} />
            )}
          </TextAndField>
        </Stack>

       

        <Accordion className='w-[100%]'>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Set Rules</Typography>
          </AccordionSummary>

          <AccordionDetails className='flex flex-col gap-4'>
            
            <TextAndField>
              <Text text={'Rule'} />
                <FieldSelectWithOutFormik
                  placeholder='Choose whom can book'
                  list={[
                    {name:'Skill', id: 'skill'}, 
                  ]}
                  width="100%"
                  value={ruleCategory}
                  callback={(e)=>{handleRuleCategory(e.target.value)}}
                />
            
            </TextAndField>

            {ruleCategory !== 'none' && <TextAndField>
              <Text text={'Who'} />
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                displayEmpty
                value={rules}
                onChange={handleChangeRules}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        style={{ borderRadius: '4px' }}
                        key={value}
                        label={selectedRule?.filter((team) => team.id === value)[0].name}
                      />
                    ))}
                  </Box>
                )}
              >
              
                  {selectedRule?.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      <Checkbox checked={personName.indexOf(item?.id) > -1} />
                      <ListItemText primary={item?.name} />
                    </MenuItem>
                  ))}

                  {selectedRule.length === 0 && (
                  <MenuItem>No rule selected</MenuItem>
                  )}
              </Select>
            
            </TextAndField>}

            <TextAndField>
              <Text text={'Gender'} />
              {!edit ? (
                <FieldSelect
                  list={[
                    {name: 'Male', id: 'male',},
                    {name: 'Female', id: 'female',},
                    {name: 'Both', id: 'both',}
                  ]}
                  value={formik.values.gender}
                  formik={formik}
                  width="100%"
                  name="gender"
                />
              ) : (
                <RenderReadOnly
                  text={
                    branches?.filter(
                      (branch) => branch.id === formik.values.branchId
                    )[0]?.name
                  }
                />
              )}
            </TextAndField>

            <TextAndField>
              <Text text={'Must Be'} />
              {!edit ? (
                <FieldSelect
                  list={[
                    {name: 'None', id: 'none',},
                    {name: 'Be Present', id: 'bePresent',},
                    {name: 'Be Absent', id: 'beAbsent',}
                  ]}
                  value={formik.values.mustBe}
                  formik={formik}
                  width="100%"
                  name="mustBe"
                />
              ) : (
                <RenderReadOnly
                  text={
                    branches?.filter(
                      (branch) => branch.id === formik.values.branchId
                    )[0]?.name
                  }
                />
              )}
            </TextAndField>

          </AccordionDetails>

        </Accordion>

        <TextAndField>
          <div className='flex items-center gap-3'>
          <Text text={'Assignee'} />
          {edit && (<p onClick={handleEditAssignee} className='text-xs text-blue-600 cursor-pointer'>Edit</p>)}

          </div>
          {(!edit  || editAssignee)? (<Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            displayEmpty
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    style={{ borderRadius: '4px' }}
                    key={value}
                    label={store.filter((team) => team.id === value)[0].name}
                  />
                ))}
              </Box>
            )}
          >
            {store
              ?.filter((team) => {
                if(team.jobRoleId === formik.values.jobRoleId){
                  if(ruleTypes === 'gender' && formik.values?.rule?.rules?.includes(team.gender)){
                    return true
                  }
                  else if (ruleTypes === 'skillSet' && formik.values?.rule?.rules?.find(val => team.skillSets?.some(obj => obj.skillSetId === val)
                  )){
                    return true
                  }
                  else return false
                }
                return false
              })
              ?.map((item) => (
                <MenuItem key={item.id} value={item?.id}>
                  <Checkbox checked={personName.indexOf(item?.id) > -1} />
                  <ListItemText primary={item?.name} />
                </MenuItem>
              ))}

            {store?.filter((team) =>
             {
              if(team.jobRoleId === formik.values.jobRoleId){
                if(ruleTypes === 'gender' && formik.values?.rule?.rules?.includes(team.gender)){
                  return true
                }
                else if (ruleTypes === 'skillSet' && formik.values?.rule?.rules?.find(val => team.skillSets?.some(obj => obj.skillSetId === val)
                )){
                  return true
                }
                else return false
              }
              return false
             })
              ?.length === 0 && (
              <MenuItem>No team member with the rules selected</MenuItem>
            )}
          </Select>) : <div className="flex items-center gap-2">
            {
              store?.filter((x) => initialState.includes(x.id))?.map((user) => <p className=" bg-5F5 rounded-2xl p-1 text-sm">{user.name}</p>)
            }
            </div>}
          {formik.errors['assignees'] && (
            <Typography sx={{ fontSize: '10px', color: 'red', p: 0 }}>
              {formik.errors['assignees']}
            </Typography>
          )}
        </TextAndField>

        {isError && (
          <PopError
            message={errorMessage}
            handleClick={() => setIsError(false)}
          />
        )}

        <Stack
          direction="row"
          width="82%"
          justifyContent="space-between"
          mt={6}
        >
          <OutlinedButton
            text="Cancel"
            callBack={() => {
              formik.resetForm();
              if (edit) {
                setEdit(false);
              }
              handleClose();
            }}
          />
          {!isLoading && (
            <FillButton text="Submit" callBack={formik.handleSubmit} />
          )}

          {isLoading && <CircularProgress />}
        </Stack>
      </Stack>
    </FormModal>
  );
};
