import {ReactComponent as VideoPlay} from '../../../Assets/video-play.svg'
import TutBg from '../../../Assets/tutorial_icon.svg'
import ScreenShot from '../../../Assets/screenshot.svg'
import ScreenPlay from '../../../Assets/screen_play.svg'
import './style.css'
import FormModal from '../../Widgets/Animation/FormModal'
import { Stack } from '@mui/material'
import { IoMdClose } from 'react-icons/io'
import { useState } from 'react'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden'
};


const TutorialPanel = ({ items = [] }) => {
  const [open, setOpen] = useState(false)
  const [viewVideo, setVideo] = useState('')

  const handleToggleModal = () => {
    setOpen((prev) => !prev)
  }

  const handleCloseVideo = () => {
    setVideo("")
  }

  const handleOnClickTutorial = (e) => {
    setVideo(e)
  }

  return (
    <div>
      <div onClick={handleToggleModal} className='px-[10px] rounded cursor-pointer w-[190px] py-[0.5rem] flex items-center justify-between tut'>
        <div className='flex items-center gap-1'>
          <VideoPlay className="dark:text-white" />
          <p className="text-424 text-sm font-semibold dark:text-white">Tutorials</p>
        </div>
        <img src={TutBg} alt="log" />
      </div>

      <FormModal open={open} handleClose={handleToggleModal}>
      <Stack className="dark:bg-A06 dark:text-white" direction="column" sx={style} gap={3}>
        <div className="relative">
        <p className='text-[20px] dark:text-white font-bold text-color-424 text-center' >
          Tutorials
        </p>
        <div onClick={viewVideo.length < 1 ? handleToggleModal: handleCloseVideo} className="cursor-pointer absolute right-3 top-1">
          <IoMdClose size={20} />
        </div>
        </div>

        {viewVideo.length === 0 &&  items?.map((item) => <div onClick={()=>handleOnClickTutorial(item.link)} className='py-3 cursor-pointer px-[10px] mb-[17px] flex items-center gap-6 shadow-custom rounded-md'>
          <div className='relative shadow-small-select'>
            <img src={ScreenShot} alt="img" />
            <img src={ScreenPlay} className='absolute top-[40%] left-[40%] cursor-pointer' alt="img" />
          </div>
          <div>
            <p className="text-base font-bold text-969 dark:text-white">{item?.title}</p>
            <p className="font-medium font-sm text-A8A">{item?.desc}</p>
          </div>
        </div>)}

        {viewVideo.length > 0 && (<div>
          <video src={viewVideo} controls />

        </div>)}

        </Stack>
      </FormModal>

    </div>
  )
}

export default TutorialPanel