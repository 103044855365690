import React, { useEffect, useState } from 'react'
import { Navbar } from '../../Widgets/Navbar'

import { Loader } from '../../Widgets/Loader';
import { Service } from '../../../Network/ServiceClass/Auth';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Storage } from '../../../Network/StorageClass/StorageClass';

import Synclogo from "../../../Assets/sync_light.svg";
import SyncdarkLogo from "../../../Assets/sync_logo.svg";


export const InviteMember = () => {

    const Auth = new Service();
    const storage = new Storage();
    const navigate = useNavigate();
    const mode = localStorage.getItem('color-theme') ?? 'light'

    const [searchParams, setSearchParam] = useSearchParams();
    const [message, setMessage] = useState('');
    

    useEffect(()=>{
        InviteTeamMember();
    },[]);

    const InviteTeamMember = async()=>{
        Auth.VerifyInvite({'inviteHash': searchParams.get('hash')}).then((value)=>{
            console.log(value.data.accepted);
            setMessage("Done")
            if(value.data.accepted === false){  
                navigate('/signup?hash='+searchParams.get('hash'));
            }else{
                navigate('/login');
            }   
        }).catch((err)=>{
            console.log(err);
            setMessage(err.response.data.message)
        });
    }

  return (
    <>
        <Navbar/>
        <section className='verify-email-body'>
          <div className='the-card'>
            <img src={mode === 'light' ? Synclogo : SyncdarkLogo} alt="logo" style={{marginBottom:"20px"}}/>
            <p>Your invite is being verified</p>
            {message==="" ?<Loader />:<p style={{color:"red", fontSize:"14px"}}>{message}</p>}
          </div>
        </section>
    </>
  )
}
