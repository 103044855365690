import axios from 'axios';
import { conn_url, chat_url_prod } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const askChatGpt = async (data) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
        const payload = data
        payload.metadata = {
            teamMembershipId: member.id,
            companyId: activeCompany.id,
            userId: user.id,
            bearer: `Bearer ${localStorage.getItem("authtoken")}`
          }
        const response = await axios.post(chat_url_prod, payload, storage.getConfig());
        return {ans: response.data, msg:  data.sentence}
    } catch (error) {
        throw Error(JSON.stringify({error: error.response.data.detail.toString(), msg:  data.sentence}))
    }
        
}  
  