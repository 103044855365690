import {Popper, Box} from '@mui/material';
import { motion } from 'framer-motion';  


const PopDetail = ({
  openPopper, 
  children, 
  popperHovered, 
  handlePopperMouseEnter,
  position = "right-end",
  handlePopperMouseLeave}) => {
  
    const open = Boolean(openPopper) || popperHovered;
    const id = open ? 'simple-popper' : undefined;

    const mainAnim ={
      hidden: {opacity: 0},
      vissible: {
        opacity: 1,
        transition: {ease: 'easeIn', duration: 1,},
        
      },
      exit: {
        opacity: 0,
        transition: {ease: 'easeOut', duration: 1}
      }
    }


  return (
      <Popper
            id={id} 
            open={open} 
            anchorEl={openPopper} 
            onMouseEnter={handlePopperMouseEnter}
            onMouseLeave={handlePopperMouseLeave}
            placement={position}>
              <motion.div
              variants={mainAnim}
              initial={'hidden'}
              animate={'vissible'}
              exit={'exit'}
              className='w-[310px] max-h-[400px] min-h-[250px] flex flex-col bg-white dark:bg-A06 dark:text-white pt-[20px] rounded-sm mt-[15px] shadow-shift-shadow-small'
              >
                  {children}
              </motion.div>

      </Popper>
  )
}

export default PopDetail

