import { useState, useEffect } from 'react'

import TableLayout from '../TableLayout';
import { CustomTableWithSearch } from '../../../../Widgets/Table/CustomTableWithSearch';
import { useSelector } from 'react-redux';
import { getWeek } from '../../../../../utils/getWeek';
import formatDate from '../../../../../utils/formatDate';
import addOneDay from '../../../../../utils/incrementDate';
import {getShiftCancellation} from '../../../../../Network/ServiceClass/dashboardService'
import { useDispatch } from 'react-redux';
import {setShiftCancellation} from '../../../../../Store/dashboard'
import { CircularProgress } from '@mui/material';
import SkillSetStat from './SkillSetStat';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { getBranchesAsync } from '../../../../../Store/Branches';


const Row3 = () => {
    const dispatch = useDispatch()
    const company = useSelector(state => state.company.activeCompany)

    const shiftCancellationState = useSelector(state => state.dashboard.shiftCancellation)

    const shiftCancellation = shiftCancellationState.map((val, index) => {
        return {
            id: index,
            col1: val.branchName,
            col2: val.count,
        }
    })

  const [branches, setBranches] = useState([])


    useEffect(()=>{
      dispatch(getBranchesAsync())
     .then(val => {
         setBranches(val.payload.data)
     })
   },[company])

   

      const headerListCancellation = ['Location Name', 'No of Cancellations',]

      const thisWeekTo = addOneDay(getWeek(0).lastDay.toUTCString())
      const thisWeekFrom = formatDate(getWeek(0).firstDay.toUTCString())

      const prevWeekTo = addOneDay(getWeek(-7).lastDay.toUTCString())
      const prevWeekFrom = formatDate(getWeek(-7).firstDay.toUTCString())

      const nextWeekTo = addOneDay(getWeek(7).lastDay.toUTCString())
      const nextWeekFrom = formatDate(getWeek(7).firstDay.toUTCString())

      const [weekValue, setWeekValue] = useState({
        label: 'Current Week', value:'current'
      })
      const [isLoading, setIsLoading] = useState(false)

      const handleChangeWeek = (x) => {
        const value = x.value
        setWeekValue(x)
        setIsLoading(true)
        let to, from

        if(value === 'current'){
            to = thisWeekTo
            from = thisWeekFrom
        }else if(value === 'prev'){
            to = prevWeekTo
            from = prevWeekFrom
        }else if(value === 'next'){
            to = nextWeekTo
            from = nextWeekFrom
        }else {
          to = thisWeekTo
          from = thisWeekFrom
        }
        getShiftCancellation(from, to).then(val => {
            setIsLoading(false)
            dispatch(setShiftCancellation(val))
            // console.log(val)
        }).catch(err => setIsLoading(false))

      }

      const list = [
        {value:'current',label:'Current Week'},
        {value:'prev',label:'Previous Week'},
        {value:'next',label:'Next Week'}
    ]


  return (
    <div className='flex gap-6 shadow-shift-shadow'>
        <TableLayout>
            <div className='flex justify-between'>
            <p className='section-header dark:text-white'>Shift Cancellations</p>

                <CustomDropdown
                trimtext
                trimLength={13}
                data={list}
                mainContainerStyle='w-44'
                className="h-10 rounded "
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={weekValue}
                onChangeOption={handleChangeWeek}
              />
            </div>
            {!isLoading && <CustomTableWithSearch rows={shiftCancellation} headerList={headerListCancellation} style='w-[100%] flex flex-col gap-3'/>}
            {isLoading && <div className='w-[100%] flex justify-center mt-3'><CircularProgress/></div>}

        </TableLayout>

       <div className='w-[50%]'> <SkillSetStat branches={branches}/></div> 
        
    </div>
  )
}

export default Row3


