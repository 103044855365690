import React from 'react'
// import './SelectBranch.css'
import { useDispatch} from 'react-redux'
import {setSelectedBranch} from '../../../../../Store/Shift'
import {FormControl,Select, MenuItem, Typography, OutlinedInput} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {font} from '../../../../Widgets/Styles/font'


const SelectBranch = ({branches}) => {

    const dispatch = useDispatch()
    const [data, setData] = React.useState('');


    const handleSelect = (value) => {
      setData(value.value.name)
      dispatch(setSelectedBranch(value.value.id))
    }

  return (
    
    <FormControl>
      <Select
        IconComponent = {ExpandMoreIcon}
        size='small'
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={data}
        onChange={(e)=>{handleSelect(e.target)}}
        displayEmpty
        // name ={labelId}
        sx={{minWidth: '116px', height: '37px', borderColor: '#E6E6E6', ...font}}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected === '') {
            return <Typography sx={{...font}}>All Location</Typography>;
          }
          return selected;
        }}
        
      >
       
        <MenuItem selected value={{id:1, name: 'All Location'}} ><Typography sx={{...font}}>All Location</Typography></MenuItem>

        {
          branches.map((value)=>
          <MenuItem value={value}><Typography sx={{...font}} >{value.name}</Typography></MenuItem>
          )
        }
        
      </Select>
    </FormControl>
  )
}

export default SelectBranch