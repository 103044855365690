import React from 'react'
import { CircularProgress } from '@mui/material';
import './Styles/Loader.css';

export const Loader = ({ loaderClass = '', style={}}) => {
  return (
    <center>
        <div className={`loader dark:bg-primary ${loaderClass}`} style={style}>
        <CircularProgress className='dark:text-white' />
        </div>
    </center>
  )
}
