import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import { getInventoryDataWithdrawalsThunk } from '../../../Store/Inventory'
import { Storage } from '../../../Network/StorageClass/StorageClass'
import dayjs from 'dayjs'
import CurrencyFormat from 'react-currency-format'
import ExportTemplate from './Widget/ExportTemplate'

const storage = new Storage()
const Withdrawals = ({statusColor, branches}) => {
    const dispatch = useDispatch()

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id

    const [toggleMenu, setToggleMenu] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})
    const [menuIndex, setMenuIndex] = useState(null)


    const dataWithdrawals = useSelector(state => state.inventory)?.dataWithdrawals


    const getWithdrawals = (id=null) => {
        dispatch(getInventoryDataWithdrawalsThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getWithdrawals(withdrawalBranch.value)
    },[withdrawalBranch])
    

    // const handleApprove = (value) => {
    //     setIsLoadingAction(true)

    //     const data = {
    //         referenceNumber: value?.referenceNumber,
    //         locationId: value?.transferLocation?.id,
    //         status: "approved",
    //         trackingStatus: "shipped",
    //         teamMemberId: teamMembershipId
    //     }

    //     inventoryUpdateTransferItemsStatus(data)
    //     .then(val => {
    //         if (val?.status === 200){
    //             toast.success('Approved Successfully')
    //             setToggleMenu(null)
    //             getPendingTransfer(withdrawalBranch.value)
    //         }else toast.error(val?.data?.message)
    //         setIsLoadingAction(false)
    //     })
    // }
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Withdrawn Items'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={dataWithdrawals?.map(x => ({
                    'Date': dayjs(x?.createdOn).format('MM/DD/YYYY'),
                    'Time': dayjs(x?.createdOn).format('hh:mmA'),
                    'Reference Number': x?.inventoryItems?.referenceNumber,
                    'Name': x?.inventoryItems?.name,
                    'Location': x?.withdrawLocation?.name,
                    'Patient ID': x?.patientId,
                    'Price': `$${x?.totalPrice/100}`,

                }))}
                fileName={'withdrawn-items'}
            />


            <div className='rounded-md border-1 dark:border-333 dark:text-white mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className=''>
                <tr className='bg-white dark:bg-444 font-bold sticky top-0'>
                    <td className='border-1 dark:border-333 border-t-0 border-l-0 text-center pl-4 py-4 rounded-tl-md '>Date</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Time</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Ref No.</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Name</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Location</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>PatientID</td>
                    <td className='border-1 dark:border-333 border-t-0 border-r-0 text-center rounded-tr-md'>Price</td>

                </tr>

                {!isLoading ? <tbody>
                    {dataWithdrawals?.length !== 0 ?
                        dataWithdrawals.map(val => (
                            <tr className='border-1 dark:bg-828 dark:border-333 dark:text-white font-bold border-l-0 text-A5A'>
                                    <td className='text-center border-r-1 dark:border-333 py-4 pl-4'>{dayjs(val?.createdOn).format('MM/DD/YYYY')}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{dayjs(val?.createdOn).format('hh:mmA')}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.inventoryItems?.referenceNumber}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.inventoryItems?.name}</td>


                                    <td className='text-center border-r-1 dark:border-333'>
                                        <div className='flex items-center justify-center gap-2'>
                                            <p className='w-2 h-2 rounded-full' style={{background: val?.withdrawLocation?.hexcode}}/>
                                            <p>{val?.withdrawLocation?.name}</p>
                                        </div>
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.patientId}</td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                        <CurrencyFormat
                                            className='dark:text-white'
                                            value={val?.totalPrice/100} // The numeric value you want to format
                                            displayType={'text'} // You can also use 'input' for input fields
                                            thousandSeparator={true} // Use commas as thousands separators
                                            prefix={'$'} // Currency symbol or prefix
                                            decimalScale={2} // Number of decimal places
                                        />
                                    </td>
                                    

                            </tr>

                        ))
                        :<tr>
                            <td colSpan={9} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                    }
                </tbody>:
                <tr>
                    <td colSpan={9} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                </tr>
                }
                
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default Withdrawals