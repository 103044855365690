import React from 'react';

export const Stepper = ({index}) => {
  return (
    <div>
        <div className='flex justify-between items-center gap-3'>
            <Box text={1} active={index === 0 || index > 0 ? true : false}/>
            <div className={`w-[100px] border-t-1 border-dashed  ${index === 1 || index > 1 ? 'border-black dark:border-white' : 'border-[#E6E6E6] dark:border-333'} `}/>
            <Box text={2} active={index === 1 || index > 1 ? true : false}/>

        </div>

    </div>
    
 )
}

const Box = ({text, active}) => {
    return (
        <div className={`w-10 h-10 border rounded-md flex justify-center items-center 
        ${active ? 'border-black dark:border-white' : 'border-[#E6E6E6] dark:border-333 dark:text-color-333 text-[#E6E6E6]'}`}>
            {text}
        </div>
    )
}
