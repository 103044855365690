import React, {useState} from 'react'
import { SideBar } from '../../../Widgets/SideBar'
import { BranchesHeader } from '../../BranchScreen/BranchesHeader/BranchesHeader'

import SearchInput from '../../../Widgets/SearchInput'
import TablePagination from '@mui/material/TablePagination';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';




const OrderLogsItems = () => {

    const navigate = useNavigate()
    const { itemId } = useParams();

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  return (
    <section>
        <AppLayout
            custom="inventory"
            subCustom="inventory-logs"
            title="Logs"
            customTitle="Inventory"
            subtitle=""
        >
            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                <Link to="/inventory-logs">LOGS</Link>
                <Link to="/order-logs"> / ORDER LOG</Link>
                <span className="text-A5A"> / {itemId}</span>
                </p>
            </div>

            <div className='flex gap-2 items-center mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-10'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={()=>{}}
                />


            </div>

            <div className='mt-6 max-h-[70vh] overflow-y-auto '>
                <table className=''>
                    <tr className=' bg-0F5 text-grey-800 sticky top-0'>
                        <td className='pl-4 py-2 rounded-tl-md '>Reference number</td>
                        <td className=''>Name</td>
                        <td className=''>Supplier</td>
                        <td className=''>Manufacturer</td>
                        <td className=''>Item/pkg</td>
                        <td className=''>Price</td>
                        <td className=''>Order qty</td>
                        <td className='rounded-tr-md'>Cost ($)</td>

                    </tr>

                    <tbody>
                        {
                            Array.from({length:20}).map(val => (
                                <tr className='border-b-1 text-A5A'>
                                        <td className='py-2 pl-4'>
                                        00001
                                        </td>

                                        <td>John Doe</td>
                                        <td>Mc kenson</td>
                                        <td>Walmart</td>
                                        <td>20</td>
                                        <td>1</td>
                                        <td>19</td>
                                        <td>500</td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>

            </div>
            <TablePagination
                className="dark:text-grey-600"
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </AppLayout>
       
    </section>
  )
}

export default OrderLogsItems