import axios from "axios";
import { Storage } from "../StorageClass/StorageClass";
import { conn_url } from "../conn_consts";

const storage = new Storage();

export class Job{

    async createJobRole(data){
        const response = await axios.post(conn_url+"/api/v1/company/job-roles", data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchJobRoles(){
        const response = await axios.get(conn_url+"/api/v1/company/job-roles", storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchJobGroups(){
        const response = await axios.get(conn_url+"/api/v1/get-role-tag", storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchLicense(memberId){
        const response = await axios.get(`${conn_url}/api/v1/company/members/${memberId}/licenses`, storage.getConfig());
        return response.data;
    }

    async updateJobRoles({jobId, data}){
        try {
            const response = await axios.put(`${conn_url}/api/v1/company/update-job-role/${jobId}`, data, storage.getConfig());
           return response.data;
            
        } catch (error) {
            return error.response
        }
    }

    async assignJobToTag({tagId, memberships}){
        // try {
            const response = await axios.post(`${conn_url}/api/v1/assign-membership-tag/${tagId}`, {memberships}, storage.getConfig());
           return response;
            
        // } catch (error) {
        //     return error.response
        // }
    }

    async removeJobTagFromJobRole({tagId, jobId}){
        try {
            const response = await axios.delete(`${conn_url}/api/v1/delete-jobgroup-from-jobrole/${jobId}/${tagId}`, storage.getConfig());
            return {data: response.data, status:  response.status};
        } catch(err) {
            return {data: err.response.data, status:  err.response.status};
        }
    }

    async updateJobGroup({groupId, data}){
        try {
            const response = await axios.put(`${conn_url}/api/v1/update-role-tag/${groupId}`, data, storage.getConfig());
           return response.data;
            
        } catch (error) {
            return error.response
        }
    }

    async deleteJobRoles({jobId}){
        try {
            const response = await axios.delete(`${conn_url}/api/v1/company/delete-job-role/${jobId}`, storage.getConfig());
           return response.data;
            
        } catch (error) {
            throw error
        }
    }

    async deleteJobGroup({groupId}){
        try {
            const response = await axios.delete(`${conn_url}/api/v1/delete-role-tag/${groupId}`, storage.getConfig());
           return response.data;
            
        } catch (error) {
            throw error
        }
    }

}