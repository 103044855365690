export function transformLocationScheduleData ({teamMembers, branches}){
  let data = {};
  const companyBranches = branches?.map((branch) => ({ name: branch?.name, id: branch?.id, hexcode: branch?.hexcode}))
  let tableData = []

  teamMembers?.forEach(member => {
    if(member.assignedBranchId){
      if(!data[member.assignedBranchId] ){
        data = {
          ...data,
          [member.assignedBranchId]: [{name: `${member?.user?.firstName} ${member?.user?.lastName}`, id: member?.user?.id, imageUrl: member?.user?.picture?.url, jobRole: member?.jobRole, email: member?.user.email, branch: member?.assignedBranch}]
        }
      } else {
        data = {
          ...data,
          [member.assignedBranchId]: [...data[member.assignedBranchId], {name: `${member?.user?.firstName} ${member?.user?.lastName}`, id: member?.user?.id, imageUrl: member?.user?.picture?.url, jobRole: member?.jobRole, email: member?.user.email, branch: member?.assignedBranch, skills: member?.userSkillSets}]
        }
      }
    }
  })

  companyBranches.forEach((bran) => {
    tableData.push({mode: 'header', branchId: bran.id, branchName: bran.name, hexcode: bran?.hexcode});
  })

  return tableData
}