import React, { useState } from "react";

import { ClickAwayListener } from "@mui/base";
import { AnimatePresence, motion } from "framer-motion";
import { OpenCliveIcon, CloseCliveIcon } from "../../../Assets/Svg/Index";
import Chat from "./Chat";
import FormModal from "../../Widgets/Animation/FormModal";
import { Drawer } from "@mui/material";
import { BiExit } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const CliveChat = () => {
  const [toggleLive, setToggleLive] = useState(false);

  const handleClickAway = () => {
    setToggleLive(false);
  };

  return (
    <div className=" relative">

      <div onClick={() => setToggleLive(prev => !prev)}
        className="bg-D57 absolute top-[-1px] right-[23px] z-[70] w-[55px] h-[55px] cursor-pointer rounded-[28px] flex justify-center items-center"
      >
        {!toggleLive ? <OpenCliveIcon data-tour="step6" /> : <CloseCliveIcon />}
      </div>

      <ClickAwayListener onClickAway={handleClickAway}>
        <AnimatePresence>
          {toggleLive && (
            <React.Fragment key="right">
              <Drawer
                component={motion.div}
                // variants={componentVariant}
                initial={'hidden'}
                animate={'vissible'}
                exit='exit'
                anchor="right"
                open={toggleLive}
                onClose={handleClickAway}
                hideBackdrop={true}
              >
                <div data-tour="step6" className="w-full h-[97%] shadow-chat bg-white dark:bg-828 ">

                <div className="p-3 flex justify-between items-center">
                <IoMdClose className="text-2xl cursor-pointer dark:text-white" onClick={handleClickAway} />
                <p className="text-A5A text-xl font-bold dark:text-white">Sync AI</p>
                <div className="w-7" />
                </div>

                <Chat  />
                </div>
              </Drawer>
              {/* <p className="max-w-[95%] m-auto h-1 rounded-t-[100px] sticky mt-[7px] dark:bg-[#3c3c3c] bg-white"/> */}

            </React.Fragment>
          )}
        </AnimatePresence>
      </ClickAwayListener>
    </div>
  );
};

export default CliveChat;
