import React,{useState} from 'react';
import Modal from '@mui/material/Modal';
import {Box, CircularProgress} from '@mui/material';
import { Home } from '../../../../Network/ServiceClass/Home';
import {OutlinedButton} from '../../../Widgets/FormUtils/FormUtils'
import { useDispatch } from 'react-redux';
import FormModal from '../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';


const DeleteLicenceModal = ({ open, handleClose, id, callback }) => {

    const home = new Home();
    const dispatch = useDispatch()
    const [isLoading,setIsLoading] = useState(false);

    const Delete = ()=>{
        setIsLoading(true);
        home.deleteLicense(id).then((value)=>{
            setIsLoading(false); 
            callback(); 
            toast('You deleted a license successfully')
            handleClose();
        }).catch((err)=>{
            console.log(err); 
            setIsLoading(false);
        });

    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        maxHeight: '100vh',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflowY:'auto',
        overflowX:'hidden'
      };

    return (
        <FormModal open={open} handleClose={handleClose}>
            <Box sx={{ ...style, }}>

                <div className='flex flex-col items-center gap-3'>

                    <p className='text-base' >Delete Licence</p>
                    <p className='text-[#A5A5A5] text-xs' >You are about to delete a license</p>

                    <div className='w-[100%] flex self-start justify-between mt-12'>
                        <OutlinedButton text={'Cancel'} width='91px' height={'40px'} callBack={handleClose}/>
                        {!isLoading && <OutlinedButton text={'Delete'} width='91px' height={'40px'} themeColor={'#C15353'} bg={'#FAF2F2'} callBack={Delete}/>}
                        {isLoading && <CircularProgress/>}

                    </div>
                </div>


            </Box>
        </FormModal>
       
    )
}

export default DeleteLicenceModal