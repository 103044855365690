import React, { useState } from 'react';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import { SideBar } from '../../Widgets/SideBar';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import {ReactComponent as FileDownload} from '../../../Assets/file_download.svg'
import {ReactComponent as FileDisplay} from '../../../Assets/file_display.svg'
import {ReactComponent as FileClose} from '../../../Assets/x-close.svg'
import CustomButton from '../../Widgets/CustomButton';
import Papa from "papaparse"
import { useDispatch } from 'react-redux';
import { setAccounCreationTeams } from '../../../Store/Branches';
import AppLayout from '../../Widgets/AppLayout';
import LiveUsers from '../LiveUsers/LiveUsers';


const BulkAccountScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkError, setCheckError] = useState(false)
  const [fileUploads, setFileUpload] = useState([])
  const [errorMsg, setErrorMsg] = useState('')

  const handleBranchBack = () => {
    navigate('/branches');
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {

        if(results.data.length > 100){
          setCheckError(true)
          setErrorMsg('You can only tend to create not more than 100 members at a time')
          return
        }

        console.log(results.data.length)
        // make all keys lower case
        results.data?.forEach(obj => {
          Object.keys(obj).forEach(key => {
            const lowerCaseKey = key.toLowerCase();
            if (lowerCaseKey !== key) {
              obj[lowerCaseKey] = obj[key];
              delete obj[key];
            }
          });
        });
        
        const isError = results.data?.every((res) => (res?.username !== '') &&(res?.firstname !== '') &&
         (res?.lastname !== '') && (res?.email !== '') && (res?.phone !== '') && (res?.gender !== ''))

        if(isError){
          setFileUpload(event.target.files);
          dispatch(setAccounCreationTeams(results.data));
          if(checkError) setCheckError(false)
        } else {
          console.log('am runing')
          setCheckError(true)
          setErrorMsg('Your file is missing some information, Please note the file should contains the name and email of invited member')
        }
      },
    });
  };

  const handleCloseIcon = () => {
    setFileUpload([])
  }

  const handleSendingNav = () => {
    navigate("/branches/bulk-account/send")
  }

  const handleDownload = () => {
    const fileUrl = 'https://res.cloudinary.com/mustyz/raw/upload/v1691765742/csvexample_f0s76l.csv'; // Replace with your file path

    // Open the file URL in a new tab/window
    window.open(fileUrl, '_blank');
  };

  return (
    <>
    
      <section>
        
        <AppLayout
        title="Bulk Account"
        subtitle="Create multiple team members at once"
        customTitle="Company">
          <div
            onClick={handleBranchBack}
            className="cursor inline-flex items-center rounded border-1 border-black p-1 dark:border-333"
          >
            <BsArrowLeft className='dark:text-white' /> <span className="ml-2 dark:text-white">Back</span>
          </div>

          <div className="mt-[70px] flex justify-center flex-col items-center">
            <div>
              <div className="flex items-center">
                <img src={require('../../../Assets/info.png')} alt="info" />
                <p className="font-medium text-sm ml-3 dark:text-white">
                  Make sure to name headers of CSV with:
                </p>
              </div>

              <div className="text-left pl-14 flex gap-16">
                <ul className="list-disc">
                  <li className="font-medium text-sm dark:text-white">Firstname</li>
                  <li className="font-medium text-sm dark:text-white">Lastname</li>
                  <li className="font-medium text-sm dark:text-white">Email</li>
                  <li className="font-medium text-sm dark:text-white">username</li>
                  <li className="font-medium text-sm dark:text-white">Phone</li>
                  <li className="font-medium text-sm dark:text-white">Gender</li>
                </ul>

                <ul className="list-disc">
                  <li className="font-medium text-sm dark:text-white">Country</li>
                  <li className="font-medium text-sm dark:text-white">Skillset</li>
                  <li className="font-medium text-sm dark:text-white">Privilege</li>
                  <li className="font-medium text-sm dark:text-white">Jobrole</li>
                  <li className="font-medium text-sm dark:text-white">Location</li>

                </ul>
              </div>
              <div className="mt-[47px]">
                <div className='flex gap-2 items-center mb-[15px]'>
                  <p className="font-semibold text-base dark:text-white ">Upload</p>
                  <p className='underline text-[12px] cursor-pointer dark:text-white' onClick={handleDownload}>Download csv sample</p>
                </div>
                <label htmlFor="upload-photo">
                <div className="w-[593px] flex flex-col items-center justify-center h-[257px] rounded border-1 border-black border-dashed dark:border-333">
                  <input  onChange={changeHandler} accept='.csv ' id="upload-photo" type="file"  style={{ display: 'none' }} />
                  <FileDownload />
                  <p className="mt-[17px] text-color-424  dark:text-white">Drop your CSV file(s) here or <span className="text-5F3 underline cursor-pointer dark:text-white">Browse</span></p>
                  <p className="text-A5A mt-[17px] dark:text-white">Max. file size 5MB</p>
                </div>
                </label>
              </div>
              {checkError && (<p className="text-[11px] text-red-700 mt-3">{errorMsg}</p>)}
              <div className="mt-[17px]">
                {fileUploads?.length > 0 && (<div className="p-[10px] border-1 border-9FD flex justify-between items-center rounded dark:border-333">
                  <div className="flex items-center">
                  <FileDisplay />
                  <p className="font-medium text-sm ml-2 dark:text-white">Team members</p>
                  </div>
                  <FileClose onClick={handleCloseIcon} className="cursor-pointer" />
                </div>)}
              </div>
              {!checkError && <div className="flex items-center mt-[53px] justify-between">
                <CustomButton onClick={handleBranchBack} className="w-[280px] justify-center dark:border-333 dark:text-white" showIcon={false} title="Cancel" />
                <CustomButton onClick={handleSendingNav} className="w-[280px] justify-center bg-base-color text-white" showIcon={false} title="Open file" />
              </div>}
            </div>
          </div>
        </AppLayout>
      </section>
    </>
  );
};

export default BulkAccountScreen;
