import {useState, useRef} from 'react'

import CustomModal from '../../../Widgets/CustomModal'
import {BsUpload} from 'react-icons/bs'
import {FiImage} from 'react-icons/fi'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import { uploadAnnouncementFile } from '../../../../Network/ServiceClass/announcementService'
import { CircularProgress } from '@mui/material'
import {BsFilePdf} from 'react-icons/bs'
import { toast } from 'react-toastify'
import trimText from '../../../../utils/trimText'
import { inventoryOrderAttachment } from '../../../../Network/ServiceClass/InventoryService'


const FileUploadUi = ({openAttachModal, handleCloseAttachModal, data, callback}) => {

    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    const [uploadFiles, setUpLoadFiles] = useState([])
    const [isUploadingFile,setIsUploadingFile] = useState(false)
    const [isLoading,setIsLoading] = useState(false)

    const makeUpload = (file) => {
        const uploadPromises = [];
      
        for (let i = 0; i < file.length; i++) {
          const formdata = new FormData();
          formdata.append('file', file[i]);
      
          const uploadPromise = uploadAnnouncementFile(formdata)
            .then((val) => {
              if (val.status === 200) return val.data;
              else throw new Error('Upload failed');
            });
      
          uploadPromises.push(uploadPromise);
        }
      
        // Wait for all promises to resolve
        return Promise.all(uploadPromises)
          .then((results) => {
            // results will contain an array of the data from successful uploads
            const successfulUploads = results.filter((result) => result !== undefined);
            setUpLoadFiles([...uploadFiles, ...successfulUploads]);
          })
          .catch((error) => {
            toast.error('Files allowed are image and pdf')
            // Handle errors
          });
      };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
          setDragActive(true);
        } else if (e.type === "dragleave") {
          setDragActive(false);
        }
      };

      const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setIsUploadingFile(true)
            makeUpload(e.dataTransfer.files)
            .then(_ => {
                setIsUploadingFile(false)
            })
        }
      };  

      const onButtonClick = () => {
        inputRef.current.click();
      };

      const handleChange = async (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setIsUploadingFile(true)
            makeUpload(e.target.files)
            .then(_ => {
                setIsUploadingFile(false)
            })
        }
      };

      const handleAttach = () => {
        setIsLoading(true)
        const datas = {
          orderIdentifier: data?.orderIdentifier,
          files: uploadFiles.map(val => ({
            url: val?.url,
            type: 'purchase_orders',
            attachmentName: val?.name
          }))
        }

        if (datas?.files.length === 0 ){
          toast.error("You've to attach a file")
          setIsLoading(false)
          return
        }
        inventoryOrderAttachment(datas)
        .then(val => {
          setIsLoading(false)
          if(val?.status === 201){

            toast.success('File Attached')
            handleCloseAttachModal()
            callback()
          } 
          // else toast.error(val?.data?.message)
          // console.log(val, datas)

        }).catch(err => {
          setIsLoading(false)
          console.log(err)

        })

      }

  return (
    <div>
        <CustomModal 
            modalClassName = 'w-[40%]'
            open={openAttachModal} 
            handleClose={()=>{
                setUpLoadFiles([])
                handleCloseAttachModal()
            }}>
            


            <div className='flex flex-col dark:text-white gap-3'>
                <p className='text-[20px] font-bold'>File Attachment</p>

                <div className={`flex flex-col gap-4 my-4 max-h-[150px] ${!isUploadingFile && 'overflow-y-auto'}`}>
                    {!isUploadingFile ? uploadFiles.map((val,index) => (

                        <div key={index} className='text-[32px] flex justify-between items-center'>
                            <div className='flex gap-1 items-center'>
                                {val?.type.split('/')[0] === 'image' ? <FiImage /> : <BsFilePdf className=''/>}
                                <p className='text-[14px]'>{trimText(val?.name,25,25) }</p>
                            </div>

                            <div className='flex gap-1 items-center'>
                                <a href={val?.url} target='_black' className='text-[12px] text-[#5175F3] cursor-pointer'>View attachment</a>
                                <MdOutlineDeleteOutline 
                                    className='text-[#FF4040] text-[24px] cursor-pointer'
                                    onClick={()=>{
                                        setUpLoadFiles([...uploadFiles.filter(x => x.name !== val?.name)])
                                    }}
                                />
                            </div>

                        </div>
                    )):
                    <div className='flex justify-center'><CircularProgress/></div>
                }
                </div>

                <input className='hidden' ref={inputRef} type="file" multiple={true} onChange={handleChange} />

                <div onDragEnter={handleDrag}
                    
                    className={`w-full flex items-center px-3  outline-1 outline-dashed ${dragActive ? 'outline-base-color outline-2' : 'outline-[#D3D3D3]'} rounded-md h-[230px]`}>
                    {!dragActive? <div className='w-full flex flex-col justify-center items-center gap-1'>
                        <BsUpload size={45} className='text-base-dark'/>
                        <p className='text-[16px] font-bold mt-2'>Add attachment to your order request.</p>
                        <p className='text-[14px] font-semibold'>Drag and drop document or photo to attach to your request or</p>
                        
                        <p 
                            onClick={onButtonClick}
                            className='cursor-pointer text-[14px] font-semibold text-base-dark underline'
                        >Choose Document.</p>

                    </div>: 
                    <div onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}
                    className='w-full h-[230px]'></div>}
                </div>

                {!isLoading ? <button 
                    onClick={handleAttach}
                    className={`self-end rounded-[3px] px-4 py-2 font-bold text-[white] hover:shadow-small-select bg-btn-color `}>
                    Attach Document
                </button>
                :
                <div className='self-end mr-6'><CircularProgress/></div>
                }
            </div>
        </CustomModal>
    </div>
  )
}

export default FileUploadUi