import React from 'react'
import TaskContainer from '../../Widget/TaskContainer/TaskContainer'
import { useSelector } from 'react-redux';

export const Overdue = ({theme}) => {
  const store = useSelector(state => state.task.taskStatuses);

  return (
    <div className='w-[30%] mt-5'>
    <TaskContainer color={theme.overdue} data={store?.statuses?.filter((status) => status.name === 'Overdue')[0]} label="Overdue" />
    </div>
  )
}
