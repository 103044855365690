import { BsSearch } from 'react-icons/bs';
import { SideBar } from '../../Widgets/SideBar';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { FetchBranchBenchmarkAsync, getBranchesAsync } from '../../../Store/Branches';
import CreateBenchmark from './Modal/BenchmarkModal';
import { CircularProgress } from '@mui/material';
import { IoIosArrowBack } from 'react-icons/io';
import LiveUsers from '../LiveUsers/LiveUsers';
import AppLayout from '../../Widgets/AppLayout';

export const Benchmarks = () => {
  const [openModal, setOpenModal] = useState(false);
  const [searchBenchMark, filterBenchmark] = useState('')
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate()

  const benchmarks = useSelector((state) => state.branches)?.benchmarks || [];
  const isLoading = useSelector((state) => state.branches)?.loading;
  const [branches] = useSelector((state) => state.branches)?.branches?.data?.filter((x) => x?.id === params?.branchId) || [];

  useEffect(() => {
    dispatch(getBranchesAsync())
    if (params?.branchId) {
      dispatch(FetchBranchBenchmarkAsync(params?.branchId));
    }
  }, []);

  const handleToggle = (state) => () => {
    setOpenModal(state);
  };

  const handleOnChange = (e) => {
    filterBenchmark(e.target.value)
  }

  return (
    <section>
      
      <AppLayout
      custom="company"
      subCustom="branch"
      customTitle=" ">
        {openModal && (
          <CreateBenchmark
            branchId={params?.branchId}
            open={openModal}
            handleClose={handleToggle(false)}
            dispatch={dispatch}
            isLoading={isLoading}
          />
        )}

        <div className='flex items-start gap-4'>
          <div className='mt-5'>
          <IoIosArrowBack size={25} className='cursor-pointer dark:text-white' onClick={()=>navigate(-1)} />
        </div>
        <BranchesHeader
          title={`Benchmarks - ${branches?.name}`}
          subtitle={`Set and update benchmarks for ${branches?.name}`}
        />
        </div>


        <section className="mt-[4vh] w-full shadow-select rounded-md">
          <div className="flex items-center justify-between mb-3 p-3">
            <div className="flex items-center gap-3 p-3 border rounded-md">
              <BsSearch />
              <input onChange={handleOnChange} placeholder="Search benchmarks" className="outline-none dark:bg-A06 dark:text-white" />
            </div>
            <ButtonWithIcon
              handleClick={handleToggle(true)}
              title="New"
              type="text"
            />
          </div>

          <table>
            <thead>
              <tr className="bg-0F5">
                <td className="py-4 pl-2">Benchmark name</td>
                <td className="py-4">Benchmark Target</td>
                <td className="py-4">Day Type</td>
              </tr>
            </thead>
            <tbody className="pb-3">
              {isLoading  && (
                <tr>
                  <td colSpan={3} className="border">
                    <div className='flex items-center justify-center py-24'>
                    <CircularProgress />
                    </div>
                  </td>
                </tr>
              )}
              {!isLoading && benchmarks?.length === 0 && benchmarks?.filter((bench) => bench?.name?.toLowerCase().includes(searchBenchMark?.toLowerCase()))?.length === 0 && (
                <tr>
                  <td colSpan={3} className="border">
                    <p className="text-center py-24 dark:text-white">
                      No Benchmarks available
                    </p>
                  </td>
                </tr>
              )}
              
                {
                  !isLoading && benchmarks?.filter((bench) => bench?.name?.toLowerCase().includes(searchBenchMark?.toLowerCase())).map((x) => <tr className="border">
                  <td className="py-4 pl-2"><p className=' dark:text-white'>{x?.name}</p></td>
                  <td className="py-4 dark:text-white">{x?.target}</td>
                  <td className="py-4 dark:text-white">{x?.dayType}</td>
                  </tr>)
                }
            </tbody>
          </table>
        </section>
      </AppLayout>
    </section>
  );
};
