import React,{useState, useEffect} from 'react'
import { Pie } from '@ant-design/plots';
import { useSelector} from 'react-redux';
import { TextAndField, FieldSelectWithOutFormik, Text } from '../../../../Widgets/FormUtils/FormUtils';
import {font} from '../../../../Widgets/Styles/font'
import { getJobeRoleDistro } from '../../../../../Network/ServiceClass/dashboardService';
import CustomDropdown from '../../../../Widgets/CustomDropdown';


import { CircularProgress } from '@mui/material';

const JobDistro = ({branches = []}) => {

 
  const [pieData, setPieData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState({
    label: 'All Locations',
    value: '1',
    hexcode: ''
  })
  const company = useSelector(state => state.company.activeCompany)



  useEffect(()=>{
    setIsLoading(true)
    getJobeRoleDistro()
    .then(val => {
      setIsLoading(false)
      setPieData(val.map(el => ({
        type: (el.jobRoleName === "Management" && el.symbol === "") ? 'MG' : el?.symbol,
        value: Number(el.count),
        color: el.hexcode === 'NULL' ? '#00000' : el.hexcode

      })))
    })
    .catch(()=> setIsLoading(false))
  },[company])

  const handleChangeBranch = (e) => {
    const value = e.value
    setSelectedBranch(e)
    if (value === '1') {
      getJobeRoleDistro()
      .then(val => {
        setIsLoading(false)
        setPieData(val.map(el => ({
          type: el.symbol,
          value: Number(el.count),
          color: el.hexcode === 'NULL' ? '#00000' : el.hexcode
  
        })))
      })
      .catch(()=> setIsLoading(false))
    } else {
      getJobeRoleDistro(value)
      .then(val => {
        setIsLoading(false)
        setPieData(val.map(el => ({
          type:(el.jobRoleName === "Management" && el.symbol === "") ? 'MG' : el?.symbol,
          value: Number(el.count),
          color: el?.hexcode === 'NULL' ? '#00000' : el.hexcode
  
        })))
      })
      .catch(()=> setIsLoading(false))
    }

  }


  const config = {
    appendPadding:0,
    padding:0,
    data: pieData,
    angleField: 'value',
    radius: 0.6,
    innerRadius: 0.8,
    
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '21px',
          ...font
        },
        content: 'Job roles',
      },
    },

    // label: {
    //     type: 'inner',
    //     offset: '-50%',
    //     content: '{value}',
    //     style: {
    //       textAlign: 'center',
    //       fontSize: 14,
    //     },
    //   },
    label: false,

    label: false,
      colorField: 'type', // or seriesField in some cases
      color: (type) => {
        const item = pieData.find((d) => d.type === type.type);
        return item ? item.color : undefined;
      },
  };

  


  return (
    <div className='flex flex-col p-3 shadow-shift-shadow rounded-lg h-[100%]'>
      <div className='flex justify-between w-[100%]'>
          <p className='text-sm font-medium dark:text-white'>Job Distribution</p>
          <div className='w-[35%]'>

            <TextAndField space={0.3}>
                <Text className='dark:text-white' text={'Select Location'}/>
                 <CustomDropdown
                trimtext
                trimLength={13}
                data={[
                  {
                    label: 'All Locations',
                    value: '1',
                    hexcode: ''
                  },
                  ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
                ]}
                className="h-10 rounded "
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={selectedBranch}
                onChangeOption={handleChangeBranch}
              />

            </TextAndField>
          </div>

      </div>
      {!isLoading && <Pie {...config} />}
      {isLoading && <div className='w-[100%] flex justify-center mt-3'><CircularProgress/></div>} 


    </div>
  )
}

export default JobDistro