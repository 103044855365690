import { create } from "zustand";
import { IntentsDefault } from "./types";

const setIntents = create((set)=>({
    intentList:[],
    generating:false,
    currentIntent:IntentsDefault,
    setCurrentIntent:(item)=>set({currentIntent:item}),
    setGenerating:(val)=>set({generating:val}),
    setIntentList:(arr)=>set({intentList:arr})
}))
export default setIntents