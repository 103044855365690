
import AppLayout from '../../../Widgets/AppLayout';


const list = [
  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699630117/nitro-help-videos/Shift_schedule_ipn0cl.mov',
    title: 'Shift Schedule',
    description: "Easily view, manage, and adjust the work shifts for you and your team. Ensure smooth operations and fair distribution of shifts. Keep track of employee availability and optimize staffing. Stay organized and avoid scheduling conflicts."

  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov',
    title: "Location Schedule",
    description: "Access detailed schedules for all company locations. Monitor staffing levels and operational hours across different sites. Ensure efficient coordination and communication between locations. Stay informed about location-specific activities."
  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699631696/nitro-help-videos/Create_Location_hica6w.mov',
    title: 'Create Location',
    description: "Add and configure a new company location to expand your business footprint. Manage all necessary details, from address to operational hours. Streamline the setup process and ensure consistency. Improve oversight and control of your growing business."
  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699631672/nitro-help-videos/Data_mwvaq8.mov',
    title: 'Company Data',
    description: "Access, update, and maintain all critical company information in one place. Keep records accurate and up-to-date. Ensure compliance with regulations and internal policies. Make informed decisions with reliable data at your fingertips."
  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699631619/nitro-help-videos/Holiday_gxjirz.mov',
    title: 'Holiday',
    description: "Check upcoming company holidays and ensure everyone is informed. Plan work schedules around these dates to maintain productivity. Communicate holiday policies clearly to all employees. Avoid last-minute surprises and scheduling issues."
  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699631196/nitro-help-videos/Inventory_imdlju.mov',
    title: 'Inventory',
    description: "Manage your inventory items efficiently and keep track of stock levels. Ensure accurate records and timely updates to avoid shortages. Facilitate smooth transfers between locations and departments. Optimize inventory management to reduce costs."
  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699630545/nitro-help-videos/Job_roles_group_announcement_m8wohq.mov',
    title: 'Job Role, Job Group & Announcement',
    description: "Organize roles, groups, and announcements for clear communication. Define job roles and group employees effectively. Keep everyone informed with timely announcements. Foster a well-structured and communicative workplace."
  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699630477/nitro-help-videos/Request_leave_t1t8xg.mov',
    title: 'Request Leave',
    description: "Submit your leave request quickly and easily through the system. Track the status of your requests and receive timely approvals. Ensure transparency and fairness in leave management. Keep everyone informed about availability."
  },

  {
    link: 'https://res.cloudinary.com/dllkyarpl/video/upload/v1699630329/nitro-help-videos/Task_dzq46f.mov',
    title: 'Task',
    description: "View and manage your tasks efficiently to stay on top of your workload. Prioritize assignments and track progress. Collaborate with team members and meet deadlines. Maintain productivity and achieve your goals."
  }
]


const Tutorials = () => {

    
  return (
    <div>
        <AppLayout
            custom="help"
            subCustom="tutorials"
            title="Tutorials"
            customTitle="Help"
            subtitle="View tutorials"
        >
            <div className='w-full flex justify-between flex-wrap'>

              {
                list.map((val, index) => (
                  
                  <VideoTemplate 
                    id={index}
                    link={val?.link}
                    title={val?.title}
                    text={val?.description}
                  />
                ))
              }

            </div>
            
        </AppLayout>
        
    </div>
  )
}

export default Tutorials


const VideoTemplate = ({link, title, id, text}) => {

  return (
    <div 
      key={id}
      className=' w-[48%] h-full mb-8 flex flex-col gap-2'>
      <video 
        className=''
        src={link}
        controls
      />

      <div>

        <p className='text-[18px] font-bold dark:text-white'>{title}</p>

        <p className='text-[14px] text-gray-600 dark:text-gray-300 text-justify'>
          {text}
        </p>
      </div>


    </div>
  )
}