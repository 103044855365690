
import React, {useState} from 'react'
import './ForgottenPassword.css';
import { Navbar } from '../../Widgets/Navbar'
import { Service } from '../../../Network/ServiceClass/Auth';
import { Loader } from '../../Widgets/Loader';
import { useSearchParams } from 'react-router-dom';
import { SuccessView } from './Widget/SuccessView';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {Text, TextAndField, Field, FillButton} from '../../Widgets/FormUtils/FormUtils'
import { CircularProgress, Stack, Box, Typography } from '@mui/material';
import {MdOutlineCancel} from 'react-icons/md'
import {font} from '../../Widgets/Styles/font'
import { useNavigate } from "react-router";



export const ResetPassword = () => {
    const navigate = useNavigate();

    const Auth = new Service();

    const [searchParams] = useSearchParams();
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const [success, setSuccess] = useState(false);

    const formik = useFormik({
        initialValues:{
          password:"",
          confirmPassword: ''
        },
    
        validationSchema: Yup.object({
          password: Yup.string().min(6).required("Please enter password"),
          confirmPassword: Yup.string().min(6).oneOf([Yup.ref('password'),null],'password must match').required("Please enter confirm password")

        }),
        onSubmit:(value)=>{
          ResetPassword(value.password)
        }
      }) 

    const ResetPassword = async(password)=>{   
        setIsLoading(true);
        Auth.ResetPassword({password: password, hash: searchParams.get('hash')}).then((value)=>{
            setIsLoading(false);
            setSuccess(true);
        }).catch((err)=>{
            console.log(err);
            setErrorMessage(err.response.data.message);
            setIsError(true) 
            setIsLoading(false);
        })
    }

  return (
    <div className='login-screen forgot'>

            <Navbar />


            {success === false && <div className='flex flex-col w-[386px] m-auto mt-[80px] items-center gap-5'>
                <p className='text-[22px] font-[500]'>Reset password</p>
                <p className='text-base'>Reset your password</p>
                
                <div className='flex flex-col gap-10 w-[100%]'>
                
                <TextAndField>
                <Text text={"Enter new password"} />
                <Field text={'Enter new password'}
                value={formik.values.password}
                handleChange={formik.handleChange}
                name={'password'}
                formik={formik} size="large" type={'password'}/>
                </TextAndField>

                <TextAndField>
                    <Text text={"Confirm password"} />
                    <Field text={'Confirm password'}
                    value={formik.values.confirmPassword}
                    handleChange={formik.handleChange}
                    name={'confirmPassword'}
                    formik={formik} size="large" type={'password'}/>
                    </TextAndField>
                    
                <div className="text-[10px] w-[80px] border-b-1 border-black cursor-pointer">
                    <span onClick={()=>navigate('/login')}>Back to log in</span>
                </div>
            

                {isError && 
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '0.2rem',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    padding: '0.5rem'
                }}>
                    <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                    <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
                </Box>}

                <div>
                    {!isLoading && <FillButton text={'Reset password'} width={'100%'}  height={'50px'} callBack={formik.handleSubmit}/>}
                    {isLoading && <Stack direction='row' justifyContent='center' ><CircularProgress/></Stack> }
                </div>

            </div>
            </div> }
            {success === true && <SuccessView />}
        </div>
  )
}
