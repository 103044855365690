import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Filter from './Widgets/Filter/filter';
import DropDownMenu from './Widgets/Filter/dropDown';
import Calendar from './Widgets/Calendar/Calendar';
import List from './Widgets/List/List';
import { font } from '../../../../Widgets/Styles/font';
import RequestTimeOff from './Widgets/RequestTimeOff/RequestTimeOff';
import AlertDialog from '../../../../Widgets/SuccessDialog';
import { TimeOffService } from '../../../../../Network/ServiceClass/TimeOffService';
import { setEmployeeList } from '../../../../../Store/timeOff';
import { setWeeklyDateLabel } from '../../../../../Store/Shift';
import { getWeek } from '../../../../../utils/getWeek';
import formatDate from '../../../../../utils/formatDate';
import { Storage } from '../../../../../Network/StorageClass/StorageClass';
import ButtonWithIcon from '../../../../Widgets/ButtonWithIcon';

import './TimeOff.css';
import { Typography } from '@mui/material';
import { BiCalendar } from 'react-icons/bi';
import { VscListSelection } from 'react-icons/vsc';
import NavigateCalender from '../../Widget/NavigateCalender/NavigateCalender';
import AppLayout from '../../../../Widgets/AppLayout';
import TutorialPanel from '../../../../Widgets/Tutorial/TutorialPanel';
import { timeOffTutorial } from '../../../mock';
import { getTeammemberAsync } from '../../../../../Store/Branches';

const timeOffService = new TimeOffService();
const timeOffStorage = new Storage();

export const TimeOff = () => {
  const isAdmin = timeOffStorage.getActiveCompany().memberships[0].privilege;

  const dispatch = useDispatch();
  const [mode, setMode] = useState('list')

  useEffect(() => {
    // load labels date
    dispatch(
      setWeeklyDateLabel({
        to: formatDate(getWeek(0).lastDay.toUTCString()),
        from: formatDate(getWeek(0).firstDay.toUTCString()),
      })
    );

    if ([1, 2].includes(isAdmin)) {
      // load filter list
      timeOffService
        .fetchTimeOff()
        .then((value) => {
          if (value.status !== 200) throw new Error('');
          // console.log(value.data)
          dispatch(setEmployeeList(value.data));
        })
        .catch((error) => {
          console.log(error);
        });

        dispatch(getTeammemberAsync({}))
    }


    
  }, []);

  const [openMenu, setOpenMenu] = React.useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const [openRequestTimeOff, setOpenRequestTimeOff] = useState(false);
  const handleOpenRequestTimeOff = () => {
    setOpenRequestTimeOff(true);
  };

  const handleOptionSelected = (option) => {
    setMode(option)
  }

  return (
    <section className="homepage">
      <AlertDialog />
      {openMenu && [1, 2].includes(isAdmin) && (
        <DropDownMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
      )}
      <RequestTimeOff
        openModal={openRequestTimeOff}
        setOpenModal={setOpenRequestTimeOff}
      />
      <AppLayout
        custom="shift"
        subCustom="time-off"
        title="Time off"
        customTitle=""
        subtitle="Manage time off requests"
        RightComponent={<TutorialPanel items={timeOffTutorial} />}
      >
          <div className="w-full flex justify-between">
            <div className="flex items-center">
            {[1, 2].includes(isAdmin) && (
                  <p
                    className="filter"
                    onClick={(event) => handleOpenMenu(event)}
                  >
                    {/* <Filter className="dark:text-white" /> */}
                  </p>
                )}
                <div onClick={()=>handleOptionSelected('calendar')} className='flex items-center ml-3 gap-2 cursor-pointer'>
                    <Typography className={mode === "calendar" ? "text-blue-600 dark:text-white" : " dark:text-969" } fontSize={'24px'} ><BiCalendar/></Typography> 
                    <Typography className={`${mode === "calendar" ? "text-blue-600 dark:text-white" : "dark:text-969"}` } sx={{...font, fontSize: '12px'}}>Calendar</Typography>
                </div>

                <div onClick={()=>handleOptionSelected('list')} className={`flex items-center mx-3 gap-2 cursor-pointer`}>
                <Typography className={mode === "list" ? "text-blue-600 dark:text-white" : "dark:text-969" } fontSize={'24px'}><VscListSelection /></Typography>
                    <Typography className={`${mode === "list" ? "text-blue-600 dark:text-white" : "dark:text-969" }`} sx={{...font, fontSize: '12px'}}>List</Typography>
                </div>
                <NavigateCalender diff={7} />

            </div>
            <ButtonWithIcon
                  title="Request time off"
                  p="5px"
                  withIcon={false}
                  className="dark:border-333 dark:text-white"
                  handleClick={handleOpenRequestTimeOff}
                />
          </div>

          <div className="main-body">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                ...font,
              }}
            ></div>
            <div className="tabpanel mt-8">
                {mode === "calendar" && (<Calendar />)}
                {mode === "list" && (<List />)}
            </div>
          </div>

      </AppLayout>
      
    </section>
  );
};
