import React from 'react';
import CustomModal from '../../../Widgets/CustomModal';
import GlobalButton from '../../../Widgets/GlobalButton';
import { useFormik } from 'formik';
import * as yup from 'yup';

function ConfirmModal({
  open = false,
  loading = false,
  handleClose = () => {},
  handleWithdrawItem = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      patientId: '',
    },
    validationSchema: yup.object().shape({
      patientId: yup.string().required('This is required'),
    }),
    onSubmit: (value) => {
      setTimeout(() => {
        handleWithdrawItem(value.patientId);
      }, 400);
    },
  });

  const handleSkipBtn = () => {
    handleWithdrawItem('');
  };

  return <CustomModal
      open={open}
      handleClose={handleClose}
      modalClassName="max-w-[500px]"
      parentContainer="mt-[0px] h-screen"
    >
      <div className="w-full">
        <input
          name="patientId"
          onChange={formik.handleChange}
          className={`outline-none mt-8 w-full rounded-md border p-3 ${
            formik.errors.patientId ? ' border-red-500' : 'border-777'
          }`}
          placeholder="Enter PatientId"
        />
        {formik.errors && (
          <p className="text-[8px] text-red-500">{formik.errors.patientId}</p>
        )}
        <div className="flex items-center gap-10 mt-5 w-full justify-center">
          <GlobalButton onClick={loading ? null : handleSkipBtn} title="Skip" />
          <GlobalButton
            type="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            title="Confirm"
          />
        </div>
      </div>
    </CustomModal>
}

export default ConfirmModal;
