import React, {useState} from 'react'
import { SideBar } from '../../../Widgets/SideBar'
import { BranchesHeader } from '../../BranchScreen/BranchesHeader/BranchesHeader'

import SearchInput from '../../../Widgets/SearchInput'
import TablePagination from '@mui/material/TablePagination';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';




const DailyReportItem = () => {

    const navigate = useNavigate()
    const { itemId } = useParams();

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  return (
    <section>
        <AppLayout
            custom="inventory"
            subCustom="inventory-report"
            title="Report"
            customTitle="Inventory"
            subtitle=""
        >
            
            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                <Link to="/inventory-report">REPORT</Link>
                <Link to="/inventory-daily-report"> / DAILY WITHDRAWAL REPORT</Link>
                <span className="text-A5A"> / LOCATION 1 FRIDAY 20/12/2023</span>
                </p>
            </div>

            <div className='flex gap-2 items-center mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-10'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={()=>{}}
                />


            </div>

            <div className='mt-6 max-h-[70vh] overflow-y-auto '>
                <table className=''>
                    <tr className=' bg-0F5 text-grey-800 sticky top-0'>
                        <td className='pl-4 py-2 rounded-tl-md w-[200px]'>Date</td>
                        <td className='w-[200px] '>Time</td>
                        <td className='w-[200px] '>User</td>
                        <td className='w-[200px] '>Location</td>
                        <td className='w-[200px] rounded-tr-md'>Reference number</td>

                    </tr>

                    <tbody>
                        {
                            Array.from({length:20}).map(val => (
                                <tr className='border-b-1 text-A5A'>
                                        <td className='py-2 pl-4'>
                                        12/12/2022
                                        </td>

                                        <td>12:11:01</td>
                                        <td>Mc kenson</td>
                                        <td>Walmart</td>
                                        <td>1020200030</td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>

            </div>
            <TablePagination
                className='dark:text-grey-600'
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </AppLayout>
       
    </section>
  )
}

export default DailyReportItem