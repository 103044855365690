import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import CompanyReducer from '../Store/Company';
import ShiftReducer from '../Store/Shift'
import SwapOffersReducer from './swapOffers'
import TimeOffReducer from './timeOff'
import TaskReducer from './Task'
import WriteReducer from './Writeup'
import RotationReducer from './Rotation'
import Branches from './Branches'
import Announcement from "./announcement";
import Dashboard from "./dashboard";
import Holiday from "./holiday";
import Utils from "./utils";
import ChatGpt from "./ChatGpt";
import Inventory from "./Inventory";
import SocketReducer from "./socketSlice";
import TroubleTicketReducer from "./Ticket";

const customSerializabilityCheck = getDefaultMiddleware({
    serializableCheck: {
    //   ignoredActionPaths: ['socket/emitEvent'], // Ignore specific actions
      ignoredPaths: ['socket.socket'], // Ignore specific state paths
    },
  });

export const Store = configureStore({
    reducer:{
        company: CompanyReducer,
        shift: ShiftReducer,
        swapOffers: SwapOffersReducer,
        timeOff: TimeOffReducer,
        task: TaskReducer,
        writeup: WriteReducer,
        rotations: RotationReducer,
        branches: Branches,
        announcement: Announcement,
        dashboard: Dashboard,
        utils: Utils,
        holidays: Holiday,
        chatGpt: ChatGpt,
        inventory: Inventory,
        socket: SocketReducer,
        ticket: TroubleTicketReducer,
    },
    middleware: [...customSerializabilityCheck],
})