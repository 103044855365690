import React, {useEffect, useState} from 'react'
import { Navbar } from '../../Widgets/Navbar'

import { Loader } from '../../Widgets/Loader';
import './VerifyEmailAddress.css'
import { Service } from '../../../Network/ServiceClass/Auth';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Synclogo from "../../../Assets/sync_light.svg";
import SyncdarkLogo from "../../../Assets/sync_logo.svg";


export const VerifyEmailAddress = () => {

  const Auth = new Service(); 
  const navigate = useNavigate();
  const mode = localStorage.getItem('color-theme') ?? 'light'

  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState("");

  useEffect(()=>{

    Auth.confirmMail(searchParams.get('hash')).then((value)=>{
      console.log(value.data);
      navigate("/");
    }).catch((value)=>{
      console.log(value.response.data.message);
      setMessage(value.response.data.message);
    });
      
  }, []);

  return (
    <div>
        <Navbar />
        <section className='verify-email-body'>
          <div className='the-card'>
            <img src={mode === 'light' ? Synclogo : SyncdarkLogo} alt="logo" style={{marginBottom:"20px"}}/>
            <p>Your email is being verified</p>
            {message==="" ?<Loader />:<p style={{color:"red", fontSize:"14px"}}>{message}</p>}
          </div>
        </section>
    </div>
  )
}
