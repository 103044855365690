import React from 'react'
import FormModal from '../../../Widgets/Animation/FormModal'
import { Stack } from '@mui/material'
import { useDispatch } from 'react-redux';
import { deleteJobRolesAsync } from '../../../../Store/Branches';
import { Loader } from '../../../Widgets/Loader';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxHeight: '90vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const DeleteJobRoleModal = ({ open, handleClose, data, loading, callback }) => {
  const dispatch = useDispatch()

  const handleDeleteJobRole = () => {
    dispatch(deleteJobRolesAsync({ jobId: data.id})).then(res => {
      console.log(res)
      if(res.meta.requestStatus === "fulfilled"){
        toast.success(`${data?.title} was successfully deleted`);
        callback()
        handleClose()
      }

      if(res.meta.requestStatus === "rejected"){
        toast.error(res?.error?.message);
      }
    })
  }

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className="dark:bg-A06 dark:text-white" direction="column" alignItems="center" sx={style} gap={3} >
        <div>
          <p className="font-medium text-lg">Are you sure you want to delete {data?.title} role</p>
          {!loading ? (<div className="flex items-center justify-between mt-10 h-[40px] gap-3">
            <button onClick={handleClose} className="rounded w-full h-full border border-color-424">No</button>
            <button onClick={handleDeleteJobRole} className="bg-171 text-white h-full font-semibold rounded w-full">Yes</button>
          </div>): <Loader />}
        </div>
      </Stack>
    </FormModal>
  )
}