import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';

const PieChart = ({data, config}) => {
  
 
  return <Pie {...{...config, data}} />;
};

export default PieChart