import React, {useEffect} from 'react'
import { Navbar } from '../../Widgets/Navbar'
import './SignUpScreen.css';
import { Stepper } from './Widgets/Stepper';
import { FiArrowUpRight } from 'react-icons/fi';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Service } from '../../../Network/ServiceClass/Auth';
import { Storage } from '../../../Network/StorageClass/StorageClass';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import SkillSet from './Widgets/SkillSet/SkillSet';
import {Text, TextAndField, Field, FieldSelect, FillButton} from '../../Widgets/FormUtils/FormUtils'
import { CircularProgress, Stack, Typography, Box } from '@mui/material';
import {font} from '../../Widgets/Styles/font'
import {MdOutlineCancel} from 'react-icons/md'
import { toast } from 'react-toastify';



export const SignUpScreen = () => {

  const navigate = useNavigate();
  const storage = new Storage();

  //States
  
  const [index, setIndex] = useState(0);
  const [countries, setCountries] = useState([]);
  const [skills, setSkills] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [chosenSkills, setChosenSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const phoneRegExp = /^(\+\d)\s?-?\s?(\d{0,4})+\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{1,4}\)?)?$/

  const formik = useFormik({
    initialValues: index === 0 ?
    {
      firstName:"",
      lastName:"",
      userName:"",
      email:"",
      countryId:"none",
     
    } : {
      password:"",
      phone:"",
      gender:"none",
      confirmPassword: ''
    },

    validationSchema: index === 0 ? Yup.object({
      firstName: Yup.string().min(2, "Invalid name").required("Please enter first name"),
      lastName: Yup.string().min(2, "Invalid name").required("Please enter last name"),
      userName: Yup.string().min(2, "Invalid name").required("Please enter user name"),
      email: Yup.string().email('must be a valid email').required("Please enter valid email address"),
      countryId: Yup.string().notOneOf(['none',null],'Select a country')
    }): Yup.object({
      
      password: Yup.string().min(6, "Password is too short").required("Please enter your password"),
      phone: Yup.string().required("Enter phone number").matches(phoneRegExp, 'Phone number is not valid: +172727272 | +1-111-111-11'),
      gender: Yup.string().notOneOf(['none',null],'Choose a gender').required('Choose a gender'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'),null],'Password must match').required('Password must match')

    }),
    onSubmit: index === 0 ? (value)=>{
      SwitchProgress(1)
    }: (value)=>{
      setIsLoading(true)
      // console.log(
      //   (searchParams.get('hash')!==null) ?
      //   {...value,skillSetIds:chosenSkills, device:{type:"web",token:"1234"}}:
      //   {...value,skillSetIds:chosenSkills,  inviteHash: searchParams.get('hash'), device:{type:"web",token:"1234"}});
    
        const data = {
          firstName: value.firstName,
          lastName: value.lastName,
          userName: value.userName,
          email: value.email,
          password: value.password,
          countryId: value.countryId,
          phone: value.phone,
          gender: value.gender,
          skillSetIds: chosenSkills.map(val => val.id),
          device: {
              "type": "web",
              "token": "NmM3Yy0xNTEzLTRlNDItOGI0Yi1mMTI4"
          }
        }

        localStorage.setItem("auth", JSON.stringify(data));

        if(searchParams.get('hash')===null){
            auth.createAccount(JSON.stringify(data)
            ).then((value)=>{
              setIsLoading(false);
              toast.success('Please check your email for verification')
              navigate('/login');
            }).catch((err)=>{
              console.log(err); setIsLoading(false);
              setErrorMessage(err.response.data.message);
              setIsError(true)
            });
        }
        else{
          auth.createAccount(JSON.stringify({...data, inviteHash: searchParams.get('hash')}
          )).then((value)=>{
            toast.success('Please check your email for verification')
            setIsLoading(false);
            navigate('/login');
            
          }).catch((err)=>{
            console.log(err);
            setIsLoading(false);
            setErrorMessage(err.response.data.message);
            setIsError(true)
          });
      }
    }
  }) 


  const auth = new Service();

  useEffect(()=>{

    if(searchParams.get('hash')!==null)
    console.log(searchParams.get('hash'));

    auth.getCountries().then((value)=>{
      setCountries(value);
    }).catch((err)=>{
      console.log(err);
    });

    auth.getSkillSet().then((value)=>{
      setSkills(value.data.sort());
      // console.log(value.data)
    }).catch((err)=>{
      console.log(err);
    })


  },[])


  const SwitchProgress = (value) => {
    if(value === 1 && formik.values.firstName !== "" && formik.values.lastName !== "" && formik.values.email !== "" && formik.values.countryId !== "")
    setIndex(value);
    else if(value === 2 && formik.values.phone !== "")
    setIndex(value);
    else if(value === 3 && chosenSkills.length !== 0)
    setIndex(value)
  }


  // handling skillset modal
  const [openSkillSet, setOpenSkillSet] = useState(false)


  return (
    <div className='SignUpScreen dark:text-white'>

      <Navbar />

      <section className='sign-body'>
        <div className='sign-inner'>
          <Stepper index={index} SwitchProgress={SwitchProgress}/>

          <section className='main'>
            <div>
              <center><p style={{ fontSize: "24px", paddingTop: "20px" }}>Welcome to Supersync</p></center>
              <hr style={{ backgroundColor: "#E6E6E6" }}></hr>
            </div>
            <center>
              <div className='info'>Let us get you started, we would like to get some details</div>
            </center>
            <center>

              {(index === 0) && <div className='form basic-info'>

                <form className='flex flex-col gap-[20px]'>
                  <TextAndField>
                    <Text text={"First Name"} />
                    <Field text={'Kindly enter first name'}
                      value={formik.values.firstName}
                      handleChange={formik.handleChange}
                      name={'firstName'}
                      formik={formik} size="large"/>
                  </TextAndField>
                  
                  <TextAndField>
                    <Text text={"Last Name"} />
                    <Field text={'Kindly enter last name'}
                      value={formik.values.lastName}
                      handleChange={formik.handleChange}
                      name={'lastName'}
                      formik={formik} size="large"/>
                  </TextAndField>

                  <TextAndField>
                    <Text text={"User Name"} />
                    <Field text={'Kindly enter user name'}
                      value={formik.values.userName}
                      handleChange={formik.handleChange}
                      name={'userName'}
                      formik={formik} size="large"/>
                  </TextAndField>

                  <TextAndField>
                    <Text text={"Email"} />
                    <Field text={'Kindly enter email address'}
                      value={formik.values.email}
                      handleChange={formik.handleChange}
                      name={'email'}
                      formik={formik} size="large"/>
                  </TextAndField>

                  <TextAndField>
                    <Text text={"Country"} />
                    <FieldSelect text={'Select Country'}
                      list={countries}
                      value={formik.values.countryId}
                      name={'countryId'}
                      placeholder={'Choose country'}
                      formik={formik} size="large" width='100%' height='50px'/>
                  </TextAndField>
                                  
                  <FillButton text={'Continue'} width={'100%'} height={'50px'} callBack={formik.handleSubmit}/>
                  <RegLink navigate={navigate}/>
                </form>
              </div>}

              {(index === 1) && <div className='form skill'>
                <form className='flex flex-col gap-[20px]'>

                  <TextAndField>
                    <Text text={'Skills'}/>
                    <div className='min-w-[356px] h-[50px] cursor-pointer border-1 dark:border-333 flex flex-col justify-center pl-2 border-[#d3d4d8] text-[13px] rounded-[4px]'
                    onClick={()=>setOpenSkillSet(true)}>Select your skills</div>
                  </TextAndField>
                  {openSkillSet === true && <div className= {!openSkillSet ? 'hidden': 'block'}>
                    <SkillSet data={skills} setOpenSkillSet={setOpenSkillSet} chosenSkills={chosenSkills} setChosenSkills={setChosenSkills}/>
                  </div> }
                  
                  <TextAndField>
                    <Text text={"Gender"} />
                    <FieldSelect 
                      list={[{name:'Male', id:'male'},{name: 'Female', id: 'female'}]}
                      value={formik.values.gender}
                      name={'gender'}
                      placeholder={'Select gender'}
                      formik={formik} size="large" width='100%' height='50px'/>
                  </TextAndField>

                  <TextAndField>
                    <Text text={"Phone"} />
                    <Field text={'Enter phone number'}
                      value={formik.values.phone}
                      handleChange={formik.handleChange}
                      name={'phone'}
                      formik={formik} size="large"/>
                  </TextAndField>

                  <TextAndField>
                    <Text text={"Password"} />
                    <Field text={'Enter password'}
                      value={formik.values.password}
                      handleChange={formik.handleChange}
                      name={'password'}
                      formik={formik} size="large" type={'password'}/>
                  </TextAndField>

                  <TextAndField>
                    <Text text={"Confirm password"} />
                    <Field text={'Enter confirm password'}
                      value={formik.values.confirmPassword}
                      handleChange={formik.handleChange}
                      name={'confirmPassword'}
                      formik={formik} size="large" type={'password'}/>
                  </TextAndField>

                  {isError && 
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '0.2rem',
                      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                      padding: '0.5rem'
                    }}>
                      <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                      <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
                    </Box>}

                  {!isLoading && <FillButton text={'Sign Up'} width={'100%'} height={'50px'} callBack={formik.handleSubmit}/>}
                  {isLoading && <Stack direction='row' justifyContent='center' ><CircularProgress/></Stack> }
                  <RegLink navigate={navigate}/>
                </form>
              </div>}

            </center>
          </section>

        </div>
      </section>

    </div>

  )


}



const RegLink = ({navigate}) => {
  return (
    <div className='flex flex-row items-center gap-1 text-[10px]'>
      <p className='text-[#696969]'>Already have an account,</p>
      <p className='ml-[10px] cursor-pointer' onClick={() => navigate("/login")}>Log in</p>
      <FiArrowUpRight />
  </div>
  )
}
