
export const convertTime = (input) => {
  const [hour, minutes] = input.split(":");
  const convHr = Number(hour);
  const convMin = Number(minutes);

  if(convHr >= 12 && convMin >= 0){
    if(convHr > 12){
      const hrStr = (convHr - 12).toString().padStart(2, '0');
      const minStr = convMin.toString().padStart(2, '0');
      return `${hrStr}:${minStr}pm`
    }
    return `${input}pm`
  } else {
     return `${input}am`
  }
}



export const transformDate = (dateMilli) => {
  const date = new Date(dateMilli);

  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  // Generate yyyy-mm-dd date string
  return year + '-' + month + '-' + day;
}