import React, { useEffect } from 'react'
import { Stack } from "@mui/material";
import FormModal from "../../../../Widgets/Animation/FormModal";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

const containerStyle = {
  width: '800px',
  height: '500px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

function GoogleMapView({ open = false, data, handleClose = () => { } }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(7)
    map.panTo({lat: (data["latitude"] && Number(data["latitude"])), lng: (data["longitude"] && Number(data["longitude"])) })

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleOnload = (e) => {
    // console.log(e)
  }

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack direction="column" alignItems="center" sx={style} gap={3}>
        <div className="relative px-2">
          {isLoaded && data["latitude"] && data["longitude"] ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: (data["latitude"] && Number(data["latitude"])), lng: (data["longitude"] && Number(data["longitude"])) }}
              // center={{ lat: (data["latitude"] && Number(data["latitude"])), lng: (data["longitude"] && Number(data["longitude"])) }}
              zoom={6}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              { /* Child components, such as markers, info windows, etc. */}
              <MarkerF onLoad={handleOnload} position={{ lat: (data["latitude"] && Number(data["latitude"])), lng: (data["longitude"] && Number(data["longitude"])) }} />
            </GoogleMap>
          ) : <></>
          }

          <button onClick={handleClose} className='p-2 mt-3 w-[100px] border border-171 text-171'>Close</button>
        </div>
      </Stack>
    </FormModal>
  )
}



export default GoogleMapView