import React from 'react'
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { Stack } from '@mui/material';
import { ReactComponent as GoogleIcon } from '../../../../../../Assets/google_icon.svg'
import { ReactComponent as LinkIcon } from '../../../../../../Assets/link.svg'
import { ReactComponent as OutLook } from '../../../../../../Assets/outlook.svg'
import { Loader } from '../../../../../Widgets/Loader';
import { checkLinkAccounts } from '../../../../../../utils/util';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '533px',
  height: '409px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const GoogleConnectModal = ({ open = false, handleClose = () => { }, handleLinkToGoogle = () => { }, handleScheduleToOutLook = () => {}, connected = false, loading, membershipId }) => {
  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack direction="column" alignItems="center" sx={style} gap={3}>
        <p>Connect Calendar</p>
        <div className='border border-6E6 border-t-0 border-r-0 border-l-0 p-[10px] w-[440px]'>
          <div className='flex items-center justify-between '>
            <div className='flex items-center gap-3'>
              <GoogleIcon />
              <p>Google Calendar</p>
            </div>
            {!loading ? (connected || checkLinkAccounts('google') ? <LinkIcon /> : (<button onClick={handleLinkToGoogle} className='border-D57 rounded p-2 border'>Sync</button>)) : (<Loader style={{ padding: '5px'}} />)}
          </div>
          <div className='flex items-center justify-between mt-4 '>
            <div className='flex items-center gap-3'>
              <OutLook />
              <p>office 365 + outlook</p>
            </div>
            {!loading ? (connected || checkLinkAccounts('microsoft') ? <LinkIcon /> : (<button onClick={handleScheduleToOutLook} className='border-D57 rounded p-2 border'>Sync</button>)) : (<Loader style={{ padding: '5px'}} />)}
          </div>
          {
           !loading && connected && JSON.parse(localStorage.getItem("user"))?.linkedEmails[membershipId]?.email && <div className='flex items-center gap-5'>
              <p className='text-sm text-787 font-medium p-[5px] rounded-[10px] bg-8FF'>{JSON.parse(localStorage.getItem("user"))?.linkedEmails[membershipId]?.email}</p>
              <p className='text-26E text-base font-medium'>Connected</p>
            </div>
          }
        </div>
        <button onClick={handleClose} className='border-D57 border rounded-md w-[70%] py-2 mt-32'>Cancel</button>
      </Stack>
    </FormModal>
  )
}