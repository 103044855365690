import React from 'react'
import { SideBar } from '../../Widgets/SideBar'
import { BranchTabs } from './BranchTab/BranchTab'
import "./BranchScreen.css";
import { Branches } from './Tabs/Branches/Branches'
import { useState } from 'react'
import { CustomersSettings } from './Tabs/CustomerSettings/CustomersSettings'
import { TeamMembers } from './Tabs/TeamMembers/TeamMembers'
import { BranchesHeader } from './BranchesHeader/BranchesHeader'
import { Storage } from '../../../Network/StorageClass/StorageClass'
import { TeamMemberDetails } from './Tabs/TeamMembers/TeamMembersDetails/TeamMembersDetails'
import {OutlinedButton, FillButton} from '../../Widgets/FormUtils/FormUtils'
import AppLayout from '../../Widgets/AppLayout';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';

const BranchScreen = () => {
  const storage = new Storage();
  const priviledge = storage.getActiveCompany()?.memberships[0]?.privilege
  
  const [index, setIndex] = useState(priviledge === 3 ? 2 : 0);
  const [branchId, setBranchId] = useState('')
  const [branchName, setBranchName] = useState('')
  const [selectedUser, setUser] = useState({})
  
  
  const branchSubtitle = ()=>{
    if(index === 0 ){
      return "Information on location details";
    }
     else if((index === 2) || (index === 3))
     return "Details on team memebers";
     else 
     return "Check and manage the settings of the company";
  }

  const branchTitle = ()=>{
    if(index === 0 ){
      if(priviledge === 2) return 'Locations I manage'
      return "Manage location information";
    }
     else if((index === 2) || (index === 3))
     return "Team members";
     else 
     return "";
  }
  const [edit, setEdit] = useState(false);

  return (
    <section className='homepage'>
       <AppLayout
        custom="company"
        subCustom="branch"
        title={branchTitle()}
        customTitle=" "
        subtitle={branchSubtitle()}
        RightComponent={<TutorialPanel items={[{ title: "Location", desc: "Watch how to add location roles to your organization scheduler."}]} />}
      >

          <div style={{display:"inline-flex",alignItems:"flex-start",justifyContent:"space-between", width:"100%"}}>
          {priviledge !== 3 && <BranchTabs index={index} setIndex={setIndex} /> }
          {(index === 1 && priviledge === 1) &&
            <>
            {!edit && <OutlinedButton text={'Edit'} callBack={()=> setEdit(true)}/>}
            {edit && 
            <div className='flex gap-2'>
              <OutlinedButton text={'Cancel'} callBack={()=> setEdit(false)}/>
              {/* <FillButton width="max-content" text={'Save Changes'}/> */}
            </div>
            }
            </>
          }
          </div>
        

          {index === 0 && <Branches setIndex={setIndex} setBranchId={setBranchId} setBranchName={setBranchName}/>}

          {/* {index === 1 && <CustomersSettings edit={edit} setEdit={setEdit} />} */}

          {index === 2 && <TeamMembers setUser={setUser} setIndex={setIndex} branchId={branchId} branchName={branchName}/>}
          {index === 3 && <TeamMemberDetails setIndex={setIndex} selectedUser={selectedUser} branchId={branchId} branchName={branchName}/>}
      </AppLayout>

       
      


    </section>
  )
}

export default BranchScreen