import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {Box, Stack} from '@mui/material';
import {BiCalendar} from 'react-icons/bi'
import {VscListSelection} from 'react-icons/vsc'
import {font} from '../../../../Widgets/Styles/font'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({children}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    
    <Box sx={{ width: '100%', }}>
      <Box sx={{display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between', }}>
        <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example" className='h-[10px]' >
          
        <Tab className='dark:text-white' sx={{textTransform: 'inherit', justifyContent: 'flex-center',alignItems:'center',  minWidth:'25px', padding: '0 0',}}
           label={ <Typography sx={{...font, fontSize: '16px'}}>All</Typography> } 
         {...a11yProps(0)} />


          <Tab className='dark:text-white' sx={{textTransform: 'inherit', justifyContent: 'flex-center',alignItems:'center', marginLeft:'15px', minWidth:'50px', padding: '0 0',}}
           label={ <Typography sx={{...font, fontSize: '16px'}}>Archived</Typography> } 
         {...a11yProps(1)} />

          <Tab className='dark:text-white' sx={{textTransform: 'inherit', justifyContent: 'flex-center',alignItems:'center', marginLeft:'15px', minWidth:'50px', padding: '0 0',}}
           label={<Typography sx={{...font, fontSize: '16px'}}>Favorite</Typography>}
         {...a11yProps(2)} />

        </Tabs>
        {children[3]}
      </Box>
      <TabPanel sx={{with: '100vw'}} value={value} index={0}>
        {children[0]}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {children[1]}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {children[2]}
      </TabPanel>
      
    </Box>
  );
}

const Label = ({children}) => {
    return(
        <>
        <Box sx={{
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
        }}>
            <Typography sx={{fontSize: '22px',}}>{children[0]}</Typography>
            <Typography sx={{fontSize: '12px',}}>{children[1]}</Typography>

        </Box>
        </>
    )
}