import React, { useState, useEffect } from 'react';
import { SideBar } from '../../Widgets/SideBar';
import './CompanyData.css';
import CreateAccountModal from './Widget/Modal/CreateAccountModal';
import { Home } from '../../../Network/ServiceClass/Home';
import { NoCompany } from './Widget/NoCompany/NoCompany';
import { Loader } from '../../Widgets/Loader';
import { VerifyEmail } from './Widget/VerifyEmail/VerifyEmail';
import { useDispatch } from 'react-redux';
import { setCompanies, setDp, setActiveCompany } from '../../../Store/Company';
import AlertDialog from '../../Widgets/SuccessDialog';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import { Storage } from '../../../Network/StorageClass/StorageClass'
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import AppLayout from '../../Widgets/AppLayout';

import VerifyAdmins from './VerifyAdmins';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { companyDataTutorial } from '../mock';

export const CompanyData = ({ verify }) => {
  const dispatch = useDispatch();

  const home = new Home();

  const [createAccont, setCreateAccount] = useState(false);
  const [accountData, setAccountData] = useState();

  const [status, setStatus] = useState('loading');

  const storage = new Storage();
  const isAdmin = storage.getActiveCompany()?.memberships[0]?.privilege

  const handleOpen = () => {
    setCreateAccount(true);
  };
  const handleClose = () => {
    setCreateAccount(false);
  };

  const callback = () => {
    home
      .getHomeData()
      .then((value) => {
        setAccountData(value.data);
        if (value.data.companies.length !== 0) {
          // dispatch(setActiveCompany(value.data.activeCompany));
          dispatch(setCompanies(value.data.companies));
          dispatch(setDp(value.data.user.picture));
        }
        setStatus('fetched');
      })
      .catch((err) => {
        if (err.response.status === 401) setStatus('empty');
        else setStatus('failed');
      });
  };
  const refresh = () => {
    home
      .getHomeData()
      .then((value) => {
        if (value.data.companies.length !== 0) {
          dispatch(setActiveCompany(value.data.activeCompany));
          dispatch(setCompanies(value.data.companies));
          dispatch(setDp(value.data.user.picture));
        }
        setStatus('fetched');
        setAccountData(value.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    callback();
  }, []);

  return (
    <div className="homepage">
      <AlertDialog />
      <AppLayout
        custom="company"
        subCustom="data"
        title="Company Data"
        customTitle="Data"
        subtitle="View Data"
        RightComponent={<TutorialPanel items={companyDataTutorial} />}
      >

        <section className="overview">
          {status === 'fetched' ? (
            accountData.companies.length !== 0 ? (
              <>
                <div className="flex justify-between items-end">
                 
                </div>

                <VerifyAdmins />
              </>
            ) : (
              <NoCompany open={handleOpen} callback={refresh} />
            )
          ) : status === 'loading' ? (
            <Loader />
          ) : status === 'empty' ? (
            <>
              <VerifyEmail />
            </>
          ) : (
            <></>
          )}
        </section>
      </AppLayout>

      <div>
        {status === 'fetched' && (
          <CreateAccountModal
            open={createAccont}
            handleClose={handleClose}
            callback={callback}
          />
        )}
      </div>
    </div>
  );
};
