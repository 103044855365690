import moment from 'moment';

function generateCalendarDates(year, month) {
    const firstDate = moment(year + '-' + month + '-01', 'YYYY-MM-DD').startOf('month');
    const newFirstDate = firstDate.day() === 0 ? firstDate : firstDate.subtract(firstDate.day() , 'days')

    const lastDate = moment(year + '-' + month + '-01', 'YYYY-MM-DD').endOf('month')
    const newLastDate = lastDate.day() === 6 ? lastDate : lastDate.add(6 - lastDate.day(), 'days')

    const diff = newLastDate.diff(newFirstDate, 'days') + 1

    const dateArr = Array.from(
        Array(
            diff
        ).keys()
    ).map((x) => moment(newFirstDate).add(x, 'days').format("YYYY-MM-DD"))

    return dateArr;

}

export default generateCalendarDates;