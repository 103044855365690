const change24HrsPmTo12hrsPm = (hour) => {
    let value
    switch (hour) {
        case 13:
            value = 1
            break;

        case 14:
            value = 2
            break;    
        case 15:
            value = 3
            break;
        case 16:
            value = 4
            break;
        case 17:
            value = 5
            break;
        case 18:
            value = 6
            break;
        case 19:
            value = 7
            break;
        case 20:
            value = 8
            break;
        case 21:
            value = 9
            break;
        case 22:
            value = 10
            break;
        case 23:
            value = 11
            break;
        case '00':
            value = '00'
            break;
        default:
            value = hour
            break;
    }

    return value

}

export default change24HrsPmTo12hrsPm