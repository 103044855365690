import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const getOverView = async (from,to) => {
    const response = await axios.get(`${conn_url}/api/v1/home/admin/overview-summary?from=${from}&to=${to}`, storage.getConfig());
    return response.data;
 
}

export const getWorkedHours = async (from,to) => {
    const response = await axios.get(`${conn_url}/api/v1/home/admin/hours-worked?from=${from}&to=${to}`, storage.getConfig());
    return response.data;
 
}

export const getWorkedHoursNonAdmin = async () => {
    const response = await axios.get(`${conn_url}/api/v1/home/member/hours-worked-summary`, storage.getConfig());
    return response.data;
 
}

export const getShiftSummaryNonAdmin = async (from,to) => {
    const response = await axios.get(`${conn_url}/api/v1/home/member/shift-booking-summary?from=${from}&to=${to}`, storage.getConfig());
    return response.data;
 
}

export const getUpcommingShiftNonAdmin = async () => {
    const response = await axios.get(`${conn_url}/api/v1/home/member/upcoming-shift`, storage.getConfig());
    return response.data;
 
}

export const updatePushStatus = async (data) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"));
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    const response = await axios.put(`${conn_url}/api/v1/push-notification-status/${member?.id}`,data, storage.getConfig());
    return response.data;
 
}

export const getShiftSwap = async (from,to) => {
    const response = await axios.get(`${conn_url}/api/v1/home/admin/member-shift-swaps?from=${from}&to=${to}`, storage.getConfig());
    return response.data;
 
}

export const getEmployeeOvertime = async (branchId) => {
    if(branchId === undefined){
        const response = await axios.get(`${conn_url}/api/v1/home/admin/employee-overtime`, storage.getConfig());
        return response.data;

    }else {
        const response = await axios.get(`${conn_url}/api/v1/home/admin/employee-overtime?branchId=${branchId}`, storage.getConfig());
        return response.data;
    }
 
}

export const getShiftCancellation = async (from,to) => {
    const response = await axios.get(`${conn_url}/api/v1/home/admin/shift-cancellation?from=${from}&to=${to}`, storage.getConfig());
    return response.data;
 
}

export const getOpenSlot = async () => {
    const response = await axios.get(`${conn_url}/api/v1/home/admin/open-slots`, storage.getConfig());
    return response.data;
 
}

export const getGraphDetails = async (query) => {
    const response = await axios.get(`${conn_url}/api/v1/shift-rotations-details?${query}`, storage.getConfig());
    return response.data;
 
}

export const getJobeRoleDistro = async (id) => {
    let query 
    if(id === undefined) query = `/api/v1/company/job-roles-distribution`
    else query = `/api/v1/company/job-roles-distribution/${id}`
    const response = await axios.get(`${conn_url}${query}`, storage.getConfig());
    return response.data;
}

export const getSkillSetStat = async (id) => {
    let query 
    if(id === undefined) query = `/api/v1/users/skill-sets-stats`
    else query = `/api/v1/users/skill-sets-stats?branchId=${id}`
    const response = await axios.get(`${conn_url}${query}`, storage.getConfig());
    return response.data;
}

export const getUnavailableStaffTimeLine = async (data,id) => {
    let query 
    if(id === undefined) query = `/api/v1/unavailable-staff-timeline`
    else query = `/api/v1/unavailable-staff-timeline/${id}`
    const response = await axios.post(`${conn_url}${query}`, data, storage.getConfig());
    return response.data;
 
}


export const getGlobalSearch = async (word) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"));
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    const response = await axios.get(`${conn_url}/api/v1/search/powerful-search?word=${word}&teamMembershipId=${member.id}`, storage.getConfig());
    return response.data;
 
}
