import React from 'react'
import { useSelector } from 'react-redux'
import ButtonWithIcon from '../../../../../Widgets/ButtonWithIcon';
import { Box } from '@mui/material';

export const BranchButton = ({handleOpen, branches, className="", ...props}) => {

    const activecompany_privilege = useSelector((state)=>state.company.activeCompany)?.memberships[0]?.privilege;

    return (
        activecompany_privilege !== 1 ? <></> : 
        <Box marginRight={'20px'}><ButtonWithIcon title='New' type='text' handleClick={handleOpen} className={className} {...props}/></Box>
        
    )
}
