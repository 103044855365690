import React from 'react';
import FormModal from '../../../../../../Widgets/Animation/FormModal';
import { Stack } from '@mui/material';
import {
  FillButton,
  OutlinedButton,
} from '../../../../../../Widgets/FormUtils/FormUtils';
import moment from 'moment';

function ViewShiftSlot({
  open,
  handleClose,
  shiftSlotData = {},
  setEdit,
  data,
  setOpenShiftSlot,
  setShiftSlotData,
  selectedData,
}) {

  const handleOnClickDateSelection = (date) => {
    const assignee = data?.assignees
      ?.filter(
        (x) =>
          moment(x.shiftStartDate).format('HH:mm') ===
            date?.split('-')[0] &&
          moment(x.shiftEndDate).format('HH:mm') ===
            date?.split('-')[1]
      )
      ?.map((user) => user?.userId);
    setShiftSlotData((prev) => ({ ...prev, selectedTimeData: date, assignee }));
    setEdit(true);
    handleClose();
    setOpenShiftSlot(true);
  }

  return (
    <FormModal open={open} handleClose={handleClose}>
      <div className="flex justify-center h-screen items-center">
        <div className="w-[567px] max-h-[700px] flex flex-col items-center p-[71px] bg-white">
          <p className="font-semibold text-base">Shifts on {moment(selectedData?.datesString)?.format("Do MMM")}</p>
          <p className="font-medium text-[10px] mt-[27px]">{selectedData?.branchName}</p>
          <p className="font-medium text-[10px] mb-[61px]">{selectedData?.jobName}</p>
          {Object.keys(shiftSlotData)?.map((key) => (
            <div
              onClick={() => handleOnClickDateSelection(key)}
              key={key}
              className="flex cursor-pointer mb-9 w-full justify-between items-center border-2 py-[15px] px-[10px] rounded-lg bg-5F5"
            >
              <p className="font-normal text-sm text-black-500">{key}</p>
              <p className="font-normal text-[9px] text-black-500">
                {shiftSlotData[key]} spaces booked
              </p>
            </div>
          ))}

          <Stack
            direction="row"
            width="82%"
            justifyContent="space-between"
            mt={10}
            gap={5}
          >
            <OutlinedButton
              text="Cancel"
              width="200px"
              callBack={() => {
                handleClose();
              }}
            />

            <FillButton
              width="200px"
              text="New"
              callBack={() => {
                handleClose();
                setShiftSlotData({})
                setOpenShiftSlot(true);
              }}
            />
          </Stack>
        </div>
      </div>
    </FormModal>
  );
}

export default ViewShiftSlot;
