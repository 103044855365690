import React, { useState } from 'react';
import AddButton from '../AddButton/AddButton';
import TaskCard from '../TaskCard/TaskCard';
import './TaskContainer.css';
import { TaskModal } from '../Modal/TaskModal/TaskModal';
import { useDispatch, useSelector } from 'react-redux';
import { changeTaskStatusAsync, updatePriorityStatusAsync } from '../../../../../Store/Task';

const TaskContainer = ({ color, label, data, searchedTask }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.task);

  const [task, setTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleOnDrop = async (e, data) => {
    e.preventDefault();
    const fromId = e.dataTransfer.getData('dragStartStatus');
    const payload = {
      taskId: store.selectedTask.id,
      data: { statusId: data.id, position: data.position },
      fromStatusId: fromId,
      destinatedStatusId: data.id,
      tasks: {...store.selectedTask, statusId: data.id, position: data.position},
      existingStatus: store.taskStatuses,
    }

    dispatch(
      changeTaskStatusAsync(payload)
    );
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  const handleUpdateStatus = (data) => {
    const payload = {
      taskId: selectedTask.id,
      data: { statusId: data.value, position: data.position },
      fromStatusId: selectedTask.statusId,
      destinatedStatusId: data.value,
      tasks: {...selectedTask, statusId: data.value, position: data.position},
      existingStatus: store.taskStatuses,
    };
    dispatch(changeTaskStatusAsync(payload)).then(() => {
      setTask(false);
    });
  };


  const handleUpdatePriority = (data) => {
    const payload = {
      taskId: selectedTask.id,
      data: {priority: data.value},
      statusId: selectedTask.statusId,
      task: {...selectedTask, priority: data.value}
    }
    dispatch(updatePriorityStatusAsync(payload))
  }

  const handleOnTaskClick = (val) => {
    setSelectedTask(val);
    setTask(true);
  }

  const handleSeachedTask = (searchedTask) => {
    const filteredData =data?.tasks?.filter((task) => task?.title?.toLowerCase().includes(searchedTask?.toLowerCase()))
    return filteredData;
  }

  return (
   
    <section
      droppable
      onDragOver={handleOnDragOver}
      onDrop={(e) => handleOnDrop(e, data)}
      className="w-[100%] bg-BEB dark:bg-E1E rounded-t-md"
    >


      <div className="p-[10px] bg-gray-600 rounded-t-[5px] font-bold text-white" 
        style={{ backgroundColor: color.p }}>
        {label}{' '}

    
        <span className="py-[1px] px-[5px] mt-[2px] bg-gray-600" style={{ backgroundColor: color.s }}>
          {data?.tasks?.length || 0}
        </span>
      </div>

      <div className="list pb-6 dark:bg-E1E">
        {handleSeachedTask(searchedTask)?.map((val) => (
          <div
            onClick={() => handleOnTaskClick(val)}
          >
            <TaskCard statusId={data.id} data={val} />
          </div>
        ))}

        {/* <center>
          <AddButton />
        </center> */}
      </div>

      <TaskModal
        handleUpdateStatus={handleUpdateStatus}
        handleUpdatePriority={handleUpdatePriority}
        selected={selectedTask}
        open={task}
        handleClose={() => setTask(false)}
      />
    </section>
  );
};

export default TaskContainer;
