import archiveImg from '../../../../Assets/trash.png'
import likeImg from '../../../../Assets/heart.png'
import darkLikeImg from '../../../../Assets/dark-like.png'
import darkArchive from '../../../../Assets/dark-archive.png'
import trimText from '../../../../utils/trimText'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';


export const AnnouncementCard = ({data, onClick=()=>{}}) => {
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;  

    return (
      <div
        onClick={onClick} 
        className='h-[216px] shadow-shift-shadow-small dark:shadow-222 flex flex-col p-3 cursor-pointer justify-between rounded-[10px] dark:border-333'>
          
          {/* first row Header*/}
          <div className='flex flex-row justify-between items-center'>
              <p className='text-[16px] font-bold dark:text-white'>{data?.title}</p>
              <p className='text-[12px] font-medium text-[#696969] dark:text-white'>{dayjs(data?.createdOn).format('MM-DD-YYYY h:mm A')}</p>
          </div>
  
          {/* second row Body */}
          <div className='text-[14px] font-semibold text-[#7F7F7F] dark:text-white'>
             {trimText(data?.description, 200, 200)}
          </div>
  
          {/* Third row  Footer*/}
          <div className='flex flex-row justify-between items-center font-bold'>
              <div className='text-[14px] flex flex-row items-center gap-3 dark:text-white'>
                <p className='w-[30px] h-[30px] rounded-full bg-[#D9D9D9]'/>
                <p className='font-semibold text-[#119DA4]'>From {data?.createdBy?.firstName} {data?.createdBy?.lastName}</p>
              </div>
              
              
              <div className='flex flex-row items-center gap-3'>
                <img src={data.sentiments?.length > 0 ? darkLikeImg : likeImg} alt="heart" />

                {[1,2].includes(privilege) && (<img src={data.archived ? darkArchive : archiveImg} alt="trash" />)}
              </div>
          </div>
      </div>
    )
  }
  