import { Typography } from '@material-ui/core';
import { BsTrash } from 'react-icons/bs';


const WriteUpContainerCard = ({ header = '', desc = '', date = '', handleOpenModal, assignee = '', handleDeleteWriteUp}) => {

  return (
    <div onClick={(e) => {
      e.preventDefault();
      handleOpenModal();
      }} className="p-[10px] shadow-md rounded-[5px] flex flex-col justify-between hover:shadow-lg cursor-pointer dark:shadow-555 dark:border-333">
      <div>
      <div className="p-[6px] bg-BEB rounded-[6px] mb-4 dark:bg-1F1">
        {header && (<p className='text-center text-[11px] font-semibold text-969  dark:text-white'><span>{header}</span> to <span className="text-black">{assignee}</span></p>)}
      </div>
      <p variant='body1' className="font-semibold text-[16px] dark:text-white">{desc}</p>
      </div>
      <div className="mt-4 flex item-center justify-between">
        <p className="m-0 text-[11px] text-grey-600">{date}</p>
        <BsTrash onClick={handleDeleteWriteUp} color="#C15353" className="cursor-pointer" />
      </div>
    </div>
  )
}

export default WriteUpContainerCard;
