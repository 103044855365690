
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeTaskStatus, updatePriorityStatus, getTaskStatuses } from '../Network/ServiceClass/TaskService';


 export const changeTaskStatusAsync = createAsyncThunk('tasks/changeTaskStatus', changeTaskStatus);
 export const updatePriorityStatusAsync = createAsyncThunk('tasks/updatePriorityStatus', updatePriorityStatus);
 
 export const getTaskStatusAsync = createAsyncThunk('tasks/getTaskStatuses', getTaskStatuses);


 const TaskSlice = createSlice({
  name: 'task',
  initialState: {
    tasks: [],
    taskStatuses: [],
    newTaskStatus: [],
    selectedTask: {},
    loading: false,
  },
  reducers: {
    setTaskLoading: (state, action) =>{
      state.loading = action.payload
      return state;
    },
    selectedTask:(state, action) => {
      state.selectedTask = action.payload;
      return state
    },
    getTaskStatus: (state, action) => {
      state.taskStatuses = action.payload;
      return state;
    }
  },
  extraReducers: {
   [getTaskStatusAsync.pending]: (state) => {
    state.loading = true
   },
   [getTaskStatusAsync.fulfilled]: (state, action) => {
    state.newTaskStatus = action.payload
    state.loading = false
   },
   [getTaskStatusAsync.rejected]: (state) => {
    state.loading = false
   },
   [changeTaskStatusAsync.pending]: (state) => {
    state.loading = true
   },
   [changeTaskStatusAsync.fulfilled]: (state, action) => {

    let [updatedTask] = action.payload?.existingStatus?.statuses?.filter((status) => status.id === action.payload.fromStatusId)
      const remindingTasks = action.payload?.existingStatus?.statuses?.filter((status) => status.id !== action.payload.fromStatusId)
      // remove the task from previous status
      updatedTask = { ...updatedTask, tasks: updatedTask.tasks.filter((task) => task.id !== action.payload.taskId)}


      // get the destinated status
      let [destinatedStatus] = remindingTasks.filter((task) => task.id === action.payload.destinatedStatusId)
      destinatedStatus = {...destinatedStatus, tasks: [...destinatedStatus?.tasks, { ...action.payload.tasks}]}

      
      let existingStatus = remindingTasks.filter((task) => task.id !== action.payload.destinatedStatusId)
      state.taskStatuses.statuses = [...existingStatus, destinatedStatus, updatedTask]
    state.loading = false;
    return state
   },
   [changeTaskStatusAsync.rejected]: (state) => {
    state.loading = false;
   },
   [updatePriorityStatusAsync.pending]: (state) => {
    state.loading = true;
   },
   [updatePriorityStatusAsync.fulfilled]: (state, action) => {
    let filteredStatus = state.taskStatuses.statuses.filter((status) => status.id !== action.payload.statusId);
    const [updatedStatus] = state.taskStatuses.statuses.filter((status) => status.id === action.payload.statusId);
    // existing task
    let existingTasks = updatedStatus.tasks.filter((task) => task.id !== action.payload.taskId)
    existingTasks = [...existingTasks, action.payload.task]

    // update the state on the status
    updatedStatus.tasks = existingTasks

    filteredStatus = [...filteredStatus, updatedStatus];

    state.taskStatuses.statuses = filteredStatus;

    state.loading = false;
    return state;
   },
   [updatePriorityStatusAsync.rejected]: (state) => {
    state.loading = false;
   },
  }
})


export const { selectedTask, getTaskStatus, setTaskLoading } = TaskSlice.actions;

export default TaskSlice.reducer;