import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"; // if using DnD

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

export const MyCalendar = (props) => (
  <div className="myCustomHeight">
     <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={[
            {
              start: moment().toDate(),
              end: moment()
                .add(1, "days")
                .toDate(),
              title: "Some title"
            },
            {
                start: moment().toDate(),
                end: moment()
                  .add(4, "days")
                  .toDate(),
                title: "Some guesses"
              }
          ]}
          style={{ height: "50vh" }}
        />
  </div>
)