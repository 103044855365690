import React,{useState} from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import {OutlinedButton, FillButton} from './FormUtils/FormUtils'
import FormModal from './Animation/FormModal';


const DisplaySuccess = ({isLoading,openModal, handleClose, data, extraFunction=()=>{}}) => {
  
  const anim = {
    hidden: {opacity: 0},
    vissible: {
      opacity: 1,
      transition: {
        ease: 'easeIn', duration: 1
      }
    },
    exit: {
      opacity: 0,
      transition: {
        ease: 'easeOut', duration: 0.5
      }
    }
  }

  const handleCallBack = () => {
    extraFunction()
    handleClose()
  }
 

  return (
    <FormModal open={openModal} >
      <AnimatePresence>
          <motion.div variants={anim} exit='exit' className='bg-white w-[516px] max-h-[556px] mt-[50px] m-auto rounded-sm'>
              <div className='flex flex-col py-3 px-10 gap-5'>

                <p className='font-semibold self-center'>Result Summary</p>
               
                <div className='flex gap-6'>
                    <div className='flex flex-col gap-2 font-semibold'>
                        <p>Successes:</p>
                        <p>Failures:</p>
                    </div>

                    <div className='flex flex-col gap-2'>
                        <p>{data?.success}/{data?.total}</p>
                        <p>{data?.failed}/{data?.total}</p>
                    </div>
                </div>

                <div className=''>
                    <p className='pb-3 font-semibold'>Failed Attempts: </p>
                    <ul className='flex flex-col gap-2 max-h-[300px] overflow-y-auto'>
                        {data?.err?.map(val => {

                           return <li className=''>
                                <div className='flex gap-2 ml-2'> <p>--</p>  <p>{val.userName ?? `${val.firstName} ${val.lastName}`}</p> </div>
                                <p className='text-[14px] ml-8 text-[grey]'>reason: {val.reason}</p>
                            </li>
                        })}


                    </ul>
                </div>

                <div className='self-center'><FillButton 
                callBack={handleCallBack} width={'100px'}
                disable={data?.loading}
                text={data?.loading ? 'Loading...' : 'Close'}
                /></div>
                
              </div>

          </motion.div>
        </AnimatePresence>
    </FormModal>
  )
}

export default DisplaySuccess