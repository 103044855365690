import React from 'react'
import DateLabel from '../../ShiftScreen/Widget/DateLabel/DateLabel';
import moment from 'moment';
import { CiSearch } from 'react-icons/ci';


const CombineScheduleRotationView = ({
    dates,
    combinedSchedules,
    selectedJobRole,
    selectedUser,
    selectedSkill,
    selectedGender,
    shiftSchedules,
    setCellBackground,
    toggleModal,
    searchTeam,
    selectJob,
    selectBranch,
    selectDate,
    handleOpenTeamList,
    openTeamList,
    cellLoading,
    toggleState,
    searchedMember,
    teamMembers,
    handleCreateShift,
    selectedMultiBranch
}) => {

    return (
        <section className="mt-16 mb-48 shadow-shift-shadow rounded-sm bg-white dark:bg-A06 overflow-x-scroll">
          <table className="w-[3700px] mb-20">
            <thead className="">
            <tr className="bg-white sticky top-0 z-[49] dark:bg-1F1">
              <td
                className={`w-[200px] border border-t-0 border-l-0 z-50 rounded-t-sm dark:border-333`}
              >
                <p className="px-3 w-[170px]"></p>
              </td>
              {dates?.map((value, index) => {
                
                return (
                  <td
                    className={`cursor-pointer border border-t-0 w-[100px] p-4 dark:!border-333 ${[0, 6].includes(value?.getDay())
                        ? 'bg-calendar-weekend dark:bg-A06'
                        : ''
                      }`}
                  >
                    <DateLabel
                      padding={'10px'}
                      detailsWeek={value}
                      type="week"
                      layoutProps={{
                        headerClass: "dark:text-white",
                        subHeaderClass: "dark:text-969"
                      }}
                    />
                  </td>
                );
              })}
              </tr>
            </thead>
            <tbody>
              {/* {branches?.map((branch) => (
                <>
                  <td className="border border-x-0 pl-5 dark:border-333" colSpan={8}>
                    <p className="font-semibold text-xl text-black my-4 dark:text-white">
                      {branch?.name}
                    </p>
                  </td>
                  {jobRoles?.map((job) => (
                   
                  ))}
                </>
              ))} */}
              {
                Object.keys(combinedSchedules)?.filter((x) => {
                  if(selectedMultiBranch?.length > 0){
                    return selectedMultiBranch?.map((y) => y?.value).includes(x)
                  }
                  return true
                })?.map((branchId) => (<>
                <tr>
                <td
                  className="border border-x-0 pl-5 z-50 sticky left-0 dark:border-333"
                  colSpan={8}
                >
                  <p className="font-semibold text-xl text-black my-4 dark:text-white">
                    {combinedSchedules[branchId]?.name}
                  </p>
                </td>
                </tr>
                {
                  combinedSchedules[branchId]?.roles?.filter((x) => x.id.includes(selectedJobRole.value || ''))?.map((role) => <>
                  
                   <tr>
                      <td className="border z-50 sticky left-0 bg-white border-l-0 dark:border-333">
                        <p className="font-semibold text-sm text-black my-3 pl-10 dark:text-white">
                          {role?.title}
                        </p>
                        {/* <div
                          style={{
                            backgroundColor:
                              role?.hexcode !== 'NULL' ? role?.hexcode : 'white',
                          }}
                          className={`w-2 h-2 rounded-full bg-[${role?.hexcode !== 'NULL' ? role?.hexcode : 'white'
                            }] absolute bottom-1 right-1`}
                        /> */}
                      </td>
                      {dates.map((date) => (
                        <td
                          className={`border dark:border-333 ${[5, 6].includes(moment(date).day()) ? 'bg-calendar-weekend dark:!bg-1F1' : ''
                            } ${moment(date).day() === 6 ? 'border-r-0' : ''}
                          ${moment(date).format('yyyy-MM-DD') ===
                              moment(new Date()).format('yyyy-MM-DD')
                              ? 'bg-[#E2FFF2] dark:bg-A06'
                              : ''
                            }
                          ${(selectedUser.value !== '' &&
                          selectedSkill.value !== '' &&
                          selectedGender.value !== '' ) &&
                          setCellBackground(
                            shiftSchedules
                              ?.filter(
                                (x) =>
                                  moment(x.start).format('yyyy-MM-DD') ===
                                  moment(date).format('yyyy-MM-DD') &&
                                  (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                  x?.branchId === branchId
                              )
                              ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y })),
                            selectedUser,
                            selectedSkill,
                            selectedGender
                          ) ? " bg-green-200" : ""
                            }
                          `}
                          onDoubleClick={() => {
                            toggleModal(true)
                            searchTeam('')
                            selectJob(role);
                            selectBranch(branchId);
                            selectDate(date);
                          }}
                          onClick={() => {
                            searchTeam('')
                            handleOpenTeamList(
                              moment(date).format('yyyy-MM-DD'),
                              role,
                              {id: branchId}
                            )

                          }
                          }
                        >
                          <div className="relative">
                            <p className={`text-sm font-semibold text-black whitespace-nowrap w-[100px] overflow-hidden text-ellipsis px-2 dark:text-white`}>
                              {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(date).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                    x?.branchId === branchId
                                )
                                ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y }))
                                ?.map((x) => x?.name)
                                ?.join(' / ')}
                            </p>

                            {openTeamList.date ===
                              moment(date).format('yyyy-MM-DD') &&
                              openTeamList?.jobId === role?.id &&
                              openTeamList?.branchId === branchId &&
                              cellLoading && (
                                <p className="px-2">Loading....</p>
                              )}

                            {openTeamList.date ===
                              moment(date).format('yyyy-MM-DD') &&
                              openTeamList?.jobId === role?.id &&
                              openTeamList?.branchId === branchId &&
                              toggleState && (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className="absolute top-full bg-white dark:bg-292 right-0 shadow-select w-max max-h-[12rem] overflow-y-scroll z-[1000]"
                                >
                                  <div className="px-2 sticky top-0">
                                    <div className="border-1 dark:border-none flex flex-row gap-1 items-center dark:text-white bg-white dark:bg-A06 py-[10px] px-2 rounded-md">
                                    <CiSearch />
                                    <input
                                      type="text"
                                      className=" outline-none dark:bg-A06"
                                      value={searchedMember}
                                      placeholder="search"
                                      onFocus={(e) => e.stopPropagation()}
                                      onChange={(e) =>
                                        searchTeam(e.target.value)
                                      }
                                    />
                                  </div>
                                  </div>


                                  {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(date).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                    x?.branchId === branchId
                                )?.filter((user) =>{
                                  const team = teamMembers.find((v) => v?.user.id === user.user.id);
                                  return team?.assignedBranch.id === branchId
                                })?.length > 0 && (
                                      <div className="sticky top-[46px] min-h-[40px] bg-white">

                                        <div className=" border-b-1 my-2 pb-4 px-2">
                                          {
                                            shiftSchedules
                                            ?.filter(
                                              (x) =>
                                                moment(x.start).format('yyyy-MM-DD') ===
                                                moment(date).format('yyyy-MM-DD') &&
                                                (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                                x?.branchId === branchId
                                            )?.filter((user) =>{
                                              const team = teamMembers.find((v) => v?.user.id === user.user.id);
                                              return team?.assignedBranch.id === branchId
                                            })?.map((x) => <p className="text-sm">{x?.user.firstName} {x?.user?.lastName}</p>)

                                          }
                                        </div>

                                      </div>
                                    )}
                                    <div className="px-4 pt-4">

                                    {teamMembers
                                      ?.filter(
                                        (team) => team?.jobRole?.id === role?.id || team?.secondaryJobroles?.some((x) => x?.jobroleId === role?.id)
                                      )
                                      .filter(
                                        (x) =>
                                          x?.user?.firstName
                                            .toLowerCase()
                                            .includes(
                                              searchedMember.toLowerCase()
                                            ) ||
                                          x?.user?.lastName
                                            .toLowerCase()
                                            .includes(
                                              searchedMember.toLowerCase()
                                            )
                                      ).filter((z) => !shiftSchedules
                                      ?.filter(
                                        (x) =>
                                          moment(x.start).format('yyyy-MM-DD') ===
                                          moment(date).format('yyyy-MM-DD') &&
                                          (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                          x?.branchId === branchId
                                      ).map((shiftUser) => shiftUser.userId).includes(z.user.id))
                                      .map((team) => (
                                        <p
                                          onClick={() =>
                                            handleCreateShift(
                                              { id: branchId },
                                              moment(date).format(
                                                'yyyy-MM-DD'
                                              ),
                                              team,
                                              role
                                            )
                                          }
                                          className="py-2 text-sm cursor-pointer hover:text-green-600 dark:hover:bg-color-333 dark:text-white"
                                        >
                                          {team?.user?.firstName}{' '}
                                          {team?.user?.lastName}
                                        </p>
                                      ))}
                                    </div>
                                </div>
                              )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </>)
                }
                </>))
              }
            </tbody>
          </table>
        </section>
    )
}


export default React.memo(CombineScheduleRotationView);