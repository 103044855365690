export const Boardtheme = {
    notstarted: {
        p:"#676767",
        s:"#D7D7D7"
    },
    inprogress: {
        p:"#FFCC00",
        s:"#FFE167"
    },
    done: {
        p:"#00AD57",
        s:"#ACEBB9"
    },
    overdue: {
        p: "#D7D7D7",
        s: "#C1C1C1"
    }
}