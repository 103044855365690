import axios from "axios";
import { Storage } from "../StorageClass/StorageClass";
import { conn_url } from "../conn_consts";

const storage = new Storage();
const isAdmin = storage.getActiveCompany() === null ? null : storage.getActiveCompany().memberships[0].privilege

export class TimeOffService{


    async requestTimeOff(data){

        const response = await axios.post(`${conn_url}/api/v1/time-off/request`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
      
    }

    async requestTimeOffForAnotherUser (data){
      
        const response = await axios.post(`${conn_url}/api/v1/time-off/admin-request`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

   
    async approveTimeOff(data){
        const response = await axios.post(conn_url+`/api/v1/time-off/${data}/approve`,{}, storage.getConfig());
        return {data: response.data, status: response.status};
    }

    async declineTimeOff(data){
        const response = await axios.post(conn_url+`/api/v1/time-off/${data}/decline`,{}, storage.getConfig());
        return {data: response.data, status: response.status};
    }

    async fetchTimeOff(data){
        if([1,2].includes(isAdmin)){

            if (data === undefined || data === null){
                const response = await axios.get(conn_url+`/api/v1/time-off`, storage.getConfig());
                return {data: response.data, status: response.status};
            }else if(data.id !== undefined || data.branchId !== undefined) {
                let response
                if(data.id === 'none' && data.branchId === 'none'){
                    response = await axios.get(conn_url+`/api/v1/time-off`, storage.getConfig());
                }else if(data.id !== 'none' && data.branchId === 'none'){
                    response = await axios.get(conn_url+`/api/v1/time-off?userId=${data.id}`, storage.getConfig());
                }else if(data.id === 'none' && data.branchId !== 'none'){
                    response = await axios.get(conn_url+`/api/v1/time-off?branchId=${data.branchId}`, storage.getConfig());
                }else {
                    response = await axios.get(conn_url+`/api/v1/time-off?userId=${data.id}&branchId=${data.branchId}`, storage.getConfig());
                }
                return {data: response.data, status: response.status};
            }else {
                const response = await axios.get(conn_url+`/api/v1/time-off?from=${data.from}&to=${data.to}`, storage.getConfig());
                return {data: response.data, status: response.status};
            }
        }else {
            const response = await axios.get(conn_url+`/api/v1/time-off/me`, storage.getConfig());
            return {data: response.data, status: response.status};
        }
    }

   

}