import { CircularProgress } from '@material-ui/core';
import { BsPlus } from 'react-icons/bs';

const GlobalButton = ({title = '', containerClass = '', iconProp, className = '', showIcon = false, Icon = BsPlus, loading = false, onClick = () => {}, ...rest}) => {
  return (
    <div className={containerClass}>
      <button className={`flex items-center font-bold gap-3 !bg-btn-color px-5 py-[10px] rounded ${className}`} onClick={loading ? null : onClick} {...rest}>
        {!loading && showIcon && (<Icon className='dark:text-white' size={iconProp?.size || "24px"} {...iconProp} />)} 
        {!loading && (<span className='text-black dark:text-white'>{title}</span>)}
        {loading && <CircularProgress />}
      </button>
    </div>
  );
};


export default GlobalButton;