import React, {useState, useEffect, useCallback} from 'react'
import SearchInput from '../../../Widgets/SearchInput'

import { Link } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';
import ButtonWithIcon from '../../../Widgets/ButtonWithIcon';
import { CreateManufacturer } from './Widget/CreateManufacturer';
import { EditManufacturer } from './Widget/EditManufacturer';
import { DeleteManufacturer } from './Widget/DeleteManufacturer';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { ClickableItem } from '../../../Widgets/ClickableItem';
import { CustomPopper } from '../../../Widgets/CustomPopper';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryManufacturersThunk } from '../../../../Store/Inventory';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { TableWrapper, TableHeader, Td, Tr } from '../../../Widgets/Table/TableWrapper';


const ManufacturerPage = () => {

    const dispatch = useDispatch()
    const manufacturerState = useSelector(state => state.inventory)

    const [openCreateManufacturer, setOpenCreateManufacturer] = useState(false)
    const [openEditManufacturer, setOpenEditManufacturer] = useState(false)
    const [openDeleteManufacturer, setOpenDeleteManufacturer] = useState(false)

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [selectedData, setSelectedData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=> {
        setIsLoading(true)
        dispatch(getInventoryManufacturersThunk())
        .then(val => setIsLoading(false))
        .catch(_ => setIsLoading(false))
    },[])

    const handleSearch = useCallback(() => {
        let data = manufacturerState?.manufacturers
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                data = manufacturerState?.manufacturers.filter((val) => (
                    val.name?.toLowerCase().match(pattern) ||
                    val.description?.toLowerCase().match(pattern) ))
        }
        return data
    
    },[searchValue, manufacturerState?.manufacturers])
  return (
    <section>
        <CreateManufacturer open={openCreateManufacturer} handleClose={()=> setOpenCreateManufacturer(false)}/>
        {openEditManufacturer && <EditManufacturer open={openEditManufacturer} handleClose={()=> setOpenEditManufacturer(false)} data={selectedData}/>}
        {openDeleteManufacturer && <DeleteManufacturer open={openDeleteManufacturer} handleClose={()=> setOpenDeleteManufacturer(false)} data={selectedData}/>}
        
        <CustomPopper 
            openPopper={toggleMenu} 
            handleClose={()=>{
                setToggleMenu(null)
                setSelectedData(null)
            }} 
            className='z-[50]' placement='bottom-start'>
            <div className='h-[70px] dark:bg-292 dark:text-white w-[120px] mt-1 py-3 font-bold flex flex-col justify-between text-[14px] bg-white rounded-sm shadow-select'>
                <ClickableItem text={'Delete'} handleClick={() => {
                    setOpenDeleteManufacturer(true)
                    setToggleMenu(null)
                }} />

                <ClickableItem text={'Edit'} handleClick={() => {
                    setOpenEditManufacturer(true)
                    setToggleMenu(null)
                }} />
            </div>          
        </CustomPopper>
         <AppLayout
            custom="inventory"
            subCustom="suppliers"
            title="Suppliers / Manufacturers"
            customTitle="Inventory"
            subtitle=""
        >

            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                <Link to="/suppliers">Suppliers & Manufactures</Link><span className="text-A5A"> / Manufacturers</span>
                </p>
            </div>

            <div className='flex justify-between items-center mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-10'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                />

                <ButtonWithIcon title='New' type='text' handleClick={()=>setOpenCreateManufacturer(true)}/>
            

            </div>

            <TableWrapper className='mt-6 !max-h-[70vh] mb-12'>
                <table >
                    <TableHeader>
                        <Td className='py-4 '>Last Updated</Td>
                        <Td className=''>Name</Td>
                        <Td className=''>Description</Td>
                        <Td className=''>Phone</Td>
                        <Td className=''>Contact</Td>
                        <Td className=''>Website</Td>
                        <Td className='!border-none'></Td>

                    </TableHeader>

                    {!isLoading ? <tbody>
                        {handleSearch().length !== 0 ?
                            handleSearch().map((val,index) => (
                                <Tr className='hover:bg-5F5 hover:dark:bg-292'>
                                        <Td className='py-4'>
                                            {dayjs(val?.createdOn).format('DD/MM/YYYY')}
                                        </Td>

                                        <Td>{val?.name}</Td>
                                        <Td>{val?.description}</Td>
                                        <Td>{val?.phone}</Td>
                                        <Td>{val?.contactInfo}</Td>
                                        <Td>{val?.website}</Td>
                                        <Td className={'!border-none'}>
                                            <BiDotsVerticalRounded 
                                            onClick={(e)=> {
                                                e.stopPropagation()
                                                setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                setMenuIndex(index)
                                                setSelectedData(val)
                                            }}
                                            className='cursor-pointer'/>

                                            
                                        </Td>
                                </Tr>

                            ))
                            :

                            <tr>
                                <td colSpan={7} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                            </tr>
                        }
                    </tbody>
                    :
                    <tr>
                        <td colSpan={7} className='text-center pt-10'><CircularProgress /></td>
                    </tr>
                    }
                </table>

            </TableWrapper>
           
        </AppLayout>
       
    </section>
  )
}

export default ManufacturerPage