import React from 'react'
import { Button, Typography } from '@mui/material'
import {font} from './Styles/font'
import { BsPlus } from 'react-icons/bs';


const ButtonWithIcon = ({title='', handleClick, p='15px', iconClassName='', gap = "10px", width='200px', withIcon=true, Icon=BsPlus, type='outline', className = '', ...props}) => {
  const mode = localStorage.getItem("color-theme") ?? 'light'
  
  return (
    <>
    {type === 'outline' && <Button className={className} variant='outlined' sx={{
        border: '1px solid',
        borderRadius: '8px',
        borderColor: mode === 'light' ? 'black' : '#FFFFFF',
        color: mode === 'light' ? 'black' : 'white',
        textTransform: 'none',
        display: 'flex',
        padding: p,
        width: width,
        zIndex: 0,
        gap: '10px',
        ":hover": mode === 'light' ? {
            borderColor: 'rgba(0,0,0,0.1)',
            backgroundColor: 'rgba(0,0,0,0.1)',
            color: 'rgba(0,0,0,0.5)'
        }: ''}} onClick={handleClick}>
          {withIcon && <Icon className={iconClassName} size={25}/>} <Typography sx={{...font, fontSize: '16px'}}>{title}</Typography>
      </Button>}

                  
      {type === 'text' && <button
       onClick={handleClick} 
       className={`flex items-center border border-D57 rounded-lg gap-3 text-D57 text-base p-2 ${className}`} {...props}> 
       <Icon size={25}/> {title}</button>}

      {type === 'fill' && <Button className={className} variant='contained' sx={{
        backgroundColor: '#242424',
        color: 'white',
        textTransform: 'none',
        display: 'flex',
        padding: p,
        width: width,
        borderRadius: 8,
        gap,
        ":hover":{
          backgroundColor: 'rgba(0,0,0,0.5)'
        }}} onClick={handleClick} {...props}>
          {withIcon && <Icon/>} <Typography sx={{...font, fontSize: '16px'}}>{title}</Typography>
      </Button>}
    </>
  )
}

export default ButtonWithIcon