import React, { useEffect, useState } from 'react'
import { getAllAnouncementAsync } from '../../../../../Store/announcement';
import { useSelector } from 'react-redux';
import { getEmployeeOvertime } from '../../../../../Network/ServiceClass/dashboardService';
import { setEmployeeOvertime } from '../../../../../Store/dashboard';
import { useDispatch } from 'react-redux';
import { getAnnouncements } from '../../../../../Network/ServiceClass/announcementService';
import JobDistro from './JobDistro';
import { getBranchesAsync } from '../../../../../Store/Branches';



function Row4 (){
  const dispatch = useDispatch()
  const [announcement, setAnnouncement] = useState([])
  const company = useSelector(state => state.company.activeCompany)
  const privilege = company.memberships[0].privilege
  const [branches, setBranches] = useState([])
  const [branchValue, setBranchValue] = useState('none');


  useEffect(()=>{
     getAnnouncements('all').then(val=>{
      setAnnouncement(val.data.data)
     }).catch(err => {})

     dispatch(getBranchesAsync())
    .then(val => {
        setBranches(val.payload.data)
    })
  },[company])

  const employeeOvertime = useSelector(
    (state) => state.dashboard.employeeOvertime
  );

  const headerListOvertime = ['Name', 'Job Role', 'Hours'];
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeBranch = (value) => {
    setIsLoading(true);
    setBranchValue(value);
    getEmployeeOvertime(value)
      .then((val) => {
        setIsLoading(false);
        dispatch(setEmployeeOvertime(val));
      })
      .catch((err) => setIsLoading(false));
  };

  const formatEmployeeOvertime = employeeOvertime.map((val, index) => {
    return {
      id: index,
      col1: val.name,
      col2: val.jobRole,
      col3: Math.round(val.hours),
      image: val.picture,
    };
  });

  useEffect(()=>{
    dispatch(getAllAnouncementAsync({data: 'all', page: 1}))
  },[dispatch])


  return (
    <div className={`${privilege === 3 ? '' : 'flex flex-row gap-8 h-[500px]'} `}>

      <div className={`p-3 ${privilege === 3 ? 'w-[100%]' : 'w-[50%] shadow-shift-shadow'}  rounded-lg gap-3 flex flex-col `}>
        {announcement?.slice(0,5)?.length > 0 && (<p className='section-header dark:text-white'>Announcements</p>)}
        <div className='dashboard-announcement-table'>
          {
            announcement?.slice(0,5)?.map((x) =><div className='mb-6'> <p className='font-medium text-sm dark:text-white'>{x?.title}</p> <p className=' text-grey-600 text-sm'>{x?.description}</p></div>)
          }
          {
            announcement?.slice(0,5)?.length === 0 && <div className='h-44 flex items-center justify-center dark:text-white'><p>No Announcements</p></div>
          }
        </div>

      </div>
      
      {[1,2].includes(privilege) && <div className='w-[50%]'> <JobDistro branches={branches}/></div> }

    </div>
  )
}

export default Row4