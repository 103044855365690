import { useState } from "react";
import { Popper } from "@mui/material";
import { ClickAwayListener } from '@mui/base';

export const CustomPopper = ({children, openPopper, handleClose=()=>{}, placement='right-end', className=''}) => {
    const open = Boolean(openPopper) ;
    const id = open ? 'simple-popper' : undefined;

    const handleCloseMenu = () => {
        // setOpen(false);
        handleClose(); // Call the provided handleClose function
      };
    return (
        <ClickAwayListener onClickAway={handleCloseMenu}>

            <Popper
            className={`${className}`}
            id={id} 
            open={open} 
            anchorEl={openPopper} 
            onClose={handleClose} 
            placement={placement}>
            {children}
            </Popper>
        </ClickAwayListener>

    )
  }