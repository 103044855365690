export const scheduleRoles = [
    {
        title: "Schedule Roles", 
        desc: "Watch how to add shift roles to your organization scheduler.",
        link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
    },
    {
        title: "Book A Shift", 
        desc: "Watch how to book a shift in your organization scheduler.",
        link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
    },
    {
        title: "Swap", 
        desc: "Watch how to swap shift in your organization scheduler.",
        link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
    },
]


export const availableSlotsMock = {
    0: [
        {name: 'Kiwi', color: 'green',},
        {name: 'Kiwi', color: 'green',},
        {name: 'Limie', color: 'red',},
        {name: 'Mandarin', color: 'purple',},
    ],
    1: [
        {name: 'Java', color: 'red',},
    ],
    2: [
        {name: 'Honeydew', color: 'blue',},
    ],
    3: [
        {name: 'Kiwi', color: 'green',},
        {name: 'Honeydew', color: 'blue',},
    ],
    4: [],
    5: [],
    6: [
        {name: 'kiwi', color: 'green',},
        {name: 'Java', color: 'red',},
    ],
  }