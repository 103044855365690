export function transformRotationData (rotationData, branchesData, jobRoles){

  let dataRows = [];
  
  const branches = branchesData?.map((branch) => ({ name: branch?.name, id: branch?.id, address: branch?.address}));
  
  
  const branchData = {}

  branches?.map((branch) => {
    jobRoles?.map((job) => {
      branchData[branch.id] = {
        ...branchData[branch.id],
        [job.id]: {
        name: job.title,
        jobId: job.id
      }};

      rotationData?.map((rotation) => {
        const dateShift = rotation.shifts.filter((shift) => (shift.branch.id === branch.id) && (shift.slot.jobRole.id === job.id));
        branchData[branch.id][job.id] = {
          ...branchData[branch.id][job.id],
          [rotation.date]: dateShift.length > 0 ? dateShift.map((dat) => ({name:`${dat.user.firstName} ${dat.user.lastName}`, userId: dat.user.id, slotId: dat?.slot?.id, shiftStartDate: dat.start, shiftEndDate: dat?.end})) : []
        }
        return rotation;
      })
  return job;
})
  return branch;
})
  
  // generate display rows
  branches?.forEach((branch) => {
    const rowsToBeDisplayed = branchData[branch?.id] !== undefined ? Object.values(branchData[branch?.id])?.map((item) => ({...item, mode: 'data', id: branch?.id, branchName: branch?.name})) : [];

    dataRows = [...dataRows, {mode: 'header', branchAddress: branch.address, branchName: branch.name, id: branch.id}, ...rowsToBeDisplayed, {mode: 'footer', id: branch.id}]
    
    
  })
  
    return dataRows
  
    }