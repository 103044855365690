import React from 'react'

import Synclogo from '../../../../Assets/sync_light.svg';
import SyncdarkLogo from '../../../../Assets/sync_logo.svg';

import {BsCheck2} from 'react-icons/bs';
import './SuccessView.css';
import { useNavigate } from 'react-router';
import {FillButton} from '../../../Widgets/FormUtils/FormUtils'

export const SuccessView = () => {

    const navigate = useNavigate();
    const mode = localStorage.getItem('color-theme') ?? 'light'

  return (
    <center className=''>

      <div className='w-[420px] flex flex-col gap-[35px] p-[20px] mt-[50px] items-center shadow-[10px_10px_40px_rgba(0,0,0,0.08)]'>
      
          <img src={mode === 'light' ? Synclogo : SyncdarkLogo} alt='logo'/>

          <p className='text-[22px]'>Password Reset</p>

          <div className='h-[85px] flex flex-row justify-center items-center font-[600] rounded-[40px] w-[85px] text-[58px] bg-[#73DE8A] text-[white]'><BsCheck2 /></div>

          <p className='text-base'>Your password has reset successfully</p>

          <FillButton text={'Log in'} width={'100%'} height={'50px'} callBack={()=>navigate("/login")}/>
      
      </div>        
    </center>
  )
}
