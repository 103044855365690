import React, { useState } from 'react'
import { Button, Stack, Typography, Divider } from '@mui/material'
import {CiSearch} from 'react-icons/ci'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FillButton, OutlinedButton} from '../../../../Widgets/FormUtils/FormUtils'
import {IoClose} from 'react-icons/io5'


const MembersSet = ({data, setOpenMembersSet, chosenMembers, setChosenMembers}) => {
    const [skills, setSkills] = useState(data)
    const [view, setView] = useState(1)
    
    const handleChange = (e) => {
        const text = e.target.value
        const pattern = new RegExp(`\D*${text}\D*`,'i')
        if(text.length >= 3){
            const result = data.filter((val) => val.name.match(pattern))
            setSkills(result)
        }else if (text === ''){
            setSkills(data)
        }
    }

    const handleSelected = (e) => {
        const checked = e.target.checked
        const id = e.target.value

        const result = data.filter(val => Number(val.id) === Number(id))
        if(checked){
            setChosenMembers([...chosenMembers,result[0]])

        }else {
            setChosenMembers(chosenMembers.filter(val => Number(val.id) !== Number(id)))
        }

    }

    const removeSelected = (id) => {
        setChosenMembers(chosenMembers.filter(val => val.id !== id))
        if (chosenMembers.length === 1) setView(1)
    }

    const handleClick = () => {
        console.log(chosenMembers)
        setView(2)
    }
    return (
        <div>
        {view === 1 && <Stack sx={{
            maxWidth: '326px',
            maxHeight: '292px',
            position: 'relative',
            backgroundColor: 'white',
            boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)',
            padding: '1rem',
            position: 'relative',
        }} direction="column" gap={1}>
  
            {/* search row */}
            <Stack sx={{fontSize: '18px', color: '#A5A5A5', padding: '3px 5px', borderRadius: '5px'}}
            direction='row'
            border='1px solid #E6E6E6'
            alignContent='flex-end'
            gap={0.5}
            >
                <CiSearch/>
                <Typography sx={{fontSize: '14px',color: 'A5A5A5'}}>Search</Typography>
                <input className='search-input' style={{
                    height: '1px', 
                    border: 'none', 
                    width: '70%', 
                    margin: 0,
                    padding: '10px 5px',
                    fontSize: '12px'
                
                    }} type="text" onChange={handleChange}/>
            </Stack>
  
            {/* checkboxes row */}
            <Stack ml={1}>
                <FormGroup onChange={handleSelected}>
                    {skills.map((val, index) => {
                        if (index < 6) {
                            return <FormControlLabel control={<Checkbox size='small' sx={{padding: '2px'}}/>} label={<Typography sx={{fontSize: '14px',fontWeight: '400'}}>{val.name}</Typography>} value={val.id}/>
                        }
                    })}
                    
                </FormGroup>
            </Stack>
  
            {/* Divider button row */}
            <Stack mt={2} gap={1} direction='column'>
                <Divider /> 
                <div className='flex flex-row justify-between'>
                    <OutlinedButton text={'Cancel'} callBack={()=>{setOpenMembersSet(false); setChosenMembers([])}}/>
                    <FillButton text={'Done'} disable={chosenMembers.length > 0 ? false : true} callBack={handleClick}/>
                </div>
            </Stack>
        </Stack>}
        
        {view === 2 && <div className='max-w-[326px] h-[115px] p-1 border-1 flex flex-row flex-wrap gap-2 overflow-y-auto border-[#d3d4d8] rounded-[4px]'>
           
           {chosenMembers.map(val => {
            return <SkillElement text={val.name} handleClick={()=>removeSelected(val.id)}/>
           })}
          
          

        </div> }
    </div>
  )
}


const SkillElement = ({text, handleClick}) => {
  return (
    <div className='bg-[#E6E6E6] min-w-[40px] px-[5px] h-[24px] gap-1 flex flex-row justify-center items-center  rounded-[5px]'>
        <p className='text-[10px]'>{text}</p>
        <div className='cursor' onClick={handleClick}><IoClose/></div>
    </div>
  )
}


export default MembersSet