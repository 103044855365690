const day = new Date().getDate()

const timeRangeTable = [
    `00 AM ${day}`,
    `01 AM ${day}`,
    `02 AM ${day}`,
    `03 AM ${day}`,
    `04 AM ${day}`,
    `05 AM ${day}`,
    `06 AM ${day}`,
    `07 AM ${day}`,
    `08 AM ${day}`,
    `09 AM ${day}`,
    `10 AM ${day}`,
    `11 AM ${day}`,
    `12 PM ${day}`,
    `01 PM ${day}`,
    `02 PM ${day}`,
    `03 PM ${day}`,
    `04 PM ${day}`,
    `05 PM ${day}`,
    `06 PM ${day}`,
    `07 PM ${day}`,
    `08 PM ${day}`,
    `09 PM ${day}`,
    `10 PM ${day}`,
    `11 PM ${day}`,
]

export default timeRangeTable;
