import React,{useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Box, Typography, Divider, CircularProgress, Stack } from '@mui/material';
import {font} from '../../../../../../Widgets/Styles/font'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {BsPerson} from 'react-icons/bs'
import {GrFormAdd} from 'react-icons/gr'
import {RiGitBranchLine} from 'react-icons/ri'
import Slect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {TimeOffService} from '../../../../../../../Network/ServiceClass/TimeOffService'
import {setListData} from '../../../../../../../Store/timeOff'
import { Branch } from '../../../../../../../Network/ServiceClass/Branch';
import {FillButton} from '../../../../../../Widgets/FormUtils/FormUtils'


const timeOffService = new TimeOffService()
const branchService = new Branch()
export default function DropDownMenu({openMenu, setOpenMenu,}) {
  const dispatch = useDispatch()
  const open = Boolean(openMenu);
  const userStateData = useSelector(state => state.timeOff.employeeList)
  let employeeList = userStateData.map(val => {
    return{
      label: `${val.requester.firstName} ${val.requester.lastName}`,
      value: val.requester.id
    }
  })
   employeeList = [{label: 'All', id:'none'},...new Map(employeeList.map((m) => [m.label, m])).values()]

   useEffect(()=>{
    branchService.getBranches().then(val => {
      setBranches( [{label: 'All', id:'none',}, ...val.data.map(val => {return{label: val.name,value: val.id}})])
    })
   },[])

  
  const handleClose = () => {
    setOpenMenu(null);
  };

  const [employee, setEmployee] = useState('none')
  const [companyBranch, setCompanyBranch] = useState('none')
  const [isLoading, setIsLoading] = useState(false)
  const [branch, setBranches] = useState([])

  const handleSubmit = () => {
    setIsLoading(true)
    const data = {
      id: employee,
      branchId: companyBranch
    }
      timeOffService.fetchTimeOff(data)
      .then((value)=>{
          setIsLoading(false)
          if(value.status !== 200) throw new Error('') 
          dispatch(setListData(value.data))
      }).catch((error)=>{
          console.log(error)
          setIsLoading(false)
      })

  }

  return (
    <div>
     
      <Menu
        id="basic-menu"
        anchorEl={openMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box 
          className = 'dark:bg-A06 dark:text-white'
          sx={{
            width: '290px',
            height: '400px',
             ...font,
             display: 'flex',
             flexDirection: 'column',
             justifyContent: 'space-between'
        }}>

            <Box>
                <Typography sx={{...font, fontSize: '24px', color: '#242424', padding: '0.5rem 1rem'}}>Filter</Typography>

                <Divider className='dark:bg-color-333'/>

                <div>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<GrFormAdd className='dark:text-white'/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className='dark:bg-A06 dark:text-white'
                        
                        >
                            <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2rem',
                            fontSize: '16px'
                            }}>

                                    <BsPerson/> <Typography sx={{...font}}>Employee</Typography>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails className='dark:bg-A06 dark:text-white'>
                        <Slect
                            className="basic-single"
                            classNamePrefix="Happy"
                            defaultValue={employeeList[0]}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="color"
                            options={employeeList}
                            onChange={(e)=>{setEmployee(e.value)}}
                        />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<GrFormAdd />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2rem',
                            fontSize: '16px'
                            }}>

                                    <RiGitBranchLine/> <Typography sx={{...font}}>Company branch</Typography>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Slect
                            className="basic-single"
                            classNamePrefix="Happy"
                            defaultValue={{label: 'All', id:'none',}}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="color"
                            options={branch}
                            onChange={(e)=>{setCompanyBranch(e.value)}}
                        />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Box>   
           
            <Stack direction='row' justifyContent='center'>
              {!isLoading && <FillButton text={'Apply'} width='30%' callBack={handleSubmit}/>}
              {isLoading && <CircularProgress/>}
            </Stack>
          
        </Box>

       
      </Menu>
    </div>
  );
}