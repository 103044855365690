import { BsPen } from 'react-icons/bs';
import CustomDropdown from '../../../Widgets/CustomDropdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  addInventoryManAndSuppThunk,
  createInventoryItemMetaThunk,
  getAnItemThunk,
  getInventoryGroupsThunk,
  getInventoryManufacturersThunk,
  getInventoryMeasurementUnitThunk,
  getInventorySuppliersThunk,
  stockupInventoryItemThunk,
  getInventoryItemsForecastThunk,
} from '../../../../Store/Inventory';
import { Loader } from '../../../Widgets/Loader';
import AppLayout from '../../../Widgets/AppLayout';
import { getBranchesAsync } from '../../../../Store/Branches';
import GlobalButton from '../../../Widgets/GlobalButton';
import AddBtn from '../../../../Assets/add_supplier.png'
import CreateSupplierModal from './components/CreateSupplier';
import { toast } from 'react-toastify';
import { convertToDollar } from '../../../../utils/util';
import { CircularProgress, TextField } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import PurchaseGraph from './components/PurchaseGraph';
import { TiPlus } from "react-icons/ti";
import { TableWrapper, Tr, Td } from '../../../Widgets/Table/TableWrapper';


const tableClassName = 'p-[14px] text-left';



export const InventoryListItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();


  const [isNewField, createNewField] = useState(false)
  const [fieldKey, setKey] = useState('')
  const [fieldValue, setFieldValue] = useState('')
  const [isModalOpen, openSupplierModal] = useState(false)
  const branches = useSelector((state) => state.branches.branches)?.data || []
  const inventoryItem = useSelector((state) => state?.inventory)?.item || [];
  const loading = useSelector((state) => state.inventory)?.loading;
  const inventoryGroup = useSelector((state) => state.inventory)?.groups;
  const loadingForcast = useSelector((state) => state.inventory)?.loadingForcast;
  const suppliers = useSelector((state) => state.inventory)?.suppliers
  const manufacturers = useSelector((state) => state.inventory)?.manufacturers
  const measurementUnits = useSelector((state) => state.inventory)?.measurementUnits
  const [location, setLocation] = useState({ value: "", label: "Select" })
  const [measurementUnit, setMeasurementUnit] = useState({ value: "", label: "Select" })
  const [stockUpData, setStockUp] = useState({ amountToAdd: 0 })
  
  const itemsForecastSpent = useSelector((state) => state.inventory)?.itemsForecast
  ?.map(x => ({
    date:dayjs(x?.date).format('DD MMM YYYY'), 
    usage_spent: x?.usages?.spent,
    withdrawal_spent: x?.withdrawals?.spent,

  }));

  const itemsForecastCount = useSelector((state) => state.inventory)?.itemsForecast
  ?.map(x => ({
    date: dayjs(x?.date).format('DD MMM YYYY') , 
    usage_count: x?.usages?.count,
    withdrawal_count: x?.withdrawals?.count,

  }));

  const [toggleGraph, setToggleGraph] = useState(0)


  useEffect(() => {
    dispatch(getAnItemThunk(params?.itemId));
    dispatch(getInventoryGroupsThunk());
    dispatch(getBranchesAsync())
    dispatch(getInventorySuppliersThunk())
    dispatch(getInventoryManufacturersThunk())
    dispatch(getInventoryMeasurementUnitThunk())
    dispatch(getInventoryItemsForecastThunk({referenceNumber: params?.itemId, itemId:params?.item}))

  }, []);

  const handleCloseSupplierModal = () => {
    openSupplierModal(false)
  }


  const handleSubmitSupplierDetails = (data, formik, addAnother) => {
    dispatch(addInventoryManAndSuppThunk(data)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        toast.success("Supplier was successfully linked to the inventory");
        if (addAnother) {
          formik.resetForm()
        } else {
          openSupplierModal(false)
        }
        dispatch(getAnItemThunk(params?.itemId));
      } else {
        toast.error(res?.payload?.data?.message || "Supplier was not successfully linked to the inventory")
      }
    })
  }

  const handleStockUpInventory = () => {


    if (["", undefined].includes(stockUpData?.locationId)) {
      toast.error("Please select a location")
      return;
    }

    if (["", undefined].includes(stockUpData?.measurementUnit)) {
      toast.error("Please select a measurement unit")
      return;
    }

    if (["", undefined, 0].includes(stockUpData?.amountToAdd)) {
      toast.error("Please supply amount to add")
      return;
    }

    const data = {
      ...stockUpData,
      amountToAdd: Number(stockUpData?.amountToAdd),
      itemId: inventoryItem?.id,
    }

    dispatch(stockupInventoryItemThunk(data)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getAnItemThunk(params?.itemId));
        toast.success("Inventory was successfully stock up")
        setStockUp({})
        setLocation({ value: "", label: "Select" })
        setMeasurementUnit({ value: "", label: "Select" })
      } else {
        toast.error(res?.payload?.data?.message || "Inventory was not successfully stock up")
      }
    })

  }

  const handleCreateField = () => {
    if(fieldKey?.length === 0) {
      return toast.success("Please supply field key")
    }

    if(fieldValue?.length === 0) {
      return toast.success("Please supply field value")
    }

    const user = JSON.parse(localStorage.getItem("user"));
        const activeCompany = JSON.parse(localStorage.getItem("activeCompany"));
        const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);

    const data = {
      key: fieldKey,
      value: fieldValue,
      itemId: inventoryItem?.id,
      teamMemberId: member?.id
    }

    dispatch(createInventoryItemMetaThunk(data)).then((res) => {
      if(res?.meta?.requestStatus === "fulfilled"){
        toast.success("Meta data was successfully created")
        dispatch(getAnItemThunk(params?.itemId));
        setKey("")
        setFieldValue("")
        createNewField(false)
      } else {
        toast.error(res?.error?.message);
      }
    })


  }

  return (
    <section>
      <AppLayout
        custom="inventory"
        subCustom="inventory-items"
        title="Inventory Items"
        customTitle=" "
        subtitle=""
      >
        <div className="flex items-center gap-3">
          <IoIosArrowBack
            className="cursor-pointer dark:text-white"
            size={25}
            onClick={() => navigate(-1)}
          />
          <p className="font-medium text-base text-5F3 my-6">
            <Link to="/inventory-items">INVENTORY ITEMS</Link>{' '}
            <Link to="/inventory-items-list">/ INVENTORY LIST</Link>
            {!loading ? (
              <span className="text-A5A">
                {' '}
                {`/ ${inventoryItem?.name?.toUpperCase()}`}
              </span>
            ) : (
              <span>/ Loading....</span>
            )}
          </p>
        </div>
        {loading && (
          <div className="h-[300px] flex items-center justify-center">
            <Loader></Loader>
          </div>
        )}
        {!loading && (
          <section className="mt-[41px] mb-[67px] flex dark:text-white items-center gap-5 flex-wrap">
            <div>
              <p className="text-sm mb-3">Description</p>
              <input
                readOnly
                value={inventoryItem?.description}
                placeholder="Description"
                className="border rounded-lg p-2 w-[300px] dark:bg-A06"
              />
            </div>
            <div>
              <p className="text-sm mb-3">Vendor Description</p>
              <input
                readOnly
                value={inventoryItem?.vendorDescription}
                placeholder="Description"
                className="border rounded-lg p-2 w-[300px] dark:bg-A06"
              />
            </div>
            <div>
              <p className="text-sm mb-3">Group</p>
              <CustomDropdown
                data={[
                  {
                    label: 'All Group',
                    value: '',
                  },
                  ...inventoryGroup?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  })),
                ]?.map((team) => ({
                  label: team?.label,
                  value: team?.value,
                }))}
                dropdownTitleClass="text-black"
                optionClass="z-[500]"
                className="min-w-[150px] w-[300px] rounded h-[42px]"
                value={{
                  label: inventoryItem?.group?.name,
                  value: inventoryItem?.group?.id,
                }}
              />
            </div>
          </section>
        )}

        <div className='py-3 px-1 mb-8 dark:text-white'>
          <p className="text-2xl font-medium mb-6">Item Usage</p>

          <div className='rounded py-4 pr-2 shadow-shift-shadow-small dark:bg-1F1 h-[350px] '>

            {!loadingForcast ? <div className='flex flex-col gap-4 h-full'>
              <div className='flex gap-3 self-end'>

                <div 
                  onClick={()=> setToggleGraph(0)}
                  className='flex gap-2 items-center dark:bg-color-333 cursor-pointer px-1 hover:rounded hover:shadow-shift-shadow-small'>
                  <p>Counts</p>
                  <p className={`w-3 h-3 border-1 ${toggleGraph === 0 ? 'bg-5F3' : 'border-gray-400'} rounded-full`}/>
                </div>

                <div 
                  onClick={()=> setToggleGraph(1)}
                  className='flex gap-2 items-center dark:bg-color-333 cursor-pointer px-1 hover:rounded hover:shadow-shift-shadow-small'>
                  <p>Spent</p>
                  <p className={`w-3 h-3 border-1 ${toggleGraph === 1 ? 'bg-5F3' : 'border-gray-400'}  rounded-full`}/>
                </div>
              </div>

              <ResponsiveContainer className='' width={'100%'} height={'100%'}>
                <LineChart
                  width={500}
                  height={300}
                  data={toggleGraph ? itemsForecastSpent : itemsForecastCount}
                  
                >
                  <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: 700}} interval={3}/>
                  <YAxis tick={{ fontSize: 12, fontWeight: 700 }}/>
                  <Tooltip content={(props) => CustomTooltip(props)} />
                  <Legend content={(props) => CustomLegend(props)} />
                  <Line type="monotone" dataKey={toggleGraph ? "usage_spent" : "usage_count"} stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey={toggleGraph ? "withdrawal_spent" : "withdrawal_count"} stroke="#82ca9d" activeDot={{ r: 8 }}/>

                </LineChart>
              </ResponsiveContainer>
            </div>
            :
            <div className='flex justify-center mt-8'>
              <CircularProgress />
            </div>
            }

          </div>


        
        </div>


        <PurchaseGraph 
          branches={branches} 
          units={measurementUnits}
          payload={{referenceNumber: params?.itemId, itemId:params?.item}}
          orderItems={inventoryItem?.reorderItems}
        />

        {!loading && (
          <div>
            <p className="text-2xl font-medium mb-6 dark:text-white">Quick Stock Up</p>
            <TableWrapper >
              <table>
                <thead className="bg-0F5 dark:bg-color-333 dark:text-white font-bold sticky top-0">
                  <Td className={'py-4'}>Location</Td>
                  <Td >Item name</Td>
                  <Td >Unit</Td>
                  <Td >Current Amount</Td>
                  <Td className={'border-r-none'}>New Amount</Td>
                 
                </thead>
                <tbody>
                  <Tr>
                    <Td className={'px-2 py-2'}>
                      <CustomDropdown
                        data={[
                          {
                            label: 'All Locations',
                            value: '',
                          },
                          ...branches?.map((x) => ({ label: x?.name, value: x?.id, hexcode: x?.hexcode })),
                        ]?.map((team) => ({
                          label: team?.label,
                          value: team?.value,
                          hexcode: team?.hexcode
                        }))}
                        value={location}
                        onChangeOption={(e) => {
                          setLocation(e);
                          setStockUp((prev) => ({ ...prev, locationId: e.value }))
                        }}
                        dropdownTitleClass="text-black"
                        optionClass="z-[500] max-h-[200px] overflow-y-scroll"
                        className="min-w-[150px] rounded h-[42px]"
                      />
                    </Td>
                    <Td >
                      <input
                        value={inventoryItem?.name}
                        placeholder="Item name"
                        className="border outline-none rounded-lg p-2 dark:bg-A06 dark:border-333 dark:text-white"
                      />
                    </Td>
                    <Td >
                      <CustomDropdown
                        data={[
                          {
                            label: 'Select',
                            value: '',
                          },
                          ...measurementUnits?.map((x) => ({ label: x.name, value: x.id }))
                        ]?.map((team) => ({
                          label: team?.label,
                          value: team?.value,
                        }))}
                        optionClass="z-[500]"
                        dropdownTitleClass="text-black"
                        className="min-w-[150px] rounded h-[42px] bg-white dark:bg-A06"
                        value={measurementUnit}
                        onChangeOption={(e) => { setMeasurementUnit(e); setStockUp((prev) => ({ ...prev, measurementUnit: e.value })) }}
                      />
                    </Td>
                    <Td >
                      <input
                        readOnly
                        value={inventoryItem?.reorderItems?.find((x) => x?.locationId === stockUpData?.locationId)?.currentAmount || 0}
                        placeholder="Current Amount"
                        className="border outline-none rounded-lg p-2 w-full dark:bg-A06 dark:border-333 dark:text-white"
                      />
                    </Td>

                    <Td >

                      <input
                        onChange={(e) => {
                          setStockUp((prev) => ({ ...prev, amountToAdd: e.target.value }))
                        }}
                        type='number'
                        value={stockUpData?.amountToAdd}
                        placeholder="New Amount"
                        className="border outline-none rounded-lg p-2 w-full dark:bg-A06 dark:border-333 dark:text-white"
                      />
                      {/* <TextField
                        type="number"
                        variant="outlined"
                        defaultValue={0}
                        value={stockUpData?.amountToAdd}
                        onChange={(e) => {
                          setStockUp((prev) => ({ ...prev, amountToAdd: e.target.value }))
                        }}
                        inputProps={{
                          style: {
                            padding: "9px",
                          }
                        }}
                        placeholder="New Amount"
                        className="border outline-none rounded-lg w-full dark:border-white"
                      /> */}
                    </Td>
                    
                  </Tr>
                  <tr>
                    <td colSpan={5}>
                      <div className="flex justify-end p-2">
                        <GlobalButton onClick={handleStockUpInventory} title="Restock" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableWrapper>
          </div>
        )}

        {!loading && (
          <>
            <p className="text-2xl font-medium mb-6 mt-10 dark:text-white">Suppliers</p>
            {
              inventoryItem?.manAndSupp?.length === 0 && <div className="flex items-center gap-3 ">
                <img onClick={() => openSupplierModal(true)} style={{ width: "41px", height: "41px" }} className="cursor-pointer" src={AddBtn} alt="add" /> <p className="font-semibold">Add New Supplier</p>
              </div>
            }
            {inventoryItem?.manAndSupp?.length > 0 && (
              <div className="bg-5F5 dark:bg-1F1 dark:text-white rounded-2xl relative py-4">
              
              <PlusIcon onClick={() => openSupplierModal(true)}/>

              {inventoryItem?.manAndSupp?.map((item) => (
                <section className="my-[10px] p-4 flex items-center justify-between">
                  <div>
                    <p className="text-sm mb-3">Primary Supplier</p>
                    <CustomDropdown
                      data={[
                        {
                          label: 'All',
                          value: '',
                        },
                        ...suppliers?.map((x) => ({ label: x.name, value: x.id }))
                      ]?.map((team) => ({
                        label: team?.label,
                        value: team?.value,
                      }))}
                      optionClass="z-[500]"
                      dropdownTitleClass="text-black"
                      className="min-w-[150px] w-[200px] rounded h-[42px] bg-white dark:border-333 dark:bg-1F1"
                      value={suppliers?.filter((y) => y.id === item.supplierId)?.map((v) => ({ label: v.name, value: v.id }))[0]}
                      onChangeOption={() => { }}
                    />

                  </div>
                  <div>
                    <p className="text-sm mb-3">Manufacturer</p>
                    <CustomDropdown
                      data={[
                        {
                          label: 'All',
                          value: '',
                        },
                        ...manufacturers?.map((x) => ({ label: x.name, value: x.id }))
                      ]?.map((team) => ({
                        label: team?.label,
                        value: team?.value,
                      }))}
                      optionClass="z-[500]"
                      dropdownTitleClass="text-black"
                      className="min-w-[150px] w-[200px] rounded h-[42px] bg-white dark:border-333 dark:bg-1F1"
                      value={manufacturers?.filter((y) => y.id === item.manufacturerId)?.map((v) => ({ label: v.name, value: v.id }))[0]}
                      onChangeOption={() => { }}
                    />
                  </div>
                  <div>
                    <p className="text-sm mb-3">Manuf #</p>
                    <input
                      value={item?.manufacturingNumber}
                      placeholder="Description"
                      className="border rounded-lg p-2 w-[150px] dark:border-333 dark:bg-1F1"
                    />
                  </div>
                  <div>
                    <p className="text-sm mb-3">Package desc</p>
                    <CustomDropdown
                      data={[
                        {
                          label: 'All',
                          value: '',
                        },
                        ...measurementUnits?.map((x) => ({ label: x.name, value: x.id }))
                      ]?.map((team) => ({
                        label: team?.label,
                        value: team?.value,
                      }))}
                      optionClass="z-[500]"
                      dropdownTitleClass="text-black"
                      className="min-w-[150px] w-[200px] rounded h-[42px] bg-white dark:border-333 dark:bg-1F1"
                      value={measurementUnits?.filter((y) => y.id === item.packageDescId)?.map((v) => ({ label: v.name, value: v.id }))[0]}
                      onChangeOption={() => { }}
                    />
                  </div>
                  <div>
                    <p className="text-sm mb-3">Items/Pkg</p>
                    <input
                      value={item?.itemsPkg}
                      placeholder="Description"
                      className="border rounded-lg p-2 w-[150px] dark:border-333 dark:bg-1F1"
                    />
                  </div>
                  <div>
                    <p className="text-sm mb-3">Pkg cost</p>
                    <div className="border rounded-lg p-2 w-[150px] bg-white dark:border-333 dark:bg-1F1">
                      <p>${convertToDollar(item?.manufacturerPkgCost)}</p>
                    </div>
                    {/* <input
                      value={convertToDollar(item?.manufacturerPkgCost)}
                      placeholder="Description"
                      
                    /> */}
                  </div>
                </section>
              ))}
            </div>)}
          </>
        )}

        {!loading && (
          <>
            <p className="text-2xl font-medium mb-6 mt-[83px] dark:text-white">Locations Status</p>
            <TableWrapper className="mt-6 !max-h-[40vh]">
              <table>
                  <tr className="bg-0F5 dark:bg-color-333 dark:text-white font-bold sticky top-0">
                    <Td className='py-5 '>Location</Td>
                    <Td>Reorder Limit</Td>
                    <Td>Critical Limit</Td>
                    <Td>Re order to</Td>
                    <Td>Current Amount</Td>
                    {/* <Td>Expiration Alert</Td> */}
                    <Td className={'!border-none'}>Measurement Unit</Td>
                    <Td className={'!border-none'}></Td>
                  </tr>
                <tbody>
                  {inventoryItem?.reorderItems?.map((item) => (
                    <Tr className='hover:bg-5F5 hover:dark:bg-292'>
                      <Td className='py-5'>
                        <div className="p-[10px] rounded-lg bg-5F5 dark:bg-color-333 dark:text-white">{
                          branches.find((x) => x.id === item?.locationId)?.name
                        }</div>
                      </Td>
                      <Td>{item?.reOrderLimit}</Td>
                      <Td>{item?.criticalLimit}</Td>
                      <Td>{item?.reOrderTo}</Td>
                      <Td>{item?.currentAmount || 0}</Td>
                      {/* <Td>{moment(item?.expirationDate).fromNow()}</Td> */}
                      <Td className={'!border-none'}>{item?.measurementUnit?.name || 0}</Td>
                      <Td className={'!border-none'}>
                        <BsPen />
                      </Td>
                    </Tr>
                  ))}
                  {inventoryItem?.reorderItems?.length === 0 && (
                    <tr>
                      <td className="h-[300px]" colSpan={7}>
                        <p className="text-center">No reorder available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {isModalOpen && (<CreateSupplierModal inventoryItemsId={inventoryItem?.id} handleSubmitItem={handleSubmitSupplierDetails} handleCloseSupplierModal={handleCloseSupplierModal} openSupplierModal={isModalOpen} />)}
            </TableWrapper>
            
              <div className="mb-40">
              <p className="text-2xl font-medium mb-[42px] mt-[77px] dark:text-white">Custom Properties</p>
                <div className="bg-5F5 dark:bg-1F1 dark:text-white rounded-2xl relative py-4 w-3/4">

                <PlusIcon onClick={() => createNewField(true)}/>
                {
                  !isNewField && inventoryItem?.metadata?.length === 0 &&  <div className="h-[300px] flex items-center justify-center"><p> No Custom Field</p></div>
                }

                {
                   inventoryItem?.metadata?.map((x) => (
                    <div className="flex items-center gap-7 py-[11px] px-3">
                  <input
                      placeholder="key"
                      value={x?.key}
                      readOnly
                      className="border rounded-lg p-2 w-full outline-none dark:bg-1F1 dark:border-333"
                    />
                     <input
                      placeholder="value"
                      value={x?.value}
                      className="border rounded-lg p-2 outline-none w-full dark:bg-1F1 dark:border-333"
                      onChange={(e) => setFieldValue(e.target.value)}
                    />
                  </div>
                   ))
                }

                  {isNewField && (<div className="flex items-center gap-7 dark:text-white py-[11px] px-3">
                  <input
                      placeholder="key"
                      value={fieldKey}
                      className="border rounded-lg p-2 w-full dark:bg-color-333 dark:border-333"
                      onChange={(e) => setKey(e.target.value)}
                    />
                     <input
                      placeholder="value"
                      value={fieldValue}
                      className="border rounded-lg p-2 w-full dark:bg-color-333 dark:border-333"
                      onChange={(e) => setFieldValue(e.target.value)}
                    />
                    <button onClick={handleCreateField} className="border-green-600 border p-2 rounded-md text-green-600">Save</button>
                  </div>)}
                </div>
              </div>
          </>
        )}
      </AppLayout>
    </section>
  );
};


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    // You can access the tooltip values from 'payload'
    return (
      <div className="custom-tooltip bg-white dark:bg-A06 rounded px-4 py-2 text-[14px] shadow-shift-shadow-small">
        <p>Date: {dayjs(label).format('DD MMM YYYY')}</p>
        {payload?.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${capitalizeFirstLetter(entry.name.split('_')[0])} ${capitalizeFirstLetter(entry.name.split('_')[1])}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
}

const CustomLegend = ({ payload }) => {
  return (
    <div className='flex justify-center gap-5'>
      {payload?.map((entry, index) => (
        <div className='flex gap-2 items-center'>
          <p className='w-2 h-2 rounded-full'  style={{ background: entry.color }}/>
          <p key={`item-${index}`} style={{ color: entry.color }}>
          {capitalizeFirstLetter(entry.value.split('_')[0])} {capitalizeFirstLetter(entry.value.split('_')[1])}
          </p>
        </div>
      ))}
    </div>
  );
}


const PlusIcon = ({onClick=()=>{}}) => {
  return (
    <div 
      onClick={onClick}
      className="cursor-pointer absolute top-[87%] bg-5F5 text-base-dark dark:bg-292 rounded-lg text-[18px] left-0 h-[41px] w-[41px] flex justify-center items-center">
        <TiPlus />
    </div>
  )
}