
export const TableWrapper = ({children, className}) => {
  return (
    <div className={`max-h-[100%] overflow-y-auto border-1 border-light-border rounded-md dark:border-333 ${className}`}>
        {children}
    </div>
  )
}

export const Td = ({children, className, onClick=()=>{}, colSpan=1}) => {
    return (
      <td 
        colSpan={colSpan}
        onClick={onClick}
        className={` dark:text-white border-r-1 border-light-border text-left px-4 dark:border-333 ${className}`}>{children}</td>
  
    )
  }

export const Tr = ({className, style, children, onClick, key}) => {
    return (
      <tr
      key={key}
      onClick={onClick}
      className={`border-b-1 last:border-none border-light-border font-bold text-A5A cursor-pointer dark:border-b-333 ${className}`}
      style={style}
    >
      {children}
  </tr>
    )
  }

export const TableHeader = ({className, children}) => {

  return (
    <tr className={`bg-0F5 dark:bg-444 dark:text-white font-bold sticky top-0 ${className}`}>
      {children}
    </tr>
  )
}  