import React, { useState } from 'react';
import CustomModal from '../../../../../Widgets/CustomModal';

import Synclogo from '../../../../../../Assets/sync_light.svg';
import SyncdarkLogo from '../../../../../../Assets/sync_logo.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveCompany,
  setCompanyLogoAsync,
} from '../../../../../../Store/Company';
import {
  FillButton,
  OutlinedButton,
} from '../../../../../Widgets/FormUtils/FormUtils';
import { CircularProgress } from '@mui/material';
import { GrUploadOption } from 'react-icons/gr';
import { toast } from 'react-toastify';

const PictureModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state?.company)?.activeCompany;
  const mode = localStorage.getItem('color-theme') ?? 'light'

  const [file, setFile] = React.useState(null);
  const [isloading, setIsLoading] = React.useState(false);

  const [msg, setMsg] = useState('');

  const chooseFile = () => {
    const choosenfile = document.querySelector('.img-file');
    choosenfile.click();
    setMsg('');
  };

  const UploadDp = () => {
    if (file !== null) {
      setIsLoading(true);
      const formdata = new FormData();
      formdata.append('file', file);
      dispatch(setCompanyLogoAsync(formdata)).then((res) => {
        dispatch(
          setActiveCompany({
            ...company,
            logo: { url: res?.payload?.url },
          })
        );
        toast.success('Company logo was successfully updated');
        setIsLoading(false);
        handleClose();
      });
    } else {
      setMsg('Select image file');
    }
  };

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      modalClassName="w-[423px] rounded-md"
    >
      <div className="flex justify-center flex-col items-center">
        <img alt="logo" src={mode === 'light' ? Synclogo : SyncdarkLogo} />
        <div className="upload-area flex justify-center" onClick={chooseFile}>
          <input
            type="file"
            className="img-file"
            onChange={(e) => setFile(e.target.files[0])}
            style={{ display: 'none' }}
          />

          <p
            className="flex items-center"
            style={{ fontSize: '12px', cursor: 'pointer' }}
          >
            <span className="mr-1" style={{ color: 'cornflowerblue' }}>
              <GrUploadOption />{' '}
            </span>{' '}
            Upload Profile image
          </p>
        </div>

        <div
          style={{
            width: '85%',
            textAlign: 'left',
            fontSize: '13px',
            marginTop: '10px',
            marginBottom: '50px',
          }}
        >
          {file === null
            ? 'File should not be larger than 3mb'
            : '1 file picked'}
        </div>

        <div
          className="flex justify-between"
          style={{ paddingTop: '0vh', width: '80%' }}
        >
          <OutlinedButton
            text={'Cancel'}
            width={'100px'}
            height={'45px'}
            callBack={handleClose}
          />
          {!isloading && (
            <FillButton
              text={'Upload'}
              width={'100px'}
              height={'45px'}
              callBack={UploadDp}
            />
          )}
          {isloading && <CircularProgress />}
        </div>

        <center>
          <p className="error-msg">{msg}</p>
        </center>
      </div>
    </CustomModal>
  );
};

export default PictureModal;
