
export const Ctd = ({children, className=''}) => {
    return (
        <td className={`border-1 dark:border-333 px-3 text-center py-4 dark:text-white ${className} `}>{children}</td>
    )
}


export const DivShadow = ({children, className=''}) => {
    
    return (
        <div className={`shadow-shift-shadow-small dark:bg-292 flex flex-col rounded-xl gap-3 w-[100%] py-4 px-5 ${className}`}>
            {children}
        </div>
    )
}