import React,{useState} from 'react'
import availablePic from '../../../../../Assets/available-slot-dashboard.png'
import overTimePic from '../../../../../Assets/row1Overtime.png'
import checkInPic from '../../../../../Assets/row1CheckIns.png'
import {SimpleSelect} from '../../../../Widgets/FormUtils/FormUtils'
import { useSelector, useDispatch } from 'react-redux';
import {getOverView} from '../../../../../Network/ServiceClass/dashboardService'
import { getWeek } from '../../../../../utils/getWeek';
import formatDate from '../../../../../utils/formatDate';
import addOneDay from '../../../../../utils/incrementDate';
import {setOverview} from '../../../../../Store/dashboard'
import { CircularProgress } from '@mui/material';
import WorkHours from './WorkHours';
import CustomDropdown from '../../../../Widgets/CustomDropdown'


const Row1 = () => {
    const dispatch = useDispatch()

    const overviewState = useSelector(state => state.dashboard.overview)

      const thisWeekTo = addOneDay(getWeek(0).lastDay.toUTCString())
      const thisWeekFrom = formatDate(getWeek(0).firstDay.toUTCString())

      const prevWeekTo = addOneDay(getWeek(-7).lastDay.toUTCString())
      const prevWeekFrom = formatDate(getWeek(-7).firstDay.toUTCString())

      const nextWeekTo = addOneDay(getWeek(7).lastDay.toUTCString())
      const nextWeekFrom = formatDate(getWeek(7).firstDay.toUTCString())

      const [weekValue, setWeekValue] = useState({
        label: 'Current Week', value:'current'
      })
      const [isLoading, setIsLoading] = useState(false)

      const handleChangeWeek = (x) => {
        const value = x.value
        setWeekValue(x)
        setIsLoading(true)
        let to, from

        if(value === 'current'){
            to = thisWeekTo
            from = thisWeekFrom
        }else if(value === 'prev'){
            to = prevWeekTo
            from = prevWeekFrom
        }else if(value === 'next'){
            to = nextWeekTo
            from = nextWeekFrom
        }
        else{
          to = thisWeekTo
          from = thisWeekFrom
        }
        getOverView(from, to).then(val => {
            setIsLoading(false)
            dispatch(setOverview(val))
        }).catch(err => setIsLoading(false))

      }

      const list = [
        {value:'current',label:'Current Week'},
        {value:'prev',label:'Previous Week'},
        {value:'next',label:'Next Week'}
    ]

  return (
    <div className='flex justify-between'>

        
        <WorkHours/>

        <div className='w-[40%] flex flex-col gap-12 rounded-lg p-3 shadow-shift-shadow '>
            <div className='flex justify-between'>
            <p className='section-header dark:text-white'>Overview</p>

                <CustomDropdown
                trimtext
                trimLength={13}
                data={list}
                mainContainerStyle='w-44'
                className="h-10 rounded "
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={weekValue}
                onChangeOption={handleChangeWeek}
              />
            </div>

            {!isLoading && <>
              {overviewState !== null &&<div className='flex flex-col gap-6'>
                  <CustBox title={'Overtime hours booked'} desc={Math.round(overviewState.overtimeHoursBooked) } img={overTimePic}/>
                  <CustBox title={'No of late clock ins'} desc={Math.round(overviewState.lateCheckInsCount)} img={checkInPic}/>

              </div>}
            </>}

            {isLoading && <div className='w-[100%] flex justify-center mt-3'><CircularProgress/></div>}

            

        </div>
    </div>
  )
}

export default Row1


const CustBox = ({img=availablePic, title, desc}) => {
  return (
    <div className="flex flex-col gap-3 border-1 border-9FD dark:border-969 px-3 py-2 rounded w-[262px] h-[100px]">
        <p className="text-xs text-grey-800 dark:text-white">{title}</p>
        <div className="flex gap-3">
            <img src={img} alt="slot" />
            <p className='text-[22px] dark:text-white'>{desc}</p>
        </div>
    </div>
  )
}
