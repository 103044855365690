/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Branch } from '../../../Network/ServiceClass/Branch';
import { Shift } from '../../../Network/ServiceClass/Shift';
import { HomeNavbar } from '../../Widgets/HomeNavbar';
import { SideBar } from '../../Widgets/SideBar';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import './ShiftScreen.css';
import ColorToggleButton from './Widget/ToggleSwitch/ToggleSwitch';
import { Box } from '@mui/material';
import getDates from '../../../utils/rangeOfData';
import WeekView from './Widget/ViewData/WeekView';
import DayView from './Widget/ViewData/DayView';
import {
  setRoaster,
  setShiftSlot,
  setIsLoadingRoaster,
  setSwappableShift,
} from '../../../Store/Shift';
import { useSelector, useDispatch } from 'react-redux';
import NavigateCalender from './Widget/NavigateCalender/NavigateCalender';
import NavigateTime from './Widget/NavigateTime/NavigateTime';
import AlertDialog from '../../Widgets/SuccessDialog';
import ShiftDetails from './Widget/shiftDetails/shiftDetails';
import SelectBranch from './Widget/SelectBranch/SelectBranch';
import { AnimatePresence } from 'framer-motion';
import addOneDay from '../../../utils/incrementDate';

const branch = new Branch();
const shift = new Shift();

export const ShiftScreen = () => {
  const dispatch = useDispatch();
  const shiftStateRoaster = useSelector((state) => state.shift.roaster);
  const shiftStateToggleCalendar = useSelector(
    (state) => state.shift.toggleCalender
  );
  const shiftStateWeeklyDate = useSelector((state) => state.shift.weeklyDate);
  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );
  const selectedBranchState = useSelector(
    (state) => state.shift.selectedBranch
  );
  const shiftToggleDetailState = useSelector(
    (state) => state.shift.toggleShiftDetails
  );

  const [branches, setBranches] = useState([]);
  const [dates, setDates] = useState([]);

  const getRoaster = () => {
    shift
    .fetchRoaster(
      selectedBranchState,
      addOneDay(shiftStateWeeklyDateLabel.to),
      shiftStateWeeklyDateLabel.from,
      1
    )
    .then((value) => {
      dispatch(setRoaster(value.data.data));
      //loading roaster loader
      dispatch(setIsLoadingRoaster(false));
    })
    .catch((err) => {
      console.log(err);
    });
  }
  

  useEffect(() => {
    //loading roaster loader
    dispatch(setIsLoadingRoaster(true));

    const dates = getDates(
      shiftStateWeeklyDateLabel.from,
      shiftStateWeeklyDateLabel.to
    );
    setDates(dates);
    branch
      .getBranches()
      .then((value) => {
        setBranches(value.data);
      })
      .catch((err) => {
        console.log(err);
      });

    shift
      .fetchCreatedSlots()
      .then((value) => {
        dispatch(setShiftSlot(value.data));
      })
      .catch((err) => {
        console.log(err);
      });

      getRoaster()

    shift
      .fetchSwappableShift(selectedBranchState, 1)
      .then((value) => {
        dispatch(setSwappableShift(value.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    shiftStateWeeklyDate,
    shiftStateToggleCalendar,
    selectedBranchState,
    // dispatch,
    shiftStateWeeklyDateLabel.from,
    shiftStateWeeklyDateLabel.to,
  ]);

  return (
    <section className="homepage">
      <HomeNavbar />
      <AlertDialog />
      <AnimatePresence>
        {shiftToggleDetailState.state && (
          <ShiftDetails open={true} handleClose={false} />
        )}
      </AnimatePresence>
      <SideBar custom="shift" subCustom="shift-board">
        <section className="overview">
          <div
            style={{
              display: 'inline-flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <BranchesHeader
              title="Shift board"
              subtitle="View shift calendar and monitor shifts"
            />

            <ColorToggleButton />

            <div style={{ width: '100px' }}></div>
          </div>

          <div className="shift-nav">
            <div className="left">
              <SelectBranch branches={branches} />

              {shiftStateToggleCalendar === 'week' && (
                <NavigateCalender diff={7} />
              )}
              {shiftStateToggleCalendar === 'day' && <NavigateTime />}
            </div>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '40%',
                justifyContent: 'flex-end',
                gap: '20px',
              }}
            >
               
              </Box>
          </div>
          <section className="inner-section">
            <div className="shift-table">
              {shiftStateToggleCalendar === 'week' && (
                <WeekView dates={dates} refresh={getRoaster}/>
              )}
              {shiftStateToggleCalendar === 'day' && (
                <DayView usersOnShift={shiftStateRoaster} />
              )}
            </div>
          </section>
        </section>
      </SideBar>

    </section>
  );
};
