import React from 'react';
import ListIcon from '../../../Assets/clipboard.png';
import { BsArrowRightSquareFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import AppLayout from '../../Widgets/AppLayout';
import { CgCalendarDates } from "react-icons/cg";
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { suppliersTutorial } from '../mock';

const Index = () => {
    const navigate = useNavigate()
    
    const cardList = [
        {
            title: "Suppliers",
            subtitle: "See the suppliers you have and manage them",
            onClick: () => navigate("/suppliers-page")
        },
        {
            title: "Manufacturers",
            subtitle: "View and manage the manufacturers",
            onClick: () => navigate("/manufacturers-page")
        },
        
    ]
  
    const RenderCardList = ({ title = "", subTitle = "", onClick = () => {}}) => {
    return (
      <div className="rounded-xl border dark:border-969 relative py-[10px] px-[15px] w-[300px] h-[150px]">
        <div className="flex gap-3 dark:text-white items-center mb-[11px]">
          <CgCalendarDates />
          <p className="text-base font-semibold">{title}</p>
        </div>
        <p className="text-sm text-A5A dark:text-white mb-[18px] ">
          {subTitle}
        </p>
        <div className="absolute right-3 bottom-3 dark:text-white">
          <BsArrowRightSquareFill className="cursor-pointer" onClick={onClick} size={30} />
        </div>
      </div>
    );
  };

  return (
    <section>
       <AppLayout
        custom="inventory"
        subCustom="suppliers"
        title="Suppliers / Manufacturers"
        customTitle="Inventory"
        subtitle=""
        RightComponent={<TutorialPanel items={suppliersTutorial} />}
      >
        <div className="flex items-center gap-10 flex-wrap">
          {
              cardList?.map((x) => (
                  <RenderCardList onClick={x.onClick} title={x.title} subTitle={x.subtitle} />
              ))
          }
        </div>

      </AppLayout>
      
    </section>
  );
};

export default Index