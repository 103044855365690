import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { font } from '../../../../../../Widgets/Styles/font';
import UserDropdown from '../../../../../../Widgets/UserDropdown';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../../../../Widgets/Loader';
import FormModal from '../../../../../../Widgets/Animation/FormModal';
import {
  restoreBackupRotationASync,
  getBackupRotationASync,
} from '../../../../../../../Store/Rotation';
import * as yup from 'yup';
import { toast } from 'react-toastify';

export default function RestoreBackupRotation({
  open,
  handleClose,
  selectedYear,
  selectedRotation,
  refresh
}) {
  const dispatch = useDispatch();

  const rotationStore = useSelector(
    (state) => state?.rotations
  )?.backupRotations;

  const loading = useSelector(
    (state) => state?.rotations
  )?.loading;

  const handleCloseModal = () => {
    handleClose();
  };

  useEffect(() => {
    dispatch(
      getBackupRotationASync({
        rotationNumber: selectedRotation.value,
        rotationYear: Number(selectedYear.value),
      })
    );
  }, [dispatch, selectedRotation.value, selectedYear.value]);

  return (
    <div>
      <FormModal open={open} handleClose={handleClose}>
        <Formik
          initialValues={{
            rotationNumber: selectedRotation.value,
            rotationYear: selectedYear.value,
            backupId: '',
          }}
          validationSchema={yup.object({
            backupId: yup.string().required('This is required')
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              dispatch(restoreBackupRotationASync({
                ...values,
                rotationYear: Number(values.rotationYear)
              })).then((res) => {
                if(res?.payload?.status === 404){
                  toast.error(res?.payload?.data?.message);
                } else {
                  refresh();
                  toast.success('backup rotation was sucessfully restored');
                  handleCloseModal();
                }
              });
            }, 400);
          }}
        >
          {({
            errors,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            values,
            ...rest
          }) => (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '485px',
                minHeight: '507px',
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                borderRadius: '5px',
                overflowY: 'auto',
                overflowX: 'hidden',
                py: '53px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: '44px',
                ...font,
              }}
            >
              <div className="w-full">
                <Typography
                  sx={{
                    fontSize: '24px',
                    color: '#242424',
                    fontWeight: '700',
                    marginTop: '3rem',
                    textAlign: 'center',
                  }}
                >
                  Restore Rotation {values.rotationNumber}
                </Typography>

                <div className="mt-[35px] w-full">
                  <div className="flex flex-col justify-end">
                    <Typography
                      variant="span"
                      className="text-[10px] font-medium mb-4"
                    >
                      Select backup
                    </Typography>
                    <div className="mt-3">
                      <UserDropdown
                        data={rotationStore?.map((back) => ({
                          label: back?.backupName,
                          value: back?.id,
                          color: '#242424',
                        }))}
                        onChangeOption={(e) => {
                          setFieldValue('backupId', e.value);

                        }}
                        showAvatar={false}
                        className="h-[51px]"
                        defaultLabel={{
                          label: 'Select backup',
                          value: 'select',
                        }}
                        headerClass="text-xs p-0"
                      />
                      {errors?.backupId ? (
                        <p className="text-red-500 text-[10px]">
                          {errors?.backupId}
                        </p>
                      ) : (
                        <p className="text-[10px] text-white">required</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {(!loading)? (
                <Box
                  className="mt-12"
                  sx={{
                    display: 'flex',
                    gap: '10rem',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      borderColor: 'black',
                      color: 'black',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'black',
                      color: 'white',
                    }}
                    disabled={isSubmitting}
                  >
                    Restore
                  </Button>
                </Box>
              ) : (
                <Loader />
              )}
            </Box>
          )}
        </Formik>
      </FormModal>
    </div>
  );
}
