import { Storage } from "../StorageClass/StorageClass";
import axios from "axios";
import { conn_url } from "../conn_consts";

const storage = new Storage();

export class Home{

    async getHomeData(){
        const response = await axios.get(conn_url+"/api/v1/users/session", storage.getConfig());
        console.log(response.data)
        localStorage.setItem("activeCompany", JSON.stringify(response.data?.activeCompany))
        return {data: response.data, status:  response.status};
    }

    async getCategories(){
        const response = await axios.get(conn_url+"/api/v1/company/categories", storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async createCompany(data){
        const response = await axios.post(conn_url+"/api/v1/company", data,  storage.getConfig());
        console.log(response.data)
        return {data: response.data, status:  response.status};
    }


    async editCompany(data){
        const response = await axios.patch(conn_url+"/api/v1/company", data , storage.getConfig());
        return response.data;
       
      }

    async getCountries(){
        const response = await axios.get(conn_url+"/api/v1/countries", storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async getCity(id){
        const response = await axios.get(conn_url+"/api/v1/cities?stateId="+id, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async getState(id){
        const response = await axios.get(conn_url+"/api/v1/states?countryId="+id, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async switchCompany(data){
        const response = await axios.post(conn_url+'/api/v1/users/switch-company', data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async getLicense(){
        const response = await axios.get(conn_url+"/api/v1/users/licences", storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async uploadDp(data){
        const response = await axios.put(conn_url+"/api/v1/users/profile-picture", data, storage.getFileConfig());
        return {data: response.data, status:  response.status};
    }

    async deleteLicense(data){
        const response = await axios.delete(conn_url+`/api/v1/users/licences/${data}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async finishTour(){
        const response = await axios.put(conn_url+`/api/v1/users/taken-tour`,{
            "tourStatus":"done"
          }, storage.getConfig());

          localStorage.setItem('user', JSON.stringify(response.data.data))
        return {data: response.data, status:  response.status};
    }

}