import axios from "axios";
import { Storage } from "../StorageClass/StorageClass";
import { conn_url } from "../conn_consts";
import { getConfig } from "@testing-library/react";

const storage = new Storage();


export class TaskClass{

    async createTask(data){
        const response = await axios.post(conn_url+"/api/v1/boards",data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async getBoards(){
        const response = await axios.get(conn_url+'/api/v1/boards', storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async getBoard(id){
        const response = await axios.get(conn_url+`/api/v1/boards/${id}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async getUserTask(){
        const response = await axios.get(conn_url+`/api/v1/tasks/userTasks`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async createATask(data){
        const response = await axios.post(conn_url+'/api/v1/tasks', data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async AddComment(id,data){
        const response = await axios.post(conn_url+`/api/v1/tasks/${id}/comments`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async getComments(data){
        const response = await axios.get(conn_url+`/api/v1/tasks/${data}/comments`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async DeleteComment(taskid, id){
        const response = await axios.delete(conn_url+`/api/v1/tasks/${taskid}/comments/${id}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    
}