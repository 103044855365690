import React from 'react';

const tabStyle = 'border-b-2 border-white hover:text-blue-700 hover:border-blue-700 dark:text-white'

export const TaskTabs = ({index, setIndex}) => {
  return (

      <div data-tour="step5" className='flex gap-4'>

          <Tab onClick={()=>setIndex(0)} text={'Tasks'} index={index} id={0}/>
          <Tab onClick={()=>setIndex(1)} text={'Overdue tasks'} index={index} id={1}/>
      </div>

  )
}

const Tab = ({text, onClick, index, id}) => {
  return (
      <div 
        onClick={onClick} 
        className={ `font-medium cursor-pointer 
         hover:text-secondary  
           ${index === id ?'text-secondary border-secondary border-b-2 dark:border-secondary dark:text-secondary':'text-5A5'} `}>
          {text}
      </div>

    )

}
