import React from 'react'
import { useSelector } from 'react-redux';
import Summary from './Summary';


const Col1 = () => {
 
  const workHourState = useSelector(state => state.dashboard.workHoursNonAdmin)
    
     
  return (
    <div className='flex flex-col w-[57%] gap-3 shadow-shift-shadow px-2'>
        {workHourState !== null && <div className='flex flex-col border-1 p-3 rounded-lg gap-2'>
            <p className='text-sm font-medium'>Hours worked</p>

            <div className='flex flex-row gap-2'>
                <CustomBox title={'This week'} desc={workHourState.week}/>
                <CustomBox title={'This rotation'} desc={workHourState.rotation}/>
                <CustomBox title={'Overtime in rotation'} desc={workHourState.rotationOvertime}/>
            </div>
           
        </div>}

        <Summary />

    </div>
  )
}

export default Col1


const CustomBox = ({title, desc}) => {
  return (
    <div className='flex flex-col border-1 rounded-md justify-between w-[182px] h-[100px] p-3'>
        <p className='text-[#A5A5A5]'>{title}</p>
        <p className='font-semibold text-[26px]'>{desc}</p>
    </div>
  )
}
