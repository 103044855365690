import { useState, useEffect } from 'react'
import './CompanyData.css'
import { useDispatch } from 'react-redux';
import { Storage } from '../../../Network/StorageClass/StorageClass'
import Row1 from './Widget/Row1/Row1'
import Row2 from './Widget/Row2/Row2'
import Row3 from './Widget/Row3/Row3'
import Row5 from './Widget/Row5/Row5';
import Row6 from './Widget/Row6/Row6';
import Row7 from './Widget/Row7/Row7';

import {
  getOverView, 
  getWorkedHours, 
  getEmployeeOvertime, 
  getShiftSwap, 
  getOpenSlot, 
  getShiftCancellation} from '../../../Network/ServiceClass/dashboardService'
import { getWeek } from '../../../utils/getWeek'
import addOneDay from '../../../utils/incrementDate'
import formatDate from '../../../utils/formatDate'
import {setOverview,
  setWorkHours,
  setEmployeeOvertime,
  setShiftSwap,
  setOpenSlot,
  setShiftCancellation} from '../../../Store/dashboard'
import NonAdmins from './NonAdmins/NonAdmins'
import { Branch } from '../../../Network/ServiceClass/Branch'  
import { useSelector } from 'react-redux'
import Row4 from './Widget/Row4/Row4';

const branchService = new Branch()


const VerifyAdmins = () => {
    const dispatch = useDispatch();
    const activeCompany = useSelector(state => state.company.activeCompany)

    const storage = new Storage();
  
    const [branch, setBranch] = useState([])
  
      const to = addOneDay(getWeek(0).lastDay.toUTCString())
      const from = formatDate(getWeek(0).firstDay.toUTCString())
      // console.log(to, from)
  
      const dashboardData = () => {
        getWorkedHours(from, to).then(val=>{
          dispatch(setWorkHours(val))
        }).catch()
  
        getOverView(from, to).then(val=>{
          dispatch(setOverview(val))
        }).catch()
  
        getShiftSwap(from, to).then(val=>{
          dispatch(setShiftSwap(val))
        }).catch()
  
        getEmployeeOvertime().then(val=>{
          dispatch(setEmployeeOvertime(val))
        }).catch()
  
        getShiftCancellation(from, to).then(val=>{
          dispatch(setShiftCancellation(val))
        }).catch()
  
        getOpenSlot().then(val=>{
          dispatch(setOpenSlot(val))
        }).catch()
  
      }
  
      const [priviledge, setPriviledge] = useState(null)
      
      useEffect(()=>{
        setPriviledge(activeCompany?.memberships[0]?.privilege)
        if([1,2].includes(priviledge)){
          dashboardData()
        }
  
      },[activeCompany])
  


  return (
    <div>
         {[1,2].includes(priviledge) && <div className='w-[100%] rounded-lg mt-3 p-6 flex flex-col gap-10'>
            {/* first row */}
                <Row5/>
                <Row6/>
                <Row4 priviledge={priviledge}/>
                <Row1/>
                <Row3 />
                <Row2/>
                <Row7 />


          </div>}

          {[3].includes(priviledge) && <div> 
            <NonAdmins/> 
        </div>}
    </div>
  )
}

export default VerifyAdmins