export const Table = ({children}) => {
    return(
        <div className="flex flex-col mt-8" >
             {/* <p className='text-[12px] text-[A5A5A5] dark:text-gray-100'>Swap requests</p> */}
             <div className='h-[70vh] overflow-y-auto'>
                <table>
                    <tr className='sticky bg-0F5 dark:bg-444 dark:bg-292 dark:text-white top-0'>
                        <td className="py-4 px-4">Name</td>
                        <td>Email</td>
                        <td>Date</td>
                        <td>Time</td>
                        <td>Location</td>
                        
                    </tr>
                    {children}
                </table>
                </div>   
            {/* {isLoading && <Box sx={{width: '10%', margin: 'auto', marginTop: '2rem'}}><CircularProgress /> </Box>} */}
        </div>
    )
}
    