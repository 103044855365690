export const statuses = [
  {
    label: 'Not Started',
    value: 'Not Started',
    color: '#676767'
  },
  {
    label: 'In Progress',
    value: 'In Progress',
    color: '#FFCC00'
  },
  {
    label: 'done',
    value: 'done',
    color: '#5CB26E'
  },
]

export const statusColor = {
  "not started": "#676767",
  "in progress": "#FFCC00",
  "done": "#00AD57",
}

export const priority = [
  {
    label: 'Highest',
    value: 'highest',
    color: '#9A4242',
    bgColor: '#F5E4E4'
  },
  {
    label: 'High',
    value: 'high',
    color: '#AE5AAB',
    bgColor: '#F9E9F9'
  },
  {
    label: 'Medium',
    value: 'medium',
    color: '#FFCC00',
    bgColor: '#FFFBEB'
  },
  {
    label: 'Low',
    value: 'low',
    color: '#415EC2',
    bgColor: '#E4E9FD'
  },
  {
    label: 'Lowest',
    value: 'lowest',
    color: '#67909B',
    bgColor: '#D7E2E5'
  },
]


export const writeUpTutorial = [
  {
      title: "Create a Writeup", 
      desc: "Watch how to create a writeups.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
  {
      title: "Delete A writeup", 
      desc: "Watch how to delete a writeup.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const ticketTutorial = [
  {
      title: "Create a ticket", 
      desc: "Watch how to create a ticket.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const jobGroupTutorial = [
  {
      title: "Create a job group", 
      desc: "Watch how to create a job group.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630545/nitro-help-videos/Job_roles_group_announcement_m8wohq.mov"
  },
]

export const announcementTutorial = [
  {
      title: "Create an announcement", 
      desc: "Watch how to create an announcement.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const unavailableDaysTutorial = [
  {
      title: "Book an unavilable", 
      desc: "Watch how to create an unavailable days.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const shiftTypesTutorial = [
  {
      title: "Create a shift type", 
      desc: "Watch how to create a shift type.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
  {
      title: "Delete a shift type", 
      desc: "Watch how to delete a shift type.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const holidayTutorial = [
  {
      title: "Create holidays", 
      desc: "Watch how to create holidays.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630477/nitro-help-videos/Request_leave_t1t8xg.mov"
  },
]

export const skillsetTutorial = [
  {
      title: "Create a Skillset", 
      desc: "Watch how to create a skillset.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
  {
      title: "Delete a Skillset", 
      desc: "Watch how to delete a skillset.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
  {
      title: "Edit a Skillset", 
      desc: "Watch how to edit a skillset.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const companyDataTutorial = [
  {
      title: "View company company data", 
      desc: "Watch how to view your company data",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699631672/nitro-help-videos/Data_mwvaq8.mov"
  },
]

export const rotationManagementTutorial = [
  {
      title: "Set a rotation Public/Private", 
      desc: "Watch how to set a rotation public or private.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const conpanyDetailsTutorial = [
  {
      title: "View a company details", 
      desc: "Watch how to fill your company details.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const locationScheduleTutorial = [
  {
      title: "View a Location schedule", 
      desc: "Watch how to view location schedule.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const combinedSchehduleTutorial = [
  {
      title: "View combined tutorial", 
      desc: "Watch how to fill your company details.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const swapOffersTutorial = [
  {
      title: "Swap offers", 
      desc: "Watch how to swap offers.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const timeOffTutorial = [
  {
      title: "Set Timeoff", 
      desc: "Watch how to set time off.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630477/nitro-help-videos/Request_leave_t1t8xg.mov"
  },
]

export const clockinClockoutTutorial = [
  {
      title: "Clock in Clock", 
      desc: "Watch how to clock in and out.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const reportTutorial = [
  {
      title: "View Report", 
      desc: "Watch how to view report.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const aiChatTutorial = [
  {
      title: "AI Chat", 
      desc: "Watch how to interract with AI.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const taskTutorial = [
  {
      title: "Assign a task", 
      desc: "Watch how to assign task.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630329/nitro-help-videos/Task_dzq46f.mov"
  },
  {
      title: "Update a task", 
      desc: "Watch how to update task progress.",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630329/nitro-help-videos/Task_dzq46f.mov"
  },
]


export const inventoryItemTutorial = [
  {
      title: "Transfer Item", 
      desc: "Watch how to update inventory item",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]


export const transferItemTutorial = [
  {
      title: "Transfer Item", 
      desc: "Watch how to transfer inventory item",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const transferItemListTutorial = [
  {
      title: "View Transfer Item List", 
      desc: "Watch how to navigate transfer item lists",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const orderRequestTutorial = [
  {
      title: "Create Order Request", 
      desc: "Watch how to create order requests",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const backOrderRequestTutorial = [
  {
      title: "Create Back Order Request", 
      desc: "Watch how to create back order requests",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const quickWithdrawalTutorial = [
  {
      title: "Create Quick Witdhrawal", 
      desc: "Watch how to create quick withdrawal",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const logsTutorial = [
  {
      title: "View Logs", 
      desc: "Watch how to view logs",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const inventoryDataTutorial = [
  {
      title: "View Inventory Data", 
      desc: "Watch how to view inventory data",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const suppliersTutorial = [
  {
      title: "Create Suppliers/Manufacturer", 
      desc: "Watch how to create supplier details and manufacturer",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]

export const measurementTutorial = [
  {
      title: "Create Measurement", 
      desc: "Watch how to create measurement",
      link: "https://res.cloudinary.com/dllkyarpl/video/upload/v1699630504/nitro-help-videos/Location_schedules_frtivu.mov"
  },
]