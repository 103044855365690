import React, { useState } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useSelector } from "react-redux";

import { ClickAwayListener } from "@mui/base";
import avatar from "../../../Assets/avatar.png";
import { CustomPopper } from "../../Widgets/CustomPopper";
import { AnimatePresence, motion } from "framer-motion";
import { ClickableItem } from "../../Widgets/ClickableItem";

const LiveUsers = () => {
  const liveUsers = useSelector((state) => state.socket);
  const [toggleLive, setToggleLive] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);

  const handleClickAway = () => {
    setToggleLive(false);
    setMenuIndex(null);
  };

  return (
    <div>
      <div className=" w-[390px] z-0 shadow-live-shadow rounded-t-2xl pr-4 pl-8 pb-3 flex justify-between items-center absolute top-0 right-24 h-[80px] pt-2 bg-white dark:text-white dark:bg-[#3c3c3c]">
        <p className="text-[18px] text-A5A font-bold dark:text-white ">
          Online{" "}
          <span className="text-[#6D6D6D] dark:text-white">
            ({liveUsers?.loggedUsersCount})
          </span>
        </p>

        {!toggleLive && (
          <AiOutlineArrowUp
            onClick={() => setToggleLive(true)}
            className="text-[#BDBDBD] dark:text-white cursor-pointer"
            size={18}
          />
        )}
      </div>

      <ClickAwayListener onClickAway={handleClickAway}>
        <AnimatePresence>
          {toggleLive && (
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className={`w-[390px] z-[50] shadow-live-shadow rounded-t-2xl pr-2 pl-8 pb-3 fixed top-[12%] right-24 overflow-y-auto pt-0 bg-white dark:bg-[#3c3c3c] h-[100%] `}
            >
              <div>
                <div className="h-[70px] flex justify-between items-center mb-3 pr-3 bg-white dark:bg-[#3c3c3c] sticky top-0">
                  <p className="text-[18px] text-A5A dark:text-white font-bold">
                    Online{" "}
                    <span className="text-[#6D6D6D] dark:text-white">
                      ({liveUsers?.loggedUsersCount})
                    </span>
                  </p>

                  {toggleLive && (
                    <AiOutlineArrowDown
                      onClick={() => {
                        setToggleLive(false);
                        setMenuIndex(null);
                      }}
                      className="text-[#BDBDBD] dark:text-white cursor-pointer"
                      size={18}
                    />
                  )}
                </div>

                {liveUsers.loggedInUsers.map((val, index) => (
                  <div
                    key={index}
                    className={`${index !== 0 ? "mt-10" : ""} ml-5 mr-0`}
                  >
                    <div className="flex gap-5 items-center">
                      <p
                        style={{
                          background:
                            val?.branch?.hexcode !== "null"
                              ? val?.branch?.hexcode
                              : "black",
                        }}
                        className={` w-2 h-2 rounded-full`}
                      />
                      <p className="text-[#6D6D6D] dark:text-white text-[15px] font-bold">
                        {val?.branch?.name}
                      </p>
                    </div>

                    {val?.data?.map((user, index) => (
                      <div key={index} className="mt-6 ml-9">
                        <div className="flex gap-4 items-center">
                          <div class="w-8 h-7 rounded-full overflow-hidden shadow-md">
                            <img
                              src={
                                user?.user?.picture === null
                                  ? avatar
                                  : user?.user?.picture?.url
                              }
                              alt="Avatar"
                              class="w-full h-full object-cover"
                            />
                          </div>
                          <div className="flex flex-col gap-[2px] w-full">
                            <div className="flex justify-between items-center">
                              <p className="text-[16px] text-[#818181] dark:text-white font-bold">
                                {user?.user?.firstName} {user?.user?.lastName}
                              </p>
                              <p className="text-[#86AFAA] dark:text-white text-[10px] font-bold">
                                Remote
                              </p>

                              <div className="relative flex flex-col gap-1 items-end">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setToggleMenu(
                                      toggleMenu ? null : e.currentTarget
                                    );
                                    setMenuIndex(user?.user?.id);
                                  }}
                                  className={`${
                                    toggleMenu && menuIndex === user?.user?.id
                                      ? "shadow-shift-shadow-small"
                                      : ""
                                  } hover:shadow-shift-shadow-small text-gray-400 font-bold rounded-full p-2`}
                                >
                                  <HiOutlineDotsVertical />
                                </button>

                                <CustomPopper
                                  openPopper={toggleMenu}
                                  handleClose={() => setToggleMenu(null)}
                                  className="z-[50]"
                                  placement="bottom-start"
                                >
                                  <div className="h-[86px] w-[91px] mt-1 py-3 font-bold flex flex-col gap-1 text-[#738583] dark:text-gray-300 text-[12px] bg-white dark:bg-1F1 rounded-lg shadow-shift-shadow-small">
                                    <ClickableItem
                                      className={"dark:text-white"}
                                      text={"Message"}
                                      handleClick={() => {}}
                                    />
                                    <ClickableItem
                                      className={"dark:text-white"}
                                      text={"Profile"}
                                      handleClick={() => {}}
                                    />
                                    <ClickableItem
                                      className={"dark:text-white"}
                                      text={"Schedule"}
                                      handleClick={() => {}}
                                    />
                                  </div>
                                </CustomPopper>
                              </div>
                            </div>

                            <p className="text-[10px] text-[#787878] dark:text-gray-300">
                              {user?.teamMember?.jobRole?.title}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ClickAwayListener>
    </div>
  );
};

export default LiveUsers;
