import React, { useState, useEffect } from 'react';
import './JobScreen.css';
import { NewJobRoleModal } from './Modal/NewJobRoleModal';
import { CircularProgress, Stack, ClickAwayListener } from '@mui/material';
import { Storage } from '../../../Network/StorageClass/StorageClass';
import AlertDialog from '../../Widgets/SuccessDialog';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import PopDetail from './Detail/PopDetail';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import { fetchJobRolesAsync, fetchJobGropusAsync } from '../../../Store/Branches';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteJobRoleModal } from './Modal/DeleteRoleModal';
import { NewJobGroupModal } from './Modal/NewJobGroup';
import AppLayout from '../../Widgets/AppLayout';
import SearchInput from '../../Widgets/SearchInput';
import { TableWrapper, Tr, Td } from '../../Widgets/Table/TableWrapper';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';


export const JobScreen = () => {
  const storage = new Storage();
  const dispatch = useDispatch()
  const priviledge = storage.getActiveCompany().memberships[0].privilege;
  const jobRoles = useSelector(state => state.branches)?.jobRoles
  const loading = useSelector(state => state.branches)?.loading

  const [openDeleteModal, setToggleDeleteModal] = useState(false);
  const [newjob, setNewJob] = useState(false);
  const [newJobGroup, setNewJobGroup] = useState(false);
  const [openDropdown, setDropdown] = useState(false);

  const [openPopper, setOpenPopper] = useState(null);
  const [jobData, setJobData] = useState(null);

  const [clickedRow, setClikedRow] = useState(null);
  const [openForEdit, setOpenForEdit] = useState(false);
  const [searchedJobRole, setSearchedJobRoles] = useState('');

  const handleOpen = () => {
    setNewJob(true);
    setDropdown(false)
  };

  const handleOpenDropdown = () =>{
    setDropdown(true)
  }

  const handleOpemJobGroup = () =>{
    setNewJobGroup(true)
  }

  const handleCloseJobGroup = () =>{
    setNewJobGroup(false)
  }
  const handleClose = () => {
    setNewJob(false);
  };

  const closeDeleteModal = () => {
    setToggleDeleteModal(false)
  }

  const handleOpenDeleteModal = (value) => {
    setToggleDeleteModal(true)
    setJobData(value);
  }

  const callback = () => {
    dispatch(fetchJobRolesAsync());
    dispatch(fetchJobGropusAsync())
  };

  useEffect(() => {
    callback();
  }, []);

  const handleOpenPopper = (event, index, value) => {
    setOpenPopper(openPopper ? null : event.currentTarget);
    setJobData(value);
    setClikedRow(clickedRow ? null : index + 1);
    setOpenForEdit(false);
  };
  const handleOpenPopperForEdit = (event, index, value) => {
    setOpenPopper(openPopper ? null : event.currentTarget);
    setJobData(value);
    setClikedRow(clickedRow ? null : index + 1);
    setOpenForEdit(true);
  };
  const mainAnim = {
    exit: {
      opacity: 0,
      transition: { ease: 'easeOut', duration: 1 },
    },
  };

  const handleJobRoleSearch = (jobName) => {
    const filteredData = jobRoles?.filter((jobRole) => jobRole?.title?.toLowerCase().includes(jobName?.toLowerCase()))
    return filteredData;
  }

  const handleOnSearchJobRole = (e) => {
    setSearchedJobRoles(e.target.value);
  }

  return (
    <section className="homepage">
      <DeleteJobRoleModal
      open={openDeleteModal}
      data={jobData}
      handleClose={closeDeleteModal}
      loading={loading}
      callback={callback}
      />

      {newJobGroup && (<NewJobGroupModal
       open={newJobGroup}
       handleClose={handleCloseJobGroup}
       jobRoles={jobRoles}
      />)}

      <AlertDialog />
      <AnimatePresence>
        {openPopper && (
          <motion.div variants={mainAnim} exit={'exit'}>
            <PopDetail
              openPopper={openPopper}
              setOpenPopper={setOpenPopper}
              data={jobData}
              setClikedRow={setClikedRow}
              edit={openForEdit}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AppLayout
        custom="company"
        subCustom="job"
        title="Job roles"
        customTitle=" "
        subtitle="Managing job roles"
        RightComponent={<TutorialPanel items={[{ title: "Job Roles", desc: "Watch how to add shift roles to your organization scheduler."}]} />}
      >

        <div className="border-1 rounded-md flex flex-col gap-3 p-7 h-[70vh] dark:border-333">

          <div className="flex justify-between items-center">

            <SearchInput 
              placeholder='Search job role'
              onChange={handleOnSearchJobRole}
            />

            {[1, 2].includes(priviledge) && (
              <ClickAwayListener onClickAway={()=>{
                setDropdown(false)
              }}>
              <div className="relative">
                <ButtonWithIcon title='New' type='text' handleClick={handleOpenDropdown}/>
                {openDropdown && (<div className='absolute right-0 top-full w-40 bg-white shadow-select py-7 z-30 dark:bg-A06 dark:text-white'>
                  <p onClick={handleOpen} className="text-xs font-medium py-2  hover:text-green-600 cursor-pointer px-7">Job Role</p>
                  <p onClick={handleOpemJobGroup} className="text-xs font-medium py-2  hover:text-green-600 cursor-pointer px-7">Job Group Tag</p>
                </div>)}
              </div>
              </ClickAwayListener>
             
            )}
          </div>

          {!loading && (
              <TableWrapper>

                <table className="">
                  <thead>
                    <tr className='bg-0F5 font-bold sticky top-0 dark:bg-1F1'>
                      <Td className={'px-2 py-4 rounded-tl-md w-[22%] !text-left pl-4'} >Job Title</Td>
                      <Td className='w-[22%]'>Job Code</Td>
                      <Td className='w-[22%] !text-left pl-4'>Date Created</Td>
                      <Td className='w-[23%] !text-left pl-4'>Description</Td>
                      {[1,2].includes(priviledge)&&(<Td colSpan={2} className='w-[10%] !border-none'></Td>)}
                    </tr>
                  </thead>
                  <tbody>
                    {handleJobRoleSearch(searchedJobRole)?.map((value, index) => {
                      const date = new Date(value.createdOn.split('T')[0])
                        .toDateString()
                        .split(' ');
                      return (
                        <Tr
                          style={{
                            boxShadow:
                              clickedRow === index + 1
                                ? '10px 10px 40px rgba(0, 0, 0, 0.08)'
                                : '',
                          }}
                        >
                          <Td className='py-4 pl-4 !text-left'
                            onClick={(event) =>
                              handleOpenPopper(event, index, value)
                            }
                          >
                            {value.title}
                          </Td>
                          <Td
                              onClick={(event) =>
                                handleOpenPopper(event, index, value)
                              }
                          >
                            {value?.symbol?.toUpperCase()}
                          </Td>
                          <Td
                            onClick={(event) =>
                              handleOpenPopper(event, index, value)
                            }
                            className="!text-left pl-4"
                          >
                            {date.slice(0, 4).join(' ')}
                          </Td>
                          <Td
                            onClick={(event) =>
                              handleOpenPopper(event, index, value)
                            }
                            className="!text-left pl-4"
                          >
                            {value.desc}
                          </Td>
                          {[1,2].includes(priviledge)&&(<Td className={'!border-none'}
                          >
                            <div className='flex gap-3 justify-center items-center'>
                              <FiEdit2 onClick={(event) =>
                              handleOpenPopperForEdit(event, index, value)
                            }/>
                              <AiOutlineDelete onClick={() => handleOpenDeleteModal(value)}/>

                            </div>
                          </Td>)}
                          
                        </Tr>
                      );
                    })}
                  </tbody>
                </table>
              </TableWrapper>
          )}

          {loading && (
            <Stack mt={5} direction="row" justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
        </div>
      </AppLayout>

      <NewJobRoleModal
        open={newjob}
        handleClose={handleClose}
        callback={callback}
      />
    </section>
  );
};


