import {useState, useEffect, useCallback} from 'react'
import AppLayout from '../../../Widgets/AppLayout'
import SearchInput from '../../../Widgets/SearchInput'
import CustomDropdown from '../../../Widgets/CustomDropdown'
import GlobalButton from '../../../Widgets/GlobalButton'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getTransferItemsRequestThunk } from '../../../../Store/Inventory'
import { CircularProgress } from '@mui/material'
import dayjs from 'dayjs';
import { Branch } from '../../../../Network/ServiceClass/Branch'
import { statusColor } from '../../../../utils/inventoryStatus'
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter'
import { CustomPopper } from '../../../Widgets/CustomPopper'
import { ClickableItem } from '../../../Widgets/ClickableItem'
import { Storage } from '../../../../Network/StorageClass/StorageClass'
import { inventoryUpdateTransferItemsStatus } from '../../../../Network/ServiceClass/InventoryService'
import { toast } from 'react-toastify'
import { IoQrCode } from 'react-icons/io5';
import { usePDF } from 'react-to-pdf';
import QRCode from 'react-qr-code'
import { TableWrapper, TableHeader, Td, Tr } from '../../../Widgets/Table/TableWrapper'
import TutorialPanel from '../../../Widgets/Tutorial/TutorialPanel'
import { transferItemListTutorial } from '../../mock'



const branchService = new Branch()
const storage = new Storage()
const ListOfTransferedItems = () => {

    const { toPDF, targetRef } = usePDF({filename: 'item-transfer-request.pdf'});

    const dispatch = useDispatch()
    const itemsList = useSelector(state => state.inventory)?.transferRequestItems || []
    const loading = useSelector(state => state.inventory)?.loading
    const activeCompany = useSelector(state => state.company)?.activeCompany
    const [showPDFContent, setShowPDFContent] = useState(false)
    const [printData, setPrintData] = useState(null)
    const [branches, setBranches] = useState([])

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All transfered from location', value: null})
    const [transferedBranch, setTransferedBranch] = useState({label: 'All transfered to location', value: null})


    const navigate = useNavigate()
    const [searchValue,setSearchValue] = useState('')

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id



    const handleDownloadPdf = () => {
        setShowPDFContent(true)
        setTimeout(() => {
            toPDF();
            setShowPDFContent(false)
         }, 100);
    }

    const getItems = () => {
        dispatch(getTransferItemsRequestThunk({
            withdrawalLocation: withdrawalBranch.value, 
            transferedLocation: transferedBranch.value
        }))
    }

    useEffect(()=> {
        getItems()
    },[withdrawalBranch, transferedBranch])
    
    useEffect(()=> {
        branchService.getBranches()
        .then(val => {
            setBranches(val?.data.map(x => ({label: x.name, value: x.id, hexcode: x?.hexcode})))
        })
        getItems()
    },[])

    const handleSearch = useCallback(() => {
        let data = itemsList 
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                data = itemsList.filter((val) => (
                    val.referenceNumber?.toLowerCase().match(pattern) ||
                    val.reference?.name?.toLowerCase().match(pattern) ||
                    val.batchIdentifier?.toLowerCase().match(pattern) )) 
        }
        return data
    
    },[searchValue, itemsList])


    const handleApprove = (value) => {
        setIsLoadingAction(true)
        const data = {
            referenceNumber: value?.referenceNumber,
            locationId: value?.transferLocation?.id,
            status: "approved",
            trackingStatus: "shipped",
            teamMemberId: teamMembershipId
        }

        inventoryUpdateTransferItemsStatus(data)
        .then(val => {
            if (val?.status === 200){
                toast.success('Approved Successfully')
                setToggleMenu(null)
                getItems()
            }else toast.error(val?.data?.message)
            setIsLoadingAction(false)
        })
    }


    return (
        <div>
            <AppLayout
                custom="inventory"
                subCustom="transfered-items-list"
                title="Transfered Items List"
                customTitle="Inventory"
                subtitle=""
                RightComponent={<TutorialPanel items={transferItemListTutorial} />}
            >
                <div className='flex justify-between items-end' >

                    <div className='flex gap-2 items-center mt-4'>
                        <SearchInput 
                            className={'w-[300px] border-gray-200 h-12'}
                            inputStyle={'placeholder:text-gray-400'}
                            iconStyle='text-gray-400'
                            placeholder='Search for items...' 
                            onChange={(e)=>{setSearchValue(e.target.value)}}
                        />

                        <CustomDropdown
                            trimtext
                            trimLength={17}
                            data={[{label: 'All transfered from location', value: null},...branches]}
                            className="w-48 h-12 rounded ml-4"
                            dropdownTitleClass="text-A5A"
                            optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                            value={withdrawalBranch}
                            onChangeOption={(val)=>setWithdrawalBranch(val)}
                        />

                        <CustomDropdown
                            trimtext
                            trimLength={17}
                            data={[{label: 'All transfered to location', value: null}, ...branches]}
                            className="w-48 rounded h-12"
                            value={transferedBranch}
                            dropdownTitleClass="text-A5A"
                            optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                            onChangeOption={(val)=> setTransferedBranch(val)}
                        />

                       


                    </div>

                    <GlobalButton 
                        // disabled={isLoading}
                        onClick = {()=> navigate('/transfer-items')}
                        title="Add New" 
                        showIcon
                        className="rounded-md text-white font-semibold text-[16px] "
                    />
                </div>

                <TableWrapper className='mt-6 !max-h-[70vh] mb-12 '>
                    <table className=''>
                        <TableHeader>
                            <Td className='py-4'>BatchID</Td>
                            <Td className=''>Transfered From</Td>
                            <Td className=''>Transfered To</Td>
                            <Td className='text-center'>Amount</Td>
                            <Td className='text-center'>No. of items</Td>

                            <Td className={'!rounded-none'}>Date</Td>
                            <Td className='!border-none w-[50px]'></Td>

                        </TableHeader>
                        {!loading ? <tbody>
                            {handleSearch()?.length !== 0 ?
                                handleSearch().map(val => (
                                    <Tr
                                        onClick={()=> {isAllow() && navigate(`/transfered-items-list/${val?.batchIdentifier
                                        }`)}}
                                        className={`font-bold ${isAllow() && '!cursor-pointer hover:bg-5F5 hover:dark:bg-292'}`}>
                                            <Td className='py-4'>{val?.batchIdentifier}</Td>
                                            {/* <Td>{val?.reference?.name}</Td> */}
                                            <Td >
                                                <div className='flex justify-start items-center gap-2'>
                                                    <p className='w-2 h-2 rounded-full' style={{background: val?.fromLocation?.hexcode}}/>
                                                    <p>{val?.fromLocation?.locationName}</p>
                                                </div>
                                            </Td>
                                            <Td>
                                                <div className='flex justify-start items-center gap-2'>
                                                    <p className='w-2 h-2 rounded-full' style={{background: val?.toLocation?.hexcode}}/>
                                                    <p>{val?.toLocation?.locationName}</p>
                                                </div>
                                            </Td>
                                            <Td className="text-center">{val?.items.length === 0 ? 0 : val?.items.reduce((accumulator, currentValue) => accumulator + currentValue.quantityWithdrawn, 0)}</Td>
                                            <Td className="text-center">{val?.items.length || 0 }</Td>
                                            <Td>{dayjs(val?.items[0]?.createdOn).format('YYYY-MM-DD')}</Td>
                                            
                                            <Td className={'cursor-pointer !border-none '}>
                                                <div className='flex justify-center items-center'>

                                                    <IoQrCode 
                                                        onClick={(e)=>{
                                                            e.stopPropagation()
                                                            handleDownloadPdf()
                                                            setPrintData(val)

                                                        }}
                                                        className='cursor-pointer'/>
                                                </div>
                                            </Td>

                                    </Tr>

                                ))
                                :<tr>
                                    <td colSpan={8} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                                </tr>
                            }
                        </tbody>:
                        <tr>
                            <td colSpan={8} className='text-center pt-10 border border-white dark:border-A1A'><CircularProgress /></td>
                        </tr>
                        }

                    
                        
                    </table>
                    
                    

                </TableWrapper>
                <PdfTemp 
                    showPDFContent={ showPDFContent} 
                    targetRef={targetRef} data={printData} 
                    company={activeCompany?.name || ''}
                />
            </AppLayout>
        </div>
    )
}

export default ListOfTransferedItems


const PdfTemp = ({showPDFContent, targetRef, data, company}) => {
    return (
        <div className={`absolute w-[100%] px-3 py-3 left-[-1500px] top-0 ${!showPDFContent && 'hidden'}`} ref={targetRef} 
                    >
                        <div className='flex flex-col gap-10'>

                            <div className='text-[18px] font-bold flex flex-col gap-2'>
                                <p>Company Name: {company}</p>
                                <p>Transfered From: {data?.fromLocation?.locationName}</p>
                                <p>Transfered To: {data?.toLocation?.locationName}</p>

                            </div>

                            <div >
                                <p className='text-[18px] font-bold mb-3'>Items List:</p>
                                <table>
                                    <tr className='font-bold'>
                                        <td className='pl-4 py-4 border-1 text-center border-[black]'>Ref No.</td>
                                        <td className='border-1 text-center border-[black]'>Name</td>
                                        <td className='border-1 text-center border-[black]'>From Location</td>
                                        <td className='border-1 text-center border-[black]'>To Location</td>
                                        <td className='border-1 text-center border-[black]'>Amount</td>
                                    </tr>
                                    <tbody>
                                        {data?.items.map(val => (
                                            <tr>
                                                <td className='pl-4 py-4 border-1 text-center border-[black]'>{val?.referenceNumber}</td>
                                                <td className='border-1 text-center border-[black]'>{val?.name}</td>
                                                <td className='border-1 text-center border-[black]'>{data?.fromLocation?.locationName}</td>
                                                <td className='border-1 text-center border-[black]'>{data?.toLocation?.locationName}</td>
                                                <td className='border-1 text-center border-[black]'>{val?.quantityWithdrawn}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='w-full flex justify-center mt-10'>
                                <div className='bg-white'>
                                    <QRCode
                                    size={200}
                                    value={data?.batchIdentifier || ''} />
                                </div>

                            </div>
                        </div>
        </div>
    )
}