import { useEffect, useState } from 'react'
import CustomDropdown from '../../../Widgets/CustomDropdown';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { createInventoryItemThunk, getAllItemsThunk, getInventoryGroupsThunk } from '../../../../Store/Inventory';
import { Loader } from '../../../Widgets/Loader';
import AppLayout from '../../../Widgets/AppLayout';
import { getBranchesAsync } from '../../../../Store/Branches';
import GlobalButton from '../../../Widgets/GlobalButton';
import CreateInventoryModal from './components/CreateInventoryItem';
import { toast } from 'react-toastify';
import sortBy from "lodash/sortBy"
import SearchInput from '../../../Widgets/SearchInput';
import { TableWrapper, Td, Tr } from '../../../Widgets/Table/TableWrapper';

export const itemStatus = {
  "received": <p className="text-26E text-center font-semibold bg-CF6 rounded-[40px] py-[10px] px-[14px]">Received</p>,
  "pending_approval": <p className="text-1F5 text-center bg-4FE font-semibold rounded-[40px] py-[10px] px-[14px]">Pending approval</p>,
  "ordered": <p className="text-09B text-center bg-0F2 font-semibold rounded-[40px] py-[10px] px-[14px]">Ordered</p>,
  "partial": <p className="text-C00 text-center bg-BEB font-semibold rounded-[40px] py-[10px] px-[14px]">Partial</p>,
}

export const statusTransform = {
  "received": "Received",
  "pending_approval": "Pending approval",
  "ordered": "Ordered",
  "partial": "Partial",
}


export const InventoryItemList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchText, searchinventory] = useState("")
  const [headerToSort, sortHeader] = useState("name")
  const [order, setOrder] = useState("asc")
  const [createModalIsOpen, openCreateModal] = useState(false)
  const inventoryItem = useSelector((state) => state.inventory)?.items || []
  const inventoryGroup = useSelector((state) => state.inventory)?.groups
  const loading = useSelector((state) => state.inventory)?.loading

  const [group, selectGroup] = useState({
    label: 'All groups',
    value: '',
  })

  useEffect(()=>{
    dispatch(getAllItemsThunk())
    dispatch(getInventoryGroupsThunk())
    dispatch(getBranchesAsync())
  },[])

  const handleCloseModal =() => {
    openCreateModal(false)
  }

  const handleSorting = () => {
    let data = sortBy(inventoryItem.map((x)=> ({...x, suppliers: x?.manAndSupp?.length || 0})), [headerToSort])

    if(order === "desc"){
      data = data.reverse()
    }

    return data
  }

  const handleSubmitItem = (data) => {
    const newData = {...data, packageCost: data.packageCost * 100}
    dispatch(createInventoryItemThunk(newData)).then((res) => {
      if(res?.meta?.requestStatus === "fulfilled"){
        dispatch(getAllItemsThunk()).then((res) => {
          if(res?.meta?.requestStatus === "fulfilled"){
            handleCloseModal();
            toast.success("Inventory item was successfully created")
          }
        })
      } else {
        toast.error(res?.payload?.data?.message)
      }
    })
  }

  const handleHeaderClick = (header)=> {
    sortHeader(header);

    if(order === "asc"){
      setOrder("desc")
    } else {
      setOrder("asc")

    }
  }

  const handleSelectGroup = (val) => {
    selectGroup(val)
  }
  
  return (
    <section>
      <AppLayout
        custom="inventory"
        subCustom="inventory-items"
        title="Inventory Items"
        customTitle=" "
        subtitle=""
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <IoIosArrowBack
              onClick={() => navigate(-1)}
              size={25}
              className="cursor-pointer dark:text-white"
            />
            <p className="font-medium text-base text-5F3 my-6">
              <Link to="/inventory-items">INVENTORY ITEMS</Link>
              <span className="text-A5A">/ INVENTORY LIST</span>
            </p>
          </div>
          <GlobalButton
            onClick={() => openCreateModal(true)}
            showIcon
            title="Create Item"
          />
        </div>
        <div className="flex items-center gap-3 dark:text-white">
          <SearchInput 
            placeholder='search inventory item'
            onChange={(e) => {
              searchinventory(e.target.value);
            }}
          />
         
          <div className="flex items-center gap-3 dark:text-black">
            <CustomDropdown
              data={[
                {
                  label: 'All groups',
                  value: '',
                },
                ...inventoryGroup?.map((x) => ({
                  label: x?.name,
                  value: x?.id,
                })),
              ]?.map((team) => ({
                label: team?.label,
                value: team?.value,
              }))}
              optionClass="z-[500]"
              className="min-w-[150px] w-max rounded h-[42px]"
              value={group}
              onChangeOption={handleSelectGroup}
            />
            
          </div>
        </div>
        <TableWrapper className="mt-6 !max-h-[70vh] mb-12 ">
          <table >
              <tr className="bg-0F5 dark:bg-color-333 dark:text-white font-bold sticky top-0">
                <Td><p onClick={()=>{handleHeaderClick("referenceNumber")}} className="cursor-pointer py-4">Reference Number</p></Td>
                <Td><p onClick={()=>{handleHeaderClick("group.name")}} className="cursor-pointer">Group </p></Td>
                <Td><p onClick={()=>{handleHeaderClick("name")}} className="cursor-pointer">Name </p></Td>
                <Td><p onClick={()=>{handleHeaderClick("packageCost")}} className="cursor-pointer">Item price </p></Td>
                <Td><p onClick={()=>{handleHeaderClick("suppliers")}} className="cursor-pointer"># Suppliers</p></Td>
                <Td><p onClick={()=>{handleHeaderClick("inventoryManufacturer.name")}} className="cursor-pointer">Manufacturer</p></Td>
              </tr>
            <tbody>
              {!loading &&
                handleSorting()
                  ?.filter((x) =>
                    x?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
                  )?.filter((y) => y?.groupId?.includes(group?.value))
                  ?.map((item) => (
                    <Tr
                      style={{fontWeight: 700}}
                      className="hover:bg-5F5 hover:dark:bg-292"
                      onClick={() =>
                        navigate(
                          `/inventory-items-list/${item?.referenceNumber}/${item?.id}`
                        )
                      }
                    >
                      <Td className="py-4" >{item?.referenceNumber}</Td>
                      <Td>{item?.group?.name}</Td>
                      <Td>{item?.name}</Td>
                      <Td>${(item?.packageCost/100)?.toFixed(2)}</Td>
                      <Td>{item?.manAndSupp?.length || 0}</Td>
                      <Td>{item?.inventoryManufacturer?.name || 'N/A'}</Td>
                      {/* <td><p className="text-26E py-[10px] px-[14px] bg-CF6 rounded-[40px] w-max">Received</p></td> */}
                    </Tr>
                  ))}
              {!loading && handleSorting()?.filter((x) =>
                    x?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
                  )?.filter((y) => y?.groupId?.includes(group?.value))?.length === 0 && (
                <tr>
                  <td className="text-center h-[300px]" colSpan={6}>
                    <p>No inventory available</p>
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {createModalIsOpen && (
            <CreateInventoryModal
              isLoading={loading}
              handleSubmitItem={handleSubmitItem}
              openInventoryModal={createModalIsOpen}
              handleCloseInventoryModal={handleCloseModal}
            />
          )}
        </TableWrapper>

      </AppLayout>
    </section>
  );
};
