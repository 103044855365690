import React, { useState, useEffect } from "react";
import { Stack, Typography, CircularProgress } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { TbSend } from "react-icons/tb";
import { ImAttachment } from "react-icons/im";
import { motion, AnimatePresence } from "framer-motion";
import PopMenu from "./PopMenu";
import { CiSearch } from "react-icons/ci";
import { Branch } from "../../../../../Network/ServiceClass/Branch";
import {
  createAnnouncement,
  uploadAnnouncementFile,
} from "../../../../../Network/ServiceClass/announcementService";
import { useFormik } from "formik";
import * as yup from "yup";
import PopError from "../../../../Widgets/PopError";
import { useDispatch, useSelector } from "react-redux";
import { refresh } from "../../util/refresh";
import { toast } from "react-toastify";
import { fetchJobRolesAsync } from "../../../../../Store/Branches";
import { ClickAwayListener } from "@mui/base";
import { Divider } from "@mui/material";
import { getAllAnouncementTagAsync } from "../../../../../Store/announcement";
import CustomDropdown from "../../../../Widgets/CustomDropdown";
import { TextAndField, Field } from "../../../../Widgets/FormUtils/FormUtils";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AttachFileIcon, SendIcon } from "../../../../../Assets/Svg/Index";

const branchService = new Branch();
export default function CreateAnnouncement({ openDrawer, handleCloseDrawer }) {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: (value) => {
      if (criteriaSelected === null) {
        setIsError(true);
        setErrorMessage("You have to select attachement type");
      } else {
        const data = {
          title: value.title,
          description: value.description,
          tagId: selectedAnnouncementTag?.value || undefined,
          jobRoleIds:
            selectedJobRole.length >= 1
              ? selectedJobRole.map((x) => x.id)
              : undefined,
          branchIds:
            selectedBranch.length >= 1
              ? selectedBranch.map((x) => x.id)
              : undefined,
          attachements:
            fileName === null
              ? []
              : [
                  {
                    url: fileUrl ? fileUrl : "",
                    name: fileName ? fileName : "",
                    type: "application/pdf",
                  },
                ],
        };

        console.log(data);
        setIsSubmit(true);
        createAnnouncement(data)
          .then((val) => {
            setIsSubmit(false);
            toast.success("Announcement created successfully");

            refresh(dispatch);
            formik.handleReset();
            handleCloseDrawer(false);
          })
          .catch((error) => {
            setIsSubmit(false);
          });
      }
    },
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleCloseDrawer(open);
  };

  // error and error message
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // set branch to send announcement
  const [branch, setBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const announcementTags =
    useSelector((state) => state.announcement)?.announcementTags || [];

  const jobRoles = useSelector((state) => state?.branches)?.jobRoles.map(
    (val) => ({ id: val.id, name: val.title })
  );

  useEffect(() => {
    setIsLoading(true);
    branchService
      .getBranches()
      .then((val) => {
        setIsLoading(false);
        setBranch(val.data);
      })
      .catch((error) => setIsLoading(false));
  }, []);

  useEffect(() => {
    dispatch(fetchJobRolesAsync());
    dispatch(getAllAnouncementTagAsync());
  }, [dispatch]);

  // select pop

  const [openListMenu, setOpenListMenu] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [openJobRolePopper, setOpenJobRolePopper] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedJobRole, setSelectedJobRole] = useState([]);
  const [company, setCompany] = useState(null);
  const [selectedAnnouncementTag, selectAnnouncementTag] = useState({
    label: "All Announcement tags",
    value: "",
  });
  const [criteriaSelected, setCriteriaSelected] = useState(null);

  const [fileName, setFileName] = useState(null);

  const handleCloseListMenu = () => {
    setOpenListMenu(null);
    setOpenPopper(false);
    setOpenJobRolePopper(false);
  };

  // manage file upload
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target?.files[0];
    setFileName(fileUploaded?.name);
    const formdata = new FormData();
    formdata.append("file", fileUploaded);

    setIsUploadingFile(true);
    uploadAnnouncementFile(formdata)
      .then((val) => {
        setIsUploadingFile(false);
        setFileUrl(val?.data?.url);
      })
      .catch((error) => {
        setIsUploadingFile(false);
      });
  };
  // end of file upload

  const componentVariant = {
    hidden: { x: "50vw" },
    vissible: {
      x: 0,
      transition: { ease: "easeIn", delay: 0, duration: 1 },
    },
    exit: {
      x: "50vw",
      transition: { ease: "easeOut", delay: 0, duration: 1 },
    },
  };

  const handleClickAway = (e) => {
    e.stopPropagation();
    // handleCloseDrawer()
  };

  const handleDisplayLabel = (data, defaultLabel, type) => {
    if (data?.length === 0) {
      return defaultLabel;
    } else if (data?.length === 1) {
      return data[0]?.name;
    } else {
      return `${data.length} Selected ${type}`;
    }
  };

  const handleSelectAnnouncementTag = (val) => {
    selectAnnouncementTag(val);
  };

  return (
    <div className="relative">
      {isLoading && <p>...</p>}

      <AnimatePresence>
        {openPopper && (
          <PopMenu
            openPopper={openPopper}
            placement="bottom"
            setOpenPopper={setOpenPopper}
          >
            <PopContent
              data={branch}
              setOpenJobRolePopper={setOpenPopper}
              setSelectedBranch={(branches) => setSelectedBranch(branches)}
              selectedBranch={selectedBranch}
              handleClose={() => {
                setOpenPopper(null);
                handleCloseListMenu();
              }}
            />
          </PopMenu>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {openJobRolePopper && (
          <PopMenu
            openPopper={openJobRolePopper}
            placement="bottom"
            setOpenPopper={setOpenJobRolePopper}
          >
            <PopContent
              data={jobRoles}
              setOpenJobRolePopper={setOpenJobRolePopper}
              setSelectedBranch={(jobRoles) => setSelectedJobRole(jobRoles)}
              selectedBranch={selectedJobRole}
              handleClose={() => {
                setOpenJobRolePopper(null);
                handleCloseListMenu();
              }}
            />
          </PopMenu>
        )}
      </AnimatePresence>

      <ClickAwayListener onClickAway={handleClickAway}>
        {openDrawer && (
          <motion.div
            variants={componentVariant}
            initial={"hidden"}
            animate={"vissible"}
            exit={"exit"}
            style={{
              zIndex: 1200,
              // boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1)'
            }}
            className="fixed top-0 right-0 shadow-shift-shadow bg-white dark:bg-1F1 dark:text-white h-[100vh] overflow-y-auto"
          >
            <Stack
              className="py-1 px-[20px] "
              width={510}
              direction="column"
              mt={1}
              gap={2}
            >
              {/* first row */}
              <div className="flex w-[60%] self-end items-center justify-between">
                <Typography variant="p" className="font-semibold text-16">
                  New Announcements
                </Typography>

                <IoClose
                  onClick={toggleDrawer(false)}
                  className="dark:text-white text-[16px] self-end cursor-pointer"
                />
              </div>

              <TextAndField space={0.5}>
                <p className="text-[14px] text-A5A dark:text-white font-medium">
                  Title of announcement
                </p>
                <Field
                  formik={formik}
                  value={formik.values.title}
                  handleChange={formik.handleChange}
                  text="Enter title"
                  name="title"
                />
              </TextAndField>

              <TextAndField space={0.5}>
                <p className="text-[14px] text-A5A dark:text-white font-medium">
                  Description of task
                </p>
                <Field
                  maxRows={3}
                  minRows={3}
                  multiline
                  formik={formik}
                  value={formik.values.description}
                  handleChange={formik.handleChange}
                  text="Enter description"
                  name="description"
                />
              </TextAndField>

              <div className="flex flex-col justify-between">
                <div
                  className="border-1 border-[#c2c2c2] cursor-pointer text-[14px] hover:border-black dark:border-333 dark:hover:border-969 text-[#5A5A5A] dark:text-white rounded-md py-2 p-2 flex flex-row items-center justify-between"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenPopper(openPopper ? null : event.currentTarget);
                    setCriteriaSelected("branch");
                    setOpenJobRolePopper(null);
                  }}
                >
                  <p>
                    {handleDisplayLabel(
                      selectedBranch,
                      "Select Locations",
                      "Locations"
                    )}
                  </p>
                  <AiOutlineCaretDown className="text-[12px] text-grey-600" />
                </div>
                <div
                  className="border-1 border-[#c2c2c2] cursor-pointer my-3 text-[14px] hover:border-black dark:border-333 dark:hover:border-969 text-[#5A5A5A] dark:text-white rounded-md py-2 p-2 flex flex-row items-center justify-between"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenJobRolePopper(
                      openJobRolePopper ? null : event.currentTarget
                    );
                    setCriteriaSelected("jobRole");
                    setOpenPopper(null);
                  }}
                >
                  <p>
                    {handleDisplayLabel(
                      selectedJobRole,
                      "Select Job Roles",
                      "Job Roles"
                    )}
                  </p>
                  <AiOutlineCaretDown className="text-[12px] text-grey-600" />
                </div>

                <CustomDropdown
                  data={[
                    { tagName: "All Announcement tags", id: "" },
                    ...announcementTags,
                  ]?.map((tag) => ({ label: tag?.tagName, value: tag.id }))}
                  className=" h-[43px]"
                  value={selectedAnnouncementTag}
                  dropdownTitleClass="text-A5A dark:text-white"
                  optionClass="z-[1000] shadow-md min-h-[50px] w-[200px]"
                  onChangeOption={handleSelectAnnouncementTag}
                />
              </div>

              {/* 
              <div className="w-[470px] h-[270px] border-1 dark:border-969 rounded-md p-3 flex flex-col">
                <input
                  className="text-[18px] dark:bg-1F1 font-semibold w-[400px] border-none p-[5px] focus:outline-none"
                  type="text"
                  value={formik.values.title}
                  name="title"
                  onChange={formik.handleChange}
                  placeholder="Title of announcement"
                />
                {formik.touched.title && formik.errors.title && (
                  <p className="text-[12px] text-red-600">
                    {formik.errors.title}
                  </p>
                )}

                <textarea
                  className="text-[12px] font-semibold w-[400px] border-none p-[5px] dark:bg-1F1 dark:text-white text-[#5A5A5A] resize-none focus:outline-none"
                  rows="8"
                  cols="10"
                  value={formik.values.description}
                  name="description"
                  onChange={formik.handleChange}
                  placeholder="What is the description of the task"
                />
                {formik.touched.description && formik.errors.description && (
                  <p className="text-[12px] text-red-600">
                    {formik.errors.description}
                  </p>
                )}
              </div> */}

              {criteriaSelected !== null && (
                <div className="flex flex-col text-gray-500 gap-2 p-2 h-full">
                  {criteriaSelected === "company" && <p>Company Members</p>}

                  {criteriaSelected !== "company" &&
                    selectedBranch.length > 0 && (
                      <div className="flex flex-col">
                        <p className="text-grey-600 text-base font-medium">
                          Selected Locations
                        </p>
                        <div className="flex gap-2 flex-wrap text-[black] mt-4">
                          {selectedBranch.map((val) => (
                            <div className="bg-[#E0FAFF] dark:bg-969 flex justify-between items-center rounded-2xl text-[12px] min-w-[100px] p-[10px] px-[8px]">
                              <p className="text-base dark:text-white">
                                {val.name}
                              </p>
                              <IoClose
                                size={20}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedBranch([
                                    ...selectedBranch.filter(
                                      (x) => x.id !== val.id
                                    ),
                                  ]);
                                }}
                                className="cursor-pointer ml-[10px] text-black dark:text-white"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                  {criteriaSelected !== "company" &&
                    selectedJobRole.length > 0 && (
                      <div className="flex flex-col mt-4 dark:text-white">
                        <p>Selected Job Roles</p>
                        <div className="flex gap-2 flex-wrap text-[black] mt-4">
                          {selectedJobRole.map((val) => (
                            <div className="bg-[#E0FAFF] dark:bg-969 dark:text-white dark:border-none flex justify-between items-center rounded-2xl text-[12px] min-w-[100px] p-[10px] px-[8px]">
                              <p className="text-base dark:text-white">
                                {val.name}
                              </p>
                              <IoClose
                                size={20}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedJobRole([
                                    ...selectedJobRole.filter(
                                      (x) => x.id !== val.id
                                    ),
                                  ]);
                                }}
                                className="cursor-pointer ml-[10px] text-black dark:text-white"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              )}

              <div className="flex mt-2 text-[20px] justify-between text-gray-500 dark:text-gray-300">
                {!isUploadingFile ? (
                  <p className="text-[12px] font-semibold items-center flex gap-2">
                    {fileName ?? ""}{" "}
                    {fileName ? (
                      <span>
                        <IoClose
                          onClick={() => setFileName(null)}
                          className="text-[14px] cursor-pointer"
                        />
                      </span>
                    ) : null}
                  </p>
                ) : (
                  <p></p>
                )}
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <div className="flex self-end gap-2">
                  {!isUploadingFile && (
                    <AttachFileIcon
                      className="cursor dark:text-white"
                      onClick={handleClick}
                    />
                  )}
                  {isUploadingFile && <CircularProgress size={30} />}

                  {!isSubmit && (
                    <SendIcon
                      className="cursor dark:text-white"
                      onClick={formik.handleSubmit}
                    />
                  )}
                  {isSubmit && <CircularProgress size={30} />}
                </div>
              </div>
              {isError && (
                <PopError
                  message={errorMessage}
                  handleClick={() => setIsError(false)}
                />
              )}
            </Stack>
          </motion.div>
        )}
      </ClickAwayListener>
    </div>
  );
}

const PopContent = ({
  data,
  setSelectedBranch,
  selectedBranch,
  handleClose,
  setOpenJobRolePopper,
}) => {
  // console.log(data)
  const [branches, setBranches] = useState(selectedBranch);
  const [text, setText] = useState("");

  const handleSearch = () => {
    let filteredArray = data;

    if (text !== "") {
      const pattern = new RegExp(`\D*${text}\D*`, "i");
      filteredArray = data?.filter((x) => x.name.match(pattern));
    }
    return filteredArray;
  };

  setSelectedBranch(branches);

  return (
    <Stack
      sx={{
        maxHeight: "300px",
        boxShadow: "10px 10px 40px rgba(0, 0, 0, 0.08)",
        borderRadius: "5px",
        overflowY: "auto",
      }}
      className="bg-white dark:bg-A06 dark:text-white dark:shadow-222 w-[470px] mt-1"
    >
      <div className="sticky top-0 z-50 flex flex-col gap-2 bg-white dark:bg-A06 dark:text-white">
        <div className="border-1 flex flex-row gap-1 dark:bg-A06 dark:border-333 items-center py-[7px] px-[5px] rounded-[3px]">
          <CiSearch className="text-[14px]" />
          <input
            onChange={(e) => {
              setText(e.target.value);
            }}
            onFocus={(e) => e.stopPropagation()}
            value={text}
            className=" h-[14px] border-none w-[70%] dark:bg-A06 px-2 m-0n outline-none text-[12px]"
            type="text"
            placeholder="Search"
          />
        </div>
      </div>

      <div className="mt-3 flex flex-col gap-2 relative p-[0.5rem]">
        {handleSearch(text).map((val) => {
          return (
            <div
              className={`hover:bg-gray-100 dark:hover:bg-color-333 flex gap-2 items-center text-sm py-1 px-[5px] cursor-pointer`}
              onClick={(e) => {
                e.stopPropagation();
                if (branches.find((b) => b.id === val.id) !== undefined) {
                  setBranches(branches.filter((b) => b.id !== val.id));
                } else {
                  setBranches([...branches, val]);
                }
              }}
            >
              <input
                type="checkbox"
                checked={branches.find((b) => b.id === val.id)}
              />{" "}
              <p>{val.name}</p>
            </div>
          );
        })}
        {data?.length === 0 && (
          <p className="text-sm text-grey-600 dark:hover:text-white">
            No branches
          </p>
        )}
      </div>
      <div className="sticky bottom-0 bg-white border-t-1 dark:border-333 dark:bg-A06 p-[0.5rem] flex justify-end">
        <Divider orientation="horizontal" className="dark:bg-969" />
        <button
          onClick={(e) => {
            e.stopPropagation();
            setOpenJobRolePopper(null);
            handleClose();
          }}
          className="bg-base-color w-14 self-end cursor-pointer rounded-[3px] text-[white] px-1 py-1"
        >
          Done
        </button>
      </div>
    </Stack>
  );
};
