import React from 'react'
import { Box } from '@mui/material'
import swapPic from '../../../../../Assets/swap_notification.png'

export const ShiftSpot = ({data}) => {

  return (
    <div style={{backgroundColor:"#FFFBED", padding: data.length !== 0 ? '10px 3px': 0, margin:'3px 3px 3px 0'}}>
      {(data.length !== 0) && <div className='shift-spot' >
          <div>{data[0].slot.startTime}-{data[0].slot.endTime}</div>
          
          <Box sx={{fontSize:"12px", display:'flex', justifyContent:'space-between'}}>
            {data[0].slot.jobRole.title} {(data.length > 1) && <span>...</span> }

          {data[0].swappable && <img src={swapPic} alt="swap notifier" /> }

          </Box>
      </div> }

      {(data.length === 0) && <div className=''>
          <div></div>
      </div> }

    </div>
  )
}
