/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBranchesAsync,
  fetchJobRolesAsync,
  getTeammemberAsync,
  getSkillSetAsync,
} from '../../../../../Store/Branches';
import { getDatesArray } from '../../../../../utils/dateArray';

import DateLabel from '../../Widget/DateLabel/DateLabel';
import { transformLocationScheduleData } from '../../../../../utils/handleLocationSchedule';
import { cancelScheduleAsync, createAssigneeShiftslotAsync, getShiftScheduleNoLoadingAsync } from '../../../../../Store/Shift';
import moment from 'moment';
// import ScheduleDayView from './components/ScheduleDayView';
import { Loader } from '../../../../Widgets/Loader';

import { months } from './mock';
import EditSchedule from './Widgets/EditBranchSchedule';
import { toast } from 'react-toastify';
import getDay from 'date-fns/getDay';
import AvatarDropdown from '../../../../Widgets/AvatarDropdown';
import { setCellBackground } from '../../../../../utils/util';
import AppLayout from '../../../../Widgets/AppLayout';
import TablePagination from '@mui/material/TablePagination';
import { getShiftTemplateAsync } from '../../../../../Store/Shift';
import TutorialPanel from '../../../../Widgets/Tutorial/TutorialPanel';
import { locationScheduleTutorial } from '../../../mock';
import MultipleSelectDropdown from '../../../../Widgets/MultiDropdown';
import generateCalendarDates from '../../../../../utils/generateScheduleDate';


const getMonth = {
  "January": "01",
  "February": "02",
  "March": "03",
  "April": "04",
  "May": "05",
  "June": "06",
  "July": "07",
  "August": "08",
  "September": "09",
  "October": "10",
  "November": "11",
  "December": "12",
}

export const BranchSchedules = () => {
  const dispatch = useDispatch();

  const store = useSelector((state) => state.branches)?.teamMembers?.map(
    (team) => {
      return {
        id: team?.user?.id,
        jobRoleId: team.jobRole.id,
        name: `${team?.user?.firstName} ${team?.user?.lastName}`,
        gender: team?.user?.gender,
        skillSets: team?.userSkillSets
      }
    }
  );

  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];
  const jobRoles = useSelector((state) => state?.branches)?.jobRoles;
  const loading = useSelector((state) => state?.shift)?.loading;
  const branchLoading = useSelector((state) => state?.branches)?.loading;
  const teamMembers = useSelector((state) => state?.branches)?.teamMembers;
  const schedules = useSelector((state) => state?.shift)?.schedules;
  const dateCreated = useSelector(
    (state) => state.company.activeCompany.createdOn
  );
  const shiftTemplates =
    useSelector((state) => state?.shift)?.shiftTemplates || [];
  const skillSets = useSelector((state) => state?.branches)?.skillSets?.data || [];


  const [open, setOpen] = useState(false);
  const [selectedSchedule, selectSchedule] = useState({});
  const [selectedMultiBranch, setMultiBranch] = useState([]);
  const [calendarRange, setCalendarRange] = useState([]);
  const privilege =
    useSelector((state) => state?.company)?.privilege || 3;  

  const [selectedBranch, setBranch] = useState({
    label: 'All locations',
    value: '',
  });
  const [selectedJobRole, setJobRole] = useState({
    label: 'All job roles',
    value: '',
  });
  const [yearSelected, setYearSelected] = useState({
    label: moment().format("yyyy"),
    value: moment().format("yyyy"),
  });
  const [monthSelected, setMonth] = useState({
    label: moment().format("MMMM"),
    value: getMonth[moment().format("MMMM")],
  });


  const [selectedUser, selectUser] = useState({
    label: 'Everyone',
    value: '',
  });

  const [selectedSkill, selectSkill] = useState({
    label: 'All Skill Sets',
    value: '',
  });

  const [selectedGender, selectGender] = useState({
    label: 'No Gender Check',
    value: '',
  });

  const [selectedTemplate, selectTemplate] = useState({
    label: 'Default Shift Template',
    value: '',
  });


const [rowsPerPage, setRowPerPage] = useState(10)
const [page, setPage] = useState(0)

  const handleSchedule = useCallback(() => {
    const newBranches = branches?.filter(x => {
      if(selectedMultiBranch?.length > 0){
        return selectedMultiBranch?.map((y) => y.value).includes(x.id)
      }
      return true
    })?.slice(page*rowsPerPage, (page*rowsPerPage )+ (rowsPerPage));
    return transformLocationScheduleData({
      teamMembers,
      branches: newBranches
    });
  }, [page, rowsPerPage,branches, selectedMultiBranch?.length]);

  const handleChangePage = (event, newPage) => {
    // const newOffset = newPage * rowsPerPage;
    // setOffset(newOffset);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleToggleModal = (value) => {
    setOpen(value)
  }

  const handleAllSchedule = (branch, date) => {

    const newSchedules = schedules;
    return newSchedules?.filter((x) => ((x.branchId === branch) && (moment(x.start).format("yyyy-MM-DD") === moment(date).format("yyyy-MM-DD"))))?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y }))
  }

  const handleSelectedSchedule = (data, branch, date) => {
    if([1,2].includes(privilege)){
      setOpen(true);
      selectSchedule({ schedules: data, branch, date })
    }
  }

  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(fetchJobRolesAsync());
    dispatch(getTeammemberAsync({}));
    dispatch(getSkillSetAsync())
    dispatch(getShiftTemplateAsync());


    if (scheduleFilter) {
      const existingLocation = JSON.parse(
        localStorage.getItem('@schedulefilter')
      );

      setMultiBranch(existingLocation?.assignLocation);
    }

    const dates = generateCalendarDates(yearSelected.value, monthSelected.value);
    setCalendarRange(dates)
  }, []);

  useEffect(()=>{
    const dates = generateCalendarDates(yearSelected.value, monthSelected.value);
    setCalendarRange(dates)
  },[yearSelected, monthSelected])

  const handleGetShiftSchedule = () => {
    const endDate = moment(
      `${yearSelected.value}-${monthSelected.value}`,
      'yyyy-MM-DD'
    ).daysInMonth();

    dispatch(
      getShiftScheduleNoLoadingAsync({
        jobRoleId: selectedJobRole?.value,
        branchId: selectedBranch?.value,
        from: moment(
          `${yearSelected.value}-${monthSelected.value}-01`,
          'yyyy-MM-DD'
        )
          .add(0, 'hours')
          .format('yyyy-MM-DDTHH:mm'),
        to: moment(
          `${yearSelected.value}-${monthSelected.value}-${endDate}`,
          'yyyy-MM-DD'
        )
          .add(0, 'hours')
          .format('yyyy-MM-DDTHH:mm'),
      })
    );
  };


  let scheduleFilter =
    localStorage.getItem('@schedulefilter') &&
    JSON.parse(localStorage.getItem('@schedulefilter'));


  useEffect(() => {
    handleGetShiftSchedule();
  }, [
    yearSelected.value,
    monthSelected.value,
    selectedBranch.value,
    selectedJobRole.value,
  ]);

  const handleJobRole = (e) => {
    setJobRole(e);
  };

  const handleOnMonthChange = (data) => {
    setMonth(data);
  };

  const handleOnDateChange = (data) => {
    setYearSelected(data);
  };

  const getBranchHexCode = (data) => {
    const [{ slot: { jobRole: { hexcode } } }] = data;
    return hexcode !== "NULL" ? hexcode : '';
  }

  const handleSubmitUser = async (users) => {
    selectedSchedule?.schedules?.forEach((res) => {
      if (!users.includes(res.userId)) {
        const userData = JSON.parse(localStorage.getItem("user"))
        dispatch(cancelScheduleAsync({ id: res?.id, privileges: [userData.id] })).then((res) => {
          if ([404, 400, 401].includes(res?.payload?.response?.status)) {
            toast.error(res?.payload?.response?.data?.message)
          } else {
            toast.success("User schedule was successfully cancelled")
            handleGetShiftSchedule();
          }
        })
      }
    })

    const shiftTemp = shiftTemplates?.find((x) => x.id === selectedTemplate?.value)

    users.forEach((user, index) => {
      if (!selectedSchedule?.schedules?.map((x) => x.userId).includes(user)) {
        const userData = store.filter((x) => x.id === user)[0]
        dispatch(createAssigneeShiftslotAsync({
          assignees: [{ userId: user, userName: userData.name }],
          bookDate: moment(selectedSchedule.date).format("yyyy-MM-DD"),
          branchId: selectedSchedule?.branch?.branchId,
          dayOfWeek: getDay(moment(selectedSchedule.date).toDate()),
          hours: shiftTemp?.hours || 8,
          staffRequired: shiftTemp?.maxStaffRequired || 1,
          startTime: shiftTemp?.startTime || "09:00",
          jobRoleId: shiftTemp?.jobRole?.id || userData.jobRoleId,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })).then((val) => {
          handleGetShiftSchedule(val);
          if (users.length === index + 1) {
            if(val.payload.status === 201){
              toast.success("Location Schedule was successfully updated")

            }else {
              toast.error(val?.payload?.data?.message)
            }
          }
        })
      }
    })
    handleToggleModal(false);
  }

  const handleSelectUser = (e) => {
    selectUser(e)
  }

  const handleSelectSkill = (e) => {
    selectSkill(e)
  }

  const handleSelectGender = (e) => {
    selectGender(e)
  }
  
  const handleFilterSelection =useCallback((branch, date) => {

    if(selectedUser.value === "" && selectedSkill.value === "" && selectedGender.value ===""){
      return null
    }

    const extractSchedule = handleAllSchedule(branch, date);
    

    return setCellBackground(extractSchedule, selectedUser, selectedSkill, selectedGender)
    
  }, [selectedUser.value, selectedGender.value, selectedSkill.value]);


  const handleSelectedShiftTemplate = (e) => {
    selectTemplate(e)
  }

  const handleMultiSelect = (val) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        assignLocation: val,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem(
        '@schedulefilter',
        JSON.stringify({ assignLocation: val })
      );
    }
    setMultiBranch(val);
  };


  return (
    <AppLayout
      custom="shift"
      subCustom="branch-schedule"
      title="Location Schedules"
      customTitle=""
      subtitle="View Schedules For Users"
      RightComponent={<TutorialPanel items={locationScheduleTutorial} />}
    >
      <div className="mb-4 relative">
        <div className="flex items-center mt-4 gap-3 sticky top-[6rem] bg-white dark:bg-primary">
          <div className="flex flex-row  items-center gap-2 flex-wrap">
            <AvatarDropdown
              bulletClassName="w-[10px] h-[10px]"
              data={[{ title: 'All job roles', value: '' }, ...jobRoles]?.map(
                (job) => ({ label: job?.title, value: job.id, color: job.hexcode })
              )}
              className="dark:text-white dark:border-333"
              value={selectedJobRole}
              dropdownTitleClass="text-A5A dark:text-white"
              optionClass="z-[1000] shadow-md min-h-[50px] w-[max-content]"
              onChangeOption={handleJobRole}
              styleClass="dark:text-white text-A5A"
              parentClass="w-48"
            />

<MultipleSelectDropdown
            data={branches?.map((branch) => ({
              label: branch?.name,
              value: branch.id,
              hexcode: branch?.hexcode,
            }))}
            placeholder="Select locations"
            className="w-44 h-[43px] dark:border-333"
            value={selectedMultiBranch}
            dropdownTitleClass="text-A5A dark:text-white"
            labelText="locations selected"
            optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll bg-white"
            setMultiBranch={handleMultiSelect}
          />

            <CustomDropdown
              data={[
                { label: 'Everyone', value: '' },
                ...teamMembers?.map((team) => ({
                  label: `${team?.user?.firstName} ${team?.user?.lastName}`,
                  value: team?.user?.id,
                })),
              ]}
              showIcon={false}
              className="w-[200px] rounded h-[43px]"
              value={selectedUser}
              dropdownTitleClass="text-A5A dark:text-white"
              optionClass="z-[1000] h-[200px] overflow-scroll"
              onChangeOption={handleSelectUser}
            />
            <CustomDropdown
              data={[
                { label: 'All Skill Sets', value: '' },
                ...skillSets?.map((skill) => ({
                  label: skill?.name,
                  value: skill?.id,
                })),
              ]}
              showIcon={false}
              className="w-[200px] rounded h-[43px]"
              value={selectedSkill}
              dropdownTitleClass="text-A5A dark:text-white"
              optionClass="z-[1000] min-h-[75px] max-h-[200px] overflow-scroll"
              onChangeOption={handleSelectSkill}
            />
            <CustomDropdown
              data={[
                { label: 'No Gender Check', value: '' },
                { label: 'Female Booked', value: 'male' },
                { label: 'Male Booked', value: 'female' },
              ]}
              showIcon={false}
              className="w-[200px] rounded h-[43px]"
              value={selectedGender}
              dropdownTitleClass="text-A5A dark:text-white"
              optionClass="z-[1000] overflow-scroll"
              onChangeOption={handleSelectGender}
            />

            <CustomDropdown
              data={months}
              className="w-[150px] rounded h-[43px] dark:border-333"
              value={monthSelected}
              optionClass="z-[1000]  min-h-[50px] max-h-[200px] overflow-y-scroll"
              dropdownTitleClass="text-A5A dark:text-white"
              onChangeOption={handleOnMonthChange}
            />
            <CustomDropdown
              data={getDatesArray(dateCreated, new Date().toString())?.map(
                (dates) => ({ label: dates, value: dates })
              )}
              className="w-[150px] rounded h-[43px]"
              value={yearSelected}
              optionClass="z-[1000]"
              dropdownTitleClass="text-A5A dark:text-white"
              onChangeOption={handleOnDateChange}
            />

              <CustomDropdown
                    data={[
                      { templateName: 'Default Shift Template', id: '' },
                      ...(shiftTemplates?.length > 0 ? shiftTemplates : []),
                    ]?.map((shift) => ({
                      label: shift?.templateName,
                      value: shift?.id,
                      smallLabel: shift?.jobRole?.title,
                      jobId: shift?.jobRole?.id
                    }))}
                    className=" w-52 rounded h-[43px]"
                    smallLabelClassName="text-gray-400"
                    value={selectedTemplate}
                    dropdownTitleClass="text-A5A dark:text-white"
                    optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
                    onChangeOption={handleSelectedShiftTemplate}
                  />
          </div>
        </div>
        
        <div className="">
          {Array.from(
            Array(
              calendarRange?.length/7
            ).keys()
          )?.map((eachWeek) => (
            <div className="shadow-shift-shadow mt-12 rounded-sm bg-white dark:bg-A06">
              <table className="mb-8">
                <thead>
                <tr>
                  <td className={`w-[200px] border-b border-r-0 z-50 dark:bg-444 dark:border-333 bg-white`}></td>
                  {
                    [...new Set(calendarRange
                      ?.slice(eachWeek * 7, (eachWeek + 1) * 7)?.map((x) => moment(x).format('MMM')))]?.map((y, index) => (<td className="dark:bg-444" colSpan={calendarRange
                        ?.slice(eachWeek * 7, (eachWeek + 1) * 7)?.map((dat) => moment(dat).format('MMM')).filter((z) => z === y).length} ><p className="text-black dark:text-white">{y}</p></td>))
                  }
                  </tr>
                <tr className=' dark:border-333 dark:bg-444'>
                  <td className="w-[200px] border-r border-b bg-[#fefefe] dark:bg-444 dark:border-333">
                    <p className="px-3 font-bold text-[#4B5563] bg-[#fefefe] dark:bg-444 dark:text-white">Locations</p>
                  </td>
                  {calendarRange
                    ?.slice(eachWeek * 7, (eachWeek + 1) * 7)
                    ?.map((value, index) => {

                      const dayOfWeek = moment(
                        `${yearSelected.value}-${monthSelected.value}-${value}`,
                        'yyyy-MM-DD'
                      ).toDate().getDay()

                      if (index < 8)
                        return (
                          <td className={`cursor-pointer border-r w-[150px] dark:bg-444 dark:border-333 p-4 ${[0, 6].includes(dayOfWeek) ? 'bg-calendar-weekend dark:bg-444' : ''} ${(moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? " bg-grey-out dark:bg-444" : ""}`}>
                            <DateLabel
                              paddingY={0}
                              padding={'10px'}
                              detailsWeek={moment(value).toDate()}
                              type="week"
                              className={(moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? " bg-grey-out dark:bg-444" : ''}
                              layoutProps={{
                                headerClass: `dark:text-white ${(moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? "text-grey-out" : ''}`,
                                subHeaderClass: "dark:text-969"
                              }}
                            />
                          </td>
                        );
                      return null;
                    })}
                </tr>
                </thead>
                {(loading || branchLoading) && (
                  <tr>
                    <td colSpan={8}>
                      <div className="w-full h-[300px] flex justify-center items-center">
                        <Loader />
                      </div>
                    </td>
                  </tr>
                )}
                {(!loading || !branchLoading) &&
                  handleSchedule().filter((x) => x?.mode === 'header')
                    ?.length === 0 && (
                    <tr>
                      <td colSpan={8}>
                        <div className="w-full h-[300px] flex justify-center items-center dark:text-white">
                          <p>No Members in this branch</p>
                        </div>
                      </td>
                    </tr>
                  )}
                {(!loading || !branchLoading) &&
                  handleSchedule()?.map((schedule) => (
                    <>
                      {schedule?.mode === 'header' && (
                        <tr>
                          <td className="border-b border-l bg-[#fefefe] px-2 py-2 flex gap-3 items-center dark:bg-828 dark:border-333">
                            <p className="font-semibold bg-[#fefefe] text-[14px] dark:bg-828 dark:text-white">
                              {schedule?.branchName}
                            </p>

                            <p className='h-2 w-2 rounded-full dark:text-white' style={{backgroundColor: schedule?.hexcode}}/>
                          </td>
                          {calendarRange
                            ?.slice(eachWeek * 7, (eachWeek + 1) * 7)
                            ?.map((value, index) => {
                              return (
                                <td
                                  onClick={() =>
                                    handleSelectedSchedule(
                                      handleAllSchedule(
                                        schedule?.branchId,
                                        moment(
                                          `${yearSelected.value}-${monthSelected.value}-${value}`,
                                          'yyyy-MM-DD'
                                        )
                                      ),
                                      schedule,
                                      moment(
                                        `${yearSelected.value}-${monthSelected.value}-${value}`,
                                        'yyyy-MM-DD'
                                      )
                                    )
                                  }
                                  className={`cursor-pointer border px-1 w-[150px] dark:border-333 dark:bg-828
                                 
                                    ${handleAllSchedule(
                                    schedule?.branchId,
                                    moment(value)
                                  )?.map((x) => x.name)?.length === 1
                                      ? getBranchHexCode(
                                        handleAllSchedule(
                                          schedule?.branchId,
                                          moment(value)
                                        )
                                      )
                                      : ''
                                    }
                                    ${
                                      handleFilterSelection(
                                        schedule?.branchId,
                                        moment(value)
                                      ) ? "bg-green-200" : ""
                                    }
                                    ${[0, 6].includes(moment(value)?.day())
                                      ? (moment(value).month()+1) !== Number(monthSelected.value) ? " bg-grey-out" : 'bg-calendar-weekend dark:bg-A06'
                                      : (moment(value).month()+1) !== Number(monthSelected.value) ? " bg-grey-out" : 'bg-white'}

                                    `}
                                >
                                  <div className="flex justify-center">
                                    <p className='text-[13px] text-center font-bold whitespace-nowrap w-[100px] overflow-hidden text-ellipsis dark:text-white'>
                               
                                    {handleAllSchedule(
                                      schedule?.branchId,
                                      moment(value)
                                    )
                                      ?.map((x) => x.name)
                                      ?.join(' / ')}
                                  </p>
                                  </div>
                                  {handleAllSchedule(
                                    schedule?.branchId,
                                    moment(value)
                                  )?.length >= 1 && (
                                      <div className="flex justify-end items-center gap-1">
                                        {
                                          handleAllSchedule(
                                            schedule?.branchId,
                                            moment(value)
                                          )?.map((x) => (
                                            <div style={{ backgroundColor: x?.jobRole?.hexcode !== "NULL" ? x?.jobRole?.hexcode : "white" }} className={`w-2 h-2 rounded-full bg-[${x?.jobRole?.hexcode !== "NULL" ? x?.jobRole?.hexcode : "white"}]`} />
                                          ))
                                        }
                                      </div>
                                    )}
                                </td>
                              );
                            })}
                        </tr>
                      )}
                    </>
                  ))}
              </table>
            </div>
          ))}

          {open && (<EditSchedule
            open={open}
            schedules={selectedSchedule}
            selectedTemplate={selectedTemplate}
            handleSubmitUser={handleSubmitUser}
            handleClose={() => handleToggleModal(false)}
          />)}
        </div>

      </div>

      <div className='flex justify-end mb-12'>
            <TablePagination
              component="div"
              count={branches?.length || 0}
              page={page}
              className='dark:text-grey-60 dark:text-white z-0'
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5,10]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

    </AppLayout>
    
  );
};
