import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const changeTaskStatus = async ({ data, taskId, ...rest }) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/tasks/${taskId}/transition`,data, storage.getConfig());
    return {...response.data, ...rest, taskId};
    
  } catch (error) {
    throw Error(error)
  }
}

export const updatePriorityStatus = async ({data, taskId, ...rest}) => {
  try {
    const response = await axios.patch(`${conn_url}/api/v1/tasks/${taskId}`,data, storage.getConfig());
    return {...response.data, taskId, ...rest};
    
  } catch (error) {
    throw Error(error)
  }
}

export const getTaskStatuses = async (companyId) => {
   try {
            const response = await axios.get(`${conn_url}/api/v1/tasks/getTaskstatuses/${companyId}`, storage.getConfig());
            return response.data;
        } catch (error) {
            throw Error(error);
        }
}
