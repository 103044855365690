import React,{useState,} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Box, Stack, CircularProgress} from '@mui/material';
import { TimeOffService } from '../../../../../../../Network/ServiceClass/TimeOffService';
import {fetchTimeOffThunk, setCalendarData, setListData} from '../../../../../../../Store/timeOff'
import {Text, TextAndField, OutlinedButton, Field, FieldSelect, FillButton} from '../../../../../../Widgets/FormUtils/FormUtils'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import PopError from '../../../../../../Widgets/PopError';
import CustomModal from '../../../../../../Widgets/CustomModal';
import moment from 'moment';
import SelectMembers from './SelectMembers';
import { toast } from 'react-toastify';

import { Storage } from '../../../../../../../Network/StorageClass/StorageClass';


const timeOffService = new TimeOffService()
export default function RequestTimeOff({openModal, selectedDate ='', setOpenModal, teamMember}) {

   const storage = new Storage();
  const isAdmin = storage.getActiveCompany().memberships[0].privilege

  const dispatch = useDispatch()
  const shiftStateWeeklyDateLabel = useSelector((state) => state.shift.weeklyDateLabel);

  
  const handleClose = () => {
    setOpenModal(false)
    formik.resetForm()
  }

  const timeOffOPtions = [
    {name: 'Sick leave', id: "sick"},
    {name: 'Unpaid time off', id: 'unpaid_time_off'},
    {name: 'Paid time off ', id: 'paid_time_off'},
    {name: 'Emergency', id: 'emergency'}
  ]

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedMember, setSelectedMember] = useState(teamMember)

  const compareDate = (start,end) => {
    const date1 = new Date(start).getTime()
    const date2 = new Date(end).getTime()

    return date1 < date2
  }

  const formik = useFormik({
    initialValues: {
      start: selectedDate || '',
      end: selectedDate || '',
      type: 'none'
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      start: Yup.string().required('Start date is required'),
      end: Yup.string().required('Start date is required'),
      type: Yup.string().required('Time off type is required')

    }),
    onSubmit: (value)=>{
      handleSubmit(value)
    }
  })

  const handleSubmit = (value) => {
    setIsLoading(true)
    let data
    
    if(!!!selectedMember) {
        data ={
          reason: value.type,
          start: value.start,
          end: value.end,
          type: value.type
        }
    }else {
      data ={
          reason: value.type,
          start: value.start,
          end: value.end,
          type: value.type,
          forUser: selectedMember?.user?.id
        }
    }
    

    // if (compareDate(value.start, value.end)){

      if(!!!selectedMember){
        timeOffService.requestTimeOff(data).then(value => {
            toast.success("Your time off request has been sent")
            formik.resetForm()
            handleClose()
            setIsLoading(false)
  
            // reload calendar
            dispatch(fetchTimeOffThunk());
            timeOffService.fetchTimeOff({from:shiftStateWeeklyDateLabel.from, to:shiftStateWeeklyDateLabel.to})
            .then((value)=>{
                if(value.status !== 200) throw new Error('') 
                dispatch(setCalendarData(value.data))
                dispatch(setListData(value.data))
            }).catch((error)=>{
                console.log(error)
            })
  
            
  
        }).catch(error => {
            setIsLoading(false)
            setIsError(true)
            setErrorMessage(Object.values(error?.response.data.details)[0])
        })

      }else {
        timeOffService.requestTimeOffForAnotherUser(data).then(value => {
            toast.success("Time off request has been created")
            formik.resetForm()
            handleClose()
  
            setIsLoading(false)

            dispatch(fetchTimeOffThunk());
  
            // reload calendar
            timeOffService.fetchTimeOff({from:shiftStateWeeklyDateLabel.from, to:shiftStateWeeklyDateLabel.to})
            .then((value)=>{
                if(value.status !== 200) throw new Error('') 
                dispatch(setCalendarData(value.data))
                dispatch(setListData(value.data))
            }).catch((error)=>{
            })
  
        }).catch(error => {
            setIsLoading(false)
            setIsError(true)
            setErrorMessage(Object.values(error?.response.data.details)[0])
        })
      }

    // }else{
    //     setIsLoading(false)
    //     setIsError(true)
    //     setErrorMessage("Start date must be less than end date!")
    // }


  }

 
  return (
    <div>

      <CustomModal 
        modalClassName='!w-[30%]'
        open={openModal} 
        handleClose={handleClose}>
         {/* second row */}
         <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '20px', justifyContent: 'center', alignItems: 'flex-start'}}>
                  <p className='text-[18px] text-[] dark:text-white mr-[1rem]' >Time off request</p>
              </Box>

              <div className='flex flex-col mt-6 gap-6 dark:text-white'>

                <div className='flex w-[100%] gap-6 '>
                  <TextAndField width='45%' space={0.5}>
                    <Text text={'Start date'}/>
                    <Field type={'date'} formik={formik}
                      name='start' value={formik.values.start}
                      width='100%' containerWidth='100%'/>
                  </TextAndField>

                  <TextAndField width='45%' space={0.5}>
                    <Text text={'End date'}/>
                    <Field type={'date'} formik={formik}
                      name='end' value={formik.values.end}
                      width='100%' containerWidth='100%'/>
                  </TextAndField>

                </div>

                <TextAndField space={0.5}>
                  <Text text={'Time off type'}/>
                  <FieldSelect formik={formik}
                    name='type' value={formik.values.type}
                    list={timeOffOPtions} placeholder='Choose vacation type'
                    width='100%' otherProps={{fontSize: '12px'}}/>
                </TextAndField>
                {[1,2].includes(isAdmin) && <SelectMembers team={teamMember} onChange={(val)=> {
                  setSelectedMember(val)}}/>}
              </div>


              {isError && <PopError message={errorMessage} handleClick={()=> setIsError(false)}/>              }

              {/* fourth row */}
              <Stack direction='row' justifyContent='space-between' mt={24}>
                <OutlinedButton text={'Cancel'} callBack={handleClose}/>

                {!isLoading &&<FillButton text={'Apply'}  callBack={formik.handleSubmit} disable={formik.values.type === 'none' ? true : false}/>}
                {isLoading && <CircularProgress /> }
              </Stack>
      </CustomModal>
     
    </div>
  );
}

