
import React, { useState, useEffect } from 'react';
import {
  Stack,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { font } from '../../../../../Widgets/Styles/font';
import { IoClose } from 'react-icons/io5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import {Text, TextAndField, Field, FieldSelect, OutlinedButton, FillButton} from '../../../../../Widgets/FormUtils/FormUtils'
import { Job } from '../../../../../../Network/ServiceClass/Job';
import { Branch } from '../../../../../../Network/ServiceClass/Branch';
import { TaskClass } from '../../../../../../Network/ServiceClass/Task';
import { useDispatch, useSelector } from 'react-redux';
import PopError from '../../../../../Widgets/PopError';
import { toast } from 'react-toastify';
import { statuses } from '../../../../mock';
import PriorityDropdown from '../../../../../Widgets/PriorityDropdown';
import AvatarDropdown from '../../../../../Widgets/AvatarDropdown';
import { getTaskStatusAsync } from '../../../../../../Store/Task';



const jobService = new Job()
const BranchService = new Branch()
const taskService = new TaskClass()

export default function CreateTask({ openDrawer, handleCloseDrawer, currentUser, branchId}) {
  const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            statusId: "",
            priority: {
              value: "lowest"
            },
            boardId: "",
            position: 1,
            dueDate: "",
            assignee: currentUser.id,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required").min(2, "Title is too short"),
            description: Yup.string().required("Description is required").min(2, "Description is too short"),
            position: Yup.number().required("Position is required").min(1, "Input position"),
            dueDate: Yup.string().required("Due date is required"),
            statusId: Yup.object().shape({
              value: Yup.string().required("StatusId is required"),
              id: Yup.string()
            }),
            boardId: Yup.string().required("Board is required"),
            assignee: Yup.string().required("Assignee is required"),

        }),
        onSubmit: (value)=>{

          const data = value
          data.priority = value.priority.value
          data.statusId = value.statusId.id
          data.assignee = {
            type,
            branchId,
            ids: [
              data.assignee
            ]
          }

          setIsLoading(true)
          taskService.createATask(data).then((val)=>{
            setIsLoading(false)
            toast('You have assign a task successfully')
            formik.handleReset()
            handleCloseDrawer(false)
          }).catch((err)=>{
              setIsLoading(false)
              setIsError(true)
              setErrorMessage(err.response.data.message)
          });
      
        }
    })
          
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    handleCloseDrawer(open);
  };

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [jobs, setJobs] = useState([])
  const [branches, setBranches] = useState([])
  const [individuals, setIndividuals] = useState([])
  const [assigneeData, setAssigneeData] = useState([])
  const [type, setType] = useState('user')
  const [displayLabel, setLabel] = useState('Assignee')
  const [board, setBoard] = useState([])
  const company = useSelector(state => state.company)?.activeCompany?.id
  const taskStatuses = useSelector(state => state.task)?.newTaskStatus

  useEffect(()=>{
    if(company){
      dispatch(getTaskStatusAsync(company))
    }
    jobService.fetchJobRoles().then(val => {
      setJobs(val.data.map(value => {
        return {id: value.id, name: value.title}
      }))
    }).catch()

    BranchService.fetchTeamMember({}).then(val => {
      setIndividuals(val.data.map(value => {
        return {id: value.user.id, name: `${value.user.firstName} ${value.user.lastName} `}
      }))
      setAssigneeData(val.data.map(value => {
        return {id: value.user.id, name: `${value.user.firstName} ${value.user.lastName} `}
      }))
    }).catch()

    BranchService.getBranches().then(val => {
      setBranches(val.data)
    }).catch()

    taskService.getBoards().then(val => {
      setBoard(val.data)
    }).catch()
  },[])

  const handleAssigneeChange = (value) => {
    setType(value)
    switch (value) {
      case 'user':
        setAssigneeData(individuals)
        setLabel("Assignee")
        break;

      case 'branch':
        setAssigneeData(branches)
        setLabel("Location")
        break;

      case 'jobRole':
        setAssigneeData(jobs)
        setLabel("Assignee Job Role")
        break;    
      default:
        break;
        
    }
  }
  
  const componentVariant ={
    hidden:{x: '50vw'},
    vissible: {
      x: 0,
      transition:{ease: 'easeIn', delay:0, duration: 1}
    },
    exit: {
      x: '50vw',
      transition:{ease: 'easeIn', delay:0, duration: 1}
    }

  }

  return (
    <div>
      <React.Fragment key="right">
          <Drawer
            component={motion.div}
            variants={componentVariant}
            initial={'hidden'}
            animate={'vissible'}
            exit='exit'
            anchor="right"
            open={openDrawer}
            onClose={toggleDrawer(false)}
            hideBackdrop={true}
          >
            <Stack className='py-3 px-10 relative dark:bg-A06 dark:text-white' width={400} direction="column" mt={2} gap={3} sx={{ ...font }}>
              {/* first row */}
              <div
              className="flex items-center justify-center pl-5"
              >
                <Typography variant='p' className="font-semibold text-lg text-center">
                  Create Task
                </Typography>

                <div className='absolute right-0 top-2'>
                <Button onClick={toggleDrawer(false)}>
                  <IoClose color='black' size={20} />
                </Button>
                </div>
              </div>

              {/* second row */}
              <TextAndField space={0.5}>
                  <Text text={'Title'}/>
                  <Field text={'What is the title of the task'} 
                      value={formik.values.title} 
                      formik={formik} 
                      name='title'/>
              </TextAndField>

              <TextAndField space={0.5}>
                  <Text text={'Description'}/>
                  <Field text={'Describe task for easy excution'} 
                      value={formik.values.description} 
                      formik={formik} 
                      name='description' multiline={true} maxRows={4}/>
              </TextAndField>

              <TextAndField space={0.5}>
                  <Text text={'Who is this task for'}/>
                  <FieldSelect list={[
                    {name:'Individual', id: 'user'},
                    {name:'Location members', id: 'branch'},
                    {name:'Job role', id: 'jobRole'},
                  ]} 
                      value={type} 
                      formik={formik} 
                      name='type'
                      otherProps={{ fontSize: '12px'}}
                      callback={(e)=>{ handleAssigneeChange(e.target.value); }}
                      />
              </TextAndField>
             

              <TextAndField space={0.5}>
                  <Text text={displayLabel}/>
                  <FieldSelect list={assigneeData} 
                      value={formik.values.assignee} 
                      formik={formik} 
                      otherProps={{ fontSize: '12px'}}
                      name='assignee'/>
              </TextAndField>

              <div className='flex flex-row gap-2 w-[100%]'>
                  <TextAndField space={0.5}>
                      <Text text={'Position'}/>
                      <Field text={'position'} 
                          value={formik.values.position} 
                          formik={formik} 
                          name='position' type={'number'} width={'130px'}/>
                  </TextAndField>

                  <TextAndField space={0.5}>
                    <Text text={'Due date'}/>
                    <Field text={'Choose date'} 
                        value={formik.values.dueDate} 
                        formik={formik} 
                      name='dueDate' type={'date'} width={'130px'}/>
                  </TextAndField>
              </div>

              <TextAndField space={0.5}>
                  <Text text={'Status'}/>
                  <AvatarDropdown 
                    data={taskStatuses?.map((x) => ({label: x.name, value: x.category, id:x.id, color: statuses.filter((y) => y.value.toLowerCase() === x?.name?.toLowerCase())[0]?.color}))}
                    styleClass="text-xs"
                    value={formik.values.statusId}
                    onChangeOption={(val) =>{
                      formik.setFieldValue("statusId", val)
                    } }
                  />
              </TextAndField>

              <TextAndField space={0.5}>
                  <Text text={'Priority'}/>
                  <PriorityDropdown
                    styleClass="text-xs"
                    value={formik.values.priority}
                    onChangeOption={(val) =>{
                      formik.setFieldValue("priority", val)
                    } }
                  />
              </TextAndField>


              <TextAndField space={0.5}>
                  <Text text={'Board'}/>
                  <FieldSelect list={board} 
                      value={formik.values.boardId} 
                      formik={formik} 
                      name='boardId'/>
              </TextAndField>

              {isError && <PopError message={errorMessage} handleClick={()=>{setIsError(false)}}/>}

              <div className='w-[100%] flex flex-row justify-between'>

                <OutlinedButton text='Cancel' width={'83px'} height={'40px'} callBack={()=> handleCloseDrawer(false)}/>
                {!isLoading && <FillButton text='create' width={'83px'} height={'40px'} callBack={formik.handleSubmit}/>}
                {isLoading && <CircularProgress/>}
              </div>
            </Stack>
          </Drawer>
      </React.Fragment>
    </div>
  );
}
