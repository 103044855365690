const day = new Date().getDate()

const checkTimeTable = [
    `06 PM ${day}`,
    `07 PM ${day}`,
    `08 PM ${day}`,
    `09 PM ${day}`,
    `10 PM ${day}`,
    `11 PM ${day}`,
    `00 AM ${day}`
]

export const checkScheduleTimeTable = [
    `06 PM ${day}`,
    `07 PM ${day}`,
    `08 PM ${day}`,
    `09 PM ${day}`,
    `10 PM ${day}`,
    `11 PM ${day}`,
    `00 AM ${day}`
]


export const wholeDayTable = [
    `00 AM`,
    `01 AM`,
    `02 AM`,
    `03 AM`,
    `04 AM`,
    `05 AM`,
    `06 AM`,
    `07 AM`,
    `08 AM`,
    `09 AM`,
    `10 AM`,
    `11 AM`,
    `12 PM`,
    `01 PM`,
    `02 PM`,
    `03 PM`,
    `04 PM`,
    `05 PM`,
    `06 PM`,
    `07 PM`,
    `08 PM`,
    `09 PM`,
    `10 PM`,
    `11 PM`,
]

export default checkTimeTable;