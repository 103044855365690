import React from 'react';
import { Stack } from '@mui/material';
import FormModal from '../../../../Widgets/Animation/FormModal';
import {
  Text,
  TextAndField,
  Field,
} from '../../../../Widgets/FormUtils/FormUtils';
import { useFormik } from 'formik';
import CustomButton from '../../../../Widgets/CustomButton';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {font} from '../../../../Widgets/Styles/font'

const EditTeamInfo = ({
  handleClose,
  open,
  handleSelectOption,
  data,
  branches,
  skillSets,
  storeAccounts,
  countries,
  jobRoles
}) => {


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: data?.username || '',
      firstname: data?.firstname || '',
      lastname: data?.lastname || '',
      phone: data?.phone || '',
      country: data?.country || '',
      email: data?.email || '',
      location: data?.location || '',
      jobrole: data?.jobrole || '',
      skillset: data?.skillset || '',
      privilege: data?.privilege || '',
     
    },
    onSubmit: (values) => {
      handleSelectOption(data?.email, [values], values?.name)
      handleClose();
    }
  });
  const handleChange = (event) => {
    const { target: { value },} = event;
    formik.setFieldValue('skillset', value)
  };

  const privilegeArray =  [
    { id: 1, name: 'Super Admin' },
    { id: 2, name: 'Admin' },
    { id: 3, name: 'Member' },
  ]

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ height: '100vh' }}
        justifyContent="center"
      >
        <div className="px-[67px] py-[15px] pb-[56px] bg-white w-[529px] overflow-y-auto">
          <p className="font-semibold text-base text-center pb-16">Edit team member</p>

          <div className='flex flex-col gap-4'>

          <TextAndField space={0.5}>
              <Text text={'User Name'} />
              <Field
                text={'Kindly user name'}
                value={formik.values.username}
                sx={{height: "25px"}}
                formik={formik}
                name="username"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text={'First Name'} />
              <Field
                text={'Kindly first name'}
                value={formik.values.firstname}
                sx={{height: "25px"}}
                formik={formik}
                name="firstname"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text={'Last Name'} />
              <Field
                text={'Kindly last name'}
                value={formik.values.lastname}
                sx={{height: "25px"}}
                formik={formik}
                name="lastname"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text={'Phone'} />
              <Field
                text={'Kindly phone'}
                value={formik.values.phone}
                sx={{height: "25px"}}
                formik={formik}
                name="phone"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text={'Email'} />
              <Field
                text={'Kindly enter email'}
                value={formik.values.email}
                sx={{height: "25px"}}
                className="border-6E6"
                formik={formik}
                name="email"
              />
            </TextAndField>


            <TextAndField space={0.5}>
              <Text text={'Country'} />
              <div>
                <Select
                className='w-[100%]'
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={formik.values.country}
                onChange={(event)=> {
                  const { target: { value },} = event;
                  formik.setFieldValue('country', value)
                }}
                input={<OutlinedInput size='small'/>}
                sx={{...font, fontSize: '12px'}}
              >
                {countries?.map((val) => (
                  <MenuItem
                    sx={{...font, fontSize: '12px'}}
                    key={val.id}
                    value={val.id}
                  >
                    {val.name}
                  </MenuItem>
                ))}
                </Select>
                
              </div>
             
            </TextAndField>


            <TextAndField space={0.5}>
              <Text text={'Location'} />
              
              <div>
                <Select
                className='w-[100%]'
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={formik.values.location}
                onChange={(event)=> {
                  const { target: { value },} = event;
                  formik.setFieldValue('location', value)
                }}
                input={<OutlinedInput size='small'/>}
                sx={{...font, fontSize: '12px'}}
              >
                {branches?.data?.map((val) => (
                  <MenuItem
                    sx={{...font, fontSize: '12px'}}
                    key={val.id}
                    value={val.id}
                    // style={getStyles(name, personName, theme)}
                  >
                    {val.name}
                  </MenuItem>
                ))}
                </Select>
                
              </div>
            </TextAndField>


            <TextAndField space={0.5}>
              <Text text={'Job Role'} />
              
              <div>
                <Select
                className='w-[100%]'
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={formik.values.jobrole}
                onChange={(event)=> {
                  const { target: { value },} = event;
                  formik.setFieldValue('jobrole', value)
                }}
                input={<OutlinedInput size='small'/>}
                sx={{...font, fontSize: '12px'}}
              >
                {jobRoles?.map((val) => (
                  <MenuItem
                    sx={{...font, fontSize: '12px'}}
                    key={val.id}
                    value={val.id}
                  >
                    {val.title}
                  </MenuItem>
                ))}
                </Select>
                
              </div>
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text={'Skill Set'} />
              
              <div>
                <Select
                className='w-[100%]'
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={formik.values.skillset}
                onChange={handleChange}
                input={<OutlinedInput size='small'/>}
                sx={{...font, fontSize: '12px'}}
              >
                {skillSets?.data?.map((val) => (
                  <MenuItem
                    sx={{...font, fontSize: '12px'}}
                    key={val.id}
                    value={val.id}
                    // style={getStyles(name, personName, theme)}
                  >
                    {val.name}
                  </MenuItem>
                ))}
                </Select>
                
              </div>
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text={'Privilege'} />
              
              <div>
                <Select
                className='w-[100%]'
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={formik.values.privilege}
                onChange={(event)=> {
                  const { target: { value },} = event;
                  formik.setFieldValue('privilege', value)
                }}
                input={<OutlinedInput size='small'/>}
                sx={{...font, fontSize: '12px'}}
              >
                {privilegeArray?.map((val) => (
                  <MenuItem
                    sx={{...font, fontSize: '12px'}}
                    key={val.id}
                    value={val.id}
                    // style={getStyles(name, personName, theme)}
                  >
                    {val.name}
                  </MenuItem>
                ))}
                </Select>
                
              </div>
            </TextAndField>

          </div>



         
          <div className="flex items-center w-full mt-[59px]">
            <CustomButton
              containerClass="w-full"
              onClick={handleClose}
              className="w-full text-center rounded flex justify-center mr-2"
              showIcon={false}
              title="Cancel"
            />
            <CustomButton
              onClick={() => {
                formik.handleSubmit();
              }}
              containerClass="w-full"
              className="w-full text-center ml-2 rounded bg-base-color text-white flex justify-center"
              showIcon={false}
              title="Save"
            />
          </div>
        </div>
      </Stack>
    </FormModal>
  );
};

export default EditTeamInfo;
