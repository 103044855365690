import React, { useState } from 'react';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import { SideBar } from '../../Widgets/SideBar';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import {ReactComponent as FileDownload} from '../../../Assets/file_download.svg'
import {ReactComponent as FileDisplay} from '../../../Assets/file_display.svg'
import {ReactComponent as FileClose} from '../../../Assets/x-close.svg'
import CustomButton from '../../Widgets/CustomButton';
import Papa from "papaparse"
import { useDispatch } from 'react-redux';
import { setInvitedTeams } from '../../../Store/Branches';
import LiveUsers from '../LiveUsers/LiveUsers';
import AppLayout from '../../Widgets/AppLayout';

const BulkInviteScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkError, setCheckError] = useState(false)
  const [fileUploads, setFileUpload] = useState([])

  const handleBranchBack = () => {
    navigate('/branches');
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const isError = results.data?.every((res) => (res?.email !== '') && (res?.name !== ''))

        if(isError){
          setFileUpload(event.target.files);
          dispatch(setInvitedTeams(results.data));
          if(checkError) setCheckError(false)
        } else {
          setCheckError(true)
        }
      },
    });
  };

  const handleCloseIcon = () => {
    setFileUpload([])
  }

  const handleSendingNav = () => {
    navigate("/branches/bulk-invite/send")
  }

  return (
    <>
      <section>
        <AppLayout
        title="Bulk Invite"
        subtitle="Invite multiple team members at once"
        customTitle="Company"
        
        >
          <div
            onClick={handleBranchBack}
            className="cursor inline-flex items-center rounded border-1 border-black p-1 dark:border-333"
          >
            <BsArrowLeft className='dark:text-white' /> <span className="ml-2 dark:text-white">Back</span>
          </div>

          <div className="mt-[70px] flex justify-center flex-col items-center">
            <div>
              <div className="flex items-center">
                <img src={require('../../../Assets/info.png')} alt="info" />
                <p className="font-medium text-sm ml-3 dark:text-white">
                  Make sure to name headers of CSV with :
                </p>
              </div>
              <div className="text-left pl-14">
                <ul className="list-disc">
                  <li className="font-medium text-sm dark:text-white">Name</li>
                  <li className="font-medium text-sm dark:text-white">Email</li>
                </ul>
              </div>
              <div className="mt-[47px]">
                <p className="font-semibold text-base mb-[15px] dark:text-white">Upload</p>
                <label htmlFor="upload-photo">
                <div className="w-[593px] flex flex-col items-center justify-center h-[257px] rounded border-1 border-black border-dashed dark:border-333">
                  <input  onChange={changeHandler} accept='.csv ' id="upload-photo" type="file"  style={{ display: 'none' }} />
                  <FileDownload />
                  <p className="mt-[17px] text-color-424 dark:text-white">Drop your CSV file(s) here or <span className="text-5F3 underline cursor-pointer">Browse</span></p>
                  <p className="text-A5A mt-[17px] dark:text-white">Max. file size 5MB</p>
                </div>
                </label>
              </div>
              {checkError && (<p className="text-[8px] text-red-700 mt-3 dark:text-white">Your file is missing some information, Please note the file should contains the name and email of invited member</p>)}
              <div className="mt-[17px]">
                {fileUploads?.length > 0 && (<div className="p-[10px] border-1 border-9FD flex justify-between items-center rounded">
                  <div className="flex items-center">
                  <FileDisplay className='dark:text-white' />
                  <p className="font-medium text-sm ml-2 dark:text-white">Team members</p>
                  </div>
                  <FileClose onClick={handleCloseIcon} className="cursor-pointer" />
                </div>)}
              </div>
              <div className="flex items-center mt-[53px] justify-between">
                <CustomButton onClick={handleBranchBack} className="w-[280px] justify-center border-0" showIcon={false} title="Cancel" />
                <CustomButton onClick={handleSendingNav} className="w-[280px] justify-center bg-base-color text-white border-0" showIcon={false} title="Open file" />
              </div>
            </div>
          </div>
        </AppLayout>
      </section>
    </>
  );
};

export default BulkInviteScreen;
