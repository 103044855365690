import { useNavigate } from "react-router"
import { BsArrowLeft } from "react-icons/bs"
import { useState } from "react"
import GlobalButton from "../../../Widgets/GlobalButton"
import AppLayout from "../../../Widgets/AppLayout"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getInventoryGroupsThunk, getInventoryGroupItemThunk } from "../../../../Store/Inventory"
import { CircularProgress } from "@mui/material"
import { TableWrapper, TableHeader, Td, Tr } from "../../../Widgets/Table/TableWrapper"
import TutorialPanel from '../../../Widgets/Tutorial/TutorialPanel'
import { transferItemListTutorial } from '../../mock';

export const TransferItemList = () => {
  const inventoryGroups = useSelector((state) => state.inventory)?.groups || []

  const [inventoryItems, setInventoryItems] = useState([])
  const [selectedGrp, selectGrp] = useState(inventoryGroups?.length > 0 ? inventoryGroups[0].id : null)
  const [selectedItems, selectItem] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInventoryGroupsThunk())
    .then(val => {
      if(val.payload?.status === 200){
        dispatch(getInventoryGroupItemThunk({groupId: selectedGrp ? selectedGrp : val.payload?.data[0]?.id }))
        .then(val => {
          setIsLoading(false)
          selectGrp(val.payload?.data[0]?.id)
          if (val.payload?.status === 200) setInventoryItems(val.payload?.data[0]?.inventoryItems)
        })

      }
    })
    // dispatch(getAllItemsThunk())
  }, [])


  const handleSelectItem = (val) => {
    const filteredItem = selectedItems.filter((x) => x.id === val.id)

    if(filteredItem?.length > 0){
      const removeItem = selectedItems.filter((x) => x.id !== val.id)

      selectItem(removeItem)
    } else {
      const addItem = selectedItems
      addItem.push(val)
      selectItem(addItem)
    }
  }

  const handleSubmitItem = () => {
    const storedData = JSON.parse(localStorage.getItem("transferItem"))
    const data = {
      ...storedData,
      transferData: selectedItems
    };
    localStorage.setItem("transferItem", JSON.stringify(data))
  }


  const handleGroupSelected = (grp) => {
    setIsLoading(true)
    selectGrp(grp)
    dispatch(getInventoryGroupItemThunk({groupId:grp}))
        .then(items => {
          setIsLoading(false)
          if (items?.payload?.status === 200) setInventoryItems(items?.payload?.data[0]?.inventoryItems)
    })
  }

  return (
    <section>
      <AppLayout
        custom="inventory"
        subCustom="transfer-items"
        title="Transfer Items"
        customTitle="Inventory"
        subtitle=""
      >

        <div className="flex items-center gap-4 mb-10 dark:text-white">
          <BsArrowLeft className="cursor-pointer" size={25} onClick={() => navigate(-1)} />
          <p className="text-base font-bold">Items</p>
        </div>

        <div className="flex justify-between">
          <div className="flex items-center gap-3 ">
            {
              inventoryGroups?.map((grp) => (
                <div onClick={() => handleGroupSelected(grp.id)} className={`p-[10px] cursor-pointer rounded-lg border border-5F5 dark:border-333 ${selectedGrp === grp.id ? "bg-btn-color" : ""}`}>
                  <p className={`font-bold ${selectedGrp === grp.id ? "text-white" : "text-grey-600"}`}>
                    {grp?.name}
                  </p>
                </div>
              ))
            }
          </div>

          <GlobalButton onClick={() => {
            handleSubmitItem()
            navigate("/transfer-items")
            }} showIcon={false} title="Done" />

        </div>

        <TableWrapper className="mt-6 mb-12 !max-h-[80vh] ">
          <table data-tour="step7"  cellPadding={0} cellSpacing={0}>
            <thead>
              <TableHeader className="text-[16px]">
                <Td className=" py-4 w-[30px]"></Td>
                <Td className="py-4 w-[300px]">Reference number #</Td>
                <Td className="w-[300px]">Name</Td>
                <Td className="w-[200px]">Manufacturer</Td>
                <Td className="w-[200px]">Group</Td>
                <Td  className="w-[300px] !border-none">Description</Td>
              </TableHeader>
            </thead>
            {!isLoading ?<tbody>
              {
                inventoryItems.length !== 0 ? inventoryItems?.map((item) => (
                  <Tr className="hover:bg-5F5 hover:dark:bg-292 font-bold">
                    <Td className="py-4"><input defaultChecked={selectedItems?.some((x) => x.id === item.id)} onChange={()=>{handleSelectItem(item)}} type='checkbox' className="h-[20px] w-[20px]" /></Td>
                    <Td className="">{item?.referenceNumber}</Td>
                    <Td>{item?.name}</Td>
                    <Td>{item?.manAndSupp.length === 0 ? '' : item?.manAndSupp[0]?.manufacturer?.name}</Td>
                    <Td>{inventoryGroups.filter(x => x.id === selectedGrp)[0]?.name}</Td>
                    <Td className={'!border-none'}>{item.description}</Td>  
                   
                  </Tr>
                ))
              :
              <tr>
                <td colSpan={6} className='text-A5A text-center pt-10'> No items here </td>
              </tr>}
            </tbody>:

            <tr className="border-b-0">
              <td colSpan={6} className='text-A5A text-center pt-10'> <CircularProgress /></td>
            </tr>}
          </table>
        </TableWrapper>
       
      </AppLayout>

    </section>)
}