import { Storage } from "../StorageClass/StorageClass";
import axios from "axios";
import { conn_url } from "../conn_consts";

const config = {
    headers:{
        "content-type":"application/json"
    }
};

const storage = new Storage();

export class Branch{
    
    async getBranches(){
        const response = await axios.get(conn_url+"/api/v1/company/branches", storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async reassignBranch(data){
        try {
            const response = await axios.patch(`${conn_url}/api/v1/company/members/reassign-location`, data, storage.getConfig());
            return {data: response.data, status:  response.status};
        } catch(err) {
            return {data: err.response.data, status:  err.response.status};
        }
    }

    async createNewBranch(data){
        const response = await axios.post(conn_url+"/api/v1/company/branches", data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async inviteTeamMember(data){
        const response = await axios.post(conn_url+'/api/v1/company/invite-member', data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async bulkInviteTeamMember(data){
        try {
            const response = await axios.post(conn_url+'/api/v1/company/bulk-invite-member', data, storage.getConfig());
            return {data: response.data, status:  response.status};
        } catch (error) {
            return error?.response;
        }
    }

    async bulkAccountCreation(data){
        try {
            const response = await axios.post(conn_url+'/api/v1/users/bulk-signup', data, storage.getConfig());
            return {data: response.data, status:  response.status};
        } catch (error) {
            return error?.response;
        }
    }

    async fetchTeamMember({branchId = null, statusId = 1}){
        if (branchId === null || branchId === ''){
            const response = await axios.get(conn_url+'/api/v1/company/members', storage.getConfig());
            return {data: response.data, status:  response.status};
        }else{
            const response = await axios.get(conn_url+`/api/v1/company/members?branchId=${branchId}&status=${statusId}`, storage.getConfig());
            return {data: response.data, status:  response.status};
        }
    }

    async fetchLoggedInUserSkillSet(){
        const response = await axios.get(conn_url+'/api/v1/users/skill-sets', storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchComapnyMember(){
        const response = await axios.get(conn_url+'/api/v1/company/members', storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchATeamMember(userId){
        const response = await axios.get(`${conn_url}/api/v1/company/members/${userId}/profile`, storage.getConfig());
        return response.data;
    }

    async fetchAllTeamMembers(branchId){
        const response = await axios.get(`${conn_url}/api/v1/company/members`, storage.getConfig());
        const members = response.data?.filter((member) => member.assignedBranchId === branchId);
        return members;
    }

    async fetchTeamMemberUpcomingShift(userId){
        const response = await axios.get(`${conn_url}/api/v1/company/members/${userId}/upcoming-shifts`, storage.getConfig());
        return response.data;
    }

    async fetchTeamMemberSwapFrequency({userId, from, to}){
        const response = await axios.get(`${conn_url}/api/v1/company/members/${userId}/swap-frequency?from=${from}&to=${to}`, storage.getConfig());
        return response.data;
    }

    async fetchTeamMemberShiftDateBooked({userId, from, to}){
        const response = await axios.get(`${conn_url}/api/v1/company/members/${userId}/shift-days-booked?from=${from}&to=${to}`, storage.getConfig());
        return response.data;
    }

    async fetchTeamMemberShiftLateClockIn({userId, from, to}){
        const response = await axios.get(`${conn_url}/api/v1/company/members/${userId}/shift-late-clock-in?from=${from}&to=${to}`, storage.getConfig());
        return response.data;
    }

    async fetchTeamMemberShiftHourWorked({userId, from, to}){
        const response = await axios.get(`${conn_url}/api/v1/company/members/${userId}/shift-hours-worked?from=${from}&to=${to}`, storage.getConfig());
        return response.data;
    }

    async updateReportTo({userId, data}){
        const response = await axios.put(`${conn_url}/api/v1/company/members/${userId}/manager`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async updateUser(data){
        try {
            const response = await axios.patch(`${conn_url}/api/v1/company/members/update`, data, storage.getConfig());
            return response.data;
        } catch (error) {
            return error?.response;
        }
    }

    async updateMemberHourlyPay(data){
        try {
            const response = await axios.patch(`${conn_url}/api/v1/company/members/hourly-pay/update`, data, storage.getConfig());
            return response.data;
        } catch (error) {
            return error?.response;
        }
    }

    async WriteUp(data){
        const response = await axios.post(conn_url+"/api/v1/writeup", data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async AddLicense(data){
        const response = await axios.post(conn_url+"/api/v1/users/licences", data, storage.getFileConfig());
        return {data: response.data, status:  response.status};
    }

    async ApproveDisapproveLicense(data){
        try {
            
            const response = await axios.post(`${conn_url}/api/v1/users/licences/approve/${data.id}`, {status: data.state}, storage.getConfig());
            return response;
        } catch (error) {
            return error
        }
    }

    async getCountries(){
        const response = await axios.get(conn_url+"/api/v1/countries", config);
        return response.data;
    }

    async getSkillSet(){
        const response = await axios.get(conn_url+"/api/v1/company/get-skill-sets", storage.getConfig());
        return {data: [...response.data], status:  response.status};
    }

    async deleteSkillSet({skillId}){
        try {
            const response = await axios.delete(`${conn_url}/api/v1/company/skill-sets/${skillId}`, storage.getConfig());
           return response.data;
            
        } catch (error) {
            throw error
        }
    }

    async createSkillSet(data){
        try {
            const response = await axios.post(`${conn_url}/api/v1/company/skill-sets`, data, storage.getConfig());
           return response.data;
            
        } catch (error) {
            throw error
        }
    }

    async addToMySkillSet(data){
        try {
            const response = await axios.post(`${conn_url}/api/v1/users/add-to-my-skill-sets`, data, storage.getConfig());
           return response.data;
            
        } catch (error) {
            throw error
        }
    }


    async fetchBranchBenchmark(branchId){
        try {
            const response = await axios.get(`${conn_url}/api/v1/get-benchmark?branchId=${branchId}`, storage.getConfig());
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }

    async fetchShiftBenchmark(data){

        let queryString = '';
        Object.keys(data).map((x) => {
        if(data[x]){
            if(queryString.startsWith('?')){
            queryString += `&${x}=${data[x]}`
            } else {
            queryString = `?${x}=${data[x]}`
            }
        }
        })

        try {
            const response = await axios.get(`${conn_url}/api/v1/get-shift-benchmark${queryString}`, storage.getConfig());
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }


    async createBranchBenchmark(data){
        try {
            const response = await axios.post(`${conn_url}/api/v1/create-benchmark`,data, storage.getConfig());
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }

    async getSecondaryJobs(){
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
            const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
            const response = await axios.get(`${conn_url}/api/v1/get-sec-jobroles/${member.id}`, storage.getConfig());
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }

    async createSecondaryJob(data){
        try {
            const response = await axios.post(`${conn_url}/api/v1/create-sec-jobroles`,data, storage.getConfig());
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }

}