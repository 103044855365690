import axios from "axios";
import { Storage } from "../StorageClass/StorageClass";
import { conn_url } from "../conn_consts";

const storage = new Storage();

export class Shift{

    async createShiftSlot(data){
        const response = await axios.post(conn_url+"/api/v1/shifts/slots", data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async bookSlotWithAsignees(data){
        const response = await axios.post(conn_url+"/api/v1/shifts/book-slot-assignees", data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async bookSlot(data){
        const response = await axios.post(conn_url+"/api/v1/shifts/book-slot", data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async deleteShift(data){
        const response = await axios.post(conn_url+`/api/v1/shifts/${data}/cancel`,{}, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchCreatedSlots(){
        const response = await axios.get(conn_url+"/api/v1/shifts/slots", storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchRoaster(branchId,to,from,page){
        if (branchId !== 1 && branchId !== '') {
            const response = await axios.get(conn_url+`/api/v1/shifts/roaster?page=${page}&from=${from}&to=${to}&branchId=${branchId}`, storage.getConfig());
            return {data: response.data, status:  response.status};
        }else{
            const response = await axios.get(conn_url+`/api/v1/shifts/roaster?page=${page}&from=${from}&to=${to}`, storage.getConfig());
            return {data: response.data, status:  response.status};
        }
    }

    async makeReadyForSwap(data){
        const response = await axios.post(conn_url+"/api/v1/shift-swap", data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async makeSwapRequest(data){
        try {
            const response = await axios.post(conn_url+"/api/v1/shift-swap/requests", data, storage.getConfig());
            return {data: response.data, status:  response.status};
            
        } catch (error) {
            return {data: error.response.data, status:  error.response.status};
            
        }
    }

    async acceptSwapRequest(data){
        const response = await axios.post(conn_url+`/api/v1/shift-swap/${data}/accept`, {}, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async rejectSwapRequest(data){
        const response = await axios.post(conn_url+`/api/v1/shift-swap/${data}/reject`, {}, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchSwappableShift(branchId,page){
        const response = await axios.get(conn_url+`/api/v1/shift-swap?page=${page}&branchId=${branchId}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchViewShiftSchedule(userId){
        const response = await axios.get(conn_url+`/api/v1/shifts/schedule?userId=${userId}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

    async fetchShiftSlotAvailability(from,to, branchId){
        let queryString = `${conn_url}/api/v1/shifts/slots-available?from=${from}&to=${to}`
        if(branchId){
            queryString = `${queryString}&branchId=${branchId}`;
        }
        const response = await axios.get(queryString, storage.getConfig());
        // &branchId=${branchId}&jobRoleId=${jobRoleid}
        return {data: response.data, status:  response.status};
    }

    async fetchSwapRequest(status){
        const response = await axios.get(conn_url+`/api/v1/shift-swap/requests?status=${status}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    }

}