import React from 'react';
import './TaskCard.css';
import { BsChat } from 'react-icons/bs';
import convertCaseToSentence from '../../../../../utils/convertToSentenceCase';
import getInitials from '../../../../../utils/getInitials';
import { useDispatch } from 'react-redux';
import { selectedTask } from '../../../../../Store/Task';

const TaskCard = ({ data, statusId }) => {
    const dispatch = useDispatch();

    const theme = {
        medium: {
            primary: "#FFCC00",
            secondary: "#FFFBEB"
        },
        highest: {
            primary: "#9A4242",
            secondary: "#F5E4E4",
        },
        high: {
            secondary: "#F9E9F9",
            primary: "#AE5AAB"
        },
        low: {
            primary: "#E4E9FD",
            secondary: "#415EC2"
        },
        lowest: {
            primary: "#D7E2E5",
            secondary: "#67909B"
        }
    }

    const EvaluateColor = (priority) => {
        if (priority === 'highest') {
            return theme.highest;
        } else if (priority === 'high') {
            return theme.high;
        }
        else if (priority === 'medium') {
            return theme.medium;
        }
        else if (priority === 'low') {
            return theme.low;
        }
        else if(priority === 'lowest'){
            return theme.lowest;
        }
    }

    const handleDragStart = async (e, data) => {
        e.dataTransfer.setData('dragStartStatus', statusId)
        dispatch(selectedTask(data))
    }

    return ( 
        <section 
            onDragStart={(e) => handleDragStart(e, data)} 
            draggable 
            className='py-[10px] px-[15px] rounded-[5px] bg-white m-[10px] dark:bg-828 dark:text-white'>

            <p className="font-bold text-color-424 text-[17px] mb-[15px] dark:text-white">{data?.title}</p>

            <div className='assign-section'>

                {
                    data?.assignees?.map((assignee, index) => (
                        <div key={index} className='user-initials'>
                    <span>{getInitials(`${assignee.user.firstName} ${assignee.user.lastName}`)}</span>
                </div>
                    ))
                }

                <div className='task-priority' >
                    <span style={{ color: EvaluateColor(data?.priority)?.primary, backgroundColor: EvaluateColor(data?.priority)?.secondary }}>{convertCaseToSentence(data?.priority)}</span>
                </div>

                <div className='chat'>
                    <BsChat />
                </div>

            </div>



        </section>
    )
}

export default TaskCard