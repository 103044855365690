import React, {useState} from 'react'
import { SideBar } from '../../../Widgets/SideBar'
import { BranchesHeader } from '../../BranchScreen/BranchesHeader/BranchesHeader'
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import SearchInput from '../../../Widgets/SearchInput'
import AppLayout from '../../../Widgets/AppLayout';


import {IoLocationSharp} from 'react-icons/io5'
import {BsFillPersonFill} from 'react-icons/bs'
import {BsCalendarFill} from 'react-icons/bs'



export const OrderLogs = () => {

    const navigate = useNavigate()

  return (
    <section>
    <AppLayout
        custom="inventory"
        subCustom="order-logs"
        title="Logs"
        customTitle=" "
        subtitle=""
    >

        <div className="flex h-[30px] items-center gap-3">
            {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
            <p className="font-medium text-base text-5F3 my-6">
            <Link to="/inventory-logs">LOGS</Link><span className="text-A5A"> / ORDER LOG</span>
            </p>
        </div>

        <div className='mt-5'>
            <SearchInput 
                className={'w-[300px] border-gray-200 h-10'}
                inputStyle={'placeholder:text-gray-400'}
                iconStyle='text-gray-400'
                placeholder='Search for items...' 
                onChange={()=>{}}
            />
        </div>

        {
            Array.from({length: 5}).map((val,index) => (

                <div className='flex gap-3 border-b-1 pb-5 mt-5'>
                    <OrderCard />
                    <ShowItemCard onClickShowItem={()=> navigate('/order-logs/2222')}/>

                </div>
            ))
        }
    </AppLayout>
    
</section>
  )
}


const OrderCard = () => {
  return (
    <div className='border-1 flex flex-col gap-2  rounded-xl w-[415px] h-[168px] px-5 py-5'>

        <div className='flex justify-between'>
            <IconAndName text='Toronto'/>
            <IconAndName text='Mustapha Yusuf' Icon={BsFillPersonFill}/>
            <IconAndName text='03/12/2023' Icon={BsCalendarFill}/>
        </div>

        <div className='flex gap-2'>
            <p className='text-[16px] text-A5A'>#RTO9OX</p>
            <p className='text-grey-600 text-[14px]'>No of items : <span className='text-color-424 text-[16px]'>39</span></p>
        </div>

        <p className='px-3 py-2 mt-3 w-[100px] max-w-[150px] rounded-xl bg-base-color text-[18px] font-bold'>
        $3000.76
        </p>

    </div>
  )
}

const ShowItemCard = ({onClickShowItem=()=>{}}) => {
    return (
        <div className='w-[690px] h-[168px] border-1 rounded-xl px-5 py-5 flex flex-col'>
            <p className='text-969 text-[18px] font-semibold'>Title</p>
            <p className='text-A5A text-[14px] mt-2 font-semibold'>DECADRON 10 mg/ml, ZAFRON ODT, Isol Decadron 10 mg/ml, Z 40ML VIAL INL, Water for Patienis, DISIMEECTANT WIPES ( BULK J, Hand sanitizer refill</p>

            <div className='text-[14px] flex gap-5 mt-4 self-end items-center font-semibold'>
                <p className=' text-5F3 cursor-pointer'>Show more</p>
                <button onClick={onClickShowItem}
                className=' bg-base-color font-bold px-4 py-1 rounded-md'>Show items</button>
            </div>
        </div>
    )
}

const IconAndName = ({Icon=IoLocationSharp, text='some text'}) => {
    return (
        <div className='flex gap-2 items-center text-base-color'>
            <Icon size={14} />
            <p className='text-[13px] text-969'>{text}</p>
        </div>
    )
}

