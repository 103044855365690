import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();


export const getHolidays = async (data) => {
    if(data === undefined){
        const response = await axios.post(`${conn_url}/api/v1/company/getbranchholiday`,{}, storage.getConfig());
        return {data: response.data, status:  response.status};

    }
    else{
        const response = await axios.post(`${conn_url}/api/v1/company/getbranchholiday`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    }
  
  }

export const createHoliday = async (data) => {
    try {
        const response = await axios.post(conn_url+"/api/v1/company/branchholiday", data, storage.getConfig());
        return response;
    } catch (error) {
        return error
    }
        
}  
  