
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { askChatGpt } from '../Network/ServiceClass/ChatGpt';

export const askChatGptThunk = createAsyncThunk('chatGpt/askChatGpt', askChatGpt);

const ChatGptSlice = createSlice({
  name: 'chatGpt',
  initialState: {
    chat: [],
    loading: false,
   
  },
  extraReducers: {
   [askChatGptThunk.pending]: (state) => {
    state.loading = true
   },
   [askChatGptThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.chat = [...state.chat,action.payload]
    return state
   },
   [askChatGptThunk.rejected]: (state, action) => {
    state.loading = false;
    const payload = JSON.parse(action.error.message)
    state.chat = [...state.chat, payload]
   },

   
   
   
  }
})


// export const { selectedTask, getTaskStatus, setTaskLoading } = ChatGptSlice.actions;

export default ChatGptSlice.reducer;