import { Modal } from '@material-ui/core';
import { Stack } from '@mui/material';
import CustomButton from '../../../../Widgets/CustomButton';
const ConfirmAccounts = ({ open = false, setOpenModal, submitInvite, isLoading }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ height: '100vh' }}
        justifyContent="center"
      >
        <div className="px-[20px] flex flex-col justify-between py-[15px] bg-white w-[336px] h-[200px] text-center">
          <p className="font-semibold text-base text-color-424">Confirm</p>
          <p className="text-xs font-medium text-969 w-[276px]">
            Would like to send these invites or continue editing
          </p>
          <div className="flex items-center w-full">
            <CustomButton
              containerClass="w-full"
              disabled={isLoading}
              onClick={handleClose}
              className="w-full mr-2"
              showIcon={false}
              title="Continue editing"
            />
            <CustomButton
              onClick={submitInvite}
              containerClass="w-full"
              className="w-full text-center ml-2 rounded bg-base-color text-white flex justify-center"
              showIcon={false}
              title={"Send"}
            />
          </div>
        </div>
      </Stack>
    </Modal>
  );
};

export default ConfirmAccounts;
