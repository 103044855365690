import React from 'react'

const InitialsGen = ({name= "   ", width, bgColor = "blue", mr="10px"}) => {

    const splitname = name?.split(" ");

  return (
    <span style={{width: width, height:width, color:"white",
    display:"inline-flex", alignItems:"center",justifyContent:"center", marginRight:mr,
    backgroundColor: bgColor, fontSize:"12px", padding:"5px", borderRadius:"100%"}}>
        {splitname[0].substring(0,1)+splitname[1].substring(0,1)}
    </span>
  )
}

export default InitialsGen