import { useFormik } from 'formik';
import FormModal from '../../../Widgets/Animation/FormModal';
import { Stack, CircularProgress } from '@mui/material';
import * as yup from 'yup';
import {
  Field,
  Text,
  TextAndField,
  FieldSelect,
  OutlinedButton,
  FillButton,
} from '../../../Widgets/FormUtils/FormUtils';
import { FetchBranchBenchmarkAsync, createBranchBenchmarkAsync } from '../../../../Store/Branches';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '518px',
  height: '587px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

function CreateBenchmark({ open, handleClose, branchId, isLoading = false, dispatch }) {
  const formik = useFormik({
    initialValues: {
      branchId: branchId,
      name: '',
      target: 0,
      dayType: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("This is required"),
      target: yup.number().min(1).required("This is required"),
      dayType: yup.string().required("This is required")
    }),
    onSubmit: (values) => {
      setTimeout(()=>{
        dispatch(createBranchBenchmarkAsync(values)).then((res) => {
          if(res?.meta?.requestStatus === "fulfilled"){
            dispatch(FetchBranchBenchmarkAsync(branchId)).then(()=> {
              handleClose()
            })
          }
        })
      },400)
    }
  });

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack direction="column" alignItems="center" sx={style} gap={3}>
        <p className="font-semibold mb-[56px] text-[22px]">
          New Benchmark - Apple Office
        </p>

        <TextAndField space={1}>
          <Text text={'Benchmark Name'} />
          <Field
            text={'Enter benchmark name'}
            value={formik.values.name}
            formik={formik}
            name="name"
            width={'300px'}
          />
        </TextAndField>

        <TextAndField space={1}>
          <Text text={'Benchmark Target'} />
          <Field
            text={'Enter target'}
            value={formik.values.target}
            formik={formik}
            name="target"
            width={'300px'}
          />
        </TextAndField>

        <TextAndField space={1}>
          <Text text={'Day Type'} />
          <FieldSelect
            list={[
              {
                name: 'Weekday',
                id: 'weekday',
              },
              {
                name: 'Weekend',
                id: 'weekend',
              },
              {
                name: 'Holiday',
                id: 'holiday',
              },
            ]}
            value={formik.values.dayType}
            formik={formik}
            name="dayType"
            width="100%"
            // callback={(val) => {
            // formik.setFieldValue('', val.target.value);
            // }}
          />
        </TextAndField>

        <Stack
          direction="row"
          width="82%"
          justifyContent="space-between"
          mt={6}
          gap={4}
        >
          <OutlinedButton
            themeColor="#00AD57"
            width="50%"
            text="Cancel"
            callBack={() => {
              formik.resetForm();
              handleClose();
            }}
          />
          {!isLoading && (
            <FillButton
              width="50%"
              text="Submit"
              callBack={formik.handleSubmit}
            />
          )}

          {isLoading && <CircularProgress />}
        </Stack>
      </Stack>
    </FormModal>
  );
}

export default CreateBenchmark;
