import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { Branch } from '../Network/ServiceClass/Branch';
import { Job } from '../Network/ServiceClass/Job';
import { Service } from '../Network/ServiceClass/Auth';

const branchClass = new Branch()
const jobClass = new Job()
const authService = new Service()

export const getSkillSetAsync = createAsyncThunk('branches/getSkillSet', branchClass.getSkillSet);
export const deleteSkillSetAsync = createAsyncThunk('branches/deleteSkillSet', branchClass.deleteSkillSet);
export const createSkillSetAsync = createAsyncThunk('branches/createSkillSet', branchClass.createSkillSet);
export const getCountriesAsync = createAsyncThunk('branches/getCountries', branchClass.getCountries);
export const getBranchesAsync = createAsyncThunk('branches/getBranches', branchClass.getBranches);
export const getTeammemberAsync = createAsyncThunk('branches/getTeamMember', branchClass.fetchTeamMember);
export const getATeammemberAsync = createAsyncThunk('branches/getATeamMember', branchClass.fetchATeamMember);
export const getLoggedInUseSkillSetAsync = createAsyncThunk('branches/fetchLoggedInUserSkillSet', branchClass.fetchLoggedInUserSkillSet);
export const addToMYSkillSetAsync = createAsyncThunk('branches/addToMySkillSet', branchClass.addToMySkillSet);

export const reassignBranchAsync = createAsyncThunk('branches/reassignBranch', branchClass.reassignBranch);



export const getAllTeamMembersAsync = createAsyncThunk('branches/getAllTeamMember', branchClass.fetchAllTeamMembers);
export const fetchTeamMemberUpcomingShiftAsync = createAsyncThunk('branches/fetchTeamMemberUpcomingShift', branchClass.fetchTeamMemberUpcomingShift);
export const fetchTeamMemberSwapFrequencyAsync = createAsyncThunk('branches/fetchTeamMemberSwapFrequency', branchClass.fetchTeamMemberSwapFrequency);
export const fetchTeamMemberShiftDateBookedAsync = createAsyncThunk('branches/fetchTeamMemberShiftDateBooked', branchClass.fetchTeamMemberShiftDateBooked);
export const fetchTeamMemberShiftLateClockInAsync = createAsyncThunk('branches/fetchTeamMemberShiftLateClockIn', branchClass.fetchTeamMemberShiftLateClockIn);
export const fetchTeamMemberShiftHourWorkedAsync = createAsyncThunk('branches/fetchTeamMemberShiftHourWorked', branchClass.fetchTeamMemberShiftHourWorked);
export const updateReportToAsync = createAsyncThunk('branches/updateReportTo', branchClass.updateReportTo);
export const fetchJobRolesAsync = createAsyncThunk('branches/fetchJobRoles', jobClass.fetchJobRoles);
export const fetchJobRolesWithoutLoadingAsync = createAsyncThunk('branches/fetchJobRolesWithoutLoading', jobClass.fetchJobRoles);

export const fetchJobGropusAsync = createAsyncThunk('branches/fetchJobGroups', jobClass.fetchJobGroups);
export const updateJobRolesAsync = createAsyncThunk('branches/updateJobRole', jobClass.updateJobRoles);
export const assignJobToTagAsync = createAsyncThunk('branches/assignJobToTag', jobClass.assignJobToTag);
export const removeJobTagFromJobRoleAsync = createAsyncThunk('branches/removeJobTagFromJobRole', jobClass.removeJobTagFromJobRole);


export const updateJobGroupRolesAsync = createAsyncThunk('branches/updateJobGroup', jobClass.updateJobGroup);
export const deleteJobRolesAsync = createAsyncThunk('branches/deleteJobRole', jobClass.deleteJobRoles);
export const deleteJobGroupRolesAsync = createAsyncThunk('branches/deleteJobGroup', jobClass.deleteJobGroup);
export const fetchLicenseAsync = createAsyncThunk('branches/fetchLicense', jobClass.fetchLicense);
export const bulkInviteAsync = createAsyncThunk('branches/bulkInviteTeamMember', branchClass.bulkInviteTeamMember);
export const updateUserStatusAsync = createAsyncThunk('branches/updateUser', branchClass.updateUser);
export const bulkAccountCreationAsync = createAsyncThunk('branches/bulkAccountCreation', branchClass.bulkAccountCreation);
export const ApproveDisapproveLicenseAsync = createAsyncThunk('branches/approveDisapproveLicenseAsync', branchClass.ApproveDisapproveLicense);

export const FetchBranchBenchmarkAsync = createAsyncThunk('branches/FetchBranchBenchmark', branchClass.fetchBranchBenchmark);
export const createBranchBenchmarkAsync = createAsyncThunk('branches/createBranchBenchmark', branchClass.createBranchBenchmark);

export const getAuthUrlAsync = createAsyncThunk('branches/authService', authService.getGoogleAuthUrl)
export const getMsAuthUrlAsync = createAsyncThunk('branches/getMSAuthUrl', authService.getMSAuthUrl)
export const linkGoogleAccountAsync = createAsyncThunk('branches/linkGoogleAccount', authService.linkGoogleAuthUrl)
export const linkMSAccountAsync = createAsyncThunk('branches/linkMSAuthUrl', authService.linkMSAuthUrl)
export const scheduleGoogleSyncAsync = createAsyncThunk('branches/scheduleGoogleSync', authService.scheduleGoogleSync)
export const scheduleMSSyncSyncAsync = createAsyncThunk('branches/scheduleMSSync', authService.scheduleMsSync)

export const getShiftBenchmarkAsync = createAsyncThunk('branches/get-shift-benchmark', branchClass.fetchShiftBenchmark)

export const getSecondaryJobsAsync = createAsyncThunk('branches/get-sec-jobroles', branchClass.getSecondaryJobs)
export const createSecondaryJobsAsync = createAsyncThunk('branches/create-sec-jobroles', branchClass.createSecondaryJob)

export const updateUserHourlyPayThunk = createAsyncThunk('branches/update-user-hourly-pay', branchClass.updateMemberHourlyPay)

const BranchSlice = createSlice({
  name: 'branches',
  initialState: {
    loading: false,
    shiftLateClockInIsLoading: false,
    shiftHourWorkedIsLoading: false,
    updateReportToLoading: false,
    approveDisapproveLicenseToLoading: false,
    shiftDateBookLoading: false,
    shiftSwapFrequencyLoading: false,
    upcomingShiftLoading: false,
    teamMembersLoading: false,
    reassignLoading: false,
    licenses: [],
    branches: [],
    countries: [],
    skillSets: [],
    teamMembers: [],
    invitedTeams: [],
    accountCreationTeams: [],
    jobRoles: [],
    jobGroups: [],
    manager: {},
    connected: false,
    loggedUserSkillSet: [],
    upcomingShift: [],
    shiftSwapFrequency: [],
    shiftDateBook: [],
    shiftLateClockIn: [],
    shiftHourWorked: {},
    benchmarks: [],
    shiftBenchmarks: [],
    secondaryJobrole:[],
    combineSchedules: {}

  },
  reducers:{
    getCombineSchedules: (state) => {
      const newStore = {}
      const branches = state.branches
      const roles = state.jobRoles
      branches?.data?.forEach((bran) => {
        newStore[bran.id] = {
          ...bran,
          roles: roles
        }
      })

      state.combineSchedules = newStore
    },
    setInvitedTeams:(state, action) => {
      state.invitedTeams = action.payload
      return state;
    },

    setAccounCreationTeams:(state, action) => {
      state.accountCreationTeams = action.payload
      return state;
    }
  },
  extraReducers: {
    [updateUserHourlyPayThunk.pending]: (state) => {
      state.loading = true;
      return state
    },
    [updateUserHourlyPayThunk.fulfilled]: (state, action) => {
      state.loading = false;
      // state.secondaryJobrole = action.payload;
      return state
    },
    [updateUserHourlyPayThunk.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [createSecondaryJobsAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [createSecondaryJobsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      // state.secondaryJobrole = action.payload;
      return state
    },
    [createSecondaryJobsAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getSecondaryJobsAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getSecondaryJobsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.secondaryJobrole = action.payload;
      return state
    },
    [getSecondaryJobsAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getShiftBenchmarkAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getShiftBenchmarkAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.shiftBenchmarks = action.payload;
      return state
    },
    [getShiftBenchmarkAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [scheduleGoogleSyncAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [scheduleGoogleSyncAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.connected = true;
      return state
    },
    [scheduleGoogleSyncAsync.rejected]: (state) => {
      state.loading = false;
      state.connected = false;
      return state
    },
    [scheduleMSSyncSyncAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [scheduleMSSyncSyncAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.connected = true;
      return state
    },
    [scheduleMSSyncSyncAsync.rejected]: (state) => {
      state.loading = false;
      state.connected = false;
      return state
    },
    [linkGoogleAccountAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [linkGoogleAccountAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [linkGoogleAccountAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [linkMSAccountAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [linkMSAccountAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [linkMSAccountAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getAuthUrlAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getAuthUrlAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [getAuthUrlAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getMsAuthUrlAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getMsAuthUrlAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [getMsAuthUrlAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [deleteJobRolesAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [deleteJobRolesAsync.fulfilled]: (state, action) => {
      const updatedJobRole = state.jobRoles.filter((job) => job.id !== action.payload)
      state.jobRoles = updatedJobRole;
      state.loading = false;
      return state
    },
    [deleteJobRolesAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [deleteJobGroupRolesAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [deleteJobGroupRolesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [deleteJobGroupRolesAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [updateJobRolesAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [updateJobRolesAsync.fulfilled]: (state, action) => {
      const updatedJobRole = state.jobRoles.map((job) => {
        if(job.id === action.payload.id){
          return action.payload
        }
        return job
      })
      state.jobRoles = updatedJobRole;
      state.loading = false;
      return state
    },
    [updateJobRolesAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [assignJobToTagAsync.pending]: (state) => {
      return state
    },
    [assignJobToTagAsync.fulfilled]: (state, action) => {
      return state
    },
    [assignJobToTagAsync.rejected]: (state) => {
      return state
    },

    [removeJobTagFromJobRoleAsync.pending]: (state) => {
      return state
    },
    [removeJobTagFromJobRoleAsync.fulfilled]: (state, action) => {
      return state
    },
    [removeJobTagFromJobRoleAsync.rejected]: (state) => {
      return state
    },

    [updateJobGroupRolesAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [updateJobGroupRolesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [updateJobGroupRolesAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },


    [updateUserStatusAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [updateUserStatusAsync.fulfilled]: (state, action) => {
      const newTeamMembers = state.teamMembers.filter((member) => member.user.id !== action?.payload.userId)
      state.teamMembers = newTeamMembers;
      state.loading = false;
      return state
    },
    [updateUserStatusAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [bulkInviteAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [bulkInviteAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [bulkInviteAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [bulkAccountCreationAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [bulkAccountCreationAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [bulkAccountCreationAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [FetchBranchBenchmarkAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [FetchBranchBenchmarkAsync.fulfilled]: (state, action) => {
      state.benchmarks = action.payload
      state.loading = false;
      return state
    },
    [FetchBranchBenchmarkAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [fetchJobRolesAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [fetchJobRolesAsync.fulfilled]: (state, action) => {
      state.jobRoles = action.payload.data
      state.loading = false;
      return state
    },
    [fetchJobRolesAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [fetchJobRolesWithoutLoadingAsync.pending]: (state) => {
      return state
    },
    [fetchJobRolesWithoutLoadingAsync.fulfilled]: (state, action) => {
      state.jobRoles = action.payload.data
      return state
    },
    [fetchJobRolesWithoutLoadingAsync.rejected]: (state) => {
      return state
    },


    [fetchJobGropusAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [fetchJobGropusAsync.fulfilled]: (state, action) => {
      state.jobGroups = action.payload.data
      state.loading = false;
      return state
    },
    [fetchJobGropusAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getBranchesAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getBranchesAsync.fulfilled]: (state, action) => {
      state.branches = action.payload
      state.loading = false;
      return state
    },
    [getBranchesAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [getCountriesAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getCountriesAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getCountriesAsync.fulfilled]: (state, action) => {
      state.countries = action.payload
      state.loading = false;
      return state
    },


    [getSkillSetAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getSkillSetAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getSkillSetAsync.fulfilled]: (state, action) => {
      state.skillSets = action.payload
      state.loading = false;
      return state
    },


    [deleteSkillSetAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [deleteSkillSetAsync.fulfilled]: (state, action) => {
      return state
    },
    [deleteSkillSetAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [createSkillSetAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [createSkillSetAsync.fulfilled]: (state, action) => {
      console.log(action.payload)
      state.loading = false;
      return state
    },
    [createSkillSetAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    

    [getTeammemberAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getTeammemberAsync.fulfilled]: (state, action) => {
      state.teamMembers = action.payload.data
      state.loading = false;
      return state
    },
    [getTeammemberAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getATeammemberAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getATeammemberAsync.fulfilled]: (state, action) => {
      state.manager = action.payload
      state.loading = false;
      return state
    },
    [getATeammemberAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [getLoggedInUseSkillSetAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getLoggedInUseSkillSetAsync.fulfilled]: (state, action) => {
      state.loggedUserSkillSet = action.payload?.data
      state.loading = false;
      return state
    },
    [getLoggedInUseSkillSetAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },


    [addToMYSkillSetAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [addToMYSkillSetAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [addToMYSkillSetAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },

    [reassignBranchAsync.pending]: (state) => {
      state.reassignLoading = true;
      return state
    },
    [reassignBranchAsync.fulfilled]: (state, action) => {
      state.reassignLoading = false;
      return state
    },
    [reassignBranchAsync.rejected]: (state) => {
      state.reassignLoading = false;
      return state
    },


    [getAllTeamMembersAsync.pending]: (state) => {
      state.teamMembersLoading = true;
      return state
    },
    [getAllTeamMembersAsync.fulfilled]: (state, action) => {
      state.teamMembers = action.payload
      state.teamMembersLoading = false;
      return state
    },
    [getAllTeamMembersAsync.rejected]: (state) => {
      state.teamMembersLoading = false;
      return state
    },
    [createBranchBenchmarkAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [createBranchBenchmarkAsync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [createBranchBenchmarkAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [fetchTeamMemberUpcomingShiftAsync.pending]: (state) => {
      state.upcomingShiftLoading = true;
      return state
    },
    [fetchTeamMemberUpcomingShiftAsync.fulfilled]: (state, action) => {
      state.upcomingShift = action.payload
      state.upcomingShiftLoading = false;
      return state
    },
    [fetchTeamMemberUpcomingShiftAsync.rejected]: (state) => {
      state.upcomingShiftLoading = false;
      return state
    },
    [fetchTeamMemberSwapFrequencyAsync.pending]: (state) => {
      state.shiftSwapFrequencyLoading = true;
      return state
    },
    [fetchTeamMemberSwapFrequencyAsync.fulfilled]: (state, action) => {
      state.shiftSwapFrequency = action.payload
      state.shiftSwapFrequencyLoading = false;
      return state
    },
    [fetchTeamMemberSwapFrequencyAsync.rejected]: (state) => {
      state.shiftSwapFrequencyLoading = false;
      return state
    },
    [fetchTeamMemberShiftDateBookedAsync.pending]: (state) => {
      state.shiftDateBookLoading = true;
      return state
    },
    [fetchTeamMemberShiftDateBookedAsync.fulfilled]: (state, action) => {
      state.shiftDateBook = action.payload
      state.shiftDateBookLoading = false;
      return state
    },
    [fetchTeamMemberShiftDateBookedAsync.rejected]: (state) => {
      state.shiftDateBookLoading = false;
      return state
    },
    [fetchTeamMemberShiftLateClockInAsync.pending]: (state) => {
      state.shiftLateClockInIsLoading = true;
      return state
    },
    [fetchTeamMemberShiftLateClockInAsync.fulfilled]: (state, action) => {
      state.shiftLateClockIn = action.payload
      state.shiftLateClockInIsLoading = false;
      return state
    },
    [fetchTeamMemberShiftLateClockInAsync.rejected]: (state) => {
      state.shiftLateClockInIsLoading = false;
      return state
    },
    [fetchTeamMemberShiftHourWorkedAsync.pending]: (state) => {
      state.shiftHourWorkedIsLoading = true;
      return state
    },
    [fetchTeamMemberShiftHourWorkedAsync.fulfilled]: (state, action) => {
      state.shiftHourWorked = action.payload
      state.shiftHourWorkedIsLoading = false;
      return state
    },
    [fetchTeamMemberShiftHourWorkedAsync.rejected]: (state) => {
      state.shiftHourWorkedIsLoading = false;
      return state
    },
    [fetchLicenseAsync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [fetchLicenseAsync.fulfilled]: (state, action) => {
      state.licenses = action.payload
      state.loading = false;
      return state
    },
    [fetchLicenseAsync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [updateReportToAsync.pending]: (state) => {
      state.updateReportToLoading = true;
      return state
    },
    [updateReportToAsync.fulfilled]: (state, action) => {
      state.shiftHourWorked = action.payload
      state.updateReportToLoading = false;
      return state
    },
    [updateReportToAsync.rejected]: (state) => {
      state.updateReportToLoading = false;
      return state
    },

    [ApproveDisapproveLicenseAsync.pending]: (state) => {
      state.approveDisapproveLicenseToLoading = true;
    },
    [ApproveDisapproveLicenseAsync.fulfilled]: (state, action) => {
      state.approveDisapproveLicenseToLoading = false;
    },
    [ApproveDisapproveLicenseAsync.rejected]: (state) => {
      state.approveDisapproveLicenseToLoading = false;
    },
  }
})

export const { setInvitedTeams, setAccounCreationTeams, getCombineSchedules } = BranchSlice.actions;

export default BranchSlice.reducer;
