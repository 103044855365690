import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const requestOverTime = async (data) => {

    const response = await axios.post(`${conn_url}/api/v1/shift-overtime/request`, data, storage.getConfig());
    return response.data;
 
}

export const getOverTime = async (page=1) => {
    const response = await axios.get(`${conn_url}/api/v1/shifts-overtime?page=${page}&status=pending`, storage.getConfig());
    return response.data;
}

export const getMyOverTime = async (page=1) => {
    const response = await axios.get(`${conn_url}/api/v1/shifts-overtime/me?page=${page}`, storage.getConfig());
    return response.data;
}

export const getWeeklyWorkedBooked = async (date) => {
    const response = await axios.get(`${conn_url}/api/v1/shifts/weekly-hours-booked?date=${date}`, storage.getConfig());
    return response.data;
}

export const approveOverTime = async (id) => {
    const response = await axios.post(`${conn_url}/api/v1/shift-overtime/${id}/approve`, {}, storage.getConfig());
    return response.data;
 
}

export const disapproveOverTime = async (id) => {
    const response = await axios.post(`${conn_url}/api/v1/shift-overtime/${id}/decline`, {}, storage.getConfig());
    return response.data;
 
}