import React from 'react';
import ListIcon from '../../../Assets/clipboard.png';
import { BsArrowRightSquareFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import AppLayout from '../../Widgets/AppLayout';
import { CgCalendarDates } from "react-icons/cg";
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { inventoryItemTutorial } from '../mock';


export const InventoryItems = () => {
    const navigate = useNavigate()
    
    const cardList = [
        {
            title: "Inventory listing",
            subtitle: "List of inventory items and the details of those items",
            onClick: () => navigate("/inventory-items-list")
        },
        // {
        //     title: "Inventory Audit",
        //     subtitle: "Manage inventory items quantity and details",
        // },
        {
            title: "Item groups",
            subtitle: "Manage the groups of items in the inventory",
            onClick: () => navigate("/inventory-items-group")
        },
    ]
  
    const RenderCardList = ({ title = "", subTitle = "", onClick = () => {}}) => {
    return (
      <div className="rounded-xl border dark:border-969 py-[10px] px-[15px] w-[300px]">
        <div className="flex gap-3 items-center dark:text-white mb-[11px]">
          <CgCalendarDates/>
          <p className="text-base font-semibold dark:text-white">{title}</p>
        </div>
        <p className="text-sm text-A5A dark:text-white mb-[18px] w-[200px]">
          {subTitle}
        </p>
        <div className="flex justify-end">
          <BsArrowRightSquareFill className="cursor-pointer dark:text-white" onClick={onClick} size={30} />
        </div>
      </div>
    );
  };

  return (
    <section>
      <AppLayout
      custom="inventory"
      subCustom="inventory-items"
      title="Inventory Items"
      customTitle=" "
      subtitle=""
      RightComponent={<TutorialPanel items={inventoryItemTutorial} />}
      >
        <section>
          <div className="flex items-center gap-10 flex-wrap">
            {
                cardList?.map((x) => (
                    <RenderCardList onClick={x.onClick} title={x.title} subTitle={x.subtitle} />
                ))
            }
          </div>
        </section>

      </AppLayout>
     
    </section>
  );
};
