import {useState, useEffect, useCallback} from 'react'

import SearchInput from '../../../Widgets/SearchInput'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';
import { CircularProgress } from '@mui/material';

import GlobalButton from '../../../Widgets/GlobalButton';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { statusColor } from '../../../../utils/inventoryStatus';
import { getTransferItemsRequestThunk } from '../../../../Store/Inventory'
import { stockupInventoryTransferItemThunk } from '../../../../Store/Inventory';
import { TableWrapper, TableHeader, Td, Tr } from '../../../Widgets/Table/TableWrapper';


import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';

const SingleTransferItem = () => {

    const { itemId } = useParams();
    const dispatch = useDispatch()

    const itemsList = useSelector(state => state.inventory)?.transferRequestItems || []

 
    
    const [isLoading, setIsLoading] = useState(false)
    const [isStocking, setIsStocking] = useState(false)

    const [searchValue, setSearchValue] = useState('')

   
    const handleSearch = useCallback(() => {
        let datas = itemsList[0]?.items
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                datas = itemsList[0]?.items.filter((val) => (
                    val.referenceNumber?.toLowerCase().match(pattern) ||
                    val.name?.toLowerCase().match(pattern) ))
        }
        return datas

    
    },[searchValue, itemsList])

    
    useEffect(()=> {
        setIsLoading(true)
        dispatch(getTransferItemsRequestThunk({batchIdentifier: itemId}))  
        .then(_ => {
            setIsLoading(false)
        })
    },[])


    const handleStockUp = () => {
        const data = {
            transferItemsData: itemsList[0]?.items.map(x => ({
                transferItemId: x?.id,
                quantityReceived: x?.quantityWithdrawn,
                locationId: itemsList[0]?.toLocation?.locationId
            })),
        }
        // console.log(data)
        setIsStocking(true)

        dispatch(stockupInventoryTransferItemThunk(data))
        .then(val => {
            if(val.payload?.status === 200) {
                dispatch(getTransferItemsRequestThunk({batchIdentifier: itemId}))  
                toast.success(val.payload?.data?.message)
            }
            else toast.error(val.payload?.data?.message)
            setIsStocking(false)
        })
    }
   
    


  return (
    <section>
        <AppLayout
           custom="inventory"
           subCustom="transfered-items-list"
           title="Transfered Items List"
           customTitle="Inventory"
           subtitle=""
        >
            
            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                <Link to="/transfered-items-list">TRANSFER ITEM LIST /</Link>
                <span className='font-bold'> {itemId}</span>
                </p>
            </div>

            <div className='flex flex-col gap-2 mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-10'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                />

                <div className='flex justify-end'>

                    <div className='flex gap-3'>
                        

                        <GlobalButton 
                            loading ={isStocking}
                            disabled={(isLoading || isStocking)}
                            onClick = {handleStockUp}
                            title="Stock Items" 
                            className="rounded-md text-white font-semibold text-[16px] "/>

                          

                    </div>
                </div>



            </div>

            <TableWrapper className='mt-6 !max-h-[70vh] mb-12'>
                <table className=''>
                    <TableHeader>
                        <Td className='py-4 min-w-[100px]'>Reference Number</Td>
                        <Td className='min-w-[70px]'>Name</Td>
                        <Td className='min-w-[70px]'>Transferred From</Td>
                        <Td className='min-w-[70px]'>Transferred To</Td>
                        <Td className='min-w-[70px]'>Amount Transferred</Td>
                        <Td className='min-w-[70px]'>Date</Td>
                        <Td className='min-w-[70px] !border-none'>Status</Td>

                    </TableHeader>

                    {!isLoading ? <tbody>
                        {handleSearch()?.length !== 0 ?
                            handleSearch()?.map((val, index) => (
                                <Tr key={index} className='hover:bg-5F5 hover:dark:bg-292'>
                                        <Td className='py-4'>
                                            {val?.referenceNumber}
                                        </Td>

                                        <Td className=''>{val?.name}</Td>

                                         <Td>
                                                <div className='flex items-center justify-center gap-2'>
                                                    <p className='w-2 h-2 rounded-full' style={{background: itemsList[0]?.fromLocation?.hexcode}}/>
                                                    <p>{itemsList[0]?.fromLocation?.locationName}</p>
                                                </div>
                                            </Td>
                                        <Td>
                                            <div className='flex items-center justify-center gap-2'>
                                                <p className='w-2 h-2 rounded-full' style={{background: itemsList[0]?.toLocation?.hexcode}}/>
                                                <p>{itemsList[0]?.toLocation?.locationName}</p>
                                            </div>
                                        </Td>

                                        <Td className=''>{val?.quantityWithdrawn}</Td>
                                        <Td>{dayjs(val?.createdOn).format('MM/DD/YY')}</Td>

                                        <Td className={'!border-none'}>
                                            <p 
                                                className={`rounded-full cursor-pointer px-2 text-center ${statusColor[val?.status]?.bg} ${statusColor[val?.status]?.color}`}>
                                                    {capitalizeFirstLetter(val?.status)}
                                            </p>
                                            
                                        </Td>
                                </Tr>

                            ))

                        :<tr>
                            <td colSpan={9} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                        }
                    </tbody>
                    : <tbody>
                    <tr>
                        <td colSpan={9} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                    </tr>
                </tbody>}
                </table>

            </TableWrapper>
           
        </AppLayout>

      
       
    </section>
  )
}

export default SingleTransferItem