import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as DownIcon } from '../../Assets/down-arrow.svg';
import Proptypes from 'prop-types';

const AvatarDropdown = ({ data, value, onChangeOption, zIndex='z-30', bulletClassName="", styleClass="", parentClass = "" }) => {
  const [openDropdown, setDropdown] = React.useState(false);

  const handleOpenDropDown = () => {
    setDropdown((prev) => !prev);
  };

  const handleSelectedOption = (val) => {
    onChangeOption(val);
    setDropdown(false);
  };

  const handleCloseDropdown = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <div className="relative">
        <div
          onClick={handleOpenDropDown}
          className={`flex justify-between items-center border-1 border-[#c2c2c2] rounded-[5px] py-[6px] px-[10px] dark:border-9FD ${parentClass}`}
        >
          <div
            className={`px-[10px] py-[5px] flex items-center cursor-pointer`}
          >
            <div
              className={`w-[20px] h-[20px] rounded-full ${bulletClassName}`}
              style={{ backgroundColor: value?.color || 'grey' }}
            />
            <p className={`text-sm ml-3 font-bold ${styleClass}`}>{value?.label}</p>
          </div>
          <DownIcon />
        </div>

        {openDropdown && (
          <div className={`shadow-select bg-white dark:bg-424 dark:text-white absolute w-full mt-4 ${zIndex}`}>
            {data?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleSelectedOption(item)}
                className={`px-[10px] py-[5px] flex items-center hover:bg-5A5 dark:hover:bg-color-333 hover:text-white cursor-pointer`}
              >
                <div
                  className={`w-[30px] h-[30px] rounded-full ${bulletClassName}`}
                  style={{ backgroundColor: item?.color }}
                />
                <p className="text-sm font-medium ml-3" style={{ color: item?.color}}>{item.label}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default AvatarDropdown;

AvatarDropdown.proptype = {
  data: Proptypes.arrayOf({
    value: Proptypes.string.isRequired,
    label: Proptypes.string.isRequired,
  }).isRequired,
  value: Proptypes.string.isRequired,
  onChangeOption: Proptypes.func.isRequired,
};
