
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllWriteUp, getMembers, createWriteUp, deleteWriteUp } from '../Network/ServiceClass/WriteUpService';

// thunk
 export const getAllWriteUpThunk = createAsyncThunk('writeup/getAllWriteUp', getAllWriteUp);
 export const getMemberThunk = createAsyncThunk('writeup/getMembers', getMembers);
 export const createWriteupThunk = createAsyncThunk('writeup/createWriteUp', createWriteUp);
 export const deleteWriteupThunk = createAsyncThunk('writeup/deleteWriteUp', deleteWriteUp);

const WriteUp = createSlice({
  name: 'writeup',
  initialState: {
    writeUps: [],
    writeUp: {},
    loading: false,
    members: [],
    writeDetailsIsLoading: false,
    createWriteUpIsLoading: false,
    deleteWriteUpIsLoading: false
  },
  extraReducers: {
   [getAllWriteUpThunk.pending]: (state) => {
    state.loading = true
   },
   [getAllWriteUpThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.writeUps = action.payload
    return state
   },
   [getAllWriteUpThunk.rejected]: (state) => {
    state.loading = false;
   },
   [getMemberThunk.pending]: (state) => {
    state.loading = true
   },
   [getMemberThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.members = action.payload
    return state
   },
   [getMemberThunk.rejected]: (state) => {
    state.loading = false;
   },
   [createWriteupThunk.pending]: (state) => {
    state.createWriteUpIsLoading = true
   },
   [createWriteupThunk.fulfilled]: (state, action) => {
    state.createWriteUpIsLoading = false;
    state.writeUps = [...state.writeUps, {...action.payload}]
    return state
   },
   [createWriteupThunk.rejected]: (state) => {
    state.createWriteUpIsLoading = false;
   },
   [deleteWriteupThunk.pending]: (state) => {
    state.deleteWriteUpIsLoading = true
   },
   [deleteWriteupThunk.fulfilled]: (state, action) => {
    state.deleteWriteUpIsLoading = false;
    state.writeUps = state.writeUps.filter((writeup) => writeup.id !== action.payload);
    return state
   },
   [deleteWriteupThunk.rejected]: (state) => {
    state.deleteWriteUpIsLoading = false;
   },
  }
})


// export const { selectedTask, getTaskStatus, setTaskLoading } = WriteUp.actions;

export default WriteUp.reducer;