import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomDropdown from "../../Widgets/CustomDropdown";
import { getDatesArray } from "../../../utils/dateArray";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { getReportAsync } from "../../../Store/Company";
import AppLayout from "../../Widgets/AppLayout";
import { convertToDollar } from "../../../utils/util";
import SearchInput from "../../Widgets/SearchInput";
import { getBranchesAsync } from "../../../Store/Branches";
import moment from "moment";
import TutorialPanel from "../../Widgets/Tutorial/TutorialPanel";
import { reportTutorial } from "../mock";
import Csv from "./components/Csv";

export const ReportScreen = () => {
  const dispatch = useDispatch();
  const dateCreated = useSelector(
    (state) => state?.company?.activeCompany?.createdOn
  );
  const stateStore = useSelector((state) => state.company);

  const [yearSelected, setYearSelected] = useState({
    label: moment().format("yyyy"),
    value: moment().format("yyyy"),
  });

  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];

  const [selectedBranch, setBranch] = useState({
    label: "All locations",
    value: "",
  });

  const [selectedRotation, setSelectedRotation] = useState(1);
  const [searchedReports, setSearchedReport] = useState("");

  useEffect(() => {
    dispatch(getReportAsync(yearSelected.value));
  }, [dispatch, yearSelected.value]);

  useEffect(() => {
    dispatch(getBranchesAsync());
  }, []);

  const handleOnDateChange = (data) => {
    setYearSelected(data);
  };

  const handleOnLocationChange = (data) => {
    setBranch(data);
  };

  const handleRotationIncreaseSelection = () => {
    setSelectedRotation((prev) =>
      Math.min(prev + 1, stateStore.rotationNumber)
    );
  };

  const handleRotationDecreaseSelection = () => {
    setSelectedRotation((prev) => Math.max(prev - 1, 1));
  };

  const handleReportSearch = (userName) => {
    const filteredData = stateStore?.reports?.data?.filter((jobRole) =>
      jobRole?.name?.toLowerCase().includes(userName?.toLowerCase())
    );
    return filteredData;
  };

  const handleOnSearchReport = (e) => {
    setSearchedReport(e.target.value);
  };

  return (
    <section className="homepage">
      <AppLayout
        custom="report"
        subCustom=""
        title="Reports"
        customTitle=""
        subtitle="View the work hours of employees"
        RightComponent={<TutorialPanel items={reportTutorial} />}
      >
        <div className="mt-[77px] flex items-center justify-between">
          <div className="flex items-center">
            <SearchInput
              onChange={handleOnSearchReport}
              placeholder="Search staff"
            />

            <CustomDropdown
              data={getDatesArray(dateCreated, new Date().toString())?.map(
                (dates) => ({ label: dates, value: dates })
              )}
              className="w-[14rem] h-[2.8rem] rounded ml-4 border-grey-600"
              optionClass="w-[90%] right-0 mt-3"
              value={yearSelected}
              onChangeOption={handleOnDateChange}
            />

            {/* <CustomDropdown
               data={[
                { name: 'All locations', value: '' },
                ...branches,
              ]?.map((branch) => ({
                label: branch?.name,
                value: branch.id,
                hexcode: branch?.hexcode
              }))}
              className="w-[14rem] h-[2.8rem] rounded ml-4 border-grey-600"
              optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
              value={selectedBranch}
              onChangeOption={handleOnLocationChange}
            /> */}
          </div>

          <div className="flex items-center gap-2">
            <Csv
              data={handleReportSearch(searchedReports)}
              selectedRotation={selectedRotation}
            />

            <div className="border-1 h-[45px] border-grey-500 dark:border-333 dark:text-white rounded py-0 flex items-center px-3">
              <div
                onClick={handleRotationDecreaseSelection}
                className="py-4 px-1 cursor-pointer"
              >
                <IoIosArrowBack size={20} />
              </div>
              <div className="h-full flex items-center px-3 border-l-1 border-r-1 border-grey-500 dark:border-333">
                <p>{`ROT ${selectedRotation}`}</p>
              </div>
              <div
                onClick={handleRotationIncreaseSelection}
                className="py-4 px-2 cursor-pointer"
              >
                <IoIosArrowForward size={20} />
              </div>
            </div>
          </div>
        </div>
        <div data-tour="step4" className="max-h-[500px] overflow-y-scroll">
        <table className="mt-[10px] mb-40 text-center">
          <tr >
            <th className="border-1 border-grey-500 dark:border-333">
              <p className="text-grey-600 dark:text-white font-medium px-2 text-left">
                Employee name
              </p>
            </th>
            <th className="border-1 border-grey-500 dark:border-333 px-2 text-left">
              <p className="text-grey-600 dark:text-white font-medium">
                Work Hours
              </p>
            </th>
            <th className="border-1 border-grey-500 dark:border-333 px-2 text-left">
              <p className="text-grey-600 dark:text-white font-medium">
                Work Days
              </p>
            </th>
            <th className="border-1 border-grey-500 dark:border-333 px-2 text-left">
              <p className="text-grey-600 dark:text-white font-medium">
                Vacation Days
              </p>
            </th>
            <th className="border-1 border-grey-500 dark:border-333 px-2 text-left">
              <p className="text-grey-600 dark:text-white font-medium">
                Sick Days
              </p>
            </th>
            <th className="border-1 dark:border-333 border-grey-500">
              <p className="text-black dark:text-white font-semibold">
                ROT {selectedRotation}
              </p>
              <p className="text-grey-600 dark:text-white font-medium">
                (H-W-V-S)
              </p>
            </th>

            <th className="border-1 dark:border-333 border-grey-500">
              <p className="text-black dark:text-white font-semibold">
                Hourly Pay
              </p>
            </th>

            <th className="border-1 dark:border-333 border-grey-500">
              <p className="text-black dark:text-white font-semibold">
                Current Earned
              </p>
            </th>
          </tr>
          {handleReportSearch(searchedReports)?.map((report) => (
            <tr className="dark:text-white">
              <td className="border-1 dark:border-333 border-grey-500 py-4 text-left px-2">
                {report?.name}
              </td>
              <td className="border-1 dark:border-333 border-grey-500 px-2 text-center">
                {report?.rotations[selectedRotation - 1].hours > 0
                  ? report?.rotations[selectedRotation - 1].hours
                  : ""}
              </td>
              <td className="border-1 dark:border-333 border-grey-500 px-2 text-center">
                {report?.rotations[selectedRotation - 1].workDays > 0
                  ? report?.rotations[selectedRotation - 1].workDays
                  : ""}
              </td>
              <td className="border-1 dark:border-333 border-grey-500 px-2 text-center">
                {report?.rotations[selectedRotation - 1].vacationDays > 0
                  ? report?.rotations[selectedRotation - 1].vacationDays
                  : ""}
              </td>
              <td className="border-1 dark:border-333 border-grey-500 px-2 text-center">
                {report?.rotations[selectedRotation - 1].sickDays > 0
                  ? report?.rotations[selectedRotation - 1].sickDays
                  : ""}
              </td>

              <td className="border-1 dark:border-333 border-grey-500 p-1">
                {Object.values(
                  report?.rotations.find((x) => x.rotation === selectedRotation)
                ).some((rott) => rott > 0) && (
                  <div className="bg-BEB dark:bg-292 dark:text-white rounded flex items-center justify-center h-[40px]">
                    <p>
                      {report?.rotations[selectedRotation - 1].hours}-
                      {report?.rotations[selectedRotation - 1].workDays}-
                      {report?.rotations[selectedRotation - 1].vacationDays}-
                      {report?.rotations[selectedRotation - 1].sickDays}
                    </p>
                  </div>
                )}
              </td>

              <td className="border-1 dark:border-333 border-grey-500 p-1 text-center">
                <p>$ {convertToDollar(report?.hourlyPay || 0)}</p>
              </td>
              <td className="border-1 dark:border-333 border-grey-500 p-1  text-center">
                <p>
                  ${" "}
                  {convertToDollar(
                    report?.rotations[selectedRotation - 1].totalEarned
                  )}
                </p>
              </td>
              {/* {report?.rotations
                ?.filter((rot) =>
                  Array.from(
                    { length: selectedRotation + 4 - selectedRotation },
                    (value, index) => selectedRotation + index * 1
                  ).includes(rot.rotation)
                )
                .map((filtered) => ({
                  workHours: filtered?.hours,
                  workDays: filtered?.workDays,
                  vacationDays: filtered?.vacationDays,
                  sickDays: filtered?.sickDays,
                }))
                .map((rotations) => (
                  <td className="border-1 border-grey-500 p-1">
                    {Object.values(rotations).some((rott) => rott > 0) && (
                      <div className="bg-BEB rounded flex items-center justify-center h-[40px]">
                        <p>
                        {rotations?.workHours}-{rotations?.workDays}-{rotations?.vacationDays}-
                          {rotations?.sickDays}
                        </p>
                      </div>
                    )}
                  </td>
                ))} */}
            </tr>
          ))}

          {stateStore?.reports?.data?.length === 0 && (
            <tr>
              <td colSpan={8}>
                {" "}
                <p>No Report available</p>
              </td>
            </tr>
          )}
        </table>
        </div>
      </AppLayout>
    </section>
  );
};
