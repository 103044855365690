import React from 'react';
import './Styles/HomePageNavbar.css';
import {IoMdNotificationsOutline} from 'react-icons/io';
import {FiChevronDown} from 'react-icons/fi';
import { Storage } from '../../Network/StorageClass/StorageClass';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import logout from '../../Assets/Vector.png';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export const HomeNavbar = ({custom=""}) => {

    const location = useLocation();
    const navigation = useNavigate();

    const storage = new Storage();
    const userData = storage.getUserDetails();

    const dp = useSelector((state)=>state.company.dp);

    useEffect(()=>{
      userData === null && navigation('/login')   
    })

    const Logout = ()=>{
      navigation('/login');
    }

  return (
    //  <AppBar
    //     elevation={false}
    //     position="absolute"
    //     sx={{
    //       width: { sm: `calc(100% - ${'205'}px)` },
    //       ml: { sm: `${'205'}px` },
    //     }}
    //   >
    //     <Toolbar>
         
    //       <Typography variant="h6" noWrap component="div">
    //         Responsive drawer
    //       </Typography>
    //     </Toolbar>
    //   </AppBar>

    <div></div>
   
    //  <div className='homepage-navbar'>
    //   <div className='nav-inner'>
    //   <div className='logo-cont'>
    //   <img src={logo} alt="logo" className="logo"/>
    //   </div>

    //   <div className='side'>
    //     <div className='side-inner'>
    //     <div className='label font-medium text-2xl'>
    //         {custom==="" ? location.pathname.substring(1,2).toLocaleUpperCase() 
    //         + location.pathname.substring(2,location.pathname.length):custom}
    //     </div>
      
    //     <div className='trailing'>
      
    //         {userData !== null && <div className='label'>
    //             <div>{userData.firstName+" "+userData.lastName} </div>
    //             <div className='email'>{userData.email}</div>
    //         </div>}
      
            
      
    //         <div className='img-cont' onClick={()=>navigation('/profile-page')}>
    //           {dp === undefined || dp===null ? <></>:<img alt="logo" src={dp.url}width="100%" height="100%" style={{objectFit:"cover",borderRadius:"100%"}} />}
    //         </div>
    //         <div className='caret'>
    //             <FiChevronDown />
    //         </div>
      
    //         <div className='divider'></div>
      
    //         <div className='notification'><IoMdNotificationsOutline /></div>
    //         <div className='notification' onClick={Logout}><img alt="logo" width="22px" height="22px" src={logout} /></div>
            
    //     </div>
    //     </div>
    //   </div>
    //   </div>
    // </div> 
  )
}

