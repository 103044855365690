import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import { getInventoryExpiredItemsThunk } from '../../../Store/Inventory'
import dayjs from 'dayjs'
import CurrencyFormat from 'react-currency-format'
import ExportTemplate from './Widget/ExportTemplate'

const Expiring = ({branches}) => {
    const dispatch = useDispatch()

    // const [loading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})


    const expiredItems = useSelector(state => state.inventory)?.expiredItems
    const loading = useSelector(state => state.inventory)?.loadingExpiredItems

    const getUsage = (id=null) => {
        dispatch(getInventoryExpiredItemsThunk(id))
        // .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        // setIsLoading(true)
        getUsage(withdrawalBranch.value)
    },[withdrawalBranch])
    

   
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Items Expiring Soon'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={expiredItems?.map(x => ({
                    'Name': x?.orders[0]?.reference?.name,
                    'Reference Number': x?.orders[0]?.referenceNumber,
                    'Location': x?.orders[0]?.branch?.name,
                    'Expiry Date': dayjs(x?.orders[0]?.expirationDate).format('MM/DD/YYYY'),
                    'Lot Number': x?.orders[0]?.lotNumber,
                    

                }))}
                fileName={'expired-items'}
            />


            <div className='rounded-md border-1 dark:border-333 mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className=''>
                <tr className='bg-white dark:bg-444 dark:text-white font-bold sticky top-0'>
                    <td className='border-1 dark:border-333 border-t-0 border-l-0 text-center pl-4 py-4 rounded-tl-md '>Name</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Ref No.</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Location</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Expiry Date</td>
                    <td className='border-1 dark:border-333 border-t-0 border-r-0 text-center rounded-tr-md'>Lot No.</td>

                </tr>

                {!loading ? <tbody>
                    {expiredItems?.length !== 0 ?
                        expiredItems.map(val => (
                            <tr className='border-1 dark:border-333 dark:bg-828 font-bold border-l-0 text-A5A dark:text-white'>
                                    <td className='text-center border-r-1 dark:border-333 py-4 pl-4'>{val?.orders[0]?.reference?.name}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.orders[0]?.referenceNumber}</td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                        <div className='flex items-center justify-center gap-2'>
                                            <p className='w-2 h-2 rounded-full' style={{background: val?.orders[0]?.branch?.hexcode}}/>
                                            <p>{val?.orders[0]?.branch?.name}</p>
                                        </div>
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{dayjs(val?.orders[0]?.expirationDate).format('MM/DD/YYYY')}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.orders[0]?.lotNumber}</td>

                            </tr>

                        ))
                        :<tr>
                            <td colSpan={9} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                    }
                </tbody>:
                <tr>
                    <td colSpan={9} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                </tr>
                }
                
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default Expiring