import { useState } from "react"
import CustomDropdown from "../../../../Widgets/CustomDropdown"
import GlobalButton from "../../../../Widgets/GlobalButton"
import { useDispatch, useSelector } from "react-redux"
import { inventoryUpdateTrackingDetailsThunk } from "../../../../../Store/Inventory"
import { toast } from "react-toastify"

const statusList = [
    {label: 'Received', value: 'received'},
    {label: 'Loaded', value: 'loaded'},
    {label: 'In Transit', value: 'in_transit'},
    {label: 'Delivered', value: 'delivered'},


]

const UpdateTracking = ({handleBack, data,callBack}) => {


    const dispatch = useDispatch()

    const [selectedStatus, setSelectedStatus] = useState(null)
    const [commentField, setCommentField] = useState('')
    const [disableButton, setDisableButton] = useState(true)

    const isLoading = useSelector(state => state.inventory)?.loadingUpdateTracking

    const newList = statusList.filter(item1 => {
        return !data?.trackingDetails.some(item2 => item1.value === item2.status);
    });

    const handleDisableButton = (text,status) => {
        if (text.length === 0 || status === null) setDisableButton(true)
        else if (text.length === 0 && status === null) setDisableButton(true)
        else setDisableButton(false)
    }

    const handleUpdate = () => {
        const datas = {
            orderId: data?.id,
            locationId: data?.branch?.id,
            status: selectedStatus?.value,
            comment: commentField,
            teamMemberId: data?.teamMembership?.id
        }
        dispatch(inventoryUpdateTrackingDetailsThunk(datas))
        .then(val => {
            console.log(val)
            if (val.payload?.status === 201) {
                toast.success('Tracking updated')
                callBack()
                handleBack()
            }else toast.error(val.payload?.data?.message)
        })
    }

    return (
        <div className="flex flex-col justify-between my-8 h-[30vh]">
            <p className="text-[20px] font-semibold">Update Tracking Details</p>

            <div className="flex justify-between gap-4 w-full">

                <div className="flex flex-col gap-1 w-[30%]">
                    <p className="text-[14px] text-grey-600">Tracking Status</p>
                    <CustomDropdown
                        trimLength={25}
                        trimtext
                        data={newList}
                        className="w-[100%] rounded h-10"
                        value={selectedStatus}
                        dropdownTitleClass="text-A5A"
                        // optionClass="z-[1000]"
                        onChangeOption={(val)=>{
                            setSelectedStatus(val)
                            handleDisableButton(commentField, val?.value)
                        }}
                    />
                </div>

                <div className="flex flex-col gap-1 w-[70%]">
                    <p className="text-[14px] text-grey-600">Comment</p>
                    <input
                        onChange={(e)=> {
                            setCommentField(e.target.value)
                            handleDisableButton(e.target.value, selectedStatus)
                        }}
                        className="border-1 focus:outline-none py-[7px] rounded-[5px] px-2"
                        type="text" 
                    />
                </div>

            </div>

            <div className="flex justify-between">
                <GlobalButton
                    className='!bg-white border-1 border-[black]'
                    onClick={handleBack}
                    title="Back"
                  />
                <GlobalButton
                    loading={isLoading}
                    disabled={(disableButton || isLoading)}
                    className={`${(disableButton || isLoading) && 'opacity-[0.4]'}`}
                    onClick={handleUpdate}
                    title="Update Tracking Details"
                />
            </div>

        </div>
    )
}

export default UpdateTracking