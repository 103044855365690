

export const getMonth = {
  "January": "01",
  "February": "02",
  "March": "03",
  "April": "04",
  "May": "05",
  "June": "06",
  "July": "07",
  "August": "08",
  "September": "09",
  "October": "10",
  "November": "11",
  "December": "12",
}


export const setCellBackground = (data, user, skill, gender) => {
  const filterOption = [user.value,skill.value,gender.value].filter((x) => x !== "")

  const userIds = data?.map(
    (x) => x?.user.id
  )

  const genderVals = data?.map(
    (x) => x?.user.gender
  )

  const skillSetIds = []

 data?.map((x) => x.user?.userSkillSets?.map((y) => {
  skillSetIds.push(y.skillSetId)
  return y
 }))

 data?.map((x) => x.userSkillSets?.map((y) => {
  skillSetIds.push(y.skillSetId)
  return y
 }))

  if(filterOption.length === 1){
    return firstCategory(userIds, genderVals, skillSetIds, user, gender, skill)
  }

  if(filterOption.length === 2){
    return secondCategory(userIds, genderVals, skillSetIds, user,gender,skill)
  }

  return thirdCategory(userIds, genderVals, skillSetIds, user, gender, skill,)
}

const firstCategory = ( users, genders, skills, user, gender, skill) => {

  let shadeBg = false

  if(users.includes(user.value)){
    shadeBg = true
  }

  if(skills.includes(skill.value)){
    shadeBg = true
  }


  if(!genders.includes(gender.value) && genders.length > 0 && skill.value === "" && user.value === ""){
    shadeBg = true
  }

  return shadeBg

}

const secondCategory = (users, genders, skills, user, gender, skill) => {
  let shadeBg = false


  if(users.includes(user.value) && skills.includes(skill.value) && gender.value === ""){
    shadeBg = true
  }

  if(skills.includes(skill.value) && genders.includes(gender.value) && user.value === ""){
    shadeBg = true
  }

  if(genders.includes(gender.value) && users.includes(user.value) && skill.value === ""){
    shadeBg = true
  }

  return shadeBg
}

const thirdCategory = (users, skills, genders, user, gender, skill) => {
  let shadeBg = false

  if(skills.includes(skill.value) && genders.includes(gender.value) && users.includes(user.value)){
    shadeBg = true
  }

  return shadeBg;
}

export const convertToDollar = (val) => (val/100).toFixed(2)

export const checkLinkAccounts = (type) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return Object.values(user?.linkedEmails || {}).map((x) => x.accountType?.toLowerCase())?.includes(type?.toLowerCase())
}