/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from '../../../../HomePageScreen/Widget/Modal/Style.js';
import './TaskModal.css';
import { TaskClass } from '../../../../../../Network/ServiceClass/Task.js';
import { Loader } from '../../../../../Widgets/Loader.jsx';
import InitialsGen from '../../../../../Widgets/InitialsGen.jsx';
import convertCaseToSentence from '../../../../../../utils/convertToSentenceCase.js';
import AvatarDropdown from '../../../../../Widgets/AvatarDropdown.jsx';
import { statusColor, statuses } from '../../../../mock';
import PriorityDropdown from '../../../../../Widgets/PriorityDropdown';
import { useSelector } from 'react-redux';
import FormModal from '../../../../../Widgets/Animation/FormModal.jsx';

export const TaskModal = ({
  open,
  handleClose,
  selected,
  handleUpdateStatus,
  handleUpdatePriority,
}) => {
  const store = useSelector((state) => state.task.taskStatuses.statuses);

  const taskClass = new TaskClass();
  const [comments, setComments] = useState([]);
  const [isuploading, setUploading] = useState(false);
  const [loadingComment, setLoadingComments] = useState(true);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState(statuses[0]);
  const [priority, setPriority] = useState({});

  const [mentions] = useState([]);

  const fetchComments = (id) => {
    taskClass
      .getComments(id)
      .then((value) => {
        setComments(value.data);
        setLoadingComments(false);
      })
      .catch((err) => {
        setLoadingComments(false);
      });
  };

  const UploadComment = () => {
    if (selected !== null && comment !== '') {
      setUploading(true);
      taskClass
        .AddComment(selected.id, {
          comment: comment,
          mentions: mentions,
        })
        .then((value) => {
          setUploading(false);
          fetchComments(selected.id);
        })
        .catch((err) => {
          setUploading(false);
        });
    }
  };

  const DeleteComment = (val) => {
    taskClass
      .DeleteComment(selected.id, val.id)
      .then((value) => {
        fetchComments(selected.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selected !== null) fetchComments(selected.id);
  }, [selected]);

  const handleStatusChange = (data) => {
    setStatus(data);
    handleUpdateStatus({ ...data, taskId: selected.id });
  };

  const handlePriorityChange = (data) => {
    setPriority(data);
    handleUpdatePriority(data);
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Box
        sx={{
          ...style,
          width: '617px',
          height: '90vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          textAlign: 'start',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '50px',
          borderRadius: '10px'
        }}
        className="create-account slot outline-none dark:bg-primary dark:text-white"
      >
        {selected !== null && (
          <>
            <p className="font-bold text-[22px] mb-[23px]">
              {convertCaseToSentence(selected.title)}
            </p>

            <p className="font-semibold text-base text-color-424 dark:text-white">Description</p>
            <p className="text-sm text-color-333 dark:text-ABA">{selected.description}</p>

            <div className="details-table mt-4 border-9FD dark:border-9FD">
              <div className="py-3 pl-4 text-[18px] font-bold border-b-1 border-9FD dark:border-9FD">Details</div>

              <div className="grid grid-cols-3 pl-4 gap-y-4 pt-4 pb-5">
                <p className="text-[11px] flex items-center dark:text-ABA">Assigner</p>
                <p className="text-sm font-medium col-span-2">
                  {`${selected?.assigner?.firstName} ${selected?.assigner?.lastName} `}
                  <InitialsGen
                    width="25px"
                    name={`${convertCaseToSentence(
                      selected?.assigner?.firstName
                    )} ${convertCaseToSentence(selected?.assigner?.lastName)}`}
                  />
                </p>

                <p className="text-[11px] flex items-center dark:text-ABA">Assignee</p>
                {selected?.assigneeDesc?.length > 0 ? (
                  <p className="text-sm font-medium col-span-2">
                    {selected.assigneeGroup === 'user' &&(`${selected?.assigneeDesc[0]?.firstName} ${selected?.assigneeDesc[0]?.lastName} `)}
                    {selected.assigneeGroup === 'jobRole' &&(`${selected?.assigneeDesc[0]?.title}`)}
                    {selected.assigneeGroup === 'branch' &&(`${selected?.assigneeDesc[0]?.name}`)}

                    
                    {selected.assigneeGroup === 'user' && <InitialsGen
                      width="25px"
                      name={`${selected?.assigneeDesc[0]?.firstName} ${selected?.assigneeDesc[0]?.lastName}`}
                    />}

                    {selected.assigneeGroup === 'jobRole' && <InitialsGen
                      width="20px"
                      name={selected?.assigneeDesc[0]?.title}
                    />}

                    {selected.assigneeGroup === 'branch' && <InitialsGen
                      width="20px"
                      name={selected?.assigneeDesc[0]?.name}
                    />}

                  </p>
                ) : (
                  <p className="text-sm font-medium col-span-2">Nil</p>
                )}
                <p className="text-[11px] flex items-center dark:text-ABA">Status</p>
                <div className="col-span-2 max-w-[250px]">
                  <AvatarDropdown
                    data={store?.map((item) => ({
                      label: item.name,
                      value: item.id,
                      position: item.position,
                      color: statusColor[item.name.toLowerCase()],
                    }))}
                    onChangeOption={handleStatusChange}
                    value={
                      store
                        ?.filter(
                          (statusItem) => statusItem.id === selected.statusId
                        )
                        ?.map((changedItem) => ({
                          label: changedItem.name,
                          value: changedItem.id,
                          position: changedItem.position,
                          color: statusColor[changedItem.name?.toLowerCase()],
                        }))[0]
                    }
                  />
                </div>

                <p className="text-[11px] flex items-center dark:text-ABA">Priority</p>
                <div className="col-span-2 max-w-[250px]">
                  <PriorityDropdown
                    data={priority}
                    onChangeOption={handlePriorityChange}
                    value={selected.priority}
                  />
                </div>
              </div>
            </div>

           

            <div className="w-full dark:bg-primary dark:text-white dark:border-333 border-9FD">
              <p>Comments</p>
              <input
                className='w-full py-[10px] dark:bg-primary dark:border-9FD dark:text-white focus:outline-none rounded mt-2 indent-[5px] border-1'
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />

              {!isuploading ? (
                <div className="flex w-full justify-end gap-3 mt-3">


                  <button onClick={handleClose} className="py-[8px] px-[15px] border-1 border-D57 rounded-[3px]">
                    Cancel
                  </button>
                  <button className="py-[8px] px-[15px] ml-[10px] rounded-[3px] bg-424 text-white" onClick={UploadComment}>
                    Save
                  </button>
                </div>
              ) : (
                <Loader />
              )}
            </div>

            <div className="comment-list dark:bg-primary">
              {!loadingComment ? (
                comments.map((value) => (
                  <div className="comments">
                    <InitialsGen
                      bgColor="#004558"
                      width="20px"
                      name={
                        value.user.firstName?.toUpperCase() +
                        ' ' +
                        value.user.lastName?.toUpperCase()
                      }
                    />
                    <div className="_comment">
                      <p className="font-bold text-[11px]">
                        {value.user.firstName + ' ' + value.user.lastName}
                      </p>

                      <div style={{ display: 'inline-flex', width: '100%' }}>
                        <p className="text-[11px]" style={{ width: '70%' }}>
                          {value.comment}
                        </p>
                        <small style={{ width: '20%', color: '#A3A3A3A3' }}>
                          <span
                            className="cursor-pointer"
                            onClick={() => DeleteComment(value)}
                          >
                            Delete
                          </span>
                        </small>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <center>
                  <Loader />
                </center>
              )}
            </div>
          </>
        )}
      </Box>
    </FormModal>
    
  );
};
