/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getDates from '../../../../../../../utils/rangeOfData';
import DateLabel from '../DateLabel/DateLabel';
import { TimeOffService } from '../../../../../../../Network/ServiceClass/TimeOffService';
import { CircularProgress, Stack, Box, Typography } from '@mui/material';
import { setCalendarData } from '../../../../../../../Store/timeOff';
import { font } from '../../../../../../Widgets/Styles/font';
import PlaceHolderOne from '../../../../../../Widgets/placeholders/PlaceHolderOne';
import InitialsGen from '../../../../../../Widgets/InitialsGen';
import moment from 'moment';

const timeOffService = new TimeOffService();
const Calendar = () => {
  const br = 'border-r-1 dark:border-333 px-[0] py-[0] max-w-[7rem]';
  const dispatch = useDispatch();

  const calendarStateData = useSelector((state) => state.timeOff.calendarData);
  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );
  const dates = getDates(
    shiftStateWeeklyDateLabel.from,
    shiftStateWeeklyDateLabel.to
  );
  const newDate = dates.map((value) =>
    Number(value.toDateString().split(' ')[2])
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    timeOffService
      .fetchTimeOff({
        from: shiftStateWeeklyDateLabel.from,
        to: shiftStateWeeklyDateLabel.to,
      })
      .then((value) => {
        setIsLoading(false);
        if (value.status !== 200) throw new Error('');
        dispatch(setCalendarData(value.data));
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [shiftStateWeeklyDateLabel.from,
    shiftStateWeeklyDateLabel.to]);

  return (
    <div>
      {!isLoading && calendarStateData.length > 0 && (
        <section>
          {!isLoading && calendarStateData.length > 0 && (
            <div className="h-[50vh] overflow-y-auto dark:text-white">
              <table className="" >
                <tr className="sticky bg-white border-1 dark:border-333 top-0 dark:bg-1F1">
                  <td
                    className="dark:bg-1F1 border-r-1 dark:border-333 py-6 px-5"
                  >
                    Employee name
                  </td>
                  {dates.map((value, index) => {
                    if (index < 8) {
                      return (
                        <td
                          className={` border-r dark:border-333 ${[0,6].includes(value.getDay()) ? 'bg-[#FAFAFA] dark:bg-292' : 'bg-white dark:bg-1F1'}`}
                          style={{
                            padding: '0 18px',
                            borderRight: index === 6 ? 'none' : '',
                          }}
                        >
                          {' '}
                          <DateLabel detailsWeek={value} type="week" />
                        </td>
                      );
                    }
                  })}
                </tr>

                {calendarStateData.map((value) => {
                  let startDay = Number(
                    value.start.split('-')[2].split('T')[0]
                    );
                  const stopDay = Number(value.end.split('-')[2].split('T')[0]);

                  const dateRange = Array.from(Array(stopDay - startDay + 1).keys()).map((x) => x + startDay)

                  let indexOfStartCol =
                    newDate.findIndex((val) => val === startDay);

                    if(indexOfStartCol === -1){
                      for(let x=1; x<dateRange.length;x++){
                        const checkIndex = newDate.findIndex((val) => val === dateRange[x])
                        if(checkIndex !== -1){
                          indexOfStartCol = checkIndex;
                          startDay =  dateRange[x];
                          break;
                        }
                      }
                    }

                    indexOfStartCol = indexOfStartCol +1
                  const stop = stopDay - startDay + 1;

                  return (
                    <tr className='border-1 dark:border-333'>
                      <td className="td-padding dark:border-333 border-r dark:bg-A06 py-5">
                        <div className="flex items-center gap-3">
                          {value?.requester?.picture?.url ? (
                            <img
                              className="rounded-full h-10 w-10 mr-2"
                              src={value?.requester?.picture?.url}
                              alt="logo"
                            />
                          ) : (
                            <InitialsGen
                              width={40}
                              name={`${value.requester.firstName} ${value.requester.lastName}`}
                              bgColor="blue"
                            />
                          )}
                          {/* <p>{value.requester.firstName} {value.requester.lastName}</p> */}
                        <p>{value.requester.firstName} {value.requester.lastName}</p>
                        </div>
                      </td>
                      <td
                        className={br}
                        style={{ padding: 0}}
                        colspan={indexOfStartCol === 1 ? stop : 1}
                      >
                        {indexOfStartCol === 1 && (
                          <TimeShiftSpot
                            details={value}
                            colSpan={stop}
                            startCol={indexOfStartCol}
                          />
                        )}
                      </td>
                      <td
                        className={br}
                        style={{ padding: 0 }}
                        colspan={indexOfStartCol === 2 ? stop : 1}
                      >
                        {indexOfStartCol === 2 && (
                          <TimeShiftSpot
                            details={value}
                            colSpan={stop}
                            startCol={indexOfStartCol}
                          />
                        )}
                      </td>
                      <td
                        className={br}
                        style={{ padding: 0 }}
                        colspan={indexOfStartCol === 3 ? stop : 1}
                      >
                        {indexOfStartCol === 3 && (
                          <TimeShiftSpot
                            details={value}
                            colSpan={stop}
                            startCol={indexOfStartCol}
                          />
                        )}
                      </td>
                      <td
                        className={br}
                        style={{ padding: 0 }}
                        colspan={indexOfStartCol === 4 ? stop : 1}
                      >
                        {indexOfStartCol === 4 && (
                          <TimeShiftSpot
                            details={value}
                            colSpan={stop}
                            startCol={indexOfStartCol}
                          />
                        )}
                      </td>
                      <td
                        className={br}
                        style={{ padding: 0 }}
                        colspan={indexOfStartCol === 5 ? stop : 1}
                      >
                        {indexOfStartCol === 5 && (
                          <TimeShiftSpot
                            details={value}
                            colSpan={stop}
                            startCol={indexOfStartCol}
                          />
                        )}
                      </td>
                      <td
                        // className={`bg-171`}
                        style={{ padding: 0 }}
                        colspan={indexOfStartCol === 6 ? stop : 1}
                      >
                        {indexOfStartCol === 6 && (
                          <TimeShiftSpot
                            details={value}
                            colSpan={stop}
                            startCol={indexOfStartCol}
                          />
                        )}
                      </td>
                      <td
                        className='px-[0] py-[0] max-w-[7rem]'
                        colspan={indexOfStartCol === 7 ? stop : 1}
                      >
                        {indexOfStartCol === 7 && (
                          <TimeShiftSpot
                            details={value}
                            colSpan={stop}
                            startCol={indexOfStartCol}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </section>
      )}
      {!isLoading && calendarStateData.length < 1 && <PlaceHolderOne />}

      <Stack direction="row" justifyContent="center">
        {isLoading && <CircularProgress />}
      </Stack>
    </div>
  );
};

export default Calendar;

const TimeShiftSpot = ({ details, colSpan, startCol }) => {
   const start = moment(details.start,  "yyyy-MM-DD").format("ddd MMM DD")
  const end = moment(details.end, "yyyy-MM-DD").format("ddd MMM DD")

  const colors = {
    first: '#FFFBED',
    second: '#EDFBFF',
    third: '#F6FFED',
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: colSpan === 1 || startCol === 7 ? 'column' : 'row',
        position: 'relative',
        backgroundColor:
          colSpan === 1
            ? colors.second
            : colSpan === 2
            ? colors.first
            : colors.third,
        padding:
          colSpan === 1 || startCol === 7 ? '0.5rem 0.5rem' : '0.5rem 1rem',
        alignItems: colSpan === 1 || startCol === 7 ? 'flex-start' : 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {details !== undefined && (
          <Typography sx={{ ...font, fontSize: '12px', color: '#554201' }}>
            Time off request
          </Typography>
        )}
        {details !== undefined && (
          <Typography sx={{ ...font, fontSize: '12px', color: '#554201' }}>
            {start} - {end}
          </Typography>
        )}
      </Box>

      {details.status === 'pending' && (
        <Typography
          sx={{
            ...font,
            fontSize: '12px',
            color: '#FFCC00',
            border: '1px solid #FFCC00',
            padding: '0 2px',
            borderRadius: '3px',
          }}
        >
          Pending
        </Typography>
      )}
      {details.status === 'approved' && (
        <Typography
          sx={{
            ...font,
            fontSize: '12px',
            color: '#458452',
            border: '1px solid #458452',
            padding: '0 2px',
            borderRadius: '3px',
          }}
        >
          Approved
        </Typography>
      )}
      {details.status === 'declined' && (
        <Typography
          sx={{
            ...font,
            fontSize: '12px',
            color: 'red',
            border: '1px solid red',
            padding: '0 2px',
            borderRadius: '3px',
          }}
        >
          Declined
        </Typography>
      )}
    </Box>
  );
};
