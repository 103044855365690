import React, { useState, useEffect } from "react";
import CreateAccountModal from "./Widget/Modal/CreateAccountModal";
import { Home } from "../../../Network/ServiceClass/Home";
import { NoCompany } from "./Widget/NoCompany/NoCompany";
import { Loader } from "../../Widgets/Loader";
import { VerifyEmail } from "./Widget/VerifyEmail/VerifyEmail";
import { useDispatch, useSelector } from "react-redux";
import { setCompanies, setDp, setActiveCompany } from "../../../Store/Company";
import AlertDialog from "../../Widgets/SuccessDialog";
import ButtonWithIcon from "../../Widgets/ButtonWithIcon";
import { Storage } from "../../../Network/StorageClass/StorageClass";
import MainContent from "./MainContent";
import AppLayout from "../../Widgets/AppLayout";

export const HomePage = ({ verify }) => {
  const dispatch = useDispatch();
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;

  const home = new Home();

  const [createAccont, setCreateAccount] = useState(false);
  const [accountData, setAccountData] = useState();

  const [status, setStatus] = useState("loading");

  const storage = new Storage();

  const handleOpen = () => {
    setCreateAccount(true);
  };
  const handleClose = () => {
    setCreateAccount(false);
  };

  const callback = () => {
    home
      .getHomeData()
      .then((value) => {
        setAccountData(value.data);
        if (value.data.companies.length !== 0) {
          dispatch(setActiveCompany(value.data.activeCompany));
          dispatch(setCompanies(value.data.companies));
          dispatch(setDp(value.data.user.picture));
        }
        setStatus("fetched");
      })
      .catch((err) => {
        if (err.response.status === 401) setStatus("empty");
        else setStatus("failed");
      });
  };
  const refresh = () => {
    home
      .getHomeData()
      .then((value) => {
        if (value.data.companies.length !== 0) {
          dispatch(setActiveCompany(value.data.activeCompany));
          dispatch(setCompanies(value.data.companies));
          dispatch(setDp(value.data.user.picture));
        }
        setStatus("fetched");
        setAccountData(value.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    callback();
  }, []);

  return (
    <div>
      <CreateAccountModal
        open={createAccont}
        handleClose={handleClose}
        callback={callback}
      />
      <AlertDialog />
      <AppLayout mainClass="bg-5F5" custom={"home"} withHeader={false}>
        {status === "fetched" ? (
          accountData.companies.length !== 0 ? (
            <>
              <div className="flex justify-between mb-7 items-end relative dark:text-white">
                <div>
                  <p className="text-base font-medium text-[#696969] dark:text-white">
                    Welcome back 👋🏼,
                  </p>
                  {
                    <p className="text-[20px]  font-semibold">
                      {accountData !== null && accountData.user.firstName}
                      <span> </span>
                      {accountData !== null && accountData.user.lastName}
                    </p>
                  }
                </div>

                {[1,2].includes(privilege) && (<ButtonWithIcon
                  className="!p-0 !min-w-[50px] h-[50px] !gap-0 !border-primary home-page-add-btn"
                  // width='max-content'
                  width="40px"
                  gap="0"
                  iconClassName="text-D57"
                  tƒitle="Create a company"
                  handleClick={handleOpen}
                />)}
              </div>

              <MainContent />
            </>
          ) : (
            <NoCompany open={handleOpen} callback={refresh} />
          )
        ) : status === "loading" ? (
          <div className="h-[500px] flex justify-center items-center dark:bg-primary">

            <Loader />
          </div>
        ) : status === "empty" ? (
          <>
            <VerifyEmail />
          </>
        ) : (
          <></>
        )}
      </AppLayout>
    </div>
  );
};
