import React, {useState} from 'react'
import { SideBar } from '../../../Widgets/SideBar'
import { BranchesHeader } from '../../BranchScreen/BranchesHeader/BranchesHeader'

import CustomDropdown from '../../../Widgets/CustomDropdown';
import { useSelector } from 'react-redux';
import { getDatesArray } from '../../../../utils/dateArray';
import moment from 'moment';
import { months } from '../../ShiftScreen/Tabs/BranchSchedule/mock';
import TablePagination from '@mui/material/TablePagination';
import { Link, useNavigate } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';


const getMonth = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  };
const UsageReport = () => {

    const navigate = useNavigate()

    const dateCreated = useSelector(
        (state) => state?.company?.activeCompany?.createdOn
      );

    const [yearSelected, setYearSelected] = useState({
        label: moment().format("yyyy"),
        value: moment().format("yyyy"),
    });

    const [monthSelected, setMonth] = useState({
        label: moment().format('MMMM'),
        value: getMonth[moment().format('MMMM')],
    });
    

    const handleOnDateChange = (data) => {
        setYearSelected(data);
    };

    const handleOnMonthChange = (data) => {
        setMonth(data);
    };

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  return (
    <section>
        <AppLayout
            custom="inventory"
            subCustom="inventory-report"
            title="Report"
            customTitle="Inventory"
            subtitle=""
        >


            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                <Link to="/inventory-report">REPORT</Link><span className="text-A5A"> / INVENTORY USAGE REPORT</span>
                </p>
            </div>

            <div className='flex gap-2 items-center mt-4'>

                <CustomDropdown
                    data={getDatesArray(dateCreated, new Date().toString())?.map(
                    (dates) => ({ label: dates, value: dates })
                    )}
                    className="w-28 h-10 rounded "
                    optionClass="w-[90%] right-0 mt-3 z-[1000]"
                    value={yearSelected}
                    onChangeOption={handleOnDateChange}
                />

                <CustomDropdown
                    data={months}
                    className="w-28 rounded h-10"
                    value={monthSelected}
                    dropdownTitleClass="text-A5A"
                    optionClass="z-[1000]"
                    onChangeOption={handleOnMonthChange}
                />


            </div>

            <div className='mt-6 max-h-[70vh] overflow-y-auto '>
                <table className=''>
                    <tr className=' bg-0F5 text-grey-800 sticky top-0'>
                        <td className='pl-4 py-2 rounded-tl-md '>Code</td>
                        <td className=''>Description</td>
                        <td className=''>Location 1</td>
                        <td className=''>Location 2</td>
                        <td className=''>Location 3</td>
                        <td className=''>Location 4</td>
                        <td>Location 5</td>
                        <td className='rounded-tr-md'>Total</td>

                    </tr>

                    <tbody>
                        {
                            Array.from({length:20}).map(val => (
                                <tr className='border-b-1 text-A5A'>
                                        <td className='py-2 pl-4'>
                                        20/12/2023
                                        </td>

                                        <td>Friday</td>
                                        <td>00001</td>
                                        <td>
                                        <HoverText text={'50'}/>
                                        </td>
                                        <td> <HoverText text={'50'}/></td>
                                        <td> <HoverText text={'50'}/></td>
                                        <td> <HoverText text={'50'}/></td>
                                        <td>19</td>

                                </tr>

                            ))
                        }
                    </tbody>
                    <tfoot className='bg-color-424 text-white sticky bottom-0'>
                        <td className='pl-4 py-2 rounded-bl-md '>Date</td>
                        <td className=''>Day</td>
                        <td className=''>Location 1</td>
                        <td className=''>Location 2</td>
                        <td className=''>Location 3</td>
                        <td className=''>Location 4</td>
                        <td>Location 5</td>
                        <td className='rounded-br-md'>Total</td>
                    </tfoot>
                </table>

            </div>
            <TablePagination
                className='dark:text-grey-600'
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </AppLayout>
        
    </section>
  )
}

export default UsageReport

const HoverText = ({text}) => {
    return (
        <p className='hover:bg-5F5 w-8 cursor-pointer rounded-md px-1 py-1'>{text}</p>
    )
}