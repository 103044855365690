import React from 'react';
import { SideBar } from '../../Widgets/SideBar';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import ListIcon from '../../../Assets/clipboard.png';
import { BsArrowRightSquareFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import AppLayout from '../../Widgets/AppLayout';

const InventoryReport = () => {
    const navigate = useNavigate()
    
    const cardList = [
        {
            title: "Daily withdrawal reports",
            subtitle: "Get detailed information on withdrawals",
            onClick: () => navigate("/inventory-daily-report")
        },
        {
            title: "Inventory usage report",
            subtitle: "See how different locations are using different items",
            onClick: () => navigate("/inventory-usage-report")
        },
        
    ]
  
    const RenderCardList = ({ title = "", subTitle = "", onClick = () => {}}) => {
    return (
      <div className="rounded-xl border py-[10px] px-[15px] w-[300px] h-[150px]">
        <div className="flex gap-3 items-center mb-[11px]">
          <img src={ListIcon} alt="list+icon" />
          <p className="text-base font-semibold">{title}</p>
        </div>
        <p className="text-sm text-A5A mb-[18px] ">
          {subTitle}
        </p>
        <div className="flex justify-end">
          <BsArrowRightSquareFill className="cursor-pointer" onClick={onClick} size={30} />
        </div>
      </div>
    );
  };

  return (
    <section>
      <AppLayout
        custom="inventory"
        subCustom="inventory-items"
        title="Report"
        customTitle="Inventory"
        subtitle=""
      >
        <section>
          <div className="flex items-center gap-10 flex-wrap">
            {
                cardList?.map((x) => (
                    <RenderCardList onClick={x.onClick} title={x.title} subTitle={x.subtitle} />
                ))
            }
          </div>
        </section>

      </AppLayout>
     
    </section>
  );
};

export default InventoryReport