import { useState, useEffect } from "react"
import { FaChevronDown } from "react-icons/fa";
import SearchInput from "../../../../../../Widgets/SearchInput";
import { ClickAwayListener } from "@mui/material";
import { useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";


const SelectMembers = ({onChange, team =null}) => {

    const [toggle, setToggle] = useState(false)
    const [text, setText] = useState("")
    const [selectedMember, setSelectedMember] = useState(team)

    const teamMembers = useSelector(state => state.branches)?.teamMembers

    useEffect(()=>{
        onChange(selectedMember)
    },[selectedMember])

    const handleSearch = (txt) => {
        let filteredArray = teamMembers;

        if (txt !== "") {
        const pattern = new RegExp(`\D*${txt}\D*`, "i");
        filteredArray = teamMembers?.filter((x) => x?.user?.firstName.match(pattern) || x?.user?.lastName.match(pattern));
        }
        return filteredArray;
    }

  return (
    <div className="w-full relative">

        <div className="border-1 pl-3 pr-1 py-[7px] text-[12px] items-center rounded-[4px] hover:border-[#414855] cursor-pointer border-[#b9bec9] dark:border-333  flex justify-between dark:text-white"
            onClick={()=> setToggle(prev => !prev)}
        >
            {!selectedMember ? <p> Select Member </p>
            :
            <p>{selectedMember?.user?.firstName} {selectedMember?.user?.lastName}</p>
        }

            <div className="flex gap-2 dark:text-gray-500">
                {selectedMember && <IoCloseSharp 
                    onClick={(e)=> {
                        e.stopPropagation()
                        setSelectedMember(null)
                    }} 
                    className="text-[14px] cursor-pointer"
                 />}

                <FaChevronDown className="text-[14px]"/>

            </div>
        </div>

        {toggle && <ClickAwayListener onClickAway={()=> setToggle(false)}>
            <div className="absolute w-full h-[170px] overflow-y-auto bg-white dark:bg-transparent shadow-shift-shadow flex flex-col mt-1 text-[13px]">
                
                <SearchInput 
                    onChange={(e)=> setText(e.target.value)}
                    className="!p-0 py-[4px] !px-1 !rounded-sm" iconStyle="text-[13px]" inputStyle="text-[13px] w-full"/>

                {
                    teamMembers.length === 0 ?<p className=" w-full text-center mt-3">No Members</p>
                    :
                    <div>
                        {handleSearch(text).length > 0 ? handleSearch(text).map((member, index)=> (
                            <div 
                                onClick={()=> {
                                    setSelectedMember(member)
                                    setToggle(false)
                                }}
                                key={index} className="flex gap-2 px-3 py-1 hover:bg-gray-300 dark:hover:bg-969 mt-2 cursor-pointer">
                                <p>{member?.user?.firstName} {member?.user?.lastName}</p>
                            </div>
                        )): 
                        <p className=" w-full text-center mt-3">No member match </p>
                        }


                    </div>

                }

            </div>

        </ClickAwayListener>}


    </div>
  )
}

export default SelectMembers