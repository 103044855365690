import React from 'react';
import Box from '@mui/material/Box';
import './NewJobRoleModal.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import tinycode from 'tinycolor2'
import { useState } from 'react';
import { Stack, Typography, CircularProgress, Tooltip } from '@mui/material';
import { font } from '../../../Widgets/Styles/font';
import { MdOutlineCancel } from 'react-icons/md';
import FormModal from '../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from '../../../Widgets/ColorPicker';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import {
  Text,
  Field,
  TextAndField,
  FillButton,
  OutlinedButton,
} from '../../../Widgets/FormUtils/FormUtils';
import { createJobGroupAsync } from '../../../../Store/Shift';

export const NewJobGroupModal = ({ open, handleClose, jobRoles, callback=()=>{} }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state?.shift)?.loading
  const [personName, setPersonName] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      desc: '',
      hexcode: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please input job title'),
      hexcode: Yup.string().required('Please input hexcode'),
      desc: Yup.string()
        .min(10, 'Job description is too short')
        .required('Enter Job description'),
    }),

    onSubmit: (value, { setSubmitting}) => {
      setTimeout(()=>{
        const data = {
          ...value,
          slug: value.name.toLocaleLowerCase()?.split(" ")?.join("-"),
          memberships: personName
        }

        dispatch(createJobGroupAsync(data)).then((res) => {
          if(res?.meta?.requestStatus === 'fulfilled'){
            handleClose()
            callback()
            toast.success("Job Group was successfully created")
          } else {
            toast.error("Job group tag was not successfully created")
          }
          setSubmitting(false)
        })
      },400)
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden'
  };

 
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className="dark:bg-A06 dark:text-white" direction="column" sx={style} gap={3}>
        <p className='text-[18px] font-medium text-center' >
          New Job group
        </p>

        <TextAndField>
          <Text text={'Group Title'} />
          <Field
            text={'Enter title'}
            value={formik.values.name}
            formik={formik}
            name="name"
          />
        </TextAndField>


        <TextAndField>
          <Text text={'Group Description'} />
          <Field
            text={'Kindly enter group description'}
            value={formik.values.desc}
            formik={formik}
            name="desc"
          />
        </TextAndField>

        <ColorPicker onChangeColor={(color) => {
          formik.setFieldValue(
            'hexcode', color
          );
        }} />




        <TextAndField>
          <Text text={'Select job Roles'} />
          <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              className="w-full"
              multiple
              displayEmpty
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const assignBg = jobRoles?.filter((x) => x.id === value)[0]
                      .hexcode;
                    return (
                      
                        <Chip
                          style={{
                            borderRadius: '4px',
                            backgroundColor: assignBg,
                            fontWeight: 'bold',
                            color: tinycode(assignBg).isDark() ? "white" : "black"
                          }}
                          key={value}
                          label={
                            jobRoles.filter((team) => team.id === value)[0].title
                          }
                        />
                    );
                  })}
                </Box>
              )}
            >
              {jobRoles?.map((item) => (
                <MenuItem key={item.id} value={item?.id}>
                  <Checkbox checked={personName.indexOf(item?.id) > -1} />
                    <ListItemText primary={item?.title} />
                 
                </MenuItem>
              ))}
              {jobRoles?.length === 0 && (
                <MenuItem>No shift templates available, please create a template</MenuItem>
              )}
            </Select>
        </TextAndField>

        {isError && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '0.2rem',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              padding: '0.5rem',
            }}
          >
            <Typography
              sx={{
                ...font,
                fontSize: '12px',
                color: 'red',
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </Typography>
            <MdOutlineCancel
              style={{ color: 'red', fontSize: '23px', cursor: 'pointer' }}
              onClick={() => setIsError(false)}
            />
          </Box>
        )}

        <div
         className='flex justify-between mt-6 w-full'
        >
          <OutlinedButton
            text="Cancel"
            callBack={() => {
              handleClose();
              formik.resetForm();
            }}
          />
          {(!loading || !formik.isSubmitting) && (
            <FillButton text="Submit" callBack={formik.handleSubmit} />
          )}
          {(loading || formik.isSubmitting) && <CircularProgress />}
        </div>
      </Stack>
    </FormModal>
  );
};
