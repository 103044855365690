function getInitials(text) {
  if(text?.length === 0 ) return '';

  const splittedText = text.trim()?.split(" ");
  if(splittedText?.length === 1) return `${splittedText.slice(0,2).toUpperCase()}`
  if(splittedText?.length === 2) return `${splittedText[0].substring(0,1)?.toUpperCase()}${splittedText[1].substring(0,1)?.toUpperCase()}`
  return '';
}

export default getInitials;
