import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { font } from '../../../../../../Widgets/Styles/font';
import Slect from 'react-select';
import DatePicker from 'react-datepicker';
import './BookSlot.css';
import { Shift } from '../../../../../../../Network/ServiceClass/Shift';
import { setSuccessAlert } from '../../../../../../../Store/Company';
import successImage from '../../../../../../../Assets/success.png';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineCancel } from 'react-icons/md';
import mask from '../../../../../../../Assets/mask.png';
import { IoClose } from 'react-icons/io5';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import Alert from './Alert';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { getTeammemberAsync } from '../../../../../../../Store/Branches';
import DisplaySuccess from '../../../../../../Widgets/DisplaySuccess';
import { toast } from 'react-toastify';
import { Storage } from '../../../../../../../Network/StorageClass/StorageClass';

const storage = new Storage();

export default function BookSlot({
  openDrawer,
  handleCloseDrawer,
  data,
  setShiftSpots,
}) {
  const dayOfWeek = [
    { name: 'Monday', id: 1 },
    { name: 'Tuesday', id: 2 },
    { name: 'Wednesday', id: 3 },
    { name: 'Thursday', id: 4 },
    { name: 'Friday', id: 5 },
    { name: 'Saturday', id: 6 },
    { name: 'Sunday', id: 0 },
  ];

  const userPriviledge = storage.getActiveCompany().memberships[0].privilege;

  const dispatch = useDispatch();
  const serviceClass = new Shift();
  const shiftStateWeeklyDate = useSelector((state) => state.shift.weeklyDate);
 
  const store = useSelector((state) => state.branches)?.teamMembers?.map(
    (team) => ({
      id: team?.user?.id,
      name: `${team?.user?.firstName} ${team?.user?.lastName}`,
      assignedBranchId: team.assignedBranchId,
      jobRoleId: team.jobRole.id
    }))
    // .filter(val => val.assignedBranchId === data?.branch?.id && val.jobRoleId === data.jobRole.id)
  
 

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    handleCloseDrawer(open);
  };

  function taskDate(dateMilli) {
    const date = new Date(dateMilli);

    const year = date.toLocaleString('default', { year: 'numeric' });
    const month = date.toLocaleString('default', { month: '2-digit' });
    const day = date.toLocaleString('default', { day: '2-digit' });

    // Generate yyyy-mm-dd date string
    return year + '-' + month + '-' + day;
  }

  const reminderOptions = [
    { value: '10', label: 'Ten minute' },
    { value: '20', label: 'Twenty minute' },
    { value: '30', label: 'Thirty minute' },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [reminder, setReminder] = React.useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [personName, setPersonName] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [openDisplaySuccess, setOpenDisplaySuccess] = useState(false);

  const [alertData, setAlertData] = useState(null);
  const [displayData, setDisplayData] = useState(null);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseDisplaySuccess = () => {
    setOpenDisplaySuccess(false);
  };

  useEffect(() => {
    dispatch(getTeammemberAsync({}));
  }, [dispatch]);

  const handleSubmit = () => {
    setIsLoading(true);

    if (userPriviledge !== 3) {
      const datas = {
        slotId: data.id,
        reminders: reminder.map((val) => {
          return { minutesBefore: Number(val.value) };
        }),
        date: taskDate(startDate),
        assignees: store
          ?.filter((team) => personName.includes(team.id))
          .map((selectedMember) => ({
            userId: selectedMember.id,
            userName: selectedMember.name,
          })),
      };
      serviceClass
        .bookSlotWithAsignees(datas)
        .then((value) => {
          const weeklyHoursExceeded = value.data.weeklyHoursExceeded;
          if (weeklyHoursExceeded === true) {
            setOpenAlert(true);
            setAlertData(datas);
          } else {
            setOpenDisplaySuccess(true);
            toast.success('Successfully');
            setDisplayData(value.data);
            // reload calendar
            serviceClass
              .fetchShiftSlotAvailability(
                shiftStateWeeklyDate.from,
                shiftStateWeeklyDate.to
              )
              .then((value) => {
                setShiftSpots(value.data);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setIsError(true);
          setErrorMessage(err.response.data.message);
        });
    } else {
      const datas = {
        slotId: data.id,
        reminders: reminder.map((val) => {
          return { minutesBefore: Number(val.value) };
        }),
        date: taskDate(startDate),
      };
      serviceClass
        .bookSlot(datas)
        .then((value) => {
          const weeklyHoursExceeded = value.data.weeklyHoursExceeded;
          if (weeklyHoursExceeded === true) {
            setOpenAlert(true);
            setAlertData(datas);
          } else {
            // setOpenDisplaySuccess(true)
            toast.success('Successfully');
            // setDisplayData(value.data)
            // reload calendar
            serviceClass
              .fetchShiftSlotAvailability(
                shiftStateWeeklyDate.from,
                shiftStateWeeklyDate.to
              )
              .then((value) => {
                setShiftSpots(value.data);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setIsError(true);
          setErrorMessage(err.response.data.message);
        });
    }
  };

  const componentVariant = {
    hidden: { x: '50vw' },
    vissible: {
      x: 0,
      transition: { ease: 'easeIn', delay: 0, duration: 1 },
    },
    exit: {
      x: '50vw',
      transition: { ease: 'easeOut', delay: 0, duration: 1 },
    },
  };

  const handleAssigneeChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <React.Fragment key="right">
        <Alert
          openModal={openAlert}
          handleClose={handleCloseAlert}
          data={alertData}
        />
        <DisplaySuccess
          openModal={openDisplaySuccess}
          handleClose={handleCloseDisplaySuccess}
          data={displayData}
          extraFunction={() => handleCloseDrawer(false)}
        />
        <Drawer
          component={motion.div}
          variants={componentVariant}
          initial={'hidden'}
          animate={'vissible'}
          exit={'exit'}
          anchor="right"
          open={openDrawer}
          onClose={toggleDrawer(false)}
          hideBackdrop={true}
        >
            <Stack
              className="p-3"
              width={400}
              direction="column"
              mt={2}
              gap={2}
              sx={{ ...font }}
            >
              {/* first row */}
              <div className="flex items-center justify-between pl-5">
                <Typography variant="p" className="font-semibold text-sm">
                  Summary
                </Typography>

                <Button onClick={toggleDrawer(false)}>
                  <IoClose />
                </Button>
              </div>

              {/* second row */}
              <Stack
                className="border-1 border-grey-500 py-3 pb-14 px-3 rounded-[10px]"
                direction="column"
              >
                {data !== null ? (
                  <>
                    <Typography variant="p" className="text-base font-semibold">
                      {data.availability.staffBooked}/
                      {data.availability.maxStaffRequired} spaces booked.
                    </Typography>
                    <div style={{ marginTop: '15px' }}>
                      <label
                        style={{ fontSize: '13px', marginTop: '25px', ...font }}
                        className="text-sm font-semibold"
                      >
                        Location Name
                      </label>
                      <div
                        style={{
                          backgroundColor: '#f7f7f7',
                          padding: '12px',
                          marginTop: '5px',
                          fontSize: '14px',
                        }}
                        className="text-grey-800"
                      >
                        {data.branch.name}
                      </div>
                    </div>

                    <div style={{ marginTop: '11px' }}>
                      <label
                        style={{ fontSize: '13px', marginTop: '10px' }}
                        className="text-sm font-semibold"
                      >
                        Location address
                      </label>
                      <div
                        style={{
                          backgroundColor: '#f7f7f7',
                          padding: '12px',
                          marginTop: '5px',
                          fontSize: '14px',
                        }}
                        className="text-grey-800"
                      >
                        {data.branch.address}
                      </div>
                    </div>

                    <div style={{ marginTop: '11px' }}>
                      <label
                        style={{ fontSize: '13px', marginTop: '10px' }}
                        className="text-sm font-semibold"
                      >
                        Day Of The Week
                      </label>
                      <div
                        style={{
                          backgroundColor: '#f7f7f7',
                          padding: '12px',
                          marginTop: '5px',
                          fontSize: '14px',
                        }}
                        className="text-grey-800"
                      >
                        {
                          dayOfWeek.filter(
                            (val) => val.id === data.dayOfWeek
                          )[0].name
                        }
                      </div>
                    </div>

                    <div style={{ marginTop: '11px' }}>
                      <label
                        style={{ fontSize: '13px', marginTop: '10px' }}
                        className="text-sm font-semibold"
                      >
                        Time
                      </label>
                      <div
                        style={{
                          backgroundColor: '#f7f7f7',
                          padding: '12px',
                          marginTop: '5px',
                          fontSize: '14px',
                        }}
                        className="text-grey-800"
                      >
                        {data.startTime + ' - ' + data.endTime}
                      </div>
                    </div>

                    {userPriviledge !== 3 && (
                      <Stack>
                        <label
                          style={{ fontSize: '13px', marginTop: '10px' }}
                          className="text-sm font-semibold"
                        >
                          Assignee
                        </label>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          placeholder="I am here"
                          value={
                            personName.length > 0
                              ? personName
                              : []
                          }
                          onChange={handleAssigneeChange}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={(selected) => (
                             <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  style={{ borderRadius: '4px' }}
                                  key={value}
                                  label={
                                    store?.filter((team) => team?.id === value)[0]
                                      .name
                                  }
                                />
                              ))}
                            </Box>
                            )
                          }
                        >
                          {store.length > 0 ? store?.map((item) => (
                            <MenuItem key={item.id} value={item?.id}>
                              <Checkbox
                                checked={personName.indexOf(item?.id) > -1}
                              />
                              <ListItemText primary={item?.name} />
                            </MenuItem>
                          )): <p className='text-[14px] text-center'> No users from thesame branch and jobrole </p>}
                        </Select>
                      </Stack>
                    )}

                    <div style={{ marginTop: '10px' }}>
                      <label
                        style={{ fontSize: '13px', marginTop: '10px' }}
                        className="text-sm font-semibold"
                      >
                        Reminder
                      </label>
                      <div></div>
                      <div className="select" style={{ marginTop: '5px' }}>
                        <Slect
                          isMulti
                          name="colors"
                          options={reminderOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            setReminder(e);
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                      <label style={{ fontSize: '13px', marginTop: '10px' }}>
                        Day
                      </label>
                      <div
                        style={{
                          border: '1px solid #E6E6E6',
                          padding: '10px',
                          height: '21px',
                          marginTop: '5px',
                          fontSize: '14px',
                        }}
                        className="bg-light-gray border-1 p-2"
                      >
                        <DatePicker
                          style={{ padding: 0 }}
                          selected={startDate}
                          dateFormat="MM/dd/yyyy"
                          onChange={(date) => setStartDate(date)}
                          filterDate={(date) =>
                            date.getDay(date) === data.dayOfWeek
                          }
                          placeholderText="Select a Monday"
                          className="outline-none border-1 bg-light-gray text-grey-800"
                        />
                      </div>
                    </div>

                    {isError && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          borderRadius: '0.2rem',
                          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                          padding: '0.5rem',
                          marginTop: '1rem',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'red',
                            textAlign: 'center',
                          }}
                        >
                          {errorMessage}
                        </Typography>
                        <MdOutlineCancel
                          style={{
                            color: 'red',
                            fontSize: '23px',
                            cursor: 'pointer',
                          }}
                          onClick={() => setIsError(false)}
                        />
                      </Box>
                    )}

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mt={6}
                    >
                      <Button
                        sx={{
                          textTransform: 'none',
                          color: '#242424',
                          borderColor: '#242424',
                          ...font,
                          ':hover': { borderColor: 'rgba(0,0,0,0.2)' },
                        }}
                        variant="outlined"
                        onClick={toggleDrawer(false)}
                      >
                        Cancel
                      </Button>

                      {!isLoading && (
                        <Button
                          sx={{
                            textTransform: 'none',
                            backgroundColor: '#242424',
                            color: 'white',
                            ...font,
                            ':hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
                          }}
                          variant="contained"
                          onClick={handleSubmit}
                        >
                          Book shift
                        </Button>
                      )}

                      {isLoading && <CircularProgress />}
                    </Stack>
                  </>
                ) : (
                  <center>
                    <div
                      style={{
                        width: '25vh',
                        height: '25vh',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '100%',
                        marginTop: '10vh',
                        backgroundColor: '#F9F9F9',
                      }}
                    >
                      <img src={mask} alt="mask" width="40%" />
                    </div>

                    <p style={{ fontSize: '14px' }}>An empty field</p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '13vh',
                        color: '#A5A5A5',
                      }}
                    >
                      You selected an empty slot
                    </p>
                  </center>
                )}
              </Stack>
            </Stack>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
