import React,{useState} from 'react';
import {Box,Stack,Typography, Button, CircularProgress} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import {FaTimes} from 'react-icons/fa'
import DoubleArrow  from '../../../../Assets/double-arrows.png'
import {Shift} from '../../../../Network/ServiceClass/Shift'
import {setSuccessAlert} from '../../../../Store/Company'
import {setOpenDrawer, setRequetsOffers} from '../../../../Store/swapOffers'
import successImage from '../../../../Assets/success_circle.png'
import {font} from '../../../Widgets/Styles/font'
import {MdOutlineCancel} from 'react-icons/md'
import { FillButton, OutlinedButton } from '../../../Widgets/FormUtils/FormUtils';


const shift = new Shift()

export default function TemporaryDrawer() {
    const dispatch = useDispatch()
    const privilege =
    useSelector((state) => state?.company)?.privilege || 3;  

    const user = JSON.parse(localStorage.getItem("user"))
    const openDrawerState = useSelector((state)=> state.swapOffers.openDrawer)

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(setOpenDrawer({state: open, data: null}))
  };

  const [isLoadingAcceptRequest, setIsLoadingAcceptRequest] = useState(false)
  const [isLoadingRejectRequest, setIsLoadingRejectRequest] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')


  const handleAcceptRequest = () => {
    setIsLoadingAcceptRequest(true)

    shift.acceptSwapRequest(openDrawerState.data.id).then(value => {
      setIsLoadingAcceptRequest(false)

      console.log(value.data)
      if(value.status !== 200) throw new Error('Something went wrong')
      dispatch(setSuccessAlert({
        status: true,
        title: 'Swap made',
        content: 'Your swap has been confirmed',
        image: successImage
      }))
      dispatch(setOpenDrawer({state: false, data:null}))

      shift.fetchSwapRequest('pending').then(value => {
        dispatch(setRequetsOffers(value.data))
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      setIsLoadingAcceptRequest(false)
      if (error.response.status === 404){
        setIsError(true)
        setErrorMessage('One of the shift no longer exist')

      }else {
        setIsError(true)
        setErrorMessage('One of the shift no longer exist')
      }
    })
  }

  const handleRejectRequest = () => {
    setIsLoadingRejectRequest(true)

    shift.rejectSwapRequest(openDrawerState.data.id).then(value => {
      console.log(value.data)
      setIsLoadingRejectRequest(false)
      if(value.status !== 200) throw new Error('Something went wrong')
      dispatch(setSuccessAlert({
        status: true,
        title: 'Swap rejected',
        content: 'Your have successfully rejected the swap',
        image: successImage
      }))
      dispatch(setOpenDrawer({state: false, data:null}))

      shift.fetchSwapRequest('pending').then(value => {
        dispatch(setRequetsOffers(value.data))
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      setIsLoadingRejectRequest(false)
      console.log(error)
      if (error.response.status === 404){
        setIsError(true)
        setErrorMessage('One of the shift no longer exist')

      }else {
        setIsError(true)
        setErrorMessage('One of the shift no longer exist')
      }
    })
  }

  return (
    <div className='bg-[red]'>
        <React.Fragment key='right'>
          <Drawer
            anchor='right'
            open={openDrawerState.state}
            onClose={toggleDrawer(false)}
            hideBackdrop={true}
          >
            {(openDrawerState.data !== null) &&
            <div 
              className='w-[529px] h-[100vh] flex flex-col dark:bg-292 dark:text-white'
            >
                {/* row 1 */}
                <Stack 
                    direction='row'
                    spacing={24}
                    alignItems='center'
                    justifyContent='center'
                    ml={22}
                    mt={2}
                >
                    <Typography sx={{...font, fontSize: '18px', fontWeight: '600'}}>Swap Details</Typography>

                    <Box sx={{cursor: 'pointer'}} onClick={toggleDrawer(false)}><FaTimes/></Box>
                </Stack>

                <Stack px={4} mt={3} direction='column' spacing={3}>

                    {/* row 2 */}
                    <Stack direction='column'>
                        <p className='text-[12px] text-[#A5A5A5]'>Your shift</p>
                        
                        <div 
                          className='flex flex-col border-1 border-[#E4E9FD] dark:border-969 rounded-md gap-2 p-4'
                        >
                            <TitleSubTitle title="Employee name" subTitle={`${openDrawerState.data.requestee.firstName} ${openDrawerState.data.requestee.lastName}`}/>
                            <TitleSubTitle title="Email" subTitle={openDrawerState.data.requestee.email}/>
                            <TitleSubTitle title="Location" subTitle={openDrawerState.data.branch.address}/>

                        </div>

                    </Stack>

                    {/* row 3 */}
                    <Stack direction='row' justifyContent='center'>
                        <img src={DoubleArrow} alt="" />
                    </Stack>

                    {/* row 4 */}
                    <div 
                          className='flex flex-col border-1 border-[#E4E9FD] dark:border-969 rounded-md gap-2 p-4'
                      >
                        <TitleSubTitle title="Employee name" subTitle={`${openDrawerState.data.requester.firstName} ${openDrawerState.data.requester.lastName}`}/>
                        <TitleSubTitle title="Email" subTitle={openDrawerState.data.requester.email}/>
                        <TitleSubTitle title="Location" subTitle={openDrawerState.data.branch.address}/>

                    </div>

                    {isError && 
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '0.2rem',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                        padding: '0.5rem'
                      }}>
                        <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                        <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
                      </Box>}

                    
                    {/* row 5 */}
                   {((openDrawerState?.data?.requestee?.id === user?.id) || [1,2].includes(privilege)) &&( <Stack direction='row' justifyContent='center' spacing={4}>
                      

                      {!isLoadingRejectRequest && <OutlinedButton
                        text={'Decline'}
                        callBack={handleRejectRequest}
                      />}

                      {isLoadingRejectRequest && <CircularProgress/> }



                      {!isLoadingAcceptRequest && <FillButton
                        text={'Accept'}
                        callBack={handleAcceptRequest}
                      />}

                      {isLoadingAcceptRequest && <CircularProgress/> }
                    </Stack>)}
                </Stack>

            </div>}
          </Drawer>
        </React.Fragment>
    </div>
  );
}

const TitleSubTitle = ({title, subTitle}) => {
    return(
        <Stack direction='column' spacing={0.5}>
            <p className='text-[12px] text-[#242424] dark:text-gray-200'>{title}</p>
            <p className='text-[16px]'>{subTitle}</p>

        </Stack>
    )
}
