import React from 'react'
import { Box } from '@mui/material'
import {GrLocation} from 'react-icons/gr'
import dayjs from 'dayjs'

const ShiftForOfferView = ({requesteeShift, requesteeBranch}) => {
    const requesteeDate = new Date(requesteeShift.start.split('T')[0]).toString().split(' ')
   
  return (
    <div 
        className='w-[433px] h-[88px] border-1 border-[#E4E9FD] dark:border-969 flex items-center px-2 gap-3 rounded-md'
    >

        {/* Column 1 */}
        <div
            className= 'w-[62px] h-[50px] border-1 border-[#E4E9FD] dark:border-969 text-center flex flex-col justify-center items-center'
        >
            
            <p className='text-[16px] dark:text-white'>{requesteeDate.slice(2,3).join(' ')}</p>
            <p className='text-[12px] dark:text-white'>{requesteeDate.slice(0,2).join(' ')}</p>
        </div>

        {/* Column 2 */}
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem', width: '300px'}}>
            {/* row 1 */}
            <div className='flex gap-4 text-[12px]'>
                <p className=" dark:text-white" >Star time, {dayjs(requesteeShift?.start).format('h:mm:ss A')}</p>
                <p className=' dark:text-white'>End time, {dayjs(requesteeShift?.end).format('h:mm:ss A')}</p>
            </div>

            {/* row 2 */}
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <GrLocation className='text-black dark:text-white' />
                <p className='text-[12px] dark:text-white'>{requesteeBranch.address}</p>
            </Box>

        </Box>

    </div>
  )
}

export default ShiftForOfferView