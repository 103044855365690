import React, {useEffect, useState} from 'react'
import { IoIosArrowForward,IoIosArrowBack } from "react-icons/io";
import {Box} from '@mui/material'
import {setWeeklyDate,setWeeklyDateLabel} from '../../../../../Store/Shift'
import {useDispatch} from 'react-redux'
import moment from 'moment';


const CustomNavigateCalender = ({dates, diff = 7, layoutProps={}, sx={}, buttonStyles = 'py-3 px-3 border-1 cursor-pointer', bodyPadding='0.55rem 1.2rem', bodyHeight='40px'}) => {
    const dispatch = useDispatch()
   
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(moment().utc().startOf('week').format("yyyy-MM-DD")) 
    const [lastDayOfWeek, setLastDayOfWeek] = useState(moment().utc().endOf('week').format("yyyy-MM-DD"))
    
    useEffect(()=>{
        const firstDayOfWeek = moment().utc().startOf('week').format("yyyy-MM-DD")
        const lastDayOfWeek =  moment().utc().endOf('week').format("yyyy-MM-DD")
  
  
        dispatch(setWeeklyDate({
          to: moment(lastDayOfWeek).format("yyyy-MM-DD"),
          from: moment(firstDayOfWeek).format("yyyy-MM-DD"),
      }))
      dispatch(setWeeklyDateLabel({
          to: moment(lastDayOfWeek).format("yyyy-MM-DD"),
          from: moment(firstDayOfWeek).format("yyyy-MM-DD"),
      }))
      },[])
    
    useEffect(()=>{
        dispatch(setWeeklyDate({
            to: moment(lastDayOfWeek).format("yyyy-MM-DD"),
            from: moment(firstDayOfWeek).format("yyyy-MM-DD"),
        }))
        dispatch(setWeeklyDateLabel({
            to: moment(lastDayOfWeek).format("yyyy-MM-DD"),
            from: moment(firstDayOfWeek).format("yyyy-MM-DD"),
        }))
       
    },[firstDayOfWeek,lastDayOfWeek, dates.length])

    const handleNextMonth = () => {
        const nextFirstDay = moment(lastDayOfWeek).add(1, 'days').format("yyyy-MM-DD")
        const nextLastDay = moment(nextFirstDay).add(6, 'days').format("yyyy-MM-DD")
        setFirstDayOfWeek(nextFirstDay)
        setLastDayOfWeek(nextLastDay)
    }

    const handlePrevMonth = () => {
        const nextFirstDay = moment(firstDayOfWeek).subtract(diff, 'days').format("yyyy-MM-DD")
        const nextLastDay = moment(nextFirstDay).utc().add(diff, 'days').format("yyyy-MM-DD")
        setFirstDayOfWeek(nextFirstDay)
        setLastDayOfWeek(nextLastDay)
    }

    
  return (
    <div className='text-[#6d6e72] flex flex-row items-center rounded border-[1px] border-[#c2c2c2] dark:border-333'>
        <Box className={`${buttonStyles} rounded-tl rounded-bl border-0 border-r dark:border-333`} onClick={handlePrevMonth}>
            <IoIosArrowBack/>
        </Box>

        <Box 
        className={`dark:text-white ${layoutProps?.subHeaderClass}`} 
        sx={{padding: bodyPadding, height: bodyHeight, color:'black', width: 'max-content', fontSize:"11px", display:'flex', alignItems:'center', borderLeft:'none', borderRight:'none', ...sx}} >
            <p className={`${layoutProps?.subHeaderClass}`} >{moment(firstDayOfWeek).format("MMM DD")} - {moment(lastDayOfWeek).format("MMM DD")}</p>
        </Box>

        <Box className={`${buttonStyles} rounded-tr rounded-br border-0 border-l dark:border-333`} onClick={handleNextMonth}>
            <IoIosArrowForward/>
        </Box>
    </div>
  )
}


export default CustomNavigateCalender