import { FiSearch } from "react-icons/fi";

const SearchInput = ({placeholder = 'Search', onChange, className='', inputStyle='', iconStyle=''}) => {
  return (
    <div>
      <div
        className={`flex items-center p-[10px] px-3 border-1 border-5A5 dark:bg-828 dark:border-333 rounded-lg ${className}`}
      >
        <FiSearch className={`${iconStyle} font-medium dark:text-grey-600`}/>
        <input
          type="text"
          className={`ml-[10px] placeholder:text-sm placeholder:font-medium outline-none dark:bg-828 dark:text-white border-0 placeholder:text-grey-600 ${inputStyle}`}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SearchInput;
