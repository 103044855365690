import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../BranchScreen/Tabs/CustomerSettings/CustomersSettings.css';
import { useFormik } from 'formik';

import InitialsGen from '../../Widgets/InitialsGen';
import editIcon from '../../../Assets/edit.png';
import {
  TextAndField,
  Field,
  Text,
  FieldSelect,
  FillButton,
} from '../../Widgets/FormUtils/FormUtils';
import PictureModal from '../BranchScreen/Tabs/CustomerSettings/Modal/PictureModal';
import { Home as HomeService } from '../../../Network/ServiceClass/Home';
import PopError from '../../Widgets/PopError';
import { toast } from 'react-toastify';
import AppLayout from '../../Widgets/AppLayout';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { companyDataTutorial } from '../mock';

const className = "dark:text-white dark:border-333 dark:border dark:border-solid"

export const CompanyDetails = ({ edit, setEdit }) => {
  const homeService = new HomeService();

  const activecompany = useSelector((state) => state.company.activeCompany);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const handlClosePictureModal = () => {
    setOpenPictureModal(false);
  };

  const [countryList, setCountryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    homeService.getCountries().then((val) => {
      setCountryList(val.data);
    });
    homeService.getCategories().then((val) => {
      setCategoryList(val.data);
    });
  }, []);

  const [stateList, setStateList] = useState([]);
  const getState = (countryId) => {
    homeService.getState(countryId).then((val) => {
      setStateList(val.data);
    });
  };

  const [cityList, setCityList] = useState([]);
  const getCities = (stateId) => {
    homeService.getCity(stateId).then((val) => {
      setCityList(val.data);
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const formik = useFormik({
    initialValues: {
      name: activecompany.name,
      teamSize: activecompany.teamSize,
      categoryId: 'none',
      ein: activecompany.ein,
      countryId: 'none',
      address: activecompany.address,
      cityId: 'none',
      stateId: 'none',
      zipCode: activecompany.zipCode,
      writeUp: activecompany.writeUp,
    },
    onSubmit: (values) => {
      setIsLoading(true);
      homeService
        .editCompany(values)
        .then((val) => {
          setIsLoading(false);
          toast.success('Edit successful');
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          setErrMsg(
            err.response.status === 500
              ? 'Fill out all filled'
              : err.response.data.message
          );
        });
    },
  });

  return (
      <AppLayout
      custom="company"
      subCustom="company-details"
      title="Company Details"
      customTitle=" "
      subtitle="Check and manage the settings of the company"
      RightComponent={<TutorialPanel items={companyDataTutorial} />}
      >

        <form className="mt-10">
          <PictureModal
            open={openPictureModal}
            handleClose={handlClosePictureModal}
          />

          <div className="flex gap-10">
            <div className="w-[100px] h-[100px] border-1 rounded-full relative">
              {activecompany.logo === undefined || activecompany.logo === null ? (
                <InitialsGen width={'100%'} name={`${activecompany?.name} `} />
              ) : (
                <img
                  src={activecompany?.logo?.url}
                  alt="logo"
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: 'cover',
                    borderRadius: '100%',
                    height: '100px',
                  }}
                />
              )}

              <img
                className="absolute cursor-pointer right-0 bottom-3"
                src={editIcon}
                alt="user_pix"
                onClick={() => setOpenPictureModal(true)}
              />
            </div>

            <div className="w-[400px]">
              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'Company Name'} />
                <CustomField edit={edit} name={'name'} formik={formik} />
              </TextAndField>
            </div>
          </div>

          <div className="flex gap-10 mt-10">
            <div className="flex flex-col gap-10">
              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'Category of company '} />
                {!edit && (
                  <CustomFieldNoFormik
                    edit={edit}
                    value={activecompany.category.name}
                  />
                )}

                {edit && (
                  <FieldSelect
                    width="400px"
                    size="large"
                    height="55px"
                    list={categoryList}
                    className={className}
                    value={formik.values.categoryId}
                    name={'categoryId'}
                    formik={formik}
                    placeholder="Choose Category"
                  />
                )}
              </TextAndField>

              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'Country code'} />
                {!edit && (
                  <CustomFieldNoFormik
                    edit={edit}
                    value={activecompany.country.name}
                  />
                )}

                {edit && (
                  <FieldSelect
                    width="400px"
                    size="large"
                    height="55px"
                    list={countryList}
                    className={className}
                    value={formik.values.countryId}
                    name={'countryId'}
                    formik={formik}
                    callback={(event) => {
                      const {
                        target: { value },
                      } = event;
                      formik.setFieldValue('countryId', value);
                      getState(value);
                    }}
                    placeholder="Choose Country"
                  />
                )}
              </TextAndField>

              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'State'} />
                {!edit && (
                  <CustomFieldNoFormik
                    edit={edit}
                    value={activecompany.state.name}
                  />
                )}

                {edit && (
                  <FieldSelect
                    width="400px"
                    size="large"
                    height="55px"
                    list={stateList}
                    value={formik.values.stateId}
                    name={'stateId'}
                    formik={formik}
                    className={className}
                    callback={(event) => {
                      const {
                        target: { value },
                      } = event;
                      formik.setFieldValue('stateId', value);
                      getCities(value);
                    }}
                    placeholder="Choose State"
                  />
                )}
              </TextAndField>

              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'City'} />
                {!edit && (
                  <CustomFieldNoFormik
                    edit={edit}
                    value={activecompany.city.name}
                  />
                )}

                {edit && (
                  <FieldSelect
                    width="400px"
                    size="large"
                    height="55px"
                    list={cityList}
                    className={className}
                    value={formik.values.cityId}
                    name={'cityId'}
                    formik={formik}
                    placeholder="Choose City"
                  />
                )}
              </TextAndField>

              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'Write up'} />
                <CustomField
                  edit={edit}
                  name={'writeUp'}
                  multiline="true"
                  rows={3}
                  formik={formik}
                />
              </TextAndField>
            </div>

            <div className="flex flex-col gap-10">
              <TextAndField>
                <Text
                className='dark:text-white'
                  size="16px"
                  weight="600"
                  text={'Total amount of team members'}
                />
                <CustomField edit={edit} name={'teamSize'} formik={formik} />
              </TextAndField>

              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'Company address'} />
                <CustomField edit={edit} name={'address'} formik={formik} />
              </TextAndField>

              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'Zip code '} />
                <CustomField edit={edit} name={'zipCode'} formik={formik} />
              </TextAndField>

              <TextAndField>
                <Text className='dark:text-white' size="16px" weight="600" text={'EID number'} />
                <CustomField edit={edit} name={'ein'} formik={formik} />
              </TextAndField>

              <div />

              <FillButton
                text={isLoading ? 'Saving changes...' : 'Save changes'}
                callBack={formik.handleSubmit}
                disable={isLoading}
              />
              {isError && (
                <PopError
                  message={errMsg}
                  handleClick={() => setIsError(false)}
                />
              )}
            </div>
          </div>
        </form>
      </AppLayout>
   
  );
};

const CustomField = ({ name, formik, edit, multiline = 'false', rows = 0 }) => {
  return (
    <Field
      name={name}
      border={edit}
      bg={!edit ? '#F5F5F5' : 'white'}
      disable={localStorage.getItem("color-theme") === "dark" ? false : !edit}
      className={className}
      size="large"
      fontSize="16px"
      formik={formik}
      width={'400px'}
      value={formik.values[name]}
      multiline={multiline}
      maxRows={rows}
    />
  );
};

const CustomFieldNoFormik = ({
  value,
  edit,
  multiline = 'false',
  rows = 0,
}) => {
  return (
    <Field
      border={edit}
      bg={!edit ? '#F5F5F5' : 'white'}
      disable={localStorage.getItem("color-theme") === "dark" ? false : !edit}
      className={className}
      size="large"
      fontSize="16px"
      width={'400px'}
      value={value}
      multiline={multiline}
      maxRows={rows}
    />
  );
};
