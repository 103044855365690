import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomPopper } from '../../Widgets/CustomPopper'
import { ClickableItem } from '../../Widgets/ClickableItem'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import {getInventoryPendingOrdersThunk } from '../../../Store/Inventory'
import { Storage } from '../../../Network/StorageClass/StorageClass'
import { toast } from 'react-toastify'
import CurrencyFormat from 'react-currency-format'
import { approveOrderRequest  } from '../../../Network/ServiceClass/InventoryService'
import ExportTemplate from './Widget/ExportTemplate'

const storage = new Storage()
const PendingOrder = ({statusColor, branches, formatStatus}) => {
    const dispatch = useDispatch()

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id

    const [toggleMenu, setToggleMenu] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})
    const [menuIndex, setMenuIndex] = useState(null)


    const pendingOrders = useSelector(state => state.inventory)?.pendingOrders
   

    const getPendingOrder = (id=null) => {
        dispatch(getInventoryPendingOrdersThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getPendingOrder(withdrawalBranch.value)
    },[withdrawalBranch])
    

    const handleApprove = (value) => {
        setIsLoadingAction(true)

        approveOrderRequest({id: value?.orderIdentifier, status:'ordered'})
        .then(val => {
            if (val?.status === 200){
                toast.success('Approved Successfully')
                setToggleMenu(null)
                getPendingOrder(withdrawalBranch.value)
            }else toast.error(val?.data?.message)
            setIsLoadingAction(false)
        })
    }
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Pending Order Request'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={pendingOrders.map(x => ({
                    'Reference Number': x?.referenceNumber,
                    'Total Value': `$${x?.items.length === 0 ? 0 : x?.items.reduce((accumulator, currentValue) => accumulator + currentValue.packageCost, 0)/100}`,
                    'Ordered By': `${x?.teamMember?.firstName} ${x?.teamMember?.lastName}`,
                    'Location': x?.branch?.name,
                    'Number Of Items': x?.items?.length,
                    'Unit': x?.UnitName,
                    'Status': x?.status

                }))}
                fileName={'pending-orders'}
            />


            <div className='rounded-md border-1 dark:border-333 dark:text-white mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className=''>
                <tr className='bg-white dark:bg-444 font-bold sticky top-0'>
                    <td className='border-1 dark:border-333 border-t-0 border-l-0 text-center pl-4 py-4 rounded-tl-md '>Ref No.</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Total Value</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Ordered By</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Location</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>No. of items</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Unit</td>
                    <td className='border-1 dark:border-333 border-t-0 border-r-0 text-center rounded-tr-md'>Status</td>

                </tr>

                {!isLoading ? <tbody>
                    {pendingOrders?.length !== 0 ?
                        pendingOrders.map(val => (
                            <tr className='border-1 dark:border-333 dark:bg-828 border-l-0 text-A5A dark:text-white font-bold'>
                                    <td className='text-center border-r-1 dark:border-333 py-4 pl-4'>{val?.referenceNumber}</td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                        <CurrencyFormat
                                            className='dark:text-white'
                                            value={val?.items.length === 0 ? 0 : val?.items.reduce((accumulator, currentValue) => accumulator + currentValue.packageCost, 0)/100} // The numeric value you want to format
                                            displayType={'text'} // You can also use 'input' for input fields
                                            thousandSeparator={true} // Use commas as thousands separators
                                            prefix={'$'} // Currency symbol or prefix
                                            decimalScale={2} // Number of decimal places
                                        />
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.teamMember?.firstName} {val?.teamMember?.lastName}</td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                        <div className='flex items-center justify-center gap-2'>
                                            <p className='w-2 h-2 rounded-full' style={{background: val?.branch?.hexcode}}/>
                                            <p>{val?.branch?.name}</p>
                                        </div>
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.items?.length}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.UnitName}</td>

                                    <td className='text-center'>
                                        {isAllow() ? 
                                            <div 
                                                onClick={(e)=> {
                                                    e.stopPropagation()
                                                    !isLoadingAction && setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                    !isLoadingAction && setMenuIndex(val?.id)
                                                }}
                                                className={`cursor-pointer text-center ${statusColor[val?.status]?.color}`}>
                                                {formatStatus(val?.status)}
                                            </div>:

                                            <div 
                                                className={`text-center ${statusColor[val?.status]?.color}`}>
                                                {formatStatus(val?.status)}
                                            </div>
                                        
                                        }

                                        {menuIndex === val?.id &&
                                            <CustomPopper 
                                                openPopper={toggleMenu} 
                                                handleClose={()=>{
                                                    !isLoadingAction && setToggleMenu(null)
                                                }} className='z-[1500]' placement='bottom'>
                                                <div className='min-h-[50px] min-w-[150px] mt-1 py-3 font-bold bg-white shadow-shift-shadow-small'>

                                                    {!isLoadingAction ? <div className='flex flex-col gap-2 text-[#738583] text-[12px]'>

                                                        <ClickableItem 
                                                            className={'py-1 text-[black]'} 
                                                            text={'Approve Request'} 
                                                            handleClick={()=> handleApprove(val)}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="flex h-full items-center justify-center"><CircularProgress/></div>
                                                    }
                                                
                                                </div>          
                                            </CustomPopper>
                                        }
                                        
                                    </td>

                            </tr>

                        ))
                        :<tr>
                            <td colSpan={7} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                    }
                </tbody>:
                <tr>
                    <td colSpan={7} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                </tr>
                }
                
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default PendingOrder