import React,{useState,useEffect} from 'react'
import NavigateCalender from '../../../ShiftScreen/Widget/NavigateCalender/NavigateCalender';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from '@ant-design/plots';
import {setWorkHours} from '../../../../../Store/dashboard'
import {getWorkedHours} from '../../../../../Network/ServiceClass/dashboardService'
import { CircularProgress } from '@mui/material';
import placeHolderPic1 from '../../../../../Assets/placholders/placeholder1.png'
import PlaceHolderOne from '../../../../Widgets/placeholders/PlaceHolderOne';



const WorkHours = () => {
    const dispatch = useDispatch()
    const today = new Date().toDateString().split(' ')[0]

    const hourState = useSelector(state => state.dashboard.workHours)
    const weeklyDate = useSelector(state => state.shift.weeklyDate)

    const data = hourState.map(val => {
        return {
            date: new Date(val.date).toDateString().split(' ')[0],
            hours: val.hours
        }
    })

    const sumHours = data.reduce((a,b) => {return a + b.hours},0)

    const config = {
        data,
        xField: 'date',
        yField: 'hours',
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        meta: {
          date: {
            alias: 'kfkf',
          },
          hours: {
            alias: 'Employees available',
          },
        },
        minColumnWidth: 25,
        maxColumnWidth: 25,
         columnStyle: {
              radius: [4,4,4,4],
            },
        colorField: 'date', // or seriesField in some cases
        color: ({ date}) => {
            switch (today) {
                case date:
                    return '#2F5C01';
                    break;

                default:
                    return '#E3EFD7';
                    break;
            }
           
        }   
      };

      const [isLoading, setIsLoading] = useState(false)

      useEffect(()=> {
        if(weeklyDate.from !== ''){
            setIsLoading(true)
            getWorkedHours(weeklyDate.from, weeklyDate.to).then(val => {
                setIsLoading(false)
                dispatch(setWorkHours(val))
            }).catch(err => setIsLoading(false))
        }

      },[weeklyDate])
  return (
    <div className='w-[57%] p-3 rounded-lg gap-3 flex flex-col shadow-shift-shadow'>
        <div className='flex gap-10'>
            <p className='dashboard-section-dropdown dark:text-white'>Hours worked</p>
            <NavigateCalender diff={7} buttonStyles='py-1 px-1 border-1 cursor-pointer' bodyPadding='10px 30px' bodyHeight='26px'/>
            
        </div>
        {(!isLoading && sumHours > 0) && <Column {...config} />} 
        {(!isLoading && sumHours === 0) && <div className='mt-6'> <PlaceHolderOne showPlaceholder={false} /></div>}
       
        {isLoading && <div className='w-[100%] flex justify-center mt-3'><CircularProgress/></div>}

    </div>
  )
}

export default WorkHours