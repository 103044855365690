import React, {useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import { Shift } from '../../../../../Network/ServiceClass/Shift'
import { CircularProgress } from '@mui/material';
import { setSuccessAlert } from '../../../../../Store/Company'
import { setRoaster } from '../../../../../Store/Shift'
import {useDispatch,useSelector} from 'react-redux'
import AlertDialog from '../../../../Widgets/SuccessDialog';
import {Box,Typography, Button} from '@mui/material'
import {MdOutlineCancel} from 'react-icons/md'
import dot from '../../../../../Assets/dot.png'
import Slect from 'react-select';
import checkPmOrAm from '../../../../../utils/checkPmOrAm';
import {font} from '../../../../Widgets/Styles/font'
import Menu from '@mui/material/Menu';
import {IoClose} from 'react-icons/io5'
import {TextAndField, Text, OutlinedButton, FillButton} from '../../../../Widgets/FormUtils/FormUtils'
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const shift = new Shift();

export default function MakeOfferView({openMakeOfferViewModal, handleCloseMakeOffer, loggedInUserDetails, data}) {

  const [singleUserShift, setSingleUserShift] = useState([])
  const [selectedShift, setSelectedShift] = useState('')
  const [isSelected, setIsSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const open = Boolean(openMakeOfferViewModal);

  useEffect(()=>{
    // get all loggedIn user shift
    shift.fetchViewShiftSchedule(loggedInUserDetails).then((value)=>{

      const result = value.data.map((val)=>{
        const startTime = Number(val.slot.startTime.split(':')[0])
        const endTime = Number(val.slot.endTime.split(':')[0])
        const startAMOrPM = checkPmOrAm(startTime)
        const endAMOrPM = checkPmOrAm(endTime)
        return {
          label: `${val.start.split('T')[0]}, ${startTime}${startAMOrPM}-${endTime}${endAMOrPM}`,
          value: val.id
        }
      })
      setSingleUserShift(result)
    }).catch((error)=>{
      console.log(error)
    })
  },[])

  const handleCloseModal = () => {
    handleCloseMakeOffer()
  }

  const handleSwap = () => {
   
    setIsLoading(true)
    const datas = {
      "requesteeShiftId": data.id,
      "requesterShiftId": selectedShift
    }


    shift.makeSwapRequest(datas).then((value) => {
      setIsLoading(false)
      handleCloseModal()
      toast.success('Your offer has been sent, give your co worker sometime to respond')
    }).catch(error => {
      setIsLoading(false)
      console.log(error)
      if (error.response.status === 400){
        setIsError(true)
        setErrorMessage(error.response.data.message)

      }
    })
  }
  const mainAnim ={
    hidden: {opacity: 0},
    vissible: {
      opacity: 1,
      transition: {ease: 'easeIn', duration: 1}
    },
    exit: {
      opacity: 0,
      transition: {ease: 'easeIn', duration: 1}
    }
  }

 
  return (
    <div>
      <Menu
       component={motion.div}
       variants={mainAnim}
       initial={'hidden'}
       animate={'vissible'}
       exit={"exit"}
      id="basic-menu"
      anchorEl={openMakeOfferViewModal}
      open={open}
      onClose={handleCloseModal}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        style: { borderRadius: '10px', boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)' }   }}
      sx={{
        width: '50%',
        marginLeft: '35rem',
        marginRight: '35rem',

        height: '400px',
        
      }}
    >
        <Box sx={{
          width: '310px',
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 1.5rem',
          ...font

        }}>
          {/* first row */}
            <Box sx={{
             alignSelf: 'flex-end',
             marginTop: '-18px',
             marginRight: '6px',
             borderRadius: '20px',
             padding: '2px 4px',
             position: 'fixed',
             background: 'white',
             cursor:'pointer',
             
            }}onClick={handleCloseModal}>
              <IoClose style={{marginTop:'0.2rem', color: '#242424', fontSize:'23px',}} />

            </Box>

            <Box sx={{display: 'flex', marginTop: '20px', flexDirection: 'column', gap: '1rem'}}>
              {/* second row */}
              <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
            
                  <Box sx={{marginTop: '0.4rem', marginRight:'0.2rem'}}>
                      <img src={dot} alt="" />
                  </Box>

                  <Typography sx={{...font, fontSize: '18px', color: '#242424', marginRight: '1rem', fontWeight: '400'}}>Swap Shift</Typography>
              </Box>
  
              {/* Third row */}
              <Typography sx={{...font, fontSize: '14px',color:'#696969'}}>What shift would you like to offer for this swap</Typography>
            
              {/* Fouth row */}
              <TextAndField space={0.5}>
                {/* <Typography sx={{...font, fontSize: '14px', color: '#242424'}}>Swap offer</Typography> */}
                <Text text={'Swap offer'}/>
                <Slect
                    className="text-[10px]"
                    classNamePrefix="select"
                    defaultValue=''
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    placeholder='Choose shift to offer'
                    options={singleUserShift}
                    onChange={(e)=>{setSelectedShift(e.value); setIsSelected(true)}}
                />
              </TextAndField>

              {isError && 
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '0.2rem',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                padding: '0.5rem'
              }}>
                <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
              </Box>}

            </Box>
              {/* Fifth row */}
              <Box sx={{marginTop: '4rem', display: 'flex', justifyContent: 'space-between', marginTop:'12rem'}}>
                <OutlinedButton text={'Cancel'} callBack={handleCloseModal}/>
                {!isLoading && <FillButton text={'Swap'} disable={!isSelected} callBack={handleSwap}/> }
                {isLoading && <CircularProgress sx={{marginLeft: '1.2rem', marginRight: '1.2rem'}}/> }
              </Box>

        </Box>
      </Menu>
    </div>
  );
}