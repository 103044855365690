import React,{useState} from 'react';
import dot from '../../../../../Assets/dot.png'
import {AiOutlineDelete} from 'react-icons/ai'
import {IoMdAlarm} from 'react-icons/io'
import {BsArrowsAngleContract} from 'react-icons/bs'
import {CgTrashEmpty} from 'react-icons/cg'
import {FiEdit2} from 'react-icons/fi'
import {GrAdd} from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux';
import {setToggleShiftDetails, setSwappableShift, setRoaster} from '../../../../../Store/Shift'
import DeleteShift from './deleteShift';
import { Box, Typography, Switch, CircularProgress, Button} from '@mui/material';
import { Shift } from '../../../../../Network/ServiceClass/Shift';
import { Storage } from '../../../../../Network/StorageClass/StorageClass';
import MakeOfferView from './makeOfferView';
import EditShift from './editShift';
import {font} from '../../../../Widgets/Styles/font'
import {IoClose} from 'react-icons/io5'
import Menu from '@mui/material/Menu';
import {OutlinedButton, FillButton} from '../../../../Widgets/FormUtils/FormUtils'
import { motion, AnimatePresence } from 'framer-motion';



const storage = new Storage()
const shift = new Shift()

export default function ShiftDetails({}) {
  const loggedInUserDetails = storage.getUserDetails()

  const dispatch = useDispatch()
  const shiftToggleDetailState = useSelector((state)=> state.shift.toggleShiftDetails)
  const shiftStateWeeklyDate = useSelector((state) => state.shift.weeklyDate)
  const selectedBranchState = useSelector((state)=>state.shift.selectedBranch)

  const data = shiftToggleDetailState.data
  const detailData = data[0]
  const firstName = shiftToggleDetailState.user.firstName
  const lastName = shiftToggleDetailState.user.lastName
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openMakeOfferViewModal, setOpenMakeOfferViewModal] = useState(false)
  const [openEditShift, setOpenEditShift] = useState(false)

  const [checked, setChecked] = useState(detailData === undefined ? false : detailData.swappable);
  const [isLoadingMakeSwap, setIsLoadingMakeSwap] = useState(false)


  const handleChange = (event) => {
    setIsLoadingMakeSwap(true)
    const shiftId = shiftToggleDetailState.data[0].id
    setChecked(event.target.checked);
    const data = {
      shiftId,
      value: event.target.checked
    }
    shift.makeReadyForSwap(data).then((data)=>{
      setIsLoadingMakeSwap(false)

      // update roaster
      shift.fetchRoaster(selectedBranchState,shiftStateWeeklyDate.to, shiftStateWeeklyDate.from, 1).then((value)=>{
        dispatch(setRoaster(value.data.data))
        //loading roaster loader
      }).catch((err)=>{
        console.log(err);
      });

      // update swappable shift
      shift.fetchSwappableShift(selectedBranchState,1).then((value)=>{
        dispatch(setSwappableShift(value.data.data))      
       }).catch((err)=>{
         console.log(err);
       });
    }).catch((error)=>{
      console.log(error)

    })
  }

  
  const handleOpenDeleteShift = () =>{
    setOpenDeleteModal(true)
  }
  const handleCloseDeleteShift = () =>{
    setOpenDeleteModal(false)
  }

  const handleOpenMakeOfferViewModal = () => {
    setOpenMakeOfferViewModal(true)
  }
  const handleCloseMakeOfferViewModal = () => {
    setOpenMakeOfferViewModal(false)
  }

  const handleOpenEditShift = () => {
    setOpenEditShift(true)
  }
  const handleCloseEditShift = () => {
    setOpenEditShift(false)
  }

  const handleClose = () => {
    dispatch(setToggleShiftDetails({state:false, data:[], user:{}}))
  }

  const textHeadStyle = {fontSize:'13px'}
  const open = Boolean(shiftToggleDetailState.state);

  const mainAnim ={
    hidden: {opacity: 0},
    vissible: {
      opacity: 1,
      transition: {ease: 'easeIn', duration: 1}
    },
    exit: {
      opacity: 0,
      transition: {ease: 'easeOut', duration: 1}
    }
  }
 
  return (
      <Menu
        component={motion.div}
        variants={mainAnim}
        initial={'hidden'}
        animate={'vissible'}
        exit={'exit'}
        id="basic-menu"
        anchorEl={shiftToggleDetailState.state}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: { borderRadius: '10px', boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)' }   }}
        sx={{
          width: '50%',
          marginLeft: '35rem',
          marginRight: '35rem',
          height: '550px',
        }}
      >
        <DeleteShift openDeleteModal={openDeleteModal} handleClose={handleCloseDeleteShift} shiftDetails={shiftToggleDetailState.data} handleCloseShiftDetails={handleClose}/>
        <AnimatePresence>
          {openMakeOfferViewModal && <MakeOfferView openMakeOfferViewModal={openMakeOfferViewModal} handleCloseMakeOffer={handleCloseMakeOfferViewModal} loggedInUserDetails={loggedInUserDetails.id} handleCloseDetail={handleClose}/> }
        </AnimatePresence>
        <EditShift openEditShift={openEditShift} handleClose={handleCloseEditShift}/>
          { (data.length > 0) && <Box 
        
          sx={{
            width: '310px',
            height: '466px',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 1.5rem',
            ...font

          }}>
              {/* first row */}
              <Box sx={{
                alignSelf: 'flex-end',
                marginTop: '-18px',
                marginRight: '6px',
                borderRadius: '20px',
                padding: '2px 4px',
                position: 'fixed',
                background: 'white',
                cursor:'pointer',
                
              }}onClick={handleClose}>
                <IoClose style={{marginTop:'0.2rem', color: '#242424', fontSize:'23px',}} />

              </Box>

              {/* second row */}
              <Box sx={{display: 'flex', marginTop: '20px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            
                  <Box sx={{marginTop: '0.3rem', marginRight:'0.2rem'}}>
                      <img src={dot} alt="" />

                  </Box>

                  <Typography sx={{...font, fontSize: '18px', color: '#242424', marginRight: '1rem', fontWeight: '400'}}>Shift Details</Typography>
              
                  
                  {(loggedInUserDetails.id == shiftToggleDetailState.user.id) && <>
                    <AiOutlineDelete style={{marginTop:'0.2rem', fontSize:'14px', marginRight:'0.8rem', color:'#242424', cursor:'pointer'}}
                    onClick={handleOpenDeleteShift}
                    />
                    <FiEdit2 style={{marginTop:'0.2rem', fontSize:'14px', marginRight:'0.8rem', color:'#242424', cursor:'pointer'}} onClick={handleOpenEditShift}/> 
                  </>  }
              </Box>

              {/* third row */}
              <Box sx={{display:'flex', flexDirection:'row', gap:'0.5rem', marginTop:'1rem'}}>
                <IoMdAlarm style={{fontSize:'26px', color:'#242424'}} />
                <Typography sx={{...font, fontSize:'16px', color:'#242424'}}>{detailData.slot.startTime}-{detailData.slot.endTime}</Typography>

              </Box>

              {/* fourth row */}
              <Box sx={{display:'flex', flexDirection:'column', gap:'1.5rem', color:'#696969', fontSize:'12px', marginTop:'1.5rem'}}>

                  <Box>
                    <Typography sx={{...font, ...textHeadStyle}} >Employee name </Typography>
                    <Typography sx={{...font, color:'#000000',...textHeadStyle}} >{firstName} {lastName}</Typography>
                  </Box>
                  <Box >
                    <Typography sx={{...font, ...textHeadStyle}}>Job role</Typography>
                    <Typography sx={{...font, color:'#000000',...textHeadStyle}}>{detailData.slot.jobRole.title}</Typography>
                  </Box>

                  <Typography sx={{...font, ...textHeadStyle}}>Skills</Typography>

                  <Typography sx={{...font, ...textHeadStyle}}>Location</Typography>

                  {(loggedInUserDetails.id === shiftToggleDetailState.user.id) && <Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem'

                  }}>

                    <Box sx={{ 
                      padding: '0.3rem 0.3rem',
                      borderRadius: '1rem',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#5175F3',
                      color: 'white'
                    }}>
                      <BsArrowsAngleContract sx={{fontSize: '14px'}} />
                    </Box>
                      <Typography sx={{...font, fontSize: '14px'}}>
                        Offer for swap
                      </Typography>
                      <Switch size='small' sx={{backgroundColor: 'white'}}
                        checked={checked}
                        onChange={handleChange}
                        disabled={isLoadingMakeSwap ? true : false}
                      />
                      {isLoadingMakeSwap && <CircularProgress size='0.8rem'/> }
                  </Box>
                  <Typography sx={{...font, color:'#A5A5A5', fontSize: '11px'}}>
                    {checked ?"This shift is now visible to co workers for swap" : "This shift is not visible to co workers for swap"}
                  </Typography>
                  </Box> }

              </Box>

              {/* fifth row */}
              {(loggedInUserDetails.id === shiftToggleDetailState.user.id) && <Button sx={{padding:'0.5rem', fontSize:'12px', backgroundColor:'white', color:'#242424', width:'60%', border:'1px solid black', borderRadius:'0.2rem', marginTop:'1rem', display:'flex', flexDirection:'row', alignItems:'center', gap:'0.5rem'}}>
                <GrAdd />
                <Typography sx={{...font, fontSize:'11px'}}>Add to calendar</Typography>
              </Button>}

              {(loggedInUserDetails.id !== shiftToggleDetailState.user.id && detailData.swappable) && <Box sx={{marginTop: '4rem', display: 'flex', justifyContent: 'space-between'}}>
                <OutlinedButton text={'Cancel'} callBack={handleClose}/>
                <FillButton text={'Make offer'} callBack={handleOpenMakeOfferViewModal}/>
              </Box> }


          </Box> }

          {(data.length === 0) && <Box sx={{width: '285px', height:'466px', display: 'flex', flexDirection:'column', gap:'0.5rem', padding: '1rem'}}>
          <Box sx={{
                alignSelf: 'flex-end',
                marginTop: '-32px',
                marginRight: '6px',
                borderRadius: '20px',
                padding: '2px 4px',
                position: 'fixed',
                background: 'white',
                cursor:'pointer',
                
              }}onClick={handleClose}>
                <IoClose style={{marginTop:'0.2rem', color: '#242424', fontSize:'23px',}} />

              </Box>
            
            <Box sx={{height: '90%', display:'flex', flexDirection:'column', gap:'0.5rem', alignItems:'center', justifyContent:'center'}}>
              <CgTrashEmpty style={{fontSize: '40px'}}/>

              <Typography sx={{...font, fontSize:'14px', color:'#696969'}} >No schedule on this day</Typography>
            </Box>

          </Box> }
        </Menu>
  );
}