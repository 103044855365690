/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './CreateAccountModal.css';
import { useState, useEffect } from 'react';
import { Home } from '../../../../../Network/ServiceClass/Home';
import { useFormik } from 'formik';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { font } from '../../../../Widgets/Styles/font';
import { formSchema } from './formSchema';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessAlert } from '../../../../../Store/Company';
import successImage from '../../../../../Assets/success.png';
import {
  Text,
  Field,
  TextAndField,
  FillButton,
  FieldSelect,
  OutlinedButton,
} from '../../../../Widgets/FormUtils/FormUtils';
import { motion, AnimatePresence } from 'framer-motion';
import FormModal from '../../../../Widgets/Animation/FormModal';
import { getCountryAsync } from '../../../../../Store/utils';
import { toast } from 'react-toastify';

const home = new Home();

function CreateAccountModal({ open, handleClose, callback }) {
  const dispatch = useDispatch();
  const countriesList = useSelector((state) => state.utils)?.countries;

  useEffect(() => {
    home
      .getCategories()
      .then((value) => {
        setCategories(value.data);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(getCountryAsync());
  }, []);

  const [categories, setCategories] = useState([]);
  const [step, setStep] = useState(1);

  const [isloading, setIsLoading] = useState(false);

  const [comstate, setComState] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      ein: '',
      address: '',
      cityId: '',
      countryId: '',
      zipCode: '',
      rotationDays: '',
      rotationStart: '',
      categoryId: '',
      teamSize: 1,
      stateId: '',
      writeUp: '',
      weeklyHours: '',
    },
    validationSchema: step === 1 ? formSchema : '',
    onSubmit:
      step === 1
        ? () => {
            setStep(2);
          }
        : (value, actions) => {
            if (
              city !== '' &&
              country !== '' &&
              value.zipCode !== '' &&
              state !== '' &&
              value.writeUp !== ''
            ) {
              value['countryId'] = country;
              value['cityId'] = city;
              value['stateId'] = state;
              value.teamSize = value.teamSize.toString();

              setIsLoading(true);

              home
                .createCompany(JSON.stringify(value))
                .then((value) => {
                  actions.resetForm();
                  setStep(1);
                  callback();
                  handleClose();
                  setIsLoading(false);
                  toast.success('New Company has been created successfully')
                })
                .catch((err) => {
                  setIsLoading(false);
                  console.log(err);
                });
            }
          },
  });

  let LoadState = (id) => {
    home
      .getState(id)
      .then((value) => {
        setComState(value.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let LoadCity = (id) => {
    home
      .getCity(id)
      .then((value) => {
        setCities(value.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    maxHeight: '100vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const secondChild = {
    hidden: { opacity: 0 },
    vissible: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: 'easeIn',
      },
    },
  };

  return (
    <div>
      <FormModal open={open} handleClose={handleClose}>
        <div>
          <AnimatePresence>
            {step === 1 && (
              <motion.div
                exit={{
                  opacity: 0,
                  transition: { duration: 1, ease: 'easeIn' },
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  sx={style}
                  gap={3}
                >
                  <Typography
                    sx={{ ...font, fontSize: '18px', fontWeight: '500' }}
                  >
                    {' '}
                    Create a new company
                  </Typography>

                  <TextAndField>
                    <Text text={'Company name'} />
                    <Field
                      text={'Kindly enter company name'}
                      value={formik.values.name}
                      formik={formik}
                      name="name"
                    />
                  </TextAndField>

                  <TextAndField>
                    <Text text={'What is the category of your company'} />
                    <FieldSelect
                      list={categories}
                      width="100%"
                      value={formik.values.categoryId}
                      formik={formik}
                      name="categoryId"
                    />
                  </TextAndField>

                  <Stack width="100%" direction="row" spacing={3}>
                    <TextAndField>
                      <Text text={'Number of team members'} />
                      <Field
                        text={0}
                        type="number"
                        width="100%"
                        value={formik.values.teamSize}
                        formik={formik}
                        name="teamSize"
                      />
                    </TextAndField>
                    <TextAndField>
                      <Text text={'Zipcode'} />
                      <Field
                        text={0}
                        width="100%"
                        value={formik.values.zipCode}
                        formik={formik}
                        name="zipCode"
                      />
                    </TextAndField>
                  </Stack>

                  <TextAndField>
                    <Text text={'EIN (Employee Identification Number)'} />
                    <Field
                      text={'Kindly enter number'}
                      value={formik.values.ein}
                      formik={formik}
                      name="ein"
                    />
                  </TextAndField>

                  <TextAndField>
                    <Text text={'Company address'} />
                    <Field
                      text={'Kindly enter address'}
                      value={formik.values.address}
                      formik={formik}
                      name="address"
                    />
                  </TextAndField>

                  <Stack
                    direction="row"
                    width="82%"
                    justifyContent="space-between"
                    mt={6}
                  >
                    <OutlinedButton
                      text="Cancel"
                      callBack={() => {
                        handleClose();
                        formik.resetForm();
                      }}
                    />
                    <FillButton
                      text="Continue"
                      callBack={formik.handleSubmit}
                    />
                    {/* <CircularProgress/> */}
                  </Stack>
                </Stack>
              </motion.div>
            )}
          </AnimatePresence>
          {step === 2 && (
            <motion.div variants={secondChild}>
              {' '}
              <Stack direction="column" alignItems="center" sx={style} gap={3}>
                <Typography
                  sx={{ ...font, fontSize: '18px', fontWeight: '500' }}
                >
                  {' '}
                  Create a new company
                </Typography>

                <Stack
                  width="100%"
                  justifyContent="space-between"
                  direction="row"
                  spacing={2}
                  className='w-full'
                >
                  <TextAndField>
                    <Text text={'Number of rotation days'} />
                    <Field
                      text={0}
                      type="number"
                      width="100%"
                      value={formik.values.rotationDays}
                      formik={formik}
                      name="rotationDays"
                    />
                  </TextAndField>
                  <TextAndField>
                    <Text text={'First day of rotation'} />
                    <Field
                      text={0}
                      type="date"
                      width="100%"
                      value={formik.values.rotationStart}
                      formik={formik}
                      name="rotationStart"
                    />
                  </TextAndField>
                </Stack>

                <TextAndField>
                  <Text text={'Country'} />
                  <FieldSelect
                    list={countriesList}
                    value={country}
                    formik={formik}
                    name="countryId"
                    width="100%"
                    callback={(val) => {
                      setCountry(val.target.value);
                      LoadState(val.target.value);
                    }}
                  />
                </TextAndField>

                <TextAndField>
                  <Text text={'State'} />
                  <FieldSelect
                    list={comstate}
                    value={state}
                    formik={formik}
                    width="100%"
                    name="stateId"
                    callback={(val) => {
                      console.log('state', val.target.value);
                      setState(val.target.value);
                      LoadCity(val.target.value);
                    }}
                  />
                </TextAndField>

                <TextAndField>
                  <Text text={'City'} />
                  <FieldSelect
                    list={cities}
                    value={city}
                    width="100%"
                    formik={formik}
                    name="cityId"
                    callback={(val) => {
                      console.log('city', val.target.value);
                      setCity(val.target.value);
                    }}
                  />
                </TextAndField>

                <TextAndField>
                  <Text text={'Company info'} />
                  <Field
                    text={'Something to write about'}
                    value={formik.values.writeUp}
                    formik={formik}
                    name="writeUp"
                  />
                </TextAndField>

                <Stack
                  width="100%"
                  justifyContent="space-between"
                  direction="row"
                  spacing={2}
                >
                  <TextAndField>
                    <Text text={'Weekly work hours'} />
                    <Field
                      text={0}
                      type="number"
                      width="100%"
                      value={formik.values.weeklyHours}
                      formik={formik}
                      name="weeklyHours"
                    />
                  </TextAndField>
                </Stack>

                <Stack
                  direction="row"
                  width="82%"
                  justifyContent="space-between"
                  mt={6}
                >
                  <OutlinedButton
                    text="Cancel"
                    callBack={() => {
                      formik.resetForm();
                      setStep(1);
                      handleClose();
                    }}
                  />
                  {!isloading && (
                    <FillButton text="Submit" callBack={formik.handleSubmit} />
                  )}

                  {isloading && <CircularProgress />}
                </Stack>
              </Stack>
            </motion.div>
          )}
        </div>
      </FormModal>
    </div>
  );
}

export default CreateAccountModal;

//
