import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import {MdOutlineCancel} from 'react-icons/md'
import {font} from '../../../../Widgets/Styles/font';
import Slect from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css"
import FormModal from '../../../../Widgets/Animation/FormModal';



import { Box, Typography, Switch, CircularProgress, Button} from '@mui/material';
const timeInputStyle = {width:"126px", height: '49px', paddingLeft: '0.8rem', fontSize: '16px'}
const timeDivStyle = {display:'flex', flexDirection:'column', gap: '0.5rem'}

export default function EditShift({openEditShift, handleClose}) {
  const [selectedShiftTime, setSelectedShiftTime] = useState({start:'0:0', end:'0:0'});
  const [dayVal, setDayVal] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date());
  

 
  return (
    <div>
      <FormModal open={openEditShift} handleClose={handleClose}>
        <Box sx={{
            position: 'absolute',
            top: '10%',
            left: '40%',
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            width: '331px',
            height: '500px',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 2rem',
            ...font

        }}>
          {/* first row */}
            <Box sx={{
                  alignSelf: 'flex-end',
                  marginTop: '-5px'
                }}>
                  <MdOutlineCancel style={{marginTop:'0.2rem', color: '#242424', fontSize:'23px', cursor:'pointer'}} onClick={handleClose}/>

            </Box>
            
            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
              {/* secont row */}
              <Typography sx={{fontSize: '18px', fontWeight: '500',}}>Editing shift</Typography>

                {/* third row */}
              <div style={{
                display: 'flex',
                fontSize: '12px',
                alignItems: 'flex-start',
                gap: '2rem'
              }}>

                <div style={{...timeDivStyle}}>
                  <div>Start Shift</div>
                  <input style={{...timeInputStyle}} type="time"  placeholder="Choose time" value={selectedShiftTime.start}
                  onChange={(e)=>{setSelectedShiftTime({start: e.target.value, end: selectedShiftTime.end})}}
                    />
                </div>

                <div style={{...timeDivStyle}}>
                    <div>End Shift</div>
                    <input style={{...timeInputStyle}} type="time" placeholder="Choose time" value={selectedShiftTime.end}
                    onChange={(e)=>{setSelectedShiftTime({end: e.target.value, start: selectedShiftTime.start})}}
                    />
                </div>
              </div>

              {/* Fourth row */}
              <div>
                <p>Choose location</p>
                <Slect
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue=''
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="color"
                  options={[{value:'one', label:'one'}]}
                  // onChange={(e)=>{setSelectedShift(e.value); setIsSelected(true)}}
                />
              </div>

              {/* Fifth row */}
              <div>
                <p>Employee</p>
                <Slect
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue=''
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="color"
                  options={[{value:'one', label:'one'}]}
                  // onChange={(e)=>{setSelectedShift(e.value); setIsSelected(true)}}
                />
              </div>

              {/* Sixth row */}
              <Box sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  // gap: '0rem'
                  // paddingLeft: '2rem',
                  // margin: 'auto',
              }} >
              <div>Day</div>
              <DatePicker 
              selected={startDate} 
              onChange={(date) => setStartDate(date)} 
              filterDate={date => date.getDay(date) === dayVal}
              placeholderText="Select a Monday"
              />
              </Box>

              {/* Seventh row */}
              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Button onClick={handleClose} variant='outlined' sx={{textTransform: 'none', borderColor:'#4A4A4A', color:'#4A4A4A', width:'30%'}}>Cancel</Button>
                <Button variant='contained' sx={{textTransform: 'none', backgroundColor: '#4A4A4A', width:'30%'}}>Save</Button>
              </Box>
            </div>

        </Box>

      </FormModal>
      {/* <Dialog
        open={openEditShift}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // style={{textAlign:"center"}}
        
      >
      </Dialog> */}
    </div>
  );
}