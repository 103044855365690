import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import GlobalButton from '../../../Widgets/GlobalButton';
import AppLayout from '../../../Widgets/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  confirmQuickWithdrawalThunk,
  fetchCurrentItemsThunk,
  inventoryTrackItemUsageThunk,
} from '../../../../Store/Inventory';
import { toast } from 'react-toastify';
import { Loader } from '../../../Widgets/Loader';
import _ from 'lodash'
import ConfirmModal from '../components/ConfirmModal';
import { convertToDollar } from '../../../../utils/util';

export const ConfirmQuickWithdrawal = () => {
  const navigate = useNavigate();
  const [isConfirmModalOpen, openConfirmModal] = useState(false)
  const [inventoryItems, setInventoryItem] = useState([]);
  const dispatch = useDispatch();
  // const currentInventoryItem =
  //   useSelector((state) => state.inventory)?.currentInventoryItem || [];
  const isLoading = useSelector((state) => state.inventory)?.loading;

  useEffect(() => {
    if (localStorage.getItem('withdrawalItem')) {
      const data = JSON.parse(localStorage.getItem('withdrawalItem'));

      dispatch(
        fetchCurrentItemsThunk({
          locationId: data?.withdrawLocationId,
          listOfId: data?.withdrawalData?.map((x) => ({ inventoryId: x?.id })),
        })
      ).then((res) => {
        const withdrawalData = res?.payload?.map((y) => ({
          ...data?.withdrawalData?.find(
            (myData) => myData.id === y.inventoryItemsId
          ),
          ...y,
          qty: y?.currentAmount || 0,
          remainingStock: y?.currentAmount || 0,
          quantityWithdrawn: 0,
        }))

        const item = {
          ...data,
          withdrawalData
        };
        setInventoryItem(item);
      });
    }
  }, []);

  const handleIncreaseQtyWiithdrawn = (val) => {
    const qtyWithdrawn = Math.min(val?.quantityWithdrawn + 1, val?.qty);
    const remainStock = val?.qty - qtyWithdrawn;
    const data = {
      ...val,
      quantityWithdrawn: qtyWithdrawn,
      remainingStock: remainStock,
    };

    const filteredItem = inventoryItems?.withdrawalData?.map((x) => {
      if (x.id === val?.id) {
        return {
          ...data,
        };
      }
      return x;
    });

    const newInventory = {
      ...inventoryItems,
      withdrawalData: filteredItem,
    };

    setInventoryItem(newInventory);
  };

  const handleDecreaseQtyWiithdrawn = (val) => {
    const qtyWithdrawn = Math.max(val?.quantityWithdrawn - 1, 0);
    const remainStock = val?.qty - qtyWithdrawn;
    const data = {
      ...val,
      quantityWithdrawn: qtyWithdrawn,
      remainingStock: remainStock,
    };

    const filteredItem = inventoryItems?.withdrawalData?.map((x) => {
      if (x.id === val?.id) {
        return {
          ...data,
        };
      }
      return x;
    });

    const newInventory = {
      ...inventoryItems,
      withdrawalData: filteredItem,
    };

    setInventoryItem(newInventory);
  };

  const handleWithdrawItem = (val = "") => {
    let data;

    if(val?.length){
      data = {
        itemUsage: inventoryItems?.withdrawalData?.map((x) => ({
          referenceNumber: x?.referenceNumber,
          teamMembershipId: inventoryItems?.teamMembershipId,
          locationId:inventoryItems?.withdrawLocationId,
          amount: x.quantityWithdrawn,
          measurementUnitId: x?.reorderItems?.find(
            (x) => x?.locationId === inventoryItems?.withdrawLocationId
          )?.measurementUnit?.id,
          patientId: val 
        })),
      };

      dispatch(inventoryTrackItemUsageThunk(data)).then((res) => {
        if (res?.meta?.requestStatus === 'fulfilled') {
          toast.success('Inventory was successfully tracked');
          localStorage.removeItem('withdrawalItem');
          openConfirmModal(false)
          navigate('/quick-withdrawal');
        } else {
          toast.error('Inventory was not successfully withdrawn');
        }
      });

    } else {
      data = {
        teamMembershipId: inventoryItems?.teamMembershipId,
        withdrawalData: inventoryItems?.withdrawalData?.map((x) => ({
          inventoryItemsId: x?.reorderItems?.find(
            (x) => x?.locationId === inventoryItems?.withdrawLocationId
          )?.inventoryItemsId,
          measurementUnitId: x?.reorderItems?.find(
            (x) => x?.locationId === inventoryItems?.withdrawLocationId
          )?.measurementUnit?.id,
          withdrawLocationId: inventoryItems?.withdrawLocationId,
          quantityToWithdraw: x.quantityWithdrawn,
        })),
      };
      dispatch(confirmQuickWithdrawalThunk(data)).then((res) => {
        if (res?.meta?.requestStatus === 'fulfilled') {
          toast.success('Inventory was successfully withdrawn');
          localStorage.removeItem('withdrawalItem');
          openConfirmModal(false)
          navigate('/quick-withdrawal');
        } else {
          toast.error('Inventory was not successfully withdrawn');
        }
      });
    }

    localStorage.setItem('withdrawalItem', JSON.stringify(data));

  };

  return (
    <div>
      <AppLayout
        custom="inventory"
        subCustom="quick-withdrawal-confirm"
        title="Withdraw Items"
        customTitle="Inventory"
        subtitle=""
      >
        <div className="flex items-center gap-3 dark:text-white mb-10">
          <BsArrowLeft
            className="cursor-pointer"
            size={25}
            onClick={() => navigate(-1)}
          />
          <p className="text-base font-bold"></p>
        </div>

        <section className="flex justify-end">
          <GlobalButton
            disabled={isLoading ||
              inventoryItems?.withdrawalData?.length === 0}
            loading={isLoading}
            onClick={() => {
              if (inventoryItems?.itemUsage) {
                openConfirmModal(true);
              } else {
                handleWithdrawItem();

              }
            }}
            className={(isLoading ||
              inventoryItems?.withdrawalData?.length === 0) ? " cursor-not-allowed" : ""}
            showIcon={false}
            title="Confirm Withdraw"
          />
        </section>

        <section className="mt-6 max-h-[70vh] mb-12 overflow-y-auto">
          <table>
              <tr className='bg-0F5 dark:bg-color-333 dark:text-white font-bold sticky top-0'>
                <td className='rounded-tl-md px-4 py-6'>Reference number #</td>
                <td className='px-2'>Name</td>
                <td className='px-2'>Quantity in stock</td>
                <td className='px-2'>Quantity withdrawn</td>
                <td className='px-2'>Remaining in stock</td>
                <td className='rounded-tr-md px-2'>Price</td>
              </tr>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6} className="h-[300px]">
                    <Loader />
                  </td>
                </tr>
              )}
              {!isLoading && inventoryItems?.withdrawalData?.length === 0 && (
                <tr>
                  <td colSpan={6} className="h-[300px] dark:text-A5A">
                    <p className="text-center">No Data</p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                inventoryItems?.withdrawalData?.map((x) => (
                  <tr className='border-b-1 dark:border-292 font-bold text-A5A dark:text-white hover:bg-5F5 hover:dark:bg-292'>
                    <td className="pl-4">{x.referenceNumber}</td>
                    <td className='px-2'>{x?.name}</td>
                    <td className='px-2'>{x?.qty}</td>
                    <td className='px-2 hover:dark:bg-color-333'>
                      <div className="h-[50px] flex items-center justify-between rounded-lg bg-5F5 dark:bg-A06 ">
                        <div
                          onClick={() => handleDecreaseQtyWiithdrawn(x)}
                          className="h-full w-[40px] bg-5F3 rounded-tl-lg rounded-bl-lg cursor-pointer flex items-center flex-col justify-center"
                        >
                          <p className="font-semibold text-2xl text-white">-</p>
                        </div>

                          <p className='dark:text-white'>{x?.quantityWithdrawn}</p>

                        <div
                          onClick={() => handleIncreaseQtyWiithdrawn(x)}
                          className="h-full w-[40px] bg-5F3 rounded-tr-lg rounded-br-lg cursor-pointer flex items-center flex-col justify-center"
                        >
                          <p className="font-semibold text-2xl text-white">+</p>
                        </div>
                      </div>
                    </td>
                    <td className='px-2'>{x?.remainingStock}</td>
                    <td className='px-2'>$ {convertToDollar(100 * Number(x?.qty - x?.remainingStock) * Number(x?.manAndSupp?.length > 0 ? _.orderBy(x?.manAndSupp, 'manufacturerPkgCost')[0]?.manufacturerPkgCost/100 : 0))}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
      
        {isConfirmModalOpen && (<ConfirmModal handleWithdrawItem={handleWithdrawItem} loading={isLoading} open={isConfirmModalOpen} handleClose={() => openConfirmModal(false)} />)}
      </AppLayout>
    </div>
  );
};
