import { Popper } from '@mui/material';
import { IoMdClose } from "react-icons/io";
import { motion } from 'framer-motion';  
import { Storage } from '../../Network/StorageClass/StorageClass';
import { EditIcon } from '../../Assets/Svg/Index';


const JobPopper = ({openPopper, handleClose = () =>{}, title, children, className, handleClickEdit = ()=>{}}) => {
  
  const open = Boolean(openPopper);
  const id = open ? 'simple-popper' : undefined;
  
  const storage = new Storage();
  const priviledge = storage.getActiveCompany().memberships[0].privilege
  
    const mainAnim ={
      hidden: {opacity: 0},
      vissible: {
        opacity: 1,
        transition: {ease: 'easeIn', duration: 1}
      },
      exit: {
        opacity: 0,
        transition: {ease: 'easeOut', duration: 1}
      }
    }



  return (
    <Popper
        id={id} 
        open={open} 
        anchorEl={openPopper} 
        onClose={handleClose} 
        placement={'bottom-start'}>

      <div className='relative w-[250px]'>
          <motion.div 
            variants={mainAnim}
            initial={'hidden'}
            animate={'vissible'}
            exit={'exit'}
            style={{boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)'}}
            className={`w-[250px] absolute z-50 flex flex-col gap-4 text-[12px] text-[#5A5A5A] font-bold bg-white dark:text-white dark:bg-1F1 pt-3 pb-16 rounded-lg ${className}`}>
              <div className='flex justify-end px-3'><IoMdClose className='text-[20px] cursor-pointer' onClick={handleClose}/></div>
              
              <div className='px-7 flex flex-col gap-4'>
                
                <div className='flex justify-between'>

                  <p 
                  className='text-[14px] dark:text-white'
                  >{title}</p>

                  {[1,2].includes(priviledge) && <>
                    <EditIcon onClick={handleClickEdit} className={'cursor-pointer'}/>
                    
                  </>}
                  </div>
              {children}

              </div>
          </motion.div>
    </div>
      </Popper>

  )
}

export default JobPopper



