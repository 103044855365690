import React, { useState, useEffect } from 'react';
import TaskLayout from './Widget/TaskLayout.jsx/TaskLayout';
import Task from './Tabs/Task/Task';
import { Overdue } from './Tabs/Overdue/Overdue';
import { TaskModal } from './Widget/Modal/TaskModal/TaskModal';
import BoardModal from './Widget/Modal/BoardModal/BoardModal';
import { TaskClass } from '../../../Network/ServiceClass/Task';
import { Loader } from '../../Widgets/Loader';
import boardicon from '../../../Assets/boardIcon.png';
import { BsCheck } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { Typography } from '@material-ui/core';
import AppLayout from '../../Widgets/AppLayout';
import SearchInput from '../../Widgets/SearchInput';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { taskTutorial } from '../mock';

export const TaskScreen = () => {
  const taskboard = new TaskClass();
  const navigate = useNavigate();

  const theme = {
    notstarted: {
      p: '#676767',
      s: '#D7D7D7',
    },
    inprogress: {
      p: '#FFCC00',
      s: '#FFE167',
    },
    done: {
      p: '#5CB26E',
      s: '#ACEBB9',
    },
    overdue: {
      p: '#D7D7D7',
      s: '#C1C1C1',
    },
  };

  const [index, setIndex] = React.useState(0);
  const [open, setOpen] = useState(false);

  const [openBoard, setOpenBoard] = useState(false);
  const [boards, setBoards] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [selectedBoard, setSelectedBoard] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [searchedTask, setSearchedTask] = useState('');

  const FetchBoards = () => {
    setIsLoading(true);
    taskboard
      .getBoards()
      .then((value) => {
        setBoards(value.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    FetchBoards();
  }, []);

  const handleTaskSearch = (userName) => {
    const filteredData = boards?.filter((board) => board?.name?.toLowerCase().includes(userName?.toLowerCase()))
    return filteredData;
  }

  const handleOnSearchtask = (e) => {
    setSearchedTask(e.target.value);
  }

  return (
    <section className="homepage">
       <AppLayout
        custom="task"
        subCustom=""
        title="Boards"
        customTitle=""
        subtitle="Manage and regulate tasks through boards"
        RightComponent={<TutorialPanel items={taskTutorial} />}
      >
        <section className="overview" data-tour="step5">

          {boards.length !== 0 && !isLoading && (
            <div
              style={{
                width: '100%',
                marginTop: '30px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SearchInput
                placeholder="Search board"
                onChange={handleOnSearchtask}
              />



              <button
                onClick={() => setOpenBoard(true)}
                className="w-[139px] h-[40px] dark:bg-white dark:text-black bg-base-color text-white rounded"
              >
                Create board
              </button>
            </div>
          )}

          {selectedBoard !== null ? (
            !isLoading ? (
              <TaskLayout>
                {index === 0 && (
                  <Task
                    theme={theme}
                    tasks={tasks}
                    open={() => setOpen(true)}
                  />
                )}

                {index === 1 && <Overdue theme={theme} />}
              </TaskLayout>
            ) : (
              <Loader />
            )
          ) : boards.length === 0 ? (
            !isLoading ? (
              <div
                style={{
                  height: '40vh',
                  alignItems: 'center',
                  marginTop: '60px',
                  width: '100%',
                  display: 'inline-flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <img src={boardicon} width="20%" alt="board_icon" />

                <center>
                  <p style={{ marginTop: '40px' }}>
                    You do not have any boards
                  </p>
                </center>

                <button
                  type="button"
                  onClick={() => setOpenBoard(true)}
                  style={{
                    padding: '7px 12px',
                    color: 'white',
                    border: '1px solid black',
                    backgroundColor: 'black',
                  }}
                >
                  Create board
                </button>
              </div>
            ) : (
              <Loader />
            )
          ) : (
            <>
              <div
                className="boards"
                style={{ width: '70%', marginTop: '30px' }}
              >
                {handleTaskSearch(searchedTask)?.map((value) => (
                  <div
                    onClick={() => navigate('/tasks/board', { state: value })}
                    className="mb-[30px]"
                  >
                    <div style={{ display: 'inline-flex' }} className="mb-2">
                      <div
                        style={{
                          width: '40px',
                          height: '40px',
                          fontSize: '20px',
                          alignItems: 'center',
                          display: 'inline-flex',
                          justifyContent: 'center',
                          backgroundColor: value?.color,
                          color: 'white',
                          marginRight: '10px',
                        }}
                      >
                        <BsCheck />
                      </div>
                      <div>
                        <Typography
                          variant="p"
                          className="font-semibold text-xs cursor-pointer dark:text-white"
                        >
                          {value.name}
                        </Typography>
                        <Typography className="font-bold text-[8px] text-grey-600 cursor-pointer">
                          {value.creator.firstName}
                        </Typography>
                      </div>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </>
          )}
        </section>

      </AppLayout>
     

      {/* <TaskModal open={open} handleClose={()=>setOpen(false)} /> */}

      <BoardModal
        open={openBoard}
        handleClose={() => setOpenBoard(false)}
        callback={FetchBoards}
      />
    </section>
  );
};
