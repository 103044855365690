import { BsArrowLeft } from "react-icons/bs"
import { useNavigate } from "react-router"
import GlobalButton from "../../../Widgets/GlobalButton"
import AppLayout from "../../../Widgets/AppLayout"
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCurrentItemsThunk, getInventoryMeasurementUnitThunk, transferItemThunk } from '../../../../Store/Inventory'
import { toast } from 'react-toastify'
import CustomDropdown from '../../../Widgets/CustomDropdown'
import { Loader } from '../../../Widgets/Loader'
import { TableWrapper, TableHeader, Td, Tr } from "../../../Widgets/Table/TableWrapper"

export const ConfirmTransfer = () => {
  const navigate = useNavigate()
  const [inventoryItems, setInventoryItem] = useState([])
  const dispatch = useDispatch()
  const measurementUnits = useSelector((state) => state.inventory)?.measurementUnits
  const isLoading = useSelector((state) => state.inventory)?.loading

  useEffect(()=>{
    if(localStorage.getItem("transferItem")){
      const data = JSON.parse(localStorage.getItem("transferItem"))
      dispatch(fetchCurrentItemsThunk({
        locationId: data?.withdrawLocationId,
        listOfId: data?.transferData?.map((x) => ({inventoryId: x?.id}))
      })).then((res) => {
        const item = {...data, transferData: res?.payload?.map((y) => ({...data?.transferData?.find((myData) => myData.id === y.inventoryItemsId), ...y, qty: y?.currentAmount || 0, remainingStock: y?.currentAmount || 0, quantityWithdrawn: 0}))}
        setInventoryItem(item)

      })
      dispatch(getInventoryMeasurementUnitThunk())
    }
  },[])

  const handleIncreaseQtyWiithdrawn = (val) => {
    const qtyWithdrawn = Math.min(val?.quantityWithdrawn + 1, val?.qty)
    const remainStock = val?.qty - qtyWithdrawn
    const data = {
      ...val,
      quantityWithdrawn: qtyWithdrawn,
      remainingStock: remainStock
    };
    
    const filteredItem = inventoryItems?.transferData?.map((x) => {
      if(x.id === val?.id){
        return {
          ...data
        }
      }
      return x
    })

    const newInventory = {
      ...inventoryItems,
      transferData: filteredItem
    }

    setInventoryItem(newInventory)
  }

  const handleMeasurementUnit = (val,e) => {
    const data = {
      ...val,
      measurementUnitId: e?.value
    };

    const filteredItem = inventoryItems?.transferData?.map((x) => {
      if(x.id === val?.id){
        return {
          ...data
        }
      }
      return x
    })

    const newInventory = {
      ...inventoryItems,
      transferData: filteredItem
    }

    setInventoryItem(newInventory)
  }

  const handleDecreaseQtyWiithdrawn = (val) => {
    const qtyWithdrawn = Math.max(val?.quantityWithdrawn - 1, 0)
    const remainStock = val?.qty - qtyWithdrawn
    const data = {
      ...val,
      quantityWithdrawn: qtyWithdrawn,
      remainingStock: remainStock
    };
    
    const filteredItem = inventoryItems?.transferData?.map((x) => {
      if(x.id === val?.id){
        return {
          ...data
        }
      }
      return x
    })

    const newInventory = {
      ...inventoryItems,
      transferData: filteredItem
    }

    setInventoryItem(newInventory)
  }

  const handleTransferItem = () => {
    const data = {
      ...inventoryItems,
      transferData: inventoryItems?.transferData?.map((x) => ({ referenceNum: x?.referenceNumber, quantityWithdrawn: x.quantityWithdrawn, measurementUnitId: x.measurementUnit?.id}))
    }

    localStorage.setItem("transferItem", JSON.stringify(data))

  
    dispatch(transferItemThunk(data)).then((res) =>{
      if(res?.meta?.requestStatus === "fulfilled"){
        toast.success("Inventory was successfully transfered")
        localStorage.removeItem("transferItem")
        navigate("/transfer-items")
      } else {
        toast.error("Inventory was not successfully transfered")
      }
    })
  }

  return (
    <div>
      <AppLayout
        custom="inventory"
        subCustom="transfer-items"
        title="Transfer Items"
        customTitle="Inventory"
        subtitle=""
      >

        <div className="flex items-center gap-3 mb-10 dark:text-white">
          <BsArrowLeft className="cursor-pointer" size={25} onClick={() => navigate(-1)} />
          <p className="text-base font-bold">Confirm transfer</p>
        </div>

        {!isLoading &&  inventoryItems?.transferData?.length > 0 && (<section className="flex justify-end">
          <GlobalButton loading={isLoading} onClick={()=>{
            handleTransferItem()
            }} showIcon={false} title="Confirm transfer" />
        </section>)}

        <TableWrapper className="mt-6 !max-h-[70vh] mb-12 ">
          <table>
              <TableHeader >
                <Td className="py-4">Reference number #</Td>
                <Td>Name</Td>
                <Td>Quantity in stock</Td>
                <Td>Amount Transfered</Td>
                <Td>Unit</Td>
                <Td>Remaining in stock</Td>
              </TableHeader>
              {
                isLoading && <tr><td colSpan={6} ><Loader /></td></tr>
              }
              {
                !isLoading &&  inventoryItems?.transferData?.length === 0 && <tr><td colSpan={6} ><p className="text-center">No Data</p></td></tr>
              }
              {
               !isLoading && inventoryItems?.transferData?.map((x) => (
                <Tr className="hover:bg-5F5 hover:dark:bg-292">
                  <Td className="py-4">{x.referenceNumber}</Td>
                  <Td>{x?.name}</Td>
                  <Td>{x?.qty}</Td>
                  <Td className="hover:dark:bg-color-333">
                    <div className="h-[50px] flex items-center justify-between rounded-lg bg-5F5 dark:bg-A06">
                      <div onClick={()=> handleDecreaseQtyWiithdrawn(x)} className="h-full w-[40px] bg-5F3 rounded-tl-lg rounded-bl-lg cursor-pointer flex items-center flex-col justify-center"><p className="font-semibold text-2xl text-white">-</p></div>
                      <p className="dark:bg-A06 dark:text-white">{x?.quantityWithdrawn}</p>
                      <div onClick={() => handleIncreaseQtyWiithdrawn(x)} className="h-full w-[40px] bg-5F3 rounded-tr-lg rounded-br-lg cursor-pointer flex items-center flex-col justify-center"><p className="font-semibold text-2xl text-white">+</p></div>
                    </div>
                    </Td>
                  <Td >
                  <CustomDropdown
                      data={[
                        {
                          label: 'All',
                          value: '',
                        },
                        ...measurementUnits?.map((x) => ({label: x.name, value: x.id}))
                      ]?.map((team) => ({
                        label: team?.label,
                        value: team?.value,
                      }))}
                      disable
                      optionClass="z-[500]"
                      dropdownTitleClass="text-black"
                      className="min-w-[150px] w-[200px] rounded h-[42px] bg-white dark:text-white dark:bg-A06 dark:border-333"
                      value={ {label: x.measurementUnit?.name, value: x.measurementUnit?.id}}
                      onChangeOption={(e) => { handleMeasurementUnit(x,e) }}
                    />
                  </Td>
                  <Td >
                    {x?.remainingStock}
                  </Td>
                </Tr>
                ))
              }
          </table>
        </TableWrapper>
       
      </AppLayout>
     
    </div>
  )
}