import React from 'react'
import './style.css';

const SlotCard = ({shiftspot}) => {


  const hex = {
    blue: {
      primary: "#EDFBFF",
      secondary: "#004556"
    },
    yellow: {
      primary: "#FFFCF2",
      secondary: "#554201"
    },
    grey: {
      primary: "#A4A4A4",
      secondary: "grey"
    }
  }

  const colorChecker = (val)=>{
      if(val.maxStaffRequired === val.staffBooked){
        return hex.grey
      }else if (val.staffBooked === 0) return hex.blue
      else return hex.yellow
  }

  return (
    <>
    {shiftspot !== null && <div className='slot-card' style={{color: colorChecker(shiftspot.availability).secondary,
       backgroundColor: colorChecker(shiftspot.availability).primary  }}>
      <><div className='time-stamp'>{Solve(shiftspot.startTime)
        
        +" - "
        
        +Solve(shiftspot.endTime)}</div>
        <p>{shiftspot.jobRole.title}</p></>
    
    </div>}
    </>
  )
}

const Solve = (time)=>{
    if(Number(time.substring(0,2))>12 || Number(time.substring(0,2))===0){
        return (Math.abs(Number(time.substring(0,2)) - 12)).toString() + (Number(time.substring(0,2))===0 ? "AM":"PM")
    }else{
        return (Number(time.substring(0,2))).toString() + "AM"
    }
}

export default SlotCard