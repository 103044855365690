import { useState, useEffect } from 'react';

import TableLayout from '../TableLayout';
import { CustomTableWithSearch } from '../../../../Widgets/Table/CustomTableWithSearch';
import { useSelector } from 'react-redux';
import { getEmployeeOvertime } from '../../../../../Network/ServiceClass/dashboardService';
import { setEmployeeOvertime } from '../../../../../Store/dashboard';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { getBranchesAsync } from '../../../../../Store/Branches';


const Row2 = () => {
  const dispatch = useDispatch();

  const [branches, setBranches] = useState([])
  const company = useSelector(state => state.company.activeCompany)

    useEffect(()=>{
      dispatch(getBranchesAsync())
     .then(val => {
         setBranches(val.payload.data)
     })
   },[company])

  const shiftSwapState = useSelector((state) => state.dashboard.shiftSwap);
  const employeeOvertime = useSelector(
    (state) => state.dashboard.employeeOvertime
  );

  const formatShiftSwap = shiftSwapState.map((val, index) => {
    return {
      id: index,
      col1: val.name,
      col2: val.title,
      col3: val.swaps,
      image: val.picture,
    };
  });

  const formatEmployeeOvertime = employeeOvertime.map((val, index) => {
    return {
      id: index,
      col1: val.name,
      col2: val.jobRole,
      col3: Math.round(val.hours),
      image: val.picture,
    };
  });

  const headerListSwap = ['Name', 'Job Role', 'Swaps'];
  const headerListOvertime = ['Name', 'Job Role', 'Hours'];
  const [branchValue, setBranchValue] = useState({
    label: 'All Locations',
    value: '1',
    hexcode: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeBranch = (val) => {
    const value = val?.value
    setIsLoading(true);
    setBranchValue(val);
    getEmployeeOvertime(value)
      .then((val) => {
        setIsLoading(false);
        dispatch(setEmployeeOvertime(val));
      })
      .catch((err) => setIsLoading(false));
  };

  return (
    <div className="shadow-shift-shadow flex gap-6">
        <TableLayout >
          <p className='section-header dark:text-white'>Shift Swaps</p>
          <CustomTableWithSearch
            withAvatar={true}
            rows={formatShiftSwap}
            headerList={headerListSwap}
            style="w-full flex flex-col gap-3"
          />
        </TableLayout>


      <TableLayout>
        <div className="flex justify-between">
        <p className='section-header dark:text-white'>Staff Overtime</p>
          
           <CustomDropdown
                trimtext
                trimLength={13}
                data={[
                  {
                    label: 'All Locations',
                    value: '1',
                    hexcode: ''
                  },
                  ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
                ]}
                mainContainerStyle='w-44'
                className="h-10 rounded "
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={branchValue}
                onChangeOption={handleChangeBranch}
              />
        </div>
        {!isLoading && (
          <CustomTableWithSearch
            withAvatar={true}
            rows={formatEmployeeOvertime}
            headerList={headerListOvertime}
            style="w-[100%] flex flex-col gap-3"
          />
        )}
        {isLoading && (
          <div className="w-[100%] flex justify-center mt-3">
            <CircularProgress />
          </div>
        )}
      </TableLayout>

    
     

      
    </div>
  );
};

export default Row2;
