import React from 'react'
import { Box, Typography} from '@mui/material'
import {IoIosArrowDown} from 'react-icons/io'
import {font} from '../../../../../../Widgets/Styles/font'

const Filter = ({boxStyle}) => {
  return (
    <Box sx={{
        border: '1px solid #E6E6E6',
        padding: '0.3rem 1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        borderRadius: '4px',
        cursor: 'pointer',
        minWidth: '150px',
        ...boxStyle,
        ...font
     }}>
        <Typography>Filter By</Typography>
        <IoIosArrowDown />
    </Box>
  )
}

export default Filter