import CustomButton from '../../../../Widgets/CustomButton';
import CustomModal from '../../../../Widgets/CustomModal'
import { ReactComponent as TrashIcon } from '../../../../../Assets/trash-icon.svg';
import { Loader } from '../../../../Widgets/Loader';


const DeleteInfoModal = ({ handleClose, open, loading, handleSubmit}) => {

  return (
    <CustomModal modalClassName="max-w-[423px]" open={open} handleClose={handleClose}>
      <div className="flex flex-col justify-between min-h-[300px]">
        <div className='flex flex-col items-center'>
        <p className="font-semibold text-base text-center mb-7"> Delete Report </p>
        <p className="text-[11px] font-medium text-center text-969"><span>Are you sure you want to </span><br /> <span>delete this report</span></p>

        <TrashIcon />
        </div>
        {loading ? <Loader /> : (<div className="flex justify-center mb-8">
        <CustomButton onClick={handleClose} className="border-black bg-white text-black text-semibold text-sm mr-24" showIcon={false} title='Cancel' />
        <CustomButton onClick={handleSubmit} className="border-353 bg-2F2 text-353 text-semibold text-sm" showIcon={false} title='Delete' />
        </div>)}
      </div>
    </CustomModal>
  )
}

export default DeleteInfoModal;