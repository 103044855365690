import { useSelector } from "react-redux";
import CustomModal from "../../../Widgets/CustomModal";
import { Field, FieldSelect, FillButton, TextAndField } from "../../../Widgets/FormUtils/FormUtils";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ReactComponent as UploadIcon } from "../../../../Assets/upload-icon.svg"
import { CircularProgress } from "@material-ui/core";
import { useRef } from "react";
import { uploadAnnouncementFile } from "../../../../Network/ServiceClass/announcementService";
import { useState } from "react";
import { BiX } from "react-icons/bi";


function CreateTicketModal({ open = false, isLoading = false, handleClose = () => { }, handleSubmit = () => { } }) {
  const inputRef = useRef(null)
  const [imgPreview, setImage] = useState(null)
  const formik = useFormik({
    initialValues: {
      title: "",
      status: "",
      priority: "",
      assignedToId: "",
      details: ""
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("This is required"),
      status: Yup.string().required("This is required"),
      priority: Yup.string().required("This is required"),
      assignedToId: Yup.string().required("This is required"),
    }),
    onSubmit: (values, { setSubmitting}) => {
      setTimeout( async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const activeCompany = JSON.parse(localStorage.getItem("activeCompany"));
        const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
        values["membershipId"] = member?.id

        const formData = new FormData()
        formData.append('file', imgPreview);

        if(imgPreview){
          const val = await  uploadAnnouncementFile(formData);
          values["attachedDocs"] = val.data.url
        }

        handleSubmit(values, formik)
        setSubmitting(false)

      }, 400)
    }
  })

  const user = useSelector(state => state.branches)?.manager
  const teamMembers = useSelector((state) => state?.branches)?.teamMembers;


  return (
    <CustomModal
      open={open}
      modalClassName="w-[535px] max-h-[95vh] dark:bg-A06 dark:text-white"
      parentContainer="!mt-[20px]"
      handleClose={handleClose}
    >
      <section className="h-full p-10 ">
        <p className="text-xl font-bold text-color-424 mb-8">New Ticket</p>

        {/* Report name */}
        <p className="mb-4">Reporter <span className="text-B1B mb-4">*</span></p>
        <div className="flex items-center gap-2 py-4 px-5 rounded-lg border-6E6 border w-fit"><div className="w-6 h-6 rounded-full bg-white"><img src={user?.picture?.url} className="h-6 w-6 rounded-full" alt="img" /></div> <p>{user?.firstName} {user?.lastName}</p></div>

        <div className="mt-6 flex items-center gap-10">
          <TextAndField>
            <p className="text-969 text-sm font-medium mb-[10px]">Assign To</p>
            <FieldSelect
              list={teamMembers?.map((team) => ({
                name: `${team?.user?.firstName} ${team?.user?.lastName}`,
                id: team?.id,
              }))}
              value={formik.values.assignedToId}
              formik={formik}
              name="assignedToId"
              placeholder="Assign to"
              width="100%"
              height="50px"
            />
          </TextAndField>
          <TextAndField>
            <p className="text-969 text-sm font-medium mb-[10px]">Status</p>
            <FieldSelect
              list={[
                { name: "Select Status", id: '' },
                { name: "Not Addressed", id: 0 },
                { name: "In Progress", id: 1 },
                { name: "QA", id: 2 },
                { name: "Resolved", id: 3 },
              ]}
              value={formik.values.status}
              formik={formik}
              name="status"
              placeholder="Select Status"
              width="100%"
              height="50px"
            />
          </TextAndField>
        </div>

        <div className="mt-6">
          <TextAndField>
            <p className="text-969 text-sm font-medium mb-[10px]">Priority</p>
            <FieldSelect
              list={[
                { name: 'Important', id: 'important' },
                { name: 'Todo', id: 'todo' },
                { name: 'Urgent', id: 'urgent' },
              ]}
              value={formik.values.priority}
              formik={formik}
              name="priority"
              placeholder="Select Priority"
              width="100%"
              height="50px"
            />
          </TextAndField>
        </div>

        <div className="mt-6">
          <TextAndField>
            <p className="text-969 text-sm font-medium mb-[10px]">Title <span className="text-B1B mb-4">*</span></p>
            <Field
              value={formik.values.title}
              formik={formik}
              name="title"
              sx={{ height: "40px" }}
              placeholder="Write a title..."
            />
          </TextAndField>
        </div>

        <div className="mt-6">
          <TextAndField>
            <p className="text-969 text-sm font-medium mb-[10px]">Description</p>
            <Field
              value={formik.values.details}
              formik={formik}
              name="details"
              multiline={true}
              maxRows={5}
              placeholder="Write a short description..."
            />
          </TextAndField>
        </div>

        <div className="mt-6 mb-[38px]">
          <TextAndField>
            <p className="text-969 text-sm font-medium mb-[10px]">Attachment</p>
            <input ref={inputRef} hidden type="file" onChange={(e) => {
              setImage(e.target.files[0])
              }} />
            <div className="py-4 px-5 rounded-lg border border-969 border-dashed text-center">
              <div onClick={() => inputRef.current.click()} className="cursor-pointer flex items-center gap-3 justify-center"> <UploadIcon /><p className="text-sm">Drop files to attach or <span className="text-0A9">browse</span></p></div>
            </div>
          </TextAndField>
          {imgPreview && (<div className="my-8">
            <p className="mb-5 text-sm text-color-424">1 File has been selected</p>
            <div className="relative w-fit">
            <img src={URL.createObjectURL(imgPreview)} alt="img" style={{ height: "100px" }} />
            <div onClick={()=> setImage(null)} className=" absolute top-0 right-0 cursor-pointer rounded-full bg-white flex justify-center">
            <BiX className="" size={20} />
            </div>

            </div>
          </div>)}
        </div>

        {(!isLoading && !formik.isSubmitting) && (<FillButton width="100%"
          text="Create Ticket"
          height="55px"
          sx={{ borderRadius: "8px" }}
          callBack={formik.handleSubmit} />)}

          <div className="flex items-center justify-center">

        {(isLoading || formik.isSubmitting) && <CircularProgress />}
          </div>


      </section>
    </CustomModal>
  )
}

export default CreateTicketModal