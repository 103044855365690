// function getDates (startDate, endDate) {
//     const st = startDate.split('-')
//     const stDate = new Date(st[0], st[1]-1, st[2])
//     const lt = endDate.split('-')
//     const ltDate = new Date(lt[0], lt[1]-1, lt[2])
//     const dates = []
//     let currentDate = stDate
//     const addDays = function (days) {
//       const date = new Date(this.valueOf())
//       date.setDate(date.getDate() + days)
//       return date
//     }
//     while(currentDate <= ltDate) {
//       dates.push(currentDate)
//       currentDate = addDays.call(currentDate, 1)
//     }
//     console.log(dates)
//     return dates
//   }

import moment from 'moment';

function getDates(startDate, endDate){
  const dates = [];
  let start = startDate
  let end = endDate
  while(!moment(start).isAfter(moment(end))){
    dates.push(moment(start).toDate())
    start = moment(start).add(1, 'days')
  }

  return dates;
}
  export default getDates