

import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const getReport = async (year) => {
  const response = await axios.get(`${conn_url}/api/v1/company/reports?year=${year}&page=1`, storage.getConfig());
  return response.data;

}