import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function BasicMenu({openListMenu, handleClose, items, setAction}) {
  const open = Boolean(openListMenu);
 
 

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={openListMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((val, index) => {
            return <MenuItem  onClick={()=>{val.click(); setAction(val.name)}}>
                <p className={index === 0 ? 'text-[#458452]': 'text-[#C15353]'}>{val.name}</p>
            </MenuItem>
        })}
      </Menu>
    </div>
  );
}