import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Synclogo from "../../Assets/light-logo.png";
import SyncdarkLogo from "../../Assets/sync_logo.svg";

import darkLogo from "../../Assets/logo_dark_mode.png";
import AppBarTop from "./AppBar";
import { useNavigate, useLocation } from "react-router";
import { RiHome6Line } from "react-icons/ri";
import { BsCalendar4Event, BsChat } from "react-icons/bs";
import { BiBot } from "react-icons/bi";
import { IoIosGitNetwork } from "react-icons/io";
import { Storage } from "../../Network/StorageClass/StorageClass";
import { FiChevronDown } from "react-icons/fi";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as tasks } from "../../Assets/task_icon.svg";
import report from "../../Assets/report.png";
import { Home } from "../../Network/ServiceClass/Home";
import { useDispatch } from "react-redux";
import { setActiveCompany } from "../../Store/Company";
import "./Styles/Sidebar.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import { font } from "./Styles/font";
import SwitchMode from "@mui/material/Switch";
import { useMemo } from "react";

import {ReactComponent as InactiveHomeIcon} from '../../Assets/icons/inactive_home.svg'
import {ReactComponent as ActiveHomeIcon} from '../../Assets/icons/active_home.svg'

import {ReactComponent as InactiveCompanyIcon} from '../../Assets/icons/inactive_company.svg'
import {ReactComponent as ActiveCompanyIcon} from '../../Assets/icons/active_company.svg'

import {ReactComponent as InactiveShiftIcon} from '../../Assets/icons/inactive_shift.svg'
import {ReactComponent as ActiveShiftIcon} from '../../Assets/icons/active_shift.svg'

import {ReactComponent as InactiveReportIcon} from '../../Assets/icons/inactive_report.svg'
import {ReactComponent as ActiveReportIcon} from '../../Assets/icons/active_report.svg'

import {ReactComponent as InactiveTaskIcon} from '../../Assets/icons/inactive_task.svg'
import {ReactComponent as ActiveTaskIcon} from '../../Assets/icons/active_task.svg'

import {ReactComponent as InactiveHelpIcon} from '../../Assets/icons/inactive_help.svg'
import {ReactComponent as ActiveHelpIcon} from '../../Assets/icons/active_help.svg'

import {ReactComponent as InactiveInventoryIcon} from '../../Assets/icons/inactive_inventory.svg'
import {ReactComponent as ActiveInventoryIcon} from '../../Assets/icons/active_inventory.svg'

import {ReactComponent as InactiveMSGIcon} from '../../Assets/icons/inactive_msg.svg'
import {ReactComponent as ActiveMSGIcon} from '../../Assets/icons/active_msg.svg'

const drawerWidth = 300;

export function SideBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;  


  const socketState = useSelector((state) => state?.socket?.socket);

  const home = new Home();
  const storage = new Storage();
  const { window, custom } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [styleMode, switchMode] = useState(false);

  const dispatch = useDispatch();
  const mode = React.useMemo(
    () => localStorage.getItem("color-theme"),
    [styleMode]
  );

  useEffect(() => {
    if (mode === "dark") {
      switchMode(true);
    } else {
      switchMode(false);
    }
  }, [styleMode, mode]);

  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const members =
    useSelector((state) => state?.company)?.activeCompany?.memberships || [];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSwitchMode = (e) => {
    if (e.target.checked) {
      localStorage.setItem("color-theme", "dark");
    } else {
      localStorage.setItem("color-theme", "light");
    }
    switchMode(e.target.checked);
    window?.location?.reload();
  };

  const switchCompany = async (id) => {
    home
      .switchCompany({ companyId: id })
      .then((value) => {
        storage.AuthStorage(value.data);
        dispatch(setActiveCompany(value.data.activeCompany));
        if (socketState && value.data?.activeCompany !== null) {
          const payload = {
            userId: value?.data?.user?.id,
            teamMemberId: value.data?.activeCompany?.memberships[0]?.id,
            branchId:
              value.data?.activeCompany?.memberships[0]?.assignedBranchId,
            companyId: value.data?.activeCompany?.id,
          };
          socketState.emit("join-company-group", payload);
          // console.log(value.data?.activeCompany?.id )
        }
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    home
      .getHomeData()
      .then((value) => {
        if (value.data.companies.length !== 0) {
          dispatch(setActiveCompany(value.data.activeCompany));
        }
      })
      .catch((err) => {});
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeCompany = useSelector((state) => state.company.activeCompany);
  const companies = useSelector((state) => state.company.companies);

  let companyList = [
    {
      name: "Locations",
      label: "branch",
      handleClick: () => {
        navigate("/branches");
      },
      privilege: [1,2]
    },

    {
      name: "Write ups",
      label: "write-up",
      handleClick: () => {
        navigate("/write-ups");
      },
    },
    {
      name: "Ticket Management",
      label: "ticket-management",
      handleClick: () => {
        navigate("/ticket-management");
      },
    },
    {
      name: "Job Roles",
      label: "job",
      handleClick: () => {
        navigate("/jobs");
      },
      privilege: [1,2]
    },

    {
      name: "Job Groups",
      label: "job-groups",
      handleClick: () => {
        navigate("/job-groups");
      },
      privilege: [1,2]
    },
    {
      name: "Announcements",
      label: "announcement",
      handleClick: () => {
        navigate("/announcement");
      },
    },
    {
      name: "Unavailable Days",
      label: "unavailable-days",
      handleClick: () => {
        navigate("/unavailable-days");
      },
    },
    {
      name: "Shift Types",
      label: "templates",
      handleClick: () => {
        navigate("/templates");
      },
      privilege: [1,2]
    },

    {
      name: "Holidays",
      label: "holiday",
      handleClick: () => {
        navigate("/holidays");
      },
    },

    {
      name: "Skill set",
      label: "skillset",
      handleClick: () => {
        navigate("/skillset");
      },
      privilege: [1,2]
    },

    {
      name: "Data",
      label: "data",
      handleClick: () => {
        navigate("/company-data");
      },
      privilege: [1,2]
    },
  ];

  if (
    members.filter((x) => x.userId === user.id)?.length > 0 &&
    [1, 2].includes(members.filter((x) => x.userId === user.id)[0]?.privilege)
  ) {
    companyList = [
      ...companyList,
      {
        name: "Rotation Management",
        label: "rotation-management",
        handleClick: () => {
          navigate("/rotation-management");
        },
      },

      {
        name: "Company Details",
        label: "company-details",
        handleClick: () => {
          navigate("/company-details");
        },
      },
    ];
  }

  const inventoryList = [
    {
      name: "Inventory Items",
      label: "inventory-items",
      handleClick: () => {
        navigate("/inventory-items");
      },
    },
    {
      name: "Transfer Items",
      label: "transfer-items",
      handleClick: () => {
        navigate("/transfer-items");
      },
    },
    {
      name: "Transfered Items List",
      label: "transfered-items-list",
      handleClick: () => {
        navigate("/transfered-items-list");
      },
    },
    {
      name: "Order Request",
      label: "order-request",
      handleClick: () => {
        navigate("/stock-order-request");
      },
    },
    {
      name: "Back Order Request",
      label: "back-order-request",
      handleClick: () => {
        navigate("/back-order-request");
      },
    },
    {
      name: "Quick withdraw",
      label: "quick-withdrawal",
      handleClick: () => {
        navigate("/quick-withdrawal");
      },
    },
    {
      name: "Create order request",
      label: "create-order-request",
      handleClick: () => {
        navigate("/create-order-request");
      },
    },

    {
      name: "Logs",
      label: "inventory-logs",
      handleClick: () => {
        navigate("/inventory-logs");
      },
    },

    {
      name: "Inventory data",
      label: "inventory-data",
      handleClick: () => {
        navigate("/inventory-data");
      },
    },

    {
      name: "Suppliers / Manufacturers",
      label: "suppliers",
      handleClick: () => {
        navigate("/suppliers");
      },
    },

    {
      name: "Measurement",
      label: "measurement",
      handleClick: () => {
        navigate("/inventory-measurement");
      },
    },
  ];

  const helpList = [
    {
      name: "Tutorials",
      label: "tutorials",
      handleClick: () => {
        navigate("/tutorials");
      },
    },
  ];

  const shiftList = [
    {
      name: "Schedules",
      label: "schedules",
      handleClick: () => {
        navigate("/schedules");
      },
    },
    {
      name: "Location Schedules",
      label: "branch-schedule",
      handleClick: () => {
        navigate("/branch-schedule");
      },
    },
    {
      name: "Combined Schedules",
      label: "combined-schedule",
      handleClick: () => {
        navigate("/combined-schedule");
      },
    },
    {
      name: 'Limbo Schedule',
      label: 'limbo-schedule',
      handleClick: () => {
        navigate('/limbo-schedule');
      },
      privilege: [1,2]
    },

    // {
    //   name: 'Available Shifts',
    //   label: 'shift-slot',
    //   handleClick: () => {
    //     navigate('/shift-slot');
    //   },
    // },
    // {
    //   name: 'Booked Shifts',
    //   label: 'shift-board',
    //   handleClick: () => {
    //     navigate('/shift');
    //   },
    // },
    {
      name: "Swap offers",
      label: "swap-offers",
      handleClick: () => {
        navigate("/swap-offers");
      },
    },
    {
      name: "Time off",
      label: "time-off",
      handleClick: () => {
        navigate("/time-off");
      },
    },
    members.filter((x) => x.userId === user.id)?.length > 0 &&
      [1, 2].includes(
        members.filter((x) => x.userId === user.id)[0]?.privilege
      ) && {
        name: "Clock in Clock out",
        label: "clock-in-clock-out",
        handleClick: () => {
          navigate("/clock-in-clock-out");
        },
      },
  ];

  const trimText = (text) => {
    if (text?.length > 17) {
      return text.slice(0, 14) + " ...";
    }
    return text;
  };

  const drawer = (
    <div className="bg-90F dark:bg-A06 h-full">
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {companies !== null &&
          companies.map((value, index) => (
            <MenuItem
              key={index}
              sx={{ fontSize: "15px" }}
              onClick={() => {
                handleClose();
                switchCompany(value.id);
              }}
            >
              {value.name}
            </MenuItem>
          ))}
      </Menu>

      <div className="bg-90F w-[100%] p-5 dark:bg-A06 my-10">
        <img
          src={localStorage.getItem("color-theme") === "dark" ? SyncdarkLogo : SyncdarkLogo}
          alt="logo"
          className=" w-[80%] my-0 mx-auto"
        />
      </div>

      <Divider className="dark:border-0 border-[0.2px] mb-2 border-FCF" />
      <section className="bg-D57 flex items-center justify-between gap-3 pl-[30px] pr-5 py-4 dark:bg-D57 dark:border-0">
        {activeCompany !== null && (
          <>
            <div className="flex items-center gap-2">
              {activeCompany?.logo?.url?.length > 0 ? (
                <img
                  className="rounded-full user-img "
                  src={activeCompany?.logo?.url}
                  alt="logo"
                ></img>
              ) : (
                <div className="user-img dark:bg-white"></div>
              )}

              <div className="text-[white] dark:text-black">
                <p className="font-semibold text-[14px] text-white dark:text-white">
                  {activeCompany !== null && trimText(activeCompany?.name)}
                </p>
                <p className="text-sm font-bold text-3F3 dark:text-white">
                  {activeCompany !== null &&
                    trimText(activeCompany?.category?.name)}
                </p>
              </div>
            </div>

            <div
              className="text-white text-[22px] cursor-pointer dark:text-black"
              onClick={handleClick}
            >
              <FiChevronDown className="dark:text-white" />
            </div>
          </>
        )}
      </section>

      {activeCompany !== null && (
        <List
          className="flex flex-col  bg-90F dark:bg-A06"
          sx={{ marginTop: "30px" }}
        >
          <CustomList
            text="Home"
            active={custom === "home" ? true : false}
            handleClick={() => {
              navigate("/home");
            }}
            Icon={custom === "home" ?  ActiveHomeIcon : InactiveHomeIcon}
            withIcon
          />

          <CustomListWithAcordion
            styleMode={mode === "dark"}
            text="Company"
            privilege={privilege}
            active={custom === "company" ? true : false}
            subActive={props.subCustom !== undefined ? props.subCustom : ""}
            // Icon={IoIosGitNetwork}
            childrenList={companyList}
            Icon={custom === "company" ?  ActiveCompanyIcon : InactiveCompanyIcon}
            withIcon
          />

          {/* <CustomList
          text="Jobs"
          Icon={BsGrid1X2}
          active={custom === 'job' ? true : false}
          handleClick={() => {
            navigate('/jobs');
          }}
        /> */}

          <CustomListWithAcordion
            text="Shifts"
           styleMode={mode === "dark"}
            active={custom === "shift" ? true : false}
            subActive={props.subCustom !== undefined ? props.subCustom : ""}
            Icon={custom === "shift" ?  ActiveShiftIcon : InactiveShiftIcon}
            withIcon
            privilege={privilege}
            childrenList={shiftList}
            initial={() => {
              navigate("/rotation");
            }}
          />

          {members.filter((x) => x.userId === user.id)?.length > 0 &&
            [1, 2].includes(
              members.filter((x) => x.userId === user.id)[0]?.privilege
            ) && (
              <CustomList
                text="Reports"
                active={custom === "report" ? true : false}
                Icon={custom === "report" ?  ActiveReportIcon : InactiveReportIcon}
                withIcon
                handleClick={() => {
                  navigate("/reports");
                }}
              />
            )}

          <CustomList
            text="Tasks"
            active={custom === "task" ? true : false}
            Icon={custom === "task" ?  ActiveTaskIcon : InactiveTaskIcon}
            withIcon
            handleClick={() => {
              navigate("/tasks");
            }}
          />

          <CustomList
            text="Messages"
            active={custom === "messages" ? true : false}
            Icon={custom === "messages" ?  ActiveMSGIcon : InactiveMSGIcon}
            withIcon
            handleClick={() => {
              navigate("/messages");
            }}
          />

          {members.filter((x) => x.userId === user.id)?.length > 0 &&
            [1, 2].includes(
              members.filter((x) => x.userId === user.id)[0]?.privilege
            ) &&(<CustomListWithAcordion
            text="Inventory (BETA)"
           styleMode={mode === "dark"}
            active={custom === "inventory" ? true : false}
            subActive={props.subCustom !== undefined ? props.subCustom : ""}
            // Icon={BsCalendar4Event}
            Icon={custom === "inventory" ?  ActiveInventoryIcon : InactiveInventoryIcon}
            withIcon
            childrenList={inventoryList}
            privilege={privilege}
            initial={() => {
              navigate("/inventory");
            }}
          />)}

          <CustomListWithAcordion
            text="Help"
           styleMode={mode === "dark"}
            active={custom === "help" ? true : false}
            subActive={props.subCustom !== undefined ? props.subCustom : ""}
            Icon={custom === "help" ?  InactiveHelpIcon : ActiveHelpIcon}
            withIcon
            privilege={privilege}
            childrenList={helpList}
            initial={() => {
              navigate("/help");
            }}
          />

          <div className="flex items-center gap-3 pl-[35px]">
            <p className="font-bold text-0A0">Dark Mode</p>
            <SwitchMode checked={styleMode} onChange={handleSwitchMode} />
          </div>
        </List>
      )}
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "white",
          color: "black",
        }}
        className="dark:bg-A06"
      >
        <AppBarTop
          layoutProps={props.layoutProps}
          layoutClass={props.layoutClass}
          custom={props.customTitle}
          withoutHeaderTitle={props.withoutHeaderTitle}
        />
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          bgcolor: "white",
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: "block",
              sm: "none",
              bgcolor: mode === "ligth" ? "white" : "#1A1A1A",
            },
            "& .MuiDrawer-paper": {
              borderColor: "red",
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block", bgcolor: "white" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              border:
                localStorage.getItem("color-theme") === "dark" ? "none" : "",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        className={`dark:bg-primary mt-12 min-h-screen ${props.layoutClass} ${props.mainClass}`}
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

SideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // window: PropTypes.func,
};

const CustomList = ({
  text,
  Icon = RiHome6Line,
  withIcon = false,
  handleClick,
  useImage = false,
  img = report,
  active = false,
}) => {
  return (
    <ListItem
      key={text}
      disablePadding
      className={
        !active
          ? "hover:text-555 text-0A0 bg-90F dark:bg-A06 mb-[34px]"
          : " bg-gradient-to-r from-D57 from-[percentage:2%] via-F13 via-[percentage:3%] to-F13 to-100% text-D57 mb-[34px] h-[54px]"
      }
    >
      <ListItemButton
        className="flex flex-row gap-4 items-center"
        sx={{ paddingLeft: "35px" }}
        onClick={handleClick}
      >
        {withIcon && !useImage && <Icon className="text-[22px]" />}

        {withIcon && useImage && (
          <img
            src={img}
            width="30px"
            style={{ marginLeft: "-5px" }}
            alt="user"
          />
        )}

        <Typography sx={{ ...font, fontWeight: "bold !important" }}>
          {text}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

const CustomListWithAcordion = ({
  text,
  Icon = RiHome6Line,
  withIcon,
  initial,
  subActive,
  active = false,
  styleMode=false,
  privilege,
  childrenList = [{ name: "child1", handleClick: () => {} }],
}) => {
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    // backgroundColor: active === true ? "#F5F5F5" : "",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));
  return (
    <Accordion
      className={`hover:bg-gray-100 ${!styleMode ? "!bg-90F": "!bg-A06"}  mb-[34px] `}
      defaultExpanded={active === true ? true : false}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            className={
              active === true ? "font-bold text-D57" : "text-0A0"
            }
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={`${active ? "bg-gradient-to-r from-D57 from-[percentage:2%] via-F13 via-[percentage:3%] to-F13 to-100% text-D57 h-[54px]": ""}`}
      >
        <Box
          className={`flex flex-row gap-4 pl-[20px] ${
            active === true
              ? "font-bold text-D57 dark:text-EBE "
              : "text-0A0"
          } hover:text-555 font-bold`}
        >
          {withIcon && <Icon className="text-[22px]" />}
          <Typography
            sx={{ ...font, fontWeight: "bold !important" }}
            className={active === true
              ? "text-D57": "text-0A0 dark:text-EBE "}
          >
            {text}
          </Typography>
        </Box>
      </AccordionSummary>

      {childrenList.map((val, index) => {
        return ((val?.privilege === undefined) || (val?.privilege.includes(privilege))) && (
          <AccordionDetails
            key={index}
            className={`hover:cursor-pointer ${subActive === val.label ? "  flex items-center": ""}`}
            onClick={val.handleClick}
          >
            <Typography
              sx={{
                ...font,
                fontSize: 14,
                fontWeight: "700",
                paddingLeft: "60px",
                color:
                  subActive === val.label
                    ? "#00AD57"
                    : localStorage.getItem("color-theme") === "dark"
                    ? "#CCCCCC"
                    : "#CCCCCC",
              }}
              className="font-bold"
            >
              {val.name}
            </Typography>
          </AccordionDetails>
        );
      })}
    </Accordion>
  );
};
