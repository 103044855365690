import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();


export const getAllTickets = async (params) => {
    try {
        let queryString = '';
    Object.keys(params).map((x) => {
      if(params[x]?.toString()){
        if(queryString.startsWith('?')){
          queryString += `&${x}=${params[x]}`
        } else {
          queryString = `?${x}=${params[x]}`
        }
      }
      return x
    })

        const response = await axios.get(`${conn_url}/api/v1/trouble-ticket${queryString}`, storage.getConfig());
        return response.data
    } catch(err) {
        throw new Error(err)
    }
}


export const createATicket = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/trouble-ticket`, data, storage.getConfig());
        return response.data
    } catch(err) {
        throw new Error(err?.response?.data?.message)
    }
}