export const statusColor = {
    pending_approval: {
      bg: 'bg-[#FED6A7]',
      color: 'text-[#C24F4F]'
    },
    received: {
      bg: 'bg-[#CDFFED]',
      color: 'text-[#458970]'
    },

    approved: {
      bg: 'bg-[#CDFFED]',
      color: 'text-[#458970]'
    },

    shipped: {
      bg: 'bg-[#e8f4f8]',
      color: 'text-[#72bcd4]'
    },

    requested: {
      bg: 'bg-[#ffd680]',
      color: 'text-[#ffa766]'
    },
  
    ordered: {
      bg: 'bg-[#CDFFED]',
      color: 'text-[#458970]'
    },
  
    partial: {
      bg: 'bg-[#C3F6F3]',
      color: 'text-[#458970]'
    },

    backorder: {
      bg: 'bg-[#FED6A7]',
      color: 'text-[#C24F4F]'
    },
  }