import React, { useEffect, useState } from 'react';
import { BsMap, BsSearch } from 'react-icons/bs';
import { Branch } from '../../../../../Network/ServiceClass/Branch';
import { Loader } from '../../../../Widgets/Loader';
import './Branches.css';
import CreateBranchModal from './Modal/CreateBranchModal';
import { BranchButton } from './Widget/BranchButton';
import AlertDialog from '../../../../Widgets/SuccessDialog';
import PlaceHolderOne from '../../../../Widgets/placeholders/PlaceHolderOne';
import branchesPlaceholderImg from '../../../../../Assets/placholders/branchesPlaceholder.png'
import MapIcon from '../../../../../Assets/map_icon.svg'
import ScanCodeIcon from '../../../../../Assets/scan-barcode.svg'
import ViewIcon from '../../../../../Assets/view_icon.svg'
import FlagIcon from '../../../../../Assets/flag.svg'
import DeleteIcon from '../../../../../Assets/trash.svg'
import { FillButton } from '../../../../Widgets/FormUtils/FormUtils'
import { CgList } from 'react-icons/cg'
import { BsFlag, BsTrashFill } from 'react-icons/bs'
import { useNavigate } from 'react-router';
import { IoQrCode } from 'react-icons/io5';
import ViewQRCode from '../../BranchTab/Modal/QRModal';
import GoogleMapView from '../../BranchTab/Modal/GoogleMapModal';
import Tooltip from '@mui/material/Tooltip';

export const Branches = ({ setIndex, setBranchId, setBranchName }) => {
  const [openModal, setModalView] = useState(false)
  const [openMapModal, setMapModalView] = useState(false)
  const navigate = useNavigate()
  const branchClass = new Branch();
  const [branchList, setBranchList] = useState([]);
  const [isloading, setisLoading] = useState(true);

  const [searchBranchName, setSearchBranchName] = useState('');
  const [createBranch, setCreateBranch] = useState(false);
  const [selectedRow, selectBranch] = useState({});
  const [selectGeodata, setGeoData] = useState({});

  const handleOpen = () => {
    setCreateBranch(true);
  };
  const handleClose = () => {
    setCreateBranch(false);
  };

  const callBack = () => {
    setisLoading(true);
    branchClass
      .getBranches()
      .then((value) => {
        setBranchList(value.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  useEffect(() => {
    callBack();
  }, []);

  // handling clicking on a branch
  const handleClickBranch = (value) => {
    setBranchId(value.id);
    setBranchName(value.name);
    setIndex(2);
  };

  const handleBranchSearch = (branchName) => {
    const filtereddata = branchList?.filter((branch) => branch?.name?.toLowerCase().includes(branchName?.toLowerCase()))
    return filtereddata;
  }

  const handleSearchAction = (e) => {
    setSearchBranchName(e.target.value);
  }

  const handleOpenModal = () => {
    setModalView(true)
  }

  const handleCloseModal = () => {
    setModalView(false)
  }

  const handleOpenMapModal = () => {
    setMapModalView(true)
  }

  const handleCloseMapModal = () => {
    setMapModalView(false)
  }

  const tableRow = "dark:text-white border border-6E6 dark:border-333"

  return (
    <>
      <section className="branches_ mb-40">
        <AlertDialog />

        {!isloading ? (<>
          {(handleBranchSearch(searchBranchName).length > 0) && <section className="inner-section">
            <div className="header" style={{ marginTop: '0px' }}>
              <div className="search-box !justify-start items-center dark:bg-828">
                <div>
                  <BsSearch className="dark:text-white" />
                </div>
                <input className='dark:text-white' onChange={handleSearchAction} type="text" placeholder="Search location name" />
              </div>
              <BranchButton handleOpen={handleOpen} data-tour="step3" />
            </div>


            <div className="branch-table" data-tour="step2">
              <table className='text-left' cellPadding={0} cellSpacing={0}>
                <thead className='dark:bg-444 bg-6E6'>
                  <tr className="dark:hover:bg-transparent">
                    <th className='dark:text-white border border-6E6 dark:border-333'>Location name</th>
                    <th className='dark:text-white border border-6E6 dark:border-333'>Location address</th>
                    <th className='dark:text-white border border-6E6 dark:border-333 w-[230px]'>Number of team members</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* onClick={() => handleClickBranch(val)} */}
                  {handleBranchSearch(searchBranchName)?.map((val, index) => (
                    <tr key={index} className=' font-bold dark:hover:bg-transparent dark:bg-828'>
                      <td className='dark:text-white dark:border-333 border border-6E6'>{val.name}</td>
                      <td className='dark:text-white dark:border-333 border border-6E6'>{val.address}</td>
                      <td className='dark:text-white dark:border-333 border border-6E6 text-center'>{val.memberCount}</td>
                      <td className='dark:border-333 border border-6E6 '>
                        <div className='flex items-center gap-3'>
                          {val["longitude"] !== null && val["latitude"] !== null && (

                            <Tooltip title="View on map" placement='top-start' >
                              <div>
                                <img src={MapIcon} alt="logo" className='cursor-pointer dark:text-white' onClick={() => {
                                setGeoData(val);
                                handleOpenMapModal();

                                }}/>
                              </div>
                            </Tooltip>


                          
                          )}

                          <Tooltip title="Scan Qrcode" placement='top-start'>
                            <div>
                              <img src={ScanCodeIcon} alt="scan_code" className='cursor-pointer dark:text-white' onClick={() => {
                                handleOpenModal();
                                selectBranch(val)
                              }} />

                            </div>
                          </Tooltip>

                          <Tooltip title="View members" placement='top-start'>
                            <div>
                              <img src={ViewIcon} alt="member" className="cursor-pointer dark:text-white" onClick={() => handleClickBranch(val)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="Benchmark" placement='top-start'>
                            <div>
                              <img src={FlagIcon} alt="flag" className="cursor-pointer dark:text-white" onClick={() => navigate(`/branches/bench-marks/${val.id}`)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="Delete" placement='top-start'>
                            <div>
                              <img src={DeleteIcon} alt="delete" className="cursor-pointer text-red-700 dark:text-white" />
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>}
          {handleBranchSearch(searchBranchName).length < 1 &&
            <div className='flex flex-col items-center justify-center gap-10'>
              <PlaceHolderOne pic={branchesPlaceholderImg} text='No locations created yet' />
              <FillButton text='Create location' width={'300px'} height={'50px'} callBack={handleOpen} />
            </div>
          }
        </>) : (
          <Loader />
        )}

        {
          openModal && <ViewQRCode data={selectedRow} open={openModal} handleClose={handleCloseModal} />
        }

        {
          openMapModal && <GoogleMapView data={selectGeodata} open={openMapModal} handleClose={handleCloseMapModal}  />
        }


        {createBranch && <CreateBranchModal
          open={createBranch}
          handleClose={handleClose}
          callback={callBack}
        />}
      </section>
    </>
  );
};
