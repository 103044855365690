import { useState, useEffect } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { Storage } from '../../../Network/StorageClass/StorageClass';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import { AiOutlineDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import AppLayout from '../../Widgets/AppLayout';
import { getInventoryMeasurementUnitThunk } from '../../../Store/Inventory';
import { NewUnitModal } from './Modal/NewUnitModal';
import { DeleteModal } from './Modal/DeleteModal';
import SearchInput from '../../Widgets/SearchInput';
import { TableWrapper, TableHeader, Td, Tr } from '../../Widgets/Table/TableWrapper';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { measurementTutorial } from '../mock';

export const InventoryMeasurement = () => {
  const storage = new Storage();
  const dispatch = useDispatch()
  const priviledge = storage.getActiveCompany().memberships[0].privilege;
  const loading = useSelector(state => state.inventory)?.loading

  const units = useSelector(state => state.inventory)?.measurementUnits


  const [openDeleteModal, setToggleDeleteModal] = useState(false);
  const [openAddUnit, setOpenAddUnit] = useState(false);

  const [data, setData] = useState(null);

  const [searched, setSearched] = useState('');

  const handleOpen = () => {
    setOpenAddUnit(true);
  };

 
  const handleClose = () => {
    setOpenAddUnit(false);
  };

  const closeDeleteModal = () => {
    setToggleDeleteModal(false)
  }

  const handleOpenDeleteModal = (value) => {
    setToggleDeleteModal(true)
    setData(value);
  }

  const callback = () => {
    dispatch(getInventoryMeasurementUnitThunk())
  };

  useEffect(() => {
    callback();
  }, []);


  const handleSearch = (val) => {
    const filteredData = units?.filter((x) => x?.name?.toLowerCase().includes(val?.toLowerCase()))
    return filteredData;
  }

  const handleOnSearch = (e) => {
    setSearched(e.target.value);
  }

  return (
    <section className="homepage">
      <DeleteModal
      open={openDeleteModal}
      data={data}
      handleClose={closeDeleteModal}
      loading={loading}
      callback={callback}
      />


      <AppLayout
        custom="inventory"
        subCustom="measurement"
        title="Inventory Measurement"
        customTitle=" "
        subtitle="Managing measurement"
        RightComponent={<TutorialPanel items={measurementTutorial} />}
      >

        <div className="border-1 dark:border-333 rounded-md flex flex-col gap-3 p-7 h-[70vh]">

          <div className="flex justify-between items-center">

            <SearchInput 
              inputStyle='placeholder:!text-grey-600'
              placeholder='Search units'
              onChange={handleOnSearch}
            />

            {[1, 2].includes(priviledge) && (
              
              <ButtonWithIcon title='New' type='text' handleClick={handleOpen}/>
            )}
          </div>

          {!loading && (
              <TableWrapper className='mb-12'>

                <table className="">
                  <thead>
                    <TableHeader>
                      <Td className='py-4 w-[35%]'>Name</Td>
                      <Td className='w-[35%]'>Description</Td>
                      <Td className='w-[20%]'>Symbol</Td>
                      <Td className='w-[5%] !border-none'></Td>

                     
                    </TableHeader>
                  </thead>
                  <tbody>
                    {handleSearch(searched)?.map((value, index) => {
                    
                      return (
                        <Tr key={index}
                          className=""
                        >
                          <Td className='py-4'>
                            {value.name}
                          </Td>
                          <Td>
                            {value?.description}
                          </Td>
                          <Td> {value.symbol} </Td>
                          
                          <Td className={'!border-none'} onClick={() => handleOpenDeleteModal(value)}>
                            <AiOutlineDelete />
                          </Td>
                        </Tr>
                      );
                    })}
                  </tbody>
                </table>
              </TableWrapper>
          )}

          {loading && (
            <Stack mt={5} direction="row" justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
        </div>
      </AppLayout>

      <NewUnitModal
        open={openAddUnit}
        handleClose={handleClose}
        callback={callback}
      />
    </section>
  );
};
