import DateLabel from '../../../Widget/DateLabel/DateLabel';
import { Loader } from '../../../../../Widgets/Loader';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import InitialsGen from '../../../../../Widgets/InitialsGen';
import { CiSearch } from 'react-icons/ci';
import { ClickAwayListener } from '@mui/material';
import tinycolor from 'tinycolor2';
import { useSelector } from 'react-redux';


function RotationView({
  displayMode,
  selectedTemplate,
  dates,
  handleTotalSlot,
  calFirstTrack,
  handleRelevantSlot,
  calSecondTrack,
  loading,
  loaderLoading,
  handleSchedule,
  selectedMultiBranch,
  jobRoles,
  userId,
  handleGenerateTags,
  offset,
  rowsPerPage,
  handleRoleTags,
  handleCloseDropdown,
  handleCellRender,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenPopper,
  setOpenPopper,
  handleOpenUserProfile,
  teamMembers,
  selectMember,
  handleOpenPTO,
  selectPTODate,
  setInputSearchBranch,
  handleToggleDropdown,
  handleOpenScheduleOption,
  shiftSchedules,
  handleCheckPTOStatus,
  onDragOver,
  onDrop,
  getBackgroundColor,
  handleOpenPopperShiftDetails,
  setOpenPopperShiftDetails,
  swapPic,
  selectedSchedule,
  progressState,
  cellLoading,
  openDropdown,
  openPTO,
  inputSearchBranch,
  selectBranch,
  handleCreateShift,
  handleSelectedBranch,
  handleSearchBranch,
  jobroleList,
  branchSelected,
  ongoingState,
  memberships,
  handleCancelSchedule,
  handleEnableShiftSwap,
  toggleAvailableSlot,
  isOpenSlotOpen,
  openSlots,
  dropdownSlotClass,
  onDrag,
  onDragStart,
  onDragStartReassignment

}) {
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;  


  const handleRenderScheduleCell = (schedule,lastBorder=true, isAdmin=true) => {
    return schedule?.mode === 'data' && (
      <tr>
        <td
          className={`border-l ${lastBorder ? ``: "border-b"}  px-2 flex items-center py-3 sticky left-0 bg-white dark:bg-A06 dark:border-333 z-20`}
          onMouseEnter={(event) => {
            let timeoutId = setTimeout(() => {
              handleOpenPopper(event, schedule);
            }, 500);
            event.currentTarget.addEventListener(
              'mouseleave',
              () => {
                clearTimeout(timeoutId);
              }
            );
          }}
          onMouseLeave={() =>
            setOpenPopper(null)
          }
        >
          <div
            className="cursor-pointer"
            onClick={() =>
              handleOpenUserProfile(schedule)
            }
          >
            {schedule?.user?.imageUrl ? (
              <img
                className="rounded-full h-10 w-10 mr-2"
                src={schedule?.user?.imageUrl}
                alt="logo"
              />
            ) : (
              <InitialsGen
                width={40}
                name={schedule?.user?.name}
                bgColor="#00AD57"
              />
            )}
          </div>
          <div>
          <p className="font-bold text-xs text-555 dark:text-white">
            {schedule?.user?.name[0]}.
            {
              schedule?.user?.name?.split(
                ' '
              )[1]
            }
            {schedule?.user?.skills?.filter(
              (x) => x?.skillSetCode !== ''
            )?.length > 0 &&
              schedule?.user?.skills?.map(
                (x) => (
                  <span>
                    {' '}
                    (
                    {x?.skillSetCode?.length > 0
                      ? x?.skillSetCode
                      : x?.name
                          ?.slice(0, 2)
                          ?.toUpperCase()}
                    )
                  </span>
                )
              )}
          </p>

          <div className="flex items-center gap-2">
            <p className="text-[9px] text-999 dark:text-white">
              {schedule?.user?.jobRole?.title}
            </p>
            <div className="">
              <div
                style={{
                  backgroundColor:
                    schedule?.user?.jobRole
                      ?.hexcode !== 'NULL'
                      ? schedule?.user?.jobRole
                          ?.hexcode
                      : 'white',
                }}
                className={`w-[6px] h-[6px] rounded-full bg-[${
                  schedule?.user?.jobRole
                    ?.hexcode !== 'NULL'
                    ? schedule?.user?.jobRole
                        ?.hexcode
                    : 'white'
                }]`}
              />
            </div>
          </div>
          </div>
          {/* <div className="absolute right-2 top-4"><p className="text-sm font-bold dark:text-white">{shiftSchedules?.filter(
                (shiftSchedule) => {
                  return (
                    schedule?.user?.id ===
                      shiftSchedule?.user?.id
                  );
                }
              )?.length || ""}</p></div> */}
        </td>
        <td
        onClick={handleCloseDropdown}
        className={`border sticky left-[170px] z-20 h-10 p-1 dark:border-333 w-[80px] bg-white dark:bg-A06`}
        >
        <p className="text-sm text-center font-bold dark:text-white">{shiftSchedules?.filter(
                (shiftSchedule) => {
                  return (
                    schedule?.user?.id ===
                      shiftSchedule?.user?.id
                  );
                }
              )?.length || ""}</p>
        </td>
        {dates?.map((date, dateIndex) => (
          <td
          onDoubleClick={() => {
            const selectedTeamMember = teamMembers.find((x) => x.user.id === schedule?.user?.id)
            selectMember(selectedTeamMember);
            selectPTODate(moment(date).format(
              'yyyy-MM-DD'
            ));
            handleOpenPTO(schedule?.user?.id)}}
            onClick={() => {
              setInputSearchBranch('');
              const cellClicked = `${moment(date).format(
                'yyyy-MM-DD'
              )}${schedule?.user?.id}`
              handleToggleDropdown(cellClicked);
              handleOpenScheduleOption(cellClicked);
            }}
            className={`border relative h-10 p-1 dark:border-333 ${
              [0, 6].includes(date.getDay())
                ? 'bg-calendar-weekend'
                : ''
            } w-[200px] dark:bg-A06`}
          >
             {
                handleCheckPTOStatus( schedule?.user?.id,date) ? <div className="flex items-center justify-center cursor-not-allowed bg-ccc text-lg text-white h-full dark:bg-444"><p>PTO</p></div> :
              

            <div
            draggable
            onDragStart={(e) => onDragStartReassignment(e, date, schedule)}
            onDragOver={(e) => onDragOver(e, date)}
            onDrop={(e) => onDrop(e,date, schedule?.user)}
              className={` h-full rounded flex items-center justify-center dark:bg-A06`}
              style={{
                backgroundColor:
                  getBackgroundColor(
                    schedule,
                    date
                  ),
                  color: tinycolor(getBackgroundColor(schedule, date)).isDark() ? "white": "black"
              }}
            >
              {shiftSchedules?.length > 0 &&
              shiftSchedules?.filter(
                (shiftSchedule) => {
                  return (
                    moment(
                      shiftSchedule?.start
                    ).format('yyyy-MM-DD') ===
                      moment(date).format(
                        'yyyy-MM-DD'
                      ) &&
                    schedule?.user?.id ===
                      shiftSchedule?.user?.id
                  );
                }
              )?.length > 0 ? (
                <div
                  className="flex gap-2 items-center flex-col relative"
                  onMouseEnter={(event) => {
                    const [
                      shiftScheduleFilter,
                    ] = shiftSchedules?.filter(
                      (shiftSchedule) => {
                        return (
                          moment(
                            shiftSchedule?.start
                          ).format(
                            'yyyy-MM-DD'
                          ) ===
                            moment(date).format(
                              'yyyy-MM-DD'
                            ) &&
                          schedule?.user?.id ===
                            shiftSchedule?.user
                              ?.id
                        );
                      }
                    );
                    let timeoutId = setTimeout(
                      () => {
                        handleOpenPopperShiftDetails(
                          event,
                          shiftScheduleFilter
                        );
                      },
                      500
                    );
                    event.currentTarget.addEventListener(
                      'mouseleave',
                      () => {
                        clearTimeout(timeoutId);
                      }
                    );
                  }}
                  onMouseLeave={() =>
                    setOpenPopperShiftDetails(
                      null
                    )
                  }
                >
                  <p className="text-[13px] text-center font-bold">
                    {
                      shiftSchedules?.filter(
                        (shiftSchedule) => {
                          return (
                            moment(
                              shiftSchedule?.start
                            ).format(
                              'yyyy-MM-DD'
                            ) ===
                              moment(
                                date
                              ).format(
                                'yyyy-MM-DD'
                              ) &&
                            schedule?.user
                              ?.id ===
                              shiftSchedule
                                ?.user?.id
                          );
                        }
                      )[0]?.slot?.branch?.name
                    }
                  </p>
                  <p className="text-[9px] text-left font-bold">
                    {moment(
                      shiftSchedules?.filter(
                        (shiftSchedule) => {
                          return (
                            moment(
                              shiftSchedule?.start
                            ).format(
                              'yyyy-MM-DD'
                            ) ===
                              moment(
                                date
                              ).format(
                                'yyyy-MM-DD'
                              ) &&
                            schedule?.user
                              ?.id ===
                              shiftSchedule
                                ?.user?.id
                          );
                        }
                      )[0]?.start
                    ).format('h:mma')}
                    -
                    {moment(
                      shiftSchedules?.filter(
                        (shiftSchedule) => {
                          return (
                            moment(
                              shiftSchedule?.start
                            ).format(
                              'yyyy-MM-DD'
                            ) ===
                              moment(
                                date
                              ).format(
                                'yyyy-MM-DD'
                              ) &&
                            schedule?.user
                              ?.id ===
                              shiftSchedule
                                ?.user?.id
                          );
                        }
                      )[0]?.end
                    ).format('h:mma')}
                  </p>
                  <div className="absolute top-0 right-[-10px]">
                    {shiftSchedules?.filter(
                      (shiftSchedule) => {
                        return (
                          moment(
                            shiftSchedule?.start
                          ).format(
                            'yyyy-MM-DD'
                          ) ===
                            moment(date).format(
                              'yyyy-MM-DD'
                            ) &&
                          schedule?.user?.id ===
                            shiftSchedule?.user
                              ?.id
                        );
                      }
                    )[0].swappable && (
                      <img
                        src={swapPic}
                        alt="swap"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <p className='text-black dark:text-white'>
                  {
                  (selectedSchedule ===
                    `${moment(date).format(
                      'yyyy-MM-DD'
                    )}${schedule?.user?.id}` &&
                    progressState.includes(moment(date).format(
                      'yyyy-MM-DD'
                    )))
                  // ongoingState.includes(`${schedule?.user?.id}${dateIndex}`)
                    &&
                  shiftSchedules?.filter(
                    (shiftSchedule) => {
                      return (
                        moment(
                          shiftSchedule?.start
                        ).format(
                          'yyyy-MM-DD'
                        ) ===
                          moment(date).format(
                            'yyyy-MM-DD'
                          ) &&
                        schedule?.user?.id ===
                          shiftSchedule?.user
                            ?.id
                      );
                    }
                  )?.length === 0 &&
                  cellLoading
                    ? 'Loading....'
                    : ''}
                </p>
              )}
              {selectedSchedule ===
                `${moment(date).format(
                  'yyyy-MM-DD'
                )}${schedule?.user?.id}` &&
                shiftSchedules?.filter(
                  (shiftSchedule) => {
                    return (
                      moment(
                        shiftSchedule?.start
                      ).format('yyyy-MM-DD') ===
                        moment(date).format(
                          'yyyy-MM-DD'
                        ) &&
                      schedule?.user?.id ===
                        shiftSchedule?.user?.id
                    );
                  }
                )?.length === 0 &&
                openDropdown && !openPTO && [1, 2].includes(
                  memberships[0]?.privilege
                ) && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="bg-white dark:bg-292 z-50 top-[80%] max-h-[200px] overflow-y-auto right-0 absolute w-max p-4 shadow-select"
                  >
                

                    <div className="border-1 flex flex-row gap-1 dark:text-white items-center sticky top-0 bg-white dark:bg-A06 py-[10px] px-[5px] rounded-md dark:border-333">
                      <CiSearch />
                      <input
                        className=' focus:outline-none dark:bg-A06 '
                        type="text"
                        value={
                          inputSearchBranch
                        }
                        placeholder="search"
                        onChange={(e) =>
                          setInputSearchBranch(
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="min-h-[40px] bg-white">
                    <div className="mt-2 py-2">
                    <p
                          onClick={() => {
                            selectBranch(
                              {id: schedule.branchId}
                            );
                            if (
                              schedule?.user
                                ?.secondaryJobroles
                                ?.length < 1 ||
                              schedule?.user
                                ?.secondaryJobroles ===
                                undefined
                            ) {
                              const data = {id: schedule.branchId};
                              handleCreateShift(
                                data,
                                moment(
                                  date
                                ).format(
                                  'yyyy-MM-DD'
                                ),
                                schedule?.user
                              );
                            } else {
                              handleSelectedBranch(
                                schedule?.user
                              );
                            }
                          }}
                          className="hover:bg-gray-100 text-black dark:hover:bg-color-333 text-sm py-1 px-[5px] cursor-pointer"
                        >{schedule?.branchName} <span className="text-[12px] text-gray-400 dark:text-gray-200">
                        {schedule?.city}
                      </span></p>
                    </div>
                    <hr className="my-2" />
                    </div>
                    {handleSearchBranch(
                      inputSearchBranch
                    ).length > 0 ? (
                      handleSearchBranch(
                        inputSearchBranch
                      )?.map((branch) => (
                        <p
                          onClick={() => {
                            selectBranch(
                              branch
                            );
                            if (
                              schedule?.user
                                ?.secondaryJobroles
                                ?.length < 1 ||
                              schedule?.user
                                ?.secondaryJobroles ===
                                undefined
                            ) {
                              handleCreateShift(
                                {...branch, index: dateIndex},
                                moment(
                                  date
                                ).format(
                                  'yyyy-MM-DD'
                                ),
                                schedule?.user
                              );
                            } else {
                              handleSelectedBranch(
                                schedule?.user
                              );
                            }
                          }}
                          className="hover:bg-gray-100 text-black dark:hover:bg-color-333 dark:text-white text-sm py-1 px-[5px] cursor-pointer"
                        >
                          {branch?.name}{' '}
                          <span className="text-[12px] text-gray-400 dark:text-gray-200">
                            {branch?.city?.name}
                          </span>
                        </p>
                      ))
                    ) : (
                      <p className="text-xs text-center mb-3 font-bold">
                        No match
                      </p>
                    )}
                  </div>
                )}
              {selectedSchedule ===
                `${moment(date).format(
                  'yyyy-MM-DD'
                )}${schedule?.user?.id}` &&
                shiftSchedules?.filter(
                  (shiftSchedule) => {
                    return (
                      moment(
                        shiftSchedule?.start
                      ).format('yyyy-MM-DD') ===
                        moment(date).format(
                          'yyyy-MM-DD'
                        ) &&
                      schedule?.user?.id ===
                        shiftSchedule?.user?.id
                    );
                  }
                )?.length === 0 &&
                openDropdown && !openPTO &&
                jobroleList?.length > 0 && (
                  <div className="absolute bg-white z-50 top-[80%] max-h-[300px] overflow-y-auto left-[100%] w-max shadow-select">
                    {jobroleList?.map(
                      (x, index) => (
                        <p
                          onClick={() => {
                            handleCreateShift(
                              {...branchSelected, index: dateIndex},
                              moment(
                                date
                              ).format(
                                'yyyy-MM-DD'
                              ),
                              schedule?.user,
                              index === 0
                                ? undefined
                                : x.id
                            );
                          }}
                          className="flex group text-black items-center gap-3 px-4 py-2 cursor-pointer hover:bg-787"
                        >
                          {x?.title}{' '}
                          {index === 0 && (
                            <span className="text-787 text-[10px] font-semibold group-hover:text-white">
                              Primary
                            </span>
                          )}
                        </p>
                      )
                    )}
                  </div>
                )}
              {(ongoingState.includes(`${schedule?.user?.id}${dateIndex}`) || (selectedSchedule ===
                `${moment(date).format(
                  'yyyy-MM-DD'
                )}${schedule?.user?.id}`)) &&
                shiftSchedules?.filter(
                  (shiftSchedule) => {
                    return (
                      moment(
                        shiftSchedule?.start
                      ).format('yyyy-MM-DD') ===
                        moment(date).format(
                          'yyyy-MM-DD'
                        ) &&
                      schedule?.user?.id ===
                        shiftSchedule?.user?.id
                    );
                  }
                )?.length > 0 &&
                memberships?.length > 0 &&
                ([1, 2].includes(
                  memberships[0]?.privilege
                ) ||
                  schedule?.user?.id ===
                    memberships[0]?.userId) && openDropdown && (
                  <div className="bg-white z-50 top-[80%] right-0 absolute drop-shadow-lg w-full max-w-[80%]">
                    {[1, 2].includes(
                  memberships[0]?.privilege
                ) && (<p
                      onClick={() => {
                        const [
                          shiftScheduleFilter,
                        ] =
                          shiftSchedules?.filter(
                            (shiftSchedule) => {
                              return (
                                moment(
                                  shiftSchedule?.start
                                ).format(
                                  'yyyy-MM-DD'
                                ) ===
                                  moment(
                                    date
                                  ).format(
                                    'yyyy-MM-DD'
                                  ) &&
                                schedule?.user
                                  ?.id ===
                                  shiftSchedule
                                    ?.user?.id
                              );
                            }
                          );

                        handleCancelSchedule(
                          shiftScheduleFilter.id
                        );
                      }}
                      className="text-xs mb-1 text-171 hover:text-green-600 p-3 cursor-pointer font-bold"
                    >
                      Cancel shift
                    </p>)}
                    <p
                      onClick={() => {
                        const [
                          shiftScheduleFilter,
                        ] =
                          shiftSchedules?.filter(
                            (shiftSchedule) => {
                              return (
                                moment(
                                  shiftSchedule?.start
                                ).format(
                                  'yyyy-MM-DD'
                                ) ===
                                  moment(
                                    date
                                  ).format(
                                    'yyyy-MM-DD'
                                  ) &&
                                schedule?.user
                                  ?.id ===
                                  shiftSchedule
                                    ?.user?.id
                              );
                            }
                          );

                        handleEnableShiftSwap(
                          shiftScheduleFilter.id,
                          shiftScheduleFilter.swappable
                        );
                      }}
                      className="text-xs mb-1 hover:text-green-600 p-3 cursor-pointer font-bold"
                    >
                      {shiftSchedules?.filter(
                        (shiftSchedule) => {
                          return (
                            moment(
                              shiftSchedule?.start
                            ).format(
                              'yyyy-MM-DD'
                            ) ===
                              moment(
                                date
                              ).format(
                                'yyyy-MM-DD'
                              ) &&
                            schedule?.user
                              ?.id ===
                              shiftSchedule
                                ?.user?.id
                          );
                        }
                      )[0].swappable ? (
                        <span className="text-171 hover:text-green-600">
                          Cancel Swap
                        </span>
                      ) : (
                        <span className="text-black hover:text-green-600 dark:text-white">Swap</span>
                      )}
                    </p>
                  </div>
                )}
            </div>
        }
          </td>
        ))}
      </tr>
    )
  }

  return (
    <div>
    <div className="overflow-x-scroll h-[500px]">
      <table
        className={`w-[3900px] border-collapse`}
      >
        <thead className="bg-white dark:bg-A06 z-50">
          <tr>
            <td className="sticky left-0 z-50 bg-white dark:bg-A06" colSpan={2}>
            {!!selectedTemplate?.value ? (
                <p
                  onClick={toggleAvailableSlot}
                  className="text-558 text-sm font-bold cursor-pointer"
                >
                  {isOpenSlotOpen ? 'Close Open shifts' : 'Open Shifts'}
                </p>
              ) : (
                <div />
              )}
            </td>
            <td colSpan={28}>
            {isOpenSlotOpen && (
                <ClickAwayListener
                  onClickAway={() => {
                    openSlots(false);
                  }}
                >
                  <div className={`relative right-0  top-0 bg-white rounded-md  h-[300px] w-full overflow-y-scroll shadow-shift-shadow z-20`}>
                    <table>
                      <thead>
                      <tr>
                      {
                      dates?.map((dat) => (
                        <th className={[0,6].includes(moment(dat).day()) ? "bg-calendar-weekend":""}>
                      <div className={`w-[100px] ${[0,6].includes(moment(dat).day()) ? "bg-calendar-weekend":""}  ${dropdownSlotClass}`}>
                        <p>{moment(dat).format("ddd DD MMM")}</p>
                      </div>
                      </th>
                      ))
                    }
                      </tr>
                      </thead>
                      <tr>
                      {dates
                      ?.map((dat, index) => {
                        const data = [];

                        selectedTemplate?.locations?.forEach((loc) => {
                          const numberOfSchedule = shiftSchedules?.filter(
                            (x) =>
                              x?.branchId === loc.location.id &&
                              moment(x?.start).format('yyyy-MM-DD') === moment(dat).format('yyyy-MM-DD')
                          ).length;
                          const remainingSlots =
                            (loc?.staffRequired || 0) - numberOfSchedule;
                          for (let b = 0; b < remainingSlots; b++) {
                            data.push(loc?.location);
                          }
                        });

                        const daysOfWeek = selectedTemplate?.daysOfWeek?.split(",")?.map((y) => Number(y));

                        return (
                          <td className={[0,6].includes(moment(dat).day()) ? "bg-calendar-weekend":""}>
                          <div
                            id={daysOfWeek[moment(dat).day()]}
                            className={`flex flex-col pl-2 px-2 ${
                              [0, 6].includes(daysOfWeek[moment(dat).day()])
                                ? 'bg-calendar-weekend'
                                : ''
                            }`}
                          >
                            {daysOfWeek[moment(dat).day()] ? (
                              data?.filter((bran) => {
                                if(selectedMultiBranch?.length > 0){
                                  return selectedMultiBranch?.map((ele) => ele?.value).includes(bran?.id)
                                }
                                return true
                              })?.map((y) => (
                                <div
                                  onDrag={onDrag}
                                  draggable="true"
                                  onDragStart={(e) => onDragStart(e, y, moment(dat).format("yyyy-MM-DD"))}
                                  className={`h-[30px] mb-3 cursor-pointer rounded-md flex items-center justify-center text-white font-bold px-2`}
                                  style={{ backgroundColor: y.hexcode }}
                                >
                                  <p className="w-[78px] whitespace-nowrap text-center overflow-hidden text-ellipsis">
                                    {y?.name}
                                  </p>
                                </div>
                              ))
                            ) : (
                              <div />
                            )}
                          </div>
                          </td>
                        );
                      })}
                      </tr>
                    </table>
                    
                    {/* <div className={dropdownSlotClass}>
                      <p>Mon</p>
                    </div>
                    <div className={dropdownSlotClass}>
                      <p>Tue</p>
                    </div>
                    <div className={dropdownSlotClass}>
                      <p>Wed</p>
                    </div>
                    <div className={dropdownSlotClass}>
                      <p>Thur</p>
                    </div>
                    <div className={dropdownSlotClass}>
                      {' '}
                      <p>Fri</p>
                    </div>
                    <div className={`bg-calendar-weekend ${dropdownSlotClass}`}>
                      <p>Sat</p>
                    </div> */}
                    
                  </div>
                </ClickAwayListener>
              )}
            </td>
          </tr>
          <tr className="bg-white">
          <td className={`w-[175px] border-r-0 top-0 dark:border-0 z-50 bg-white dark:bg-A06 dark:border-333 sticky left-0 `}></td>
          <td className={`w-[20px] border-r-0 z-50 top-0 dark:border-0 bg-white dark:bg-A06 dark:border-333 sticky left-[170px]`}></td>
          {
            [...new Set(dates.map((x) => moment(x).format('MMM')))]?.map((y, index) => (<td className={index === 0 ? "sticky left-0 top-0 bg-white z-50 dark:bg-A06" : "sticky left-[400px] top-0 bg-white z-50 dark:bg-A06"} colSpan={dates.map((dat) => moment(dat).format('MMM')).filter((z) => z === y).length} ><p className="dark:text-white font-extrabold text-[20px] text-[#444]">{y}</p></td>))
          }
          </tr>
          
          {!!selectedTemplate?.value && (
            <tr>
              <td className={`w-[175px] border-b border-r-0 z-50 dark:bg-A06 dark:border-333 sticky left-0 bg-white`}></td>
              <td className={`w-[20px] border-b border-r-0 z-50 dark:bg-A06 dark:border-333 sticky left-[170px] bg-white`}></td>
              {dates?.map((value, index) => {
                if (index > 6 && displayMode.value === 'date-picker')
                  return null;
                return (
                  <td>
                    <p className="font-extrabold font text-base text-39E flex items-center gap-3">
                      <div className="flex items-center gap-1">
                        <span>{`${handleTotalSlot()}`} </span>
                        <span
                          className={
                            calFirstTrack(value) < 0 ? 'text-red-700' : ''
                          }
                        >
                          {calFirstTrack(value)}
                        </span>
                      </div>{' '}
                      <div className="flex items-center gap-1">
                        {' '}
                        <span>{`${handleRelevantSlot()}`}</span>{' '}
                        <span
                          className={
                            calSecondTrack(value) < 0 ? 'text-red-700' : ''
                          }
                        >
                          {calSecondTrack(value)}
                        </span>
                      </div>
                    </p>
                  </td>
                );
              })}
            </tr>
          )}

          <tr className="border">
            <td
              className={`w-[175px] border-b top-8 border-r z-50 dark:border-1 dark:bg-A06 dark:border-333 sticky left-0 bg-white`}
            >
              <p className="px-3 w-[170px] text-sm dark:text-white">
                Employee Name
              </p>
            </td>
            <td
              className={`w-[20px] border-b top-8 border-r z-50 dark:bg-A06 dark:border-1 dark:border-333 sticky left-[170px] bg-white`}
            >
              <p className="px-3 text-center dark:text-white text-sm">Shifts</p>
            </td>
            {dates?.map((value, index) => {
              if (index > 6 && displayMode.value === 'date-picker') return null;

              return (
                <td
                  className={`cursor-pointer top-8 z-40 sticky border-b border-r dark:border-1 dark:border-333 p-4 ${
                    [0, 6].includes(value.getDay())
                      ? 'bg-calendar-weekend dark:bg-A06'
                      : 'bg-white'
                  } w-[400px] dark:bg-A06`}
                >
                  <DateLabel
                    padding={'10px'}
                    className=""
                    detailsWeek={value}
                    hideMonth
                    type="week"
                    layoutProps={{
                      headerClass: 'dark:text-white text-sm',
                      subHeaderClass: 'dark:text-969',
                    }}
                  />
                </td>
              );
            })}
          </tr>
        </thead>
        {(loading || loaderLoading) && (
          <tr>
            <td
              colSpan={30 }
            >
              <div className="w-full h-[300px] flex justify-center items-center">
                <Loader />
              </div>
            </td>
          </tr>
        )}
        {!loading &&
          !loaderLoading &&
          (handleSchedule(selectedMultiBranch).filter((x) => x?.mode === 'data')
            ?.length === 0 ||
            jobRoles?.length === 0) && (
            <tr>
              <td colSpan={displayMode.value === 'date-picker' ? 9 : 28}>
                <div className="w-full h-[300px] flex justify-center items-center dark:text-white">
                  <p>No Members in this branch</p>
                </div>
              </td>
            </tr>
          )}

        {!loading &&
          !loaderLoading &&
          handleSchedule(selectedMultiBranch)?.filter(
            (x) => x?.user.id === userId
          )?.length > 0 && (
            <>
              {Array.from(handleSchedule(selectedMultiBranch))
                ?.filter((x) => x?.user.id === userId)
                ?.map((schedule) => (
                  <>{handleRenderScheduleCell(schedule)}</>
                ))}
            </>
          )}
        {!loading &&
          !loaderLoading &&
          [1,2].includes(privilege) && handleGenerateTags()?.map((tags, tagIndex) => (
            <>
              {Array.from(handleSchedule(selectedMultiBranch))
                ?.slice(offset, offset + rowsPerPage)
                ?.filter(
                  (x) =>
                    handleRoleTags(x?.user?.jobRole?.id)?.map(
                      (y) => y?.tagInfo?.id
                    )[0] === tags.id
                )?.length > 0 && (
                <tr>
                  <td
                    onClick={handleCloseDropdown}
                    className={`border border-l-0 p-4 border-r-0 dark:bg-A06 dark:border-333 left-0 z-20 sticky`}
                    
                  >
                    <p
                      className="font-bold text-[10px] dark:text-white"
                      style={{ color: tags?.hexcode }}
                    >
                      {tags?.name}
                    </p>
                  </td>
                  <td colSpan={dates?.length }></td>
                </tr>
              )}
              {[1,2].includes(privilege) && handleCellRender(true, tags)?.map((schedule, index) => (
                <>
                  {handleRenderScheduleCell(
                    schedule,
                    index === handleCellRender(true, tags)?.length - 1 &&
                      handleGenerateTags()?.length - 1 !== tagIndex,
                    [1,2].includes(privilege)
                  )}
                </>
              ))}
            </>
          ))}
        <tr>
          <td colSpan={30}>
            
          </td>
        </tr>
      </table>
    </div>
      <div>
      {[1,2].includes(privilege) && (jobRoles?.length > 0 ||
              handleSchedule(selectedMultiBranch)?.length > rowsPerPage) &&
              !loaderLoading &&
              !loading && (
                <div className="flex mb-20 justify-end">
                  <TablePagination
                    className="dark:text-grey-600"
                    component="div"
                    count={handleSchedule(selectedMultiBranch)?.length || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              )}
      </div>
    </div>
  );
}

export default RotationView;
