
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHolidays, createHoliday } from '../Network/ServiceClass/holiday';
// thunk
 export const getHolidaysThunk = createAsyncThunk('holiday/getHolidays', getHolidays);
 export const getHolidaysNoLoadingThunk = createAsyncThunk('holiday/getHolidaysNoLoading', getHolidays);

 export const createHolidayThunk = createAsyncThunk('holiday/createHoliday', createHoliday);

 

const HolidaySlice = createSlice({
  name: 'holiday',
  initialState: {
    holidays: [],
    loading: false,
   
  },
  extraReducers: {
   [getHolidaysThunk.pending]: (state) => {
    state.loading = true
   },
   [getHolidaysThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.holidays = action.payload.data
    return state
   },
   [getHolidaysThunk.rejected]: (state) => {
    state.loading = false;
   },

   [getHolidaysNoLoadingThunk.pending]: (state) => {
   },
   [getHolidaysNoLoadingThunk.fulfilled]: (state, action) => {
    state.holidays = action.payload.data
    return state
   },
   [getHolidaysNoLoadingThunk.rejected]: (state) => {
   },

  //  [createHolidayThunk.pending]: (state) => {
  //   state.loading = true
  //  },
  //  [createHolidayThunk.fulfilled]: (state, action) => {
  //   state.loading = false;
  //   // state.holidays = action.payload.data

  //   // return action
  //  },
   
   
  }
})


// export const { selectedTask, getTaskStatus, setTaskLoading } = HolidaySlice.actions;

export default HolidaySlice.reducer;