/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideBar } from '../../../Widgets/SideBar';
import { useNavigate } from 'react-router';
import { BsArrowLeft, BsSearch } from 'react-icons/bs';
import { BranchesHeader } from '../../BranchScreen/BranchesHeader/BranchesHeader';
import CustomButton from '../../../Widgets/CustomButton';
import { useEffect } from 'react';

import {
  getBranchesAsync,
  fetchJobRolesAsync,
  getCountriesAsync,
  bulkAccountCreationAsync,
  getSkillSetAsync
} from '../../../../Store/Branches';

import { FiMoreHorizontal } from 'react-icons/fi';
import ConfirmAccounts from './Modal/ConfirmAccounts';
import EditTeamInfo from './Modal/EditTeamInfo';
import { toast } from 'react-toastify';
import DisplaySuccess from '../../../Widgets/DisplaySuccess';

import { Storage } from '../../../../Network/StorageClass/StorageClass';
import LiveUsers from '../../LiveUsers/LiveUsers';

const storage = new Storage()


const rowStyle = 'py-4 px-2 border-y-1 min-w-[100px] border-9FD';
const ViewAccountsScreen = () => {
  // const userId = storage.getUserDetails()?.id
  
  // const socket = io(conn_url, { query: { userId }, })
  const socket = useSelector(state => state?.socket?.socket)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employeeDetails, setEmployeeDetails] = useState({})
  const [openModal, setOpenModal]= useState(false)
  const [openEditModal, setOpenEditModal]= useState(false)
  const [searchItem, setSearchItem] = useState('');
  const accounts = useSelector((state) => state.branches)?.accountCreationTeams;
  const branches = useSelector((state) => state.branches)?.branches;
  const jobRoles = useSelector((state) => state.branches)?.jobRoles;
  const skillSets = useSelector((state) => state.branches)?.skillSets;
  const isLoading = useSelector((state) => state.branches)?.loading;
  const countries = useSelector(state => state.branches)?.countries
  const [storeAccounts, setStoreAccounts] = useState(accounts);

  
  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(fetchJobRolesAsync());
    dispatch(getBranchesAsync());
    dispatch(getCountriesAsync());
    dispatch(getSkillSetAsync());
    
  }, []);
  
  
  useEffect(()=>{
    socket.on('progressUpdate', (progressData) => {
      setDisplayData(progressData)
      // if(progressData.loading === false){
      //   socket.disconnect();
      // }
    });

    return () => {
      socket.disconnect();
    };
    
  },[socket])

  const handleBranchBack = () => {
    setOpenDisplaySuccess(false)
    navigate('/branches');
  };

  const handleCreateAccounts = () => {
    let accs = []
      setOpenDisplaySuccess(true)
      setOpenModal(false)

    storeAccounts.forEach(obj => {
     accs.push({
      userName: obj.username,
      firstName: obj.firstname,
      lastName: obj.lastname,
      email: obj.email,
      countryId: obj.country,
      phone: '+' + obj.phone,
      gender: obj.gender,
      skillSetIds:[obj.skillset],
      assignedBranchId: obj.location,
      jobRoleId: obj.jobrole,
      privilege: obj.privilege
     })
    });

    dispatch(bulkAccountCreationAsync(accs)).then((res)=> {
      if(res?.payload?.status === 400){
        toast.error(res?.payload?.data?.message)
        if(res?.payload?.data?.details !== undefined){
          const keys = Object.getOwnPropertyNames(res?.payload?.data?.details);
         
          toast.error(`At row ${Number(keys[0].split('.')[0][1]) + 1} : ${keys[0].split('.')[1]} is required`);
        }
       
      }

      if(res?.payload?.status === 201){
        // toast.success('Successfully');
        setOpenModal(false);
        // setOpenDisplaySuccess(true)
        setDisplayData(res?.payload?.data)
        // handleBranchBack();
      }
    });
  }

  const handleSearchAction = (e) => {
    setSearchItem(e.target.value);
  };

  const handleFilterItem = (itemToSearch) => {
    // console.log(storeAccounts)
    const filtered = storeAccounts?.filter(
      (invite, index) =>
        invite?.email?.includes(itemToSearch) ||
        invite?.name?.includes(itemToSearch) ||
        index + 1 === Number(itemToSearch) ||
        invite?.lastname?.includes(itemToSearch) ||
        invite?.username?.includes(itemToSearch) 
    );
   
    return filtered;
  };

  const handleSelectOption = (con, prop, val) => {
    const updateData = storeAccounts.map((newData) => {
      if (newData.email === con) {
        if(Array.isArray(prop)){
          return {
            ...newData,
            ...prop[0],
          }
        }
        return {
          ...newData,
          [prop]: val,
        };
      }
      return newData;
    });

    setStoreAccounts(updateData);
  };

  const handleOpenOptionMoreClick = (data) => {
    setEmployeeDetails(data);
    setOpenEditModal(true);
  }

  const handleCloseOptionMoreClick = () => {
    setOpenEditModal(false);
  }

  const [openDisplaySuccess, setOpenDisplaySuccess] = useState(false);

  const [displayData, setDisplayData] = useState({});

  const handleCloseDisplaySuccess = () => {
    setOpenDisplaySuccess(false);
  };

  const privilegeArray =  [
    { id: 1, name: 'Super Admin' },
    { id: 2, name: 'Admin' },
    { id: 3, name: 'Member' },
  ]

  const displayFieldValue = (val) => {
    if(val !== undefined) return val
    else  return (<p className='text-[red] italic font-semibold'>wrong value</p>)
  }

  const handleOpenCreateAccount = () => {
    setOpenModal(true)
    // connectWebSocket()
   }

  return (
    <>
      <section>
        <SideBar customTitle="Company">
          <div
            onClick={handleBranchBack}
            className="cursor inline-flex items-center rounded border-1 border-black p-1"
          >
            <BsArrowLeft /> <span className="ml-2">Back</span>
          </div>
          <div className='relative'>
            <LiveUsers/>
          </div>
          <BranchesHeader
            title="Create team members"
            subtitle="Create team members to be part of the company"
          />
          <div className="flex justify-end w-full">
            <CustomButton
              onClick={handleOpenCreateAccount}
              className="bg-base-color text-white w-[180px] h-[50px]"
              title="Create Accounts"
            />
          </div>

          <div className="rounded border-1 border-9FD px-[26px] py-[40px] mt-[17px] mb-[20px]">
            <div className="flex items-center mb-[33px]">
              <div className="flex items-center rounded border-1 border-9FD w-[245px] p-[10px]">
                <BsSearch className="mr-3" />
                <input
                  onChange={handleSearchAction}
                  type="text"
                  className="outline-none"
                  placeholder="Search by first 5 columns"
                />
              </div>
              <p className="font-medium text-sm text-A5A border-1 border-9FD p-[5px] ml-[32px] rounded">
                Total:{' '}
                <span className="text-black font-semibold">
                  {handleFilterItem(searchItem)?.length}
                </span>
              </p>
            </div>

            <div className='max-w-[100%] overflow-x-auto pb-16'>
              <table >
                <thead className=" bg-0F5">
                  {[
                    'S/N',
                    'User name',
                    'First name',
                    'Last name',
                    'Email',
                    'Phone',
                    'Gender',
                    'Country',
                    'Skill Sets',
                    'Privilege',
                    'Job Role',
                    'Location',
                    '',
                  ]?.map((item) => (
                    <th className="py-[16px] text-[11px] text-left pl-2">{item}</th>
                  ))}
                </thead>
                <tbody className="border-1 ">
                  {handleFilterItem(searchItem)?.map((data, index) => (
                    
                    <tr className='text-[11px] '>
                      <td className='py-4 px-2'>{index + 1}</td>
                      <td className={rowStyle}>{data?.username}</td>
                      <td className={rowStyle}>{data?.firstname}</td>
                      <td className={rowStyle}>{data?.lastname}</td>
                      <td className={rowStyle}>{data?.email}</td>
                      <td className={rowStyle}>{data?.phone}</td>
                      <td className={rowStyle}>{data?.gender}</td>


                      <td className={rowStyle}>{displayFieldValue(countries?.filter(val => val.id === data?.country)[0]?.name)}</td>

                      <td className={rowStyle}>{displayFieldValue(skillSets?.data?.filter(val => val.id === data?.skillset)[0]?.name)}</td>
                      
                      <td className={rowStyle}>{displayFieldValue(privilegeArray.filter(val => val.id === Number(data?.privilege))[0]?.name)}</td>

                      <td className={rowStyle}>{displayFieldValue(jobRoles?.filter(val => val.id === data?.jobrole)[0]?.title)}</td>

                      <td className={rowStyle}>{displayFieldValue(branches?.data?.filter(val => val.id === data?.location)[0]?.name)}</td>
                  
                      <td className='py-4 px-2'>
                        <FiMoreHorizontal
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenOptionMoreClick(data);
                          }}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <ConfirmAccounts
              submitInvite={handleCreateAccounts}
              open={openModal}
              setOpenModal={setOpenModal}
              isLoading={isLoading}
            />
            <EditTeamInfo
              handleSelectOption={handleSelectOption}
              open={openEditModal}
              setOpenModal={setOpenEditModal}
              branches={branches}
              jobRoles={jobRoles}
              skillSets={skillSets}
              storeAccounts={storeAccounts}
              countries={countries}
              data={employeeDetails}
              handleClose={handleCloseOptionMoreClick}
            />
            <DisplaySuccess 
              isLoading={isLoading}
              openModal={openDisplaySuccess}
              handleClose={handleCloseDisplaySuccess}
              data={displayData}
              extraFunction={()=>{
                handleBranchBack()
                socket.disconnect();
              }}
            />
          </div>
        </SideBar>
      </section>
    </>
  );
};

export default ViewAccountsScreen;
