import { useState, useEffect } from 'react';
import {AiOutlineCaretDown} from 'react-icons/ai'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as CheckIcon } from '../../Assets/check.svg';
import { IoClose } from 'react-icons/io5';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MultipleSelectDropdown = ({
    optionClass, 
    className, 
    dropdownTitleClass, 
    data, 
    value=[],
    parentClass="",
    placeholder = "Select Location",
    labelText="items selected",
    setMultiBranch =()=>{}
  }) => {

    const [openDropdown, setOpenDropdown] = useState(false)

    const handleOpenDropDown = () => setOpenDropdown(!openDropdown)
    const handleCloseDropdown = () => setOpenDropdown(false)

  
    const handleSelectedOption = (item) => {
      const isSelected = value.find(val => val.label === item.label)
      if (isSelected) {
        const existingItem = value?.filter(val => val.label !== item.label)
        setMultiBranch(existingItem)
      }else {
        setMultiBranch([...value, item])
      }
    }

    const checkSelected = (item) => {
        const isSelected = value.find(val => val.label === item.label)
        if (isSelected) return true
        else return false
    }

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
        <div className={`relative ${parentClass}`}>
            <div onClick={handleOpenDropDown}
                className={`flex justify-between hover:border-gray-700 hover:dark:border-6E6 cursor-pointer items-center border-1 border-[#c2c2c2] dark:border-333 rounded-[5px] py-[6px] px-[10px] ${className}`}>
                <p className={`cursor-pointer font-bold text-[14px] ${dropdownTitleClass}`}>
                    {value.length <= 1 ? (value.length === 0 ? placeholder : value[0].label) : `${value.length} ${labelText}`}
                </p>
                <AiOutlineCaretDown className='text-[12px] text-black dark:text-white'/>
            </div>

            {openDropdown && (
            <div className={`shadow-select bg-white dark:bg-292 dark:text-white absolute w-full mt-0 max-h-[200px] overflow-y-auto ${optionClass}`}>
                {data.map((item, index) => (
                <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleSelectedOption(item)
                    }}
                    className={`px-[10px] py-[7px] cursor-pointer hover:bg-5A5 dark:hover:bg-color-333 hover:text-white flex items-center justify-between`}
                >
                    <div className='flex items-center gap-2'>
                  {item?.hexcode && <div className="w-[10px] h-[10px] rounded-full" style={{ backgroundColor: item?.hexcode }} />}
                    <p className="text-[14px] cursor-pointer">{item.label}</p>
                  </div>
                    {/* {showIcon && <CheckIcon />} */}
                    {checkSelected(item) && <CheckIcon />}
                </div>
                ))}
            </div>
            )}

        </div>
    </ClickAwayListener>
  )
}

export default MultipleSelectDropdown
