/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './List.css';
import { TimeOffService } from '../../../../../../../Network/ServiceClass/TimeOffService';
import { CircularProgress, Stack } from '@mui/material';
import { numberOfDaysBtwDates } from '../../../../../../../utils/numberOfDaysBtwDate';
import { setListData } from '../../../../../../../Store/timeOff';
import { useDispatch, useSelector } from 'react-redux';
import { Storage } from '../../../../../../../Network/StorageClass/StorageClass';
import PopModal from './PopModal';
import { AnimatePresence } from 'framer-motion';
import PlaceHolderOne from '../../../../../../Widgets/placeholders/PlaceHolderOne';
import InitialsGen from '../../../../../../Widgets/InitialsGen';

const timeOffService = new TimeOffService();
const timeOffStorage = new Storage();

const bodyClassName = "dark:!bg-A1A dark:!text-white text-left :hover:bg-transparent dark:!border-333"
const headerClassName = "dark:!bg-1F1 text-left dark:!text-white dark:!border-333"

const List = () => {
  const isAdmin = timeOffStorage.getActiveCompany()?.memberships[0]?.privilege;
  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );

  const dispatch = useDispatch();
  const listDataState = useSelector((state) => state.timeOff.listData);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [openPopper, setOpenPopper] = React.useState(null);
  const [clickedRow, setClikedRow] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    timeOffService
      .fetchTimeOff({from: shiftStateWeeklyDateLabel.from, to: shiftStateWeeklyDateLabel.to})
      .then((value) => {
        setIsLoading(false);
        if (value.status !== 200) throw new Error('');
        dispatch(setListData(value.data));
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [shiftStateWeeklyDateLabel.from, shiftStateWeeklyDateLabel.to]);

  return (
    <div>
      
      <section className="table">
        <AnimatePresence>
          {openPopper && (
            <PopModal
              openPopper={openPopper}
              setOpenPopper={setOpenPopper}
              data={modalData}
            />
          )}
        </AnimatePresence>

        {listDataState.length > 0 && !isLoading && (
          <div className="h-[70vh] overflow-y-auto dark:border-333">
            <table className="table-wrapper dark:border-333 text-center" cellSpacing="0" cellPadding={0}>
              {[1, 2].includes(isAdmin) && (
                <thead className="sticky bg-white top-0 dark:bg-1F1">
                  <td className={headerClassName}>Employee</td>
                  <td className={headerClassName}>Leave type</td>
                  <td className={headerClassName}>Days left</td>
                  <td className={`${headerClassName} w-[250px]`}>Duration</td>
                  <td className={headerClassName}>Status</td>
                </thead>
              )}

              {[3].includes(isAdmin) && (
                <thead className="sticky bg-white top-0 dark:bg-1F1">
                  <td className={headerClassName}>Create date</td>
                  <td className={headerClassName}>Start date</td>
                  <td className={headerClassName}>End date</td>
                  <td className={headerClassName}>Status</td>
                </thead>
              )}

              {listDataState.map((value, index) => {
                const start = new Date(value.start)
                  .toDateString()
                  .split(' ')
                  .slice(0, 3)
                  .join(' ');
                const end = new Date(value.end)
                  .toDateString()
                  .split(' ')
                  .slice(0, 3)
                  .join(' ');
                const createdOn = value.createdOn.split('T')[0];
                const startDay = value.start.split('T')[0];
                const endDay = value.end.split('T')[0];

                // console.log(endDay)

                const stop = Math.abs(
                  numberOfDaysBtwDates(value.end, new Date())
                );

                if ([1, 2].includes(isAdmin)) {
                  return (
                    <tr
                      className={
                        `${[1, 2].includes(isAdmin) ? 'list-row' : 'list-no-hover'} ${bodyClassName}`
                      }
                      onClick={(event) => {
                        setModalData(value);
                        setOpenPopper(openPopper ? null : event.currentTarget);
                        setClikedRow(clickedRow ? null : index + 1);
                      }}
                      style={{
                        boxShadow:
                          clickedRow === index + 1
                            ? '10px 10px 40px rgba(0, 0, 0, 0.08)'
                            : '',
                      }}
                    >
                      <td className={bodyClassName}>
                        <div className="flex items-center gap-3">
                        {value?.requester?.picture?.url ? (
                          <img
                            className="rounded-full h-10 w-10 mr-2"
                            src={value?.requester?.picture?.url}
                            alt="logo"
                          />
                        ) : (
                          <InitialsGen
                            width={40}
                            name={`${value.requester.firstName} ${value.requester.lastName}`}
                            bgColor="blue"
                          />
                        )}
                        <p>{value.requester.firstName} {value.requester.lastName}</p>

                        </div>
                        
                      </td>
                      <td className={bodyClassName}>{value.vacationType}</td>
                      <td  className={bodyClassName}>
                        {stop} {stop > 1 ? 'Days' : 'Day'}
                      </td>
                      <td style={{ width: '200px' }}  className={bodyClassName}>
                        {start} - {end}
                      </td>
                      <td  className={bodyClassName}>
                        {value.status === 'pending' && <Pending />}
                        {value.status === 'approved' && <Accepted />}
                        {value.status === 'declined' && <Declined />}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr
                      className={`list-no-hover ${bodyClassName}`}
                      onClick={(event) => {
                        setOpenPopper(openPopper ? null : event.currentTarget);
                        setModalData(value);
                        setClikedRow(clickedRow ? null : index + 1);
                      }}
                      style={{
                        boxShadow:
                          clickedRow === index + 1
                            ? '10px 10px 40px rgba(0, 0, 0, 0.08)'
                            : '',
                      }}
                    >
                      <td className={bodyClassName}>{createdOn}</td>
                      <td  className={bodyClassName}>{startDay}</td>
                      <td  className={bodyClassName}>{endDay}</td>
                      <td  className={bodyClassName}>
                        {value.status === 'pending' && <Pending />}
                        {value.status === 'approved' && <Accepted />}
                        {value.status === 'declined' && <Declined />}
                      </td>
                    </tr>
                  );
                }
              })}
            </table>
          </div>
        )}
        {listDataState.length < 1 && !isLoading && <PlaceHolderOne />}
        {isLoading && (
          <Stack direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
      </section>
    </div>
  );
};

export default List;

const Pending = () => {
  return (
    <span
      style={{
        border: '1px solid #FFCC00',
        color: '#FFCC00',
        padding: '0.2rem 0.2rem',
        borderRadius: '3px',
      }}
    >
      Pending
    </span>
  );
};

const Accepted = () => {
  return (
    <span
      style={{
        border: '1px solid #458452',
        color: '#458452',
        padding: '0.2rem 0.2rem',
        borderRadius: '3px',
      }}
    >
      Accepted
    </span>
  );
};

const Declined = () => {
  return (
    <span
      style={{
        border: '1px solid #C15353',
        color: '#C15353',
        padding: '0.2rem 0.2rem',
        borderRadius: '3px',
      }}
    >
      Declined
    </span>
  );
};
