import { BsPen } from 'react-icons/bs';
import CustomDropdown from '../../../Widgets/CustomDropdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
 
  getInventoryItemsUtilityRateThunk
} from '../../../../Store/Inventory';

import AppLayout from '../../../Widgets/AppLayout';

import { CircularProgress, TextField } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';





const InventoryItemGroupList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();


  
  
  const loading = useSelector((state) => state.inventory)?.loading;
  const rateSpent = useSelector((state) => state.inventory)?.utilityRate
  ?.map(x => ({
    date:dayjs(x?.date).format('DD MMM YYYY'), 
    usage_spent: x?.usages?.spent,
    withdrawal_spent: x?.withdrawals?.spent,

  }));

  const rateCount = useSelector((state) => state.inventory)?.utilityRate
  ?.map(x => ({
    date: dayjs(x?.date).format('DD MMM YYYY') , 
    usage_count: x?.usages?.count,
    withdrawal_count: x?.withdrawals?.count,

  }));


  const [toggleGraph, setToggleGraph] = useState(0)


  useEffect(() => {
    
    dispatch(getInventoryItemsUtilityRateThunk({groupId: params?.groupId}))
  }, []);

  
  return (
    <section>
      <AppLayout
        custom="inventory"
        subCustom="inventory-items"
        title="Inventory Items"
        customTitle=" "
        subtitle=""
      >
        <div className="flex items-center gap-3">
          <IoIosArrowBack
            className="cursor-pointer dark:text-white"
            size={25}
            onClick={() => navigate(-1)}
          />
          <p className="font-medium text-base text-5F3 my-6">
            <Link to="/inventory-items">INVENTORY ITEMS</Link>{' '}
            <Link to="/inventory-items-group">/ INVENTORY-GROUPS</Link>
           
          </p>
        </div>
       

        <div className='py-3 px-1 mb-8'>
          <p className="text-2xl font-medium mb-6 dark:text-white">Utility Rate</p>

          <div className='rounded py-4 pr-2 shadow-shift-shadow-small dark:text-white dark:bg-1F1 h-[350px] '>

            {!loading ? <div className='flex flex-col gap-4 h-full'>
              <div className='flex gap-3 self-end'>

                <div 
                  onClick={()=> setToggleGraph(0)}
                  className='flex gap-2 items-center dark:bg-color-333 cursor-pointer px-1 hover:rounded hover:shadow-shift-shadow-small'>
                  <p>Counts</p>
                  <p className={`w-3 h-3 border-1 ${toggleGraph === 0 ? 'bg-5F3' : 'border-gray-400'} rounded-full`}/>
                </div>

                <div 
                  onClick={()=> setToggleGraph(1)}
                  className='flex gap-2 items-center dark:bg-color-333 cursor-pointer px-1 hover:rounded hover:shadow-shift-shadow-small'>
                  <p>Spent</p>
                  <p className={`w-3 h-3 border-1 ${toggleGraph === 1 ? 'bg-5F3' : 'border-gray-400'}  rounded-full`}/>
                </div>
              </div>

              <ResponsiveContainer className='' width={'100%'} height={'100%'}>
                <LineChart
                  width={500}
                  height={300}
                  data={toggleGraph ? rateSpent : rateCount}
                  
                >
                  <XAxis dataKey="date" tick={{ fontSize: 12 }} interval={3}/>
                  <YAxis tick={{ fontSize: 12 }}/>
                  <Tooltip content={(props) => CustomTooltip(props)} />
                  <Legend content={(props) => CustomLegend(props)} />
                  <Line type="monotone" dataKey={toggleGraph ? "usage_spent" : "usage_count"} stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey={toggleGraph ? "withdrawal_spent" : "withdrawal_count"} stroke="#82ca9d" activeDot={{ r: 8 }}/>

                </LineChart>
              </ResponsiveContainer>
            </div>
            :
            <div className='flex justify-center mt-8'>
              <CircularProgress />
            </div>
            }

          </div>


        
        </div>

       
      </AppLayout>
    </section>
  );
};

export default InventoryItemGroupList


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    // You can access the tooltip values from 'payload'
    return (
      <div className="custom-tooltip bg-white dark:bg-A06 rounded px-4 py-2 text-[14px] shadow-shift-shadow-small">
        <p>Date: {dayjs(label).format('DD MMM YYYY')}</p>
        {payload?.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${capitalizeFirstLetter(entry.name.split('_')[0])} ${capitalizeFirstLetter(entry.name.split('_')[1])}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
}

const CustomLegend = ({ payload }) => {
  return (
    <div className='flex justify-center gap-5'>
      {payload.map((entry, index) => (
        <div className='flex gap-2 items-center'>
          <p className='w-2 h-2 rounded-full'  style={{ background: entry.color }}/>
          <p key={`item-${index}`} style={{ color: entry.color }}>
          {capitalizeFirstLetter(entry.value.split('_')[0])} {capitalizeFirstLetter(entry.value.split('_')[1])}
          </p>
        </div>
      ))}
    </div>
  );
}
