import React, {useState, useEffect, useCallback} from 'react'

import SearchInput from '../../Widgets/SearchInput'
import CustomDropdown from '../../Widgets/CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getDatesArray } from '../../../utils/dateArray';
import moment from 'moment';
import { months } from '../ShiftScreen/Tabs/BranchSchedule/mock';
import TablePagination from '@mui/material/TablePagination';
import { Link, useNavigate } from 'react-router-dom';
import AppLayout from '../../Widgets/AppLayout';
import { getInventoryWithdrawalLogs } from '../../../Network/ServiceClass/InventoryService';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { month } from 'react-big-calendar-scheduler/lib/utils/dates';
import Inventory, { getInventoryWithdrawalLogsThunk } from '../../../Store/Inventory';
import { TableWrapper, TableHeader, Td, Tr } from '../../Widgets/Table/TableWrapper';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { logsTutorial } from '../mock';

const getMonth = {
    None: '0',
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  };

const InventoryLogs = () => {

    // const navigate = useNavigate()
    const dispatch = useDispatch()
    // const [logs, setLogs] = useState([])
    // const [loading, setIsLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dateCreated = useSelector(
        (state) => state?.company?.activeCompany?.createdOn
      );

    const withdrawalLogsList = useSelector(state => state.inventory)?.withdrawalLogs
    const loading = useSelector(state => state.inventory.loading)

    const [yearSelected, setYearSelected] = useState({
        label: moment().format("yyyy"),
        value: moment().format("yyyy"),
    });

    const [monthSelected, setMonth] = useState({
        label: moment().format('MMMM'),
        value: getMonth[moment().format('MMMM')],
    });

    useEffect(()=>{
        dispatch(getInventoryWithdrawalLogsThunk({
            page:page + 1, 
            pageSize:rowsPerPage, 
            month:Number(monthSelected?.value),
            year: Number(yearSelected?.value)
    }))
    
    },[monthSelected, yearSelected, page, rowsPerPage, dispatch])
    

    const handleOnDateChange = (data) => {
        setYearSelected(data);
    };

    const handleOnMonthChange = (data) => {
        setMonth(data);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

  return (
    <section>
        <AppLayout
            custom="inventory"
            subCustom="inventory-logs"
            title="Logs"
            customTitle="Inventory"
            subtitle="View inventory logs"
            RightComponent={<TutorialPanel items={logsTutorial} />}
        >

            <div className='flex gap-2 items-center mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-12'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                />

                <CustomDropdown
                    data={getDatesArray(dateCreated, new Date().toString())?.map(
                    (dates) => ({ label: dates, value: dates })
                    )}
                    className="w-28 h-12 rounded ml-4"
                    optionClass="w-[90%] right-0 mt-3 z-[1000]"
                    value={yearSelected}
                    dropdownTitleClass='dark:text-white'
                    onChangeOption={handleOnDateChange}
                />

                <CustomDropdown
                    data={months}
                    className="w-28 rounded h-12"
                    value={monthSelected}
                    dropdownTitleClass="text-A5A dark:text-white"
                    optionClass="z-[1000]"
                    onChangeOption={handleOnMonthChange}
                />


            </div>

            <TableWrapper className='mt-6 !max-h-[70vh] mb-12'>
                <table className=''>
                    <TableHeader >
                        <Td className='py-4 w-[150px]'>Date</Td>
                        <Td className='w-[150px]'>Time</Td>
                        <Td className='w-[150px]'>User</Td>
                        <Td className='min-w-[150px] max-w-[400px]'>Details</Td>
                        <Td className='w-[120px]'>Location</Td>
                        <Td className='!border-none w-[100px]'>Action</Td>
                       

                    </TableHeader>
                    {!loading ? <tbody>
                        {(withdrawalLogsList.length !== 0 && !loading) ? 
                            withdrawalLogsList
                            .filter((x) => x?.user?.firstName?.toLowerCase().includes(searchValue) ||
                            x?.user?.lastName?.toLowerCase().includes(searchValue))
                            .map(val => (
                                <Tr className='hover:bg-5F5 hover:dark:bg-292'>
                                        <Td className='py-5 '>
                                        {dayjs(val?.id?.timestamp).format('DD/MM/YYYY')}
                                        </Td>

                                        <Td>{dayjs(val?.id?.timestamp).format('h:mmA')}</Td>

                                        <Td>{val?.user?.firstName} {val?.user?.lastName}</Td>
                                        <Td className='min-w-[200px] max-w-[400px]'>{val?.id?.details}</Td>
                                        <Td>{val?.branch?.name}</Td>
                                        <Td className={'!border-none'}>{val?.id?.action}</Td>
                                       
                                </Tr>

                            ))
                         :

                        <tr>
                            <td colSpan={7} className='text-center py-5 text-A5A'>Nothing to see here</td>
                        </tr>
                        }

                    </tbody>
                    : <tbody>
                        <tr>
                            <td colSpan={7} className='text-center py-10 border-1 border-white dark:border-A1A'><CircularProgress /></td>
                        </tr>
                    </tbody>}
                </table>

            </TableWrapper>
            <div className='flex justify-start sticky bottom-0'>
                <TablePagination
                    className=' dark:text-white'
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </div>
        </AppLayout>
        
    </section>
  )
}

export default InventoryLogs