import { createSlice } from "@reduxjs/toolkit"

const socketSlice = createSlice({
    name: 'socket',
    initialState: {
        loggedIn: false,
        socket: null,
        loggedInUsers: [],
        loggedUsersCount: 0
    },
    reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },

        setLoggedInUsers: (state, action) => {
            state.loggedInUsers = action.payload
        },

        setLoggedUsersCount: (state, action) => {
            state.loggedUsersCount = action.payload
        }
               
    }

});

export const {
    setSocket,
    setLoggedIn,
    setLoggedInUsers,
    setLoggedUsersCount
   } = socketSlice.actions;
export default socketSlice.reducer;