import React,{useState} from 'react'
import {Popper, Box} from '@mui/material';
import Button from '@mui/material/Button';
import { Typography, CircularProgress} from '@mui/material';
import {font} from '../../../../../../Widgets/Styles/font'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { numberOfDaysBtwDates } from '../../../../../../../utils/numberOfDaysBtwDate';
import { TimeOffService } from '../../../../../../../Network/ServiceClass/TimeOffService';
import {setListData} from '../../../../../../../Store/timeOff'
import { useDispatch,} from 'react-redux';
import { motion,} from 'framer-motion';
import { toast } from 'react-toastify';
import { Storage } from '../../../../../../../Network/StorageClass/StorageClass'
import { FillButton, OutlinedButton } from '../../../../../../Widgets/FormUtils/FormUtils';


const timeOffService = new TimeOffService()
const timeOffStorage = new Storage()


const PopModal = ({openPopper, setOpenPopper, data}) => {

    const open = Boolean(openPopper);
    const id = open ? 'simple-popper' : undefined;

  const dispatch = useDispatch()
  const isAdmin = timeOffStorage.getActiveCompany()?.memberships[0]?.privilege


  const [isLoadingApprove, setIsLoadingApprove] = useState(false)
  const [isLoadingDecline, setIsLoadingDecline] = useState(false)

  
  const handleClose = () => {
    setOpenPopper(null);
  };

  const handleApprove = (id) => {
    setIsLoadingApprove(true)

    timeOffService.approveTimeOff(id).then(value => {
      setIsLoadingApprove(false)

      if (value.status !== 200) throw new Error('')
      console.log(value.data)

      // update data list
      timeOffService.fetchTimeOff()
      .then((value)=>{
          if(value.status !== 200) throw new Error('') 
          dispatch(setListData(value.data))
          toast('Approved Successfully')
      }).catch((error)=>{
          console.log(error)
      })

      setOpenPopper(null)

    }).catch(error => {
      setIsLoadingApprove(false)
      console.log(error)
      setOpenPopper(null)

    })
  }

  const handleDecline = (id) => {
    setIsLoadingDecline(true)

    timeOffService.declineTimeOff(id).then(value => {
      setIsLoadingDecline(false)

      if (value.status !== 200) throw new Error('')
      console.log(value.data)

      // update data list
      timeOffService.fetchTimeOff()
      .then((value)=>{
          if(value.status !== 200) throw new Error('') 
          dispatch(setListData(value.data))
          toast('Declined Successfully')
      }).catch((error)=>{
          console.log(error)
      })

      setOpenPopper(null)


    }).catch(error => {
      setIsLoadingDecline(false)
      console.log(error)
      setOpenPopper(null)

    })
  }

  const mainAnim ={
    hidden: {opacity: 0},
    vissible: {
      opacity: 1,
      transition: {ease: 'easeIn', duration: 1}
    },
    exit: {
      opacity: 0,
      transition: {ease: 'easeOut', duration: 1}
    }

  }


  return (
      <Popper 
       
        id={id} 
        open={open} 
        anchorEl={openPopper} 
        onClose={handleClose} 
        placement={'bottom-start'}>
          <motion.div 
            variants={mainAnim}
            initial={'hidden'}
            animate={'vissible'}
            exit={'exit'}

            className='w-[394px] h-[178px] flex flex-col justify-between bg-white dark:bg-292 dark:text-white pb-[20px]'
            style={{
            boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)',
            }}>

            <FolderList data={data}/>

            {[1,2].includes(isAdmin) && 
            <div className='flex justify-between self-end px-4 gap-3' >
                  {!isLoadingDecline &&  <OutlinedButton disable={(data.status === 'declined' || data.status === 'approved') ? true : false } text={'Decline'} callBack={()=> handleDecline(data.id)}/> }
                 
                  {isLoadingDecline && <CircularProgress />}

                  {!isLoadingApprove && <FillButton disable={(data.status === 'declined' || data.status === 'approved') ? true : false } text={'Accept'} callBack={()=> handleApprove(data.id)}/> }
                   
                  {isLoadingApprove && <CircularProgress />}

              
            </div>}  

            
          </motion.div>
      </Popper>
  )
}

export default PopModal

export function FolderList({data}) {
    const stop = numberOfDaysBtwDates(new Date(), new Date(data.createdOn))
      return (
          <Box>
  
              <ListItem>
              <ListItemAvatar>
                  <Avatar sx={{backgroundColor: '#004558', ...font}}>
                  <Typography sx={{...font}}>{data.requester.firstName.split('')[0].toUpperCase()}{data.requester.lastName.split('')[0].toUpperCase()}</Typography> 
                  </Avatar>
              </ListItemAvatar>
  
              <ListItemText primary={<Typography sx={{...font}}>{data.requester.firstName} {data.requester.lastName}</Typography> } 
              secondary={ <Typography sx={{...font}}>{stop} {stop > 1 ? 'Days' : 'Day'} ago</Typography>} />
  
              </ListItem>
  
              <Typography sx={{...font, fontSize: '14px', marginLeft: '4rem'}}>Request for time off</Typography>
          </Box>
        
      );
    }