import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { font } from '../../../Widgets/Styles/font';
import FormModal from '../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addInventoryMeasurementUnitThunk, getInventoryMeasurementUnitThunk } from '../../../../Store/Inventory';

import {
  Text,
  Field,
  TextAndField,
  FillButton,
  OutlinedButton,
} from '../../../Widgets/FormUtils/FormUtils';


export const NewUnitModal = ({ open, handleClose, callback }) => {
 
  const dispatch = useDispatch()

  const loading = useSelector(state => state.inventory)?. loadingAddUnit

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      symbol: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please input job title'),
      symbol: Yup.string().required('Please input job symbol'),
      description: Yup.string()
        .min(5, 'Job description is too short')
        .required('Enter Job description'),
    }),
   
    onSubmit: (value) => {
     dispatch(addInventoryMeasurementUnitThunk(value))
     .then(val => {
      console.log(val.payload)
      if([201,200].includes(val.payload?.status)){
        toast.success("Success")
        dispatch(getInventoryMeasurementUnitThunk())
        handleClose()
      }
      else {
        toast.error(val?.payload?.data?.message)
      }
     })
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY:'auto',
    overflowX:'hidden'
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-A06 dark:text-white' direction="column" alignItems="center" sx={style} gap={3}>
        <p className='text-[18px] font-medium dark:text-white'>
          New Measurement
        </p>


        <TextAndField>
          <Text text={'Name'} />
          <Field
            text={'Kindly enter unit name'}
            value={formik.values.name}
            formik={formik}
            name="name"
          />
        </TextAndField>


        <TextAndField>
          <Text text={'Symbol'} />
          <Field
            text={'Kindly enter symbol'}
            value={formik.values.symbol}
            formik={formik}
            name="symbol"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Description'} />
          <Field
            text={'Kindly enter description'}
            value={formik.values.description}
            formik={formik}
            name="description"
            multiline={true}
            maxRows={3}
          />
        </TextAndField>

        <Stack
          direction="row"
          width="82%"
          justifyContent="space-between"
          mt={6}
        >
          <OutlinedButton
            text="Cancel"
            callBack={() => {
              handleClose();
              formik.resetForm();
            }}
          />
          {!loading && (
            <FillButton text="Submit" callBack={formik.handleSubmit} />
          )}
          {loading && <CircularProgress />}
        </Stack>
      </Stack>
    </FormModal>
  );
};
