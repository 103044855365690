import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Box, Typography, Divider } from '@mui/material';
import { font } from '../../../../../../Widgets/Styles/font';
import Slect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBranchesAsync,
  getTeammemberAsync,
} from '../../../../../../../Store/Branches';

export default function DropDownMenu({
  openMenu,
  setOpenMenu,
  handleOnApplyFilter,
}) {
  const open = Boolean(openMenu);
  const dispatch = useDispatch();
  const store = useSelector((state) => state.branches);
  const [ruleType, setRuleType] = useState({name: "Select rule type", value: ""});
  const [ruleValue, setRuleValue] = useState({name: "Select rule type", value: ""});

  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(getTeammemberAsync({}));
  }, []);

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleApplyFilter = () => {
    handleOnApplyFilter({
      ruleType: ruleType.value,
      ruleValue: ruleValue.value,
    });
    handleClose();
  };
  const handleClearFilter = () => {
    handleOnApplyFilter({
      ruleType: '',
      ruleValue: '',
    });
    handleClose();
  };

  const handleSetRuleType = (e) => {
    setRuleType(e);
  }

  const ruleTypeData =
    ruleType?.value === 'gender'
      ? [
          { title: 'Male', id: 'male' },
          { title: 'Female', id: 'female' },
        ]
      : ruleType?.value === 'jobRole' ? store?.jobRoles :
       ruleType?.value === 'skillSet' ? store?.skillSets?.data.map(val => ({id: val.id, title: val.name})) : [];

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={openMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box
          sx={{
            width: '290px',
            height: '400px',
            ...font,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '24px',
                color: '#242424',
                padding: '0.5rem 1rem',
                fontWeight: '600',
              }}
            >
              Filter
            </Typography>

            <Divider />

            <div className="p-3">
              <Slect
                className="basic-single"
                classNamePrefix="Happy"
                defaultValue={ruleType}
                value={ruleType?.value ? ruleType : null}
                isDisabled={false}
                placeholder="Select rule type"
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name="color"
                options={[
                  { name: 'Gender', value: 'gender' },
                  { name: 'Job role', value: 'jobRole' },
                  { name: 'Skill set', value: 'skillSet' },
                ].map(({ name, value }) => ({
                  label: name,
                  value: value,
                }))}
                onChange={(e) => {
                  handleSetRuleType(e)
                }}
              />

              <Slect
                className="basic-single mt-4"
                classNamePrefix="Happy"
                defaultValue={ruleValue}
                value={ruleValue?.value ? ruleValue : null}
                isDisabled={false}
                placeholder="Select rule value"
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={ruleTypeData.map(({ title, id }) => ({
                  label: title,
                  value: id,
                }))}
                onChange={(e) => {
                  setRuleValue(e)
                }}
              />
            </div>
          </Box>

          <Box display="flex" justifyContent="center" mb="20px">
          <Button
            sx={{
              backgroundColor: 'white',
              textTransform: 'none',
              color: "black",
              width: '30%',
              border: "1px solid black",
              alignSelf: 'center',
              ':hover': {
                backgroundColor: 'white',
              },
            }}
            onClick={handleClearFilter}
            variant="contained"
          >
            Clear
          </Button>

          <Button
            sx={{
              backgroundColor: '#242424',
              textTransform: 'none',
              width: '30%',
              ml: "15px",
              alignSelf: 'center',
              ':hover': {
                backgroundColor: 'rgba(0,0,0,0.5)',
              },
            }}
            onClick={handleApplyFilter}
            variant="contained"
          >
            Apply
          </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
}
