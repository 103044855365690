import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import { Shift } from '../../../../../Network/ServiceClass/Shift'
import { CircularProgress } from '@mui/material';
import { setSuccessAlert } from '../../../../../Store/Company'
import { setRoaster } from '../../../../../Store/Shift'
import {useDispatch,useSelector} from 'react-redux'
import AlertDialog from '../../../../Widgets/SuccessDialog';
import {Box,Typography, Button} from '@mui/material'
import {font} from '../../../../Widgets/Styles/font'
import successImage from '../../../../../Assets/success.png'
import FormModal from '../../../../Widgets/Animation/FormModal';


const shift = new Shift();

export default function DeleteShift({openDeleteModal, handleClose, shiftDetails, handleCloseShiftDetails}) {
  const dispatch = useDispatch()
  const shiftStateWeeklyDate = useSelector((state) => state.shift.weeklyDate)
  const shiftStateBranch = useSelector((state) => state.shift.selectedBranch)

  const [isLoading, setIsLoading] = useState(false);
  const shiftId = shiftDetails.length === 0 ? 0 : shiftDetails[0].id

  const handleCloseModal = () => {
    handleClose()
  }

  const handleDeleteShift = () => {
    setIsLoading(true)
    
    shift.deleteShift(shiftId).then((value)=>{
      // console.log(value.data)
      setIsLoading(false)
      
      handleCloseModal()
      handleCloseShiftDetails()

      dispatch(setSuccessAlert({
        status:true,
        title: 'Successful',
        content: 'Your shift has been cancel successfully',
        image: successImage
        
      }))

    }).catch((err)=>{
      console.log(err);
      setIsLoading(false)

    });
    shift.fetchRoaster(shiftStateBranch ,shiftStateWeeklyDate.to, shiftStateWeeklyDate.from, 1).then((value)=>{
      dispatch(setRoaster(value.data.data))
      //loading roaster loader
      }).catch((err)=>{
      console.log(err);
      });

  }
 
  return (
    <div>
      <AlertDialog/>
      <FormModal open={openDeleteModal} handleClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '30%',
          left: '40%',
          // transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          width: '285px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          padding: '1rem',
          ...font

        }}>
          <Typography sx={{
            fontSize: '18px',
            color: '#242424',
            ...font
          }}>Delete Shift</Typography>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4rem'
            }}>

              <Button sx={{
                border: '1px solid black',
                borderColor: 'black',
                padding: '0.5rem 1rem',
                borderRadius: '0.2rem',
                color: 'black',
                ...font
              }}  onClick={handleCloseModal}> Cancel </Button>

              {!isLoading && <Button sx={{
                border: '1px solid black',
                borderColor: '#C15353',
                padding: '0.5rem 1rem',
                borderRadius: '0.2rem',
                backgroundColor: '#FAF2F2',
                color: '#C15353',
                ...font
              }}  onClick={handleDeleteShift}> Delete </Button> }
              {isLoading && <CircularProgress sx={{marginLeft: '1.2rem', marginRight: '1.2rem'}}/> }


            </Box>

        </Box>

      </FormModal>
     
    </div>
  );
}