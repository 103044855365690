import React from 'react'

const TableLayout = ({children, styles='w-[50%]'}) => {
  return (
    <div className={` shadow-shift-shadow bg-white py-3 px-[10px] rounded-lg flex flex-col gap-6 ${styles} dark:bg-1F1`}>
        {children}
    </div>
  )
}

export default TableLayout