import React, { useState } from "react";
import { Popper, Box } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const PopMenu = ({
  openPopper,
  setOpenPopper,
  placement = "right-start",
  children,
}) => {
  const open = Boolean(openPopper);
  const id = open ? "simple-popper" : undefined;

  const handleClose = () => {
    setOpenPopper(null);
  };

  const mainAnim = {
    hidden: { opacity: 0 },
    vissible: {
      opacity: 1,
      transition: { ease: "easeIn", duration: 0.5 },
    },
    exit: {
      opacity: 0,
      transition: { ease: "easeOut", duration: 0.5 },
    },
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={openPopper}
      onClose={handleClose}
      placement={placement}
      sx={{ zIndex: 1300 }}
    >
      <motion.div
        variants={mainAnim}
        initial={"hidden"}
        animate={"vissible"}
        exit={"exit"}
      >
        {children}
      </motion.div>
    </Popper>
  );
};

export default PopMenu;
