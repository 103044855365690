import {useState} from 'react'
import Button from '@mui/material/Button';
import {font} from '../../../Widgets/Styles/font'
import {IoClose} from 'react-icons/io5'
import { BsSearch } from 'react-icons/bs';
import {Input, TextArea} from '../../../Widgets/FormUtils/FormUtils'

import { useDispatch, useSelector} from 'react-redux';
import { 
  deleteJobRolesAsync, 
  updateJobRolesAsync, 
  assignJobToTagAsync, 
  fetchJobRolesWithoutLoadingAsync, 
  removeJobTagFromJobRoleAsync 
} from '../../../../Store/Branches';

import { toast } from 'react-toastify';
import { CustomPopper } from '../../../Widgets/CustomPopper';
import ColorPicker from '../../../Widgets/ColorPicker';

import JobPopper from '../../../Widgets/JobPopper';
import { FillButton, OutlinedButton } from '../../../Widgets/FormUtils/FormUtils';

const PopDetail = ({openPopper, setOpenPopper, data, setClikedRow, edit=false}) => {

    const groupsTag = useSelector(state=> state.branches)?.jobGroups

    const dispatch = useDispatch()
  
    const [jobTitile, setJobTitle] = useState(data.title)
    const [decsribtion, setDecsribtion] = useState(data.desc)
    const [jobColor, setJobColor] = useState(data?.hexcode)
    const [disableField, setDisableField] = useState(!edit)
    const date = new Date(data.createdOn.split('T')[0]).toDateString().split(' ')
    const [inputSearch, setInputSearch] = useState('')
    const [selectedTag, setSelectedTag] = useState(data.roleTags.map(val => val.tagInfo))
    const [openTagPopper, setOpenTagPopper] = useState(null)

    const [loadingTag, setLoadingTag] = useState(false)

    const handleSearchTag = (val) => {
      let filteredTag = groupsTag
      if(val.length >= 1){
        const pattern = new RegExp(`\D*${val}\D*`,'i')
        filteredTag = groupsTag.filter(x => x.name.match(pattern) )
      }
      return filteredTag
    }

    const handleAssignJobRoleToTag = (tag) => {
      setLoadingTag(true)
      dispatch(assignJobToTagAsync({tagId: tag.id, memberships:[data.id]}))
      .then(val => {
          if(val?.meta?.requestStatus === 'rejected') {}
          else if (val?.meta?.requestStatus === 'fulfilled'){
            setSelectedTag([...selectedTag, tag])
            dispatch(fetchJobRolesWithoutLoadingAsync())

          }
          // console.log(val)
          setLoadingTag(false)
        })
    }

    const handleRemoveTag = (e, tag)=>{
      e.stopPropagation()
      setLoadingTag(true)
      // console.log(tag)

      dispatch(removeJobTagFromJobRoleAsync({tagId: tag?.id, jobId: data?.id}))
      .then(val => {
        setLoadingTag(false)
        if (val.payload?.status === 200){
          setSelectedTag([...selectedTag.filter(x => x.id !== tag.id)])
          dispatch(fetchJobRolesWithoutLoadingAsync())
        }
        else {
          toast.error(val.payload?.data?.message)
        }
      })
    }
    
    const handleClose = () => {
      setOpenPopper(null);
      setClikedRow(null)
      setDisableField(true)
      return true
    };
  
    const handleApprove = () => {
      // setIsLoadingApprove(true)

      dispatch(updateJobRolesAsync({ jobId: data.id, data: {
        title: jobTitile,
        desc: decsribtion,
        hexcode: jobColor
      }})).then((res) => {
        if(res.meta.requestStatus === "fulfilled"){
          toast.success(`${jobTitile} was successfully updated`)
        }
      })
      handleClose()
     
    }
  
    const handleDecline = () => {
     dispatch(deleteJobRolesAsync({ jobId: data.id }))
     
    }

    

  return (
      
        <JobPopper
          title={'Role Details'} 
          openPopper={openPopper}
          handleClose={handleClose}
          handleClickEdit={()=> setDisableField(!disableField)}
        >
          
            {disableField && <div className='flex flex-col gap-1'>
              <p className='text-[#A5A5A5]'>
              Last updated
              </p>

              <p>{date.slice(0,4).join(' ')}</p>

            </div>}

            {/* third row */}
            {!disableField && <div className='flex flex-col gap-1'>
                <p className='text-[#A5A5A5]'>Job title</p>

                <Input
                  value={jobTitile}
                  onChange={(e)=>setJobTitle(e.target.value)}
                  disable={disableField}
                />
            </div>}


            {disableField && <div className='flex flex-col gap-1'>
              <p className='text-[#A5A5A5]'>Job title</p>
              <p className='text-[14px]'>{jobTitile}</p>

            </div>}

            {/* third four */}

            {!disableField && <div className='flex flex-col gap-1 h-[120px]'>
              <p className='text-[#A5A5A5]'>Job Description</p>
              <TextArea
                  value={decsribtion}
                  onChange={(e)=>setDecsribtion(e.target.value)}
                  disable={disableField}
                />
            </div>}

            {disableField && <div className='flex flex-col gap-1'>
              <p className='text-[#A5A5A5]'>Job Description</p>
              <p className='text-[14px]'>{decsribtion}</p>

            </div>}

            {/* third four */}
          

            {!disableField && <div className='flex flex-col gap-1'>
              <ColorPicker  defaultColor={jobColor} onChangeColor={(color)=>{
                setJobColor(color)
            }}/>
            </div>}


            {!disableField && <div className='flex flex-col gap-1'>
              <p className='text-[#A5A5A5]'>Job Groups</p>

              <div onClick={(e)=> {
                e.stopPropagation()
                setOpenTagPopper(openTagPopper === null ? e.currentTarget : null)
              }}
              className='w-[100%] hover:border-A5A p-2 cursor-pointer min-h-[30px] max-h-[100px] overflow-y-auto border-1 dark:border-333 flex flex-wrap gap-2 rounded-md'>
                
                {!loadingTag ? 
                (selectedTag?.map(tag => 
                  <div className='bg-AFF dark:bg-color-333 flex justify-between items-center rounded-md text-[12px] border-1 dark:border-333 min-w-[100px] px-1 py-[2px]'>
                    <p className='w-[90%]'>{tag?.name}</p> 
                    <IoClose 
                    onClick={(e)=>handleRemoveTag(e, tag)}
                    className='cursor-pointer text-[18px]'/>
                  </div>  
                )):
                <p className='text-center text-[14px] w-full'>Loading...</p>
                }
              </div>

              <CustomPopper 
                openPopper={openTagPopper}
                handleClose={()=> setOpenTagPopper(null)}
                placement='right-end'>

                <div  style={{boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1)', borderRadius: '10px'}}
                className='text-[14px] flex flex-col gap-2 pt-3 w-[250px] bg-[white] dark:bg-1F1 max-h-[250px] px-2 py-2  overflow-y-auto'>
                
                <div className='py-[4px] bg-white dark:text-white dark:bg-1F1 text-[14px] border-1 dark:border-333 px-2 rounded-md flex gap-2 sticky top-[-8px] items-center'>
                  <BsSearch />
                  <input 
                  className='focus:outline-none dark:bg-1F1'
                  type="text" placeholder='Search' onChange={(e)=> setInputSearch(e.target.value)}/>
                  
                </div>

                <div>
                  {handleSearchTag(inputSearch)?.length > 0 ? 
                    (handleSearchTag(inputSearch)?.map(tag => 
                      <p 
                      onClick={()=>handleAssignJobRoleToTag(tag)}
                      className='hover:bg-5A5 dark:hover:bg-color-333 dark:text-white py-1 px-2 cursor-pointer'>{tag.name}</p>)
                    ):
                    <p className='dark:text-white'>Nothing here</p>
                  }
                </div>


              
              </div>
              </CustomPopper>
              
              <div className='w-[50%] text-[14px]'>
                  
              </div>
            </div>}


            {!disableField &&<div className='flex justify-between mt-[5px] mb-[2px]'>
              <OutlinedButton 
                text={'Cancel'}
                callBack={handleClose}
              />

              <FillButton text={'Save'} callBack={handleApprove}/>
              
            </div>}


        </JobPopper>
                

               


  )
}

export default PopDetail

