import React from 'react';
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { Stack } from '@mui/material';
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { TextAndField, Text, Field } from '../../../../../Widgets/FormUtils/FormUtils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '533px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

export const CreateGroupModal = ({
  open = false,
  handleClose = () => {},
  loading = false,
  onSubmit
}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("This is required"),
      description: Yup.string().required("This is required"),
    }),
    onSubmit: (value, { setSubmitting }) => {
      setTimeout(()=>{
        onSubmit(value)
      },400)
    }
  })

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-A06 dark:text-white' direction="column" alignItems="center" sx={style} gap={3}>
        <p className="font-bold text-2xl text-center text-424 dark:text-white">Create group</p>
        <div className="mb-5 w-full">
          <TextAndField>
            <Text size="16px" weight="600" text={'Name'} />
          <Field
            text={'Name'}
            value={formik.values.name}
            formik={formik}
            name="name"
          />
          </TextAndField>
          <div className='my-3'>
        <TextAndField>
            <Text size="16px" weight="600" text={'Description'} />
          <Field
            text={'Description'}
            value={formik.values.description}
            formik={formik}
            name="description"
            multiline={true}
            maxRows={3}
          />
          </TextAndField>
          </div>
        <div className="flex items-center gap-10 mt-[50px] justify-between w-full">
          <button
            onClick={loading ? null : handleClose}
            className="border-D57 border rounded-md w-[70%] py-2"
          >
            Cancel
          </button>
          <button
            onClick={loading ? null : formik.handleSubmit}
            className="bg-btn-color text-white rounded-md w-[70%] py-2"
          >
           {loading ? "Loading..." : "Save"}
          </button>
        </div>
        </div>
      </Stack>
    </FormModal>
  );
};
