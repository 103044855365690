import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Divider, CircularProgress} from '@mui/material';
import {IoClose} from 'react-icons/io5'
import archiveImg from '../../../../../Assets/trash.png'
import likeImg from '../../../../../Assets/heart.png'
import darkLikeImg from '../../../../../Assets/dark-like.png'
import darkArchive from '../../../../../Assets/dark-archive.png'
import deleteIcon from '../../../../../Assets/deteteicon.png'
import FormModal from '../../../../Widgets/Animation/FormModal';
import {likeAnnouncement,archiveAnnouncement} from '../../../../../Network/ServiceClass/announcementService'
import { useDispatch, useSelector } from 'react-redux';
import { refresh } from '../../util/refresh';
import CustomModal from '../../../../Widgets/CustomModal';

export default function Details({openModal, handleClose, data}) {

  const dispatch = useDispatch()
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;

  const [isLoadingLike, setIsLoadingLike] = useState(false)
  const [isLoadingArchive, setIsLoadingArchive] = useState(false)
  const [likeButton, setLikeButton] = useState(data.userSentiments.includes('like')? true : false)
  const [archiveButton, setArchiveButton] = useState(data.archived ? true : false)

  // console.log(data)

  

  const handleLiked = () => {
      setIsLoadingLike(true)
      likeAnnouncement(data.id).then(val => {
          setIsLoadingLike(false)
          refresh(dispatch)
          setLikeButton(!likeButton)
      }).catch(error => {
          console.log(error)
          setIsLoadingLike(false)
      })

  }


  const handleArchived = () => {
    setIsLoadingArchive(true)
    archiveAnnouncement(data.id).then(val => {
        setIsLoadingArchive(false)
        refresh(dispatch)
        setArchiveButton(!archiveButton)
    }).catch(error => {
        console.log(error)
        setIsLoadingArchive(false)
    })

}

  
   
  return (
    <div>

      <CustomModal 
        modalClassName='w-[35%]'
        open={openModal} handleClose={handleClose}>
        {data !== null && <div className='mt-[10px] dark:text-white flex flex-col gap-4'>
              <p className='text-[16px] font-semibold'>{data.title}</p>
              <div className='p-2 border-1 dark:border-333 text-[14px] min-h-[134px] max-h-[134px] overflow-auto rounded-md'>
               {data.description}
              </div>

              <div className='flex justify-between'>
                <div className='flex flex-row items-center gap-3'>
                  {!isLoadingLike && <img src={likeButton ? darkLikeImg : likeImg} alt="heart" className='cursor-pointer' onClick={handleLiked}/>}
                  {isLoadingLike && <CircularProgress size={20}/>}

                  {[1,2].includes(privilege) && !isLoadingArchive && <img src={archiveButton ? darkArchive : archiveImg} alt="trash" className='cursor-pointer' onClick={handleArchived}/>}
                  {isLoadingArchive && <CircularProgress size={20}/>}

                </div>

                {/* <div><img src={deleteIcon} alt="delete" /></div> */}
              </div>

              <Divider className='dark:border-333'/>

              <p className='text-[10px] font-semibold'>From {`${data.createdBy.firstName} ${data.createdBy.lastName}`} </p>

        </div>}
      </CustomModal>

      
    </div>
  );
}

