import { useEffect } from 'react';
import './App.css';
import { WelcomeScreen } from './Components/Screens/WelcomeScreen/WelcomeScreen.jsx';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { SignUpScreen } from './Components/Screens/SignUpScreen/SignUpScreen';
import { HomePage } from './Components/Screens/HomePageScreen/HomePage';
import BranchScreen from './Components/Screens/BranchScreen/BranchScreen';
import { LoginScreen } from './Components/Screens/LoginScreen/LoginScreen';
import { JobScreen } from './Components/Screens/JobsScreen/JobScreen';
import { ShiftScreen } from './Components/Screens/ShiftScreen/ShiftScreen';
import { VerifyEmailAddress } from './Components/Screens/VerifyEmailAddress/VerifyEmailAddress';
import { MyCalendar } from './Components/Screens/Calendar';
import { Forgottenpassword } from './Components/Screens/ForgottenPassword/Forgottenpassword';
import { ResetPassword } from './Components/Screens/ForgottenPassword/ResetPassword';
import { InviteMember } from './Components/Screens/InviteMember/InviteMember';
import { TaskScreen } from './Components/Screens/TaskScreen/TaskScreen';
import { ReportScreen } from './Components/Screens/ReportScreen/ReportScreen';
import ProfilePageScreen from './Components/Screens/ProfilePageScreen/ProfilePageScreen';
import { ShiftSlotPage } from './Components/Screens/ShiftScreen/Tabs/ShiftSlotPage/ShiftSlot';
import { WriteUpScreen } from './Components/Screens/WriteUpScreen/WriteUpScreen';
import { SwapOffers } from './Components/Screens/SwapOffers/SwapOffers';
import BoardScreen from './Components/Screens/BoardScreen/BoardScreen';
import { Rotations } from './Components/Screens/ShiftScreen/Tabs/Rotations/Rotation';
import { TimeOff } from './Components/Screens/ShiftScreen/Tabs/TimeOff/TimeOff';
import { Announcements } from './Components/Screens/Announcements/Announcements';
import { OverTimeScreen } from './Components/Screens/ShiftScreen/Tabs/OverTime/OverTime';
import AppToast from './Components/Widgets/Toaster/AppToast';
import BulkInviteScreen from './Components/Screens/BulkInvites/BulkInvites';
import ViewInvitesScreen from './Components/Screens/BulkInvites/ViewInvite/ViewInvite';
import BulkAccountScreen from './Components/Screens/BulkAccount/BulkAccount';
import ViewAccountsScreen from './Components/Screens/BulkAccount/ViewAccounts/ViewAccounts';
import "react-datepicker/dist/react-datepicker.css";
import { ShiftTemplate } from './Components/Screens/ShiftScreen/Tabs/ShiftTemplate/ShiftTemplate';
import { Schedules } from './Components/Screens/ShiftScreen/Tabs/Schedules/SchedulesScreen';
import Chats from './Components/Screens/Chats/Chats';
import { BranchSchedules } from './Components/Screens/ShiftScreen/Tabs/BranchSchedule/BranchSchedule';
import UnavailableDays from './Components/Screens/UnavailableDays/UnavailableDays';
import Holiday from './Components/Screens/Holiday/Holiday';
import Gpt from './Components/Screens/Gpt/Gpt';
import { CompanyDetails } from './Components/Screens/CompanyDetails/CompanyDetails';
import { CombinedSchedules } from './Components/Screens/CombinedSchedule/CombinedSchedule';
import { Skillset } from './Components/Screens/Skillset/Skillset';
import { JobGroup } from './Components/Screens/JobsGroup/JobGroup';
import { Benchmarks } from './Components/Screens/Benchmarks/Benchmarks';
import { ClockInClockOut } from './Components/Screens/ClockInAndOut/ClockInAndOut';
import { InventoryItems } from './Components/Screens/InventoryItems/InventoryItems';
import { InventoryItemList } from './Components/Screens/InventoryItems/inventoryItemsList/inventoryItemsList';
import { InventoryListItem } from './Components/Screens/InventoryItems/inventoryListItem/inventoryListItem';
import { InventoryItemGroup } from './Components/Screens/InventoryItems/InventoryItemGroup/inventoryItemGroup';
import { TransferItems } from './Components/Screens/TransferItems/TransferItems';
import { TransferItemList } from './Components/Screens/TransferItems/TransferItemList/TransferItemList';
import ListOfTransferedItems from './Components/Screens/TransferItems/ListOfTransferedItems/ListOfTransferedItems';
import { ConfirmTransfer } from './Components/Screens/TransferItems/ConfirmTransfer/ConfirmTransfer';
import { QuickWithdraw } from './Components/Screens/QuickWithdrawal/QuickWithdraw';
import { QuickWithdrawalList } from './Components/Screens/QuickWithdrawal/QuickWithdrawalList/QuickWithdrawalList';
import { ConfirmQuickWithdrawal } from './Components/Screens/QuickWithdrawal/ConfirmQuickWithdrawal/ConfirmQuickWithdrawal';
import { StockOrderRequest } from './Components/Screens/OrderRequest/StockOrderRequest/StockOrderRequest.jsx';
import { BackOrderRequest } from './Components/Screens/OrderRequest/BackOrderRequest/BackOrderRequest.jsx';
import OrderRequestItem from './Components/Screens/OrderRequest/Widget/OrderRequestItem.jsx';
import InventoryOrderRequest from './Components/Screens/InventoryOrderRequest/InventoryOrderRequest';
import InventoryLogs from './Components/Screens/InventoryLogs/InventoryLogs';
import { OrderLogs } from './Components/Screens/InventoryLogs/OrderLogs/OrderLogs';
import OrderLogsItems from './Components/Screens/InventoryLogs/OrderLogs/OrderLogsItems';
import InventoryReport from './Components/Screens/InventoryReport/InventoryReort';
import DailyReport from './Components/Screens/InventoryReport/DailyReport/DailyReport';
import DailyReportItem from './Components/Screens/InventoryReport/DailyReport/DailyReportItem';
import UsageReport from './Components/Screens/InventoryReport/UsageReport/UsageReport';
import { CompanyData } from './Components/Screens/CompanyData/CompanyData';
import InventoryData from './Components/Screens/InventoryData/InventoryData';

import { useSelector, useDispatch } from 'react-redux';
import { Storage } from './Network/StorageClass/StorageClass';

import { io } from 'socket.io-client';
import { setSocket } from './Store/socketSlice';
import { conn_url } from './Network/conn_consts';

import { setLoggedInUsers, setLoggedUsersCount } from './Store/socketSlice';
import Suppliers from './Components/Screens/Suppliers/Index';
import SuppliersPage from './Components/Screens/Suppliers/SuppliersPage/SuppliersPage';
import ManufacturerPage from './Components/Screens/Suppliers/ManufacturerPage/ManufacturerPage';
import RotationManagement from './Components/Screens/RotationManagement/RotationManagement';
import TroubleTicket from './Components/Screens/Tickets/Tickets';
import SingleTransferItem from './Components/Screens/TransferItems/ListOfTransferedItems/SingleTransferItem';
import InventoryItemGroupList from './Components/Screens/InventoryItems/InventoryItemGroup/inventoryItemGroupList';
import {InventoryMeasurement} from './Components/Screens/InventoryMeasurement/InventoryMeasurement.jsx';

import Tutorials from './Components/Screens/Help/Tutorials/Tutorials.jsx';
import BackOrderRequestItem from './Components/Screens/OrderRequest/Widget/BackOrderRequestItem.jsx';
import { LimboSchedule } from './Components/Screens/LimboSchedule/LimboSchedule.jsx';


function App() {
  const storage = new Storage()
  const dispatch = useDispatch()
  const socketStateLoggedIn = useSelector(state => state?.socket?.loggedIn)
  const socketState = useSelector(state => state?.socket?.socket)

  
  useEffect(()=> {
    const handleOffline = () => {
      // Emit an event indicating disconnection
      socketState?.emit('logout')
      socketState?.disconnect();
    };
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  },[])

  useEffect(()=> {
    // console.log(storage.getUserDetails())
    if (storage.getUserDetails() !== null ){
     
      const socket = io(conn_url, {
        query: { userId: storage.getUserDetails()?.id },
        // reconnection: true,         // Enable reconnection
        // reconnectionAttempts: 3,    // Limit reconnection attempts to 3 times
        // reconnectionDelay: 1000,    // Delay between reconnection attempts (in milliseconds)
        // Other options...
      });
      dispatch(setSocket(socket));

    
      socket.on('disconnect', () => {
        console.log('Disconnected to socket.io server');
      });

      socket.on('connect', (data) => {
        console.log('Connected to socket.io server');
        if (socket && storage.getActiveCompany() !== null) {

          const payload = {
            userId: storage.getUserDetails()?.id,
            teamMemberId: storage.getActiveCompany()?.memberships[0]?.id,
            branchId: storage.getActiveCompany()?.memberships[0]?.assignedBranchId,
            companyId: storage.getActiveCompany()?.id

          }

          socket.emit('join-company-group', payload);
        }
  
        socket.on('live-users', (data)=>{
          dispatch(setLoggedUsersCount(data?.count))
          dispatch(setLoggedInUsers(data?.data))
        })

        socket.on('user-logged-out', ( data ) => {
          // console.log(data);s
          dispatch(setLoggedUsersCount(data?.count))
          dispatch(setLoggedInUsers(data?.data))
        });
  
      });
  
      return () => {
        socket.disconnect();
        socket?.off("live-users");
        socket?.off("user-logged-out");

      };
    }

  }, [socketStateLoggedIn])

  

  
  
  return (
    <div className="App relative">
     
      <AppToast/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<WelcomeScreen />} />
          <Route path='/signup' element={<SignUpScreen />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/branches' element={<BranchScreen />} />
          
          <Route path='/branches/bulk-invite' element={<BulkInviteScreen />} />
          <Route path='/branches/bulk-invite/send' element={<ViewInvitesScreen />} />
          <Route path='/branches/bulk-account' element={<BulkAccountScreen />} />
          <Route path='/branches/bulk-account/send' element={<ViewAccountsScreen />} />
          <Route path='/branches/bench-marks/:branchId' element={<Benchmarks />} />
          <Route path='/login' element={<LoginScreen />} />
          <Route path='/jobs' element={<JobScreen />} />
          <Route path='/shift' element={<ShiftScreen />} />
          <Route path='/shift-slot' element={<ShiftSlotPage />} />
          <Route path='/confirm-email' element={<VerifyEmailAddress />} />
          <Route path='/forgot-password' element={<Forgottenpassword />} />
          <Route path='/calendar' element={<MyCalendar />} />
          <Route path='/profile-page' element={<ProfilePageScreen />}/>
          <Route path='/forgot' element={<Forgottenpassword />} />
          <Route path='/tasks' element={<TaskScreen />} />
          <Route path='/write-ups' element={<WriteUpScreen/>} />
          <Route path="/reports" element={<ReportScreen />} />
          <Route path='/swap-offers' element={<SwapOffers />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/clock-in-clock-out' element={<ClockInClockOut />} />
          <Route path='/signup/invitation' element={<InviteMember />}/>
          <Route path='/inventory-items' element={<InventoryItems />}/>
          <Route path='/rotation-management' element={<RotationManagement />}/>
          <Route path='/inventory-items-list' element={<InventoryItemList />}/>
          <Route path='/inventory-items-group' element={<InventoryItemGroup />}/>
          <Route path='/inventory-items-group/:groupId' element={<InventoryItemGroupList />}/>
          <Route path='/inventory-items-list/:itemId/:item' element={<InventoryListItem />}/>
          <Route path='/transfer-items' element={<TransferItems />}/>
          <Route path='/transfer-items-list' element={<TransferItemList />}/>
          <Route path='/transfered-items-list' element={<ListOfTransferedItems />}/>
          <Route path='/transfered-items-list/:itemId' element={<SingleTransferItem />}/>

          <Route path='/transfer-items-confirm' element={<ConfirmTransfer />}/>
          <Route path='/quick-withdrawal' element={<QuickWithdraw />}/>
          <Route path='/quick-withdrawal-list' element={<QuickWithdrawalList />}/>
          <Route path='/quick-withdrawal-confirm' element={<ConfirmQuickWithdrawal />}/>
          
          <Route path='/stock-order-request' element={<StockOrderRequest />}/>
          <Route path='/stock-order-request/:itemId' element={<OrderRequestItem />}/>

          <Route path='/back-order-request' element={<BackOrderRequest />}/>
          <Route path='/back-order-request/:itemId' element={<BackOrderRequestItem />}/>

          <Route path='/inventory-logs' element={<InventoryLogs />}/>
          <Route path='/order-logs' element={<OrderLogs />}/>
          <Route path='/order-logs/:itemId' element={<OrderLogsItems />}/>

          <Route path='/inventory-report' element={<InventoryReport />}/>
          <Route path='/inventory-daily-report' element={<DailyReport />}/>
          <Route path='/inventory-daily-report/:itemId' element={<DailyReportItem />}/>
          <Route path='/inventory-usage-report' element={<UsageReport />}/>
          <Route path='/inventory-data' element={<InventoryData />}/>
          <Route path='/inventory-measurement' element={<InventoryMeasurement />}/>


          <Route path='/create-order-request' element={<InventoryOrderRequest />}/>
          <Route path='/rotation' element={<Rotations />} />
          <Route path='/schedules' element={<Schedules />} />
          <Route path='/branch-schedule' element={<BranchSchedules />} />
          <Route path='/unavailable-days' element={<UnavailableDays />} />
          <Route path='/ticket-management' element={<TroubleTicket />} />
          <Route path='/combined-schedule' element={<CombinedSchedules />} />
          <Route path='/limbo-schedule' element={<LimboSchedule />} />
          <Route path='/time-off' element={<TimeOff />} />
          <Route path='/tasks/board' element={<BoardScreen />} />
          <Route path='/announcement' element={<Announcements />} />
          <Route path='/over-time' element={<OverTimeScreen />} />
          <Route path='/templates' element={<ShiftTemplate />} />
          <Route path='/messages' element={<Chats />} />
          <Route path='/holidays' element={<Holiday />} />
          <Route path='/Nitro-AI' element={<Gpt />} />
          <Route path='/company-details' element={<CompanyDetails />} />
          <Route path='/company-data' element={<CompanyData />} />
          <Route path='/skillset' element={<Skillset/>}/>
          <Route path='/job-groups' element={<JobGroup/>}/>
          <Route path='/suppliers' element={<Suppliers/>}/>
          <Route path='/suppliers-page' element={<SuppliersPage/>}/>
          <Route path='/manufacturers-page' element={<ManufacturerPage/>}/>


          <Route path='/tutorials' element={<Tutorials/>}/>


        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
