import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const getAllWriteUp = async (order = 'ASC') => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/writeup?order=${order}`,storage.getConfig());
    return response.data;
    
  } catch (error) {
    throw Error(error)
  }
}

export const getMembers = async () => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/company/members`,storage.getConfig());
    return response.data;
    
  } catch (error) {
    throw Error(error)
  }
}

export const createWriteUp = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/writeup`, data, storage.getConfig());
    return response.data;
    
  } catch (error) {
    throw Error(error)
  }
}

export const deleteWriteUp = async (id) => {
  try {
    await axios.delete(`${conn_url}/api/v1/writeup/${id}`, storage.getConfig());
    return id;
    
  } catch (error) {
    throw Error(error)
  }
}


