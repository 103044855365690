export function transformScheduleData ({teamMembers, branches}){
  let data = {};
  const companyBranches = branches?.map((branch) => ({ name: branch?.name, id: branch?.id, city: `${branch?.city?.name}, ${branch?.state?.name}`}))
  let tableData = []

  teamMembers?.forEach(member => {
    if(member.assignedBranchId){
      if(!data[member.assignedBranchId] ){
        data = {
          ...data,
          [member.assignedBranchId]: [{name: `${member?.user?.firstName} ${member?.user?.lastName}`, id: member?.user?.id, imageUrl: member?.user?.picture?.url, jobRole: member?.jobRole, secondaryJobroles: member?.secondaryJobroles , email: member?.user.email, branch: member?.assignedBranch,profileInfo: member}]
        }
      } else {
        data = {
          ...data,
          [member.assignedBranchId]: [...data[member.assignedBranchId], {name: `${member?.user?.firstName} ${member?.user?.lastName}`, id: member?.user?.id, imageUrl: member?.user?.picture?.url, secondaryJobroles: member?.secondaryJobroles, jobRole: member?.jobRole, email: member?.user.email, branch: member?.assignedBranch, skills: member?.userSkillSets,profileInfo: member}]
        }
      }
    }
  })

  companyBranches.forEach((bran) => {
    if(data[bran?.id]){
      data[bran?.id]?.forEach(x => {
        tableData.push({user: x, mode: 'data', branchId: bran.id, branchName: bran.name, email: bran.email, city: bran?.city});
      })
    }
  })

  return tableData
}