import { useEffect } from 'react';
import {getAnnouncements} from '../../../../Network/ServiceClass/announcementService'
import {setAll, setArchive, setFavorite} from '../../../../Store/announcement'

export const refresh = (dispatch) => {
    getAnnouncements('all').then(val=>{
      dispatch(setAll(val.data.data))
    }).catch()
    getAnnouncements('archived').then(val=>{
        dispatch(setArchive(val.data.data))
    }).catch()
    getAnnouncements('like').then(val=>{
        dispatch(setFavorite(val.data.data))
    }).catch()
  }

    