import React from 'react'
import {BsChat} from 'react-icons/bs'
import {AiOutlineSave} from 'react-icons/ai'
import { CustomIconButton } from '../FormUtils/FormUtils';
import InitialsGen from '../InitialsGen';

const UserDetail = ({userInfo}) => {
  
  return (
    <div className='flex flex-col gap-4 text-[14px] pl-6 pt-4 text-[#a5a5a5]'>

        <div className='flex gap-3 items-center'>
            {userInfo?.picture?.url ? (
                <img
                className="rounded-full h-6 w-6 mr-2"
                src={userInfo?.picture?.url}
                alt="logo"
                />
            ) : (
                <InitialsGen
                width={30}
                name={`${
                    userInfo?.name
                }`}
                bgColor="#969696"
                />
            )}

            <p>{userInfo?.name}</p>

        </div>
        <p className=''> {userInfo?.email} </p>

        <div className='flex gap-2 items-center'>
            <p className={`h-2 w-2 rounded-full `} style={{
                backgroundColor: userInfo.jobRole?.hexcode !== 'NULL' ? userInfo.jobRole?.hexcode : 'black'
            }}></p>
            <p>{userInfo.jobRole?.title} {`${userInfo.jobRole?.symbol !== ''? `- ${userInfo.jobRole?.symbol}`: ''}`}</p>
        </div>

        <div className='flex gap-2 items-center'>
            <p className={`h-2 w-2 rounded-full `}
            style={{
                backgroundColor: userInfo.branch?.hexcode !== 'NULL' ? userInfo.branch?.hexcode : 'black'
            }}></p>
            <p>{userInfo.branch?.name} {` ${userInfo.branch?.symbol !== '' ? `- ${userInfo.branch?.symbol}`: ''}`}</p>
        </div>

        {/* <div className='flex gap-2'>
            <CustomIconButton Icon={BsChat} />
            <CustomIconButton Icon={AiOutlineSave} />
        </div> */}
    </div>
  )
}

export default UserDetail