import { BsPlus } from 'react-icons/bs';

const CustomButton = ({title = '', containerClass = '', iconProp, className = '', showIcon = true, Icon = BsPlus, ...rest}) => {
  return (
    <div className={containerClass}>
      <button className={`border-1 border-color-424 rounded p-[10px] px-3 font-semibold text-sm flex items-center hover:opacity-70 ${className}`} {...rest}>
        {showIcon && (<Icon className='dark:text-white' size={iconProp?.size || "24px"} {...iconProp} />)} 
        <span>{title}</span>
      </button>
    </div>
  );
};


export default CustomButton;