import React from 'react'
import { ShiftSpot } from '../ShiftSpot/ShiftSpot'
import DateLabel from '../DateLabel/DateLabel'
import EmployeeLabel from '../EmployeeLabel/EmployeeLabel'
import {useDispatch,useSelector} from 'react-redux'
import {setWeeklyDate,setToggleCalender,setDayNumber,setToggleShiftDetails, setRoaster} from '../../../../../Store/Shift'
import addOneDay from '../../../../../utils/incrementDate'
import { CircularProgress,Box } from '@mui/material'


const WeekView = ({dates,}) => {

  const dispatch = useDispatch()
  const shiftStateIsLoadingRoaster = useSelector((state) => state.shift.isLoadingRoaster)
  const shiftStateRoaster = useSelector((state) => state.shift.roaster)
  

  const handleViewDetail = (data, user) => {
    dispatch(setToggleShiftDetails({state: true, data, user}))
  }

  const handleClick = (value,index) => {
  const dayNumber = index + 1
  const date = new Date(value).toISOString()

    dispatch(setWeeklyDate({
      to:addOneDay(date), 
      from:date
  }))
  dispatch(setToggleCalender('day'))
  dispatch(setDayNumber(dayNumber))
  
  }
  return (
    <>
    <div className='h-[70vh] overflow-y-auto'>
      
      <table >
        <thead className='sticky bg-white top-0'>
          <td className='td-padding '>Employee name</td>
          {dates.map((value,index) => {
            if(index < 7) return( <td  onClick={()=>handleClick(value,index)} className='cursor td-padding '>  <DateLabel detailsWeek={value} type='week'/></td>)
          })}
          
        </thead>
        {(!shiftStateIsLoadingRoaster && shiftStateRoaster !== undefined) && shiftStateRoaster.map((value)=>
          <tr> 
            <td className='td-padding'><EmployeeLabel user={value.user} /> </td>
            
            <td className='cursor' onClick={()=> handleViewDetail(value.shiftsPerDay['1'], value.user)}><ShiftSpot data={value.shiftsPerDay["1"]}/></td>
            <td className='cursor' onClick={()=> handleViewDetail(value.shiftsPerDay['2'], value.user)}><ShiftSpot data={value.shiftsPerDay["2"]}/></td>
            <td className='cursor' onClick={()=> handleViewDetail(value.shiftsPerDay['3'], value.user)}><ShiftSpot data={value.shiftsPerDay["3"]}/></td>
            <td className='cursor' onClick={()=> handleViewDetail(value.shiftsPerDay['4'], value.user)}><ShiftSpot data={value.shiftsPerDay["4"]}/></td>
            <td className='cursor' onClick={()=> handleViewDetail(value.shiftsPerDay['5'], value.user)}><ShiftSpot data={value.shiftsPerDay["5"]}/></td>
            <td className='cursor' onClick={()=> handleViewDetail(value.shiftsPerDay['6'], value.user)}><ShiftSpot data={value.shiftsPerDay["6"]}/></td>
            <td className='cursor' onClick={()=> handleViewDetail(value.shiftsPerDay['0'], value.user)}><ShiftSpot data={value.shiftsPerDay["0"]}/></td>

          </tr> 
          ) }

      
      </table>
      {shiftStateIsLoadingRoaster && <Box sx={{display: 'flex', marginTop: '4rem', justifyContent: 'center', alignItems: 'center', }}>
        <CircularProgress/>
      </Box>}
    </div>
    </>
  )
}

export default WeekView