import React, {useState} from 'react'
import './ForgottenPassword.css';
import { Navbar } from '../../Widgets/Navbar'
import { Service } from '../../../Network/ServiceClass/Auth';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {Text, TextAndField, Field, FillButton} from '../../Widgets/FormUtils/FormUtils'
import { CircularProgress, Stack, Box, Typography } from '@mui/material';
import {MdOutlineCancel} from 'react-icons/md'
import {font} from '../../Widgets/Styles/font'
import { useNavigate } from "react-router";


const service = new Service();

export const Forgottenpassword = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const formik = useFormik({
        initialValues:{
          email:"",
        },
    
        validationSchema: Yup.object({
          email: Yup.string().email('Please enter valid email').required("Please enter email")
        }),
        onSubmit:(value)=>{
          ForgotPassword(value.email)
        }
      }) 

    const ForgotPassword = (email)=>{
        if(email!==""){
        setIsLoading(true);
        service.ForgotPassword(email).then((value)=>{
            console.log(value);
            setIsLoading(false);
            setErrorMessage(value.data.message);
            setIsError(true)            
        }).catch((err)=>{
            console.log(err); 
            setIsLoading(false);
            setErrorMessage(err.data.message);
            setIsError(true)
        });
     }
    }

    return (
        <div className='login-screen forgot'>

            <Navbar />

            <div className='flex flex-col dark:text-white w-[386px] m-auto mt-[140px] items-center gap-5'>
                <p className='text-[22px] font-[500]'>Forgot Password ?</p>
                <p className='text-base'>Enter the email address linked to your account</p>

                <div className='flex flex-col gap-10 w-[100%]'>

                <TextAndField>
                    <Text text={"Email"} />
                    <Field text={'Enter email address'}
                    value={formik.values.email}
                    handleChange={formik.handleChange}
                    name={'email'}
                    formik={formik} size="large"/>
                </TextAndField>

                <div className="text-[10px] w-[80px] border-b-1 border-black cursor-pointer">
                    <span onClick={()=>navigate('/login')}>Back to log in</span>
                </div>
            

                {isError && 
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '0.2rem',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    padding: '0.5rem'
                }}>
                    <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                    <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
                </Box>}

                <div>
                    {!isLoading && <FillButton text={'Next'} width={'100%'}  height={'50px'} callBack={formik.handleSubmit}/>}
                    {isLoading && <Stack direction='row' justifyContent='center' ><CircularProgress/></Stack> }
                </div>

            </div>
            </div>



        </div>
    )
}
