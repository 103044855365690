export const numberOfDaysBtwDates = (date1, date2) => {
    let date_1 = new Date(date1);
    let date_2 = new Date(date2);

    const days = (date_1, date_2) =>{
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }
    return days(date_1,date_2)
}