import React from 'react';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import {Stack, Typography,CircularProgress } from '@mui/material';
import {font} from '../../../../Widgets/Styles/font'
import {MdOutlineCancel} from 'react-icons/md'
import FormModal from '../../../../Widgets/Animation/FormModal';
import {Text, Field, TextAndField, FillButton, OutlinedButton} from '../../../../Widgets/FormUtils/FormUtils'
import MembersSet from './MembersSet';



export const NewGroup = ({ openNewGroup, handleCloseNewGroup,}) => {

  const [isloading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const formik = useFormik({
    initialValues: {
      groupName: "",
    },
    validationSchema: Yup.object({
        groupName: Yup.string().required("Please input group name"),
    }),
    onSubmit: (value) => {
      console.log(value);
      
    }
  })

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    minHeight: '70vh',
    maxHeight: '100vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden'
  };
  
  const [openMembersSet, setOpenMembersSet] = useState(false)
  const [members, setMembers] = useState([
    {name: 'one', id:1},
    {name: 'two', id:2},
    {name: 'three', id:3},
    {name: 'four', id:4},
    {name: 'five', id:5},
    {name: 'six', id:6},
    {name: 'seven', id:7},
    {name: 'eight', id:8},
    {name: 'nine', id:9},
    {name: 'ten', id:10},
    {name: 'eleven', id:11},
    {name: 'twelve', id:12},
    {name: 'thirteen', id:13},
    {name: 'fourteen', id:14},
    {name: 'fifteen', id:15},

  ]);


  const [chosenMembers, setChosenMembers] = useState([]);

  return (
    <FormModal open={openNewGroup} handleClose={handleCloseNewGroup}>
      <Stack direction='column' alignItems='center' sx={style} gap={3}>
        <Typography sx={{...font, fontSize: '18px', fontWeight: '500'}}>New group</Typography>
        
        <TextAndField space={0.5}>
            <Text text={'Group Name'}/>
            <Field text={'Product group'} 
                value={formik.values.groupName} 
                formik={formik} 
                name='groupName'/>
        </TextAndField>

        <div className='flex flex-col gap-[10px] relative'>
            <TextAndField space={0.5}>
                <Text text={'Group group members'}/>
                <div className='min-w-[326px] h-[35px] text-[#A5A5A5] cursor-pointer border-1 flex flex-col justify-center pl-2 border-[#d3d4d8] text-[10px] rounded-[4px]'
                onClick={()=>{setOpenMembersSet(true)}}>Search</div>
            </TextAndField>
            {openMembersSet === true && <div className= {!openMembersSet ? 'hidden': 'block'}>
            <MembersSet data={members} setOpenMembersSet={setOpenMembersSet} chosenMembers={chosenMembers} setChosenMembers={setChosenMembers}/>
            </div> }
        </div>

        {isError && 
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '0.2rem',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                padding: '0.5rem'
            }}>
                <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
        </Box>}

        <Stack direction='row' width='82%' justifyContent='space-between' mt={12}>
            <OutlinedButton text='Cancel' callBack={()=>{handleCloseNewGroup(); formik.resetForm()}}/>
            {!isloading && <FillButton text='Submit' callBack={formik.handleSubmit}/>}
            {isloading && <CircularProgress/> }
        </Stack>
      </Stack>
    </FormModal>

  )
}
