import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as DownIcon } from '../../Assets/down-arrow.svg';
import Proptypes from 'prop-types';
import { priority } from '../Screens/mock';

const PriorityDropdown = ({ value = '', onChangeOption, zIndex = "z-20", styleClass ="" }) => {
  const [openDropdown, setDropdown] = React.useState(false);
  const defaultValue = value?.length > 0 ? priority.filter((x) => x.value === value)[0] : priority[0];
  const [selectedPriority, setPriority] = React.useState(defaultValue);

  const handleOpenDropDown = () => {
    setDropdown((prev) => !prev);
  };

  const handleSelectedOption = (val) => {
    onChangeOption(val);
    setDropdown(false);
    setPriority(val)
  };

  const handleCloseDropdown = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <div className="relative">
        <div
          onClick={handleOpenDropDown}
          className="flex justify-between items-center border-1 border-6E6 dark:border-9FD rounded-[5px] py-0 px-[10px]"
        >
          <div
            className={`px-[10px] py-[5px] flex items-center cursor-pointer`}
          >
            <p className={`text-sm font-medium p-2 rounded text-center ${styleClass}`} style={{ backgroundColor: selectedPriority.bgColor, color: selectedPriority.color}}>{selectedPriority?.label}</p>
          </div>
          <DownIcon />
        </div>

        {openDropdown && (
          <div className={`shadow-select bg-white dark:bg-424 absolute w-full mt-4 ${zIndex}`}>
            {priority.map((item, index) => (
              <div
                key={index}
                onClick={() => handleSelectedOption(item)}
                className={`px-[10px] py-[5px] flex items-center hover:bg-5A5 dark:hover:bg-color-333 dark:text-white hover:text-white cursor-pointer`}
              >
                <p className="text-sm font-medium p-2 rounded text-center" style={{ backgroundColor: item.bgColor, color: item.color}}>{item.label}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default PriorityDropdown;

PriorityDropdown.proptype = {
  priority: Proptypes.arrayOf({
    value: Proptypes.string.isRequired,
    label: Proptypes.string.isRequired,
    color: Proptypes.string.isRequired,
    bgColor: Proptypes.string.isRequired,
  }).isRequired,
  value: Proptypes.string.isRequired,
  onChangeOption: Proptypes.func.isRequired,
};
