import React, { useState } from 'react'
import TaskContainer from '../../Widget/TaskContainer/TaskContainer'
import { Boardtheme } from '../../../../../utils/BoardThemes';
import { useSelector } from 'react-redux';



const theme = Boardtheme;

const Task = ({ open, tasks, searchedTask }) => {
    const store = useSelector(state => state.task.taskStatuses) || []

    return (
        <div style={{ display: "grid", width: "100%", gridTemplateColumns: "1fr 1fr 1fr", alignItems: "flex-start", columnGap: "20px", marginTop: "20px" }}>
            <TaskContainer searchedTask={searchedTask} onClick={open} data={store?.statuses?.filter((status) => status.name === 'Not started')[0]} color={theme.notstarted} label="Not Started" />
            <TaskContainer searchedTask={searchedTask} onClick={open} data={store?.statuses?.filter((status) => status.name === 'In Progress')[0]} color={theme.inprogress} label="In Progress" />
            <TaskContainer searchedTask={searchedTask} onClick={open} data={store?.statuses?.filter((status) => status.name === 'Done')[0]} color={theme.done} label="Done" />
        </div>
    )
}

export default Task