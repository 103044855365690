import * as React from 'react';
import Button from '@mui/material/Button';
import {Dialog, Box} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import successImage from '../../Assets/success.png'
import {setSuccessAlert} from '../../Store/Company'
import {useSelector, useDispatch} from 'react-redux'
import {font} from './Styles/font'
import FormModal from './Animation/FormModal';


export default function AlertDialog({open, handleClose, message}) {
  const dispatch = useDispatch()
  const successAlertState = useSelector((state) => state.company.successAlert)

  return (
    <Box >
      <FormModal open={successAlertState.status} handleClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              width: '23vw',
              hieght: '60vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '1rem',
              gap: '1rem',
              borderRadius: '10px'

            }}>
            <DialogTitle id="alert-dialog-title" style={{fontSize:"18px",fontWeight:"400", color: '#242424',...font}}>
              {successAlertState.title}
            </DialogTitle>
            <DialogContent sx={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Box sx={{
                width: '100px',
                height: '100px',
                padding: '20px',
                backgroundColor: '#E5FFEB',
                backgroundImage: `url(${successAlertState.image !== '' ? successAlertState.image : successImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '80%',
                borderRadius: '4rem',
                backgroundPosition: 'center'              
              }}>
              </Box>
              <DialogContentText id="alert-dialog-description" style={{...font,fontSize:"12px", minWidth:'70%', paddingTop: '1rem'}}>
                {successAlertState.content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>

            <Button sx={{...font, backgroundColor:'black', color:'white', padding:'0.5rem 1rem', borderRadius: '0.2rem', ":hover": {background: 'rgba(0, 0, 0, 0.5)'}}} onClick={()=>{
              dispatch(
                setSuccessAlert({status: false, title:'', content:''})
              )
            }}>Done</Button>
            </DialogActions>

          </Box>
      </FormModal>
      
    </Box>
  );
}