import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Proptypes from "prop-types";
import { AiOutlineCaretDown } from "react-icons/ai";
import trimText from "../../utils/trimText";
// import {ReactComponent as ArrowIcon} from '../../Assets/icons/arrow_down_icon.svg'

const CustomDropdown = ({
  data,
  value = { label: "Select", value: "Select" },
  onChangeOption,
  showIcon = true,
  disable = false,
  className = "",
  optionClass = "",
  dropdownTitleClass = "",
  mainContainerStyle = "",
  trimtext = false,
  labelClass = "",
  smallLabelClassName = "",
  trimLength = 10,
  iconColor = "text-black"
}) => {
  const [openDropdown, setDropdown] = React.useState(false);

  const handleOpenDropDown = () => {
    setDropdown((prev) => !prev);
  };

  const handleSelectedOption = (val) => {
    onChangeOption(val);
    setDropdown(false);
  };

  const handleCloseDropdown = () => {
    setDropdown(false);
  };
  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <div className={`relative ${mainContainerStyle}`}>
        <div
          onClick={handleOpenDropDown}
          className={`flex justify-between hover:border-gray-700 dark:hover:border-969 cursor-pointer items-center border-1 border-[#c2c2c2] dark:border-333 rounded-[5px] py-[6px] px-[10px] ${className}`}
        >
          <div className="flex items-center gap-2">
            {value?.hexcode && (
              <div
                className="w-[10px] h-[10px] rounded-full"
                style={{ backgroundColor: value?.hexcode }}
              />
            )}
            <p
              className={`font-bold cursor-pointer text-[14px] ${dropdownTitleClass}`}
            >
              {trimtext === true
                ? trimText(value?.label, trimLength, trimLength)
                : value?.label}
            </p>
          </div>
          {!disable && (
            <AiOutlineCaretDown className={`text-[12px] ${iconColor} dark:text-white`}/>
          )}
        </div>

        {!disable && openDropdown && (
          <div
            className={`shadow-select bg-white dark:bg-828 dark:text-white absolute w-full mt-4 ${optionClass}`}
          >
            {data.map((item) => (
              <div
                onClick={() => handleSelectedOption(item)}
                className={`px-[14px] py-[5px] hover:bg-5A5 dark:hover:bg-color-333 hover:text-white flex items-center justify-between group`}
              >
                <div className="flex items-center gap-2 ">
                  {item?.hexcode && (
                    <div
                      className="w-[10px] h-[10px] rounded-full"
                      style={{ backgroundColor: item?.hexcode }}
                    />
                  )}
                  <p className={` text-[14px] cursor-pointer  ${labelClass}`}>
                    {item.label}{" "}
                    {item.smallLabel !== undefined && (
                      <span className={`text-[9px] ${smallLabelClassName}`}>
                        {item?.smallLabel}
                      </span>
                    )}{" "}
                  </p>
                </div>
                {/* {showIcon && <CheckIcon />} */}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CustomDropdown;

CustomDropdown.proptype = {
  data: Proptypes.arrayOf({
    value: Proptypes.string.isRequired,
    label: Proptypes.string.isRequired,
  }).isRequired,
  value: Proptypes.string.isRequired,
  onChangeOption: Proptypes.func.isRequired,
};
