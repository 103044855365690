import React from 'react';
import InitialsGen from '../../../../Widgets/InitialsGen';

const EmployeeLabel = ({ user, short = true }) => {
  return (
    <div
      className="employee-label"
      style={{ display: 'inline-flex', alignItems: 'center' }}
    >
      {user?.picture?.url ? (
        <img
          className="rounded-full h-10 w-10 mr-2"
          src={user?.picture?.url}
          alt="logo"
        />
      ) : (
        <InitialsGen
          width={40}
          name={`${user.firstName} ${user.lastName}`}
          bgColor="blue"
        />
      )}

        {short && (<div className='name' style={{fontSize:"12px"}}>
           {user.firstName[0]}.{user.lastName}
        </div>)}
        {!short && (<div className='name' style={{fontSize:"12px"}}>
           {user.firstName} {user.lastName}
        </div>)}
    </div>
  );
};

export default EmployeeLabel;
