/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from 'react-router-dom';
import { MoreHoriz } from '@material-ui/icons';
import { CreateGroupModal } from './componennts/Modals/CreateGroup';
import { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { createInventoryGroupsThunk, getInventoryGroupsThunk } from '../../../../Store/Inventory';
import moment from 'moment'
import { toast } from 'react-toastify';
import AppLayout from '../../../Widgets/AppLayout';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import _ from 'lodash'
import { TableWrapper, Tr, Td, TableHeader } from '../../../Widgets/Table/TableWrapper';

export const InventoryItemGroup = () => {
  const [order, setOrder] = useState('asc')
  const dispatch = useDispatch()
  const [open, setModal] = useState(false);
  const navigate = useNavigate()
  const inventoryGroups = useSelector((state) => state.inventory)?.groups
  const isLoading = useSelector((state) => state.inventory)?.loading

  useEffect(()=>{
    dispatch(getInventoryGroupsThunk())
  },[])

  const handleOpenModal = () => {
    setModal(true)
  }

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleCreateGroup = (data) => {
    dispatch(createInventoryGroupsThunk({...data, slug: data?.name?.toLowerCase()?.split(" ").join("-")})).then((res) => {
      if(res.meta?.requestStatus === "fulfilled"){
        toast.success("Inventory group was successfully created")
        handleCloseModal()
        return dispatch(getInventoryGroupsThunk())
      } else {
        toast.error(res?.payload?.message);
      }
    })
  }

  const handleToggleOrder = () => {
    if(order === "asc"){
      setOrder("desc")
    } else {
      setOrder("asc")
    }
  }

  const handleDataRendering = () => {
    
    let data =  []
    if(order === "asc"){
      data = _.orderBy(inventoryGroups, (x) => x?.name?.toLowerCase(), 'asc')
    } else {
      data = _.sortBy(inventoryGroups, (x) => x?.name?.toLowerCase(),'desc').reverse()
    }
    
    return data
  }

  return (
    <section>
      {open && (<CreateGroupModal loading={isLoading} onSubmit={handleCreateGroup} handleClose={handleCloseModal} open={open} />)}
      <AppLayout
        custom="inventory"
        subCustom="inventory-items"
        title="Inventory Items"
        customTitle=" "
        subtitle=""
      >

        <div className="flex items-center gap-3">
          <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer dark:text-white" />
          <p className="font-medium text-base text-5F3 my-6">
          <Link to="/inventory-items">INVENTORY ITEMS</Link> <span className="text-A5A"> / INVENTORY-GROUPS </span>
          </p>
        </div>

        <div className="flex items-center justify-end">
          <button onClick={handleOpenModal} className="bg-btn-color text-white px-5 py-[10px] rounded font-semibold text-base">Create group</button>
        </div>

        <TableWrapper className="mt-6 max-h-[70vh] mb-12">
          <table>
              <TableHeader >
                <Td className="py-4">
                  <div className='flex items-center justify-start gap-2'>

                    <p>Name of group</p> 
                    <p onClick={handleToggleOrder} className="cursor-pointer">
                      {order === "asc" ? <BsArrowDown /> : <BsArrowUp />}
                      </p>
                  </div>
                  </Td>
                <Td className="text-center">Number of items</Td>
                <Td>Date created</Td>
                <Td className="text-center">Action</Td>
              </TableHeader>
            <tbody>
              {
                handleDataRendering()?.map((x) => (
                  <Tr className='hover:bg-5F5 hover:dark:bg-292'
                    onClick={()=> navigate(`/inventory-items-group/${x.id}`)}
                    style={{fontWeight: 700}}>

                    <Td className='py-4'>{x?.name}</Td>
                    <Td className="text-center">{x?.item || 0}</Td>
                    <Td>{moment(x?.createdOn).format("MM/DD/yyyy")}</Td>
                    <Td className="text-center"><MoreHoriz /></Td>

                  </Tr>

                ))
              }
              {
                inventoryGroups?.length === 0 && (<tr><td className='text-center pt-4 text-A5A' colSpan={4}>No groups available</td></tr>)
              }
            </tbody>
          </table>
        </TableWrapper>
      </AppLayout>
     
    </section>
  );
};
