import React, { useEffect, useState } from 'react';
import DateLabel from '../DateLabel/DateLabel';
import EmployeeLabel from '../EmployeeLabel/EmployeeLabel';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import timeRangeTable from '../../../../../utils/timeDataTable';
import checkTimeTable from '../../../../../utils/checkTimeTable';
import checkAmOrPm from '../../../../../utils/checkPmOrAm';
import change24HrsPmTo12hrsPm from '../../../../../utils/change24HrsPmTo12hrsPm';
import { Shift } from '../../../../../Network/ServiceClass/Shift';
import { CircularProgress } from '@material-ui/core';
import EmptyPlaceholder from '../../../../../Assets/boardIcon.png';

import _ from 'lodash';

const shift = new Shift();
const DayView = ({usersOnShift}) => {
  const shiftStateDayNumber = useSelector((state) => state.shift.dayNumber)
  const shiftStateDailyTime = useSelector((state) => state.shift.dailyTime)
  const shiftStateWeeklyDate = useSelector((state) => state.shift.weeklyDate)
  const selectedBranchState = useSelector((state)=>state.shift.selectedBranch)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  useEffect(()=>{
    setIsLoading(true)
    shift.fetchRoaster(selectedBranchState,shiftStateWeeklyDate.to, shiftStateWeeklyDate.from, 1).then((value)=>{
      setData(value.data.data)
      setIsLoading(false)
    }).catch((err)=>{
      console.log(err);
      setIsLoading(false)
    });
  },[])


  const checkTime = _.slice(timeRangeTable, shiftStateDailyTime.from, (shiftStateDailyTime.to+1))
  const time = (checkTime.length === 0)? checkTimeTable : checkTime

  const br={borderBottom: 'none', paddingLeft: 0, paddingRight: 0, maxWidth: '7rem'}
return (
  <div className='h-[70vh] overflow-y-auto'>
  <table >
      <thead className='sticky bg-white top-0'>
        <td className='td-padding'>Employee name</td>
        {/* {console.log(dates)} */}
        {time.map((value,index) => {
          if(index < 7) return(<td className='td-padding'><DateLabel detailsDay={value} type='day'/> </td>)
        })}
        
      </thead>
      {(!isLoading && data !== undefined) && data.map((value)=>{
        // console.log(value)
        // console.log(1)

          return value.shifts.map((dailyUserShift,index) => {
            const startHour = Number(dailyUserShift.slot.startTime.split(':')[0])
            const startMinute = Number(dailyUserShift.slot.startTime.split(':')[1])
            const stopHour = Number(dailyUserShift.slot.endTime.split(':')[0])
            const stopMinute = Number(dailyUserShift.slot.endTime.split(':')[1])

            ///////////////////////////////////////////////////////////
            // this code divide the calender into AM and PM time and maps the data to the correct division
            let indexOfStartCol
            if(checkAmOrPm(startHour) === 'AM'){
              indexOfStartCol = time.findIndex(val => (val.split(' ')[0] == change24HrsPmTo12hrsPm(startHour)) && val.split(' ')[1] === 'AM') + 1
            }else if (checkAmOrPm(startHour) === 'PM'){
              indexOfStartCol = time.findIndex(val => (val.split(' ')[0] == change24HrsPmTo12hrsPm(startHour)) && val.split(' ')[1] === 'PM') + 1
            }
            // end of mapping to correct division
            //////////////////////////////////////////////////////////

            const stopPadding = 0 
            const startPadding = 0
            const stop = ((stopHour - startHour) && (stopPadding !== 0)) > 0 ? (stopHour - startHour) + 1 : (stopHour - startHour)
            const timeAdjust = {paddingLeft: startPadding,paddingRight:  stopPadding}
            if((dailyUserShift.slot.dayOfWeek === shiftStateDayNumber) || (dailyUserShift.slot.dayOfWeek === 0 && shiftStateDayNumber === 7)){
              // display[indexOfStartCol] = {indexOfStartCol:indexOfStartCol,stop,timeAdjust,user:dailyUserShift.slot}
              return (<tr>
                        <td className='td-padding'><EmployeeLabel user={value.user} /> </td>
                        <td colspan={(indexOfStartCol === 1)? stop: 1} style={{...br, ...timeAdjust }}>{(indexOfStartCol === 1) && <TimeShiftSpot details={dailyUserShift.slot}/>}</td>
                      
                        <td colspan={(indexOfStartCol === 2)? stop: 1} style={{...br, ...timeAdjust }}>{(indexOfStartCol === 2) && <TimeShiftSpot details={dailyUserShift.slot}/>}</td>
                        <td colspan={(indexOfStartCol === 3)? stop: 1} style={{...br, ...timeAdjust}}>{(indexOfStartCol === 3) && <TimeShiftSpot details={dailyUserShift.slot}/>}</td>
                        <td colspan={(indexOfStartCol === 4)? stop: 1} style={{...br, ...timeAdjust}}> {(indexOfStartCol === 4) && <TimeShiftSpot details={dailyUserShift.slot}/>}</td>
                        <td colspan={(indexOfStartCol === 5)? stop: 1} style={{...br, ...timeAdjust}}>{(indexOfStartCol === 5) && <TimeShiftSpot details={dailyUserShift.slot}/>}</td>
                        <td colspan={(indexOfStartCol === 6)? stop: 1} style={{...br, ...timeAdjust}}>{(indexOfStartCol === 6) && <TimeShiftSpot details={dailyUserShift.slot}/>}</td>
                        <td colspan={(indexOfStartCol === 7)? stop: 1} style={{...br, ...timeAdjust}}>{(indexOfStartCol === 7) && <TimeShiftSpot details={dailyUserShift.slot}/>}</td>
                    </tr>)
          }
          } )
            
          
        }
        
        
        ) }

      
  </table>
  {isLoading && <div className='w-[100%] flex justify-center mt-10'><CircularProgress/></div> }
  </div>
)
}

export default DayView;

const TimeShiftSpot = (details) => {
  // console.log(details.details)
  const detail = details.details;
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: '#FFFBED',
        padding: '0.5rem 1rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {detail !== undefined && (
        <Typography sx={{ fontSize: '14px', color: '#554201' }}>
          {detail.startTime}-{detail.endTime}
        </Typography>
      )}
      {detail !== undefined && (
        <Typography sx={{ fontSize: '14px', color: '#554201' }}>
          {detail.jobRole.title}
        </Typography>
      )}
    </Box>
  );
};
