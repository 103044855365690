import { Stack, Typography } from "@mui/material";
import FormModal from "../../../../Widgets/Animation/FormModal";
import QRCode from "react-qr-code";
import { IoClose } from "react-icons/io5";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

function ViewQRCode({ open = false, data, handleClose = () => { } }) {
  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-A06 dark:text-white' direction="column" alignItems="center" sx={style} gap={3}>
        <div className="relative">
        <Typography component='span' className="font-bold text-lg">Scan QR Code</Typography>
        <IoClose onClick={handleClose} size={25} className="absolute top-[-20px] right-[-120px] cursor-pointer" />
        </div>
        <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
          <QRCode
            size={500}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${data?.id}/${data?.companyId}/${Date.now()}`}
          />
        </div>
      </Stack>
    </FormModal>
  )
}

export default ViewQRCode