import React,{useState} from 'react'
import {Popper, Switch} from '@mui/material';
import { motion,} from 'framer-motion';  
import {IoIosNotificationsOutline} from 'react-icons/io'
import { styled } from '@mui/material/styles';
import CalendaNotify from '../../Assets/calenda-notify.png'
import DeleteNotify from '../../Assets/delete-notify.png'
import {IoClose} from 'react-icons/io5'
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {CircularProgress} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationAsync, markNotificationAsReadAsync, updatePushNotificationStatus } from '../../Store/dashboard';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';



const Notification = ({openPopper, setOpenPopper, data, user, handlePushNotification}) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state?.dashboard)?.loading

    const open = Boolean(openPopper);
    const id = open ? 'simple-popper' : undefined;
    const [checked, setChecked] = useState(false)

    const handleSwitch = () => {
        handlePushNotification()
        setChecked(!checked)
    }
    
    const handleClose = () => {
      setOpenPopper(null);
     
      return true
    };

    const handleMarkAllAsRead = () => {
      const ids = data.map((not) => not.id)
      const id = {
        ids
      }
      dispatch(markNotificationAsReadAsync(id)).then((res) => {
        if(res.meta.requestStatus === "fulfilled"){
          dispatch(getNotificationAsync())
        }
      })
    }
  
    const mainAnim ={
      hidden: {opacity: 0},
      vissible: {
        opacity: 1,
        transition: {ease: 'easeIn', duration: 1}
      },
      exit: {
        opacity: 0,
        transition: {ease: 'easeOut', duration: 1}
      }
    }

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 10,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 7,
          borderRadius: 6,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));


  return (
      <Popper
            id={id} 
            open={open} 
            anchorEl={openPopper} 
            onClose={handleClose} 
            placement={'bottom-start'}>
            
            <motion.div 
               variants={mainAnim}
               initial={'hidden'}
               animate={'vissible'}
               exit={'exit'}
              className='w-[357px] min-h-[245px]  py-[20px] mt-[10px] rounded-lg bg-white dark:bg-1F1 shadow-custom z-[1000] '>
                <div className='flex justify-end mr-2'><IoClose className='cursor-pointer dark:text-white' onClick={()=> setOpenPopper(null)}/></div>
                <div className='flex flex-col gap-3 px-[25px]'>

                  <div className='flex justify-between items-center'>
                      <div className='flex flex-col gap-1'>
                          <p className='text-[16px] font-semibold dark:text-white'>Notifications</p>
                          <p className='text-[8px] text-[#A5A5A5] dark:text-white'>Get alerts for notifications</p>
                      </div>
                      <p onClick={handleMarkAllAsRead} className='text-[8px] cursor-pointer text-[#5175F3]'>Mark all as read</p>
                  </div>

                  <div className='flex border-1 px-3 py-2 justify-between items-center rounded-lg'>
                      <div className='flex gap-3 items-center'>
                          <IoIosNotificationsOutline className='dark:text-white'/>
                          <div>
                              <p className='text-[12px] dark:text-white'>Push notifications</p>
                              <p className='text-[8px] text-[#696969] dark:text-white'>Allow notifications</p>
                          </div>

                      </div>

                      {loading ? <CircularProgress size={15} /> : (<AntSwitch checked={user?.pushNotificationStatus} onChange={handleSwitch} inputProps={{ 'aria-label': 'ant design' }} />)}
                  </div>
                </div>


                <div className='max-h-[300px] mt-3 flex flex-col gap-3 overflow-y-auto'>
                  {data.map(val => {
                    return <Notify dispatch={dispatch} data={val}/>
                  })}
                </div>
            </motion.div>

      </Popper>
  )
}

export default Notification


const Notify = ({img=CalendaNotify, data, dispatch}) => {
  const navigate = useNavigate()
  const date = new Date(data.createdOn).toDateString().split(' ').slice(0,4).join(' ')
  const [expand, setExpand] = useState(false)
  const [isLoadingMark, setIsLoadingMark] = useState(false)

  const handleClick = (e) => {
    e.stopPropagation();
    // setIsLoadingMark(true)
    const id = {
      ids: [data.id]
    }

    dispatch(markNotificationAsReadAsync(id)).then((res) => {
      if(res.meta.requestStatus === "fulfilled"){
        dispatch(getNotificationAsync()).then((res) => {
          
          if(data.type === "new_announcement"){
            navigate("/announcement")
            return
          }
          
          
          if(data.type === "shift_reminder"){
            navigate("/schedules")
            return
          }
          
          if(data.type === "shift_cancelled"){
            navigate("/schedules")
            return
          }
          
          if(data.type === "shift_clock_in_late"){
            navigate("/clock-in-clock-out")
            return
          }
          
          if(data.type === "shift_abandoned"){
            navigate("/schedules")
            return
          }
          
          if(data.type === "new_write_up"){
            navigate("/write-ups")
            return
          }
          
          if(data.type === "task_completed"){
            navigate("/tasks")
            return
          }

        })
      }
    })

    // markNotificationAsSeen(id).then(val=>{
    //   setIsLoadingMark(false)
    //   console.log(val)
    // }).catch(err => setIsLoadingMark(false))
  }
  
  return (
    <div onClick={(e)=>{
      handleClick(e)
      setExpand(!expand)
    }}
    className={`flex items-center justify-between cursor-pointer ${expand ? 'mx-[10px]' : 'mx-[25px]' }`}>
        <div className='flex gap-3 items-center'>
            <img src={img} alt="" />
            <div className='flex flex-col gap-1 w-[85%]'>
                <p className='text-[12px] leading-4 dark:text-white'>{data.title}</p>
                <p className='text-[10px] text-[#A5A5A5] dark:text-white'>{date}</p>
            </div>
        </div>
        {!isLoadingMark &&<>{!expand && <div className='cursor-pointer ' onClick={handleClick}><IoClose className='dark:text-white'/></div>}</>}
        {isLoadingMark && <>{!expand &&<p>...</p>}</>}
        {expand && <div className='flex justify-between text-[#A5A5A5] dark:text-white w-[70px]'>
          <HiOutlineArrowNarrowRight/>
          <img src={DeleteNotify} alt="" />
        </div> }
        
    </div>
  )
}
