import React, { useRef } from 'react';
import moment from 'moment';
import { ClickAwayListener } from '@mui/material';
import DateLabel from '../../../Widget/DateLabel/DateLabel';
import { Loader } from '../../../../../Widgets/Loader';
import InitialsGen from '../../../../../Widgets/InitialsGen';
import swapPic from '../../../../../../Assets/swap_notification.png';
import TablePagination from '@mui/material/TablePagination';
import tinycolor from 'tinycolor2';

function NameEntryView({
  branches,
  dates,
  loading,
  loaderLoading,
  handleSchedule,
  onDragOver,
  onDrop,
  isOpenSlotOpen,
  openSlots,
  selectedTemplate,
  toggleAvailableSlot,
  dropdownSlotClass,
  onDrag,
  onDragStart,
  onDragStartReassignment,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  jobRoles,
  handleOpenPopper,
  setOpenPopper,
  handleOpenUserProfile,
  setInputSearchBranch,
  handleToggleDropdown,
  handleOpenScheduleOption,
  getBackgroundColor,
  shiftSchedules,
  handleOpenPopperShiftDetails,
  setOpenPopperShiftDetails,
  userID,
  selectedMultiBranch,
  cellLoading,
  openDropdown,
  setOpenDropdown,
  selectBranch,
  handleCreateShift,
  handleCreateScheduleEntry,
  handleGenerateTags,
  offset,
  rowsPerPage,
  handleRoleTags,
  selectedSchedule,
  memberships,
  handleCancelSchedule,
  handleEnableShiftSwap,
  jobroleList,
  handleSelectedBranch,
  searchInput,
  setInput,
  isBranchhover,
  setIsHover,
  branchSelected
}) {
  const prevState = useRef(null)
  const [userId, setUserId] = React.useState('');
  const [cellIndex, setCell] = React.useState(0);
  // const [searchInput, setInput] = React.useState('');
  // const [isBranchhover, setIsHover] = React.useState(false)

  const handleSelectItem = (e) => {
    const nextdIndex = cellIndex + 1;
    setCell(nextdIndex);
    prevState.current = cellIndex
    const option = branches.find((x) => x.id === e.id);
    selectBranch(option);

    if (
      shiftSchedules?.filter((shiftSchedule) => {
        return (
          moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
            moment(e.date).format('yyyy-MM-DD') &&
          e.schedule?.user?.id === shiftSchedule?.user?.id
        );
      })?.length > 0
    ) {
      const [shiftScheduleFilter] = shiftSchedules?.filter((shiftSchedule) => {
        return (
          moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
            moment(e.date).format('yyyy-MM-DD') &&
          e.schedule?.user?.id === shiftSchedule?.user?.id
        );
      });
      handleCreateScheduleEntry(
        { ...option, shiftId: shiftScheduleFilter.id },
        moment(e.date).format('yyyy-MM-DD'),
        e.schedule?.user,
        undefined,
        false,
      );
    } else {
      handleCreateShift(
        option,
        moment(e.date).format('yyyy-MM-DD'),
        e.schedule?.user,
        undefined,
        false,
        ()=>{},
        false
      );
    }
  };

  const handleSelectedCell = (user, condition) => {
    return user === condition;
  };
  const handleCellRender = (cond = true, tags) => {
    return Array.from(handleSchedule(selectedMultiBranch))
      ?.slice(offset, offset + rowsPerPage)
      .filter((x) => (cond ? x?.user.id !== userID : x?.user.id === userID))
      ?.filter(
        (x) =>
          handleRoleTags(x?.user?.jobRole?.id)?.map(
            (y) => y?.tagInfo?.id
          )[0] === tags.id
      );
  };

  const handleRenderScheduleCell = (schedule, lastBorder = true) => {
    return (
      schedule?.mode === 'data' && (
        <tr>
          <td
            className={`border-l ${
              lastBorder ? `` : 'border-b'
            }  px-2 flex items-center py-3 sticky left-0 bg-white dark:bg-A06 dark:border-333 z-20`}
            onMouseEnter={(event) => {
              let timeoutId = setTimeout(() => {
                handleOpenPopper(event, schedule);
              }, 500);
              event.currentTarget.addEventListener('mouseleave', () => {
                clearTimeout(timeoutId);
              });
            }}
            onMouseLeave={() => setOpenPopper(null)}
          >
            <div className={`p-2 flex items-center bg-white dark:bg-A06 dark:border-333 z-250`}>
            <div
              className="cursor-pointer"
              onClick={() => handleOpenUserProfile(schedule)}
            >
              {schedule?.user?.imageUrl ? (
                <img
                  className="rounded-full h-10 w-10 mr-2"
                  src={schedule?.user?.imageUrl}
                  alt="logo"
                />
              ) : (
                <InitialsGen
                  width={40}
                  name={schedule?.user?.name}
                  bgColor="#00AD57"
                />
              )}
            </div>
            <div>
            <p className="font-bold text-xs text-555 dark:text-white">
            {schedule?.user?.name[0]}.
            {
              schedule?.user?.name?.split(
                ' '
              )[1]
            }
            {schedule?.user?.skills?.filter(
              (x) => x?.skillSetCode !== ''
            )?.length > 0 &&
              schedule?.user?.skills?.map(
                (x) => (
                  <span>
                    {' '}
                    (
                    {x?.skillSetCode?.length > 0
                      ? x?.skillSetCode
                      : x?.name
                          ?.slice(0, 2)
                          ?.toUpperCase()}
                    )
                  </span>
                )
              )}
            </p>
            <div className="flex items-center gap-2">
            <p className="text-[9px] text-999 dark:text-white">
                {schedule?.user?.jobRole?.title}
              </p>
              <div className="">
              <div
                style={{
                  backgroundColor:
                    schedule?.user?.jobRole
                      ?.hexcode !== 'NULL'
                      ? schedule?.user?.jobRole
                          ?.hexcode
                      : 'white',
                }}
                className={`w-[6px] h-[6px] rounded-full bg-[${
                  schedule?.user?.jobRole
                    ?.hexcode !== 'NULL'
                    ? schedule?.user?.jobRole
                        ?.hexcode
                    : 'white'
                }]`}
              />
            </div>
            </div>
            </div>
            </div>
            {/* <div className="absolute right-2 top-4">
              <p className="text-sm font-bold dark:text-white">
                {shiftSchedules?.filter((shiftSchedule) => {
                  return schedule?.user?.id === shiftSchedule?.user?.id;
                })?.length || ''}
              </p>
            </div> */}
          </td>
          {dates?.map((date, index) => (
            <td
              onClick={() => {
                setUserId(schedule?.user?.id);
                setCell(index);
                prevState.current = index;
                setInputSearchBranch('');
                handleToggleDropdown();
                handleOpenScheduleOption(
                  `${moment(date).format('yyyy-MM-DD')}${schedule?.user?.id}`
                );
              }}
              className={`border dark:border-333 relative h-10 p-1 dark:bg-A06 ${
                [0, 6].includes(moment(date).day()) ? 'bg-calendar-weekend' : ''
              } w-[100px]`}
            >
              <div
              draggable
              onDragStart={(e) => onDragStartReassignment(e, date, schedule)}
              onDragOver={(e) => onDragOver(e, date)}
              onDrop={(e) => {
                prevState.current = index;
                onDrop(e,date, schedule?.user)}}
                className={` h-full rounded flex items-center justify-center dark:bg-A06`}
                style={{
                  backgroundColor:
                    (handleSelectedCell(
                      `${schedule?.user?.id}${index}`,
                      `${userId}${cellIndex}`
                    ) && shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })?.length === 0) ||
                    (cellLoading &&
                      handleSelectedCell(
                        `${schedule?.user?.id}${index}`,
                        `${userId}${cellIndex}`
                      ))
                      ? 'white'
                      : getBackgroundColor(schedule, date),
                      color: tinycolor((handleSelectedCell(
                        `${schedule?.user?.id}${index}`,
                        `${userId}${cellIndex}`
                      ) && shiftSchedules?.filter((shiftSchedule) => {
                        return (
                          moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                            moment(date).format('yyyy-MM-DD') &&
                          schedule?.user?.id === shiftSchedule?.user?.id
                        );
                      })?.length === 0) ||
                      (cellLoading &&
                        handleSelectedCell(
                          `${schedule?.user?.id}${index}`,
                          `${userId}${cellIndex}`
                        ))
                        ? 'white'
                        : getBackgroundColor(schedule, date)).isDark() ? "white" : "black"
                }}
              >
                {shiftSchedules?.length > 0 &&
                shiftSchedules?.filter((shiftSchedule) => {
                  return (
                    moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                      moment(date).format('yyyy-MM-DD') &&
                    schedule?.user?.id === shiftSchedule?.user?.id
                  );
                })?.length > 0 ? (
                  <div
                    className="flex gap-2 items-center flex-col"
                    onMouseEnter={(event) => {
                      const [shiftScheduleFilter] = shiftSchedules?.filter(
                        (shiftSchedule) => {
                          return (
                            moment(shiftSchedule?.start).format(
                              'yyyy-MM-DD'
                            ) === moment(date).format('yyyy-MM-DD') &&
                            schedule?.user?.id === shiftSchedule?.user?.id
                          );
                        }
                      );
                      let timeoutId = setTimeout(() => {
                        handleOpenPopperShiftDetails(
                          event,
                          shiftScheduleFilter
                        );
                      }, 500);
                      event.currentTarget.addEventListener('mouseleave', () => {
                        clearTimeout(timeoutId);
                      });
                    }}
                    onMouseLeave={() => setOpenPopperShiftDetails(null)}
                  >
                    <p className="text-[13px] text-center font-bold">
                      {
                        shiftSchedules?.filter((shiftSchedule) => {
                          return (
                            moment(shiftSchedule?.start).format(
                              'yyyy-MM-DD'
                            ) === moment(date).format('yyyy-MM-DD') &&
                            schedule?.user?.id === shiftSchedule?.user?.id
                          );
                        })[0]?.slot?.branch?.name
                      }
                    </p>
                    <p className="text-[9px] text-left font-bold">
                      {moment(
                        shiftSchedules?.filter((shiftSchedule) => {
                          return (
                            moment(shiftSchedule?.start).format(
                              'yyyy-MM-DD'
                            ) === moment(date).format('yyyy-MM-DD') &&
                            schedule?.user?.id === shiftSchedule?.user?.id
                          );
                        })[0]?.start
                      ).format('h:mma')}
                      -
                      {moment(
                        shiftSchedules?.filter((shiftSchedule) => {
                          return (
                            moment(shiftSchedule?.start).format(
                              'yyyy-MM-DD'
                            ) === moment(date).format('yyyy-MM-DD') &&
                            schedule?.user?.id === shiftSchedule?.user?.id
                          );
                        })[0]?.end
                      ).format('h:mma')}
                    </p>
                    {shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })[0].swappable && <img src={swapPic} alt="swap" />}
                  </div>
                ) : (
                  <p className="text-black dark:text-white">
                    {`${schedule?.user?.id}${index}` ===
                      `${userId}${prevState.current}` &&
                    shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })?.length >= 0 &&
                    cellLoading
                      ? 'Loading...'
                      : ''}
                  </p>
                )}

                {`${schedule?.user?.id}${index}` === `${userId}${cellIndex}` &&
                  openDropdown && shiftSchedules?.length > 0 &&
                  shiftSchedules?.filter((shiftSchedule) => {
                    return (
                      moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                        moment(date).format('yyyy-MM-DD') &&
                      schedule?.user?.id === shiftSchedule?.user?.id
                    );
                  })?.length === 0 && ([1, 2].includes(memberships[0]?.privilege)) &&(
                    <div
                      on
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{ zIndex: 200 }}
                      className="relative"
                    >
                      <input
                      onFocus={()=>{
                        setIsHover(false)
                        setInput('')
                      }}
                        onKeyUp={(e) => {
                          handleSelectedBranch(schedule.user)
                          const locationList = branches?.filter((fil) =>
                            fil.name?.toLowerCase()?.startsWith(searchInput)
                          );
                          if (e.key === 'Enter') {
                            setInput('');
                            const nextdIndex = cellIndex + 1;
                            setCell(nextdIndex);

                            if (searchInput?.length > 0) {
                              const firstLocation = locationList[0];
                              handleSelectItem({
                                id: firstLocation.id,
                                date: date,
                                schedule,
                              });
                            }
                          }
                        }}
                        value={searchInput}
                        onChange={(e) => {
                          setInput(e.target.value);
                        }}
                        autoFocus
                        className="rounded-md border border-solid border-red-500 pl-1 w-full h-[45px]"
                      />

                      {!!searchInput && (
                        <div className="absolute z-50 top-full w-full bg-white overflow-y-scroll shadow-select min-h-[50px] h-[100px]">
                          {branches
                            ?.filter((fil) =>
                              fil.name?.toLowerCase()?.startsWith(searchInput)
                            )
                            ?.map((x) => ({
                              id: x.id,
                              label: x.name,
                              name: x.name,
                              city: x.city.name,
                              schedule,
                              date,
                            }))
                            ?.map((bran) => (
                              <div 
                              onClick={()=> {
                                handleSelectItem({
                                  id: bran.id,
                                  date: date,
                                  schedule,
                                });
                              }} className="group cursor-pointer">
                                <div onMouseOver={()=> {
                                  const locationList = branches?.filter((fil) =>
                                    fil.name?.toLowerCase()?.startsWith(searchInput)
                                  );
                                  const firstLocation = locationList[0];
                                  if(!isBranchhover){
                                    setIsHover(true)
                                  }
                                  const option = branches.find((x) => x.id === firstLocation.id);
                                  selectBranch(option);
                                }} className="p-2 flex items-end gap-1 group-hover:bg-444 group-hover:text-white">
                                  <p className="text-xs">{bran?.name}</p>
                                  <span className="text-[8px] text-EBE group-hover:text-white">
                                    {bran?.city}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}

{selectedSchedule ===
                    `${moment(date).format('yyyy-MM-DD')}${
                      schedule?.user?.id
                    }` && isBranchhover &&
                    shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })?.length === 0 &&
                    openDropdown &&
                    jobroleList?.length > 1 && (
                      <div className="absolute bg-white z-50 top-[80%] max-h-[300px] overflow-y-auto right-[100%] w-max shadow-select">
                        {jobroleList?.map((x, dateIndex) => (
                          <p
                            onClick={() => {
                              handleCreateShift(
                                { ...branchSelected, index },
                                moment(date).format('yyyy-MM-DD'),
                                schedule?.user,
                                dateIndex === 0 ? undefined : x.id
                              );
                            }}
                            className="flex group text-black items-center gap-3 px-4 py-2 cursor-pointer hover:bg-787"
                          >
                            {x?.title}{' '}
                            {dateIndex === 0 && (
                              <span className="text-787 text-[10px] font-semibold group-hover:text-white">
                                Primary
                              </span>
                            )}
                          </p>
                        ))}
                      </div>
                    )}

                {`${schedule?.user?.id}${index}` === `${userId}${cellIndex}` &&
                    shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })?.length > 0 &&
                    memberships?.length > 0 &&
                    ([1, 2].includes(memberships[0]?.privilege) ||
                      schedule?.user?.id === memberships[0]?.userId) &&
                    openDropdown && (
                      <div className="bg-white z-50 top-[80%] right-0 absolute drop-shadow-lg w-full">
                        {[1, 2].includes(memberships[0]?.privilege) && (<p
                          onClick={() => {
                            const [shiftScheduleFilter] =
                              shiftSchedules?.filter((shiftSchedule) => {
                                return (
                                  moment(shiftSchedule?.start).format(
                                    'yyyy-MM-DD'
                                  ) === moment(date).format('yyyy-MM-DD') &&
                                  schedule?.user?.id === shiftSchedule?.user?.id
                                );
                              });

                            handleCancelSchedule(shiftScheduleFilter.id);
                          }}
                          className="text-xs mb-3 text-171 hover:text-green-600 p-3 cursor-pointer font-bold"
                        >
                          Cancel shift
                        </p>)}
                        <p
                          onClick={() => {
                            const [shiftScheduleFilter] =
                              shiftSchedules?.filter((shiftSchedule) => {
                                return (
                                  moment(shiftSchedule?.start).format(
                                    'yyyy-MM-DD'
                                  ) === moment(date).format('yyyy-MM-DD') &&
                                  schedule?.user?.id === shiftSchedule?.user?.id
                                );
                              });

                            handleEnableShiftSwap(
                              shiftScheduleFilter.id,
                              shiftScheduleFilter.swappable
                            );
                          }}
                          className="text-xs mb-3 hover:text-green-600 p-3 cursor-pointer font-bold"
                        >
                          {shiftSchedules?.filter((shiftSchedule) => {
                            return (
                              moment(shiftSchedule?.start).format(
                                'yyyy-MM-DD'
                              ) === moment(date).format('yyyy-MM-DD') &&
                              schedule?.user?.id === shiftSchedule?.user?.id
                            );
                          })[0].swappable ? (
                            <span className="text-171">Cancel Swap</span>
                          ) : (
                            <span className='text-black hover:text-green-600 dark:text-white'>Swap</span>
                          )}
                        </p>
                      </div>
                    )}
              </div>
            </td>
          ))}
        </tr>
      )
    );
  };

  return (
    <div>
      <section className="overflow-auto h-[500px] shadow-small-select mb-4 pb-16">
        {
          <table className="w-[4000px] border-collapse">
            <thead className="bg-white dark:bg-A06 z-50">
            <tr>
          <td className={`w-[175px] border-b dark:border-b-0 border-r-0 z-50 dark:bg-A06 dark:border-333 sticky top-0 left-0 bg-white`}></td>
          {
            [...new Set(dates.map((x) => moment(x).format('MMM')))]?.map((y, index) => (<td className={index === 0 ? "sticky left-0 top-0 bg-white z-40 dark:bg-A06" : ""} colSpan={dates.map((dat) => moment(dat).format('MMM')).filter((z) => z === y).length} ><p className="dark:text-white font-extrabold text-[20px] text-[#444]">{y}</p></td>))
          }
          </tr>
            <tr>
            <td className="sticky z-50 left-0 top-4 bg-white dark:bg-A06" colSpan={1}>
            {!!selectedTemplate?.value ? (
                <p
                  onClick={toggleAvailableSlot}
                  className="text-558 pl-2 pt-2 text-sm font-bold cursor-pointer"
                >
                  {isOpenSlotOpen ? 'Close Open shifts' : 'Open Shifts'}
                </p>
              ) : (
                <div />
              )}
            </td>
            <td className="top-4" colSpan={28}>
            {isOpenSlotOpen && (
                <ClickAwayListener
                  onClickAway={() => {
                    openSlots(false);
                    setIsHover(false);
                    setInput('');
                  }}
                >
                  <div className={`relative right-0  top-0 bg-white rounded-md  h-[300px] w-full overflow-y-scroll shadow-shift-shadow z-20`}>
                    <table>
                      <thead>
                      <tr>
                      {
                      dates?.map((dat) => (
                        <th className={`${[0,6].includes(moment(dat).day()) ? "bg-calendar-weekend ":""}`}>
                      <div className={`w-[100px] ${[0,6].includes(moment(dat).day()) ? "bg-calendar-weekend":""}  ${dropdownSlotClass}`}>
                        <p>{moment(dat).format("ddd DD MMM")}</p>
                      </div>
                      </th>
                      ))
                    }
                      </tr>
                      </thead>
                      <tr>
                      {dates
                      ?.map((dat, index) => {
                        const data = [];

                        selectedTemplate?.locations?.forEach((loc) => {
                          const numberOfSchedule = shiftSchedules?.filter(
                            (x) =>
                              x?.branchId === loc.location.id &&
                              moment(x?.start).format('yyyy-MM-DD') === moment(dat).format('yyyy-MM-DD')
                          ).length;
                          const remainingSlots =
                            (loc?.staffRequired || 0) - numberOfSchedule;
                          for (let b = 0; b < remainingSlots; b++) {
                            data.push(loc?.location);
                          }
                        });

                        const daysOfWeek = selectedTemplate?.daysOfWeek?.split(",")?.map((y) => Number(y));

                        return (
                          <td className={[0,6].includes(moment(dat).day()) ? "bg-calendar-weekend":""}>
                          <div
                            id={daysOfWeek[moment(dat).day()]}
                            className={`flex flex-col pl-2 px-2 ${
                              [0, 6].includes(daysOfWeek[moment(dat).day()])
                                ? 'bg-calendar-weekend'
                                : ''
                            }`}
                          >
                            {daysOfWeek[moment(dat).day()] ? (
                              data?.filter((bran) => {
                                if(selectedMultiBranch?.length > 0){
                                  return selectedMultiBranch?.map((ele) => ele?.value).includes(bran?.id)
                                }
                                return true
                              })?.map((y) => (
                                <div
                                  onDrag={onDrag}
                                  draggable="true"
                                  onDragStart={(e) => onDragStart(e, y, moment(dat).format("yyyy-MM-DD"))}
                                  className={`h-[30px] mb-3 cursor-pointer rounded-md flex items-center justify-center text-white font-bold px-2`}
                                  style={{ backgroundColor: y.hexcode }}
                                >
                                  <p className="w-[78px] whitespace-nowrap text-center overflow-hidden text-ellipsis">
                                    {y?.name}
                                  </p>
                                </div>
                              ))
                            ) : (
                              <div />
                            )}
                          </div>
                          </td>
                        );
                      })}
                      </tr>
                    </table>
                    
                  </div>
                </ClickAwayListener>
              )}
            </td>
          </tr>
              <tr>
              <td
                className={`w-[350px] bg-white dark:bg-A06 dark:border-1 sticky left-0 border-b top-8 border-r z-50 dark:border-333`}
              >
                <p className="px-3 w-[170px] dark:text-white">Employee Name</p>
              </td>
              {dates?.map((value, index) => {
                return (
                  <td
                    className={`cursor-pointer border-b sticky z-[40]  top-8 dark:border-t-1 border-r p-4 w-[300px] dark:border-333 dark:bg-A06 ${
                      [0, 6].includes(value.getDay())
                        ? 'bg-calendar-weekend'
                        : 'bg-white'
                    }`}
                  >
                    <DateLabel
                      padding={'10px'}
                      className=""
                      detailsWeek={value}
                      hideMonth
                      type="week"
                      layoutProps={{
                        headerClass: 'dark:text-white',
                        subHeaderClass: 'dark:text-969',
                      }}
                    />
                  </td>
                );
              })}
              </tr>
            </thead>
            {(loading || loaderLoading) && (
              <tr>
                <td colSpan={8}>
                  <div className="w-full h-[300px] flex justify-center items-center">
                    <Loader />
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              !loaderLoading &&
              (handleSchedule(selectedMultiBranch).filter((x) => x?.mode === 'data')
                ?.length === 0 ||
                jobRoles?.length === 0) && (
                <tr>
                  <td className="dark:border-333" colSpan={dates.length}>
                    <div className="w-full h-[300px] flex justify-center items-center dark:text-white">
                      <p>No Members in this branch</p>
                    </div>
                  </td>
                </tr>
              )}

            {!loading &&
              !loaderLoading &&
              handleSchedule(selectedMultiBranch)?.filter(
                (x) =>
                  x?.user.id === JSON.parse(localStorage.getItem('user')).id
              ).length > 0 && (
                <>
                  {Array.from(handleSchedule(selectedMultiBranch))
                    ?.filter(
                      (x) =>
                        x?.user.id ===
                        JSON.parse(localStorage.getItem('user')).id
                    )
                    ?.map((schedule) => (
                      <>{handleRenderScheduleCell(schedule)}</>
                    ))}
                </>
              )}
            {!loading &&
              !loaderLoading && [1, 2].includes(memberships[0]?.privilege) &&
              handleGenerateTags()?.map((tags, tagIndex) => (
                <>
                  {Array.from(handleSchedule(selectedMultiBranch))
                    ?.slice(offset, offset + rowsPerPage)
                    ?.filter(
                      (x) =>
                        handleRoleTags(x?.user?.jobRole?.id)?.map(
                          (y) => y?.tagInfo?.id
                        )[0] === tags.id
                    )?.length > 0 && (
                    <tr>
                      <td
                        className="border p-4 sticky dark:border-333 left-0 dark:bg-A06"
                        colSpan={1}
                      >
                        <p
                          className="font-bold text-[10px]"
                          style={{ color: tags?.hexcode }}
                        >
                          {tags?.name}
                        </p>
                      </td>
                    </tr>
                  )}
                  {handleCellRender(true, tags)
                    ?.map((schedule, index) => (
                      <>
                        {
                        handleRenderScheduleCell(
                          schedule,
                                        index ===
                                          handleCellRender(true, tags)?.length -
                                            1 &&
                                          handleGenerateTags()?.length - 1 !==
                                            tagIndex,
                        )
                        }
                      </>
                    ))}
                </>
              ))}
          </table>
        }
      </section>
      {[1, 2].includes(memberships[0]?.privilege) && (jobRoles?.length > 0 ||
              handleSchedule(selectedMultiBranch)?.length > rowsPerPage) &&
              !loaderLoading &&
              !loading && (
                <div className="flex mb-20 justify-end">
                  <TablePagination
                    className="dark:text-grey-600"
                    component="div"
                    count={handleSchedule(selectedMultiBranch)?.length || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              )}
    </div>
  );
}

export default NameEntryView;
