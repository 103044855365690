import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { askChatGptThunk } from '../../../Store/ChatGpt'
import {AiOutlineSend} from 'react-icons/ai'
import { CustomIconButton } from '../../Widgets/FormUtils/FormUtils'
import { CircularProgress } from '@mui/material'
import AppLayout from '../../Widgets/AppLayout'
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel'
import { aiChatTutorial } from '../mock'


const Gpt = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.chatGpt)
    const [reserveData, setReserveData] = useState(store.chat)
  

    useEffect(()=>{
      setReserveData(store.chat)
    },[store.chat])

    const [text, setText] = useState('')
    const [showSendIcon, setShowSendIcon] = useState(false)

    const handleChange = (event) => {
        setText(event.target.value);
        if (event.target.value.length >= 1) setShowSendIcon(true) 
        else setShowSendIcon(false)
    };

    const handleTextareaResize = (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + 'px';
    };

    const handleAskChatGpt = () => {
        setText('')
        setReserveData([...reserveData, {ans: false, msg:text}])
        window.scrollTo(-20, document.documentElement.scrollHeight);

        dispatch(askChatGptThunk({sentence: text}))
        .then(val => {
        })
    }


  return (
       <AppLayout
        custom="Nitro AI"
        title="Ask Nitro AI"
        customTitle=" "
        subtitle="Powered by Open AI’s ChatGPT"
        RightComponent={<TutorialPanel items={aiChatTutorial} />}
      >
        <div className='flex flex-col gap-2 relative text-[14px] h-[75vh]'>

          {!store.loading &&
          <div className='w-full max-h-[100%] '>
              {store?.chat.map((val, index) => 
                  <div
                  style={{paddingBottom: index == (store.chat.length - 1) ? '300px': ''}}
                    className={`flex flex-col ${index !== 0 ? 'mt-10' : ''} px-8`}>

                  <div className='w-[421px] min-h-[50px] self-end px-8 py-4 bg-[#E6F4F4] leading-8 rounded-lg '>
                    {val.msg}
                  </div>

                  <div className='mt-[20px] w-[421px] min-h-[50px] px-8 py-4 bg-[#FFF4F4] leading-8 rounded-lg '>
                      {val.ans}
                  </div>
              </div>
              )}
          </div>}
          {/* <div>loading</div> */}
          {store.loading && <div className='w-full max-h-[100%] '>
            {reserveData.map((val, index) => 
                <div
                style={{paddingBottom: index == (reserveData.length - 1) ? '300px': ''}}
                  className={`flex flex-col ${index !== 0 ? 'mt-10' : ''} px-8`}>

                <div className='w-[421px] min-h-[50px] self-end px-8 py-4 bg-[#E6F4F4] leading-8 rounded-lg '>
                  {val.msg}
                </div>

                    {val.ans === false ? 
                      <div className='w-[421px] flex justify-center'><CircularProgress className='text-[#9ee6e1]'/></div>
                    :
                    <div className='mt-[20px] w-[421px] min-h-[50px] px-8 py-4 bg-[#FFF4F4] leading-8 rounded-lg '>
                      {val.ans}
                    </div>
                    }
                
            </div>
            )}
        </div>}


          

        </div>
        
        <div className='fixed left-[290px] right-[30px] bottom-16 flex flex-col'>

            <div className='flex items-center bg-[#EFF5F5] dark:bg-292 dark:text-white rounded-lg '>
                <textarea 
                className="max-h-[300px] w-[95%] dark:bg-292 focus:border-transparent focus:outline-none min-h-[65px]  px-5 py-5 bg-[#EFF5F5] rounded-l-lg resize-none"
                value={text}
                onChange={handleChange}
                onInput={handleTextareaResize}
                onKeyDown={(event)=>{
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    if (text.length >= 1) handleAskChatGpt()
                  }
                }}
                placeholder="Ask Nitro AI Something..."
                rows={1}/>

                <div className='w-[30px] h-[30px]'>
                    <CustomIconButton 
                    Icon={AiOutlineSend}  
                    disable={!showSendIcon || store.loading}
                    callBack={handleAskChatGpt}
                    />
                </div>
            
            </div>
        </div>

      </AppLayout>
   
  )
}

export default Gpt