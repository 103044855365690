/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import CustomDropdown from '../../Widgets/CustomDropdown';
import { getDatesArray } from '../../../utils/dateArray';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import DateLabel from '../ShiftScreen/Widget/DateLabel/DateLabel';
import getDates from '../../../utils/rangeOfData';
import {
  getBranchesAsync,
  fetchJobRolesAsync,
  getTeammemberAsync,
  getSkillSetAsync,
  getCombineSchedules
} from '../../../Store/Branches';
import {
  cancelScheduleNoLoadingAsync,
  createAssigneeShiftslotNoLoadingAsync,
  getShiftScheduleNoLoadingAsync,
  setWeeklyDateLabel,
  getShiftTemplateAsync
} from '../../../Store/Shift';
import { toast } from 'react-toastify';
import getDay from 'date-fns/getDay';
import { CiSearch } from 'react-icons/ci';
import { setCellBackground } from '../../../utils/util';
import AppLayout from '../../Widgets/AppLayout';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { combinedSchehduleTutorial } from '../mock';
import CombineScheduleModal from './components/CombineScheduleModal';
import CustomNavigateCalender from '../ShiftScreen/Widget/CustomNavigateCalendar/CustomNavigateCalendar';
import MultipleSelectDropdown from '../../Widgets/MultiDropdown';
import CombineScheduleRotationView from './components/CombineScheduleRotationView';
import LinearProgress  from '@mui/material/LinearProgress';

export const CombinedSchedules = () => {
  const [toggleState, toggleDropdown] = useState(false);
  const [openModal, toggleModal] = useState(false);
  const [searchedMember, searchTeam] = useState('');
  const dispatch = useDispatch();
  const dateCreated = useSelector(
    (state) => state.company.activeCompany.createdOn
  );

  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );
  const privilege =
    useSelector((state) => state?.company)?.privilege || 3;  


  const shiftTemplates =
    useSelector((state) => state?.shift)?.shiftTemplates || [];

  const shiftSchedules = useSelector((state) => state?.shift)?.schedules;
  const cellLoading = useSelector((state) => state?.shift)?.cellLoading;
  const company = useSelector((state) => state?.company)?.activeCompany;
  const rotationNumber = useSelector((state) => state?.company)?.rotationNumber;

  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];
  const jobRoles = useSelector((state) => state?.branches)?.jobRoles;
  const skillSets =
  useSelector((state) => state?.branches)?.skillSets?.data || [];
  const branchLoading = useSelector((state) => state?.branches)?.loading;
  const combinedSchedules = useSelector((state) => state?.branches)?.combineSchedules;

  const [selectedJob, selectJob] = useState('');
  const [selectedDate, selectDate] = useState('');
  const [selectedBranch, selectBranch] = useState('');

  let scheduleFilter =
    localStorage.getItem('@schedulefilter') &&
    JSON.parse(localStorage.getItem('@schedulefilter'));

  const [yearSelected, setYearSelected] = useState({
    label: moment().format('yyyy'),
    value: moment().format('yyyy'),
  });

  const [selectedUser, selectUser] = useState({
    label: 'Everyone',
    value: '',
  });

  const [selectedSkill, selectSkill] = useState({
    label: 'All Skill Sets',
    value: '',
  });

  const [selectedGender, selectGender] = useState({
    label: 'No Gender Check',
    value: '',
  });

  const [selectedTemplate, selectTemplate] = useState({
    label: 'Default Shift Template',
    value: '',
  });

  const [selectedJobRole, setJobRole] = useState({
    label: 'All job roles',
    value: '',
  });

  const [selectedRoleTag, setRoleTag] = useState({
    label: 'All role tags',
    value: '',
  });

  const [displayMode, setDisplayMode] = useState({
    value: 'date-picker',
    label: 'Date picker',
  });


  const [selectedROT, setROT] = useState({
    label: 'ROT 1',
    value: 1,
  });

  const [selectedMultiBranch, setMultiBranch] = useState([]);

  const [userList, showList] = useState(false);

  const teamMembers = useSelector((state) => state?.branches)?.teamMembers;

  const [openTeamList, setToggleTeamList] = useState({});

  const memberships = useSelector((state) => state?.company)?.activeCompany
    ?.memberships;

  const handleGetShiftSchedule = () => {
    if (shiftStateWeeklyDateLabel.from) {
      dispatch(
        getShiftScheduleNoLoadingAsync({
          from: moment(shiftStateWeeklyDateLabel.from)
            .add(0, 'minutes')
            .add(0, 'hours')
            .format('yyyy-MM-DDTHH:mm'),
          to: moment(shiftStateWeeklyDateLabel.to)
            .add(59, 'minutes')
            .add(23, 'hours')
            .format('yyyy-MM-DDTHH:mm'),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(fetchJobRolesAsync());
    dispatch(getTeammemberAsync({}));
    dispatch(getSkillSetAsync());
    dispatch(getShiftTemplateAsync());
    dispatch(getCombineSchedules())

    if (scheduleFilter) {
      const existingLocation = JSON.parse(
        localStorage.getItem('@schedulefilter')
      );

      setMultiBranch(existingLocation?.assignLocation);
      setJobRole({ ...selectedJobRole, ...existingLocation?.jobRole });
      setRoleTag({ ...selectedRoleTag, ...existingLocation?.roleTag });
      selectTemplate({
        ...selectedTemplate,
        ...existingLocation?.shiftTemplate,
      });
    }
  }, []);

  useEffect(() => {
    if (displayMode.value === 'date-picker') {
      handleGetShiftSchedule();
    }
  }, [shiftStateWeeklyDateLabel.from, shiftStateWeeklyDateLabel.to, displayMode.value]);

  useEffect(()=>{
    if (displayMode.value === 'rotation-view') {
      handleOnChangeROT(selectedROT);
    }
  },[
    displayMode.value,
  ])

  const dates =
    (shiftStateWeeklyDateLabel?.from &&
      shiftStateWeeklyDateLabel?.to &&
      getDates(
        shiftStateWeeklyDateLabel?.from,
        shiftStateWeeklyDateLabel?.to
      )) ||
    [];

  const handleOnDateChange = (data) => {
    setYearSelected(data);
  };

  const handleExtractSchedule = (date, jobId, branchId) => {
    console.log('loading')
    
    const newSchedules = shiftSchedules;
    return shiftSchedules
      ?.filter(
        (x) =>
          moment(x.start).format('yyyy-MM-DD') ===
            moment(date).format('yyyy-MM-DD') &&
          (x?.slotjobRole?.id === jobId || x?.jobrole?.id === jobId) &&
          x?.branchId === branchId
      )
      ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y }));
  };

  const renderBranch = useCallback(() => {
    const newBranches = branches?.filter(x => {
      if(selectedMultiBranch?.length > 0){
        return selectedMultiBranch?.map((y) => y.value).includes(x.id)
      }
      return true
    })
    return newBranches
  }, [selectedMultiBranch?.length ]);

  const handleCreateShift = (e, date, team, job) => {
    // let selectedTimeData = '09:00-17:00';
    toggleDropdown(false);
    let startTime = '09:00';
    let endTime = '17:00';
    let jobId = job.id;
    let maxStaffRequired = 1;
    const assignees = [
      {
        userId: team.user.id,
        userName: `${team.user.firstName} ${team.user.lastName}`,
      },
    ];

    // if (selectedTemplate.value !== '') {
    //   const [filteredData] = shiftTemplates?.filter(
    //     (x) => x.id === selectedTemplate.value
    //   );
    //   startTime = filteredData?.startTime;
    //   endTime = filteredData?.endTime;
    //   jobId = filteredData?.jobRole?.id;
    //   maxStaffRequired = filteredData?.maxStaffRequired;
    // }

    var start = moment.utc(startTime, 'HH:mm');
    var end = moment.utc(endTime, 'HH:mm');

    const shiftTemp = shiftTemplates?.find(
      (x) => x.id === selectedTemplate?.value
    );

    const data = {
      branchId: e?.id,
      jobRoleId: shiftTemp?.jobRole?.id || jobId,
      assignees,
      dayOfWeek: (date && getDay(moment(date).toDate())) || 0,
      startTime: shiftTemp?.startTime || startTime,
      bookDate: date && moment(date).format('yyyy-MM-DD'),
      hours: shiftTemp?.hours || end.diff(start, 'hours'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      maxStaffRequired: shiftTemp?.maxStaffRequired || maxStaffRequired,
    };

    dispatch(createAssigneeShiftslotNoLoadingAsync(data)).then((res) => {
      if (![200, 201].includes(res?.payload?.status)) {
        toast.error(
          res?.payload?.response?.data?.message || res?.payload?.data?.message
        );
        return;
      }

      if (res?.payload?.data?.slotBookings?.err?.length > 0) {
        toast.error(res?.payload?.data?.slotBookings?.err[0]?.reason);
        return;
      }

      handleGetShiftSchedule();
      showList(false);
      toast.success('Your shift has been created successfully');
    });
  };

  const handleShowUser = () => {
    showList((prev) => !prev);
  };

  const handleCancelShift = (e) => {
    const admins = memberships
      .filter((x) => [1, 2].includes(x.privilege))
      ?.map((x) => x.userId);
    dispatch(
      cancelScheduleNoLoadingAsync({ id: e.id, privileges: admins })
    ).then((res) => {
      if (res?.payload?.status === 404) {
        toast.error(res?.payload?.response?.data?.message);
        return;
      }
      if (res?.meta?.requestStatus === 'fulfilled') {
        handleGetShiftSchedule(false);
        toast.success('Your shift has been cancelled successfully');
      }
    });
  };

  const handleOpenTeamList = (date, role, branch) => {
    setToggleTeamList({ date, jobId: role?.id, branchId: branch?.id });
    toggleDropdown((prev) => !prev);
  };

  const handleSelectUser = (e) => {
    selectUser(e);
  };

  const handleSelectSkill = (e) => {
    selectSkill(e);
  };

  const handleSelectGender = (e) => {
    selectGender(e);
  };

  // const handleFilterSelection = useCallback(
  //   (date, id, branchId) => {
  //     console.log("filter")
  //     if (
  //       selectedUser.value === '' &&
  //       selectedSkill.value === '' &&
  //       selectedGender.value === ''
  //     ) {
  //       return null;
  //     }

  //     const extractSchedule = handleExtractSchedule(date, id, branchId);

  //     return setCellBackground(
  //       extractSchedule,
  //       selectedUser,
  //       selectedSkill,
  //       selectedGender
  //     );
  //   },
  //   [selectedUser.value, selectedGender.value, selectedSkill.value]
  // );

  const handleSelectedShiftTemplate = (e) => {
    selectTemplate(e);
  };

  const handleMultiSelect = (val) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        assignLocation: val,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem(
        '@schedulefilter',
        JSON.stringify({ assignLocation: val })
      );
    }
    setMultiBranch(val);
  };

  const handleJobRole = (e) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        jobRole: e,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem('@schedulefilter', JSON.stringify({ jobRole: e }));
    }
    setJobRole(e);
  };
  const handleViewDisplay = (e) => {
    setDisplayMode(e);
  };


  const handleOnChangeROT = (e) => {
    setROT(e);
    const rotationStart = company?.rotationStart;
    const startDays = 1 + company?.rotationDays * (e.value - 1);
    const endDays = startDays + (company?.rotationDays - 1);
    dispatch(
      setWeeklyDateLabel({
        from: moment(rotationStart).add(startDays, 'days').format('yyyy-MM-DD'),
        to: moment(rotationStart).add(endDays, 'days').format('yyyy-MM-DD'),
      })
    );

    if (shiftStateWeeklyDateLabel.from) {
      dispatch(
        getShiftScheduleNoLoadingAsync({
          rotationNumber: selectedROT.value,
          from: moment(rotationStart)
            .add(startDays, 'days')
            .format('yyyy-MM-DD'),
          to: moment(rotationStart).add(endDays, 'days').format('yyyy-MM-DD'),
        })
      );
    }
  };


  return (
    <section className="bg-white">
      <AppLayout
        custom="shift"
        subCustom="combined-schedule"
        title="Combined Schedules"
        customTitle=" "
        subtitle="View Combined Schedules For Users"
        RightComponent={<TutorialPanel items={combinedSchehduleTutorial} />}
      >
        <div className="flex flex-row flex-wrap sticky top-[4.35rem] z-[100] items-center bg-[white] mt-16 gap-2 dark:bg-A06">
          <MultipleSelectDropdown
            data={branches?.map((branch) => ({
              label: branch?.name,
              value: branch.id,
              hexcode: branch?.hexcode,
            }))}
            placeholder="Select locations"
            className="w-44 h-[43px] dark:border-333"
            value={selectedMultiBranch}
            dropdownTitleClass="text-A5A"
            labelText="locations selected"
            optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
            setMultiBranch={handleMultiSelect}
          />
          <CustomDropdown
            data={[
              { label: 'Everyone', value: '' },
              ...teamMembers?.map((team) => ({
                label: `${team?.user?.firstName} ${team?.user?.lastName}`,
                value: team?.user?.id,
              })),
            ]}
            showIcon={false}
            className="w-[200px] rounded h-[43px]"
            value={selectedUser}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] h-[200px] overflow-scroll"
            onChangeOption={handleSelectUser}
          />
          <CustomDropdown
            data={[
              { label: 'All Skill Sets', value: '' },
              ...skillSets?.map((skill) => ({
                label: skill?.name,
                value: skill?.id,
              })),
            ]}
            showIcon={false}
            className="w-[200px] rounded h-[43px]"
            value={selectedSkill}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] h-[200px] overflow-scroll"
            onChangeOption={handleSelectSkill}
          />
          <CustomDropdown
            data={[
              { label: 'No Gender Check', value: '' },
              { label: 'Female Booked', value: 'male' },
              { label: 'Male Booked', value: 'female' },
            ]}
            showIcon={false}
            className="w-[200px] rounded h-[43px]"
            value={selectedGender}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] overflow-scroll"
            onChangeOption={handleSelectGender}
          />
          {['date-picker'].includes(displayMode.value) && (<CustomNavigateCalender
            dates={dates}
            layoutProps={{
              headerClass: 'dark:text-white',
              subHeaderClass: 'dark:text-969',
            }}
            sx={{ fontWeight: '700', fontSize: '13px' }}
            diff={7}
          />)}
          <CustomDropdown
            data={getDatesArray(dateCreated, new Date().toString())?.map(
              (dates) => ({ label: dates, value: dates })
            )}
            showIcon={false}
            className="w-28 rounded h-[43px]"
            value={yearSelected}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000]"
            onChangeOption={handleOnDateChange}
          />
          <CustomDropdown
            data={[
              { templateName: 'Default Shift Template', id: '' },
              ...(shiftTemplates?.length > 0 ? shiftTemplates : []),
            ]?.map((shift) => ({
              label: shift?.templateName,
              value: shift?.id,
              smallLabel: shift?.jobRole?.title,
            }))}
            className=" w-52 rounded h-[43px]"
            smallLabelClassName="text-gray-400"
            value={selectedTemplate}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
            onChangeOption={handleSelectedShiftTemplate}
          />

          <CustomDropdown
            data={[{ title: 'All job roles', value: '' }, ...jobRoles]?.map(
              (job) => ({ label: job?.title, value: job.id })
            )}
            className="w-[150px] h-[43px] dark:border-333"
            value={selectedJobRole}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] shadow-md min-h-[50px] w-[max-content]"
            onChangeOption={handleJobRole}
          />
          <CustomDropdown
            data={['Date picker', 'Rotation view'].map((mode) => ({
              label: mode,
              value: mode.toLowerCase().split(' ').join('-')
            }))}
            className="w-[150px] h-[43px] dark:border-333"
            value={displayMode}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] shadow-md min-h-[50px] w-[max-content]"
            onChangeOption={handleViewDisplay}
          />

          {
            displayMode.value === 'rotation-view' && (
              <CustomDropdown
                data={Array.from(Array(rotationNumber || 1).keys())?.map(
                  (x) => ({ value: x + 1, label: `ROT ${x + 1}`})
                )}
                className="w-28 rounded h-[43px] dark:border-333"
                value={selectedROT}
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000]"
                onChangeOption={handleOnChangeROT}
              />
            )}
        </div>
        {(branchLoading) && (<LinearProgress className="my-2 mt-16" />)}
        { displayMode.value === 'date-picker' && (<section className={` ${branchLoading ? "": "mt-16"} mb-48 shadow-shift-shadow rounded-sm bg-white dark:bg-A06`}>
          <table>
            <thead className="bg-white sticky top-[9.5rem] z-[49] dark:bg-1F1">
              <tr>
                <td className={`border-b border-r-0 h-[30px] dark:bg-A06 dark:border-333  bg-white`}> <p className="px-3 w-[170px]"></p></td>
                {
                  [...new Set(dates.map((x) => moment(x).format('MMM')))]?.map((y, index) => (<td colSpan={dates.map((dat) => moment(dat).format('MMM')).filter((z) => z === y).length} >{y}</td>))
                }
              </tr>
              <tr>
              <td
                className={`w-[200px] border border-t-0 p-4 border-l-0 z-50 rounded-t-sm dark:border-333`}
              >
                <p className="px-3 w-[170px]"></p>
              </td>
              {dates?.map((value, index) => {
                if (index > 7) return null;

                return (
                  <td
                    className={`cursor-pointer border border-t-0 w-[100px] p-4 dark:!border-333 ${
                      [0, 6].includes(value?.getDay())
                        ? 'bg-calendar-weekend dark:bg-A06'
                        : ''
                    }`}
                  >
                    <DateLabel
                      padding={'10px'}
                      detailsWeek={value}
                      type="week"
                      hideMonth
                      layoutProps={{
                        headerClass: 'dark:text-white',
                        subHeaderClass: 'dark:text-969',
                      }}
                    />
                  </td>
                );
              })}
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(combinedSchedules)?.filter((x) => {
                  if(selectedMultiBranch?.length > 0){
                    return selectedMultiBranch?.map((y) => y?.value).includes(x)
                  }
                  return true
                })?.map((branchId) => (<>
                <tr>
                <td
                  className="border border-x-0 pl-5 dark:border-333"
                  colSpan={8}
                >
                  <p className="font-semibold text-xl text-black my-4 dark:text-white">
                    {combinedSchedules[branchId]?.name}
                  </p>
                </td>
                </tr>
                {
                  combinedSchedules[branchId]?.roles?.filter((x) => x.id.includes(selectedJobRole.value || ''))?.map((role) => <>
                  <tr>
                        <td className="border border-l-0 relative dark:border-333">
                          <p className="font-semibold text-sm text-black my-3 pl-10 dark:text-white">
                            {role?.title}
                          </p>
                          <div
                            style={{
                              backgroundColor:
                                role?.hexcode !== 'NULL'
                                  ? role?.hexcode
                                  : 'white',
                            }}
                            className={`w-2 h-2 rounded-full bg-[${
                              role?.hexcode !== 'NULL' ? role?.hexcode : 'white'
                            }] absolute bottom-1 right-1`}
                          />
                        </td>
                        {Array.from(Array(7).keys()).map((date) => (
                          <td
                            className={`border dark:border-333 ${
                              [5, 6].includes(date)
                                ? 'bg-calendar-weekend dark:!bg-1F1'
                                : ''
                            } ${date === 6 ? 'border-r-0' : ''}
                          ${
                            moment(dates[date]).format('yyyy-MM-DD') ===
                            moment(new Date()).format('yyyy-MM-DD')
                              ? 'bg-[#E2FFF2] dark:bg-A06'
                              : ''
                          }
                          ${
                            (selectedUser.value !== '' &&
                            selectedSkill.value !== '' &&
                            selectedGender.value !== '' ) &&
                            setCellBackground(
                              shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(dates[date]).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                    x?.branchId === branchId
                                )
                                ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y })),
                              selectedUser,
                              selectedSkill,
                              selectedGender
                            )
                              ? ' bg-green-200'
                              : ''
                          }
                          `}
                            onDoubleClick={() => {
                              if([1,2].includes(privilege)){
                                toggleModal(true);
                                searchTeam('');
                                selectJob(role);
                                selectBranch(branchId);
                                selectDate(dates[date]);
                              }
                            }}
                            onClick={() => {
                              if([1,2].includes(privilege)){
                                searchTeam('');
                                handleOpenTeamList(
                                  moment(dates[date]).format('yyyy-MM-DD'),
                                  role,
                                  {...combinedSchedules[branchId]}
                                );
                              }
                            }}
                          >
                            <div className="relative">
                              <p
                                className={`text-sm font-semibold text-black whitespace-nowrap w-[100px] overflow-hidden text-ellipsis px-2 dark:text-white`}
                              >
                                {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(dates[date]).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                    x?.branchId === branchId
                                )
                                ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y }))
                                  ?.map((x) => x?.name)
                                  ?.join(' / ')}
                              </p>

                              {openTeamList.date ===
                                moment(dates[date]).format('yyyy-MM-DD') &&
                                openTeamList?.jobId === role?.id &&
                                openTeamList?.branchId === branchId &&
                                cellLoading && (
                                  <p className="px-2">Loading....</p>
                                )}

                              {openTeamList.date ===
                                moment(dates[date]).format('yyyy-MM-DD') &&
                                openTeamList?.jobId === role?.id &&
                                openTeamList?.branchId === branchId &&
                                toggleState && (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    className="absolute px-4 pt-4 top-full bg-white dark:bg-292 right-0 shadow-select w-max max-h-60 overflow-y-scroll z-[1000]"
                                  >
                                    <div className="border-1 dark:border-none flex flex-row gap-1 items-center sticky top-0 dark:text-white bg-white dark:bg-A06 py-[10px] px-[5px] rounded-md">
                                      <CiSearch />
                                      <input
                                        type="text"
                                        className=" outline-none dark:bg-A06"
                                        value={searchedMember}
                                        placeholder="search"
                                        onFocus={(e) => e.stopPropagation()}
                                        onChange={(e) =>
                                          searchTeam(e.target.value)
                                        }
                                      />
                                    </div>

                                    {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(dates[date]).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                    x?.branchId === branchId
                                ).length > 0 && (
                                      <div className="sticky top-[46px] min-h-[40px] bg-white">
                                        <div className=" border-b-1 my-2 pb-4">
                                          {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(dates[date]).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === role?.id || x?.jobrole?.id === role?.id) &&
                                    x?.branchId === branchId
                                )?.filter((user) =>{
                                  const team = teamMembers.find((v) => v?.user.id === user.user.id);
                                  return team?.assignedBranch.id === branchId
                                })?.map((x) => (
                                            <p className="text-sm">
                                              {x?.user.firstName}{' '}
                                              {x?.user?.lastName}
                                            </p>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                    {teamMembers
                                      ?.filter(
                                        (team) =>
                                          team?.jobRole?.id === role?.id ||
                                          team?.secondaryJobroles?.some(
                                            (x) => x?.jobroleId === role?.id
                                          )
                                      )
                                      .filter(
                                        (x) =>
                                          x?.user?.firstName
                                            .toLowerCase()
                                            .includes(
                                              searchedMember.toLowerCase()
                                            ) ||
                                          x?.user?.lastName
                                            .toLowerCase()
                                            .includes(
                                              searchedMember.toLowerCase()
                                            )
                                      )
                                      .filter(
                                        (z) =>
                                          !shiftSchedules
                                          ?.filter(
                                            (x) =>
                                              moment(x.start).format('yyyy-MM-DD') ===
                                                moment(date).format('yyyy-MM-DD') &&
                                              (x?.slotjobRole?.id === role.id || x?.jobrole?.id === role.id) &&
                                              x?.branchId === branchId
                                          )
                                          ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y }))
                                            .map(
                                              (shiftUser) => shiftUser.userId
                                            )
                                            .includes(z.user.id)
                                      )
                                      .map((team) => (
                                        <p
                                          onClick={() =>
                                            handleCreateShift(
                                              { id: branchId },
                                              moment(dates[date]).format(
                                                'yyyy-MM-DD'
                                              ),
                                              team,
                                              role
                                            )
                                          }
                                          className="py-2 text-sm cursor-pointer hover:text-green-600 dark:hover:bg-color-333 dark:text-white"
                                        >
                                          {team?.user?.firstName}{' '}
                                          {team?.user?.lastName}
                                        </p>
                                      ))}
                                  </div>
                                )}
                            </div>
                          </td>
                        ))}
                      </tr>
                  </>)
                }
                </>))
              }
              {/* {renderBranch()?.map((branch) => (
                <>
                  <td
                    className="border border-x-0 pl-5 dark:border-333"
                    colSpan={8}
                  >
                    <p className="font-semibold text-xl text-black my-4 dark:text-white">
                      {branch?.name}
                    </p>
                  </td>
                  {jobRoles
                    ?.filter((x) => x.id.includes(selectedJobRole.value || ''))
                    ?.map((job) => (
                      <tr>
                        <td className="border border-l-0 relative dark:border-333">
                          <p className="font-semibold text-sm text-black my-3 pl-10 dark:text-white">
                            {job?.title}
                          </p>
                          <div
                            style={{
                              backgroundColor:
                                job?.hexcode !== 'NULL'
                                  ? job?.hexcode
                                  : 'white',
                            }}
                            className={`w-2 h-2 rounded-full bg-[${
                              job?.hexcode !== 'NULL' ? job?.hexcode : 'white'
                            }] absolute bottom-1 right-1`}
                          />
                        </td>
                        {Array.from(Array(7).keys()).map((date) => (
                          <td
                            className={`border dark:border-333 ${
                              [5, 6].includes(date)
                                ? 'bg-calendar-weekend dark:!bg-1F1'
                                : ''
                            } ${date === 6 ? 'border-r-0' : ''}
                          ${
                            moment(dates[date]).format('yyyy-MM-DD') ===
                            moment(new Date()).format('yyyy-MM-DD')
                              ? 'bg-[#E2FFF2] dark:bg-A06'
                              : ''
                          }
                          ${
                            handleFilterSelection(
                              moment(dates[date]),
                              job?.id,
                              branch?.id
                            )
                              ? ' bg-green-200'
                              : ''
                          }
                          `}
                            onDoubleClick={() => {
                              toggleModal(true);
                              searchTeam('');
                              selectJob(job);
                              selectBranch(branch.id);
                              selectDate(dates[date]);
                            }}
                            onClick={() => {
                              searchTeam('');
                              handleOpenTeamList(
                                moment(dates[date]).format('yyyy-MM-DD'),
                                job,
                                branch
                              );
                            }}
                          >
                            <div className="relative">
                              <p
                                className={`text-sm font-semibold text-black whitespace-nowrap w-[100px] overflow-hidden text-ellipsis px-2 dark:text-white`}
                              >
                                {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(dates[date]).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === job?.id || x?.jobrole?.id === job?.id) &&
                                    x?.branchId === branch?.id
                                )
                                ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y }))
                                  ?.map((x) => x?.name)
                                  ?.join(' / ')}
                              </p>

                              {openTeamList.date ===
                                moment(dates[date]).format('yyyy-MM-DD') &&
                                openTeamList?.jobId === job?.id &&
                                openTeamList?.branchId === branch?.id &&
                                cellLoading && (
                                  <p className="px-2">Loading....</p>
                                )}

                              {openTeamList.date ===
                                moment(dates[date]).format('yyyy-MM-DD') &&
                                openTeamList?.jobId === job?.id &&
                                openTeamList?.branchId === branch?.id &&
                                toggleState && (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    className="absolute px-4 pt-4 top-full bg-white dark:bg-292 right-0 shadow-select w-max max-h-60 overflow-y-scroll z-[1000]"
                                  >
                                    <div className="border-1 dark:border-none flex flex-row gap-1 items-center sticky top-0 dark:text-white bg-white dark:bg-A06 py-[10px] px-[5px] rounded-md">
                                      <CiSearch />
                                      <input
                                        type="text"
                                        className=" outline-none dark:bg-A06"
                                        value={searchedMember}
                                        placeholder="search"
                                        onFocus={(e) => e.stopPropagation()}
                                        onChange={(e) =>
                                          searchTeam(e.target.value)
                                        }
                                      />
                                    </div>

                                    {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(dates[date]).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === job?.id || x?.jobrole?.id === job?.id) &&
                                    x?.branchId === branch?.id
                                ).length > 0 && (
                                      <div className="sticky top-[46px] min-h-[40px] bg-white">
                                        <div className=" border-b-1 my-2 pb-4">
                                          {shiftSchedules
                                ?.filter(
                                  (x) =>
                                    moment(x.start).format('yyyy-MM-DD') ===
                                    moment(dates[date]).format('yyyy-MM-DD') &&
                                    (x?.slotjobRole?.id === job?.id || x?.jobrole?.id === job?.id) &&
                                    x?.branchId === branch?.id
                                )?.map((x) => (
                                            <p className="text-sm">
                                              {x?.user.firstName}{' '}
                                              {x?.user?.lastName}
                                            </p>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                    {teamMembers
                                      ?.filter(
                                        (team) =>
                                          team?.jobRole?.id === job?.id ||
                                          team?.secondaryJobroles?.some(
                                            (x) => x?.jobroleId === job?.id
                                          )
                                      )
                                      .filter(
                                        (x) =>
                                          x?.user?.firstName
                                            .toLowerCase()
                                            .includes(
                                              searchedMember.toLowerCase()
                                            ) ||
                                          x?.user?.lastName
                                            .toLowerCase()
                                            .includes(
                                              searchedMember.toLowerCase()
                                            )
                                      )
                                      .filter(
                                        (z) =>
                                          !shiftSchedules
                                          ?.filter(
                                            (x) =>
                                              moment(x.start).format('yyyy-MM-DD') ===
                                                moment(date).format('yyyy-MM-DD') &&
                                              (x?.slotjobRole?.id === job.id || x?.jobrole?.id === job.id) &&
                                              x?.branchId === branch.id
                                          )
                                          ?.map((y) => ({ name: y?.user?.firstName, userId: y.user?.id, ...y }))
                                            .map(
                                              (shiftUser) => shiftUser.userId
                                            )
                                            .includes(z.user.id)
                                      )
                                      .map((team) => (
                                        <p
                                          onClick={() =>
                                            handleCreateShift(
                                              { id: branch?.id },
                                              moment(dates[date]).format(
                                                'yyyy-MM-DD'
                                              ),
                                              team,
                                              job
                                            )
                                          }
                                          className="py-2 text-sm cursor-pointer hover:text-green-600 dark:hover:bg-color-333 dark:text-white"
                                        >
                                          {team?.user?.firstName}{' '}
                                          {team?.user?.lastName}
                                        </p>
                                      ))}
                                  </div>
                                )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                </>
              ))} */}
            </tbody>
          </table>
        </section>)}
       
        {
          displayMode.value === 'rotation-view' && (
            <CombineScheduleRotationView 
            {
              ...{
                dates,
                jobRoles,
                toggleModal,
                searchTeam,
                selectJob,
                selectBranch,
                selectedJobRole,
                selectedUser,
                selectedSkill,
                selectedGender,
                shiftSchedules,
                setCellBackground,
                combinedSchedules,
                selectDate,
                handleOpenTeamList,
                openTeamList,
                cellLoading,
                toggleState,
                searchedMember,
                teamMembers,
                handleCreateShift,
                selectedMultiBranch
              }
            }
            />
          )
        }
      </AppLayout>
      {openModal && (
        <CombineScheduleModal
          openModal={openModal}
          teamMembers={teamMembers}
          handleClose={() => toggleModal((prev) => !prev)}
          searchTeam={searchTeam}
          searchedMember={searchedMember}
          handleCreateShift={handleCreateShift}
          branchId={selectedBranch}
          date={selectedDate}
          job={selectedJob}
          loading={cellLoading}
          userList={userList}
          handleShowUser={handleShowUser}
          handleExtractSchedule={handleExtractSchedule}
          handleCancelShift={handleCancelShift}
        />
      )}
    </section>
  );
};
