import { Stack } from '@mui/material';
import FormModal from '../../../../Widgets/Animation/FormModal';
import {
  Text,
  TextAndField,
  Field,
} from '../../../../Widgets/FormUtils/FormUtils';
import { useFormik } from 'formik';
import CustomButton from '../../../../Widgets/CustomButton';
import CustomDropdown from '../../../../Widgets/CustomDropdown';

const EditTeamInfo = ({
  handleClose,
  open,
  handleSelectOption,
  data,
  branches,
  storeInvites,
  jobRoles,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || '',
      email: data?.email || '',
      assignedBranchId: data?.assignedBranchId || '',
      privilege: data?.privilege || '',
      jobRoleId: data?.jobRoleId || '',
    },
    onSubmit: (values) => {
      handleSelectOption(data?.email, [values], values?.name)
      handleClose();
    }
  });

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ height: '100vh' }}
        justifyContent="center"
      >
        <div className="px-[67px] py-[15px] pb-[56px] bg-white w-[529px]">
          <p className="font-semibold text-base text-center pb-16">Edit team member</p>
          <TextAndField>
            <Text text={'Employee'} />
            <Field
              text={'Kindly employee name'}
              value={formik.values.name}
              sx={{height: "25px"}}
              formik={formik}
              name="name"
            />
          </TextAndField>
          <div className="my-5">
            <TextAndField>
              <Text text={'Email'} />
              <Field
                text={'Kindly enter email'}
                value={formik.values.email}
                sx={{height: "25px"}}
                className="border-6E6"
                formik={formik}
                name="email"
              />
            </TextAndField>
          </div>
          <div>
            <CustomDropdown
              optionClass="z-10"
              dropdownTitleClass="text-sm text-black"
              onChangeOption={(val) =>
                {
                  formik.setFieldValue('assignedBranchId', val?.value)
                }
              }
              value={{
                label:
                  branches?.data?.filter(
                    (y) =>
                      y.id ===
                      storeInvites?.filter((x) => x.email === data?.email)[0]
                        ?.assignedBranchId
                  )[0]?.name || 'Assign location',
                value:
                  storeInvites?.filter((x) => x.email === data?.email)[0]
                    ?.assignedBranchId || 'Select',
              }}
              data={branches?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </div>
          <div className="my-5">
            <CustomDropdown
              dropdownTitleClass="text-sm text-black"
              onChangeOption={(val) =>
                {
                  formik.setFieldValue('privilege', val?.value)
              }
              }
              optionClass="z-10"
              value={{
                label:
                  [
                    { id: 1, name: 'Super Admin' },
                    { id: 2, name: 'Admin' },
                    { id: 3, name: 'Member' },
                  ].filter(
                    (y) =>
                      y.id ===
                      storeInvites?.filter((x) => x.email === data?.email)[0]
                        ?.privilege
                  )[0]?.name || 'Assigned privilege',
                value:
                  storeInvites?.filter((x) => x.email === data?.email)[0]
                    ?.privilege || 'Select',
              }}
              data={[
                { id: 1, name: 'Super Admin' },
                { id: 2, name: 'Admin' },
                { id: 3, name: 'Member' },
              ]?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </div>
          <div>
            <CustomDropdown
              dropdownTitleClass="text-sm text-black"
              onChangeOption={(val) =>
                {
                formik.setFieldValue('jobRoleId', val?.value)
              }
              }
              value={{
                label:
                  jobRoles?.filter(
                    (y) =>
                      y.id ===
                      storeInvites?.filter((x) => x.email === data?.email)[0]
                        ?.jobRoleId
                  )[0]?.title || 'Assigned job roles',
                value:
                  storeInvites?.filter((x) => x.email === data?.email)[0]
                    ?.jobRoleId || 'Select',
              }}
              optionClass="z-10 h-[100px] overflow-y-scroll"
              data={jobRoles?.map((item) => ({
                label: item.title,
                value: item.id,
              }))}
            />
          </div>
          <div className="flex items-center w-full mt-[59px]">
            <CustomButton
              containerClass="w-full"
              onClick={handleClose}
              className="w-full text-center rounded flex justify-center mr-2"
              showIcon={false}
              title="Cancel"
            />
            <CustomButton
              onClick={() => {
                formik.handleSubmit();
              }}
              containerClass="w-full"
              className="w-full text-center ml-2 rounded bg-base-color text-white flex justify-center"
              showIcon={false}
              title="Save"
            />
          </div>
        </div>
      </Stack>
    </FormModal>
  );
};

export default EditTeamInfo;
