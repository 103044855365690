import React, {useEffect, useState} from 'react'
import { IoIosArrowForward,IoIosArrowBack } from "react-icons/io";
import {Box} from '@mui/material'

import {useDispatch} from 'react-redux'
import {setWeeklyDate,setWeeklyDateLabel} from '../../../../../Store/Shift'
import formatDate from '../../../../../utils/formatDate'
import addOneDay from '../../../../../utils/incrementDate';

const NavigateCalender = ({diff, layoutProps={}, sx={}, buttonStyles = 'py-3 px-3 border-1 cursor-pointer', bodyPadding='0.55rem 1.2rem', bodyHeight='40px'}) => {
    const dispatch = useDispatch()
    const [firstDayOfWeek, setFirstDayOfWeek] = useState('') 
    const [lastDayOfWeek, setLastDayOfWeek] = useState('') 
    const [variant, setVariant] = useState(0)

    
    useEffect(()=>{
        const firstday = getWeek(variant).firstDay.toDateString().split(' ')
        const lastday = getWeek(variant).lastDay.toDateString().split(' ')

        console.log(firstday,"uuuu")


        setFirstDayOfWeek(`${firstday[1]} ${firstday[2]}`)
         setLastDayOfWeek(`${lastday[1]} ${lastday[2]}`)
        dispatch(setWeeklyDate({
            to:addOneDay(getWeek(variant).lastDay.toDateString()), 
            from:formatDate(getWeek(variant).firstDay.toDateString())
        }))
        dispatch(setWeeklyDateLabel({
            to:formatDate(getWeek(variant).lastDay.toDateString()), 
            from:formatDate(getWeek(variant).firstDay.toDateString())
        }))
       
    },[variant])

    // styles

    // const buttonStyles = 'py-3 px-3 border-1 cursor-pointer'

  return (
    <div className='text-[#6d6e72] flex flex-row items-center rounded border-[1px] border-[#E6E6E6] dark:border-333'>
        <Box className={`${buttonStyles} rounded-tl rounded-bl border-0 border-r dark:border-333`} onClick={()=>{
            setVariant(variant - diff)
        }}>
            <IoIosArrowBack/>
        </Box>

        <Box className={`${layoutProps?.subHeaderClass} dark:text-white`} sx={{padding: bodyPadding, height: bodyHeight, color:'black', width: 'max-content',
        fontSize:"11px",
         display:'flex', alignItems:'center', borderLeft:'none', borderRight:'none', ...sx}} >{firstDayOfWeek} - {lastDayOfWeek}
        </Box>

        <Box className={`${buttonStyles} rounded-tr rounded-br border-0 border-l dark:border-333`} onClick={()=>{
            setVariant(variant + diff) 
            }}>
            <IoIosArrowForward/>
        </Box>
    </div>
  )
}

const getWeek = (variant) => {
    const today =  addOneDay(new Date());

    // ✅ Get the first day of the current week (Monday)
    function getFirstDayOfWeek(d) {
      // 👇️ clone date object, so we don't mutate it
      const date = new Date(d);
      const day = date.getDay(); // 👉️ get day of week
    
      // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
      const diff = date.getDate() - day + (day === 0 ? -6 : 1+variant);
    
      return new Date(date.setDate(diff));
    }
    
    const firstDay = getFirstDayOfWeek(today);
    
    // ✅ Get the last day of the current week (Sunday)
    const lastDay = new Date(firstDay);
    lastDay.setDate(lastDay.getDate() + 6);
    return {firstDay,lastDay}
}

export default NavigateCalender