import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import FormModal from '../../../../Widgets/Animation/FormModal';
import { FillButton, OutlinedButton } from '../../../../Widgets/FormUtils/FormUtils';
import { CircularProgress } from '@mui/material';
import { deleteInventoryManufacturerThunk, getInventoryManufacturersThunk } from '../../../../../Store/Inventory';
import { useDispatch } from 'react-redux';



export const DeleteManufacturer = ({ open, handleClose, data }) => {
  const [isloading, setLoading] = useState(false);
  const dispatch = useDispatch()
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY:'auto',
    overflowX:'hidden'
  };

  const handleDelete = () => {
    setLoading(true)
    dispatch(deleteInventoryManufacturerThunk(data?.id))
    .then(val => {
      setLoading(false)
      if(val.payload?.status === 200){
        dispatch(getInventoryManufacturersThunk())
        toast.success(val?.payload?.data?.message)
        handleClose()
      }else {
        toast.error(val?.payload?.data?.message)
      }
    })

  }

  return (
    <FormModal open={open} handleClose={handleClose}>
        <div 
        className='absolute dark:bg-A06 dark:text-white px-8 py-5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[350px] h-[200px] bg-white rounded-md flex flex-col gap-2 justify-between'>

        <p className='text-center font-semibold text-[18px] px-2'>Are you sure you want to delete this manufacturer?</p>
        <p className='text-center text-[16px] text-A5A'>“{data?.name}”</p>

        <div className='flex justify-between'>
          <OutlinedButton
            text="Cancel"
            width={'48%'}
            callBack={handleClose}
          />
          {!isloading && (
            <FillButton text="Delete" width={'48%'} bg='#C15353' callBack={handleDelete} />
          )}
          {isloading && <CircularProgress />}
            
        </div>
     </div>
    </FormModal>
  );
};
