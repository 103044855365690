import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import {
  ResponsiveContainer,
  Area,
  AreaChart,
  YAxis,
  XAxis,
  Tooltip,
} from 'recharts';
import { getGraphDetails } from '../../../../../Network/ServiceClass/dashboardService';
import PlaceHolderOne from '../../../../Widgets/placeholders/PlaceHolderOne';
import { useSelector, useDispatch } from 'react-redux';
import {
  FieldSelectWithOutFormik,
  Text,
  TextAndField,
} from '../../../../Widgets/FormUtils/FormUtils';
import {
  getBranchesAsync,
  fetchJobRolesAsync,
  getShiftBenchmarkAsync,
} from '../../../../../Store/Branches';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import dayjs from 'dayjs';
import capitalizeFirstLetter from '../../../../../utils/capitalizeFirstLetter';

const Row5 = () => {
  const distpatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [foundData, setFoundData] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);

  const company = useSelector((state) => state.company.activeCompany);
  // const branches = useSelector(state => state.branches.branches)?.data
  // const jobRoles = useSelector(state => state.branches.jobRoles).map(val => ({id: val.id, name: val.title}))
  const benchmarks = useSelector(state => state.branches)?.shiftBenchmarks || []

  const [branches, setBranches] = useState([]);
  const [jobRoles, setJobRoles] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState( {
    label: 'All Branches',
    value: '1',
    hexcode: ''
  });
  const [selectedJobRole, setSelectedJobRole] = useState( {
    label: 'All Job Roles',
    value: '1',
    hexcode: ''
  });

  const today = new Date();

  // Get the date 30 days from now
  const next30thDay = new Date();
  next30thDay.setDate(today.getDate() + 30);

  // Format the dates as strings (YYYY-MM-DD)
  const formattedToday = today.toISOString().split('T')[0];
  const formattedNext30thDay = next30thDay.toISOString().split('T')[0];

  useEffect(() => {
    setIsLoading2(true);
    distpatch(getBranchesAsync()).then((val) => {
      setBranches(val.payload.data);
    });
    distpatch(fetchJobRolesAsync()).then((val) => {
      setIsLoading2(false);
      setJobRoles( val.payload.data);
    });
  }, [company]);

  useEffect(() => {
    let queryString = `year=2023&to=${formattedNext30thDay}&from=${formattedToday}&format=daily`;
    if (selectedBranch.value !== '1') {
      queryString += `&branchIds=${selectedBranch.value}`;
    }
    if (selectedJobRole.value !== '1') {
      queryString += `&jobRoleId=${selectedJobRole.value}`;
    }

    setIsLoading(true);
    distpatch(getShiftBenchmarkAsync({
      from: formattedToday,
      to: formattedNext30thDay,
      branchId: selectedBranch.value === '1' ? undefined : selectedBranch.value
  }))
    if (selectedBranch.value === '1' && selectedJobRole.value === '1') {
      getGraphDetails(queryString)
        .then((val) => {
          setIsLoading(false);
          setData(val);
          setFoundData(
            val.reduce((total, item) => total + item.counts, 0) > 0
              ? true
              : false
          );
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      getGraphDetails(queryString)
        .then((val) => {
          setIsLoading(false);
          setData(val);
          setFoundData(
            val.reduce((total, item) => total + item.counts, 0) > 0
              ? true
              : false
          );
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [company, selectedBranch, selectedJobRole]);

  return (
    <div className="w-[100%] shadow-shift-shadow p-3 rounded-lg gap-3 flex flex-col">
      {!isLoading2 && (
        <div className="flex items-start gap-2">
          <p className="text-[16px] font-semibold w-[30%] dark:text-white">
            Shift Coverage (Next 30 Days)
          </p>
          <div className="w-[40%] flex gap-4">
            <TextAndField>
              <Text className="dark:text-white" text={'Select Location'} />
              <CustomDropdown
                trimtext
                trimLength={20}
                data={[
                  {
                    label: 'All Branches',
                    value: '1',
                    hexcode: ''
                  },
                  ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
                ]}
                className="h-10 rounded "
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={selectedBranch}
                onChangeOption={(val) => {
                  setSelectedBranch(val);
                }}
              />
            </TextAndField>

            <TextAndField>
              <Text className="dark:text-white" text={'Select Job role'} />
              <CustomDropdown
                trimtext
                trimLength={20}
                data={[
                  {
                    label: 'All Job Roles',
                    value: '1',
                    hexcode: ''
                  },
                  ...jobRoles?.map((x) => ({ label: x?.title, value: x.id, hexcode: x?.hexcode}))
                ]}
                className="h-10 rounded "
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={selectedJobRole}
                onChangeOption={(val) => {
                  setSelectedJobRole(val);
                }}
              />
            </TextAndField>
          </div>
        </div>
      )}
      {!isLoading && (
        <div>
          {(foundData || benchmarks.some((x) => x.counts > 0)) && (
            <ResponsiveContainer width="95%" height={400}>
              <AreaChart data={data?.map((x) => ({...x, Benchmark: benchmarks?.filter((y) => y.date === x.date)[0]?.counts, Count: data?.filter((y) => y.date === x.date)[0].counts}))} margin={{ top: 50, bottom: 50, left: 20 }}>
                <YAxis
                  allowDecimals={false}
                  tickSize={22}
                  tickLine={false}
                  axisLine={false}
                  padding={{ left: 0 }}
                  fontSize={12}
                />
                <Area
                  type="monotone"
                  dataKey="Benchmark"
                  stroke="#ddaaaa"
                  fill="#fffefe" 
                />
                <Area
                  type="monotone"
                  dataKey="Count"
                  stroke="#8ce5d0"
                  fill="#9ee6e1"
                />
                <XAxis
                  interval={Math.floor(data?.length / 5)}
                  angle={0}
                  tick={{ stroke: 'rgb(185, 183, 183)', strokeWidth: 0.1 }}
                  axisLine={{ strokeWidth: 0.2 }}
                  dx={10}
                  dy={8}
                  dataKey="date"
                  tickLine={false}
                  fontSize={10}
                  padding={{ left: 0, right: 20 }}
                  tickSize={10}
                  allowDataOverflow={true}
                  minTickGap={30}
                  style={{ backgroundColor: '#fff', zIndex: '2' }}
                />
                <Tooltip  content={(props) => CustomTooltip(props)}/>
              </AreaChart>
            </ResponsiveContainer>
          )}
          {!foundData && benchmarks.every((x) => x.counts === 0) && (
            <p className="mt-3">
              <PlaceHolderOne showPlaceholder={false} />
            </p>
          )}
        </div>
      )}

      {isLoading && (
        <div className="w-[100%] h-[170px] flex justify-center mt-3">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Row5;

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    // You can access the tooltip values from 'payload'
    return (
      <div className="custom-tooltip bg-white dark:bg-292 dark:text-white rounded px-4 py-2 text-[14px] shadow-shift-shadow-small">
        <p>Date: {dayjs(label).format('DD MMM YYYY')}</p>
        {payload?.map((entry, index) => (
          <p key={`item-${index}`} >
            {`${capitalizeFirstLetter(entry.name)}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
}
