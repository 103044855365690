import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import {getInventoryItemsBelowCriticalLimitThunk} from '../../../Store/Inventory'
import ExportTemplate from './Widget/ExportTemplate'

const CriticalLimit = ({branches}) => {
    const dispatch = useDispatch()


    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})



    const criticalLimitItems = useSelector(state => state.inventory)?.criticalLimit

   

    const getCriticalLimit = (id=null) => {
        dispatch(getInventoryItemsBelowCriticalLimitThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getCriticalLimit(withdrawalBranch.value)
    },[withdrawalBranch])
    

  
  return (
    <div className='w-full'>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Items Running Low'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={criticalLimitItems.map(x => ({
                    'Items': x?.inventoryItems?.name,
                    'Location': x?.location?.name,
                    'Current Amount': x?.currentAmount,
                    'Critical Limit': x?.criticalLimit,
                    

                }))}
                fileName={'critical-limit'}
            />


            <div className='rounded-md border-1 dark:border-333 mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className=''>
                <tr className='bg-white dark:bg-444 dark:text-white font-bold sticky top-0'>
                    <td className='border-1 dark:border-333 border-t-0 border-l-0 text-left pl-4 py-4 rounded-tl-md w-[30%] '>Items</td>
                    <td className='border-1 dark:border-333 border-t-0 text-left w-[30%] pl-4'>Location</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center w-[20%]'>Current Amount</td>
                    <td className='border-1 dark:border-333 border-t-0 border-r-0 text-center rounded-tr-md w-[20%]'>Critical Limit</td>

                </tr>


                {!isLoading ? <tbody>
                    {criticalLimitItems?.length !== 0 ?
                        criticalLimitItems.map(x => (
                            <tr className='border-1 border-l-0 dark:bg-828 dark:border-333 text-A5A dark:text-white font-bold'>
                                    <td className='text-left border-r-1 dark:border-333 py-4 pl-4'>{x?.inventoryItems?.name}</td>
                                    <td className='text-left border-r-1 dark:border-333'>
                                        <div className='flex items-center justify-start pl-4 gap-2'>
                                            <p className='w-2 h-2 rounded-full' style={{background: x?.location?.hexcode}}/>
                                            <p>{x?.location?.name}</p>
                                        </div>
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                       {x?.currentAmount}
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{x?.criticalLimit}</td>
                            </tr>

                        ))
                        :<tr>
                            <td colSpan={7} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                    }
                </tbody>:
                <tr>
                    <td colSpan={7} className='text-center pt-10 border-1 border-white dark:border-333'><CircularProgress /></td>
                </tr>
                }
                
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default CriticalLimit