import React,{useEffect} from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useDispatch,useSelector} from 'react-redux'
import {setToggleCalender, setRoaster, setDailyTime, setIsLoadingRoaster} from '../../../../../Store/Shift'
import { Shift } from '../../../../../Network/ServiceClass/Shift'
import addOneDay from '../../../../../utils/incrementDate';
import {font} from '../../../../Widgets/Styles/font'



export default function ColorToggleButton() {
  const shift = new Shift();

  const dispatch = useDispatch()
  const shiftStateWeeklyDateLabel = useSelector((state) => state.shift.weeklyDateLabel)
  const shiftStateToggleCalender = useSelector((state) => state.shift.toggleCalender)


  const [alignment, setAlignment] = React.useState('week');

  useEffect(()=>{
    setAlignment(shiftStateToggleCalender)
  },[shiftStateToggleCalender])

  const handleChange = (event, newAlignment) => {
    const value = (newAlignment === null) ?  alignment : newAlignment
    dispatch(setToggleCalender(value))
    setAlignment(value);
    if(value === 'week'){
      dispatch(setIsLoadingRoaster(true))
      shift.fetchRoaster(1,addOneDay(shiftStateWeeklyDateLabel.to), shiftStateWeeklyDateLabel.from,1).then((value)=>{
        dispatch(setRoaster(value.data.data))
        setIsLoadingRoaster(false)
      }).catch((err)=>{
        console.log(err);
      });
      dispatch(setDailyTime({to:6, from:0}))
    }
  };

  return (
    <ToggleButtonGroup
        style={{backgroundColor:"#A5A5A5", height:"35px"}}
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton style={{backgroundColor: (alignment === "week")?"#A5A5A5":"white", color:(alignment === "week")?"grey":"black", fontSize:"12px", ...font}} value="day">Day</ToggleButton>
      <ToggleButton style={{backgroundColor: (alignment === "week")?"white":"#A5A5A5", color:(alignment === "week")?"black":"grey", fontSize:"12px", ...font}} value="week">Week</ToggleButton>
    </ToggleButtonGroup>
  );
}