export class Storage {

    async AuthStorage(object) {
        await localStorage.setItem("authtoken", object.accessToken);
        await localStorage.setItem("user", JSON.stringify({...object.user, linkedEmails: object?.linkedEmails}))
        await localStorage.setItem('activeCompany', JSON.stringify(object.activeCompany));
        await localStorage.setItem("companies", JSON.stringify(object.companies));
        await localStorage.setItem("picture", JSON.stringify(object.picture));
        return true;
    }

    async SetUserInStorage(object){
        await localStorage.setItem("user", JSON.stringify({...object.user, linkedEmails: object?.linkedEmails}))

    }

    clearStorage() {
        localStorage.clearStorage();
    }

    getUserDetails() {
        if (localStorage.getItem("user") !== "undefined")
            return JSON.parse(localStorage.getItem("user"));
        else
            return null;
    }


    getActiveCompany() {
        if (localStorage.getItem("activeCompany") !== "undefined")
            return JSON.parse(localStorage.getItem("activeCompany"));
        else
            return null;
    }

    getCompanies() {
        if (localStorage.getItem("companies") !== "undefined")
            return JSON.parse(localStorage.getItem("companies"));
        else
            return null;
    }

    getConfig() {
        return {
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authtoken")}`
            },
        };
    }

    getFileConfig() {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
                'Accept': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("authtoken")}`,
            },
        }
    }

    getToken() {
        return localStorage.getItem("authtoken");
    }


}