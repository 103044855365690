import {useEffect, useState} from "react"
import GlobalButton from "../../../Widgets/GlobalButton"
import AppLayout from "../../../Widgets/AppLayout"
import CustomDropdown from "../../../Widgets/CustomDropdown"

import { Branch } from "../../../../Network/ServiceClass/Branch"
import { useDispatch, useSelector } from "react-redux"
import { getInventoryBackOrderRequestThunk } from "../../../../Store/Inventory"
import { CircularProgress } from "@mui/material"
import dayjs from "dayjs"
import { useNavigate } from "react-router"
import CurrencyFormat from "react-currency-format"
import { Storage } from "../../../../Network/StorageClass/StorageClass"
import {ImAttachment} from 'react-icons/im'
import FileUploadUi from "../Widget/FileUploadUi"
import ViewDocs from "../Widget/ViewDocs"
import avatar from '../../../../Assets/avatar.png'
import TutorialPanel from '../../../Widgets/Tutorial/TutorialPanel'
import { backOrderRequestTutorial } from '../../mock';

const branchService = new Branch()
const storage = new Storage()



export const BackOrderRequest = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const backOrderList = useSelector(state => state.inventory)?.backOrderRequest || []
  const loading = useSelector(state => state.inventory)?.loading

  const [branches, setBranches] = useState([])
  const [selectedBranch, setSelectedBranch] = useState({label: 'All locations', value:'1'})

  const [openAttachModal, setOpenAttachModal] = useState(false)
  const [attachmentData, setAttachmentData] = useState(null)
  const [openDocsModal, setOpenDocsModal] = useState(false)



  const isAllow = () => {
    if ([1,2].includes(storage.getActiveCompany().memberships[0].privilege)) return true
    else return false
  }


  useEffect(()=> {
    branchService.getBranches()
    .then(val => {
      setBranches([{label: 'All locations', value:'1'},...val?.data.map(x => ({label: x.name, value: x.id}))])
    })
  },[])

  const getBackOrderRequest = () => {
    dispatch(getInventoryBackOrderRequestThunk(selectedBranch.value === '1' ? undefined : selectedBranch.value))
  }

  useEffect(()=> {
    getBackOrderRequest()
  },[selectedBranch])


  const handleCloseAttachModal = ()=> {
    setOpenAttachModal(false)
    setAttachmentData(null)
  }

  const handleCloseDocsModal = ()=> {
    setOpenDocsModal(false)
    setAttachmentData(null)
  }

  return (
    <section>
      {openAttachModal && <FileUploadUi 
        openAttachModal={()=> setOpenAttachModal(true)} 
        handleCloseAttachModal={handleCloseAttachModal}
        data={attachmentData}
        callback = {getBackOrderRequest}
      />}

      {openDocsModal && <ViewDocs 
        openModal={openDocsModal} 
        handleCloseModal={handleCloseDocsModal}
        data={attachmentData}
      />}

     
        <AppLayout
          custom="inventory"
          subCustom="back-order-request"
          title="Back Order Request"
          customTitle="Inventory"
          subtitle=""
          RightComponent={<TutorialPanel items={backOrderRequestTutorial} />}
        >
          <div className="flex flex-col gap-4 mb-7">
          
            <div className="sticky top-[4.3rem] pb-5 bg-white dark:bg-A06 flex justify-between">
              <CustomDropdown
                mainContainerStyle="w-32"
                trimtext
                data={branches}
                className="w-32 rounded h-10"
                value={selectedBranch}
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000]"
                onChangeOption={(e)=>{
                  setSelectedBranch(e)
                }}
              />

              <GlobalButton 
                onClick={()=> navigate('/create-order-request')}
                title="Create order request" 
                className="rounded-md font-semibold text-[16px] text-white"/>    


            </div>

            {!loading ? 
              (backOrderList.length !== 0 ? <div className="flex flex-col mb-28 gap-5">
              

                {backOrderList.map((order,index) => (

                  <div
                    onClick={()=> (isAllow()) && navigate(`/back-order-request/${order?.orders[0]?.orderIdentifier}`, 
                    { state: {title:'Back Order Request'} })}
                    key={index} 
                    className={`border-9FD dark:border-969 ${isAllow() ? 'cursor-pointer': 'cursor-default'}  px-6 flex flex-col gap-5 pb-12 pt-3 border h-full rounded-[20px] w-full`}>
                   
                    <div className="flex gap-2 justify-end ">
                        {order?.attachments.length > 0 &&<div
                            onClick={(e) => {
                              e.stopPropagation()
                              setOpenDocsModal(true)
                              setAttachmentData(order)
                            }}
                            className='flex gap-1 h-full text-[13px] cursor-pointer items-center text-D57'>
                            <ImAttachment size={13}/>
                            <p>{order?.attachments.length}</p>
                            <p>Documents</p>
                        </div>}

                        <div 
                          onClick={(e)=> {
                            e.stopPropagation()
                            setOpenAttachModal(true)
                            setAttachmentData(order)
                          }}
                          className='flex gap-1 h-full ml-3 text-[13px] cursor-pointer items-center text-D57'>
                          <ImAttachment size={13}/>
                          <p>Attach</p>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <Column title={'ID'} text={order?.orders[0]?.orderIdentifier}/>
                      <Column title={'Location'} text={order?.orders[0]?.branch?.name} hexcode={order?.orders[0]?.branch?.hexcode}/>
                      
                      <Column 
                        title={'Created By'} 
                        text={`${order?.orders[0]?.teamMembership?.user?.firstName} ${order?.orders[0]?.teamMembership?.user?.lastName}`}
                        pic={order?.orders[0]?.teamMembership?.user?.picture?.url || 'empty'}  
                      />
                      
                      <Column title={'Date'} text={dayjs(order?.orders[0]?.createdOn).format('D MMM YYYY')}/>
                      <Column title={'No of items'} text={order?.orders.length}/>

                      <div className="flex flex-col gap-3 items-center">
                        <p className="text-sm text-A5A dark:text-white font-bold">Total value</p>
                        <p className="text-[18px] font-extrabold">
                        <CurrencyFormat
                          className="dark:text-white"
                          value={order?.orders.length === 0 ? 0 : order?.orders.reduce((accumulator, currentValue) => accumulator + currentValue.reference.packageCost, 0)/100} // The numeric value you want to format
                          displayType={'text'} // You can also use 'input' for input fields
                          thousandSeparator={true} // Use commas as thousands separators
                          prefix={'$'} // Currency symbol or prefix
                          decimalScale={2} // Number of decimal places
                        /></p>

                      </div>

                     
                    </div>  
                  
                  </div>

                  
                ))}

              </div> : 
              <div className="text-center text-A5A mt-2">Nothing to see here</div>) : 
              <div className="text-center mt-2">
                <CircularProgress />
              </div>
            }

          </div>
        </AppLayout>
      
    </section>
  )
}

const Column = ({title, text, hexcode, pic}) => {
  return(
    <div className="flex gap-5">

      {pic && <div className="self-end">
        {pic !== 'empty' ? 
          <img className="w-9 h-9 rounded-full border-1 bg-gray-400" src={pic}/>
        :
        <img className="w-9 h-9 rounded-full" src={avatar}/>
        }
      </div>}

      <div className="flex flex-col gap-3 items-center min-w-20">
        <p className="text-sm text-A5A dark:text-white">{title}</p>

        <div className={`flex gap-2 items-center`}>
          {hexcode && <p className="w-2 h-2 rounded-full" style={{backgroundColor: hexcode}}/>}
          <p className="text-A5A font-bold dark:text-white">{text}</p>
        </div>
      </div>
    </div>
  )
}