import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { TimeOffService } from "../Network/ServiceClass/TimeOffService";

const timeoff = new TimeOffService()
export const fetchTimeOffThunk = createAsyncThunk("timeoff/fetchtTimeoff", timeoff.fetchTimeOff)


const initialState = {
    listData: [],
    calendarData: [],
    employeeList: [],
    timePTO: [],
    loading: false,
}

const TimeOffSlice = createSlice({
    name: 'timeOff',
    initialState: initialState,
    reducers: {
        setListData: (state, action)=>{
            state.listData = action.payload;
        },
        setCalendarData: (state, action)=>{
            state.calendarData = action.payload;
        }, 
        setEmployeeList: (state, action)=>{
            state.employeeList = action.payload;
        },
               
    },

    extraReducers: {
        [fetchTimeOffThunk.pending]: (state, action) => {
            state.loading = true
        },
        [fetchTimeOffThunk.fulfilled]: (state, action) => {
            state.loading = false
            state.timePTO = action.payload.data
        },
        [fetchTimeOffThunk.rejected]: (state, action) => {
            state.loading = false
        },
    }

});

export const {
    setCalendarData,
    setListData,
    setEmployeeList
   } = TimeOffSlice.actions;
export default TimeOffSlice.reducer;