import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    requestOffers: [],
    openDrawer: {state: false, data: null}
}

const SwapOfferSlice = createSlice({
    name: 'swapOffers',
    initialState: initialState,
    reducers: {
        setRequetsOffers: (state, action)=>{
            state.requestOffers = action.payload;
        },
        setOpenDrawer: (state, action)=>{
            state.openDrawer = action.payload;
        }, 
               
    }

});

export const {
    setRequetsOffers,
    setOpenDrawer
   } = SwapOfferSlice.actions;
export default SwapOfferSlice.reducer;