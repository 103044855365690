import React,{useState, useEffect} from 'react'
import Details from './Details/Details'
import {getAnnouncements} from '../../../../Network/ServiceClass/announcementService'
import { CircularProgress } from '@mui/material'
import {AnnouncementCard} from './AnnouncementCard'
import { useDispatch,useSelector } from 'react-redux'
import {setFavorite} from '../../../../Store/announcement'
import placeholderPic2 from '../../../../Assets/placholders/placeholder2.png'
import PlaceHolderOne from '../../../Widgets/placeholders/PlaceHolderOne'


const Favorite = () => {
  const dispatch = useDispatch()
  const stateData = useSelector((state) => state.announcement.favorite)

  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    setIsLoading(true)
    getAnnouncements('liked').then(val => {
      setIsLoading(false)
      dispatch(setFavorite(val.data.data))
    }).catch(error=> {
      setIsLoading(false)
      console.log(error)
    })
  },[])


  const handleOpenModal = (value) => {
    setOpenModal(true)
    setModalData(value)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <div>
         {modalData ? 
            <Details openModal={openModal} handleClose={handleClose} data={modalData}/>
            : <></>
          }

      {(!isLoading && stateData.length > 0) && <div className='grid grid-cols-3 gap-4 mt-3'>
          {stateData.map(val => (
                    <AnnouncementCard 
                      onClick={()=> handleOpenModal(val)}
                      data={val}
                     />
          ))}
      </div>}
      {(!isLoading && stateData.length <= 0) && <PlaceHolderOne pic={placeholderPic2}/>}

      {isLoading && <div className='w-[100%] flex justify-center mt-3'>
        <CircularProgress/>
      </div>}
    </div>
  )
}

export default Favorite



