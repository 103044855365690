import React, {useState} from 'react'
import empty from '../../../../../Assets/tracker_empty.png';
import { CreateCompanyForm } from './Form/CreateCompanyForm';
import {FillButton} from '../../../../Widgets/FormUtils/FormUtils'
import './NoCompany.css';
import CreateAccountModal from '../Modal/CreateAccountModal';

export const NoCompany = ({open, callback}) => {

    const [createAccount, setCreateAccount] = useState(false);

  return (
    <div className='no-company'>

        <div className='inner_'>

        {!createAccount ? <>

            <img alt="img" src={empty} />

            <p>You do not have any companies yet</p>

            {/* <button onClick={()=>setCreateAccount(true)}>Create company</button> */}
            <FillButton 
              type='submit' 
              text={"Create company"} 
              callBack={()=>setCreateAccount(true)} 
              width='200px' 
              height={'50px'} 
              textSize='30px'/>

            </> : <CreateAccountModal open={createAccount} handleClose={()=>setCreateAccount(false)} callback={callback}/> }
            

        </div>

    </div>
  )
}
