import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import DateLabel from '../../../Widget/DateLabel/DateLabel';
import InitialsGen from '../../../../../Widgets/InitialsGen';
import swapPic from '../../../../../../Assets/swap_notification.png';
import { ClickAwayListener } from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { fetchTimeOffThunk } from '../../../../../../Store/timeOff';
import generateCalendarDates from '../../../../../../utils/generateScheduleDate';
import tinycolor from 'tinycolor2';

export const LocationStackView = ({
  monthSelected,
  handleSchedule,
  selectedMultiBranch,
  handleOpenScheduleOption,
  handleToggleDropdown,
  setInputSearchBranch,
  inputSearchBranch,
  getBackgroundColor,
  openDropdown,
  shiftSchedules,
  handleOpenPopperShiftDetails,
  setOpenPopperShiftDetails,
  selectedSchedule,
  cellLoading,
  branches,
  setOpenDropdown,
  handleCreateShift,
  handleCancelSchedule,
  handleSelectedBranch,
  selectBranch,
  handleEnableShiftSwap,
  selectSchedule,
  handleOpenUserProfile,
  selectMember,
  selectPTODate,
  teamMembers,
  handleOpenPTO,
  calendarDisplay,
  dispatch,
  memberships,
  privilege,
  dates,
}) => {
  
  const timeoff = useSelector((state) => state?.timeOff)?.timePTO;
  
  useEffect(() => {
    dispatch(fetchTimeOffThunk())
  }, [monthSelected]);

  const handleSearchBranch = (val) => {
    if (val === '') return branches;

    const pattern = new RegExp(`\D*${val}\D*`, 'i');
    return branches.filter((branch) => branch.name.match(pattern));
  };

  return (
    <div>
      <ClickAwayListener
        onClickAway={() => {
          selectSchedule('');
          setOpenDropdown(false);
        }}
      >
        <section className=" mb-40 shadow-small-select">
          <table className=" ">
            {Array.from(
              Array(
                  calendarDisplay?.length/7
              ).keys()
            )?.map((eachWeek) => (
              <div
                className="mb-12 w-full"
              >
                <thead className="sticky top-16 bg-[white] z-50 dark:bg-A06">
                <tr>
                  <td className={`w-[200px] border-b border-r-0 z-50 dark:bg-A06 dark:border-333 bg-white`}></td>
                  {
                    [...new Set(calendarDisplay
                      ?.slice(eachWeek * 7, (eachWeek + 1) * 7).map((x) => moment(x).format('MMM')))]?.map((y, index) => (<td colSpan={calendarDisplay
                        ?.slice(eachWeek * 7, (eachWeek + 1) * 7).map((dat) => moment(dat).format('MMM')).filter((z) => z === y).length} >{y}</td>))
                  }
                  </tr>
                  <tr>
                  <td className="w-[200px] border-b border-r p-4 dark:border-333">
                    <p className="px-3 dark:text-white">Employee</p>
                  </td>
                  {calendarDisplay
                    ?.slice(eachWeek * 7, (eachWeek + 1) * 7)
                    .map((value, index) => {
                      if (index < 8)
                        return (
                          <td
                            className={`cursor-pointer border-b border-r dark:border-333 w-[150px]
                          ${
                            [0, 1].includes(index)
                              ? (moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? " bg-grey-out" : 'bg-calendar-weekend dark:bg-A06'
                              : ''
                          } dark:bg-A06 ${(moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? " bg-grey-out" : ""}`}
                          >
                            <DateLabel
                              padding={'10px'}
                              detailsWeek={moment(value).toDate()}
                              type="week"
                              hideMonth
                              className={(moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? " bg-grey-out" : ''}
                              layoutProps={{
                                headerClass: `dark:text-white ${(moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? "text-grey-out" : ''}`,
                                subHeaderClass: 'dark:text-969',
                              }}
                            />
                          </td>
                        );
                      return null;
                    })}

                  </tr>
                </thead>

                {
                  handleSchedule(selectedMultiBranch)?.map((schedule) => (
                    <>
                      {schedule?.mode === 'data' && (
                        <tr className='border dark:border-333'>
                          <td className="'border dark:border-333 px-2 py-3 flex items-center gap-2 relative dark:bg-A06 ">
                            <div
                              className="cursor-pointer"
                              onClick={() => handleOpenUserProfile(schedule)}
                            >
                              {schedule?.user?.imageUrl ? (
                                <img
                                  className="rounded-full h-10 w-10 mr-2"
                                  src={schedule?.user?.imageUrl}
                                  alt="logo"
                                />
                              ) : (
                                <InitialsGen
                                  width={40}
                                  name={schedule?.user?.name}
                                  bgColor="#00AD57"
                                  mr="0"
                                />
                              )}
                            </div>
                            <p className="font-semibold text-xs text-555 dark:text-white">
                              {schedule?.user?.name[0]}.
                              {schedule?.user?.name?.split(' ')[1]}{' '}
                              <span>
                                {schedule?.user?.skills?.filter(
                                  (x) => x?.name === 'Spanish Speaker'
                                )?.length > 0
                                  ? ' (SP)'
                                  : ''}
                              </span>
                            </p>
                            <div>
                              <p className="text-xs font-semibold absolute text-[9px] left-14 bottom-0 text-999 dark:text-white">
                                {schedule?.user?.jobRole?.title}
                              </p>
                              <div
                                style={{
                                  backgroundColor:
                                    schedule?.user?.jobRole?.hexcode !== 'NULL'
                                      ? schedule?.user?.jobRole?.hexcode
                                      : 'white',
                                }}
                                className={`w-2 h-2 rounded-full bg-[${
                                  schedule?.user?.jobRole?.hexcode !== 'NULL'
                                    ? schedule?.user?.jobRole?.hexcode
                                    : 'white'
                                }] absolute bottom-1 right-1`}
                              />
                            </div>
                            <div className="absolute right-2 top-4">
                              <p className="text-sm font-bold dark:text-white">
                                {shiftSchedules?.filter((shiftSchedule) => {
                                  return (
                                    schedule?.user?.id ===
                                    shiftSchedule?.user?.id
                                  );
                                })?.length || ''}
                              </p>
                            </div>
                          </td>
                          {calendarDisplay
                            ?.slice(eachWeek * 7, (eachWeek + 1) * 7)
                            ?.map((date) =>
                              moment(date).toDate()
                            )
                            .map((value, index) => {

                              const calendarData = timeoff?.filter(
                                (x) =>
                                  x.requester.id === schedule?.user?.id &&
                                  moment(moment(value).format('yyyy-MM-DD')).isBetween(
                                    moment(x.start).subtract(1, 'days').format('yyyy-MM-DD'),
                                    moment(x.end).add(1, 'days').format('yyyy-MM-DD')
                                  )
                              )
                             
                              return (
                                <td
                                  onDoubleClick={() => {
                                    const selectedTeamMember = teamMembers.find(
                                      (x) => x.user.id === schedule?.user?.id
                                    );
                                    selectMember(selectedTeamMember);
                                    selectPTODate(
                                      moment(value).format('yyyy-MM-DD')
                                    );
                                    handleOpenPTO(schedule?.user?.id);
                                  }}
                                  onClick={() => {
                                    setInputSearchBranch('');
                                    handleToggleDropdown();
                                    handleOpenScheduleOption(
                                      `${moment(value).format('yyyy-MM-DD')}${
                                        schedule?.user?.id
                                      }`
                                    );
                                  }}
                                  className={`border relative  p-1 w-[100px] cursor-pointer dark:border-333 ${
                                    [0, 6].includes(value?.getDay())
                                      ? (moment(value).toDate().getMonth()+1) !== Number(monthSelected.value) ? " bg-grey-out" : 'bg-calendar-weekend dark:bg-A06'
                                      : (value.getMonth()+1) !== Number(monthSelected.value) ? " bg-grey-out" : ''
                                  } dark:bg-A06`}
                                >
                                  {
                                    calendarData?.length > 0
                                   ? (
                                    <div className="flex items-center justify-center p-2 cursor-not-allowed bg-ccc text-lg text-white h-full dark:bg-444">
                                      <p>PTO</p>
                                    </div>
                                  ) : (
                                    <div
                                      className={` h-full rounded flex p-2 items-center justify-center`}
                                      style={{
                                        backgroundColor: getBackgroundColor(
                                          schedule,
                                          value
                                        ),
                                        color: tinycolor(getBackgroundColor(schedule, value)).isDark() ? "white": "black"
                                      }}
                                    >
                                      {shiftSchedules?.length > 0 &&
                                      shiftSchedules?.filter(
                                        (shiftSchedule) => {
                                          return (
                                            moment(shiftSchedule?.start).format(
                                              'yyyy-MM-DD'
                                            ) ===
                                              moment(value).format(
                                                'yyyy-MM-DD'
                                              ) &&
                                            schedule?.user?.id ===
                                              shiftSchedule?.user?.id
                                          );
                                        }
                                      )?.length > 0 ? (
                                        <div
                                          className="flex gap-2 items-center flex-col justify-center"
                                          onMouseEnter={(event) => {
                                            const [shiftScheduleFilter] =
                                              shiftSchedules?.filter(
                                                (shiftSchedule) => {
                                                  return (
                                                    moment(
                                                      shiftSchedule?.start
                                                    ).format('yyyy-MM-DD') ===
                                                      moment(value).format(
                                                        'yyyy-MM-DD'
                                                      ) &&
                                                    schedule?.user?.id ===
                                                      shiftSchedule?.user?.id
                                                  );
                                                }
                                              );
                                            let timeoutId = setTimeout(() => {
                                              handleOpenPopperShiftDetails(
                                                event,
                                                shiftScheduleFilter
                                              );
                                            }, 500);
                                            event.currentTarget.addEventListener(
                                              'mouseleave',
                                              () => {
                                                clearTimeout(timeoutId);
                                              }
                                            );
                                          }}
                                          onMouseLeave={() =>
                                            setOpenPopperShiftDetails(null)
                                          }
                                        >
                                          <p className="text-center text-[13px] font-bold">
                                            {
                                              shiftSchedules?.filter(
                                                (shiftSchedule) => {
                                                  return (
                                                    moment(
                                                      shiftSchedule?.start
                                                    ).format('yyyy-MM-DD') ===
                                                      moment(value).format(
                                                        'yyyy-MM-DD'
                                                      ) &&
                                                    schedule?.user?.id ===
                                                      shiftSchedule?.user?.id
                                                  );
                                                }
                                              )[0]?.slot?.branch?.name
                                            }
                                          </p>
                                          <p className="text-[9px] text-left font-bold">
                                            {moment(
                                              shiftSchedules?.filter(
                                                (shiftSchedule) => {
                                                  return (
                                                    moment(
                                                      shiftSchedule?.start
                                                    ).format('yyyy-MM-DD') ===
                                                      moment(value).format(
                                                        'yyyy-MM-DD'
                                                      ) &&
                                                    schedule?.user?.id ===
                                                      shiftSchedule?.user?.id
                                                  );
                                                }
                                              )[0]?.start
                                            ).format('h:mma')}
                                            -
                                            {moment(
                                              shiftSchedules?.filter(
                                                (shiftSchedule) => {
                                                  return (
                                                    moment(
                                                      shiftSchedule?.start
                                                    ).format('yyyy-MM-DD') ===
                                                      moment(value).format(
                                                        'yyyy-MM-DD'
                                                      ) &&
                                                    schedule?.user?.id ===
                                                      shiftSchedule?.user?.id
                                                  );
                                                }
                                              )[0]?.end
                                            ).format('h:mma')}
                                          </p>
                                          {shiftSchedules?.filter(
                                            (shiftSchedule) => {
                                              return (
                                                moment(
                                                  shiftSchedule?.start
                                                ).format('yyyy-MM-DD') ===
                                                  moment(value).format(
                                                    'yyyy-MM-DD'
                                                  ) &&
                                                schedule?.user?.id ===
                                                  shiftSchedule?.user?.id
                                              );
                                            }
                                          )[0].swappable && (
                                            <img src={swapPic} alt="swap" />
                                          )}
                                        </div>
                                      ) : (
                                        <p className="text-black dark:text-white">
                                          {selectedSchedule ===
                                            `${moment(value).format(
                                              'yyyy-MM-DD'
                                            )}${schedule?.user?.id}` &&
                                          shiftSchedules?.filter(
                                            (shiftSchedule) => {
                                              return (
                                                moment(
                                                  shiftSchedule?.start
                                                ).format('yyyy-MM-DD') ===
                                                  moment(value).format(
                                                    'yyyy-MM-DD'
                                                  ) &&
                                                schedule?.user?.id ===
                                                  shiftSchedule?.user?.id
                                              );
                                            }
                                          )?.length === 0 &&
                                          cellLoading
                                            ? 'Loading....'
                                            : ''}
                                        </p>
                                      )}
                                      {selectedSchedule ===
                                        `${moment(value).format('yyyy-MM-DD')}${
                                          schedule?.user?.id
                                        }` &&
                                        shiftSchedules?.filter(
                                          (shiftSchedule) => {
                                            return (
                                              moment(
                                                shiftSchedule?.start
                                              ).format('yyyy-MM-DD') ===
                                                moment(value).format(
                                                  'yyyy-MM-DD'
                                                ) &&
                                              schedule?.user?.id ===
                                                shiftSchedule?.user?.id
                                            );
                                          }
                                        )?.length === 0 &&
                                        openDropdown && ([1, 2].includes(
                                          memberships[0]?.privilege
                                        )) && (
                                          <div 
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          className="bg-white dark:text-white dark:bg-292 z-[100] top-[80%] max-h-[300px] overflow-y-auto right-0 absolute w-max p-4 shadow-select">
                                            <div className="border-1 flex flex-row gap-1 items-center sticky top-0 bg-white dark:text-white dark:bg-A06 py-[10px] px-[5px] rounded-md dark:border-333">
                                              <CiSearch />
                                              <input
                                                className="text-[14px] dark:bg-A06 focus:outline-none "
                                                type="text"
                                                value={inputSearchBranch}
                                                placeholder="search"
                                                onChange={(e) =>
                                                  setInputSearchBranch(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="sticky top-[46px] min-h-[40px] bg-white">
                                                    <div className="py-2 mt-2">
                                                    <p
                                                          onClick={() => {
                                                            selectBranch(
                                                              {id: schedule.branchId}
                                                            );
                                                            if (
                                                              schedule?.user
                                                                ?.secondaryJobroles
                                                                ?.length < 1 ||
                                                              schedule?.user
                                                                ?.secondaryJobroles ===
                                                                undefined
                                                            ) {
                                                              const data = {id: schedule.branchId};
                                                              handleCreateShift(
                                                                data,
                                                                moment(
                                                                  value
                                                                ).format(
                                                                  'yyyy-MM-DD'
                                                                ),
                                                                schedule?.user
                                                              );
                                                            } else {
                                                              handleSelectedBranch(
                                                                schedule?.user
                                                              );
                                                            }
                                                          }}
                                                          className="hover:bg-gray-100 dark:hover:bg-color-333 text-sm py-1 px-[5px] cursor-pointer"
                                                        >{schedule?.branchName} <span className="text-[12px] text-gray-400 dark:text-gray-200">
                                                        {schedule?.city}
                                                      </span></p>
                                                    </div>
                                                    <hr className="my-2" />
                                                    </div>
                                            {/* <input 
                                        // className='border-1 m-2 sticky top-0 text-[14px] focus:outline-none px-2 py-[1px] rounded-[3px] border-grey-800'
                                        type="text" value={inputSearchBranch}
                                        onChange={(e)=>setInputSearchBranch(e.target.value)} /> */}

                                            {handleSearchBranch(
                                              inputSearchBranch
                                            ).length > 0 ? (
                                              handleSearchBranch(
                                                inputSearchBranch
                                              )?.map((branch) => (
                                                <p
                                                  onClick={() =>
                                                    handleCreateShift(
                                                      branch,
                                                      moment(value).format(
                                                        'yyyy-MM-DD'
                                                      ),
                                                      schedule?.user
                                                    )
                                                  }
                                                  className="hover:bg-gray-100 dark:hover:bg-color-333 dark:text-white text-sm py-1 px-[5px] cursor-pointer"
                                                >
                                                  {branch?.name}{' '}
                                                  <span className="text-[12px] text-gray-400 dark:text-200">
                                                    {branch?.city?.name},{' '}
                                                    {branch?.state?.name}
                                                  </span>
                                                </p>
                                              ))
                                            ) : (
                                              <p className="text-xs text-center mb-3 font-bold">
                                                No match
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      {selectedSchedule ===
                                        `${moment(value).format('yyyy-MM-DD')}${
                                          schedule?.user?.id
                                        }` &&
                                        shiftSchedules?.filter(
                                          (shiftSchedule) => {
                                            return (
                                              moment(
                                                shiftSchedule?.start
                                              ).format('yyyy-MM-DD') ===
                                                moment(value).format(
                                                  'yyyy-MM-DD'
                                                ) &&
                                              schedule?.user?.id ===
                                                shiftSchedule?.user?.id
                                            );
                                          }
                                        )?.length > 0 && openDropdown && ([1, 2].includes(
                                          memberships[0]?.privilege
                                        ) ||
                                          schedule?.user?.id ===
                                            memberships[0]?.userId) && (
                                          <div className="bg-white z-50 top-[80%] right-0 absolute drop-shadow-lg w-max">
                                            {[1,2].includes(privilege) && (<p
                                              onClick={() => {
                                                const [shiftScheduleFilter] =
                                                  shiftSchedules?.filter(
                                                    (shiftSchedule) => {
                                                      return (
                                                        moment(
                                                          shiftSchedule?.start
                                                        ).format(
                                                          'yyyy-MM-DD'
                                                        ) ===
                                                          moment(value).format(
                                                            'yyyy-MM-DD'
                                                          ) &&
                                                        schedule?.user?.id ===
                                                          shiftSchedule?.user
                                                            ?.id
                                                      );
                                                    }
                                                  );

                                                handleCancelSchedule(
                                                  shiftScheduleFilter.id
                                                );
                                              }}
                                              className="text-xs mb-3 text-171 hover:text-green-600 p-3 cursor-pointer font-bold"
                                            >
                                              Cancel shift
                                            </p>)}
                                            <p
                                              onClick={() => {
                                                const [shiftScheduleFilter] =
                                                  shiftSchedules?.filter(
                                                    (shiftSchedule) => {
                                                      return (
                                                        moment(
                                                          shiftSchedule?.start
                                                        ).format(
                                                          'yyyy-MM-DD'
                                                        ) ===
                                                          moment(value).format(
                                                            'yyyy-MM-DD'
                                                          ) &&
                                                        schedule?.user?.id ===
                                                          shiftSchedule?.user
                                                            ?.id
                                                      );
                                                    }
                                                  );

                                                handleEnableShiftSwap(
                                                  shiftScheduleFilter.id,
                                                  shiftScheduleFilter.swappable
                                                );
                                              }}
                                              className="text-xs mb-3 hover:text-green-600 p-3 cursor-pointer font-bold"
                                            >
                                              {shiftSchedules?.filter(
                                                (shiftSchedule) => {
                                                  return (
                                                    moment(
                                                      shiftSchedule?.start
                                                    ).format('yyyy-MM-DD') ===
                                                      moment(value).format(
                                                        'yyyy-MM-DD'
                                                      ) &&
                                                    schedule?.user?.id ===
                                                      shiftSchedule?.user?.id
                                                  );
                                                }
                                              )[0].swappable ? (
                                                <span className="text-171">
                                                  Cancel Swap
                                                </span>
                                              ) : (
                                                <span className='text-black hover:text-green-600 dark:text-white'>Swap</span>
                                              )}
                                            </p>
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </td>
                              );
                            })}
                        </tr>
                      )}
                    </>
                  ))}
              </div>
            ))}
          </table>
        </section>
      </ClickAwayListener>
    </div>
  );
};
