import React,{useState,} from 'react';
import FormModal from '../../../../Widgets/Animation/FormModal';
import {FillButton, OutlinedButton,} from '../../../../Widgets/FormUtils/FormUtils'
import { useDispatch } from 'react-redux';
import {setSuccessAlert} from '../../../../../Store/Company'
import successImage from '../../../../../Assets/success.png'
import { CircularProgress } from '@mui/material';
import {approveOverTime, disapproveOverTime} from '../../../../../Network/ServiceClass/OverTimeService'

export default function Confirm({openModal, setOpenModal, data, action, refresh}) {

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleApprove = () => {
    setIsLoading(true)
    approveOverTime(data.id).then(val=>{
        handleClose()
        setIsLoading(false)
        refresh()
        dispatch(setSuccessAlert({
            status:true,
            title: 'Successful',
            content: 'Your approval was successful',
            image: successImage
        }))
    }).catch(error => {
        console.log(error)
        setIsLoading(false)
    })

  }

  const handleDisapproval = () => {
    setIsLoading(true)
    disapproveOverTime(data.id).then(val=>{
        handleClose()
        setIsLoading(false)
        refresh()
        dispatch(setSuccessAlert({
            status:true,
            title: 'Successful',
            content: 'Your disapproval was successful',
            image: successImage
        }))
    }).catch(error => {
        console.log(error)
        setIsLoading(false)
    })

  }

  
 
   
  return (
    <div>
      <FormModal open={openModal} handleClose={handleClose}>

          <div className='bg-white dark:bg-A06 dark:text-white w-[316px] h-[196px] mt-[200px] m-auto rounded-sm'>
              <div className='flex flex-col items-center py-3 px-4 gap-5'>

                <p className='text-base font-semibold'>Confirm</p>

                <p className='text-xs text-[#A5A5A5] dark:text-white'>You are approve or disapprove a request </p>

                <div className='flex flex-row justify-between w-[100%] mt-6'>
                  <OutlinedButton text='Cancel' callBack={handleClose}/>
                  {!isLoading && <FillButton text='Confirm' callBack={action === 'Approve' ? handleApprove: handleDisapproval} />}
                  {isLoading && <CircularProgress/>}
                </div>
              </div>
          </div>

     
      </FormModal>
    </div>
  );
}
