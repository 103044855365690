import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { font } from './Styles/font'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
        },
    },
    borderNone: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
});


export default function TimePickerValue({ label="", value, className, handleChange = () => { }, formik, width, name, sx }) {
    const classes = useStyles();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                    label={label}
                    sx={{
                        fontSize: 14,
                        ...font,
                        width: width !== undefined ? width : '300px',
                        color: formik?.errors[name] && formik?.touched[name] ? 'red' : '#5A5A5A',
                        ...sx,
                    }}
                    placeholder="--:--"
                    timeSteps={{ minutes: 15 }}
                    defaultValue={value === undefined ? '' : value}
                    value={value === undefined ? '' : value}
                    onChange={formik === undefined ? handleChange : formik.handleChange}
                    onBlur={formik === undefined ? () => { } : formik.onBlur}
                    className={
                        formik?.errors[name] && formik?.touched[name]
                            ? classes.root
                            : className
                    }
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}