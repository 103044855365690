/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Storage } from '../../../Network/StorageClass/StorageClass';

import './ProfilePageScreen.css';
import edit from '../../../Assets/edit.png';
import AddLicenseModal from '../BranchScreen/Tabs/TeamMembers/Modal/AddLicenseModal';
import { Home } from '../../../Network/ServiceClass/Home';
import ProfileModal from './Modal/ProfileModal';
import roundimage from '../../../Assets/roundimg.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Loader } from '../../Widgets/Loader';
import DeleteLicenceModal from './Modal/DeleteLicenceModal';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import AlertDialog from '../../Widgets/SuccessDialog';
import { LicenseModal } from './Modal/License';
import InitialsGen from '../../Widgets/InitialsGen';
import { getATeammemberAsync, getLoggedInUseSkillSetAsync, getSkillSetAsync, addToMYSkillSetAsync, getAuthUrlAsync, getMsAuthUrlAsync, linkGoogleAccountAsync, getSecondaryJobsAsync, fetchJobRolesAsync, createSecondaryJobsAsync } from '../../../Store/Branches';
import { CustomPopper } from '../../Widgets/CustomPopper';
import moment from 'moment';
import { toast } from 'react-toastify';
import {BsSearch} from 'react-icons/bs'
import { BiLinkAlt} from 'react-icons/bi'
import { useLocation } from 'react-router';
import {ReactComponent as GoogleIcon} from '../../../Assets/google_logo.svg';
import {ReactComponent as OutLookIcon} from '../../../Assets/outlook.svg';
import SyncIcon from '../../../Assets/link_icon.png';
import AppLayout from '../../Widgets/AppLayout';
import {FiEdit3} from 'react-icons/fi'
import EditProfile from './Modal/EditProfile';
import { checkLinkAccounts } from '../../../utils/util';
import {ReactComponent as Placeholder} from '../../../Assets/icons/no_value_placeholder.svg'
import {ReactComponent as AddIcon} from '../../../Assets/icons/add_circle.svg'
import {ReactComponent as AddIconBlk} from '../../../Assets/icons/add_circle_blk.svg'
import {ReactComponent as MoreIcon} from '../../../Assets/icons/more_circle.svg'
import {ReactComponent as DarkAddIcon} from '../../../Assets/icons/add_circle_dark.svg'
import {ReactComponent as DarkMoreIcon} from '../../../Assets/icons/more_circle_dark.svg'
import DarkImgIcon from '../../../Assets/icons/gallery_dark.svg'



const ProfilePageScreen = () => {

  const mode = localStorage.getItem("color-theme") ?? 'light'
  const home = new Home();
  const params = useLocation()
  const [code] = params?.search?.split("&")
  const dispatch = useDispatch();
  const storage = new Storage();

  const user = storage.getUserDetails();

  const activeCompany = useSelector(
    (state) => state.company.activeCompany
  ).name;

  const manager = useSelector(
    (state) => state.branches.manager
  )?.manager;

  const userInfo = useSelector(
    (state) => state.branches
  )?.manager;

  const loggedUserSkillSet = useSelector(
    (state) => state.branches?.loggedUserSkillSet
  );

  const companySkillset = useSelector(
    (state) => state.branches?.skillSets
  )?.data;

  const jobRoles = useSelector(
    (state) => state.branches?.jobRoles
  );

  const secondaryJobroles = useSelector(
    (state) => state.branches?.secondaryJobrole
  );
  

  const [addlicense, setAddLicense] = useState(false);
  const [uploadDp, setUploadDp] = useState(false);
  const [license, setLicense] = useState([]);
  const [licenseImg, setLicenseImg] = useState('')
  const [openLicenseModal, setOpenLincenseModal] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false);


  const [data, setData] = useState(loggedUserSkillSet)
  const [inputSearch, setInputSearch] = useState('')
  const [inputJobroleSearch, setInputJobroleSearch] = useState('')

  const [deleteModal, setDeleteModal] = useState(false);
  const handleOpenLincenseModal = (value) => {
    setOpenLincenseModal(true);
    setLicenseImg(value?.document?.url);
    setSelectedId(value.id);
  };

  const handleSearchCompanySkillSet = (searchText) => {
    let filterSkillSet = companySkillset
    if (searchText.length >= 1) {
      const pattern = new RegExp(`\D*${searchText}\D*`,'i')
      filterSkillSet = companySkillset?.filter((x)=> x.name.match(pattern) || 
      x.skillSetCode.match(pattern))

    }
    return filterSkillSet
  }

  const handleSearchJobroles = (searchText) => {
    let filterJobRoles = jobRoles
    if (searchText.length >= 1) {
      const pattern = new RegExp(`\D*${searchText}\D*`,'i')
      filterJobRoles = jobRoles?.filter((x)=> x.title.match(pattern) || 
      x.symbol.match(pattern))

    }
    return filterJobRoles
  }

  const dp = useSelector((state) => state.company.dp);

  const openDp = () => {
    setUploadDp(true);
  };

  const closeDp = () => {
    setUploadDp(false);
  };

  const openLicense = () => {
    setAddLicense(true);
  };

  const closeLicense = () => {
    setAddLicense(false);
  };

  const callback = () => {
    home
      .getLicense()
      .then((value) => {
        setLicense(value.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [isdeleting, setDelete] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [openSearchSkills, setOpenSearchSkill] = useState(null)
  const [openSearchJob, setOpenSearchJob] = useState(null)
  const [loadingAddToSkill, setLoadingAddToSkill] = useState(false)

  useEffect(()=>{
    if(params?.search){
      const user = JSON.parse(localStorage.getItem("user"));
      const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
      const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
      const data = {
        teamMembershipId: member?.id,
        code: code?.replace('?code=', '')
      }
      if(code?.replace('?code=', '').startsWith("M.")){
        handleLinkToMS(data)
      } else {
        handleLinkToGoogle(data)
      }
    }
  },[params?.search])

  useEffect(() => {
    setData(loggedUserSkillSet)
    callback();
    dispatch(getATeammemberAsync(user.id))
    dispatch(getSecondaryJobsAsync())
    dispatch(getLoggedInUseSkillSetAsync())
    .then(val => {
      setData(val.payload.data)
    })
    dispatch(getSkillSetAsync())
    dispatch(fetchJobRolesAsync())
  }, []);

  const handleCreateSecJobRole = (val) => {
    setLoadingAddToSkill(true)
    const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    dispatch(createSecondaryJobsAsync({
      teamMembershipId: member?.id,
      jobRoles: [val]
    })).then((res) => {
      if(res?.meta?.requestStatus === 'fulfilled'){
        setLoadingAddToSkill(false)
        toast.success("Secondary job role  was successfully created")
        dispatch(getSecondaryJobsAsync())
      }
    })
  }

  const handleAddSkill = (skill) => {
    setLoadingAddToSkill(true)
    setOpenSearchSkill(null)
    dispatch(addToMYSkillSetAsync({skillSetId: skill.id}))
    .then(val => {
      if(val.meta.requestStatus === 'fulfilled'){
        setData([...data, {
          ...skill,
          verifiedBy: [
            {
              dateVerified: new Date()
            }
        ]
        }])
        toast.success('Skill added')

      }else if(val.meta.requestStatus === 'rejected'){

      }
      setLoadingAddToSkill(false)
    })
  }

  const handleGoogleAuth = () => {
    dispatch(getAuthUrlAsync())
  }

  const handleMSAuth = () => {
    dispatch(getMsAuthUrlAsync())
  }

  const handleLinkToMS = (data) => {
    dispatch(linkGoogleAccountAsync(data)).then((res)=>{
      if(res?.meta?.requestStatus === 'fulfilled'){
        localStorage.setItem("linked", true)
        toast.success("Account was successfully linked")
      }
    })
  }

  const handleLinkToGoogle = (data) => {
    dispatch(linkGoogleAccountAsync(data)).then((res)=>{
      if(res?.meta?.requestStatus === 'fulfilled'){
        localStorage.setItem("linked", true)
        toast.success("Account was successfully linked")
      }
    })
  }

  return (
    <section className="homepage">

      <AlertDialog />
      <AppLayout
        custom="profile page"
        subCustom=""
        title="User Profile"
        customTitle=" "
        layoutClass='dark:bg-primary'
        mainClass="bg-5F5"
        subtitle="Manage your profile details"
        RightComponent={

          <div className="mt-2 flex justify-end gap-3 items-center p-2">

            {
              checkLinkAccounts('google') ? 
          <button
            className="border border-D57 px-[20px] py-[10px] rounded flex items-center gap-3"
          >
            <GoogleIcon color="#00AD57" size={20} />
            <p>Connected</p>
          </button> :
          <button
            onClick={handleGoogleAuth}
            className="px-[20px] py-[10px] rounded border-D57 flex items-center gap-3"
          >
            <img src={SyncIcon} alt="img" height="30px" width="30px" />
            <p className="font-bold">Connect</p>
          </button>

            }

            {
              checkLinkAccounts('microsoft') ? 
          <button
            className="border border-595 px-[20px] py-[10px] rounded flex items-center gap-3"
          >
            <OutLookIcon color="#00AD57" size={20} />
            <p>Connected</p>
          </button> :
          <button
            onClick={handleMSAuth}
            className="px-[20px] py-[10px] rounded flex items-center gap-3"
          >
           <OutLookIcon color="#00AD57" size={20} />
            <p className="font-bold">Connect</p>
          </button>

            }


        </div>
        }
      >

          <section className="overview mb-40 mt-10" style={{ height: 'unset' }}>
            <div  data-tour="step8" className='w-full'>
            <div className="flex items-center gap-5 justify-between">
                <div className="flex flex-row grow rounded-xl gap-5 p-5 border-[0.2px] border-FCF bg-white dark:bg-828 dark:text-white">
                <div className="w-[120px] h-[114px] rounded-full relative">
                    {dp === undefined || dp === null ? (
                    (<InitialsGen width={70} name={`${userInfo?.firstname} ${userInfo?.lastName}`} />)
                    ) : (
                      <div className="w-[120px] h-full rounded-full" style={{ background: `url("${dp?.url}")`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                    )}

                    <img
                    className="absolute cursor-pointer right-0 bottom-3"
                    src={edit}
                    alt="user_pix"
                    onClick={openDp}
                    />
                </div>

                <div className="flex flex-col w-[100%] ">
                    <p className="text-[22px] text-A5A dark:text-white font-semibold mb-2">
                    {userInfo.firstName + ' ' + userInfo.lastName}
                    </p>
                    <p className="text-lg text-393 dark:text-FBF">{userInfo?.jobRole?.title}</p>
                    <p className="text-lg text-393 dark:text-FBF">{activeCompany}</p>

                    {/* <div className="flex flex-row w-[100%] text-sm text-[#696969] gap-10 items-center ">
                    <div className="flex flex-col gap-5">
                        <p>Gender:</p>
                        <p>Date Joined:</p>
                    </div>
                    <div className="flex flex-col gap-5">
                        <p>{userInfo.gender}</p>
                        <p>{moment(userInfo.createdOn).format("dddd MMM yyyy")}</p>
                    </div>
                    </div> */}
                </div>
                </div>
                {manager && (<div className="border-[0.2px] rounded-xl border-FCF bg-white dark:bg-828 p-5 w-[350px] h-full">
                    <p className="text-xs font-semibold text-969 mb-[14px] dark:text-white">Reporting to:</p>
                    <div className="flex justify-start items-center gap-4">
                    <div>
                   { !manager?.picture ? (<InitialsGen width={70} name={`${manager?.firstName} ${manager?.lastName}`} />)
                     : (
                      <div className="w-[80px] h-[80px] rounded-full" style={{ background: `url("${manager?.picture?.url}")`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />) }
                    </div>
                    <div>
                    <p className="font-semibold text-lg text-A5A dark:text-white">{`${manager?.firstName} ${manager?.lastName}`}</p>
                    <p className="text-393 font-semibold text-lg">Manager</p>
                    </div>
                    </div>
                </div>)}
            </div>

            <div className="flex items-center gap-5 mt-5 justify-between">
            <div className="grow rounded-xl gap-5 p-5 border-[0.2px] border-FCF bg-white dark:bg-828 dark:text-white">
              <p className="mb-7 text-xl font-semibold">Personal Information</p>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <p className="text-base text-393 dark:text-FBF">Email address</p>
                  <p className="font-medium text-lg text-color-333 dark:text-white">{userInfo?.email}</p>
                </div>
                <div>
                  <p className="text-base text-393 dark:text-FBF">Date joined</p>
                  <p className="font-medium text-lg text-color-333 dark:text-white">{moment(userInfo?.createdOn).format("dddd MMM yyyy")}</p>
                </div>
                <div>
                  <p className="text-base text-393 dark:text-FBF">Date of birth</p>
                  <p className="font-medium text-lg text-color-333 dark:text-white">{userInfo?.dob ? moment(userInfo?.dob).format("dddd MMM yyyy") : "N/A"}</p>
                </div>
                <div>
                  <p className="text-base text-393 dark:text-FBF">Gender</p>
                  <p className="font-medium text-lg text-color-333 dark:text-white">{userInfo.gender}</p>
                </div>

              </div>
            </div>
            <div className="border-[0.2px] self-stretch rounded-xl border-FCF bg-white p-5 w-[350px] dark:bg-828">
              <p className="font-semibold text-xl mb-4 text-A5A dark:text-white">Roles</p>
              <div>
                {
                  secondaryJobroles?.map((e,i) =><p className="text-lg text-393 dark:text-FBF" key={i}>- {e?.jobrole?.title}</p>)
                }
                {secondaryJobroles?.length === 0 && <div className="flex justify-center"><Placeholder /></div>}
              </div>
            </div>
            </div>
            </div>


            <div className="flex items-start gap-5 mt-5">

              <div className="Licenses w-[50%] dark:text-white">
                <div className="flex items-center justify-between mb-4">
                <p className='text-xl text-A5A font-bold dark:text-white'>Skillsets</p>
                <div>
                {[1,2].includes(JSON.parse(localStorage.getItem("activeCompany"))?.memberships[0]?.privilege) && (
                  <div>
                        
                          <ButtonWithIcon
                          Icon={AddIcon}
                          type='text' title='Add New' handleClick={(e)=>{
                            e.stopPropagation()
                            setOpenSearchSkill(openSearchSkills ? null : e.currentTarget)}}/>

                          <CustomPopper
                          openPopper={openSearchSkills}
                          handleClose={()=> setOpenSearchSkill(null)}
                          placement='bottom-end'>
                            <div  style={{boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1)', borderRadius: '10px'}}
                            className='w-[250px] bg-[white] dark:bg-292 dark:text-white max-h-[250px] px-2 py-2  overflow-y-auto'>
                              <div className='py-[4px] bg-white text-[14px] border-1 px-2 rounded-md flex gap-2 sticky top-[-8px] items-center'>
                                <BsSearch />
                                <input 
                                className='focus:outline-none '
                                type="text" placeholder='Search' onChange={(e)=> setInputSearch(e.target.value)}/>
                              </div>


                              {(handleSearchCompanySkillSet(inputSearch) !== undefined && handleSearchCompanySkillSet(inputSearch).length > 0) ? 
                              <div className='mt-2'>
                                {handleSearchCompanySkillSet(inputSearch)?.map(val => 
                                <div
                                onClick={()=>handleAddSkill(val)}
                                className='hover:bg-gray-100 font-semibold py-2 px-2 cursor-pointer flex justify-between'>
                                  <p className="text-B7B">{val.name}</p>
                                  <p>{val.skillSetCode}</p>
                                  </div>)}
                              </div> :
                              <p className='text-[14px] text-center pt-3'>Nothing here</p>
                              }
                            </div>
                          </CustomPopper>
                          </div>
                          )}
                </div>
                </div>
                <div className='w-full max-h-[400px] min-h-[200px] overflow-y-auto rounded-tl-lg rounded-tr-lg'>
                  <table >
                    <tr className='bg-color-0F5 dark:bg-444'>
                      <td className="border-[0.8px] bg-color-0F5 dark:bg-444 border-r-0 sticky top-0 text-B7B dark:text-white dark:border-0">Skillset Name</td>
                      <td className="border-[0.8px] bg-color-0F5 dark:bg-444 border-x-0 sticky top-0 text-B7B dark:text-white dark:border-0">Skillset Code</td>
                      <td className="border-[0.8px] bg-color-0F5 dark:bg-444 border-l-0 sticky top-0 text-B7B dark:text-white dark:border-0">Date Added</td>
                    </tr>

                    {!loadingAddToSkill ? <tbody>
                      {data.length === 0 ?<tr className='text-center'>
                        <td colSpan={3}>Nothing to see here</td>
                      </tr>:
                      (data?.map((skill,e) => 
                        <tr className={`bg-white dark:bg-828 ${(e === (data?.length-1)) ? "border-b-0": "border-b-1 dark:border-b-BBB"} `}>
                          <td className="text-B7B dark:text-white">{skill.name}</td>
                          <td className="text-B7B dark:text-white">{skill.skillSetCode}</td>
                          <td className="text-B7B dark:text-white">{moment(skill?.createdOn).format('dddd, MMMM YYYY')
                          }</td>
                        </tr>
                        ))
                      }
                      
                    </tbody> : 
                    <tr className='text-center'>
                    <td colSpan={3}>Loading ...</td>
                    </tr>}
                  </table>

                </div>
              </div>

              <div className="Licenses w-[50%] dark:text-white">
                <div className="flex items-center justify-between mb-4">
                <p className='font-bold text-xl'>Secondary Jobs</p>
                <div>
                <ButtonWithIcon
                Icon={AddIcon}
                type='text' title='Add New' handleClick={(e)=>{
                            e.stopPropagation()
                            setOpenSearchJob(openSearchJob ? null : e.currentTarget)}}/>

                          <CustomPopper
                          openPopper={openSearchJob}
                          handleClose={()=> setOpenSearchJob(null)}
                          placement='bottom-end'>
                            <div  style={{boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1)', borderRadius: '10px'}}
                            className='w-[250px] bg-[white] dark:bg-292 dark:text-white max-h-[250px] px-2 py-2  overflow-y-auto'>
                              
                              <div className='py-[4px] bg-white dark:bg-292 dark:border-969 text-[14px] border-1 px-2 rounded-md flex gap-2 sticky top-[-8px] items-center'>
                                <BsSearch />
                                <input 
                                className='focus:outline-none dark:bg-292'
                                type="text" placeholder='Search' onChange={(e)=> {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setInputJobroleSearch(e.target.value)
                                  }}/>

                              </div>


                              {(handleSearchJobroles(inputJobroleSearch) !== undefined && handleSearchJobroles(inputJobroleSearch).length > 0) ? 
                              <div className='mt-2'>
                                {handleSearchJobroles(inputJobroleSearch)?.map(val => 
                                <div
                                onClick={()=>{
                                  handleCreateSecJobRole(val.id)}}
                                className='hover:bg-gray-100 dark:hover:bg-color-333 font-semibold py-2 px-2 cursor-pointer flex justify-between'>
                                  <p>{val.title}</p>
                                  </div>)}
                              </div> :
                              <p className='text-[14px] text-center pt-3'>Nothing here</p>
                              }
                            </div>
                          </CustomPopper>
                </div>
                </div>
                <div className='w-full self-stretch max-h-[400px] min-h-[200px] overflow-y-auto rounded-tl-lg rounded-tr-lg'>
                  <table >
                    <tr className='sticky top-0 dark:bg-292 bg-color-0F5 dark:bg-444'>
                      <td className="text-A5A dark:bg-444 dark:border-0 dark:text-white">Name</td>
                      <td className="text-A5A dark:bg-444 dark:border-0 dark:text-white">Symbol</td>
                    </tr>

                    {!loadingAddToSkill ? <tbody>
                      {secondaryJobroles.length === 0 ?<tr className='text-center h-[300px]'>
                        <td colSpan={3}>Nothing to see here</td>
                      </tr>:
                      (secondaryJobroles?.map((role, i) => 
                        <tr className={`bg-white dark:bg-828 ${i === secondaryJobroles?.length-1 ? "border-b-0": "border-b-[0.8px]"} dark:border-b-BBB`}>
                          <td className="text-A5A dark:text-white">{role?.jobrole?.title}</td>
                          <td className="text-A5A dark:text-white">{role?.jobrole?.symbol}</td>
                        </tr>
                        ))
                      }
                    </tbody> : 
                    <tr className='text-center'>
                    <td colSpan={3}>Loading ...</td>
                    </tr>}
                  </table>

                </div>
              </div>
            </div>


            <div className="Licenses">
              <div className="flex flex-row dark:text-white mb-5 justify-between items-end">
                <p className="text-xl font-bold text-A5A dark:text-white">Licenses</p>
                <ButtonWithIcon
                  Icon={ mode === "light"? AddIconBlk : DarkAddIcon}
                  title="Add new license"
                  iconClassName='dark:text-white'
                  p="10px"
                  handleClick={openLicense}
                />
              </div>
              
              <div className="rounded-tl-lg rounded-tr-lg">
              <table>
                <thead>
                <tr className=' border-none dark:text-white bg-color-0F5 dark:bg-444'>
                  <td className=" rounded-tl-xl bg-color-0F5 dark:text-white border-r-0 dark:bg-444 dark:border-0">Licence Name</td>
                  <td className="dark:bg-444 dark:text-white ">Licence Number</td>
                  <td className="dark:bg-444 dark:text-white ">Expiring Date</td>
                  <td className="text-center dark:text-white dark:bg-444 ">Licence Image</td>
                  <td className="text-center dark:text-white dark:bg-444 ">Status</td>
                  <td className="rounded-tr-xl text-center dark:text-white dark:bg-444 ">Action</td>
                </tr>
                </thead>
                <tbody>
                {license?.map((val, i) => (
                  <tr
                  key={i}
                    className={`hover:cursor-pointer dark:text-white hover:shadow-lg bg-white dark:bg-828 ${(i === (license?.length-1)) ? "border-b-0": "border-b-1 dark:border-b-BBB"} `}
                    onClick={() => handleOpenLincenseModal(val)}
                  >
                    <td className="text-color-333 dark:text-white">{val.name}</td>
                    <td className="text-color-333 dark:text-white">{val.licenceNumber}</td>
                    <td className="text-color-333 dark:text-white">
                      {Date(val.expiration.toString())
                        .toString()
                        .substring(0, 15)}
                    </td>
                    <td className="flex justify-center">
                      <img src={mode === "light" ? roundimage: DarkImgIcon} alt="licence" />
                    </td>
                    <td>
                      <div className="flex justify-center">
                      <div className="flex items-center gap-2 w-fit  rounded-[16px] py-[4px] px-[9px]" style={{ background: val?.status ? "rgba(0, 173, 87, 0.1)": "rgba(255, 214, 51, 0.1)"}}>
                        <div className="w-[6px] h-[6px] rounded-full" style={{ background: val?.status ? "rgba(0, 173, 87, 1)": "rgba(255, 214, 51, 1)"}} />
                        <p style={{
                           color: val.status ? 'rgba(0, 173, 87, 1)' : 'rgba(255, 214, 51, 1)',
                        }}>{val.status ? 'Approved' : 'Pending' }</p>
                      </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                      <span
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleClickMenu(e);
                          setSelectedId(val.id);
                        }}
                      >
                        {mode === "light"? <MoreIcon /> : <DarkMoreIcon />}
                      </span>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>

              </table>
              </div>
            </div>
          </section>

      </AppLayout>

      <AddLicenseModal
        open={addlicense}
        handleClose={closeLicense}
        callback={callback}
      />

      <ProfileModal open={uploadDp} handleClose={closeDp} />

      <DeleteLicenceModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        id={selectedId}
        callback={callback}
      />
      <LicenseModal
        open={openLicenseModal}
        handleClose={() => setOpenLincenseModal(false)}
        id={selectedId}
        url={licenseImg}
        callback={callback}
        openDeleteModal={setDeleteModal}
      />

      {openEditProfile && <EditProfile
        open={openEditProfile}
        handleClose={()=> setOpenEditProfile(false)}
        data={userInfo}
      />}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        // style={{ left: "-4vw", top:"40px" }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}

        sx={{
          '& .MuiPaper-root': {
            backgroundColor: mode === 'light' ? 'white' : '#292929',
            color: mode === 'dark' &&  'white'
          },
         
        }}
        
      >
          {!isdeleting ? (
            <div>
              <MenuItem 
              className="menu-text"
              sx={{
                '&:hover': {
                  backgroundColor: mode === 'light' ? '#a6a6a6' : '#333333',
                  color: 'white'

                },
              }}
              >
                <span style={{ fontSize: '14px' }}>Edit</span> <span></span>
              </MenuItem>

              <MenuItem
                className="menu-text"
                sx={{
                  '&:hover': {
                    backgroundColor:  mode === 'light' ? '#a6a6a6' : '#333333',
                    color: 'white'
                  },
                }}
                onClick={() => {
                  setDeleteModal(true);
                  handleCloseMenu();
                }}
              >
                <span style={{ fontSize: '14px' }}>Delete</span> <span></span>
              </MenuItem>
            </div>
          ) : (
            <Loader />
          )}

      </Menu>
    </section>
  );
};

export default ProfilePageScreen;
