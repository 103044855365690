import {useState} from 'react'
import {Input, TextArea, FillButton, OutlinedButton, TextAndField} from '../../../Widgets/FormUtils/FormUtils'
 
import { useDispatch,} from 'react-redux';
import { updateJobGroupRolesAsync } from '../../../../Store/Branches';
import { toast } from 'react-toastify';
import { SketchPicker } from 'react-color';
import { CustomPopper } from '../../../Widgets/CustomPopper';

import JobPopper from '../../../Widgets/JobPopper';


const PopDetail = ({openPopper, setOpenPopper, data, setClikedRow, edit=false, callback=()=>{}}) => {

    

  
    const dispatch = useDispatch()
  
    const [isLoadingApprove, setIsLoadingApprove] = useState(false)
    const [isLoadingDecline, setIsLoadingDecline] = useState(false)

    const [jobGroupName, setJobName] = useState(data?.name)
    const [decsribtion, setDecsribtion] = useState(data?.desc)
    const [jobGroupSlug, setJobGroupSlug] = useState(data?.slug)
    const [hexcode, setHexcode] = useState(data?.hexcode)
    const [showPicker, setShowColorPicker] = useState(null)

    const handleColorChange = (newColor) => {
      setHexcode(newColor.hex);
    };


    const [disableField, setDisableField] = useState(!edit)
    
    const handleClose = () => {
      setOpenPopper(null);
      setClikedRow(null)
      setDisableField(true)
      return true
    };
  
    const handleApprove = () => {
      setIsLoadingApprove(true)
      const datas = {
        name: jobGroupName,
        slug: jobGroupSlug,
        desc: decsribtion,
        hexcode: hexcode,
      }

      dispatch(updateJobGroupRolesAsync({ groupId: data.id, data: datas})).then((res) => {
        if(res.meta.requestStatus === "fulfilled"){
          toast.success(`${jobGroupName} was successfully updated`)
          handleClose()
          callback()
        }
      })
     
    }
  
   
  
    


  return (
    <JobPopper 
      openPopper={openPopper} 
      handleClose={handleClose} 
      title={'Job Group Details'} 
      handleClickEdit={()=> setDisableField(!disableField)}
    >

        {!disableField && <div>
          <OutlinedButton themeColor={hexcode} text={(!showPicker && !disableField) ? 'Show color picker' : 'Hide picker'} 
          callBack={(event)=> {
            setShowColorPicker(showPicker ? null : event.currentTarget)
            }}/>

          <CustomPopper openPopper={showPicker}>
            <div className=''>
              <SketchPicker color={hexcode} onChange={handleColorChange} />
            </div>
          </CustomPopper>

        </div>}

        {/* third row */}
        {!disableField && <TextAndField space={0.5} >
          <p className='text-[#A5A5A5]'>Job group name</p>
          <Input 
            value={jobGroupName}
            disable={disableField}
            onChange={(e)=>setJobName(e.target.value)}
          />
        </TextAndField> }

        {disableField && <TextAndField space={0.2}>
          <p className='text-[#A5A5A5] '>Job group name</p>
          <p className=''>{jobGroupName}</p>
        </TextAndField>}

          {/* third four */}
          {!disableField && <TextAndField space={0.5} >
          <p className='text-[#A5A5A5]'>Slug</p>
          <Input 
            value={jobGroupSlug}
            disable={disableField}
            onChange={(e)=>setJobGroupSlug(e.target.value)}
          />
        </TextAndField>}

        {disableField && <TextAndField space={0.2}>
          <p className='text-[#A5A5A5]'>Slug</p>
          <p >{jobGroupSlug}</p>
        </TextAndField>}

        {/* third four */}
        {!disableField && <TextAndField space={0.5}>
          <p className='text-[#A5A5A5]' >Description</p>
          <TextArea 
            value={decsribtion}
            disable={disableField}
            onChange={(e)=>setDecsribtion(e.target.value)}
          />
        </TextAndField >}

        {disableField && <TextAndField space={0.2}>
          <p className='text-[#A5A5A5]'>Description</p>
          <p>{decsribtion}</p>
        </TextAndField>}
          
        


        {!disableField && <div className='flex justify-between mb-2' >
          <OutlinedButton 
            text={'Cancel'}
            callBack={handleClose}
          />

          <FillButton text={'Save'} callBack={handleApprove}/>
        </div>}
    </JobPopper>

              
  )
}

export default PopDetail



