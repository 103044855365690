import React, { useState } from 'react';
import AppLayout from '../../Widgets/AppLayout';
import AllAnnouncement from './Widget/AllAnnouncement';
import TabPanel from './Widget/Tabs/Tabs';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import { HiOutlineSpeakerWave } from 'react-icons/hi2';
import CreateAnnouncement from './Widget/CreateAnnouncement/CreateAnnouncement';
import { NewGroup } from './Widget/CreateAnnouncement/NewGroup';
import { AnimatePresence } from 'framer-motion';
import AlertDialog from '../../Widgets/SuccessDialog';
import Archived from './Widget/Archived';
import Favorite from './Widget/Favorite';
import { BiPlus } from 'react-icons/bi';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { announcementTutorial } from '../mock';
import { useSelector } from 'react-redux';

export const Announcements = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;
  const handleCloseDrawer = (value) => {
    setOpenDrawer(value);
  };

  // New group modal
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const handleOpenNewGroup = () => {
    setOpenNewGroup(true);
  };
  const handleCloseNewGroup = () => {
    setOpenNewGroup(false);
  };

  return (
    <div>
      <AlertDialog />
      <AnimatePresence>
        {openDrawer && (
          <CreateAnnouncement
            openDrawer={openDrawer}
            handleCloseDrawer={handleCloseDrawer}
            handleOpenNewGroup={handleOpenNewGroup}
          />
        )}
      </AnimatePresence>
      {openNewGroup && (
        <NewGroup
          openNewGroup={openNewGroup}
          handleCloseNewGroup={handleCloseNewGroup}
        />
      )}

      <AppLayout
        custom="company"
        subCustom="announcement"
        title="Announcements"
        customTitle=" "
        subtitle="View write ups and manage them here"
        RightComponent={<TutorialPanel items={announcementTutorial} />}
      >
        <TabPanel>
          <AllAnnouncement />
          <Archived />
          <Favorite />
          
          {[1,2].includes(privilege) && (<ButtonWithIcon
            title="New"
            type='text'
            Icon={BiPlus}
            handleClick={(e) => {
              e.stopPropagation()
              setOpenDrawer(true)
            }}
          />)}
        </TabPanel>
      </AppLayout>
    </div>
  );
};
