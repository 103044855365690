import React from 'react'
import placeholderPic1 from '../../../Assets/empty_icon.svg'

const PlaceHolderOne = ({pic=placeholderPic1, showPlaceholder=true, text='Nothing to see here for now'}) => {
  return (
    <div className='flex flex-col justify-center gap-6 min-h-[200px] items-center'>
        {showPlaceholder && (<img src={pic} alt="" />)}
        <p className='text-[12px] text-grey-600'>{text}</p>
    </div>
  )
}

export default PlaceHolderOne