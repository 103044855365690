import addOneDay from "./incrementDate";
export const getWeek = (variant) => {
    const today =  addOneDay(new Date());

    // ✅ Get the first day of the current week (Monday)
    function getFirstDayOfWeek(d) {
      // 👇️ clone date object, so we don't mutate it
      const date = new Date(d);
      const day = date.getDay(); // 👉️ get day of week
    
      // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
      const diff = date.getDate() - day + (day === 0 ? -6 : 1+variant);
    
      return new Date(date.setDate(diff));
    }
    
    const firstDay = getFirstDayOfWeek(today);
    
    // ✅ Get the last day of the current week (Sunday)
    const lastDay = new Date(firstDay);
    lastDay.setDate(lastDay.getDate() + 6);
    return {firstDay,lastDay}
}
