import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import {getInventoryLogsThunk} from '../../../Store/Inventory'
import ExportTemplate from './Widget/ExportTemplate'
import dayjs from 'dayjs'

const InventoryLog = ({branches}) => {
    const dispatch = useDispatch()


    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})



    const inventoryData = useSelector(state => state.inventory)?.logs

   

    const getInventoryLog = (id=null) => {
        dispatch(getInventoryLogsThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getInventoryLog(withdrawalBranch.value)
    },[withdrawalBranch])
    

  
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Inventory Logs'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={inventoryData.map(x => ({
                    'Date': dayjs(x?.timestamp).format('MM/DD/YYYY'),
                    'Time': dayjs(x?.timestamp).format('h:mmA'),
                    'User': `${x?.user?.firstName} ${x?.user?.lastName}`,
                    'Details': x?.details,
                    'Location': x?.branch?.name,
                    'Action': x?.action,

                }))}
                fileName={'logs'}
            />

            <div className='rounded-md border-1 dark:border-333 dark:text-white mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className=''>
                <tr className='bg-white dark:bg-444 font-bold sticky top-0'>
                    <td className='border-1 dark:border-333 border-t-0 border-l-0 text-center pl-4 py-4 rounded-tl-md w-[9%] '>Date</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center w-[5%]'>Time</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center w-[17%]'>User</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center w-[34%]'>Details</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center w-[17%]'>Location</td>
                    <td className='border-1 dark:border-333 border-t-0 border-r-0 text-center rounded-tr-md w-[16%]'>Action</td>

                </tr>

                {!isLoading ? <tbody>
                    {inventoryData?.length !== 0 ?
                        inventoryData.map(x => (
                            <tr className='border-1 dark:border-333 dark:bg-828 border-l-0 text-A5A dark:text-white font-bold'>
                                    <td className='text-center border-r-1 dark:border-333 py-4 pl-4'>{dayjs(x?.timestamp).format('MM/DD/YYYY')}</td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                       {dayjs(x?.timestamp).format('h:mmA')}
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{x?.user?.firstName} {x?.user?.lastName}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{ x?.details}</td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                        <div className='flex items-center justify-center gap-2'>
                                            <p className='w-2 h-2 rounded-full' style={{background: x?.branch?.hexcode}}/>
                                            <p>{x?.branch?.name}</p>
                                        </div>
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{x?.action}</td>

                            </tr>

                        ))
                        :<tr>
                            <td colSpan={7} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                    }
                </tbody>:
                <tr>
                    <td colSpan={7} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                </tr>
                }
                
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default InventoryLog