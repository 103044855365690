import React,{useState} from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import {OutlinedButton, FillButton} from '../../../../../../Widgets/FormUtils/FormUtils'
import FormModal from '../../../../../../Widgets/Animation/FormModal';
import { CircularProgress } from '@mui/material';
import successImage from '../../../../../../../Assets/success.png';
import { useDispatch,} from 'react-redux';
import {setSuccessAlert} from '../../../../../../../Store/Company'
import {requestOverTime} from '../../../../../../../Network/ServiceClass/OverTimeService'

const Alert = ({openModal, handleClose, data}) => {

  const dispatch = useDispatch()

  const anim = {
    hidden: {opacity: 0},
    vissible: {
      opacity: 1,
      transition: {
        ease: 'easeIn', duration: 1
      }
    },
    exit: {
      opacity: 0,
      transition: {
        ease: 'easeOut', duration: 0.5
      }
    }
  }
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)

    const value = {
      slotId: data.slotId,
      date: data.date
    }

    requestOverTime(value).then(val => {
      setIsLoading(false)
      dispatch(
        setSuccessAlert({
          status: true,
          title: 'Successful',
          content: 'Over time booked successfully',
          image: successImage,
        })
      );
      handleClose()

    }).catch(err => setIsLoading(false))


  }

  return (
    <FormModal open={openModal} handleClose={handleClose}>
      <AnimatePresence>
          <motion.div variants={anim} exit='exit' className='bg-white w-[316px] h-[196px] mt-[200px] m-auto rounded-sm'>
              <div className='flex flex-col items-center py-3 px-4 gap-5'>

                <p className='text-base font-semibold'>Alert</p>

                <p className='text-xs text-[#A5A5A5]'>You are about to exceed your work hours would you like to request for approval ?</p>

                <div className='flex flex-row justify-between w-[100%] mt-6'>
                  <OutlinedButton text='Cancel' callBack={handleClose}/>
                  {!isLoading && <FillButton text='Request'  callBack={handleSubmit}/>}
                  {isLoading && <CircularProgress/>}
                </div>
              </div>
          </motion.div>
        </AnimatePresence>
    </FormModal>
  )
}

export default Alert