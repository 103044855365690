import React, { useState, useEffect } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import timeRange from '../../../../../utils/timeData';
import './NavigateTime.css';
import { useDispatch } from 'react-redux';
import { setDailyTime } from '../../../../../Store/Shift';
import { Box } from '@mui/material';

const NavigateTime = ({ timeDiff = 6, startTime = 0 }) => {
  const dispatch = useDispatch();

  const [startCount, setStartCount] = useState(startTime);
  const [endCount, setEndCount] = useState(timeDiff);

  useEffect(()=>{
    dispatch(dispatch(setDailyTime({ to: endCount, from: startCount })))
  },[])

  const handleLeftClick = () => {
    let start, end;
    if (timeRange[startCount - timeDiff] === undefined) {
      setStartCount(startCount);
      start = startCount;
    } else {
      setStartCount(startCount - timeDiff);
      start = startCount - timeDiff;
    }
    if (timeRange[startCount - timeDiff] === undefined) {
      setEndCount(endCount);
      end = endCount;
    } else {
      setEndCount(startCount);
      end = startCount;
    }

    dispatch(setDailyTime({ to: end, from: start }));
  };
  const handleRightClick = () => {
    let start, end;

    if (timeRange[endCount] === undefined) {
      setEndCount(endCount);
      end = endCount;
    } else {
      setStartCount(endCount);
      start = endCount;
    }
    if (timeRange[endCount + timeDiff] === undefined) {
      setEndCount(startTime);
      end = startTime;
    } else {
      setEndCount(endCount + timeDiff);
      end = endCount + timeDiff;
    }
    dispatch(setDailyTime({ to: end, from: start }));
  };

  const buttonStyles = {
    padding: '0.55rem 1rem',
    cursor: 'pointer',
    height: '100%',
  };

  return (
    <div className="border-1 text-[#6d6e72] h-10 flex flex-row items-center navigate-time">
      <Box
        sx={{ ...buttonStyles }}
        className="border-r mr-2"
        onClick={handleLeftClick}
      >
        <IoIosArrowBack />
      </Box>
      <Box
        sx={{
          padding: '0.55rem 1.2rem',
          color: 'black',
          height: '100%',
          borderLeft: 'none',
          borderRight: 'none',
          width: 'max-content'
        }}
      >{`${timeRange[startCount]} - ${timeRange[endCount]}`}</Box>

      <Box sx={{ ...buttonStyles }} className="border-l" onClick={handleRightClick}>
        <IoIosArrowForward />
      </Box>
    </div>
  );
};

export default NavigateTime;
