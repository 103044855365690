import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const createAssigneeShiftslot = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/shifts/slots-assignees`,data, storage.getConfig());
    return {data: response.data, status:  response.status};
    
  } catch (error) {
    return {data: error.response.data, status:  error.response.status}
  }
}

export const cancelSchedule = async ({id, privileges}) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"))
    const adminUrl = privileges.includes(user.id) ? `admin/${id}/cancel` : `${id}/cancel`
    const response = await axios.post(`${conn_url}/api/v1/shifts/${adminUrl}`,undefined, storage.getConfig());
    return {data: response.data, status:  response.status};
    
  } catch (error) {
    return error;
  }
}

export const makeReadyForSwap = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/shift-swap`, data, storage.getConfig());
    return {data: response.data, status:  response.status};
  } catch (error) {
    return error;
  }
}

export const createShiftTemplate = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/shifts/templates`,data, storage.getConfig());
    return {data: response.data, status: response.status}
    
  } catch (error) {
    return error;
  }
}

export const updateShiftTemplate = async ({data, shiftTempId}) => {
  try {
    const response = await axios.put(`${conn_url}/api/v1/update-shift-templates/${shiftTempId}`,data, storage.getConfig());
    return response.data;
    
  } catch (error) {
    return error;
  }
}

export const deleteShiftTemplate = async (id) => {
  try {
    const response = await axios.delete(`${conn_url}/api/v1/shifts/templates/${id}`, storage.getConfig());
    return {data: response.data, status: response.status};
    
  } catch (error) {
    return error;
  }
}

export const getShiftTemplate = async () => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/shift-templates`, storage.getConfig());
    return response.data;
    
  } catch (error) {
    return error;
  }
}

export const getShiftSchedule = async (data) => {
  let queryString = '';
  Object.entries(data)?.forEach(x => {
    if(x[1]){
      queryString += `${x[0]}=${x[1]}&`
    }
  })
  if(queryString.endsWith("&")){
    queryString = queryString.slice(0, queryString.length-1)
  }
  try {
    const response = await axios.get(`${conn_url}/api/v1/shifts/schedule?${queryString}`, storage.getConfig());
    return response.data;
    
  } catch (error) {
    return error;
  }
}


export const getSwappableShift = async (page=1) => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/shift-swap?page=${page}`, storage.getConfig());
    return response.data;
    
  } catch (error) {
    return error;
  }
}


export const createJobGroup = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/create-role-tag`,data, storage.getConfig());
    return response.data;
    
  } catch (error) {
    throw Error(error)
  }
}


export const getAllJobGroup = async (data) => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/get-role-tag`, storage.getConfig());
    return response.data || [];
    
  } catch (error) {
    throw Error(error)
  }
}

export const fetchClockInClockOut = async (data) => {
  try {

    let queryString = '';
    Object.keys(data).map((x) => {
      if(data[x]){
        if(queryString.startsWith('?')){
          queryString += `&${x}=${data[x]}`
        } else {
          queryString = `?${x}=${data[x]}`
        }
      }
      return x;
    })
    const response = await axios.get(conn_url+`/api/v1/shifts/schedule/clock-ins${queryString}`, storage.getConfig());
    return response.data
  } catch (error) {
    throw Error(error)
  }


}

export const adminClockInOutUser = async ({data, id}) => {
  try {
      const response = await axios.post(`${conn_url}/api/v1/shifts/${id}/admin-clock-in-or-out`, data, storage.getConfig());
      return {data: response.data, status:  response.status};
  } catch(err) {
      return {data: err.response.data, status:  err.response.status};
  }
}

export const reassigningSchedule = async ({data, id}) => {
  try {
      const response = await axios.put(`${conn_url}/api/v1/shifts/reassign-limbo-shift/${id}`, data, storage.getConfig());
      return {data: response.data, status:  response.status};
  } catch(err) {
    throw Error(err?.response?.data?.message);
  }
}

export const getLimboSchedules = async ({from, to}) => {
  try {
      const response = await axios.get(`${conn_url}/api/v1/shifts/get-limbo-shifts?from=${from}&to=${to}`, storage.getConfig());
      return {data: response.data, status:  response.status};
  } catch(err) {
      throw Error(err);
  }
}