import React, { useEffect, useState } from 'react';
import FormModal from '../../../../Widgets/Animation/FormModal';
import { Stack, CircularProgress } from '@mui/material';
import * as Yup from 'yup';
import {
  Field,
  FieldSelect,
  FillButton,
  Text,
  TextAndField,
} from '../../../../Widgets/FormUtils/FormUtils';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import { InputAdornment } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '485px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

function CreateSupplierModal({
  openSupplierModal,
  handleCloseSupplierModal,
  isLoading = false,
  handleSubmitItem,
  inventoryItemsId = '',
}) {
  const [addAnother, setCheck] = useState(false);

  const formik = useFormik({
    initialValues: {
      supplierId: '',
      manufacturerId: '',
      manufacturingNumber: '',
      packageDescId: '',
      itemsPkg: 0,
      manufacturerPkgCost: 0,
      inventoryItemsId: '',
    },
    validationSchema: Yup.object().shape({
      supplierId: Yup.string().required('This is required'),
      manufacturerId: Yup.string().required('This is required'),
      manufacturerPkgCost: Yup.number().min(1).required('This is required'),
      itemsPkg: Yup.number().min(1).required('This is required'),
      packageDescId: Yup.string().required('This is required'),
      manufacturingNumber: Yup.string().required('This is required'),
    }),
    onSubmit: (values) => {
      setTimeout(() => {
        handleSubmitItem(
          {
            ...values,
            manufacturerPkgCost: values.manufacturerPkgCost * 100,
            inventoryItemsId,
          },
          formik,
          addAnother
        );
      }, 400);
    },
  });

  const suppliers = useSelector((state) => state.inventory)?.suppliers;
  const manufacturers = useSelector((state) => state.inventory)?.manufacturers;
  const measurementUnits = useSelector(
    (state) => state.inventory
  )?.measurementUnits;


  return (
    <FormModal open={openSupplierModal} handleClose={handleCloseSupplierModal}>
      <Stack direction="column" alignItems="center" sx={style} gap={2}>
        <div className="relative w-full mb-[29px]">
          <p className=" text-2xl text-color-424 font-bold text-center">
            New Supplier
          </p>
          <IoClose
            onClick={handleCloseSupplierModal}
            size={20}
            className="absolute cursor-pointer right-0 top-[30%]"
          />
        </div>

        <TextAndField space={1}>
          <Text text={'Primary Supplier'} />
          <FieldSelect
            list={[{ name: 'Select', id: '' }, ...suppliers]?.map((x) => ({
              name: x?.name,
              id: x?.id,
            }))}
            value={formik.values.supplierId}
            formik={formik}
            name="supplierId"
            width="100%"
            otherProps={{ fontSize: '14px' }}
          />
        </TextAndField>

        <TextAndField space={1}>
          <Text text={'Manufacturers'} />
          <FieldSelect
            list={[{ name: 'Select', id: '' }, ...manufacturers]?.map((x) => ({
              name: x?.name,
              id: x?.id,
            }))}
            value={formik.values.manufacturerId}
            formik={formik}
            name="manufacturerId"
            width="100%"
            otherProps={{ fontSize: '14px' }}
          />
        </TextAndField>

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          gap={4}
        >
          <TextAndField>
            <Text text="Manufacturer Number" />
            <Field
              value={formik.values.manufacturingNumber}
              formik={formik}
              name="manufacturingNumber"
              width="100%"
            />
          </TextAndField>

          <TextAndField>
            <Text text="Package Description" />
            <FieldSelect
              list={[{ name: 'Select', id: '' }, ...measurementUnits]?.map(
                (x) => ({ name: x?.name, id: x?.id })
              )}
              value={formik.values.packageDescId}
              formik={formik}
              name="packageDescId"
              width="100%"
              otherProps={{ fontSize: '14px' }}
            />
          </TextAndField>
        </Stack>

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          gap={4}
        >
          <TextAndField>
            <Text text="Items/pkg" />
            <Field
              value={formik.values.itemsPkg}
              formik={formik}
              placeholder="Items/pkg"
              type="number"
              name="itemsPkg"
              width="100%"
            />
          </TextAndField>

          <TextAndField>
            <Text text="Package Cost ($)" />
            <Field
              value={
                formik.values.manufacturerPkgCost
              }
              formik={formik}
              type="number"
              name="manufacturerPkgCost"
              width="100%"
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </TextAndField>

        </Stack>
          <div className={`self-start flex gap-2 ${formik.values.manufacturerPkgCost !== 0 && formik.values.itemsPkg !==0 ? '' :'hidden'}`}>
            <p>Item Cost:</p>
            <p>${formik.values.manufacturerPkgCost/formik.values.itemsPkg}</p>
          </div>

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          mt={6}
          gap={4}
        >
          <div className="flex items-center gap-3 w-full">
            <input
              checked={addAnother}
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
              type="checkbox"
            />
            <p className="font-medium text-969">Add another supplier</p>
          </div>
          {!isLoading && (
            <FillButton
              width="100%"
              text="Add"
              callBack={formik.handleSubmit}
            />
          )}

          {isLoading && <CircularProgress />}
        </Stack>
      </Stack>
    </FormModal>
  );
}

export default CreateSupplierModal;
