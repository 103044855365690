import React from 'react';
import { useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import './calendar.css';
import PlaceHolderOne from '../../../Widgets/placeholders/PlaceHolderOne';
import placeholderPic2 from '../../../../Assets/placholders/placeholder2.png';
import Row4 from '../Widget/Row4/Row4';

const Col2 = () => {
  const upcomingShiftState = useSelector(
    (state) => state.dashboard.upcomingShiftNonAdmin
  );
  const startTime = new Date(upcomingShiftState?.shift?.start).getHours();
  const endTime = new Date(upcomingShiftState?.shift?.end).getHours();
  const date = new Date(upcomingShiftState?.shift?.end)
    .toDateString()
    .split(' ')
    .splice(0, 4)
    .join(' ');

  return (
    <div className='w-full'>
      <div className="border-1 flex flex-col gap-10 rounded-lg p-3 shadow-shift-shadow">
        {/* <Calendar className='w-[500px]'/> */}
        <div className="w-full">
          <Row4 />
        </div>

        <div className="flex flex-col gap-3">
          <p className="text-base font-medium">Upcoming shift</p>

          {upcomingShiftState !== null && upcomingShiftState.shift !== null && (
            <div className="flex flex-col bg-[#F1F7EB] rounded-md p-2">
              <div className="text-[#264A01] flex flex-row justify-between text-xs">
                <p>{date}</p>
                <p>
                  {startTime} - {endTime}
                </p>
              </div>
              <p className="text-[#696969] text-xs">
                {upcomingShiftState?.shift.branch.name} :{' '}
                {upcomingShiftState?.shift.branch.address}
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-xs text-[#696969]">Teammates on shift</p>
          {upcomingShiftState?.teamMates?.length > 0 && (
            <div className="flex flex-col gap-2 max-h-[300px] overflow-y-auto">
              {upcomingShiftState?.teamMates?.map((val) => {
                return (
                  <div className="border-1 p-2 flex rounded-md items-center gap-3">
                    {val?.picture === null && (
                      <p className="w-[30px] h-[30px] bg-[#F1F7EB]  border-1 rounded-full"></p>
                    )}
                    {val?.picture !== null && (
                      <img
                        className="w-[30px] h-[30px] bg-[#F1F7EB]  border-1 rounded-full"
                        src={val.picture?.url}
                        alt=""
                      />
                    )}
                    <p className="text-sm">
                      {val.firstName} {val.lastName}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {upcomingShiftState?.teamMates?.length <= 0 && (
            <PlaceHolderOne showPlaceholder={false} pic={placeholderPic2} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Col2;
