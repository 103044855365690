import { useState, useEffect } from 'react';
import {AiOutlineCaretDown} from 'react-icons/ai'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as CheckIcon } from '../../Assets/check.svg';
import { IoClose } from 'react-icons/io5';

const CustomDropdownMultipleSelect = ({
    optionClass, 
    className, 
    dropdownTitleClass, 
    data, 
    showIcon=false,
    value=[],
    selectedItems =()=>{}}) => {

    const [openDropdown, setOpenDropdown] = useState(false)
    const [selected, setSelected] = useState(value)

    const handleOpenDropDown = () => setOpenDropdown(!openDropdown)
    const handleCloseDropdown = () => setOpenDropdown(false)

    useEffect(()=>{selectedItems(selected)},[selected])
    useEffect(()=>{setSelected(value)},[value])

    const handleSelectedOption = (item) => {
        const isSelected = selected.find(val => val.name === item.name)
        if (isSelected) {
            setSelected(selected.filter(val => val.name !== item.name))
        }else {
            setSelected([...selected, item])
            
        }
    }

    const checkSelected = (item) => {
        const isSelected = selected.find(val => val.name === item.name)
        if (isSelected) return true
        else return false
    }

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
        <div className="relative ">
            <div onClick={handleOpenDropDown}
                className={`flex justify-between hover:border-gray-700 hover:dark:border-6E6 cursor-pointer items-center border-1 border-6E6 dark:border-333 rounded-[5px] py-[6px] px-[10px] ${className}`}>
                <p className={`text-grey-600 cursor-pointer text-[13px] w-[80%] font-medium ${dropdownTitleClass}`}>
                    {selected.length <= 1 ? (selected.length === 0 ? 'Select location' : selected[0].label) : `${selected.length} items selected`}
                </p>
                <AiOutlineCaretDown className='text-[12px] text-gray-500'/>
            </div>

            {openDropdown && (
            <div className={`shadow-select bg-white dark:bg-292 dark:text-white absolute w-full mt-0 max-h-[200px] overflow-y-auto ${optionClass}`}>
                {data.map((item) => (
                <div
                    onClick={() => handleSelectedOption(item)}
                    className={`px-[10px] py-[7px] cursor-pointer hover:bg-5A5 dark:hover:bg-color-333 hover:text-white flex items-center justify-between`}
                >
                    <div className='flex items-center gap-2'>
                  {item?.hexcode && <div className="w-[10px] h-[10px] rounded-full" style={{ backgroundColor: item?.hexcode }} />}
                    <p className="text-[14px] cursor-pointer">{item.label}</p>
                  </div>
                    {/* {showIcon && <CheckIcon />} */}
                    {checkSelected(item) && <CheckIcon />}
                </div>
                ))}
            </div>
            )}

        </div>
    </ClickAwayListener>
  )
}

export default CustomDropdownMultipleSelect
