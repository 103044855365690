import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const getRotations = async (data) => {
  try {
    let queryString = '';
    Object.keys(data).map((x) => {
      if(data[x]){
        if(queryString.startsWith('?')){
          queryString += `&${x}=${data[x]}`
        } else {
          queryString = `?${x}=${data[x]}`
        }
      }
    })

    const response = await axios.get(`${conn_url}/api/v1/shift-rotations${queryString}`, storage.getConfig());
    return response.data;
    
  } catch (error) {
    throw Error(error)
  }
}

export const copyRotations = async (data) => {
  try {

    const response = await axios.post(`${conn_url}/api/v1/shift-rotations/copy`, data, storage.getConfig());
    return response.data;
    
  } catch (error) {
    throw Error(error)
  }
}

export const filterRotation = async (data) => {
  try {

    let queryString = '';
    Object.keys(data).map((x) => {
      if(data[x]){
        if(queryString.startsWith('?')){
          queryString += `&${x}=${data[x]}`
        } else {
          queryString = `?${x}=${data[x]}`
        }
      }
      return x;
    })

    const response = await axios.get(`${conn_url}/api/v1/certain-shift-rotations${queryString}`, storage.getConfig());
    return response.data;
    
  } catch (error) {
    throw Error(error)
  }
}

export const backupRotation = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/shift-rotations/backup`, data, storage.getConfig());

    return { 
      data: response.data,
      statusCode: response.status
    }    
  } catch (error) {
    throw Error(error)
  }
}

export const getBackupRotation = async ({rotationNumber, rotationYear}) => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/shift-rotations/backups?rotationNumber=${rotationNumber}&rotationYear=${rotationYear}`, storage.getConfig());

    return { 
      data: response.data,
      statusCode: response.status
    }    
  } catch (error) {
    throw Error(error)
  }
}


export const restoreBackupRotation = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/shift-rotations/restore`, data, storage.getConfig());

    return {data: response.data, status:  response.status};
  } catch (error) {
    return error?.response
  }
}

export const setRotationStatus = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/rotation-statuses`, data, storage.getConfig());

    return {data: response.data, status:  response.status};
  } catch (error) {
    return error?.response
  }
}

export const getRotationStatus = async (data) => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/rotation-status?rotationNumber=${data?.rotationNumber}&rotationYear=${data?.rotationYear}`, storage.getConfig());

    return response.data;   
  } catch (error) {
    return error?.response
  }
}