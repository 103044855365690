import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { newGetAnnouncements, getAnnouncementTags, getAllAnnouncements } from '../Network/ServiceClass/announcementService';

export const getAllAnouncementAsync = createAsyncThunk('announcement/getAllAnnouncement', newGetAnnouncements);

export const getAllAnouncementTagAsync = createAsyncThunk('announcement/getAllAnnouncementTag', getAnnouncementTags);

export const getDashboardAnnouncmentThunk = createAsyncThunk('announcement/getAllAnnouncements', getAllAnnouncements);

const initialState = {
    all: [],
    favorite: [],
    archive: [],
    loading: false,
    announcementTags: [],
    announcements: []
}

const AnnouncementSlice = createSlice({
    name: 'announcement',
    initialState: initialState,
    extraReducers: {
        [getAllAnouncementTagAsync.pending]: (state) => {
            state.loading = true;
        },
        [getAllAnouncementTagAsync.fulfilled]: (state, action) => {
            state.loading = true;
            state.announcementTags = action.payload
        },
        [getAllAnouncementTagAsync.rejected]: (state) => {
            state.loading = true;
        },
        [getAllAnouncementAsync.pending]: (state) => {
            state.loading = true;
        },
        [getAllAnouncementAsync.fulfilled]: (state, action) => {
            state.loading = false;
            state.all = action.payload.data
        },
        [getAllAnouncementAsync.rejected]: (state) => {
            state.loading = false;
        },
        [getDashboardAnnouncmentThunk.pending]: (state) => {
            state.loading = true;
        },
        [getDashboardAnnouncmentThunk.fulfilled]: (state, action) => {
            state.loading = false;
            state.announcements = action.payload.data
        },
        [getDashboardAnnouncmentThunk.rejected]: (state) => {
            state.loading = false;
        },
    },
    reducers: {
        setAll: (state, action)=>{
            state.all = action.payload;
        },
        setFavorite: (state, action)=>{
            state.favorite = action.payload;
        }, 
        setArchive: (state, action)=>{
            state.archive = action.payload;
        },
               
    }

});

export const {
    setAll,
    setFavorite,
    setArchive
   } = AnnouncementSlice.actions;
export default AnnouncementSlice.reducer;