export const weekDaysMock = [
  {
    name: 'Sunday',
    key: 0,
    value: 0
  },
  {
    name: 'Monday',
    key: 1,
    value: 0
  },
  {
    name: 'Tuesday',
    key: 2,
    value: 0
  },
  {
    name: 'Wednesday',
    key: 3,
    value: 0
  },
  {
    name: 'Thursday',
    key: 4,
    value: 0
  },
  {
    name: 'Friday',
    key: 5,
    value: 0
  },
  {
    name: 'Saturday',
    key: 6,
    value: 0
  },
]