import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { Shift } from '../../../../../../Network/ServiceClass/Shift'
import { CircularProgress, Popper } from '@mui/material';

import { Box, Typography, Button } from '@mui/material'
import { MdOutlineCancel } from 'react-icons/md'
import dot from '../../../../../../Assets/dot.png'
import Slect from 'react-select';
import checkPmOrAm from '../../../../../../utils/checkPmOrAm';
import { font } from '../../../../../Widgets/Styles/font'
import Menu from '@mui/material/Menu';
import { IoClose } from 'react-icons/io5'
import { TextAndField, Text, OutlinedButton, FillButton } from '../../../../../Widgets/FormUtils/FormUtils'
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getSwappableShiftAsync } from '../../../../../../Store/Shift';
import dayjs from 'dayjs';
import CustomDropdown from '../../../../../Widgets/CustomDropdown';

const shift = new Shift();

export default function MakeOfferView({ openMakeOfferViewModal, handleCloseMakeOffer, loggedInUserDetails, data }) {
  const dispatch = useDispatch()
  const [singleUserShift, setSingleUserShift] = useState([])
  const [selectedShift, setSelectedShift] = useState(null)
  const [isSelected, setIsSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [offersData, setOffersData] = useState([])

  const open = Boolean(openMakeOfferViewModal);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    // get all loggedIn user shift
    dispatch(getSwappableShiftAsync())
      .then(val => {
        setOffersData(val.payload?.data)
      })
  }, [])

  const handleCloseModal = () => {
    handleCloseMakeOffer()
  }

  const handleSwap = () => {

    setIsLoading(true)
    const datas = {
      "requesteeShiftId": data.id,
      "requesterShiftId": selectedShift.value
    }


    shift.makeSwapRequest(datas)
      .then((val) => {
        setIsLoading(false)
        if ([400, 404, 401, 500].includes(val.status)) {
          setIsError(true)
          setErrorMessage(val.data?.message)
          toast.error(val.data?.message)
        } else {
          handleCloseModal()
          toast.success('Your offer has been sent, give your co worker sometime to respond')
        }
      })
  }
  const mainAnim = {
    hidden: { opacity: 0 },
    vissible: {
      opacity: 1,
      transition: { ease: 'easeIn', duration: 1 }
    },
    exit: {
      opacity: 0,
      transition: { ease: 'easeIn', duration: 1 }
    }
  }


  return (
    <Popper
      id={id}
      open={open}
      anchorEl={openMakeOfferViewModal}
      placement={'bottom-start'}>

      <motion.div
        variants={mainAnim}
        initial={'hidden'}
        animate={'vissible'}
        exit={'exit'}
        className='flex flex-col w-[310px] h-[466px] px-[1.5rem] bg-[white] dark:bg-292 dark:text-white rounded-md'
        style={{ boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)' }}>

        {/* first row */}
        <Box
          className='dark:bg-color-333 bg-white'
          sx={{
            alignSelf: 'flex-end',
            marginTop: '-18px',
            marginRight: '6px',
            borderRadius: '20px',
            padding: '2px 4px',
            position: 'fixed',
            cursor: 'pointer',
          }} onClick={handleCloseModal}>
          <IoClose className='mt-[0.2rem] text-[#242424] text-[23px] dark:text-white' />

        </Box>

        <Box sx={{ display: 'flex', marginTop: '20px', flexDirection: 'column', gap: '1rem' }}>
          {/* second row */}
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>

            <Box sx={{ marginTop: '0.4rem', marginRight: '0.2rem' }}>
              <img src={dot} alt="" />
            </Box>

            <p
              className='text-[18px] text-[#242424] dark:text-white mr-[1rem] '>
              Swap Shift
            </p>
          </Box>

          {/* Third row */}
          <p className='text-[14px] text-[#696969] dark:text-white'>What shift would you like to offer for this swap</p>

          {/* Fouth row */}
          <TextAndField space={0.5}>
            <Text text={'Swap offer'} />

            <CustomDropdown
              data={offersData.map(val => (
                {
                  label: `${dayjs(val.start).format('ha DD MMM YYYY')} - ${dayjs(val.end).format('ha DD MMM YYYY')}`,
                  value: val?.id,
                  smallLabel: val.branch?.name
                }
              ))}
              showIcon={false}
              className="w-full rounded h-[43px]"
              value={selectedShift}
              dropdownTitleClass="text-A5A"
              optionClass="z-[1000] min-h-[100px] max-h-[200px] overflow-scroll"
              labelClass="text-[12px]"
              trimtext
              trimLength={23}
              onChangeOption={(val) => {
                setSelectedShift(val)
                setIsSelected(true)
              }}
            />

          </TextAndField>

          {isError &&
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '0.2rem',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              padding: '0.5rem'
            }}>
              <Typography sx={{ ...font, fontSize: '12px', color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
              <MdOutlineCancel style={{ color: 'red', fontSize: '23px', cursor: 'pointer' }} onClick={() => setIsError(false)} />
            </Box>}

        </Box>

        {/* Fifth row */}
        <Box sx={{ marginTop: '4rem', display: 'flex', justifyContent: 'space-between', marginTop: '12rem' }}>
          <OutlinedButton text={'Cancel'} callBack={handleCloseModal} />
          {!isLoading && <FillButton text={'Swap'} disable={!isSelected} callBack={handleSwap} />}
          {isLoading && <CircularProgress sx={{ marginLeft: '1.2rem', marginRight: '1.2rem' }} />}
        </Box>
      </motion.div>
    </Popper>

  );
}