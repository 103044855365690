import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { font } from '../../../../../../Widgets/Styles/font';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Loader } from '../../../../../../Widgets/Loader';
import FormModal from '../../../../../../Widgets/Animation/FormModal';
import {
  TextAndField,
  Field,
  Text,
} from '../../../../../../Widgets/FormUtils/FormUtils';
import { backupRotationASync } from '../../../../../../../Store/Rotation';
import { toast } from 'react-toastify';

export default function BackupRotation({ open, handleClose, store, selectedYear, selectedRotation }) {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <div>
      <FormModal open={open} handleClose={handleClose}>
        <Formik
          initialValues={{
            backupName: '',
            rotationNumber: selectedRotation?.value,
            rotationYear: Number(selectedYear?.value),
          }}
          validationSchema={Yup.object({
            backupName: Yup.string().required('Required'),
          })}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              setSubmitting(false)
              dispatch(backupRotationASync({...values, rotationYear: Number(values.rotationYear)})).then((error) => {
                toast.success('backup rotation was sucessfully created')
                handleCloseModal();
              });
            }, 400);
          }}
        >
          {({
            errors,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            values,
            ...rest
          }) => (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '485px',
                minHeight: '300px',
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                borderRadius: '5px',
                overflowY: 'auto',
                overflowX: 'hidden',
                py: '53px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: '44px',
                ...font,
              }}
            >
              <div className="w-full">
                <Typography
                  sx={{
                    fontSize: '24px',
                    color: '#242424',
                    fontWeight: '700',
                    marginTop: '2rem',
                    textAlign: 'center',
                  }}
                >
                  Backup Rotation {values.rotationNumber}
                </Typography>

                <div className="mt-[35px] w-full">
                  <div className="mb-6">
                    <TextAndField>
                      <Text text={'Rotation name'} />
                      <Field
                        text={'Rotation name'}
                        type="text"
                        sx={{
                          height: '34px',
                          outline: 'none',
                          fontSize: '14px',
                          color: 'grey',
                        }}
                        value={values.backupName}
                        formik={{
                          ...rest,
                          errors,
                          setFieldValue,
                          handleSubmit,
                          values,
                        }}
                        name="backupName"
                      />
                    </TextAndField>
                  </div>
                  {/* <div className="mb-4">
                    <Typography
                      variant="span"
                      className="text-xs font-medium mb-2"
                    >
                      Rotation year
                    </Typography>
                    <UserDropdown
                      data={year}
                      onChangeOption={(e) => {
                        setFieldValue('rotationYear', e.value);
                      }}
                      showAvatar={false}
                      className="h-[51px]"
                      defaultLabel={{ label: 'Select year', value: 'select' }}
                      headerClass="text-14 p-0"
                    />
                    {errors?.rotationYear ? (
                      <p className="text-red-500 text-xs">
                        {errors?.rotationYear}
                      </p>
                    ) : (
                      <p className="text-xs text-white">required</p>
                    )}
                  </div> */}
                  {/* <div className="flex flex-col justify-end">
                    <Typography
                      variant="span"
                      className="text-xs font-medium mb-2"
                    >
                      Rotation Number
                    </Typography>
                    <UserDropdown
                      data={Array.from(Array(store.rotationNumber).keys()).map(
                        (rotationNum) => ({
                          label: `${rotationNum + 1}`,
                          value: rotationNum + 1,
                          color: '#242424',
                        })
                      )}
                      onChangeOption={(e) => {
                        setFieldValue('rotationNumber', e.value);
                      }}
                      showAvatar={false}
                      className="h-[51px]"
                      defaultLabel={{
                        label: 'Select Rotation Number',
                        value: 'select',
                      }}
                      headerClass="text-14 p-0"
                    />
                     {errors?.rotationNumber ? (
                      <p className="text-red-500 text-xs">
                        {errors?.rotationNumber}
                      </p>
                    ) : (
                      <p className="text-xs text-white">required</p>
                    )}
                  </div> */}
                </div>
              </div>

              {(!store.loading) ? (
                <Box
                  className="mt-12"
                  sx={{
                    display: 'flex',
                    gap: '10rem',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      borderColor: 'black',
                      color: 'black',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'black',
                      color: 'white',
                    }}
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                </Box>
              ) : (
                <Loader />
              )}
            </Box>
          )}
        </Formik>
      </FormModal>
    </div>
  );
}
