import {useState} from 'react'

import CustomModal from '../../../../Widgets/CustomModal'
import {BsTruck} from 'react-icons/bs'
import {FcApproval} from 'react-icons/fc'
import GlobalButton from '../../../../Widgets/GlobalButton'
import UpdateTracking from './UpdataTracking'
import {GoPackage} from 'react-icons/go'
import {FaTruck} from 'react-icons/fa'
import dayjs from 'dayjs'


const MainTrackingModal = ({openModal, handleCloseModal, data, callBack}) => {
  
  const received = data?.trackingDetails.filter(x => x.status === 'received')[0]
  const inTransit = data?.trackingDetails.filter(x => x.status === 'in_transit')[0]
  const loaded = data?.trackingDetails.filter(x => x.status === 'loaded')[0]
  const delivered = data?.trackingDetails.filter(x => x.status === 'delivered')[0]

  

  const [currentPage, setCurrentPage] = useState(0)

     
  return (
    <div>
        <CustomModal 
            modalClassName = 'w-[60%] min-h-[30vh] z-[1500] mt-[90px]'
            open={openModal} 
            handleClose={()=>{
                handleCloseModal()
            }}>
            


            {currentPage === 0 ? <div className='flex flex-col justify-center gap-10 mb-5 w-full'>

                <div className='flex justify-between mt-16'>
                    <p className='text-[16px] font-medium'>#Ref Number {data?.referenceNumber} </p>

                    <div className='text-[14px] text-969 flex gap-2 items-center'>
                      <p>ID {data?.orderIdentifier}</p>
                      <p className='h-1 w-1 rounded-full bg-grey-600'/>
                      <p>{data?.reference?.name}</p>
                      <p className='h-1 w-1 rounded-full bg-grey-600'/>

                      <p>{data?.reference?.group?.name}</p>
                      <p className='h-1 w-1 rounded-full bg-grey-600'/>

                      <p>{data?.packagesToOrder} {data?.measurementUnit?.name}</p>
                      <p className='h-1 w-1 rounded-full bg-grey-600'/>

                      <p>{data?.branch?.name}</p>
                    </div>
                </div>

                <div className='flex gap-1'>

                  <div className='flex items-center w-[30%]'>
                    <TextAndIcon 
                      className={received ? '!bg-[#E2FFFD]': ''}
                      title={'Ordered Received'}
                      subText={received ? dayjs(received?.createdOn).format('hh:mmA') : ''}
                      Icon={FcApproval}
                    />

                    <div className={`min-w-[40%] h-0 border-1 border-dashed ${received && 'border-[1.5px] border-D57'} `}/>

                  </div>

                  <div className='flex items-center w-[27%]'>
                    <TextAndIcon 
                      title={'Order Loaded'}
                      subText={loaded ? dayjs(loaded?.createdOn).format('hh:mmA') : ''}
                      Icon={BsTruck}
                      className={loaded ? '!bg-[#E2FFFD]': ''}
                    />

                    <div className={`min-w-[40%] h-0 border-1 border-dashed ${loaded && 'border-[1.5px] border-D57'} `}/>


                  </div>

                  <div className='flex items-center w-[27%]'>

                    <TextAndIcon 
                      title={'Order In Transit'}
                      subText={inTransit ? dayjs(inTransit?.createdOn).format('hh:mmA') : ''}
                      Icon={FaTruck}
                      className={inTransit ? '!bg-[#E2FFFD]': ''}

                    />
                    <div className={`min-w-[40%] h-0 border-1 border-dashed ${inTransit && 'border-[1.5px] border-D57'} `}/>


                  </div>



                  <div className='w-[16%]'>

                    <TextAndIcon 
                      title={'Order Delivered'}
                      subText={delivered ? dayjs(delivered?.createdOn).format('hh:mmA') : ''}
                      Icon={GoPackage}
                      className={delivered ? '!bg-[#E2FFFD]': ''}

                    />
                  </div>
                </div>

                <div className='flex gap-4 self-end'>
                  <GlobalButton
                    disabled={(received && inTransit && loaded && delivered) ? true : false}
                    className={`!bg-white border-1 border-[black] ${(received && inTransit && loaded && delivered) && 'opacity-[0.3]'} `}
                    onClick={() => setCurrentPage(1)}
                    title="Update Tracking Details"
                  />

                  {/* <GlobalButton
                    className=''
                    onClick={() =>{}}
                    title="Confirm Delivery"
                  /> */}
                  
                </div>
               
            </div> :
            <UpdateTracking
              handleBack={()=> setCurrentPage(0)} 
              data={data} 
              callBack={()=> {
                callBack()
                handleCloseModal()
              }}/>
          }
        </CustomModal>
    </div>
  )
}

export default MainTrackingModal

const TextAndIcon = ({Icon, title, subText, className}) => {
  return (
    <div className='flex flex-col gap-3 items-center min-w-[60%]'>
      <div className={`bg-[#F9F9F9] px-2 py-2 rounded-md ${className}`}>
        <Icon size={22}/>

      </div>

      <div className='flex flex-col gap-1 items-center'>
        <p className='text-[16px] font-medium text-center'>{title}</p>
        <p className='text-[14px] text-grey-600 text-center'>{subText}</p>
      </div>

    </div>
  )
}