import React from 'react';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Job } from '../../../../Network/ServiceClass/Job';
import { useState } from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { font } from '../../../Widgets/Styles/font';
import { MdOutlineCancel } from 'react-icons/md';
import FormModal from '../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import { createSkillSetAsync } from '../../../../Store/Branches';
import { useDispatch } from 'react-redux';

import {
  Text,
  Field,
  TextAndField,
  FillButton,
  OutlinedButton,
} from '../../../Widgets/FormUtils/FormUtils';


export const NewSkillSetModal = ({ open, handleClose, callback }) => {

  const dispatch = useDispatch()

  const [isloading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      desc: '',
      skillSetCode: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please input skill name'),
      skillSetCode: Yup.string().required('Please input skill Set Code'),
      desc: Yup.string()
        .min(5, 'skill description is too short')
        .required('Enter skill description'),
    }),
   
    onSubmit: (value) => {
      setLoading(true);

      dispatch(createSkillSetAsync(value)).then(res => {
        if(res.meta.requestStatus === "fulfilled"){
          toast.success('New skill set added successfully');
          setLoading(false);
          callback()
          handleClose()
        }
  
        if(res.meta.requestStatus === "rejected"){
          setLoading(false);
          setErrorMessage(res?.error?.message);
          setIsError(true);
        }
      })
    
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY:'auto',
    overflowX:'hidden'
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-A06 dark:text-white' direction="column" alignItems="center" sx={style} gap={3}>
        <Typography sx={{ ...font, fontSize: '18px', fontWeight: '500' }}>
          New Skill Set
        </Typography>


        <TextAndField>
          <Text text={'Skill Name'} />
          <Field
            text={'Kindly enter skill name'}
            value={formik.values.name}
            formik={formik}
            name="name"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Skill Code'} />
          <Field
            text={'Kindly enter skill abbreviation'}
            value={formik.values.skillSetCode}
            formik={formik}
            name="skillSetCode"
          />
        </TextAndField>

       
        <TextAndField>
          <Text text={'Skill Description'} />
          <Field
            text={'Kindly enter description'}
            value={formik.values.desc}
            formik={formik}
            name="desc"
            multiline={true}
            maxRows={3}
          />
        </TextAndField>

        {isError && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '0.2rem',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              padding: '0.5rem',
            }}
          >
            <Typography
              sx={{
                ...font,
                fontSize: '12px',
                color: 'red',
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </Typography>
            <MdOutlineCancel
              style={{ color: 'red', fontSize: '23px', cursor: 'pointer' }}
              onClick={() => setIsError(false)}
            />
          </Box>
        )}

        <div
          className='flex justify-between mt-6 w-full'
        >
          <OutlinedButton
            text="Cancel"
            callBack={() => {
              handleClose();
              formik.resetForm();
            }}
          />
          {!isloading && (
            <FillButton text="Submit" callBack={formik.handleSubmit} />
          )}
          {isloading && <CircularProgress />}
        </div>
      </Stack>
    </FormModal>
  );
};
