import React,{useState, useEffect, useCallback} from 'react'
import SearchInput from '../../../Widgets/SearchInput'
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryGroupsThunk, getInventoryGroupItemThunk } from '../../../../Store/Inventory';
import { CircularProgress } from '@mui/material';
import { TableWrapper, TableHeader, Td, Tr } from '../../../Widgets/Table/TableWrapper';

const GetItems = ({setPageIndex, handleGetItems}) => {

    const distpatch = useDispatch()
    const inventoryStore = useSelector(state => state.inventory)

    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(0)
    const [items, setItems] = useState([])
    const [searchText, setSearchText] = useState('')
    const [selectedItems, setSelectedItems] = useState([])
    


    useEffect(()=> {
        distpatch(getInventoryGroupsThunk())
        .then(val => {
            if (val?.payload?.status === 200){
                setGroups(val?.payload?.data)
                distpatch(getInventoryGroupItemThunk({groupId: val?.payload?.data[selectedGroup]?.id}))
                .then(items => {
                    if (items?.payload?.status === 200) setItems(items?.payload?.data[0]?.inventoryItems)
                })
            } 

        })
    },[])

    const handleSelectGroup = useCallback((group, index) => {
        setSelectedGroup(index)
        distpatch(getInventoryGroupItemThunk({groupId:group?.id}))
        .then(items => {
            if (items?.payload?.status === 200) setItems(items?.payload?.data[0]?.inventoryItems)
        })
    }, [selectedGroup])

    const handleSearch = useCallback((val) => {
        const prevData = items
        const text = val
        
        if(val !== '' || val !== undefined){
            const pattern = new RegExp(`\D*${text}\D*`,'i')
            const result = items.filter((val) => (
                val.name?.toLowerCase().match(pattern) ||
                val.referenceNumber?.toLowerCase().match(pattern) ||
                val.description?.toLowerCase().match(pattern) ))
    
            return result
        }else {
            return prevData
        }
    }, [searchText, items]) 

    const handleSelectItems = (item) => {
        const findItem = selectedItems.find(val => val.id === item.id)
        if (findItem){
            setSelectedItems([...selectedItems.filter(val => val.id !== item.id)])
            handleGetItems([...selectedItems.filter(val => val.id !== item.id)])
        }else {
            setSelectedItems([...selectedItems, item])
            handleGetItems([...selectedItems, item])
        }
    }

  return (
    <div>
        <SearchInput 
            className={'w-[300px] border-gray-200 h-12'}
            inputStyle={'placeholder:text-gray-400'}
            iconStyle='text-gray-400'
            placeholder='Search for items...' 
            onChange={(val)=>setSearchText(val.target.value)}
        />

        <div onClick={()=> setPageIndex(0)}
         className='flex gap-2 items-center mt-6 dark:text-white cursor-pointer'>
            <IoIosArrowBack size={20}/>
            <p className='text-[16px] font-semibold'>Items</p>
        </div>

        <div className='flex justify-between'>
            <div className='mt-4 flex gap-2'>
                {
                    groups.map((val, index) => (
                        <p 
                        onClick={()=> handleSelectGroup(val, index)}
                        key={index} 
                        className={`cursor-pointer text-center ${selectedGroup === (index) ? 'bg-btn-color text-[white]': 'text-[#A5A5A5] dark:text-white border-1 border-gray-100 dark:border-333'} font-bold py-2 px-2 rounded-md min-w-[80px] `}>{val?.slug}</p>

                    ))
                }
            </div>
            <button onClick={()=> {setPageIndex(0)}}
            className='self-end rounded-[3px] px-4 py-2 bg-btn-color text-[white] hover:shadow-small-select'>Done</button>
            
        </div>

        <TableWrapper className='mt-6 !max-h-[70vh] mb-12'>
            <table className=''>
                <TableHeader>
                    <Td className='py-4 w-[300px]'>Reference number #</Td>
                    <Td className='w-[200px]'>Name</Td>
                    <Td className='w-[200px]'>Manufacturer</Td>
                    <Td className='w-[200px] text-center'>No. Of Suppliers</Td>
                    <Td className='w-[200px]'>Group</Td>
                    <Td className='!border-none w-[300px]'>Description</Td>
                </TableHeader>

                {!inventoryStore?.loadingItems ? <tbody>
                    {
                       (items.length !== 0 && !inventoryStore?.loadingItems) ? handleSearch(searchText).map(val => (
                            <Tr className='hover:bg-5F5 hover:dark:bg-292 dark:text-white'>
                                    <Td className='flex gap-10 py-4 '>
                                        <input className='cursor-pointer' checked={selectedItems.find(item => val?.id === item?.id) ? true: false}  type="checkbox" onChange={()=> handleSelectItems(val)}/>
                                        <p>{val?.referenceNumber}</p>
                                    </Td>
                                    <Td>{val?.name}</Td>

                                    <Td>{val?.manAndSupp.length === 0 ? '' : val?.manAndSupp[0]?.manufacturer?.name}</Td>
                                    <Td className="text-center">{val?.manAndSupp.length}</Td>
                                    <Td>{groups[selectedGroup]?.name}</Td>
                                    <Td className={'!border-none'}>{val?.description}</Td>
                            </Tr>

                        )):
                        <tr>
                            <td colSpan={6} className='text-A5A text-center pt-10'> No items here </td>
                        </tr>
                    }
                </tbody> : 
                
                <tr>
                    <td colSpan={6} className='text-A5A border-1 border-white dark:border-A1A text-center pt-10'> <CircularProgress /></td>
                </tr>
                }
            </table>
        </TableWrapper>



    </div>
  )
}

export default GetItems