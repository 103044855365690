import React,{useState, useEffect} from 'react'
import { SideBar } from '../../Widgets/SideBar'
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader'
import ButtonWithIcon from '../../Widgets/ButtonWithIcon'

import { IoClose } from 'react-icons/io5';

import CustomDropdownMultipleSelect from '../../Widgets/CustomDropdownMultipleSelect'
import GetItems from './GetItems/GetItems';
import ConfirmRequest from './ConfirmRequest/ConfirmRequest';
import {BsPlus} from 'react-icons/bs'
import { Branch } from '../../../Network/ServiceClass/Branch';
import AppLayout from '../../Widgets/AppLayout';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { orderRequestTutorial } from '../mock';


const branchService = new Branch()

const InventoryOrderRequest = () => {
    const [selectedBranch, setBranch] = useState([]);
    const [pageIndex, setPageIndex] = useState(0)
    const [selectedItems, setSelectedItems] = useState([])
    const [branchList, setBranchList] = useState([])
    
    const handleSelectBranch = (e) => {
        setBranch(e);
    };

    const reset = () =>{
        setBranch([])
        setSelectedItems([])
    }

    useEffect(()=> {
        branchService.getBranches().then(val => {
            setBranchList(val.data.map(val => ({name: val?.id, label:val?.name, hexcode: val?.hexcode})))
          }).catch()
    },[])
  return (
    <section>
        <AppLayout
            custom="inventory"
            subCustom="create-order-request"
            title="Create order request"
            customTitle="Inventory"
            subtitle=""
            RightComponent={<TutorialPanel items={orderRequestTutorial} />}
        >
            <div>   
                {pageIndex === 0 && <div className='flex justify-between items-start'>
                    <div className='flex flex-col gap-2 w-[400px]'>

                        <div className='w-[400px] h-[200px] p-3 border-1 dark:border-969 rounded-md flex flex-row gap-2 flex-wrap'>
                        {selectedItems.map(item => (
                            <div key={item?.id} className="bg-5F5 dark:bg-color-333 dark:text-white flex justify-between items-center rounded-[5px] h-6 min-w-[100px] p-[4px] px-[10px]">
                                
                                <p className="text-[16px]">{item?.referenceNumber}</p>
                                <IoClose
                                size={16}
                                onClick={(e) => {
                                e.stopPropagation();
                                setSelectedItems([
                                    ...selectedItems.filter(
                                    (x) => x?.id !== item?.id
                                    ),
                                ]);
                                }}
                                className="cursor-pointer ml-[10px] text-grey-600 dark:text-white"
                                />
                            </div>
                        ))
                        }   
                        </div>

                        <div className='ml-10 flex justify-between items-center'>
                            <CustomDropdownMultipleSelect 
                                className={'w-[200px] h-10 '}
                                optionClass={'w-[200px]'}
                                dropdownTitleClass={'text-[black] text-[14px]'}
                                showIcon
                                data={branchList}
                                selectedItems={(items)=> handleSelectBranch(items)}
                                value={selectedBranch}
                            />
                            <button onClick={()=> setPageIndex(2)}
                            className='rounded-[3px] px-4 py-2 bg-btn-color hover:shadow-small-select font-bold text-white'>Request items</button>

                        </div>

                        
                    </div>

                    {/* <ButtonWithIcon title='Get Items' type='text' handleClick={()=>{setPageIndex(1)}}/> */}
                    <button 
                        onClick={()=> setPageIndex(1)}
                        className='flex gap-2 items-center rounded-[3px] px-4 py-2 font-bold bg-btn-color hover:shadow-small-select text-white'>
                        <BsPlus size={25} className="text-white" />
                        <p> Get items</p>
                    </button>

                </div>}
                {pageIndex === 1 && <GetItems setPageIndex={setPageIndex} handleGetItems={(items)=>setSelectedItems(items)}/>}
                {pageIndex === 2 && <ConfirmRequest setPageIndex={setPageIndex} reset={reset} branchId={selectedBranch[0]} data={selectedItems.map(val=> ({...val, packagesToOrder: 0}))}/>}


            </div>
        </AppLayout>
       
    </section>
  )
}

export default InventoryOrderRequest


