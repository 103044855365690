import '../Widgets/Styles/Navbar.css';

import Synclogo from "../../Assets/sync_light.svg";
import SyncdarkLogo from "../../Assets/sync_logo.svg";

export function Navbar() {

    const mode = localStorage.getItem('color-theme') ?? 'light'


    return <div className="Navbar">
        <div className='inner mt-12'>
            <img src={mode === 'light' ? Synclogo : SyncdarkLogo} alt="logo" className="h-[4rem] mx-auto my-8" />
        </div>
    </div>;
}