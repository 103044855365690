import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateShiftTemplate } from './Widget/CreateShiftTemplate';
import { convertTime } from '../../../../../utils/formatTime';
import ButtonWithIcon from '../../../../Widgets/ButtonWithIcon';
import AppLayout from '../../../../Widgets/AppLayout';
import { deleteShiftTemplateAsync, getShiftScheduleAsync, getShiftTemplateAsync } from '../../../../../Store/Shift';
import { BsTrash, BsPencil } from 'react-icons/bs';
import ConfirmDeleteShiftType from './Widget/ConfirmDeleteShift';
import { toast } from 'react-toastify';
import moment from 'moment'
import TutorialPanel from '../../../../Widgets/Tutorial/TutorialPanel';
import { shiftTypesTutorial } from '../../../mock';
import { CircularProgress } from '@material-ui/core';

export const ShiftTemplate = () => {
  const dispatch = useDispatch()
  const [openMenu, setOpenMenu] = React.useState(false);
  const [shiftTemplate, setShiftTemplate] = React.useState({});
  const [isDelete, openConfirm] = React.useState(false)
   
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;
  const [shiftId, selectShift] = React.useState('')
  const dateCreated = useSelector(
    (state) => state.company.activeCompany.createdOn
  );
  const stateStore = useSelector((state) => state.shift)?.shiftTemplates || [];
  const isLoading = useSelector((state) => state.shift)?.loading;

  const [yearSelected, setYearSelected] = React.useState({
    label: moment().format("yyy"),
    value: moment().format("yyy"),
  });

  useEffect(()=> {
dispatch(getShiftTemplateAsync())
  },[])


  const handleOnDateChange = (data) => {
    setYearSelected(data);
  };

  const handleOpen = () => {
    setOpenMenu(true);
  }

  const handleCloseDelete = () => {
    openConfirm(false);
    selectShift('')
  }

  const handleClose = () => {
    setOpenMenu(false);
    setShiftTemplate({})
  }

  const handleSelectShift = (id) => {
    selectShift(id)
    openConfirm(true)
  }

  const handleDeleteShiftTemplate = () => {
    dispatch(deleteShiftTemplateAsync(shiftId)).then((res) => {
      if(res?.meta?.requestStatus === "fulfilled" && [200,201].includes(res?.payload?.status)){
        toast.success("Deleting shift type was successful")
        dispatch(getShiftTemplateAsync()).then((res) => {
          openConfirm(false)
        })
      } else {
        toast.error(res?.payload?.response?.data?.message || "Deleting shift was not successful")
      }
    })
  }

  const handleShiftClick = (e) => {
    setShiftTemplate(e)
    handleOpen()
  }

  return (
    <section className="homepage">
      <AppLayout
        custom="company"
        subCustom="templates"
        title="Shift Types"
        customTitle=""
        subtitle="Create Shifts  for easy schedule building."
        RightComponent={
        <div className="flex items-center gap-3">
          <TutorialPanel items={shiftTypesTutorial} />
          {[1,2].includes(privilege)&&(<ButtonWithIcon className='dark:border-333' title="New" type='text' handleClick={handleOpen}/>)}
        </div>
      }
      >
        

        {
          isLoading && <div className="w-full h-[400px] flex items-center justify-center"> <CircularProgress /></div>
        }
       
        <div className="mt-5 grid grid-cols-3 gap-12 mb-40">

          {
            !isLoading && stateStore?.map((store) => (
          <div className="shadow-shift-shadow-small flex flex-col py-3 px-5 rounded-md gap-3 dark:shadow-dark-mode">
            <div className='flex justify-between items-center'>
              <p className="font-semibold text-[18px]  text-color-424 dark:text-white">{store?.templateName}</p>
             {[1,2].includes(privilege)&&( <div className="flex items-center gap-3">
              <BsPencil className="cursor-pointer" color="black" onClick={()=>handleShiftClick(store)} />
              <BsTrash className="cursor-pointer" color="red" onClick={()=>handleSelectShift(store?.id)} />
              </div>)}

            </div>

            <div className='flex items-center gap-8 flex-wrap'>

              <TitleText 
                title={'Job Role'}
                text={store?.jobRole?.title}
              />

              <TitleText 
                title={'Time'}
                text={`${convertTime(store?.startTime)}-${convertTime(store?.endTime)}`}
              />
            </div>

            <div className='flex items-center gap-8 flex-wrap'>
            <TitleText 
                title={'Staff Required'}
                text={`${(store?.locations?.reduce((prev, curr) => prev + (curr?.staffRequired || 0),0)||(store?.maxStaffRequired || 0))} Staff(s)`}
            />

            </div>
            <TitleText 
                title={'Locations'}
                text={store?.locations?.map((x) => x?.location?.name)?.join(", ")}
            />



            
          </div>

            ))
          }

        </div>
          {
           !isLoading && stateStore?.length === 0 && <div className="flex text-center justify-between w-full h-10">
              <p>No template available</p>
            </div>
          }
        {openMenu && (<CreateShiftTemplate setShiftTemplate={setShiftTemplate} shiftTemplate={shiftTemplate} open={openMenu} handleClose={handleClose} />)}
        {isDelete && (<ConfirmDeleteShiftType open={isDelete} isLoading={isLoading} handleClose={handleCloseDelete} handleSubmit={handleDeleteShiftTemplate} />)}
      </AppLayout>
      
    </section>
  );
};


const TitleText = ({title, text}) => {
  return (
    <div className='flex flex-col'>
      <p className='text-[13px] text-grey-600 dark:text-white font-medium'>{title}</p>
      <p className='text-A5A text-[14px] dark:text-white font-semibold'>{text}</p>
    </div>
  )
}