import React from 'react';
import { useSelector } from 'react-redux';
import { Storage } from '../../../../Network/StorageClass/StorageClass';
import './BranchTab.css';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router';

export const BranchTabs = ({ index, setIndex }) => {
  const navigate = useNavigate()
  const storage = new Storage();
  const membership = useSelector(
    (state) => state.company.activeCompany
  )?.memberships;
  const privilege = membership?.find(
    (val) => storage.getUserDetails().id === val.userId
  )?.privilege;

  const handleDirectSendBulkInvite = () => {
    navigate("/branches/bulk-invite")
  }

  const handleDirectSendBulkAccountCreation = () => {
    navigate("/branches/bulk-account")
  }

  return (
    <div className="branch-tabs w-full flex justify-between">
      <div className="tabs">
        <div
          onClick={() => setIndex(0)}
          className={[0, 2, 3].includes(index) ? 'Active' : ''}
        >
          {/* <span className=' font-semibold'>Company Locations</span> */}
        </div>

      </div>
      {index !== 1 && (<div className='flex gap-2'>
        {/* <button onClick={handleDirectSendBulkInvite} className="p-2 rounded bg-base-color text-white flex items-center font-semibold"> <BsPlus size={23} /> <span className="w-max">Bulk Invites</span></button> */}
        <button onClick={handleDirectSendBulkAccountCreation} className="p-2 rounded bg-base-color text-white flex items-center font-semibold"> <BsPlus size={23} /> <span className="w-max">Bulk Creation</span></button>
      </div>)}

    </div>
  );
};
