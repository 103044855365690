import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { OutlinedButton } from './FormUtils/FormUtils';

const ColorPicker = ({
  onChangeColor,
  openPicker = false,
  defaultColor = '#000000',
}) => {
  const [color, setColor] = useState(defaultColor);
  const [showPicker, setShowColorPicker] = useState(openPicker);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    onChangeColor(newColor.hex);
  };

  useEffect(() => {
    onChangeColor('#000000');
  }, []);

  return (
    <div className="w-full">
      <p className="text-[12px] font-[500]">Color Picker</p>
      {showPicker && (
        <SketchPicker color={color} onChange={handleColorChange} />
      )}
      <div className="flex gap-1 w-full flex-wrap items-center mt-1">
        <OutlinedButton
          themeColor={color}
          text={!showPicker ? 'Show color picker' : 'Hide picker'}
          callBack={() => setShowColorPicker(!showPicker)}
        />
        <p className="text-[12px]">
          Selected Color: <span style={{ color }}>{color}</span>
        </p>
      </div>
    </div>
  );
};

export default ColorPicker;
