import React,{useState, useEffect} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Stack, CircularProgress } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import { toast } from 'react-toastify';
import FormModal from '../../../../Widgets/Animation/FormModal';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { editInventorySuppliersThunk, getInventorySuppliersThunk } from '../../../../../Store/Inventory';
import { useDispatch } from 'react-redux';

import {
  Text,
  Field,
  TextAndField,
  FillButton,
  OutlinedButton,
} from '../../../../Widgets/FormUtils/FormUtils';

import { Home } from '../../../../../Network/ServiceClass/Home';

const homeService = new Home()
export const EditSupplier = ({ open, handleClose, data }) => {

  const dispatch = useDispatch()
  const [isloading, setLoading] = useState(false);

  const [cities, setCities] = useState([])
  const [selectedCity,setSelectedCity] = useState(null)

  const [state, setState] = useState([])
  const [selectedState,setSelectedState] = useState(null)

  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)



  const formik = useFormik({
    initialValues: {
      name: data?.name,
      contactInfo: data?.contactInfo,
      extraContact: data?.extraContact,
      website: data?.website,
      description: data?.description,
      cityId: data?.cityId,
      stateId: data?.stateId,
      phone: data?.phone,
    },
    validationSchema: Yup.object({
        name: Yup.string().required('Please a name'),
        description: Yup.string().required('Please input description'),
        contactInfo: Yup.string().required('Please input contact 1'),
        website: Yup.string().required('Please input website'),
        phone: Yup.string().required('Please input phone'),
        cityId: Yup.string().required('Please choose a city'),
        stateId: Yup.string().required('Please choose a state'),


    }),
   
    onSubmit: (value) => {
      setLoading(true);
      dispatch(editInventorySuppliersThunk({data: value, id: data?.id}))
      .then(val => {
        setLoading(false)
        if (val.payload?.status === 200){
          toast.success('Supplier Edited')
          dispatch(getInventorySuppliersThunk())
          handleClose()
        }else {
          toast.error(val?.payload?.data?.message)
        }
      })
    },
  });

  useEffect(()=> {
    homeService.getCountries()
    .then(val => {
      setCountries(val?.data)
      const filterCountry = val?.data.filter(x => x.id === data?.countryId)
      setSelectedCountry({label: filterCountry[0]?.name, value: filterCountry[0]?.id})
    })
  },[])

  useEffect(()=> {
    if(selectedCountry !== null) {

      homeService.getState(selectedCountry.value)
      .then(val => {
        setState(val?.data)
        const filterState = val?.data.filter(x => x.id === data?.stateId)
        setSelectedState({label: filterState[0]?.name, value: filterState[0]?.id})
      })
    }
  },[selectedCountry])

  useEffect(()=> {
    if(selectedState !== null) {

      homeService.getCity(selectedState.value)
      .then(val => {
        setCities(val?.data)
        const filterCity = val?.data.filter(x => x.id === data?.cityId)
        setSelectedCity({label: filterCity[0]?.name, value: filterCity[0]?.id})
      })
    }
  },[selectedState])



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY:'auto',
    overflowX:'hidden'
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-A06 dark:text-white' direction="column" alignItems="center" sx={style} gap={2}>
        <p className='font-bold dark:text-white'>Edit Supplier</p>


        <TextAndField space={0.5}>
          <Text text={'Name'} />
          <Field
            text={'Kindly enter supplier"s name'}
            value={formik.values.name}
            formik={formik}
            name="name"
          />
        </TextAndField>

        <TextAndField space={0.5}>
          <Text text={'Description'} />
          <Field
            text={'Kindly enter a description'}
            value={formik.values.description}
            formik={formik}
            name="description"
          />
        </TextAndField>

        <TextAndField space={0.5}>
            <Text text={'Country'} />
            <CustomDropdown
                data={countries?.map((state) => ({
                    label: state?.name,
                    value: state.id,
                }))}
                className=" w-full h-9 border-[#c7c5c5] rounded py-[14px] px-5"
                value={selectedCountry}
                dropdownTitleClass="text-A5A"
                optionClass="z-[1000] shadow-md min-h-[50px]"
                onChangeOption={(val)=>setSelectedCountry(val)}
            />
        </TextAndField>

        <div className='flex justify-between w-full'>
            
            <div className='w-[49%]'>
              <TextAndField space={0.5}>
                  <Text text={'State'} />
                  <CustomDropdown
                      data={state?.length !== 0 ? state?.map((state) => ({
                          label: state?.name,
                          value: state.id,
                      })) : [{label: 'No data', value: null}]}
                      trimtext
                      className={` w-full h-9 ${formik?.errors?.stateId ? 'border-red-500' : 'border-[#c7c5c5]'} rounded py-[14px] px-5`}
                      value={selectedState}
                      dropdownTitleClass="text-A5A"
                      optionClass="z-[1000] shadow-md min-h-[50px]"
                      onChangeOption={(val)=>{
                        if(val?.value === null){
                          setSelectedState(null)
                          formik.setFieldValue('stateId', '')
                        }else {
                          setSelectedState(val)
                          formik.setFieldValue('stateId', val?.value)
                        }
                      }}
                  />
              </TextAndField>
            </div>

            <div className='w-[49%]'>

              <TextAndField space={0.5}>
                  <Text text={'City'} />
                  <CustomDropdown
                      data={cities?.length !== 0 ? cities?.map((city) => ({
                        label: city?.name,
                        value: city.id,
                      })) : [{label: 'No data', value: null}]}
                      trimtext ={true}
                      className={`w-full h-9 ${formik?.errors?.cityId ? 'border-red-500' : 'border-[#c7c5c5]'} rounded py-[14px] px-5`}
                      value={selectedCity}
                      dropdownTitleClass="text-A5A"
                      optionClass="z-[1000] shadow-md min-h-[50px]"
                      onChangeOption={(val)=>{
                        if(val?.value === null){
                          setSelectedCity(null)
                          formik.setFieldValue('cityId', '')
                        }else {
                          setSelectedCity(val)
                          formik.setFieldValue('cityId', val?.value)
                        }
                      }}
                    />
              </TextAndField>
            </div>


           
        </div>

        <TextAndField space={0.5}>
            <Text text={'Phone'} />
            <Field
                text={''}
                value={formik.values.phone}
                formik={formik}
                name="phone"
            />
        </TextAndField>

        <div className='flex gap-2'>
            <TextAndField space={0.5}>
                <Text text={'Contact 1'} />
                <Field
                    width={''}
                    text={''}
                    value={formik.values.contactInfo}
                    formik={formik}
                    name="contactInfo"
                />
            </TextAndField>

            <TextAndField space={0.5}>
                <Text text={'Contact 2'} />
                <Field
                    width={''}
                    text={''}
                    value={formik.values.extraContact}
                    formik={formik}
                    name="extraContact"
                />
            </TextAndField>

        </div>

        <TextAndField space={0.5}>
            <Text text={'Website'} />
            <Field
                text={''}
                value={formik.values.website}
                formik={formik}
                name="website"
            />
        </TextAndField>


       

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          mt={4}
        >
          <OutlinedButton
            text="Cancel"
            width={'42%'}
            callBack={() => {
              handleClose();
              formik.resetForm();
              setSelectedCity(null)
              setSelectedState(null)
              setSelectedCountry(null)
            }}
          />
          {!isloading && (
            <FillButton text="Save" width={'42%'} callBack={formik.handleSubmit} />
          )}
          {isloading && <CircularProgress />}
        </Stack>

      </Stack>
    </FormModal>
  );
};
