import CustomButton from '../../../../Widgets/CustomButton';
import CustomModal from '../../../../Widgets/CustomModal';
import React from 'react';

const WriteUpDetailModal = ({
  handleClose,
  open,
  writeUpDetails,
  handleOpenDeleteInfoModal,
}) => {
  return (
    <CustomModal
      modalClassName="max-w-[423px]"
      open={open}
      handleClose={handleClose}
    >
      <div className="flex flex-col justify-between min-h-[350px]">
        <div>
          <p className="font-semibold text-base text-center mb-7"> Report </p>
          <p className="text-sm font-medium">
            {writeUpDetails.body}
          </p>
        </div>
        <div className="flex justify-center mb-8">
          <CustomButton
            onClick={handleOpenDeleteInfoModal}
            className="border-353 bg-2F2 text-353 text-semibold text-sm"
            showIcon={false}
            title="Delete report"
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default WriteUpDetailModal;
