import React from 'react';
import AppLayout from '../../Widgets/AppLayout';
import CustomButton from '../../Widgets/CustomButton';
import SearchInput from '../../Widgets/SearchInput';
import { ReactComponent as TopIcon } from '../../../Assets/top.svg';
import { ReactComponent as BottomIcon } from '../../../Assets/bottom.svg';
import WriteUpContainerCard from './WriteUpContainer/WriteUpScreenCard';
import WriteUpDetailModal from './Modal/WriteUpModal/WriteupModal';
import DeleteInfoModal from './Modal/DeleteInfoModal/DeleteInfoModal';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon'
import {
  createWriteupThunk,
  deleteWriteupThunk,
  getAllWriteUpThunk,
  getMemberThunk,
} from '../../../Store/Writeup';
import AppLoader from '../../Widgets/AppLoader';
import { format } from 'date-fns';
import CreateWriteUpModal from './Modal/CreateWriteUp/CreateWriteUp';
import categorizeWriteups from '../../../utils/categorizeWriteup';
import PlaceHolderOne from '../../Widgets/placeholders/PlaceHolderOne';
import EmptyIcon from '../../../Assets/empty_icon.svg'
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { writeUpTutorial } from '../mock';

export const WriteUpScreen = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.writeup);

  const [filter, setFilter] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setDeleteModal] = React.useState(false);
  const [openCreateModal, setCreateModal] = React.useState(false);
  const [writeUpData, setWriteup] = React.useState({});
  const [writeUpSearch, setWriteupSearch] = React.useState('');

  React.useEffect(() => {
    dispatch(getAllWriteUpThunk());
    dispatch(getMemberThunk());
  }, [dispatch]);

  const handleToggle = () => {
    setFilter((prev) => !prev);
    dispatch(getAllWriteUpThunk(!filter ? 'DESC' : 'ASC'));
  };

  const handleOpenModal = (data) => {
    setWriteup(data);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setWriteup('');
  };

  const handleOpenDeleteInfoModal = () => {
    setDeleteModal(true);
    setOpen(false);
  };

  const handleCloseDeleteInfoModal = () => {
    setDeleteModal(false);
  };

  const handleOpenCreateWriteupModal = () => {
    setCreateModal(true);
  };

  const handleCloseCreateWriteupModal = () => {
    setCreateModal(false);
  };

  const handleSubmitWriteup = (values) => {
    dispatch(createWriteupThunk(values)).then(() => setCreateModal(false));
  };

  const handleDeleteWriteUp = (e, data) => {
    e.stopPropagation();
    setWriteup(data);
    handleOpenDeleteInfoModal();
  };

  const handleClickDelete = () => {
    dispatch(deleteWriteupThunk(writeUpData.id)).then(() =>
      handleCloseDeleteInfoModal()
    );
  };

  const handleEmployeeSearch = (searchWriteUps, cat) => {
    const filteredData = categorizeWriteups(store?.writeUps)[cat]?.filter(
      (writeup) =>
        writeup?.user.firstName
          ?.toLowerCase()
          .includes(searchWriteUps?.toLowerCase()) ||
        writeup?.user.lastName
          ?.toLowerCase()
          .includes(searchWriteUps?.toLowerCase())
    );
    return filteredData;
  };

  const handleOnChangeWriteup = (e) => {
    setWriteupSearch(e.target.value);
  };

  return (
    <AppLayout
      custom="company"
      subCustom="write-up"
      title="Write ups"
      subtitle="View write ups and manage them here"
      customTitle=" "
      RightComponent={<TutorialPanel items={writeUpTutorial} />}
    >
      <AppLoader loading={store.loading}>
        <section>
          <div className="mt-20 flex items-center justify-between mb-14">
            <div className="flex items-center gap-2">
              <SearchInput
                onChange={handleOnChangeWriteup}
                placeholder="Search write up"
              />
              
            {/* <div onClick={handleOpenCreateWriteupModal} className="cursor-pointer">
                <div className="flex items-center">
                  <BsPlus className='text-[24px]'/>
                  <p className="mr-2">New write up</p>
                </div>
            </div> */}
            <CustomButton
                onClick={handleToggle}
                title={filter ? 'Oldest first' : 'Newest first'}
                className="gap-2 dark:border-333 dark:text-white"
                Icon={filter ? BottomIcon : TopIcon}
              
              />
            </div>
            <ButtonWithIcon title='New' type='text' handleClick={handleOpenCreateWriteupModal}/>
          </div>
          {React.Children.toArray(
            Object.keys(categorizeWriteups(store?.writeUps))?.map(
              (category) => (
                <div>
                  <p className="font-medium text-base text-color-424 mb-6 dark:text-white">
                    {category}
                  </p>
                  <div>
                    <div className="grid grid-cols-4 gap-4">
                      {React.Children.toArray(
                        handleEmployeeSearch(writeUpSearch, category)?.map(
                          (writeUp) => (
                            <WriteUpContainerCard
                              header={`${writeUp?.user?.firstName} ${writeUp?.user?.lastName}`}
                              handleDeleteWriteUp={(e) =>
                                handleDeleteWriteUp(e, writeUp)
                              }
                              date={format(
                                new Date(writeUp.createdOn),
                                'dd MMM yyyy'
                              )}
                              desc={writeUp?.body}
                              handleOpenModal={() => handleOpenModal(writeUp)}
                            />
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
              )
            )
          )}

          {
            store?.writeUps?.length < 1 && <div className="w-full h-[500px] flex justify-center items-center">
              <PlaceHolderOne pic={EmptyIcon} showPlaceholder={true}/>
            </div>
          }
          <WriteUpDetailModal
            open={open}
            writeUpDetails={writeUpData}
            handleOpenDeleteInfoModal={handleOpenDeleteInfoModal}
            handleClose={handleCloseModal}
          />
          <DeleteInfoModal
            open={openDeleteModal}
            handleClose={handleCloseDeleteInfoModal}
            handleSubmit={handleClickDelete}
            loading={store?.deleteWriteUpIsLoading}
          />
          <CreateWriteUpModal
            open={openCreateModal}
            handleClose={handleCloseCreateWriteupModal}
            submitWriteup={handleSubmitWriteup}
            loading={store?.createWriteUpIsLoading}
          />
        </section>
      </AppLoader>
    </AppLayout>
  );
};
