import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import {font} from '../Styles/font'
import {BsSearch} from 'react-icons/bs'
import PlaceHolderOne from '../placeholders/PlaceHolderOne';

export const CustomTableWithSearch = (
    {
        headerList=[], 
        rows=[], style='', 
        withAvatar=false, 
        handleClick=()=>{},
        hoverStyle='',
        component=<></>,
        searchStyle='w-[50%]',
        includeSearch = true,
        maxScrollHeight = 'h-[400px]'
    }) => {
    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const handleChange = (e)=> {
        const val = e.target.value
        setSearchValue(val)
        if(val !== ''){
            const pattern = new RegExp(`\D*${val}\D*`,'i')
            const result = data.filter((val) => val.col1.match(pattern))
    
            setData(result)
        }else {
            setData(rows)
        }


    }
    useEffect(()=>{
        setData(rows)
    },[rows])

    return (
      <div className={`${style} dark:bg-1F1`}>

        {includeSearch && <div className='flex justify-between'>
            <div className={`p-[5px] rounded-md border-1 dark:border-969 flex items-center gap-[10px] dashboard-section-searchbar ${searchStyle} `}>
                <div className='text-[12px]'>
                    <BsSearch className="dark:text-white" />
                </div>
                <input 
                className='border-none bg-transparent outline-none w-[80%] text-[12px] dark:border-333'
                type="text" placeholder="Search Team Member" 
                value={searchValue}
                onChange={(e)=>handleChange(e)}

                />
            </div>
            {component}
        </div>}
  
  
  
          <div className='rounded-lg '>


              <div class="max-h-[400px] overflow-y-auto px-3">
              {data.length <= 0 && <div class="w-[100%] py-6 flex justify-center items-center">
                    <PlaceHolderOne showPlaceholder="false" pic="placeHolderPic2" />
                </div>}

                {data.length > 0 && <table class="w-[100%] max-h-[100px]">
                    <thead class="bg-[#fafafa]  sticky top-0 dark:bg-1F1">
                        <tr className='text-[14px] text-[#444444] dark:text-white dark:bg-1F1'>
                            {headerList.map(val => {
                                return <th className='text-left py-4 px-4 dark:bg-1F1'>{val}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((row,index) => (
                              <tr
                              className='border-b-1 last:border-0 dark:border-969'
                              key={row.id}
                            //   sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...font }}
                              onClick={()=>handleClick(row)}
                              >


                                <td className='py-4 px-4 dark:border-333 dark:text-white flex items-center gap-2'>

                                   {(withAvatar && row.image !== null) && <Avatar alt="" src={row.image} />}
                                    {(withAvatar && row.image === null) && <Avatar sx={{
                                      color: '#444355', 
                                      fontSize: '10px',
                                      backgroundColor: '#EEEDFF',
                                      ...font
                                      }}>{row.col1.slice(0,2).toUpperCase()}</Avatar> }


                                <p className='text-[14px] text-[#444444] dark:text-white'>{row.col1}</p>
                              </td>
                              <td className="dark:border-333">
                                <p className='text-[14px] text-[#444444] dark:text-white'>{row.col2}</p></td>
                              {row.col3 !== undefined && <td className="dark:border-333 dark:text-white"><p className='text-[14px] dark:text-white text-[#444444]'>{row.col3}</p></td>}
                              {row.col4 !== undefined && <td className="dark:border-333 dark:text-white"><p className='text-[14px] dark:text-white text-[#444444]'>{row.col4}</p></td>}
                              {row.col5 !== undefined && <td className="dark:border-333 dark:text-white">{row.col5}</td>}
                        </tr> ))}
                    </tbody>
                </table>}
              </div>

          </div>
      </div>
    )
  }
  