import React, { useState,useEffect } from 'react';
import AppLayout from '../../../../Widgets/AppLayout';
import { Table,TableCell, TableContainer, TableHead, TableRow, TableBody, CircularProgress, MenuItem} from '@mui/material';
import {getOverTime, getMyOverTime} from '../../../../../Network/ServiceClass/OverTimeService'
import BasicMenu from './menu';
import Confirm from './confrim';
import AlertDialog from '../../../../Widgets/SuccessDialog';
import { Storage } from '../../../../../Network/StorageClass/StorageClass';
import { CustomTableWithSearch } from '../../../../Widgets/Table/CustomTableWithSearch';

const storage = new Storage()
export const OverTimeScreen = () => {
 
  const priviledge = storage.getActiveCompany()?.memberships[0]?.privilege
  
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const [openListMenu, setOpenListMenu] = useState(null);
    const [menuItem, setMenuItem] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [confirmData, setConfirmData] = useState(null)
    const [action, setAction] = useState(null)

    const handleOpenConfirm = () => {
      setOpenConfirm(true)
      // setConfirmData(data)
    }

    const handleToggleListMenu = (event) => {
      setOpenListMenu(event.currentTarget);
    };
    const handleCloseListMenu = () => {
      setOpenListMenu(null);
    };

   

    const items = [
      {name: 'Approve', color:'#458452', click: handleOpenConfirm},
      {name: 'Dissapprove', color:'#C15353', click: handleOpenConfirm}
    ]

    const refresh = () => {
      getOverTime().then(val => {
        setData(val.data)
      }).catch(error=>{
        console.log(error)
      })
    }

    useEffect(()=>{
      setIsLoading(true)
      if([1,2].includes(priviledge)){
        getOverTime().then(val => {
          console.log(val.data)
          setData(val.data)
          setIsLoading(false)
        }).catch(error=>{
          console.log(error)
          setIsLoading(false)
        })
      }else {
        getMyOverTime().then(val => {
          setData(val.data)
          setIsLoading(false)
        }).catch(error=>{
          console.log(error)
          setIsLoading(false)
        })
      }


    },[])

  return (
    <AppLayout
      custom = 'shift'
      subCustom="over-time"
      title="Over time"
      customTitle=" "
      subtitle="Manage over time requests "
    >
      <BasicMenu 
        openListMenu={openListMenu} 
        handleClose={handleCloseListMenu}
        items={menuItem}
        setAction={setAction}
      />
      <Confirm 
      openModal={openConfirm} 
      setOpenModal={setOpenConfirm} 
      data={confirmData} 
      action={action}
      refresh={refresh}
      />
      <AlertDialog/>

      {!isLoading && <div className=''>
        {/* <TableContainer className='w-[100%] max-h-[70vh] rounded-md overflow-y-auto'>
                  <Table  aria-label="simple table">
                      <TableHead>
                          <TableRow className='bg-[#F0F0F5]'>
                              <TableCell> <p className='text-[16px]'>Staff name</p> </TableCell>
                              <TableCell><p className='text-[16px]'>Number of hours</p></TableCell>
                              <TableCell><p className='text-[16px]'>Date of request</p></TableCell>
                              <TableCell><p className='text-[16px]'>Status</p></TableCell>


                          </TableRow>
                      </TableHead>
                      {data.length > 0 && <TableBody>
                          {data.map((row) => {
                            const date = new Date(row.date).toString().split(' ').splice(0,4).join(' ')
                            return <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 },  }}
                              >
                              <TableCell component="th" scope="row" sx={{
                                  display: 'flex', 
                                  flexDirection: 'row',
                                  gap: 1,
                                  alignItems: 'center',
                                  }}>
                                  <p className='text-[16px]'>{row.user.firstName} {row.user.lastName}</p>
                              </TableCell>
                              <TableCell ><p className='text-[16px]'>{row.hours} Hours</p></TableCell>
                              <TableCell ><p className='text-[16px]'>{date}</p></TableCell>
                              {[1,2].includes(priviledge) && <TableCell >
                                <p className='text-[16px] cursor-pointer' onClick={(event)=>{handleToggleListMenu(event); setMenuItem(items); setConfirmData(row)}}>...</p>
                              </TableCell>}
                              {[3].includes(priviledge) && <TableCell >
                                <p className='text-[16px] ' >{row.status}</p>
                              </TableCell>}

                              
                                  </TableRow>
                            
                          })}
                      </TableBody>}
                  </Table>
        </TableContainer> */}

         <CustomTableWithSearch 
            style='flex flex-col gap-3 py-3'
            searchStyle='w-[20%]'
            headerList={['Staff name','Number of hours', 'Date of request', 'Status',]}
            rows={data.map(val=> {
              const date = new Date(val.date).toString().split(' ').splice(0,4).join(' ')
              const start = Number(val.slot.startTime.split(':').splice(0,2)[0])
              const end = Number(val.slot.endTime.split(':').splice(0,2)[0])
              const hours = end - start
              return {
              id: val.id,
              col1: `${val.user.firstName} ${val.user.lastName}`,
              col2: hours,
              col3: date,
              col4: [1,2].includes(priviledge) ? <p className='text-sm cursor-pointer' onClick={(event)=>{handleToggleListMenu(event); setMenuItem(items); setConfirmData(val)}}>...</p>: val.status
            }})}
        />

      </div>}
      
      {isLoading && <div className='w-[70px] m-auto mt-[20px]'>
        <CircularProgress/>
      </div>}


    </AppLayout>
  );
};
