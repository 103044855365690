import React from 'react'
import moment from 'moment'

const DateLabel = ({detailsWeek, hideMonth = false, detailsDay ,type, padding, className, paddingY, layoutProps={}}) => {
  const date = moment(new Date(detailsWeek)).toDate().toDateString().split(' ')
  const dayLetters = date[0]
  const day = date[2]
  const month = date[1]

  const daily = (detailsDay === undefined)? '' : detailsDay.split(' ')
  return (
    <div style={{padding:padding, paddingTop: paddingY, paddingBottom: paddingY}} className={className}>
      {(type === 'week') && <div className='date-label' style={{display:"inline-flex", alignItems:"center"}}>
          
          <div style={{fontSize:"16px"}}>
            {!hideMonth && (<p className={`font-bold text-gray-600 ${layoutProps?.headerClass}`}>{dayLetters} {day} {month}</p>)}
            {hideMonth && (<p className={`font-bold text-gray-600 ${layoutProps?.headerClass}`}>{dayLetters} {day}</p>)}
          </div>

          {/* <div style={{fontSize:"12px", marginLeft:"5px"}}>
              <div>{dayLetters}</div>
              <div style={{color:"grey"}}>{month}</div>
          </div> */}

      </div> }

      {(type === 'day' && detailsDay !== undefined) && <div className='date-label' style={{display:"inline-flex", alignItems:"center"}}>
          
          <div style={{fontSize:"30px"}}>
            {daily[0]}
          </div>

          <div style={{fontSize:"12px", marginLeft:"5px"}}>
              <div>{daily[1]}</div>
              {/* <div style={{color:"grey"}}>{daily[2]}</div> */}
          </div>

      </div> }

    </div>
  )
}

export default DateLabel