import React from 'react'
import { Storage } from '../../../../../../Network/StorageClass/StorageClass'
import ButtonWithIcon from '../../../../../Widgets/ButtonWithIcon'
import { Box } from '@mui/material'

export const InviteButton = ({handleOpen}) => {
  
  const storage = new Storage();
  const isAdmin = storage.getActiveCompany().memberships[0].privilege

  return (
        [3].includes(isAdmin) ? <></> : 
        <Box marginRight={'20px'}><ButtonWithIcon title='New' type='text' handleClick={handleOpen}/></Box>
        
  )
  // jjv
}


