export const IntentsDefault = {
	action: "",
	active: 1,
	amount: "",
	commodity: "greeting",
	commodity_detail: "",
	description: "This intent represents generic greetings",
	destination_entity: "",
	entity_list: ["*"],
	insights: "Thems",
	intent: "greeting",
	intent_id: 413194189,
	origination_entity: { data: "*", name: "", is_default: true },
	tool_name: "",
	use_as_tool: 0,
	use_metadata: 0,
	_id: "6566417120fa166258d4e882",
};