import { Loader } from './Loader'

const AppLoader = ({ loading, children }) => {
  return loading ? (
    <div className="w-full h-96 flex justify-center items-center">
      <Loader />
    </div>
  ): children
}

export default AppLoader;