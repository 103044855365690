import { CSVLink } from "react-csv";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";
import GlobalButton from "../../../Widgets/GlobalButton";
import { convertToDollar } from "../../../../utils/util";

const Csv = ({ data = [], selectedRotation }) => {
  const refinedData = data?.map((dt) => ({
    Name: dt.name,
    "Total work days": dt?.totalWorkDays,
    "Total vacation": dt?.totalVacationDays,
    "Total hours": dt?.totalHours,
    "Total sick days": dt?.totalSickDays,
    "Hourly pay": `$ ${convertToDollar(dt?.hourlyPay || 0)}`,
    "Current Earned": `$ ${convertToDollar(
      dt?.rotations[selectedRotation - 1]?.totalEarned || 0
    )}`,
  }));

  return (
    <div>
      <CSVLink data={refinedData} filename={`report.csv`}>
        <GlobalButton
          className="h-10"
          title="Export"
          onClick={() => {}}
          Icon={PiMicrosoftExcelLogoLight}
          showIcon
          disabled={refinedData.length === 0 && true}
        />
      </CSVLink>
    </div>
  );
};

export default Csv;
