import { BsPlus } from "react-icons/bs"
import CustomDropdown from "../../Widgets/CustomDropdown"
import GlobalButton from "../../Widgets/GlobalButton"
import { useNavigate } from "react-router"
import AppLayout from "../../Widgets/AppLayout"
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { getBranchesAsync } from '../../../Store/Branches';
import { IoClose } from 'react-icons/io5';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel'
import { quickWithdrawalTutorial } from '../mock'



export const QuickWithdraw = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [fromBranch, selectFromBranch] = useState({ label: "Select", value : ""})
  const [selectItems, selectInventoryItem] = useState([])

  const branches = useSelector(state => state?.branches)?.branches?.data || []

  useEffect(()=>{
    dispatch(getBranchesAsync())
  },[])

  useEffect(()=>{
    const withdrawalItem = localStorage.getItem("withdrawalItem")
    if(withdrawalItem !== undefined){
      const data = JSON.parse(withdrawalItem);
      const withdrawalBranch = branches.find((x) => x.id === data?.withdrawLocationId)
      if(withdrawalBranch){
        selectFromBranch({ label: withdrawalBranch?.name, value: withdrawalBranch?.id})
      }
      if(data?.withdrawalData){
        selectInventoryItem(data?.withdrawalData)
      }
    } else {
      selectFromBranch({ label: "Select", value : ""})
    }
  },[])

  const handleSaveLocationInfo = (val = undefined) => {
    const data = JSON.parse(localStorage.getItem("withdrawalItem"))
    const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    const withdrawalData = {
      ...data,
      teamMembershipId: member.id,
      withdrawalData: selectItems,
      itemUsage: val
    }
    localStorage.setItem("withdrawalItem", JSON.stringify(withdrawalData))

    navigate("/quick-withdrawal-confirm")
  }

  const handleGotoInventoryList = () => {
    if(["", undefined, null].includes(fromBranch?.value)){
      toast.error("Please select a withdrawal location")
      return
    }

    const withdrawalData = {
      withdrawLocationId: fromBranch.value,
    }


    localStorage.setItem("withdrawalItem", JSON.stringify(withdrawalData))

    navigate("/quick-withdrawal-list")
  }

  return (
    
      <AppLayout
        custom="inventory"
        subCustom="quick-withdrawal"
        title="Withdraw Items"
        customTitle="Inventory"
        subtitle=""
        RightComponent={<TutorialPanel items={quickWithdrawalTutorial} />}
      >

        <div className="flex justify-between items-start">
          <div className="flex flex-col justify-end">
          <div className="w-[475px] h-[168px] flex items-start p-3 gap-1 flex-wrap rounded-[10px] border dark:border-969 border-9FD">
          {
            selectItems?.map((item) => (<div key={item?.id} className="bg-5F5 dark:bg-color-333 dark:text-white flex justify-between items-center rounded-[5px] h-6 min-w-[100px] p-[4px] px-[10px]">
                                
            <p className="text-[16px]">{item?.referenceNumber}</p>
            <IoClose
            size={16}
            onClick={(e) => {
            e.stopPropagation();
            selectInventoryItem([
                ...selectItems.filter(
                (x) => x?.id !== item?.id
                ),
            ]);
            }}
            className="cursor-pointer ml-[10px] text-grey-600 dark:text-white"
            />
        </div>))
          }
        </div>
          <div className="mt-6">
          <div className="flex items-center gap-4 justify-end">
            <p className="font-medium text-base text-color-424 dark:text-white">Withdraw Location</p>
            <CustomDropdown
                data={[
                  {
                    label: 'Select',
                    value: '',
                  },
                  ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
                ]?.map((team) => ({
                  label: team?.label,
                  value: team?.value,
                  hexcode: team?.hexcode
                }))}
                optionClass="z-[500] max-h-[200px] overflow-y-scroll"
                className="min-w-[200px] rounded-[8px] h-[42px]"
                value={fromBranch}
                dropdownTitleClass='dark:text-white'
                onChangeOption={(val) => {selectFromBranch(val)}}
              />

          </div>
          <div className="flex justify-end">
            <div>
          <GlobalButton onClick={()=> {
            if(!selectItems?.length){
              return toast.error("Please select inventory to withdraw from use Get Item or Item usage")
            }
            handleSaveLocationInfo()
          }} showIcon={false} containerClass="mt-9" title="Withdraw Items" />
            <button onClick={ () => {
              if(!selectItems?.length){
                return toast.error("Please select inventory to withdraw from use Get Item or Item usage")
              }
              handleSaveLocationInfo("true")
            }} className="text-center w-full mt-5 font-bold gap-3 bg-btn-color dark:text-white px-5 py-[10px] rounded">Use items</button>
            </div>
          </div>
        </div>
          </div>
          <button onClick={handleGotoInventoryList} className="flex items-center font-bold gap-3 bg-btn-color px-5 py-[10px] rounded"><BsPlus size={25} className="text-white" /> <span className="text-white">Get Items</span></button>
        </div>

      </AppLayout>
      
  )
}