import { Link } from "react-router-dom";

const Layout = ({ children, className, content, ...props }) => {
    return (
        <div className={`bg-white w-[459px] rounded-xl p-4 ${className}`}>
            <div className="w-full">
                {content}
                {children}
            </div>
        </div>
    )
};

export const customizeHelper = {
    0: ({ ...props }) => <Layout content={props.content} className="bottom-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (<div onClick={() => props.gotoStep(props.current - 1)} className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
            <Link onClick={() => {
                localStorage.setItem("step", props.current + 1)
                // props.gotoStep(props.current + 1)
                // props.navigate("/branches")
            }} to="/branches"><div  className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div></Link>
        </div>
    </Layout>,
    1: ({ ...props }) => <Layout content={props.content} className="bottom-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (
            <Link to="/home" onClick={() => {
                props.gotoStep(props.current - 1)
                localStorage.setItem("step", props.current - 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div></Link>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
            <div onClick={() => {
                props.gotoStep(props.current + 1)
                localStorage.setItem("step", props.current + 1)
               }} className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div>
        </div>
    </Layout>,
    2: ({ ...props }) => <Layout content={props.content} className="left-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (
            <Link onClick={() => {
                props.gotoStep(props.current - 1)
                localStorage.setItem("step", props.current - 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div></Link>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
            <Link to="/reports" onClick={() => {
                props.gotoStep(props.current + 1)
                localStorage.setItem("step", props.current + 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div></Link>
        </div>
    </Layout>,
    3: ({ ...props }) => <Layout content={props.content} className="bottom-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (
            <Link to="/branches" onClick={() => {
                props.gotoStep(props.current - 1)
                localStorage.setItem("step", props.current - 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div></Link>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
           <Link to="/tasks" onClick={() => {
                props.gotoStep(props.current + 1)
                localStorage.setItem("step", props.current + 1)
               }}><div className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div></Link>
        </div>
    </Layout>,
    4: ({ ...props }) => <Layout content={props.content} className="bottom-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (
            <Link to="/reports" onClick={() => {
                props.gotoStep(props.current - 1)
                localStorage.setItem("step", props.current - 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div></Link>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
            <div onClick={() => props.gotoStep(props.current + 1)} className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div>
        </div>
    </Layout>,
    5: ({ ...props }) => <Layout content={props.content} className="left-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (
            <Link to="/tasks" onClick={() => {
                props.gotoStep(props.current - 1)
                localStorage.setItem("step", props.current - 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div></Link>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
            <Link to="/transfer-items-list" onClick={() => {
                props.gotoStep(props.current + 1)
                localStorage.setItem("step", props.current + 1)
               }}><div className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div></Link>
        </div>
    </Layout>,
    6: ({ ...props }) => <Layout content={props.content} className="bottom-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (
            <Link onClick={() => {
                props.gotoStep(props.current - 1)
                localStorage.setItem("step", props.current - 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div></Link>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
            <Link to="/profile-page" onClick={() => {
                props.gotoStep(props.current + 1)
                localStorage.setItem("step", props.current + 1)
               }}><div className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div></Link>
        </div>
    </Layout>,
    7: ({ ...props }) => <Layout content={props.content} className="bottom-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {props.current > 0 ? (
            <Link to="/transfer-items-list" onClick={() => {
                props.gotoStep(props.current - 1)
                localStorage.setItem("step", props.current - 1)
               }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div></Link>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p>
            <Link to="/home" onClick={() => {
                localStorage.removeItem("new-user")
                localStorage.removeItem("step")
                props.gotoStep(0)
                props.handleCloseTour()
            }} ><div  className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Finish</p></div></Link>
        </div>
    </Layout>,
}

export const tourGuide = [
    {
        selector: "[data-tour='step1']",
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">News & Updates</p>
            <p className="text-base text-060 !font-jakarta">Stay in the loop and also get the latest updates from the company.</p></div>,
        position: 'top',
        style: {
            left: '30%',
            top: '-2%'
        },
        // observe: '.home-page-announcement-card'
    },
    {
        selector: '[data-tour="step2"]',
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Locations Directory</p>
            <p className="text-base text-060 !font-jakarta">See all office locations worldwide and the number of team members in each office.</p></div>,
        position: 'top',
        style: {
            left: '30%',
            top: '-2%'
        },
        mutationObservables: ['[data-tour="step2"]']
    },
    {
        selector: '[data-tour="step3"]',
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Add new</p>
            <p className="text-base text-060 !font-jakarta">Click to add a new row of information</p></div>,
        position: 'top',
        style: {
            left: '-8%',
            top: '12%'
        },
        mutationObservables: ['[data-tour="step3"]']
    },
    {
        selector: '[data-tour="step4"]',
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Company Reports</p>
            <p className="text-base text-060 !font-jakarta">See all reports at a glance.</p></div>,
        position: 'top',
        style: {
            left: '30%',
            top: '-2%'
        },
        mutationObservables: ['[data-tour="step4"]']
    },
    {
        selector: '[data-tour="step5"]',
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Assigned Tasks</p>
            <p className="text-base text-060 !font-jakarta">View and organize your tasks</p></div>,
        position: 'top',
        style: {
            left: '30%',
            top: '-2%'
        },
        mutationObservables: ['[data-tour="step5"]']
    },
    {
        selector: ['[data-tour="step6"]'],
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Sync AI</p>
            <p className="text-base text-060 !font-jakarta">With this cutting-edge capability, employees can simply ask questions using natural language, making it easier than ever to find the answers they need.</p></div>,
        position: 'top',
        style: {
            left: '-8%',
            top: '12%'
        },
        mutationObservables: ['[data-tour="step6"]',]
    },
    {
        selector: ['[data-tour="step7"]'],
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Inventory Transfers</p>
            <p className="text-base text-060 !font-jakarta">Manage item transfers here.</p></div>,
        position: 'top',
        style: {
            left: '30%',
            top: '-2%'
        },
        mutationObservables: ['[data-tour="step7"]',]
    },
    {
        selector: ['[data-tour="step8"]'],
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Profile Management</p>
            <p className="text-base text-060 !font-jakarta">View and edit your profile</p></div>,
        position: 'top',
        style: {
            left: '30%',
            top: '-2%'
        },
        mutationObservables: ['[data-tour="step8"]',]
    },
]