import React,{useEffect,useState} from 'react'
import { Storage } from '../../../../Network/StorageClass/StorageClass'
import Col1 from './Col1'
import Col2 from './Col2'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import {getShiftSummaryNonAdmin, getWorkedHoursNonAdmin, getUpcommingShiftNonAdmin} from '../../../../Network/ServiceClass/dashboardService'
import { getWeek } from '../../../../utils/getWeek'
import addOneDay from '../../../../utils/incrementDate'
import formatDate from '../../../../utils/formatDate'
import {setShiftSummaryNonAdmin, setUpcomingShiftNonAdmin, setWorkHoursNonAdmin} from '../../../../Store/dashboard'

const storage = new Storage()
const NonAdmins = () => {
    const dispatch = useDispatch()

    const userDetails = storage.getUserDetails()
    const [isLoading, setIsLoading] = useState(false)

    const to = addOneDay(getWeek(0).lastDay.toUTCString())
    const from = formatDate(getWeek(0).firstDay.toUTCString())

    useEffect(()=>{
      setIsLoading(true)
      getShiftSummaryNonAdmin(from, to).then(val => {
        setIsLoading(false)
        dispatch(setShiftSummaryNonAdmin(val))
      }).catch(error => {
        setIsLoading(false)
      })

      getUpcommingShiftNonAdmin().then(val => {
        dispatch(setUpcomingShiftNonAdmin(val))
      }).catch()

      getWorkedHoursNonAdmin().then(val => {
        dispatch(setWorkHoursNonAdmin(val))
      }).catch()

    },[])

  return (
    <div>
      {!isLoading && <div>
        {/* <p className='text-[30px] font-semibold'>{userDetails.firstName} {userDetails.lastName}</p> */}

        <div className=' w-[100%] flex flex-row p-6 gap-6 mt-3 rounded-lg'>
           <Col1 />
           <Col2 />
        </div>

      </div>}
      {isLoading && <div className='w-[100%] mt-[50px] flex justify-center'><CircularProgress/></div>}
      

    </div>
  )
}

export default NonAdmins