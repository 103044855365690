import React from 'react'
import { Modal } from '@material-ui/core'
import moment from 'moment'


function UnblockModal({ handleClose, open, date, handleSubmit, loading}){
  return (
      <Modal onClose={handleClose} open={open}>
        <div className='h-screen flex items-center justify-center'>
          <div className='bg-white w-[533px] px-[73px] py-[59px]'>
            <p className='font-semibold text-lg text-center mb-[62px]'>Unblock Day</p>
            <p className='font-semibold text-lg mb-12'>Will you be able to work on the {moment(date).format("Do of MMMM, yyyy")}?</p>
            <div className='flex items-center gap-3 h-10'>
              <button onClick={loading ? null : handleClose} className='w-full h-full border rounded'>No</button>
              <button onClick={loading ? null : () => handleSubmit(moment(date).format("yyyy-MM-DD"))} className='w-full h-full bg-black text-white rounded'>{loading ? "Loading..." : "Yes"}</button>
            </div>
          </div>
        </div>
      </Modal>
  )
}

export default UnblockModal