import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const createAnnouncement = async (data) => {

    const response = await axios.post(`${conn_url}/api/v1/announcements`, data, storage.getConfig());
    return response.data;
 
}

export const uploadAnnouncementFile = async (data) => {
    
    const response = await axios.post(conn_url+"/api/v1/announcements/attachment/uploads", data, storage.getFileConfig());
    return {data: response.data, status:  response.status};
 
}

export const archiveAnnouncement = async (data) => {
    
    const response = await axios.post(conn_url+`/api/v1/announcements/${data}/archive`, {}, storage.getFileConfig());
    return {data: response.data, status:  response.status};
 
}

export const likeAnnouncement = async (id) => {
    const requestOptions = {
        method: 'POST',
        headers: storage.getConfig().headers,
        body: JSON.stringify({ type: 'like' })
    };

    const response = await fetch(`${conn_url}/api/v1/announcements/${id}/sentiment`, requestOptions)
    return {data: response.data, status:  response.status};
 
}

export const getAnnouncements = async (data,page=1) => {
    let response
    if(data === 'all'){
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}`, storage.getConfig());
    }else if(data === 'archived'){
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}&archived=true`, storage.getConfig());
    }else{
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}&liked=true`, storage.getConfig());
    }
    
    return {data: response.data, status:  response.status};
 
}

export const getAllAnnouncements = async ({data,page=1}) => {
    let response
    if(data === 'all'){
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}`, storage.getConfig());
    }else if(data === 'archived'){
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}&archived=true`, storage.getConfig());
    }else{
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}&liked=true`, storage.getConfig());
    }
    
    return {data: response.data.data, status:  response.status};
 
}

export const getAnnouncementTags = async (data,page=1) => {
    const response = await axios.get(conn_url+`/api/v1/announcements-tags`, storage.getConfig());
    
    return response.data;
 
}

export const getAnnouncementByTag = async (tagId,page=1) => {
    let response
    if(tagId === 'all'){
        const res = await axios.get(conn_url+`/api/v1/announcements?page=${page}`, storage.getConfig());
        response = res?.data
    }else {
        response = await axios.get(conn_url+`/api/v1/announcements-by-tag?tagId=${tagId}`, storage.getConfig());
    }
    
    return response.data;
 
}

export const newGetAnnouncements = async ({data,page=1}) => {
    let response
    if(data === 'all'){
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}`, storage.getConfig());
    }else if(data === 'archived'){
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}&archived=true`, storage.getConfig());
    }else{
        response = await axios.get(conn_url+`/api/v1/announcements?page=${page}&liked=true`, storage.getConfig());
    }
    
    return {data: response.data, status:  response.status};
 
}