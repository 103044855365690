import React, { useEffect } from 'react'
import FormModal from '../../../../Widgets/Animation/FormModal';
import { Stack, CircularProgress, InputAdornment } from '@mui/material';
import * as Yup from 'yup'
import {
  Field,
  FieldSelect,
  FillButton,
  OutlinedButton,
  Text,
  TextAndField,
} from '../../../../Widgets/FormUtils/FormUtils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryGroupsThunk, getInventoryManufacturersThunk } from '../../../../../Store/Inventory';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '485px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

function CreateInventoryModal({
  openInventoryModal,
  handleCloseInventoryModal,
  isLoading = false,
  handleSubmitItem,
}) {
  const dispatch = useDispatch()
  const manufacturers = useSelector((state) => state.inventory)?.manufacturers
  const formik = useFormik({
    initialValues: {
      name: "",
      packageCost: 0,
      description: "",
      groupId: "",
      manufacturerId: "",
      referenceNumber: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("This is required"),
      description: Yup.string().required("This is required"),
      groupId: Yup.string().required("This is required"),
      manufacturerId: Yup.string().required("This is required"),
      packageCost: Yup.number().min(1).required("This is required")
    }),
    onSubmit: (values)=>{
      setTimeout(()=>{
        if(!values.referenceNumber?.length){
          delete values["referenceNumber"]
        }
        handleSubmitItem(values)
      },400)
    }
  });

  const inventoryGroup = useSelector((state) => state.inventory)?.groups

  useEffect(()=>{
    dispatch(getInventoryGroupsThunk())
    dispatch(getInventoryManufacturersThunk())
  },[])

  return (
    <FormModal
      open={openInventoryModal}
      handleClose={handleCloseInventoryModal}
    >
      <Stack className='dark:bg-A06 dark:text-white' direction="column" alignItems="center" sx={style} gap={2}>
        <p className=" text-2xl text-color-424 dark:text-white font-bold text-center">
          Create Item
        </p>
        <TextAndField>
          <Text text="Name" />
          <Field
            value={formik.values.name}
            formik={formik}
            name="name"
          />
        </TextAndField>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          gap={4}
        >
          <TextAndField space={1}>
          <Text text={'Group'} />
          <FieldSelect
            list={inventoryGroup?.map((x) => ({ name: x?.name, id: x?.id }))}
            value={formik.values.groupId}
            formik={formik}
            name="groupId"
            width="100%"
          />
        </TextAndField>

        <TextAndField>
          <Text text="Item Price" />
          <Field
            value={formik.values.packageCost || ''}
            formik={formik}
            name="packageCost"
            width="100%"
            placeholder="0.00"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </TextAndField>
        </Stack>

        <TextAndField space={1}>
          <Text text={'Manufacturer'} />
          <FieldSelect
            list={manufacturers?.map((x) => ({ name: x?.name, id: x?.id }))}
            value={formik.values.manufacturerId}
            formik={formik}
            name="manufacturerId"
            width="100%"
          />
        </TextAndField>

        <TextAndField>
          <Text text="Description" />
          <Field
            value={formik.values.description}
            formik={formik}
            name="description"
          />
        </TextAndField>

        <TextAndField>
          <Text text="Reference Number" />
          <Field
            value={formik.values.referenceNumber}
            formik={formik}
            name="referenceNumber"
          />
        </TextAndField>

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          mt={6}
          gap={4}
        >
          <OutlinedButton
            themeColor="#00AD57"
            width="100%"
            text="Cancel"
            callBack={() => {
              formik.resetForm();
              handleCloseInventoryModal();
            }}
          />
          {!isLoading && (
            <FillButton
              width="100%"
              text="Submit"
              callBack={formik.handleSubmit}
            />
          )}

          {isLoading && <CircularProgress />}
        </Stack>

      </Stack>
    </FormModal>
  );
}

export default CreateInventoryModal;
