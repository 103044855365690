import React from 'react'
import {font} from '../../../../../../Widgets/Styles/font'

const DateLabel = ({detailsWeek, detailsDay ,type, padding}) => {
  const date = new Date(detailsWeek).toDateString().split(' ')
  const dayLetters = date[0]
  const day = date[2]
  const month = date[1]

  const daily = (detailsDay === undefined)? '' : detailsDay.split(' ')
  return (
    <div style={{padding:padding}}>
      {(type === 'week') && <div className='date-label' style={{display:"inline-flex", alignItems:"center"}}>
          
          <div style={{...font,fontSize:"30px"}}>
            {day}
          </div>

          <div style={{...font, fontSize:"12px", marginLeft:"5px"}}>
              <div>{dayLetters}</div>
              <div style={{color:"grey"}}>{month}</div>
          </div>

      </div> }

      {(type === 'day' && detailsDay !== undefined) && <div className='date-label' style={{display:"inline-flex", alignItems:"center"}}>
          
          <div style={{...font,fontSize:"30px"}}>
            {daily[0]}
          </div>

          <div style={{...font, fontSize:"12px", marginLeft:"5px"}}>
              <div>{daily[1]}</div>
              <div style={{color:"grey"}}>{daily[2]}</div>
          </div>

      </div> }

    </div>
  )
}

export default DateLabel