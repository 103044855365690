import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getNotifications, markNotificationAsSeen } from "../Network/ServiceClass/notification";
import { getGlobalSearch, updatePushStatus } from '../Network/ServiceClass/dashboardService';

export const markNotificationAsReadAsync = createAsyncThunk("dashboard/markNotification", markNotificationAsSeen)
export const getNotificationAsync = createAsyncThunk("dashboard/getNotification", getNotifications)

export const getGlobalSearchAsync = createAsyncThunk("dashboard/getGlobalSearch", getGlobalSearch)

export const updatePushNotificationStatus = createAsyncThunk("dashboard/updateStatus", updatePushStatus)

const initialState = {
    overview: null,
    workHours: [],
    shiftSwap: [],
    employeeOvertime: [],
    shiftCancellation: [],
    openSlot: [],
    workHoursNonAdmin: null,
    upcomingShiftNonAdmin: null,
    shiftSummaryNonAdmin: [],
    notifications: [],
    loading: false,
    globalSearch: []
}

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    extraReducers:{
        [updatePushNotificationStatus.pending]: (state) => {
            state.loading = true;
        },
        [updatePushNotificationStatus.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [updatePushNotificationStatus.rejected]: (state) => {
            state.loading = false;
        },
        [getGlobalSearchAsync.pending]: (state) => {
            state.loading = true;
        },
        [getGlobalSearchAsync.fulfilled]: (state, action) => {
            state.globalSearch = action.payload;
            state.loading = false;
        },
        [getGlobalSearchAsync.rejected]: (state) => {
            state.loading = false;
        },
        [getNotificationAsync.pending]: (state) => {
            state.loading = true;
        },
        [getNotificationAsync.fulfilled]: (state, action) => {
            state.notifications = action.payload;
            state.loading = false;
        },
        [getNotificationAsync.rejected]: (state) => {
            state.loading = false;
        },
        [markNotificationAsReadAsync.pending]: (state) => {
            state.loading = true;
        },
        [markNotificationAsReadAsync.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [markNotificationAsReadAsync.rejected]: (state) => {
            state.loading = false;
        },
    },
    reducers: {
        setWorkHoursNonAdmin: (state, action)=>{
            state.workHoursNonAdmin = action.payload;
        },
        setUpcomingShiftNonAdmin: (state, action)=>{
            state.upcomingShiftNonAdmin = action.payload;
        },
        setShiftSummaryNonAdmin: (state, action)=>{
            state.shiftSummaryNonAdmin = action.payload;
        },
        setOverview: (state, action)=>{
            state.overview = action.payload;
        },
        setWorkHours: (state, action)=>{
            state.workHours = action.payload;
        }, 
        setShiftSwap: (state, action)=>{
            state.shiftSwap = action.payload;
        }, 
        setEmployeeOvertime: (state, action)=>{
            state.employeeOvertime = action.payload;
        }, 
        setShiftCancellation: (state, action)=>{
            state.shiftCancellation = action.payload;
        }, 
        setOpenSlot: (state, action)=>{
            state.openSlot = action.payload;
        }, 
            
    }

});

export const {
    setWorkHours,
    setOverview,
    setEmployeeOvertime,
    setShiftSwap,
    setOpenSlot,
    setShiftCancellation,
    setShiftSummaryNonAdmin,
    setWorkHoursNonAdmin,
    setUpcomingShiftNonAdmin
   } = DashboardSlice.actions;
export default DashboardSlice.reducer;