import React from 'react'

import TableLayout from '../TableLayout';
import { CustomTableWithSearch } from '../../../../Widgets/Table/CustomTableWithSearch';
import { useSelector } from 'react-redux';



const Row7 = () => {

    const openSlotState = useSelector(state => state.dashboard.openSlot)
    const openSlot = openSlotState.map((val, index) => {
        return {
            id: index,
            col1: val.branchName,
            col2: val.openSlots,
        }
    })

    const headerListOpenSlot = ['Location Name', 'No of open slot',]
    
  return (

    <TableLayout styles='w-[100%]'>
        <p className='section-header dark:text-white'>Open Slots</p>
        <CustomTableWithSearch rows={openSlot} headerList={headerListOpenSlot} style='w-[100%] flex flex-col gap-3'/>
    </TableLayout>
  )
}

export default Row7


