import moment from 'moment';


export function getDatesArray(startDate, stopDate) {
  const dateArray = [];
  let currentDate = moment(startDate).format("YYYY");
  const endDate = moment(stopDate).format("YYYY");
  while (currentDate <= endDate) {
      dateArray.push( moment(currentDate).format('YYYY') )
      currentDate = moment(currentDate).add(1, 'years').format("YYYY");
  }
  return dateArray;
}

