import React, {useState} from 'react'
import { Service } from '../../../../../Network/ServiceClass/Auth';
import { Storage } from '../../../../../Network/StorageClass/StorageClass';
import { Loader } from '../../../../Widgets/Loader';
import AlertDialog from '../../../../Widgets/SuccessDialog';
import './VerifyEmail.css';

export const VerifyEmail = () => {

    const storage = new Storage();
    const Auth = new Service();

    const email = storage.getUserDetails().email;

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState({
        title: "Success",
        content: "Confirmation mail sent"
    });

    const [isloading, setIsloading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function sendEmail(){
        setIsloading(true);
        Auth.sendComfirmationEmail(email).then((value)=>{
            console.log(value.data); setIsloading(false);
            setMessage({title:"Notice", content: value.data.message}); handleClickOpen()
        }).catch((err)=>{
            setIsloading(false);
            console.log(err.response);
        });
    }



  return (
    <div className='verify-email'>

        <div className='inner-verify-email'>

            {!isloading ? <>

            <p>Verify email address @ <b>{email}</b></p><br></br>

            <button className='black-btn' onClick={sendEmail}>
                    Verify
            </button>

            </> : <Loader /> }

        </div>

        <AlertDialog open={open} handleClose={handleClose} message={message} />

    </div>
  )
}
