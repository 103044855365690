import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const getNotifications = async (page=1) => {
    const response = await axios.get(`${conn_url}/api/v1/users/notifications?page=${page}`, storage.getConfig());
    return response.data;
 
}

export const markNotificationAsSeen = async (data) => {
     await axios.post(`${conn_url}/api/v1/users/notifications/seen`, data, storage.getConfig());
    return data.ids;
 
}

