import { useNavigate } from "react-router"
import { BsArrowLeft } from "react-icons/bs"
import { useEffect, useState } from "react"
import CustomDropdown from "../../../Widgets/CustomDropdown"
import GlobalButton from "../../../Widgets/GlobalButton"
import AppLayout from "../../../Widgets/AppLayout"
import { useDispatch, useSelector } from 'react-redux'
import { getAllItemsThunk, getInventoryGroupsThunk } from '../../../../Store/Inventory';
import { Loader } from '../../../Widgets/Loader';
import { TableWrapper, Td, Tr, TableHeader } from "../../../Widgets/Table/TableWrapper"


export const QuickWithdrawalList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedItems, selectItem] = useState([])
  
  const inventoryItem = useSelector((state) => state.inventory)?.items
  const loading = useSelector((state) => state.inventory)?.loading
  const inventoryGroup = useSelector((state) => state.inventory)?.groups
  const [group, selectGroup] = useState({
    label: 'All groups',
    value: '',
  })

  const handleSelectGroup = (val) => {
    selectGroup(val)
  }

  useEffect(()=>{
    dispatch(getAllItemsThunk())
    dispatch(getInventoryGroupsThunk())
  },[])


  const handleSelectItem = (val) => {
    const filteredItem = selectedItems.filter((x) => x.id === val.id)

    if(filteredItem?.length > 0){
      const removeItem = selectedItems.filter((x) => x.id !== val.id)

      selectItem(removeItem)
    } else {
      const addItem = selectedItems
      addItem.push(val)
      selectItem(addItem)
    }
  }

  const handleSubmitItem = () => {
    const storedData = JSON.parse(localStorage.getItem("withdrawalItem"))
    const data = {
      ...storedData,
      withdrawalData: selectedItems
    };
    localStorage.setItem("withdrawalItem", JSON.stringify(data))
  }

  return (
    <section>
      <AppLayout
        custom="inventory"
        subCustom="quick-withdrawal"
        title="Withdraw Items"
        customTitle="Inventory"
        subtitle=""
      >

        <div className="flex items-center gap-4 mb-10">
          <BsArrowLeft className="cursor-pointer dark:text-white" size={25} onClick={() => navigate(-1)} />
          <p className="text-base font-bold dark:text-white">Items</p>
        </div>
        <div className="flex items-center gap-3 justify-between">
            <CustomDropdown
              data={[
                {
                  label: 'All groups',
                  value: '',
                },
                ...inventoryGroup?.map((x) => ({
                  label: x?.name,
                  value: x?.id,
                })),
              ]?.map((team) => ({
                label: team?.label,
                value: team?.value,
              }))}
              optionClass="z-[500]"
              className="min-w-[150px] w-max rounded h-[42px]"
              value={group}
              dropdownTitleClass='dark:text-white'
              onChangeOption={handleSelectGroup}
            />

              <GlobalButton onClick={()=>{
                handleSubmitItem()
                navigate("/quick-withdrawal")
                }} showIcon={false} title="Done" />
        </div>

        <TableWrapper className="mt-6 !max-h-[70vh] mb-12">
          <table>
              <TableHeader>
                <Td className="w-[3%]"></Td>
                <Td className="py-4 w-[20%]">Reference number #</Td>
                <Td className="w-[20%] "># Suppliers</Td>
                <Td className="w-[20%] ">Group</Td>
                <Td className="w-[18%] ">Name</Td>
                <Td className="!border-none w-[19%] ">Manufacturer</Td>
              </TableHeader>
            <tbody>
            {!loading &&
                inventoryItem?.filter((inv) => inv.groupId?.includes(group.value)).map((item) => (
                  <Tr
                    className="hover:bg-5F5 hover:dark:bg-444"
                  >
                  <Td className="py-4"><input defaultChecked={selectedItems?.some((x) => x.id === item.id)} onChange={()=>{handleSelectItem(item)}} type='checkbox' className="h-[20px] w-[20px]" /></Td>
                    <Td className="">{item?.referenceNumber}</Td>
                    <Td className="">{item?.manAndSupp?.length || 0}</Td>
                    <Td className="">{item?.group?.name}</Td>
                    <Td className="">{item?.name}</Td>
                    <Td className="">{item?.inventoryManufacturer?.name || "N/A"}</Td>
                    {/* <td>{item?.packageCost}</td> */}
                    {/* <td><p className="text-26E py-[10px] px-[14px] bg-CF6 rounded-[40px] w-max">Received</p></td> */}
                  </Tr>
                ))}
              {!loading && inventoryItem?.length === 0 && (
                <tr>
                  <td className="text-center pt-4 text-A5A" colSpan={6}>
                    <p>No inventory available</p>
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </TableWrapper>
        
      </AppLayout>
     
    </section>)
}