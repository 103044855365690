import React, { useEffect, useState } from 'react';
import { getHolidaysThunk } from '../../../Store/holiday';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { getBranchesAsync } from '../../../Store/Branches';
import PlaceHolderOne from '../../Widgets/placeholders/PlaceHolderOne';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import CreateHoliday from './CreateHoliday';
import AppLayout from '../../Widgets/AppLayout';
import CustomDropdown from '../../Widgets/CustomDropdown';
import { TableWrapper, Td, Tr } from '../../Widgets/Table/TableWrapper';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { holidayTutorial } from '../mock';

const Holiday = () => {
  const dispatch = useDispatch();
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;
  const [branches, setBranches] = useState([]);
  const existingHoliday = useSelector(state => state.holidays)?.holidays?.branchHolidays
  const isLoading = useSelector(state => state.holidays)?.loading
  const [selectedBranch, setSelectedBranch] = useState({ label: 'All locations', value: '1' });

  useEffect(() => {
    if (selectedBranch.value === '1') {
      dispatch(getHolidaysThunk())
    } else {
      dispatch(getHolidaysThunk({ branchId: selectedBranch.value }))
    }
  }, [dispatch, selectedBranch]);

  useEffect(() => {
    dispatch(getBranchesAsync()).then((val) => {
      setBranches(val.payload.data);
    });
  }, []);

  const [openCreateHoliday, setOpenCreateHoliday] = useState(false)
  const handleOPenCreatHoliday = () => setOpenCreateHoliday(true)
  const handleCloseCreatHoliday = () => setOpenCreateHoliday(false)
  const mainAnim = {
    exit: {
      opacity: 0,
      transition: { ease: 'easeOut', duration: 1 },
    },
  };

  return (
    <AppLayout
      custom="company"
      subCustom="holiday"
      title="Company Holidays"
      customTitle=" "
      subtitle="Managing Company Holidays"
      RightComponent={<TutorialPanel items={holidayTutorial} />}
    >
      <CreateHoliday 
          open={openCreateHoliday} 
          handleClose={handleCloseCreatHoliday} 
          callback={handleCloseCreatHoliday}/>
      <div className="pt-4 flex flex-col h-[70vh] gap-4 p-7 border-1 rounded-md border-b-gray-300 dark:border-333">
        <div className="flex justify-between">

          <CustomDropdown
            data={[
              { name: 'All locations', id: '1' },
              ...branches,
            ]?.map((branch) => ({
              label: branch?.name,
              value: branch.id,
              hexcode: branch?.hexcode
            }))}
            className="min-w-[200px] w-36 !rounded-lg !py-[12px] "
            value={selectedBranch}
            dropdownTitleClass="text-A5A"
            optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
            onChangeOption={(val) => {
              setSelectedBranch(val);
            }}
          />
          
        {[1,2].includes(privilege)&&(<ButtonWithIcon title="New" type='text' handleClick={handleOPenCreatHoliday}/>)}
          
        </div>

        {!isLoading ? 
          <>
            { existingHoliday?.length > 0 ?
            <TableWrapper className={''}>
              <table>
                <thead className="bg-[#F0F0F5] font-bold sticky top-0 dark:bg-1F1">
                  <Td className="py-4 px-4 !text-left"><p className='font-bold'>Holiday name</p></Td>
                  <Td className="!text-left pl-4"><p className='font-bold '>Location Name</p></Td>
                  <Td  className={'!border-none !text-left pl-4'}><p className='font-bold'>Date</p></Td>
                </thead>

                <tbody>
                  {existingHoliday?.map((holiday, index) => (
                    <Tr
                      className={`${
                        existingHoliday?.length - 1 === index ? '' : 'border-b-1'
                      }`}
                    >
                      <Td className="py-4 px-4 !text-left">{holiday?.name}</Td>
                      <Td className='px-4 !text-left'>{holiday?.branch?.name}</Td>
                      <Td className='px-4 !text-left !border-none'>
                        {moment(holiday?.date).format('Do ddd MMMM YYYY')}
                      </Td>
                    </Tr>
                  ))}
                </tbody>
              </table>
            </TableWrapper>
             : 
              <div className="w-[100%] flex justify-center">
                <PlaceHolderOne showPlaceholder={false} />
              </div>
            }
          </>
         
          :
            <div className="w-[100%] flex justify-center mt-2 dark:bg-A06">
              <CircularProgress className='dark:text-white' />
            </div>
          }
      </div>
          

    </AppLayout>
   
  );
};

export default Holiday;
