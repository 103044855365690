import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ShiftForOfferView from './ShiftForOfferView';
import {styled} from '@mui/material/styles'
import { Table } from '../Table/Table';
import { useDispatch,useSelector} from 'react-redux';
import {setOpenDrawer} from '../../../../Store/swapOffers'
import {font} from '../../../Widgets/Styles/font'
import dayjs from 'dayjs';



const AntTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  width: '100%',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 0,
    width: '0',
    backgroundColor: '#635ee7',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  width: '100%',
  padding: '0',
  margin: 0,
  ...font,
  
  '&:hover': {
    color: '#5175F3',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#5175F3',

  },
  '&.Mui-focusVisible': {
    backgroundColor: 'red',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const dispatch = useDispatch()
  const handleOpenDrawer = (open, data) => {
    dispatch(setOpenDrawer({state:open, data:data}))
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const swapRequesterState = useSelector((state)=> state.swapOffers.requestOffers)

  return (
    
    <div className='w-[100%]'>
      <div className='flex gap-1 w-full' >
        <AntTabs  value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" orientation="horizontal">

          {swapRequesterState.map((value, index) => {
           return <AntTab  label={<ShiftForOfferView requesteeShift={value.requesteeShift} requesteeBranch={value.branch}/>} 
            {...a11yProps(index)} sx={{minWidth: '45%', marginRight: '5px'}}/>
          })}


        </AntTabs>
      </div>
      <Table>

          {swapRequesterState.map((val,ind) => {
            return  <tr className='dark:text-white border-b-1 cursor-pointer dark:bg-828 hover:bg-5F5 dark:hover:bg-1F1 dark:border-333' onClick={()=> handleOpenDrawer(true, val)}>
            <td className="py-4 px-4">{val.requester.firstName}-{val.requester.lastName}</td>
            <td className="">{val.requester.email}</td>
            <td className="">{dayjs(val.requesterShift.start).format('ddd MMM DD')}</td>
            <td className="">{dayjs(val.requesterShift.start).format('h:mm:ss A')}-{dayjs(val.requesterShift.end).format('h:mm:ss A')}</td>
            <td className="">{val.branch.address}</td>
        </tr>
          })}


</Table>
    </div>
  );
}

