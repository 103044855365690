import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./MainContent.css";
import { BsChevronDown } from "react-icons/bs";

import AnnouncementOne from "./Widget/AnnouncementOne";
import { getAnnouncements } from "../../../Network/ServiceClass/announcementService";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../Widgets/CustomDropdown";
import { getDashboardAnnouncmentThunk } from "../../../Store/announcement";
import moment from "moment";
import SkeletonLoading from "../../Widgets/SkeletonLoading";
import {ReactComponent as NoValuePlaceholder} from '../../../Assets/icons/no_value_placeholder.svg'

const MainContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  // const [announcement, setAnnouncement] = useState([]);
  const announcement = useSelector(state => state.announcement.announcements)
  const loading = useSelector(state => state.announcement.loading)
  const [newsType, setNewsType] = useState({label: 'All News', value: ''});
  const company = useSelector((state) => state.company.activeCompany);

  useEffect(() => {
    dispatch(getDashboardAnnouncmentThunk({data: 'all', page: 1}))
  }, [company]);

  // const statusColor = (name) => {
  //   const text = name?.toLowerCase();
  //   if (text === "not started") return "text-[#676767]";
  //   else if (text === "in progress") return "text-[#FFCC00]";
  //   else if (text === "done") return "text-[#5CB26E]";
  //   else return "text-[red]";
  // };

  const handleNewOnChange = (e) => {
    setNewsType(e)
  }


  return (
    <div className="w-full mb-16">
      <div className="flex flex-col gap-2">
          <div className=" flex justify-between mb-8 w-full items-center">
            <p className="font-semibold text-xl text-A5A dark:text-white">Announcements</p>
          <CustomDropdown
                  data={[
                    { label: "All News", value: ''},
                    { label: "Medical News", value: 'medical'},
                    { label: "HR News", value: 'hr'},
                  ]}
                  className="h-[43px] dark:border-333 !border-0 w-fit !rounded-[24px] gap-2 bg-D57 m-0 ml-auto "
                  value={newsType}
                  dropdownTitleClass="text-white"
                  optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
                  onChangeOption={handleNewOnChange}
                  mainContainerStyle=" w-36"
                  iconColor='text-white'
                />
          </div>
          <div className="h-[420px] overflow-y-scroll" data-tour="step1">

          {!loading && (<div className="grid grid-cols-3 gap-5 auto-rows-fr md:grid-cols-1 ">
            {
              React.Children.toArray(announcement?.map((eachAnnounce) => (
            <div className=" border-[0.4px] border-custom-border rounded-lg p-5 h-full bg-AFA dark:bg-828 shadow-announcement-card">
              <div className="flex items-center justify-between mb-6"><p className="font-semibold text-lg text-555 dark:text-white">{eachAnnounce?.title}</p><p className="font-semibold text-sm text-D57">{moment(eachAnnounce?.createdOn).format("DD MMM yyyy")}</p></div>
              <p className="text-sm text-555 dark:text-8C8">{eachAnnounce?.description}</p>
            </div>

              )))
            }
          </div>)}

          {
            !loading && (announcement?.length === 0) && <div className="w-full h-[300px] flex items-center justify-center"><NoValuePlaceholder /></div>
          }
          </div>



      </div>

      <div className="flex flex-col gap-3 mt-8 rounded-xl">
        <CustomText text={"Quick Links"} />

        <div className="flex justify-between w-[75%]">
          <ColumnContainer
            list={[
              {
                name: "My Schedule",
                handleClick: () => navigate("/schedules"),
              },
              {
                name: "Location Schedule",
                handleClick: () => navigate("/branch-schedule"),
              },
              {
                name: "Combined Schedule",
                handleClick: () => navigate("/combined-schedule"),
              },
            ]}
          />

          <ColumnContainer
            list={[
              { name: "Messages", handleClick: () => navigate("/messages") },
              {
                name: "My Profile",
                handleClick: () => navigate("/profile-page"),
              },
              { name: "Skill Sets", handleClick: () => navigate("/skillset") },
            ]}
          />

          <ColumnContainer
            list={[
              { name: "Reports", handleClick: () => navigate("/reports") },
              { name: "Tasks", handleClick: () => navigate("/tasks") },
              { name: "Job Roles", handleClick: () => navigate("/jobs") },
            ]}
          />

          <ColumnContainer
            list={[
              {
                name: "Inventory",
                handleClick: () => navigate("/inventory-items"),
              },
              { name: "Data", handleClick: () => navigate("/company-data") },
              {
                name: "Job Groups",
                handleClick: () => navigate("/job-groups"),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MainContent;

export const AnnouncementCard = ({
  header,
  subHeader,
  news,
  day,
  newsStyle,
  dayStyle,
  className,
  showImage = false,
  image = "",
  handleClick = () => {},
}) => {
  return (
    <div
      onClick={handleClick}
      className={`flex flex-col gap-2 bg-[#FCFCFC] dark:bg-292 p-2 rounded-lg dark:text-white items-start shadow-small dark:shadow-222 ${className}`}
    >
      <div className="flex justify-between w-full items-start">
        <p className="text-[18px] font-semibold">{header}</p>
        <p
          className={`text-9A9 dark:text-gray-300 text-[12px] mt-1 w-[30%] text-right ${dayStyle}`}
        >
          {day}
        </p>
      </div>

      {showImage ? (
        <div className="text-[12px] flex gap-6 items-center">
          <img src={image} alt="avatar" className="h-5 w-5 rounded-full" />
          <p className={`text-[#E47AFF] ${newsStyle}`}>{news}</p>
        </div>
      ) : (
        <p className="text-sm text-5F5 dark:text-gray-400">{subHeader}</p>
      )}
    </div>
  );
};

const CustomText = ({ text }) => {
  return (
    <p className=" text-xl mb-5 text-A5A font-bold dark:text-4F4">{text}</p>
  );
};

export const DropDown = ({ text, handleClick }) => {
  return (
    <div className="flex sticky top-0 bg-white dark:bg-1F1">
      <div
        onClick={handleClick}
        className="flex items-center gap-3 px-3 py-2 mb-3  cursor-pointer rounded-3xl bg-[#E0FAFF] dark:text-white dark:bg-292"
      >
        <p className="font-semibold text-[14px]">{text}</p>
        <BsChevronDown />
      </div>
    </div>
  );
};

const ColumnContainer = ({ list = [] }) => {
  return (
    <div className="flex flex-col gap-3">
      {list.map((val, index) => (
        <p
          key={index}
          onClick={val.handleClick}
          className="text-base font-medium text-grey-600 dark:text-EBE cursor-pointer hover:text-D57"
        >
          {val.name}
        </p>
      ))}
    </div>
  );
};
