import React,{useEffect, useState} from 'react'
import { CircularProgress } from '@mui/material';
import { ResponsiveContainer, Area, AreaChart, YAxis, XAxis,Tooltip } from 'recharts';
import { getUnavailableStaffTimeLine } from '../../../../../Network/ServiceClass/dashboardService';
import PlaceHolderOne from '../../../../Widgets/placeholders/PlaceHolderOne';
import { useSelector,useDispatch } from 'react-redux';
import { Text, TextAndField  } from '../../../../Widgets/FormUtils/FormUtils';
import { getBranchesAsync, fetchJobRolesAsync } from '../../../../../Store/Branches';
import dayjs from 'dayjs';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import capitalizeFirstLetter from '../../../../../utils/capitalizeFirstLetter';

const Row6 = () => {

    const distpatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [foundData, setFoundData] = useState(true)
    const [isLoading2, setIsLoading2] = useState(false)


    const company = useSelector(state => state.company.activeCompany)
    // const branches = useSelector(state => state.branches.branches)?.data
    // const jobRoles = useSelector(state => state.branches.jobRoles).map(val => ({id: val.id, name: val.title}))

    const [branches, setBranches] = useState([])
    const [jobRoles, setJobRoles] = useState([])

    
    const [selectedBranch, setSelectedBranch] = useState({
        label: 'All Locations',
        value: '1',
        hexcode: ''
    })

    const today = new Date();

   

    // Get the date 30 days from now
    const next30thDay = new Date();
    next30thDay.setDate(today.getDate() + 30);

    // Format the dates as strings (YYYY-MM-DD)
    const formattedToday = today.toISOString().split('T')[0];
    const formattedNext30thDay = next30thDay.toISOString().split('T')[0];

    function generateDateRange(start, end) {
        const dates = [];
        let currentDate = dayjs(start).startOf('day');
      
        while (currentDate.isSame(end, 'day') || currentDate.isBefore(end, 'day')) {
          dates.push(currentDate.format('YYYY-MM-DD'));
          currentDate = currentDate.add(1, 'day');
        }
      
        return dates;
      }

    useEffect(()=> {
        setIsLoading2(true)
        distpatch(getBranchesAsync())
        .then(val => {
            setBranches(val.payload.data)
        })
        distpatch(fetchJobRolesAsync())
        .then(val => {
            setIsLoading2(false)
            setJobRoles(val.payload.data.map(val => ({id: val.id, name: val.title})))
        })
    },[company])

    useEffect(()=> {
        const thirtyDays = generateDateRange(formattedToday, formattedNext30thDay)

        let payload ={
            from: formattedToday,
            to: formattedNext30thDay
        }
        let id
        if (selectedBranch.value !== '1') {
           id = selectedBranch.value
          }
       
          

        setIsLoading(true)
       
        getUnavailableStaffTimeLine(payload, id)
        .then(val => {
            setIsLoading(false)
            const processResult = thirtyDays.map(day => {
                const foundShift = val.filter(el => el.date === day)
                if(foundShift[0]) return foundShift[0]
                else return {date: day, count:0}
            })
            setData(processResult)
            setFoundData(processResult.reduce((total, item) => total + item.count, 0) > 0 ? true : false)
        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    },[company, selectedBranch])

  return (
    <div className='w-[100%] shadow-shift-shadow p-3 rounded-lg gap-3 flex flex-col'>
        {!isLoading2 && <div className='flex items-start gap-2'>
            <p className='text-[16px] font-semibold w-[30%] dark:text-white'>Staff Unavailability (Next 30 Days)</p>
            <div className='w-[30%] flex gap-4'>
                <TextAndField>
                    <Text className="dark:text-white" text={'Select Location'}/>
                    <CustomDropdown
                        trimtext
                        trimLength={13}
                        data={[
                        {
                            label: 'All Locations',
                            value: '1',
                            hexcode: ''
                        },
                        ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
                        ]}
                        mainContainerStyle='w-44'
                        className="h-10 rounded "
                        dropdownTitleClass="text-A5A"
                        optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                        value={selectedBranch}
                        onChangeOption={(val) => {
                        setSelectedBranch(val);
                        }}
                    />

                </TextAndField>



            </div>



        </div>}
        {!isLoading && <div>

            {foundData && <ResponsiveContainer width="95%" height={400}>
                <AreaChart data={data} margin={{top: 50, bottom: 50, left: 20}}>

                    <YAxis allowDecimals={false} dataKey="count" tickSize={22} tickLine={false} axisLine={false} padding={{ left: 0 }} fontSize={12} />
                    <Area type="monotone" dataKey="count" stroke="#ffcccc" fill="#ffcccc" />
                    <XAxis interval={Math.floor(data/5)} angle={0} tick={{ stroke: 'rgb(185, 183, 183)', strokeWidth: 0.1 }} axisLine={{strokeWidth: 0.2}} dx={10} dy={8} dataKey="date" tickLine={false} fontSize={10} padding={{ left: 0, right: 20 }} tickSize={10} allowDataOverflow={true} minTickGap={30} style={{ backgroundColor: "#fff", zIndex: "2" }} />
                    <Tooltip content={(props) => CustomTooltip(props)}/>
                </AreaChart>

            </ResponsiveContainer>}
        {!foundData && <p className='mt-3'><PlaceHolderOne showPlaceholder={false} /></p>}
        </div>}

        
        {isLoading && <div className='w-[100%] h-[170px] flex justify-center mt-3'><CircularProgress/></div> }
    </div>
  )
}

export default Row6

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      // You can access the tooltip values from 'payload'
      return (
        <div className="custom-tooltip bg-white dark:bg-292 dark:text-white rounded px-4 py-2 text-[14px] shadow-shift-shadow-small">
          <p>Date: {dayjs(label).format('DD MMM YYYY')}</p>
          {payload?.map((entry, index) => (
            <p key={`item-${index}`} >
              {`${capitalizeFirstLetter(entry.name)}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  }
  