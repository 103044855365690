import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomPopper } from '../../Widgets/CustomPopper'
import { ClickableItem } from '../../Widgets/ClickableItem'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import CustomDropdown from '../../Widgets/CustomDropdown'
import { getInventoryPendingTransferApprovalThunk } from '../../../Store/Inventory'
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter'
import { Storage } from '../../../Network/StorageClass/StorageClass'
import { inventoryUpdateTransferItemsStatus } from '../../../Network/ServiceClass/InventoryService'
import { toast } from 'react-toastify'
import ExportTemplate from './Widget/ExportTemplate'

const storage = new Storage()
const PendingTransfer = ({statusColor, branches}) => {
    const dispatch = useDispatch()

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id

    const [toggleMenu, setToggleMenu] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})
    const [menuIndex, setMenuIndex] = useState(null)


    const transferApprovalRow = useSelector(state => state.inventory)?.pendingTransferApproval
   

    const getPendingTransfer = (id=null) => {
        dispatch(getInventoryPendingTransferApprovalThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getPendingTransfer(withdrawalBranch.value)
    },[withdrawalBranch])
    

    const handleApprove = (value) => {
        setIsLoadingAction(true)

        const data = {
            referenceNumber: value?.referenceNumber,
            locationId: value?.transferLocation?.id,
            status: "approved",
            trackingStatus: "shipped",
            teamMemberId: teamMembershipId
        }

        inventoryUpdateTransferItemsStatus(data)
        .then(val => {
            if (val?.status === 200){
                toast.success('Approved Successfully')
                setToggleMenu(null)
                getPendingTransfer(withdrawalBranch.value)
            }else toast.error(val?.data?.message)
            setIsLoadingAction(false)
        })
    }
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>


            <ExportTemplate 
                title='Pending Transfer Request'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={transferApprovalRow?.map(x => ({
                    'Reference Number': x?.referenceNumber,
                    'Batch ID': x?.batchIdentifier,
                    'Name': x?.reference?.name,
                    'Manufacturer': x?.reference?.inventoryManufacturer?.name,
                    'Transfered From': x?.withdrawLocation?.name,
                    'Transfered To': x?.transferLocation?.name,
                    'Unit': x?.measurementUnit?.name,
                    'Amount': x?.quantityWithdrawn,
                    'Status': capitalizeFirstLetter(x?.status)



                }))}
                fileName={'transfer-request'}
            />


            <div className='rounded-md border-1 dark:border-333 dark:text-white mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className=''>
                <tr className='bg-white dark:bg-444 font-bold sticky top-0'>
                    <td className='border-1 dark:border-333 border-t-0 border-l-0 text-center pl-4 py-4 rounded-tl-md '>Ref No.</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>BatchID</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Name</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Manufacturer</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Transfered From</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Transfered To</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Unit</td>
                    <td className='border-1 dark:border-333 border-t-0 text-center '>Amount</td>
                    <td className='border-1 dark:border-333 border-t-0 border-r-0 text-center rounded-tr-md'>Status</td>

                </tr>

                {!isLoading ? <tbody>
                    {transferApprovalRow?.length !== 0 ?
                        transferApprovalRow.map(val => (
                            <tr className='border-1 dark:bg-828 dark:border-333 dark:text-white font-bold border-l-0 text-A5A'>
                                    <td className='text-center border-r-1 dark:border-333 py-4 pl-4'>{val?.referenceNumber}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.batchIdentifier}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.reference?.name}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.reference?.inventoryManufacturer?.name}</td>


                                    <td className='text-center border-r-1 dark:border-333'>
                                        <div className='flex items-center justify-center gap-2'>
                                            <p className='w-2 h-2 rounded-full' style={{background: val?.withdrawLocation?.hexcode}}/>
                                            <p>{val?.withdrawLocation?.name}</p>
                                        </div>
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>
                                        <div className='flex items-center justify-center gap-2'>
                                            <p className='w-2 h-2 rounded-full' style={{background: val?.transferLocation?.hexcode}}/>
                                            <p>{val?.transferLocation?.name}</p>
                                        </div>
                                    </td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.measurementUnit?.name}</td>
                                    <td className='text-center border-r-1 dark:border-333'>{val?.quantityWithdrawn}</td>
                                    <td className='text-center'>
                                        {isAllow() ? 
                                            <div 
                                                onClick={(e)=> {
                                                    e.stopPropagation()
                                                    !isLoadingAction && setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                    !isLoadingAction && setMenuIndex(val?.id)
                                                }}
                                                className={`cursor-pointer text-center ${statusColor[val?.status]?.color}`}>
                                                {capitalizeFirstLetter(val?.status)}
                                            </div>:

                                            <div 
                                                className={`text-center ${statusColor[val?.status]?.color}`}>
                                                {capitalizeFirstLetter(val?.status)}
                                            </div>
                                        
                                        }

                                        {menuIndex === val?.id &&
                                            <CustomPopper 
                                                openPopper={toggleMenu} 
                                                handleClose={()=>{
                                                    !isLoadingAction && setToggleMenu(null)
                                                }} className='z-[1500]' placement='bottom'>
                                                <div className='min-h-[50px] min-w-[150px] mt-1 py-3 font-bold bg-white shadow-shift-shadow-small'>

                                                    {!isLoadingAction ? <div className='flex flex-col gap-2 text-[#738583] text-[12px]'>

                                                        <ClickableItem 
                                                            className={'py-1 text-[black]'} 
                                                            text={'Approve Request'} 
                                                            handleClick={()=> handleApprove(val)}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="flex h-full items-center justify-center"><CircularProgress/></div>
                                                    }
                                                
                                                </div>          
                                            </CustomPopper>
                                        }
                                        
                                    </td>

                            </tr>

                        ))
                        :<tr>
                            <td colSpan={9} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                    }
                </tbody>:
                <tr>
                    <td colSpan={9} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                </tr>
                }
                
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default PendingTransfer