import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { 
    createJobGroup,
    createAssigneeShiftslot, 
    makeReadyForSwap ,
    createShiftTemplate,
    updateShiftTemplate,
    getShiftTemplate,
    getAllJobGroup,
    getSwappableShift,
    fetchClockInClockOut,
    deleteShiftTemplate,
    getShiftSchedule, cancelSchedule,
    reassigningSchedule,
    getLimboSchedules
} from '../Network/ServiceClass/ShiftService';

import {Shift} from '../Network/ServiceClass/Shift'
const shiftClass = new Shift()

export const createAssigneeShiftslotAsync = createAsyncThunk('shift/createAssigneeShiftslot', createAssigneeShiftslot);
export const createStaffShiftAsync = createAsyncThunk('shift/createStaffShiftslot', shiftClass.createShiftSlot);
export const createAssigneeShiftslotNoLoadingAsync = createAsyncThunk('shift/createAssigneeShiftslotNoLoading', createAssigneeShiftslot);
export const createShiftTemplateAsync = createAsyncThunk('shift/createAssigneeShiftTemplate', createShiftTemplate);
export const updateShiftTemplateAsync = createAsyncThunk('shift/updateShiftTemplate', updateShiftTemplate);
export const deleteShiftTemplateAsync = createAsyncThunk('shift/deleteShiftTemplate', deleteShiftTemplate);
export const getShiftTemplateAsync = createAsyncThunk('shift/getShiftTemplate', getShiftTemplate);
export const getShiftScheduleAsync = createAsyncThunk('shift/getShiftSchedule', getShiftSchedule);
export const getShiftScheduleNoLoadingAsync = createAsyncThunk('shift/getShiftScheduleNoLoading', getShiftSchedule);
export const cancelScheduleAsync = createAsyncThunk('shift/cancelSchedule', cancelSchedule);
export const cancelScheduleNoLoadingAsync = createAsyncThunk('shift/cancelSchedule', cancelSchedule);
export const enableShiftSwapAsync = createAsyncThunk('shift/enableSwap', makeReadyForSwap);
export const createJobGroupAsync = createAsyncThunk('shift/createJobGroup', createJobGroup);
export const getAllJobGroupAsync = createAsyncThunk('shift/getAllJobGroup', getAllJobGroup);

export const getSwappableShiftAsync = createAsyncThunk('shift/getSwappableShift', getSwappableShift);
export const getClockinClockoutShiftAsync = createAsyncThunk('shift/fetchClockInClockOut', fetchClockInClockOut);

export const updateLimoScheduleAsync = createAsyncThunk('shift/updateShiftToLimbo', reassigningSchedule);
export const getLimboScheduleAsync = createAsyncThunk('shift/getLimboSchedule', getLimboSchedules);

const initialState = {
    toggleCalender: 'week',
    roaster: [],
    shiftSlot: [],
    weeklyDate: {to:'', from:''},
    weeklyDateLabel: {to:'', from:''},
    dailyTime: {to:6, from:0},
    dayNumber: 1,
    shiftTemplates: [],
    isLoadingRoaster: false,
    schedules: [],
    toggleShiftDetails: {state:false, data:[], user:{}},
    swappableShift: [],
    selectedBranch: 1,
    cellLoading: false,
    loading: false,
    viewChanging: false,
    swappableShifts: [],
    clockinShifts: [],
    roleTags: [],
    limboSchedules: [],
    limboLoading: false,
    limboCancelLoading: false,
}


const ShiftSlice = createSlice({
    name: 'shift',
    initialState: initialState,
    extraReducers: {
    [deleteShiftTemplateAsync.pending]: (state) => {
        state.loading = true
    },
    [deleteShiftTemplateAsync.fulfilled]: (state, action) => {
        state.loading = false
        return state
    },
    [deleteShiftTemplateAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [getClockinClockoutShiftAsync.pending]: (state) => {
        state.loading = true
    },
    [getClockinClockoutShiftAsync.fulfilled]: (state, action) => {
        state.clockinShifts = action.payload
        state.loading = false
        return state
    },
    [getClockinClockoutShiftAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [getSwappableShiftAsync.pending]: (state) => {
    },
    [getSwappableShiftAsync.fulfilled]: (state, action) => {
        state.swappableShifts = action.payload
        return state
    },
    [getSwappableShiftAsync.rejected]: (state, action) => {
    },
    [getAllJobGroupAsync.pending]: (state) => {
        state.loading = true
    },
    [getAllJobGroupAsync.fulfilled]: (state, action) => {
        state.roleTags = action.payload
        state.loading = false
        return state
    },
    [getAllJobGroupAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [createJobGroupAsync.pending]: (state) => {
        state.loading = true
    },
    [createJobGroupAsync.fulfilled]: (state, action) => {
        state.loading = false
        return state
    },
    [createJobGroupAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [cancelScheduleAsync.pending]: (state) => {
        state.loading = false
        state.limboCancelLoading = false
    },
    [cancelScheduleAsync.fulfilled]: (state, action) => {
        state.loading = false
        state.limboCancelLoading = true
        return state
    },
    [cancelScheduleAsync.rejected]: (state, action) => {
        state.loading = false
        state.limboCancelLoading = false
    },
    [cancelScheduleNoLoadingAsync.pending]: (state) => {
        state.cellLoading = true
    },
    [cancelScheduleNoLoadingAsync.fulfilled]: (state, action) => {
        state.cellLoading = false
        return state
    },
    [cancelScheduleNoLoadingAsync.rejected]: (state, action) => {
        state.cellLoading = false
    },

    [enableShiftSwapAsync.pending]: (state) => {
        state.loading = false
    },
    [enableShiftSwapAsync.fulfilled]: (state, action) => {
        state.loading = false
        return state
    },
    [enableShiftSwapAsync.rejected]: (state, action) => {
        state.loading = false
        state.viewChanging = true
    },
    [getShiftScheduleNoLoadingAsync.pending]: (state) => {
        state.loading = false
        state.viewChanging = true
    },
    [getShiftScheduleNoLoadingAsync.fulfilled]: (state, action) => {
        state.schedules = action?.payload || [];
        state.loading = false
        state.viewChanging = false
        return state
    },
    [getShiftScheduleNoLoadingAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [getShiftScheduleAsync.pending]: (state) => {
        state.loading = true
    },
    [getShiftScheduleAsync.fulfilled]: (state, action) => {
        state.schedules = action?.payload || [];
        state.loading = false
        return state
    },
    [getShiftScheduleAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [getShiftTemplateAsync.pending]: (state) => {
        state.loading = true
    },
    [getShiftTemplateAsync.fulfilled]: (state, action) => {
        state.shiftTemplates = action.payload;
        state.loading = false
    },
    [getShiftTemplateAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [createShiftTemplateAsync.pending]: (state) => {
        state.loading = true
    },
    [createShiftTemplateAsync.fulfilled]: (state) => {
        state.loading = false
    },
    [createShiftTemplateAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [updateShiftTemplateAsync.pending]: (state) => {
        state.loading = true
    },
    [updateShiftTemplateAsync.fulfilled]: (state) => {
        state.loading = false
    },
    [updateShiftTemplateAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [createAssigneeShiftslotNoLoadingAsync.pending]: (state) => {
        state.cellLoading = true
    },
    [createAssigneeShiftslotNoLoadingAsync.fulfilled]: (state) => {
        state.cellLoading = false
    },
    [createAssigneeShiftslotNoLoadingAsync.rejected]: (state, action) => {
        state.cellLoading = false
    },
    [createStaffShiftAsync.pending]: (state) => {
        state.cellLoading = true
    },
    [createStaffShiftAsync.fulfilled]: (state) => {
        state.cellLoading = false
    },
    [createStaffShiftAsync.rejected]: (state, action) => {
        state.cellLoading = false
    },
    [createAssigneeShiftslotAsync.pending]: (state) => {
        state.loading = true
    },
    [createAssigneeShiftslotAsync.fulfilled]: (state) => {
        state.loading = false
    },
    [createAssigneeShiftslotAsync.rejected]: (state, action) => {
        state.loading = false
    },
    [updateLimoScheduleAsync.pending]: (state) => {
        state.limboLoading = true
    },
    [updateLimoScheduleAsync.fulfilled]: (state) => {
        state.limboLoading = false
    },
    [updateLimoScheduleAsync.rejected]: (state, action) => {
        state.limboLoading = false
    },
    [getLimboScheduleAsync.pending]: (state) => {
        state.loading = true
    },
    [getLimboScheduleAsync.fulfilled]: (state, action) => {
        state.limboSchedules = Array.isArray(action.payload.data) ? action.payload.data : []
        state.loading = false
    },
    [getLimboScheduleAsync.rejected]: (state, action) => {
        state.loading = false
    },
    },
    reducers: {
        setToggleCalender: (state, action)=>{
            state.toggleCalender = action.payload;
        }, 
        setRoaster: (state, action)=>{
            state.roaster = action.payload;
        },
        setShiftSlot: (state, action)=>{
            state.shiftSlot = action.payload;
        },
        setWeeklyDate: (state, action)=>{
            state.weeklyDate = action.payload;
        },
        setWeeklyDateLabel: (state, action)=>{
            state.weeklyDateLabel = action.payload;
        },
        setDailyTime: (state, action)=>{
            state.dailyTime = action.payload;
        },
        setDayNumber: (state, action)=>{
            state.dayNumber = action.payload;
        }, 
        setIsLoadingRoaster: (state, action)=>{
            state.isLoadingRoaster = action.payload;
        },  
        setToggleShiftDetails: (state, action)=>{
            state.toggleShiftDetails = action.payload;
        },
        setSwappableShift: (state, action)=>{
            state.swappableShift = action.payload;
        },
        setSelectedBranch: (state, action)=>{
            state.selectedBranch = action.payload;
        }          
    }

});

export const {
    setToggleCalender, 
    setRoaster, 
    setWeeklyDate, 
    setWeeklyDateLabel, 
    setDailyTime, 
    setDayNumber,
    setShiftSlot, 
    setIsLoadingRoaster,
    setToggleShiftDetails,
    setSwappableShift,
    setSelectedBranch} = ShiftSlice.actions;
export default ShiftSlice.reducer;