import React,{useState} from 'react'
import {Input, TextArea, FillButton, OutlinedButton, TextAndField} from '../../../Widgets/FormUtils/FormUtils'
import JobPopper from '../../../Widgets/JobPopper';



const PopDetail = ({openPopper, setOpenPopper, data, setClikedRow, edit=false}) => {

   
    const [skillName, setSkillName] = useState(data.name)
    const [skillCode, setSkillCode] = useState(data.skillSetCode)

    const [decsribtion, setDecsribtion] = useState(data.desc)
    const [disableField, setDisableField] = useState(!edit)
    
    const handleClose = () => {
      setOpenPopper(null);
      setClikedRow(null)
      setDisableField(true)
      return true
    };
  
    const handleApprove = () => {
      
      handleClose()
     
    }

  return (
                  
      <JobPopper
        title={'Skill Details'} 
        openPopper={openPopper}
        handleClose={handleClose}
        handleClickEdit={()=> setDisableField(!disableField)}
      >

          {!disableField && <TextAndField space={0.2}>
            <p className='text-[#A5A5A5]'>Skill Code</p>
            <Input 
              value={skillCode}
              disable={disableField}
              onChange={(e)=>setSkillCode(e.target.value)}
            />
          </TextAndField>}

          {disableField && <TextAndField space={0.2}>
            
            <p className='text-[#A5A5A5]'>Skill Code</p>
            <p className='text-[14px]' >{skillCode}</p>
          </TextAndField>}

          {/* third row */}
          {!disableField && <TextAndField space={0.5}>
            <p className='text-[#A5A5A5]'>Skill Name</p>
            <Input 
              value={skillName}
              disable={disableField}
              onChange={(e)=>setSkillName(e.target.value)}
            />
          </TextAndField> }

          {disableField && <TextAndField space={0.2}>
            <p className='text-[#A5A5A5]'>Skill Name</p>
            <p className='text-[14px]'>{skillName}</p>
          </TextAndField>}

          {/* third four */}
          {!disableField && <TextAndField space={0.5} height='100px'>
            <p className='text-[#A5A5A5]'>Skill Description</p>
            <TextArea 
              value={decsribtion}
              disable={disableField}
              onChange={(e)=>setDecsribtion(e.target.value)}
            />
          </TextAndField>}

          {disableField && <TextAndField space={0.2} mb={4}>
            <p className='text-[#A5A5A5]'>Skill Description</p>
            <p className='text-[14px]'>{decsribtion}</p>
          </TextAndField>}


          {!disableField && <div className='flex justify-between mb-1'>
              <OutlinedButton 
                text={'Cancel'}
                callBack={handleClose}
              />

              <FillButton text={'Save'} callBack={handleApprove}/>

          </div>}
      </JobPopper>
  )
}

export default PopDetail

