import React, {useState, useEffect, useCallback} from 'react'
import SearchInput from '../../../Widgets/SearchInput'

import { Link } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';
import ButtonWithIcon from '../../../Widgets/ButtonWithIcon';
import { CreateSupplier } from './Widget/CreateSupplier';
import { EditSupplier } from './Widget/EditSupplier';
import { DeleteSupplier } from './Widget/DeleteSupplier';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { ClickableItem } from '../../../Widgets/ClickableItem';
import { CustomPopper } from '../../../Widgets/CustomPopper';
import { useDispatch, useSelector } from 'react-redux';
import { getInventorySuppliersThunk } from '../../../../Store/Inventory';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { TableWrapper, Td, Tr, TableHeader } from '../../../Widgets/Table/TableWrapper';


const SuppliersPage = () => {

    const dispatch = useDispatch()
    const supplierState = useSelector(state => state.inventory)

    const [openCreateSupplier, setOpenCreateSupplier] = useState(false)
    const [openEditSupplier, setOpenEditSupplier] = useState(false)
    const [openDeleteSupplier, setOpenDeleteSupplier] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [selectedData, setSelectedData] = useState(null)

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    
    const handleSearch = useCallback(() => {
        let data = supplierState?.suppliers
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                data = supplierState?.suppliers.filter((val) => (
                    val.name?.toLowerCase().match(pattern) ||
                    val.description?.toLowerCase().match(pattern) ))
        }
        return data
    
    },[searchValue, supplierState?.suppliers])


    useEffect(()=>{
        setIsLoading(true)
        dispatch(getInventorySuppliersThunk())
        .then(_ => setIsLoading(false))
        .catch(_ => setIsLoading(false))
    },[])

  return (
    <section>
        <CreateSupplier open={openCreateSupplier} handleClose={()=> setOpenCreateSupplier(false)}/>
        {openEditSupplier && <EditSupplier open={openEditSupplier} handleClose={()=> setOpenEditSupplier(false)} data={selectedData}/>}
        {openDeleteSupplier && <DeleteSupplier open={openDeleteSupplier} handleClose={()=> setOpenDeleteSupplier(false)} data={selectedData}/>}
        
        <CustomPopper openPopper={toggleMenu} 
            handleClose={()=>{
                setToggleMenu(null)
                setSelectedData(null)
            }} 
            className='z-[50]' 
            placement='bottom-start'>
            <div className='h-[70px] w-[120px] dark:bg-292 dark:text-white mt-1 py-3 font-bold flex flex-col justify-between text-[14px] bg-white rounded-sm shadow-select'>
                <ClickableItem text={'Delete'} handleClick={() => {
                    setOpenDeleteSupplier(true)
                    setToggleMenu(null)
                }} />

                <ClickableItem text={'Edit'} handleClick={() => {
                    setOpenEditSupplier(true)
                    setToggleMenu(null)
                }} />
            </div>          
        </CustomPopper>
         <AppLayout
            custom="inventory"
            subCustom="suppliers"
            title="Suppliers / Manufacturers"
            customTitle="Inventory"
            subtitle=""
        >

            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                <Link to="/suppliers">Suppliers & Manufactures</Link><span className="text-A5A"> / SUPPLIERS</span>
                </p>
            </div>

            <div className='flex justify-between items-center mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-10'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                />

                <ButtonWithIcon title='New' type='text' handleClick={()=>setOpenCreateSupplier(true)}/>
            

            </div>

            <TableWrapper className='mt-6 !max-h-[70vh] mb-12'>
                <table >
                    <TableHeader>
                        <Td className='py-4'>Last Updated</Td>
                        <Td className=''>Name</Td>
                        <Td className=''>Description</Td>
                        <Td className=''>Phone</Td>
                        <Td className=''>Contact</Td>
                        <Td className=''>Website</Td>
                        <Td className='!border-none'></Td>

                    </TableHeader>

                    {!isLoading ?<tbody>
                        {handleSearch().length !== 0 ?
                            handleSearch().map((val,index) => (
                                <Tr className='hover:bg-5F5 hover:dark:bg-292'>
                                        <Td className='py-4'>
                                        {dayjs(val?.createdOn).format('DD/MM/YYYY')}
                                        </Td>

                                        <Td>{val?.name}</Td>
                                        <Td>{val?.description}</Td>
                                        <Td>{val?.phone}</Td>
                                        <Td>{val?.contactInfo}</Td>
                                        <Td>{val?.website}</Td>
                                        <Td>
                                            <BiDotsVerticalRounded 
                                            onClick={(e)=> {
                                                e.stopPropagation()
                                                setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                setMenuIndex(index)
                                                setSelectedData(val)
                                            }}
                                            className='cursor-pointer'/>

                                            
                                        </Td>
                                </Tr>

                            ))
                            :

                        <tr>
                            <td colSpan={7} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                        }
                    </tbody>
                    : <tbody>
                    <tr>
                        <td colSpan={7} className='text-center border-1 border-white dark:border-A1A pt-10'><CircularProgress /></td>
                    </tr>
                </tbody>}
                </table>

            </TableWrapper>
            
        </AppLayout>
       
    </section>
  )
}

export default SuppliersPage