import React,{useState} from 'react'
import { SimpleSelect } from '../../../Widgets/FormUtils/FormUtils'
import { Pie } from '@ant-design/plots';
import {font} from '../../../Widgets/Styles/font'
import { useSelector, dispatch, useDispatch } from 'react-redux';
import { getWeek } from '../../../../utils/getWeek';
import formatDate from '../../../../utils/formatDate';
import addOneDay from '../../../../utils/incrementDate';
import {getShiftSummaryNonAdmin} from '../../../../Network/ServiceClass/dashboardService'
import {setShiftSummaryNonAdmin} from '../../../../Store/dashboard'
import { CircularProgress } from '@mui/material';


const dataColor = ['#F1F4FE', '#304691', '#415EC2', '#5175F3', '#7491F5', '#97ADF8', '#E4E9FD'];

const Summary = () => {

  const dispatch = useDispatch()
  const shiftSummaryState = useSelector(state => state.dashboard.shiftSummaryNonAdmin)

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Satuday']

  const summary = shiftSummaryState.map((val,index) => {
    return {
      type: days[index],
      value: val.count,
      color: dataColor[index]
    }
  })

  const config = {
    appendPadding:0,
    padding:0,
    data: summary,
    angleField: 'value',
    radius: 0.6,
    innerRadius: 0.8,
    
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '21px',
          ...font
        },
        content: 'Summary',
      },
    },

    // label: {
    //     type: 'inner',
    //     offset: '-50%',
    //     content: '{value}',
    //     style: {
    //       textAlign: 'center',
    //       fontSize: 14,
    //     },
    //   },
    label: false,

    label: false,
      colorField: 'type', // or seriesField in some cases
      color: dataColor,
  };

  const thisWeekTo = addOneDay(getWeek(0).lastDay.toUTCString())
  const thisWeekFrom = formatDate(getWeek(0).firstDay.toUTCString())

  const prevWeekTo = addOneDay(getWeek(-7).lastDay.toUTCString())
  const prevWeekFrom = formatDate(getWeek(-7).firstDay.toUTCString())

  const nextWeekTo = addOneDay(getWeek(7).lastDay.toUTCString())
  const nextWeekFrom = formatDate(getWeek(7).firstDay.toUTCString())

  const [weekValue, setWeekValue] = useState('current')
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeWeek = (value) => {
    setWeekValue(value)
    setIsLoading(true)
    let to, from

    if(value === 'current'){
        to = thisWeekTo
        from = thisWeekFrom
    }else if(value === 'prev'){
        to = prevWeekTo
        from = prevWeekFrom
    }else if(value === 'next'){
        to = nextWeekTo
        from = nextWeekFrom
    }
    getShiftSummaryNonAdmin(from, to).then(val => {
        setIsLoading(false)
        dispatch(setShiftSummaryNonAdmin(val))
        // console.log(val)
    }).catch(err => setIsLoading(false))

  }

  const list = [
    {id:'current',name:'Current Week'},
    {id:'prev',name:'Previous Week'},
    {id:'next',name:'Next Week'}
]



  return (
    <div className='flex flex-col border-1 p-3 rounded-lg h-[450px]'>
      <div className='flex justify-between'>
          <p className='text-sm font-medium'>Days Booked</p>
          <SimpleSelect placeholder='Choose week' list={list} value={weekValue} handleChange={(e)=> handleChangeWeek(e.target.value)}/>

      </div>

      {!isLoading && <>{shiftSummaryState.length !== 0 && <Pie {...config} />}</>}
      {isLoading && <div className='w-[100%] flex justify-center mt-3'><CircularProgress/></div>}


    </div>
  )
}

export default Summary