export const FolderIcon = ({ active }) => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.125 3H12.75V1.125L12.125 0.5H5.875L5.25 1.125V3H0.875L0.25 3.625V13.625L0.875 14.25H17.125L17.75 13.625V3.625L17.125 3ZM6.5 1.75H11.5V3H6.5V1.75ZM16.5 4.25V5.2L11.5 8V7.375L10.8875 6.75H7.1375L6.5 7.375V8L1.5 5.1375V4.25H16.5ZM10.25 8V9.25H7.75V8H10.25ZM1.5 13V6.575L6.5 9.4375V9.875L7.125 10.5H10.875L11.5 9.875V9.4875L16.5 6.6375V13H1.5Z"
      fill={"red"}
    />
  </svg>
);

export const EditIcon = ({ active, onClick, className }) => (
  <svg
    className={`${className}`}
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2Z"
      stroke="#5175F3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.9127 7.84003L7.72272 13.03C7.52272 13.23 7.33273 13.62 7.29273 13.9L7.01273 15.88C6.91273 16.6 7.41272 17.1 8.13272 17L10.1127 16.72C10.3927 16.68 10.7827 16.49 10.9827 16.29L16.1727 11.1C17.0627 10.21 17.4927 9.17003 16.1727 7.85003C14.8527 6.52003 13.8127 6.94003 12.9127 7.84003Z"
      stroke="#5175F3"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.1719 8.58008C12.6119 10.1501 13.8419 11.3901 15.4219 11.8301"
      stroke="#5175F3"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const AttachFileIcon = ({ active, onClick, className }) => (
  <svg
    className={`${className}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke-[#292D32] dark:stroke-white"
      d="M10.3297 10.1499L7.85969 12.6199C6.48969 13.9899 6.48969 16.1999 7.85969 17.5699C9.22969 18.9399 11.4397 18.9399 12.8097 17.5699L16.6997 13.6799C19.4297 10.9499 19.4297 6.50992 16.6997 3.77992C13.9697 1.04992 9.52969 1.04992 6.79969 3.77992L2.55969 8.01992C0.219687 10.3599 0.219687 14.1599 2.55969 16.5099"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const SendIcon = ({ active, onClick, className }) => (
  <svg
    className={`${className}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke-[#292D32] dark:stroke-white"
      d="M7.50733 2.22989L16.0673 6.50989C19.9073 8.42989 19.9073 11.5699 16.0673 13.4899L7.50733 17.7699C1.74733 20.6499 -0.602666 18.2899 2.27733 12.5399L3.14733 10.8099C3.36733 10.3699 3.36733 9.63989 3.14733 9.19989L2.27733 7.45989C-0.602666 1.70989 1.75733 -0.65011 7.50733 2.22989Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const OpenCliveIcon = ({ active, onClick, className, ...props }) => (
  <svg  
    onClick={onClick}
    className={`${className}`}
    width="48"
    height="48"
    viewBox="0 0 48 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.2031 24.0008C41.2031 14.5015 33.5024 6.80078 24.0031 6.80078C14.5038 6.80078 6.80313 14.5015 6.80313 24.0008C6.80313 26.7501 7.44906 29.3519 8.59853 31.6597C8.77006 32.0041 8.80645 32.3558 8.72729 32.6516L7.77432 36.2133C7.12197 38.6514 9.35251 40.8819 11.7906 40.2296L15.3523 39.2766C15.6481 39.1975 15.9998 39.2338 16.3442 39.4054C18.652 40.5548 21.2538 41.2008 24.0031 41.2008C33.5024 41.2008 41.2031 33.5001 41.2031 24.0008ZM24.0031 9.20078C32.1769 9.20078 38.8031 15.827 38.8031 24.0008C38.8031 32.1746 32.1769 38.8008 24.0031 38.8008C21.6334 38.8008 19.3971 38.2447 17.4142 37.2571C16.6183 36.8607 15.6667 36.7081 14.732 36.9582L11.1703 37.9111C10.5162 38.0862 9.91774 37.4877 10.0928 36.8336L11.0457 33.2719C11.2958 32.3372 11.1432 31.3856 10.7468 30.5897C9.75917 28.6068 9.20313 26.3705 9.20313 24.0008C9.20313 15.827 15.8293 9.20078 24.0031 9.20078Z" fill="white"/>
  </svg>

)

export const CloseCliveIcon = ({ active, onClick, className }) => (
  <svg 
    onClick={onClick}
    className={`${className}`}
    width="19" 
    height="22" 
    viewBox="0 0 19 22" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M3.92045 0.181818L9.54545 9.25852H9.71591L15.3409 0.181818H18.4517L11.5909 11.0909L18.4517 22H15.3409L9.71591 13.0938H9.54545L3.92045 22H0.809659L7.84091 11.0909L0.809659 0.181818H3.92045Z" fill="white"/>
  </svg>
)


export const CliveSendIcon = ({ active, onClick, className}) => (
  <svg 
    onClick={onClick}
    className={`${className}`}
    width="30" 
    height="30" 
    viewBox="0 0 30 30" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1437 6.63083C19.9737 6.83896 18.3964 7.36262 16.1827 8.10051L11.1714 9.77094C9.39139 10.3643 8.1021 10.7947 7.19146 11.182C6.23939 11.5869 5.90071 11.8692 5.79071 12.0584C5.45191 12.6412 5.45191 13.361 5.79071 13.9437C5.90071 14.1329 6.23939 14.4152 7.19146 14.8201C8.1021 15.2075 9.39139 15.6379 11.1714 16.2312C11.1995 16.2406 11.2273 16.2498 11.2547 16.2589C11.6343 16.3852 11.9479 16.4895 12.222 16.6328L17.5454 11.3681C17.8471 11.0697 18.3335 11.0724 18.6319 11.3741C18.9303 11.6758 18.9276 12.1623 18.6259 12.4607L13.3259 17.7022C13.4912 17.994 13.603 18.3302 13.7411 18.7453C13.7502 18.7727 13.7594 18.8005 13.7688 18.8286C14.3621 20.6086 14.7925 21.8979 15.1799 22.8085C15.5848 23.7606 15.867 24.0993 16.0563 24.2093C16.639 24.5481 17.3588 24.5481 17.9416 24.2093C18.1308 24.0993 18.4131 23.7606 18.818 22.8085C19.2053 21.8979 19.6357 20.6086 20.2291 18.8286L21.8995 13.8173C22.6374 11.6036 23.161 10.0263 23.3692 8.85629C23.5783 7.68074 23.4185 7.1814 23.1185 6.88146C22.8186 6.58152 22.3193 6.42171 21.1437 6.63083ZM20.8746 5.11797C22.1768 4.88632 23.3496 4.93941 24.2051 5.79491C25.0606 6.65041 25.1137 7.82322 24.882 9.12542C24.6518 10.4197 24.0903 12.1041 23.3794 14.2367L21.6745 19.3515C21.096 21.0869 20.6465 22.4354 20.232 23.41C19.8322 24.35 19.3882 25.1457 18.7139 25.5377C17.6537 26.1541 16.3442 26.1541 15.284 25.5377C14.6096 25.1457 14.1657 24.35 13.7658 23.41C13.3513 22.4354 12.9018 21.0869 12.3234 19.3515L12.311 19.3145C12.1084 18.7065 12.0466 18.5416 11.9591 18.4109C11.8611 18.2646 11.7354 18.1389 11.5891 18.0409C11.4584 17.9534 11.2935 17.8916 10.6855 17.689L10.6485 17.6766C8.91306 17.0982 7.56463 16.6487 6.59005 16.2342C5.64996 15.8343 4.85431 15.3904 4.46228 14.716C3.84591 13.6558 3.84591 12.3463 4.46228 11.2861C4.85431 10.6118 5.64996 10.1678 6.59005 9.76797C7.56464 9.35345 8.91309 8.90397 10.6485 8.3255L15.7633 6.62057C17.8959 5.9097 19.5803 5.34822 20.8746 5.11797Z" fill="#696969"/>
  </svg>
)