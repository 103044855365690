import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createATicket, getAllTickets } from '../Network/ServiceClass/Ticket';

export const getAllTicketThunk = createAsyncThunk('tickets/getAllTicketsThunk', getAllTickets)
export const createTicketThunk = createAsyncThunk('tickets/createTicket', createATicket)

const TroubleTicketSlice = createSlice({
  name: 'ticket',
  initialState: {
    loading: false,
    tickets: []
  },
  extraReducers: {
    [createTicketThunk.pending]: (state) => {
      state.loading = true;
    },
    [createTicketThunk.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createTicketThunk.rejected]: (state) => {
      state.loading = false;
    },
    [getAllTicketThunk.pending]: (state) => {
      state.loading = true;
    },
    [getAllTicketThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.tickets = action.payload
    },
    [getAllTicketThunk.rejected]: (state) => {
      state.loading = false;
    },
  }
})

export default TroubleTicketSlice.reducer