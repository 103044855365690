import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { copyRotations, getRotations, filterRotation, backupRotation, getBackupRotation, getRotationStatus, restoreBackupRotation, setRotationStatus } from '../Network/ServiceClass/RotationService';

export const getRotationASync = createAsyncThunk('rotations/getRotations', getRotations);
export const copyRotationASync = createAsyncThunk('rotations/copyRotations', copyRotations);
export const getRotationFilterASync = createAsyncThunk('rotations/filterRotation', filterRotation);
export const backupRotationASync = createAsyncThunk('rotations/backupRotation', backupRotation);
export const getBackupRotationASync = createAsyncThunk('rotations/getBackupRotation', getBackupRotation);
export const restoreBackupRotationASync = createAsyncThunk('rotations/restoreBackupRotation', restoreBackupRotation);
export const setRotationStatusASync = createAsyncThunk('rotations/setRotationStatus', setRotationStatus);
export const getRotationStatusASync = createAsyncThunk('rotations/getRotationStatus', getRotationStatus);

const RotationSlice = createSlice({
  name: 'rotations',
  initialState: {
    loading: false,
    rotations: [],
    filterItems: [],
    backupRotations: [],
    rotationDetails: {},
    rotationStatus:{},
    copyRotation: []
  },
  extraReducers: {
    [getRotationStatusASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getRotationStatusASync.fulfilled]: (state, action) => {
      state.rotationStatus = action.payload;
      state.loading = false;
      return state
    },
    [getRotationStatusASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [setRotationStatusASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [setRotationStatusASync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [setRotationStatusASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [backupRotationASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [backupRotationASync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [backupRotationASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getBackupRotationASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getBackupRotationASync.fulfilled]: (state, action) => {
      state.backupRotations = action.payload.data
      state.loading = false;
      return state
    },
    [getBackupRotationASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [restoreBackupRotationASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [restoreBackupRotationASync.fulfilled]: (state, action) => {
      state.loading = false;
      return state
    },
    [restoreBackupRotationASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getRotationFilterASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getRotationFilterASync.fulfilled]: (state, action) => {
      state.filterItems = action.payload
      state.loading = false;
      return state
    },
    [getRotationFilterASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [getRotationASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [getRotationASync.fulfilled]: (state, action) => {
      state.rotations = action.payload
      state.loading = false;
      return state
    },
    [getRotationASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
    [copyRotationASync.pending]: (state) => {
      state.loading = true;
      return state
    },
    [copyRotationASync.fulfilled]: (state, action) => {
      state.copyRotation = action.payload
      state.loading = false;
      return state
    },
    [copyRotationASync.rejected]: (state) => {
      state.loading = false;
      return state
    },
  }
})

export default RotationSlice.reducer;
