import { BsPlus } from "react-icons/bs"
import CustomDropdown from "../../Widgets/CustomDropdown"
import GlobalButton from "../../Widgets/GlobalButton"
import { useNavigate } from "react-router"
import AppLayout from "../../Widgets/AppLayout"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getBranchesAsync } from "../../../Store/Branches"
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify'
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel'
import { transferItemListTutorial, transferItemTutorial } from '../mock';

export const TransferItems = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [transfromBranch, selectFromBranch] = useState({ label: "All Branches", value : ""})
  const [selectItems, selectInventoryItem] = useState([])
  const [toBranch, selectToBranch] = useState({ label: "All Branches", value : ""})
  const branches = useSelector(state => state?.branches)?.branches?.data || []

  useEffect(()=>{
    dispatch(getBranchesAsync())
  },[])

  useEffect(()=>{
    const transferItem = localStorage.getItem("transferItem")
    if(transferItem !== undefined){
      const data = JSON.parse(transferItem);
      const withdrawalBranch = branches.find((x) => x.id === data?.withdrawLocationId)
      selectFromBranch({ label: withdrawalBranch?.name, value: withdrawalBranch?.id})
      selectInventoryItem(data?.transferData)
    } else {
      selectFromBranch({ label: "All Branches", value : ""})
    }
  },[])

  const handleSaveLocationInfo = () => {
    const data = JSON.parse(localStorage.getItem("transferItem"))
    const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    const transferData = {
      ...data,
      transferLocationId: toBranch.value,
      teamMembershipId: member.id,
      transferData: selectItems
    }
    localStorage.setItem("transferItem", JSON.stringify(transferData))

    navigate("/transfer-items-confirm")
  }

  return (
      <AppLayout
        custom="inventory"
        subCustom="transfer-items"
        title="Transfer Items"
        customTitle="Inventory"
        subtitle=""
        RightComponent={<TutorialPanel items={transferItemTutorial} />}
      >
      <div className="flex justify-between items-start">
        <div  className="flex flex-col justify-end">
        <div className="w-[475px] h-[168px] flex items-start p-3 gap-1 flex-wrap rounded-[10px] border dark:border-969 border-9FD">
          {
            selectItems?.map((item) => (<div key={item?.id} className="bg-5F5 dark:bg-color-333 dark:text-white flex justify-between items-center rounded-[5px] h-6 min-w-[100px] p-[4px] px-[10px]">
                                
            <p className="text-[16px]">{item?.referenceNumber}</p>
            <IoClose
            size={16}
            onClick={(e) => {
            e.stopPropagation();
            selectInventoryItem([
                ...selectItems.filter(
                (x) => x?.id !== item?.id
                ),
            ]);
            }}
            className="cursor-pointer ml-[10px] text-grey-600 dark:text-white"
            />
        </div>))
          }
        </div>
        <div className="mt-6">
          <div className="flex items-center gap-8 justify-end mt-6">
          <p className="font-medium text-base text-color-424 dark:text-white">Withdraw Location</p>
          <CustomDropdown
              data={[
                {
                  label: 'All Branches',
                  value: '',
                },
                ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
              ]?.map((team) => ({
                label: team?.label,
                value: team?.value,
                hexcode: team?.hexcode
              }))}
              optionClass="z-[500] h-[200px] overflow-y-scroll"
              className="min-w-[150px] w-[200px] rounded-[8px] h-[42px]"
              value={transfromBranch}
              dropdownTitleClass='dark:text-white'
              onChangeOption={(val) => selectFromBranch(val)}
            />

        </div>
        <div  className="flex items-center gap-8 justify-end mt-6">
          <p className="font-medium text-base text-color-424 dark:text-white">Transfer Location</p>
          <CustomDropdown
              data={[
                {
                  label: 'All branches',
                  value: '',
                },
                ...branches?.map((branch) => ({ label: branch?.name, value: branch.id, hexcode: branch?.hexcode}))
              ]?.map((team) => ({
                label: team?.label,
                value: team?.value,
                hexcode: team?.hexcode
              }))}
              optionClass="z-[500] h-[200px] overflow-y-scroll"
              className="min-w-[150px] w-[200px] rounded-[8px] h-[42px]"
              value={toBranch}
              dropdownTitleClass='dark:text-white'
              onChangeOption={(val) => selectToBranch(val)}
            />

        </div>
        <div className="flex justify-end">
        <GlobalButton onClick={()=> {
          if(transfromBranch?.value === ""){
            toast.error("Please select a withdrawal location")
            return
          }
          if(toBranch?.value === ""){
            toast.error("Please select a destination to transfer to")
            return
          }
          if(selectItems?.length === 0){
            toast.error("Please select inventory items to transfer")
            return
          }
          
          if(toBranch?.value === transfromBranch?.value){
            toast.error("Withdrawal location and transfer location cannot be the same location")
            return
          }

          handleSaveLocationInfo()

        }} className="text-white"  showIcon={false} containerClass="mt-9" title="Transfer Items" />
        </div>
      </div>
        </div>
        <button onClick={()=>{
            if(transfromBranch?.value === ""){
              toast.error("Please select a withdrawal location")
              return
            }

            const transferData = {
              withdrawLocationId: transfromBranch.value,
            }


            localStorage.setItem("transferItem", JSON.stringify(transferData))

            
          navigate("/transfer-items-list")}}className="flex items-center cursor-pointer font-bold gap-3 bg-btn-color px-5 py-[10px] rounded" ><BsPlus size={25} className="text-white" /> <span className="text-white">Get Items</span></button>
      </div>

      </AppLayout>
      
  )
}