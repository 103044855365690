import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from '../../../../HomePageScreen/Widget/Modal/Style.js';
import { Loader } from '../../../../../Widgets/Loader.jsx';
import { TaskClass } from '../../../../../../Network/ServiceClass/Task.js';
import {BsCheck} from 'react-icons/bs'
import FormModal from '../../../../../Widgets/Animation/FormModal.jsx';


const BoardModal = ({ open, handleClose, callback }) => {

    const [creating, setIsCreating] = useState(false);
    const [msg, setMsg] = useState("");

    const colors = ['#336A79','#5175F3', "#5CB26E", "#CCA300", "#824380", "#733131"]

    const task = new TaskClass();

    const [name, setName] = useState('');
    const [color, setColor] = useState();

    const CreateTask = () => {
        if (name !== '') {
            setIsCreating(true); setMsg('');
            task.createTask({
                name: name,
                color: colors[color]
            }).then((value) => {
                setName('');
                setIsCreating(false); 
                callback();
                handleClose();
            }).catch((err) => {
                console.log(err);  setIsCreating(false);
            });
        }else{
            setMsg("Input name");
        }

    }

    return (
        <FormModal open={open} handleClose={handleClose}>
            <Box sx={{ ...style, textAlign: "start", alignItems: "center", padding:"30px" }} className="create-account slot w-[436px] dark:bg-A06 dark:text-white">

                <div className='inner' style={{width:"100%"}}>

                        <>
                            <div style={{ fontSize: "14px" }}><p className="text-color-424 dark:text-white font-semibold mb-[10px]">Create new board</p></div>
                            <p className=" text-grey-600 font-semibold text-xs mb-[57px]">You are about to create a task board</p>

                            <div  style={{width:"100%"}}>

                                <div 
                                
                                style={{display:"inline-flex", width:"100%",justifyContent:"space-between",}}>
                                    {
                                        colors.map((value,index)=><div 
                                        onClick={()=>setColor(index)}
                                        style={{backgroundColor: value, marginTop:"20px", borderRadius:"5px", display:"inline-flex",
                                        justifyContent:"center", alignItems:'center', color:"white",
                                        }}
                                        className="w-12 h-12"
                                        >

                                            {(color===index)&&<BsCheck size={24} /> }

                                        </div>)
                                    }
                                </div>

                                    <center>
                                        <p className='label text-color-424 dark:text-white outline-none font-medium text-[10px] text-left mt-5 mb-[10px]'>Board Title</p>
                                        <input className=" py-[15px] pl-2 border-1 border-grey-500 focus:outline-none dark:border-333 dark:bg-A06 w-full placeholder:pl-4" value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter name of board" />
                                    </center>

                                {(!creating) ? <center><div className='btns' style={{ paddingTop: "2vh", width:"unset" }}>

                                    <button className='rounded border-1 border-black dark:border-333 font-semibold' type="button" onClick={(handleClose)}>Cancel</button>
                                    <span style={{width:"50px"}}></span>
                                    <button className={`rounded border-1 dark:border-none text-white ${name?.length > 0 ? "bg-base-color" : "bg-color-3A3"}`} type="button" onClick={CreateTask} >Create</button>

                                </div></center> : <Loader />}


                                <center><p className='error-msg'>{msg}</p></center>

                            </div>

                        </>

                </div>

            </Box>

        </FormModal>
       
    )
}

export default BoardModal